import React, {useEffect, useState, createRef, useRef} from 'react';
import {Translate} from "react-localize-redux";
import {Grid, Hidden, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import {useHistory} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import {staticContent, interviewTypes} from "../../../utils/constants";
import {isIOS, isAndroid} from "react-device-detect";


export default function SeeDemo(props) {
    const isSpeaking = window.location.pathname.indexOf("speaking") > -1;
    const useStyles = makeStyles((theme) => ({
        circle: {
            width: 88,
            height: 88,
            borderRadius: 50,
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 0px 25px #00000040;",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            [theme.breakpoints.up('xs')]: {
                position: "absolute",
                top: isSpeaking ? "46%" : "44%",
                left: isSpeaking ? "25%" : "34%"
            },
            [theme.breakpoints.only('xs')]: {
                position: "absolute",
                top: "calc((100% / 2) - 44px);",
                left: "calc((100% / 2) - 44px)"
            }
        },
        text: {
            fontWeight: 600,
            fontSize: 16
        }
    }))


    let classes = useStyles();
    let type = props.type ? props.type : interviewTypes.manual;

    const theme = useTheme();
    const screenLarge = useMediaQuery(theme.breakpoints.up('xs'));

    const desktopManual = "https://videoshihired.ams3.digitaloceanspaces.com/Vinterview/Videoentrevista-Desktop-Manual.mp4";
    const desktopAutomatic = "https://videoshihired.ams3.digitaloceanspaces.com/Vinterview/Videoentrevista-Desktop-Automatica.mp4";
    const iphone = "https://videoshihired.ams3.digitaloceanspaces.com/Vinterview/Videoentrevista-Iphone.mp4";
    const androidManual = "https://videoshihired.ams3.digitaloceanspaces.com/Vinterview/Videoentrevista-Android-Manual.mp4";
    const androidAutomatic = "https://videoshihired.ams3.digitaloceanspaces.com/Vinterview/Videoentrevista-Android-Automatica.mp4";


    const desktopVideoSpeaking = "https://videoshihired.ams3.digitaloceanspaces.com/Vspeaking/VideoSpeaking-Desktop.mp4";
    const androidVideoSpeaking = "https://videoshihired.ams3.digitaloceanspaces.com/Vspeaking/VideoSpeaking-Android.mp4";
    const iphoneVideoSpeaking = "https://videoshihired.ams3.digitaloceanspaces.com/Vspeaking/VideoSpeaking-Iphone.mp4";


    useEffect(() => {

    }, [])


    const resolveDemoUrl = () => {
        if (isSpeaking) {
            if (isIOS) return iphoneVideoSpeaking;
            if (isAndroid) return androidVideoSpeaking;
            if (screenLarge) return desktopVideoSpeaking;
        } else {

            if (isIOS) return iphone;
            if (isAndroid) return type === interviewTypes.manual
                ? androidManual : androidAutomatic;
            if (screenLarge) return type === interviewTypes.manual
                ? desktopManual : desktopAutomatic;
        }
        return "";
    }

    const handleOpenDemo = () => {
        window.open(resolveDemoUrl(), "_blank")
    }

    return (<Translate>
        {({translate}) => {
            return <div className={classes.circle} role="button" onClick={handleOpenDemo}>
                <div className="text-center">
                    <SearchIcon/>
                    <Typography className={classes.text}>{translate("Buttons.SeeDemo")}</Typography>
                </div>
            </div>
        }}
    </Translate>);
}