import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Box, useMediaQuery} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { Tooltip } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import LogoImage from "../../images/logo.png";
import LogoImageMovil from "../../images/logoMovil.png";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        width: "100%",
        [theme.breakpoints.up('xl')]: {
            width: 1716,
            maxWidth: 1716
        },
        [theme.breakpoints.down('lg')]: {
            width: "100%"
        },
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: 60,
        },
        [theme.breakpoints.down('xs')]: {
            height: 60,
        }
    },
    logo: {
        //background: `transparent url('${LogoImage}') 0% 0% no-repeat padding-box`,
        //backgroundSize: "cover",
        objectFit: "cover",
        width: 97,
        height: 69,
        [theme.breakpoints.down('sm')]: {
            width: 72,
            height: 50,
           // background: `transparent url('${LogoImageMovil}') 0% 0% no-repeat padding-box`,

        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: 90,
            height: 62,
        },
    },
    header: {
        position: "fixed",
        background: "white",
        height: 90,
        zIndex: 1000,
        [theme.breakpoints.down('sm')]: {
            height: 60,
        },
        '@media (min-width: 900px) and (max-width: 1400px)': {
            '@media (min-height: 400px) and (max-height: 800px)': {
                height: 70
            }
        }
    },
    closed: {
        marginTop: 16,
        marginRight: 12,
        padding: 0,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 50,
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            marginTop: 4,
            marginRight: 0,
        },
        '@media (min-width: 900px) and (max-width: 1400px)': {
            '@media (min-height: 400px) and (max-height: 800px)': {
                marginTop: 6,
                marginRight: 8,
            }
        }
    },
    icon: {
        height: 52,
        width: 53,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            height: 30,
            width: 31,
        },
        '@media (min-width: 900px) and (max-width: 1400px)': {
            '@media (min-height: 400px) and (max-height: 800px)': {
                height: 40,
                width: 40,
            }
        }
    },
}));

export default function VideoHeader() {
    const classes = useStyles();
    const headerActions = useSelector(state => {
        return state.interviews.actions;
    });
    const currentAction = headerActions.find(x => x.path === window.location.pathname);
    const Wrap = ({ children }) => <div>{children}</div>

    function renderChild() {
        return currentAction && <div variant="outlined" color="default" className={classes.closed} onClick={currentAction.callBack}>
            {currentAction.component === "close"
                ? <Tooltip title={<Translate id={"Buttons.Exit"} />}><CloseSharpIcon fontSize="large" id="iconClose" className={classes.icon} color="action" /></Tooltip>
                : currentAction.component === "back"
                    ? <Tooltip title={<Translate id={"Buttons.GoBack"} />}><ArrowBackOutlinedIcon fontSize="large" id="iconArrow" className={classes.icon} color="action" /></Tooltip> : ""
            }
        </div>
    }
    const theme = useTheme();
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (<Grid container direction="row" id="headerVideo" justify="center" className={classes.header}>
        <div className={classes.root} id="headerVideoRoot">
            <Box display="flex" p={1} id="headerVideoBox" bgcolor="background.paper" className="py-0">
                <Box p={1} flexGrow={1}>
                    <img alt={"Hihired!"} src={screenSmDown ? LogoImageMovil : LogoImage} className={classes.logo}/>
                </Box>
                <Box p={1}>
                    {renderChild()}
                </Box>
            </Box>
        </div>
    </Grid>);
}