import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import IconFilter from "../../images/professional/Group 443.png";
import IconFilterBlack from "../../images/professional/Group 443 Black.png";
import Popper from "@material-ui/core/Popper/Popper";
import Grow from "@material-ui/core/Grow/Grow";
import Paper from "@material-ui/core/Paper/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        marginBottom: 0,
        marginTop: 0,
        fontSize: 18,
        color: "#838386",
        [theme.breakpoints.only('xs')]: {
            minHeight: 22
        },
    },

    colorWhite: {
        color: "#FFFFFF"
    },
    colorBlack: {
        color: theme.palette.text.secondary
    }
}));

export default function Filter(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function short(value) {
        handleClose()
        props.order(value)
    }

    return ( <div className={classes.menu} style={props.top === 0 ? {paddingRight: 12} : {}}>
            <IconButton style={props.top === 0 ? {padding: 0} : {padding: 8}}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={props.color === true ? classes.colorBlack : classes.colorWhite}>
                <img src={props.color ===true ? IconFilterBlack : IconFilter} />
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{zIndex: 1}}
                    disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0} square>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow"
                                          style={{ paddingTop: 0 }}>
                                    <MenuItem onClick={()=>short(1)} className={classes.menuItem} style={props.filterValue && props.filterValue === 1 ? {backgroundColor: "rgba(0, 0, 0, 0.04)"} : {}}
                                              >
                                        <Translate id={"Professional.ByStartDate"} />
                                    </MenuItem>
                                    <MenuItem onClick={()=>short(2)} className={classes.menuItem} style={props.filterValue && props.filterValue === 2 ? {backgroundColor: "rgba(0, 0, 0, 0.04)"} : {}}
                                              >
                                        <Translate id={"Professional.ByEndDate"} />
                                    </MenuItem>
                                    <MenuItem onClick={()=>short(3)} className={classes.menuItem} style={props.filterValue && props.filterValue === 3 ? {backgroundColor: "rgba(0, 0, 0, 0.04)"} : {}}
                                              >
                                        <Translate id={"Professional.ByProvinceMunicipality"} />
                                    </MenuItem>
                                    <MenuItem onClick={()=>short(4)} className={classes.menuItem} style={props.filterValue && props.filterValue === 4 ? {backgroundColor: "rgba(0, 0, 0, 0.04)"} : {}}
                                              >
                                        <Translate id={"Professional.ByProcessName"} />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>

    );
}