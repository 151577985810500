import React, {useEffect, useState, createRef, useRef} from 'react';
import {withRouter} from "react-router-dom";
import api from "../../api/index";
import {Translate} from "react-localize-redux";
import {Button, Grid, Hidden, Paper, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import GoBack from "../navigation/goBack";
import moment from "moment";
import {Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';
import {PaymentType} from "../../utils/constants";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => {
    theme.overrides.MuiInputLabel = {
        ...theme.overrides.MuiInputLabel,
        root: {
            ...theme.overrides.MuiInputLabel.root,
            fontWeight: "bold"
        }
    }
    return {
        paddingXs: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "1rem",
                paddingLeft: "1rem"
            }
        },
        title: {
            fontSize: 35,
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
                marginTop: 0
            }
        },
        subTitle: {
            fontSize: 25,
            fontWeight: 600,
            lineHeight: "normal",
            [theme.breakpoints.only('xs')]: {
                fontSize: 18,
            }
        },
        paper: {
            padding: "28px 60px",
            boxShadow: "0px 0px 25px #0000001A",
            paddingBottom: 47,
            [theme.breakpoints.only('xs')]: {
                boxShadow: "none",
                padding: 0,
                marginTop: 0
            },
        },
        box: {
            padding: "23px 36px",
            backgroundColor: "#F7F7F7",
            height: "100%",
            minHeight: 450,
            [theme.breakpoints.only('xs')]: {
                padding: "12px 15px",
                minHeight: 'auto',
            }
        },
        boxContainer: {
            marginTop: 40,
            [theme.breakpoints.only('xs')]: {
                marginTop: 20
            }
        },
        table: {
            width: "100%",
            marginTop: 27
        },
        column: {
            fontWeight: 600,
            fontSize: 18,
            width: "50%",
            padding: "5px 0"
        },
        row: {
            fontSize: 18,
            padding: "5px 0"
        },
        tr: {
            borderBottom: `1px solid #707070`,
        },
        optionCard: {
            marginTop: 27
        },
        labelCard: {
            fontSize: 18,
            fontWeight: 600
        },
        select: {
            border: "1px solid #141414",
            background: "white",
            padding: 10,
            minWidth: 360,
            "&:focus": {
                background: "white",
            },
            [theme.breakpoints.only('xs')]: {
                minWidth: 200
            }
        },
        formControl: {
            marginTop: '0 !important',
            minHeight: 30,
            marginLeft: 34,
            "& .MuiInputBase-root": {
                marginTop: '0 !important',
                minHeight: 30,
            }
        },
        button: {
            borderRadius: 0,
            //minWidth: 180,
            //minHeight: 40,
            //height: 40,
            marginTop: 40
        },
        buttonLabel: {
            fontSize: 18,
            fontWeight: 700,
            color: "#141414"
        },
        form: {
            [theme.breakpoints.only('xs')]: {
                textAlign: "center"
            }
        },
        submit2: {
            minWidth: 82,
            borderRadius: 0,
            fontSize: 18,
            fontWeight: "bold"
        },
        paperAlert: {
            textAlign: "center",
            boxShadow: "0px 0px 25px #0000004D",
            borderRadius: 10,
            padding: "15px 48px 29px 48px",
            [theme.breakpoints.only('xs')]: {
                padding: "15px 24px 29px 24px",
            },
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        }
    }
});

function Pay(props) {
    let classes = useStyles();
    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    const planCode = props.match.params["plan"] ? props.match.params["plan"] : null;
    const testCant = planCode === PaymentType.VideoSpeakingTest || planCode === PaymentType.AddCredit ? props.match.params["cant"] ? parseInt(props.match.params["cant"]) : 0 : 0;
    const offerId = planCode !== PaymentType.VideoSpeakingTest || planCode !== PaymentType.AddCredit ? props.match.params["cant"] ? parseInt(props.match.params["cant"]) : 0 : 0;
    const paymentId = props.match.params["payment"] ? parseInt(props.match.params["payment"]) : 0;


    let [plan, setPlan] = useState(null);
    let [typeCard, setTypeCard] = useState("new");
    let [paymentRequest, setPaymentRequest] = useState(null);
    let [paymentInfos, setPaymentInfos] = useState(null);
    let [payment, setPayment] = useState(null);

    let [redirectUrl, setRedirectUrl] = useState(null);
    let [invoiceNumber, setInvoiceNumber] = useState(null);

    let [savedCard, setSavedCard] = useState(null);
    let [rememberCard, setRememberCard] = useState(false);
    let [openRememberDialog, setOpenRememberDialog] = useState(false);

    useEffect(() => {

        if (planCode) getPlan(planCode);
        loadPreOrder();
        loadPayment();

        //loadPaymentRequest();
        loadPaymentsInfo();
    }, [typeCard, invoiceNumber, redirectUrl, plan, savedCard, paymentRequest])

    function loadPayment() {
        if (payment === null) {
            api.payments.getPayment(paymentId).then(response => {
                if (response.status === 200) {
                    setPayment(response.data);
                }
            })
        }
    }

    function loadPreOrder() {
        if (redirectUrl === null && invoiceNumber === null) {
            if (plan) {
                api.payments.getCompletedUrl(plan.id, offerId, paymentId).then(response => {
                    if (response.status === 200) {
                        let {redirectUrl, order} = response.data;
                        setInvoiceNumber(order);
                        setRedirectUrl(redirectUrl);
                    }
                })
            }
        }
    }

    function getPlan(code) {
        if (plan == null) {
            api.plans.getPlan(code, testCant, offerId).then(response => {
                if (response.status === 200) {
                    setPlan(response.data);
                }
            })
        }
    }

    async function loadPaymentRequest(reload = false, remember = true) {
        if (paymentRequest == null || reload) {
            if (redirectUrl && payment && plan) {
                let response = await api.payments.getPaymentRequest(redirectUrl,
                    payment.invoiceNumber,
                    payment.import,
                    remember ? null : "",
                    planCode, paymentId);
                if (response.status === 200) {
                    setPaymentRequest(response.data);
                    return true;
                }
            }
        }
        return false;
    }

    async function loadPaymentRequestWithCard() {
        if (redirectUrl && payment && plan && savedCard) {
            let response = await api.payments.getPaymentRequest(redirectUrl, payment.invoiceNumber, payment.import, savedCard, planCode, paymentId);
            if (response.status === 200) {
                setPaymentRequest(response.data);
                return true;
            }
        }
        return false;
    }

    function loadPaymentsInfo() {
        if (paymentInfos == null) {
            api.payments.getEmployerPaymentInfos().then(response => {
                if (response.status === 200) {
                    setPaymentInfos(response.data);
                }
            })
        }
    }

    const handleChangeTypeCard = (event) => {
        setTypeCard(event.target.value);
        if (event.target.value === "new") {
            setPaymentRequest(null);
            setSavedCard(null);
        }
    };

    const handleChangeCardNumber = (event) => {
        let card = event.target.value;
        setSavedCard(card);
    };

    function addPayment() {
        api.payments.createOrder(plan.id, plan.total, invoiceNumber, testCant, plan.price, plan.ivaPercent, offerId);
    }

    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace'><Translate id={"Employer.EmployerArea"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace/segment'><Translate id={"Payments.Invoice"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    function renderPaymentForm() {
        return paymentRequest
            ? <form name="from" action="https://sis.redsys.es/sis/realizarPago" method="POST"
                    className={classes.form}
                    hidden={true}
                    id="payment-form">
                <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                <input type="hidden" name="Ds_MerchantParameters"
                       value={paymentRequest ? paymentRequest.merchantParameters : ""}/>
                <input type="hidden" name="Ds_Signature" value={paymentRequest ? paymentRequest.signature : ""}/>

                <Button variant="contained" color="secondary" type="submit"
                        classes={{root: classes.button, label: classes.buttonLabel}}
                        onClick={addPayment}>
                    <Translate id={"Payments.Pay"}></Translate>
                </Button>
            </form>
            : <div></div>
    }

    function submitPayForm() {
        document.getElementById("payment-form").submit();
    }

    function renderPrePaymentForm() {
        return <Grid item xs={12}>
            <Button variant="contained" color="secondary"
                    disabled={typeCard == "saved" && savedCard === null}
                    classes={{root: classes.button, label: classes.buttonLabel}}
                    onClick={async () => {
                        if (typeCard === "new")
                            setOpenRememberDialog(true)
                        else {
                            let go = await loadPaymentRequestWithCard();
                            if (go) submitPayForm()
                        }
                    }}>
                <Translate id={"Payments.Pay"}/>
            </Button>
        </Grid>
    }

    function renderDialogRemember() {
        return <Dialog
            open={openRememberDialog}
            classes={{paper: classes.paperAlert}}>
            <IconButton className={classes.closeButton} onClick={() => setOpenRememberDialog(false)}>
                <CloseIcon/>
            </IconButton>

            <DialogTitle className="mt-4">
                <Typography className={`${classes.submit2} font-weight-bold`}>
                    <Translate id={"Payments.RememberCard"}/>
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button
                    variant="contained" color="secondary"
                    onClick={async () => {
                        let go = await loadPaymentRequest(true, true);
                        if (go) submitPayForm()
                        setOpenRememberDialog(false)
                    }}
                    className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}>
                        <Translate id={"Buttons.Yes"}/>
                    </Typography>
                </Button>
                <Button variant="contained" color="secondary"
                        onClick={async () => {
                            let go = await loadPaymentRequest(true, false);
                            if (go) submitPayForm()
                            setOpenRememberDialog(false)
                        }}
                        className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}>
                        <Translate id={"Buttons.No"}/>
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    }

    return (<Translate>
        {({translate}) => {
            return <Grid container spacing={0}>
                {renderBreadCrumb()}
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item item xs={11} sm={9} md={11} className="pb-4">
                        <Paper elevation={2} className={classes.paper}>
                            <Typography className={classes.title} color="textSecondary">
                                <Translate id={"Payments.PayTitle"}/>
                            </Typography>

                            <Grid item xs={12} className={classes.boxContainer}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={5}>
                                        <div className={classes.box}>
                                            {plan && payment ?
                                                <React.Fragment>
                                                    <Typography className={classes.subTitle} color="textSecondary">
                                                        <Translate id={"Payments.OperationTitle"}/>
                                                    </Typography>

                                                    <table className={classes.table}>
                                                        <tr className={classes.tr}>
                                                            <th className={classes.column}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.column}>
                                                                    {`${translate("Payments.Import")}:`}
                                                                </Typography>
                                                            </th>
                                                            <th className={classes.column}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.column}>

                                                                    {`${payment.import.toFixed(2)} ${payment.currencySymbol}`}
                                                                </Typography>
                                                            </th>
                                                        </tr>

                                                        <tr className={classes.tr}>
                                                            <th className={classes.column}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.column}>
                                                                    {`${translate("Payments.ConceptColumn")}:`}
                                                                </Typography>
                                                            </th>
                                                            <th className={classes.row}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.row}>
                                                                    {payment.concept}
                                                                </Typography>
                                                            </th>
                                                        </tr>

                                                        {/*<tr className={classes.tr}>
                                                            <th className={classes.column}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.column}>
                                                                    {`${translate("Payments.Order")}:`}
                                                                </Typography>
                                                            </th>
                                                            <th className={classes.row}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.row}>
                                                                    {payment.paymentNumber}
                                                                </Typography>
                                                            </th>
                                                        </tr>*/}

                                                        <tr className={classes.tr}>
                                                            <th className={classes.column}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.column}>
                                                                    {`${translate("Payments.Date")}:`}
                                                                </Typography>
                                                            </th>
                                                            <th className={classes.row}>
                                                                <Typography color="textSecondary"
                                                                            className={classes.row}>
                                                                    {moment(new Date()).format("DD/MM/YYYY")}
                                                                </Typography>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={7}>
                                        <div className={classes.box}>
                                            <Typography className={classes.subTitle} color="textSecondary">
                                                <Translate id={"Payments.PaymentTitle"}/>
                                            </Typography>

                                            <div className={classes.optionCard}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="cardType" name="cardType" value={typeCard}
                                                                onChange={handleChangeTypeCard}>
                                                        <FormControlLabel classes={{label: classes.labelCard}}
                                                                          value="saved"
                                                                          control={<Radio color="primary"/>}
                                                                          label={translate("Payments.SavedCard")}/>

                                                        <FormControl className={classes.formControl}
                                                                     disabled={typeCard === "new"}>
                                                            <Select classes={{select: classes.select}}
                                                                    value={savedCard}
                                                                    onChange={handleChangeCardNumber}>
                                                                {paymentInfos && paymentInfos.map(x => {
                                                                    return <MenuItem
                                                                        value={x.token}>{x.identifier}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>


                                                        <FormControlLabel classes={{label: classes.labelCard}}
                                                                          value="new" control={<Radio color="primary"/>}
                                                                          label={translate("Payments.NewCard")}/>
                                                    </RadioGroup>
                                                </FormControl>

                                                {renderPaymentForm()}
                                                {renderPrePaymentForm()}
                                            </div>

                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Paper>
                    </Grid>
                </Grid>

                <Grid item item xs={12} sm={9} md={11} className="my-4 text-center">
                    <GoBack type="button"/>
                </Grid>

                {renderDialogRemember()}
            </Grid>
        }}
    </Translate>)
}

export default withRouter(Pay);