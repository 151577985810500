import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles((theme) => ({
    imgResponsive: {
        width: "auto",
        height: "auto",
        maxWidth: "45%",
        marginRight: 20,
        [theme.breakpoints.only('xs')]: {
            maxWidth: "100%"
        }
    },
    title: {
        fontWeight: 600,
        marginBottom: 20
    }
}));

export default function SafariIphone(props) {

    let classes = useStyles();
    return (<Translate>
        {({translate}) => {
            return <Grid id="firefox" key="firefox" container className="mb-5">
                <Typography className={classes.title} variant="h2"><Translate id="Help.SafariIphoneTitle"/></Typography>
                <Grid item xs={12} className="mb-3" hidden={true}>
                    <Typography color="error"><Translate id="Help.GenericText1"/></Typography>
                    <Typography color="error"><Translate id="Help.GenericText2"/></Typography>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.ChromeIphoneStep1"/></Typography>
                    <img alt={translate("Help.SafariIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/9.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.SafariIphoneStep2"/></Typography>
                    <img alt={translate("Help.SafariIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/safariiphone/1.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.SafariIphoneStep1"/></Typography>
                    <img alt={translate("Help.SafariIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/safariiphone/2.jpg")}/>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography>4. <Translate id="Help.ChromeStep3"/></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                    </div>
                </Grid>
            </Grid>
        }}
    </Translate>)
}