import {
    isIOS,
    isWindows,
    isMacOs,
    isAndroid,
    isChrome,
    isFirefox,
    isSafari,
    isEdge,
    isOpera
} from "react-device-detect";


export function browserSupport() {
    var isOpera = (!!window.opr) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    var isFirefox = typeof InstallTrigger !== 'undefined';
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined'));
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia;
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    let support = [{
        support: isOpera,
        name: "opera",
        platform: navigator ? navigator.platform : ""
    }, {
        support: isFirefox,
        name: "firefox",
        platform: navigator ? navigator.platform : ""
    }, {
        support: isSafari,
        name: "safari",
        platform: navigator ? navigator.platform : ""
    }, {
        support: isEdge,
        name: "edge",
        platform: navigator ? navigator.platform : ""
    }, {
        support: isChrome,
        name: "chrome",
        platform: navigator ? navigator.platform : ""
    }, {
        support: isEdgeChromium,
        platform: navigator ? navigator.platform : ""
    }]
    return support;
}

export function getBrowserSupport() {
    return browserSupport().find(x => x.support === true);
}


export function isSupportedBrowser() {
    let isOpera = (!!window.opr) || !!window.opera || (navigator && navigator.userAgent && navigator.userAgent.indexOf(' OPR/') >= 0);
    if (isOpera) return false;
    if (!isChrome && !isFirefox && !isEdge && !isSafari) return false;

    return isWindows && (isChrome || isFirefox || isEdge) ? true
        : isMacOs && (isFirefox || isChrome) ? true
            : isIOS && (isFirefox || isSafari || isChrome) ? true
                : isAndroid && (isChrome || isFirefox) ? true
                    : false;
}