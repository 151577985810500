import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Translate } from "react-localize-redux";
import Paper from "@material-ui/core/Paper";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Hidden,
  CircularProgress,
  Grid,
  Icon,
  Tooltip,
  Divider,
  MenuItem,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import LockIcon from "@material-ui/icons/Lock";
import "react-quill/dist/quill.snow.css";
import api from "../../api/index";
import moment from "moment";
import DefaultImage from "../../images/employer/default-image.png";
import { NavLink } from "react-router-dom";
import ConfirmDialog from "../../components/confirmation/confirm";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/users/index";
import ImageRecord from "../../images/professional/group-567.png";
import ImageRecordOther from "../../images/professional/group-568.png";
import { ReactComponent as Help } from "../../images/professional/help.svg";
import UploadFile from "./uploadFile";
import LogoPreview from "./interviewPreview";
import { useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";

const useStyles = makeStyles((theme) => {
  theme.overrides.MuiInputLabel = {
    ...theme.overrides.MuiInputLabel,
    root: {
      ...theme.overrides.MuiInputLabel.root,
      fontWeight: "bold",
    },
  };
  return {
    phone: {
      ":focus": {
        outlineColor: "#000",
        borderColor: "#000",
      },
    },
    text: {
      fontSize: 16,
      letterSpacing: 0.9,
      textAlign: "right",
      fontWeight: 600,
    },
    title: {
      fontSize: 35,
      fontWeight: 600,
      marginTop: 20,
      lineHeight: "normal",
      letterSpacing: 0,
      [theme.breakpoints.only("xs")]: {
        fontSize: 30,
        marginTop: 0,
      },
    },
    responsiveWidth: {
      width: "100%",
      "& input": {
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          fontSize: 15,
        },
      },
    },
    icon: {
      padding: 15,
      background: "#EDEDED",
      width: 54,
      height: 54,
      position: "absolute",
      right: 0,
      top: 20,
      cursor: "pointer",
    },
    information: {
      fontSize: 22,
      fontWeight: 600,
      fontFamily: "Barlow",
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    colorBlack: {
      color: theme.palette.text.secondary,
    },
    toolTip: {
      backgroundColor: "white",
      color: theme.palette.text.secondary,
      fontSize: 15,
      width: 125,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: "initial",
      letterSpacing: 0.58,
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
        letterSpacing: 0.55,
      },
    },
    toolTip2: {
      backgroundColor: "white",
      color: theme.palette.text.secondary,
      fontSize: 15,
      width: 158,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: "initial",
      letterSpacing: 0.58,
      textAlign: "left",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
        letterSpacing: 0.55,
      },
    },
    divider: {
      width: 143,
      height: 0,
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    adornment: {
      "& .MuiIconButton-root.Mui-disabled": {
        color: theme.palette.text.secondary,
      },
      backgroundColor: "transparent",
    },
    adornment2: {
      "& .MuiIconButton-root.Mui-disabled": {
        color: theme.palette.text.secondary,
      },
      backgroundColor: "transparent",
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
    },
    submit: {
      minWidth: 200,
      borderRadius: 0,
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: "#FFFFFF",
      textTransform: "none",

      /*minHeight: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"*/
    },
    marginUpMd: {
      marginTop: 112,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    image: {
      width: 232,
      height: 232,
      [theme.breakpoints.down("lg")]: {
        width: 190,
        height: 190,
      },
    },
    recordImage: {
      height: 25,
      width: 25,
    },
    help: {
      height: 23,
      width: 23,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 50,
      "& svg": {
        maxHeight: 18,
      },
    },
    completeSuggest: {
      fontSize: 25,
      lineHeight: "normal",
      letterSpacing: 0,
      [theme.breakpoints.only("xs")]: {
        fontSize: 22,
        marginTop: 8,
      },
    },
    deleteAccount: {
      position: "absolute",
      minHeight: 50,
      right: 0,
      minWidth: 200,
      borderRadius: 0,
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: theme.palette.primary.main,
      [theme.breakpoints.only("xs")]: {
        position: "unset",
        marginTop: 20,
      },
    },
    rootSecondary: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      display: "flex",
      letterSpacing: 0.8,
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
    },
    rootSecondaryFull: {
      color: theme.palette.secondary.main,
      "&.Mui-disabled": {
        color: theme.palette.secondary.main,
      },
    },
    butonContainer: {
      display: "flex",
      position: "relative",
      [theme.breakpoints.only("xs")]: {
        display: "block",
        position: "unset",
        textAlign: "center",
      },
    },
    required: {
      fontWeight: 600,
      fontSize: 16,
    },
    paddingXs: {
      [theme.breakpoints.only("xs")]: {
        paddingRight: "1rem",
        paddingLeft: "1rem",
      },
    },
    titleInterview: {
      fontWeight: "bold",
      fontSize: 22,
      letterSpacing: 1.1,
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    subTitleInterview: {
      textAlign: "start",
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: 0.9,
      [theme.breakpoints.only("xs")]: {
        letterSpacing: 0.45,
      },
    },
    checkboxBackground: {
      height: 53,
      backgroundColor: "#F7F7F7",
    },
    divWidth: {
      [theme.breakpoints.only("md")]: {
        width: "40.3%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "27.3%",
      },
      [theme.breakpoints.only("xl")]: {
        width: "29.3%",
      },
      width: "100%",
    },
    divWidth2: {
      [theme.breakpoints.only("md")]: {
        width: "37.3%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "26.3%",
      },
      [theme.breakpoints.only("xl")]: {
        width: "28.3%",
      },
      width: "100%",
    },
    divWidth3: {
      [theme.breakpoints.only("md")]: {
        width: "37.3%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "57%",
      },
      [theme.breakpoints.only("xl")]: {
        width: "60%",
      },
      width: "100%",
    },
    divWidth4: {
      [theme.breakpoints.only("md")]: {
        width: "40.3%",
      },
      [theme.breakpoints.only("lg")]: {
        width: "23.3%",
      },
      [theme.breakpoints.only("xl")]: {
        width: "29.3%",
      },
      width: "100%",
    },
    iconInfo: {
      width: "auto",
      height: "auto",
      position: "absolute",
      top: -8,
      right: 0,
    },
    iconInfo2: {
      width: "auto",
      height: "auto",
      position: "absolute",
      top: -8,
      right: 0,
      [theme.breakpoints.only("xs")]: {
        right: 15,
      },
    },
    iconUpload: {
      width: "auto",
      height: "auto",
      position: "absolute",
      top: -15,
      right: 0,
      cursor: "pointer",
    },
    iconSocial: {
      top: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      color: "#DDDDDD",
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    ellipsis: {
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
      maxWidth: "97%",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
    },
    ellipsisMin: {
      maxWidth: "89%",
    },
    paper: {
      [theme.breakpoints.up("sm")]: {
        boxShadow: "0px 0px 36px #00000015",
      },
    },
    itemContainer: {
      width: "max-content",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    boxLanguage: {
      padding: 20,
      backgroundColor: "#F7F7F7",
      display: "flex",
      flexWrap: "wrap",
    },
    captionLanguage: {
      fontWeight: 700,
      marginBottom: 8,
    },
    grid: {
      display: "grid",
      padding: 6,
      width: 92,
      justifyContent: "center",
    },
    img: {
      width: 36,
      height: 36,
      margin: "auto",
      marginBottom: 12,
    },
    languageName: {
      fontSize: 14,
    },
    opacity: {
      opacity: 0.4,
    },
    divGrey: {
      height: 50,
      backgroundColor: "#F7F7F7",
      display: "flex",
      alignItems: "center",
      paddingLeft: 16,
    },
    paddingLarge: {
      paddingLeft: `34px !important`,
    },
    socialLink: {
      "& .MuiInputBase-input": {
        minWidth: 100,
        width: "75%",
        [theme.breakpoints.only("sm")]: {
          width: "87%",
        },
        [theme.breakpoints.only("md")]: {
          width: "64%",
        },
        [theme.breakpoints.only("lg")]: {
          width: "67%",
        },
        [theme.breakpoints.only("xl")]: {
          width: "77%",
        },
      },
    },
    field: {
      margin: "10px 0",
    },
    countryList: {
      ...theme.typography.body1,
    },
  };
});

export default function Professional() {
  let history = useHistory();
  const theme = useTheme();
  const screenXsOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const screenSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  let [typePass, setTypePass] = useState(true);
  let [professionalInfo, setProfessionalInfo] = useState(null);
  let [saving, setSaving] = useState(false);
  let [loading, setLoading] = useState(false);
  let [searching, setSearching] = useState(false);
  let [isCompanyManager, setIsCompanyManager] = useState(false);
  let [selectedLogo, setSelectedLogo] = useState(null);
  let [confirmation, setConfirmation] = useState(false);
  let [confirmationFirst, setConfirmationFirst] = useState(false);
  let [countries, setCountries] = useState([]);
  let [provinces, setProvinces] = useState([]);
  let [municipalities, setMunicipalities] = useState([]);
  let [validateYear, setValidateYear] = useState(true);
  let [yearBirthToolTip, setYearBirthToolTip] = useState(false);
  let [descriptionToolTip, setDescriptionToolTip] = useState(false);
  let [validateView, setValidateView] = useState(true);
  let [validateMobile, setValidateMobile] = useState(true);

  let [formData, setFormData] = useState({
    password: "",
    registrationDate: "",
    logo: null,
    imageVideoInterview: null,
    name: "",
    lastName1: "",
    lastName2: "",
    yearBirth: null,
    mobilePhone: null,
    contactedWhatsApp: false,
    professionalRegistrationEmail: null,
    country: "",
    province: "",
    municipality: "",
    descriptiveTitleProfession: "",
    HigherLevelTraining: "",
    mostImportantJobs: "",
    attachCV: "",
    socialEnvironmentalCollaborations: "",
    achievementsAwards: "",
    linkedIn: "",
    facebook: "",
    twitter: "",
    instagram: "",
    pinterest: "",
    youtube: "",
    receiveNotifications: false,
  });
  const dispatch = useDispatch();
  let loguedUser = useSelector((state) => state.users.loggedUser);

  const redirectUrl = window.location.search
    ? window.location.search.replace("?redirect=", "")
    : null;

  useEffect(() => {
    loadProfessionalProfile();
    loadCountries();
  }, []);

  const handleTooltipCloseYearBirth = () => {
    setYearBirthToolTip(false);
  };

  const handleTooltipOpenYearBirth = () => {
    setYearBirthToolTip(true);
  };

  const handleTooltipCloseDescription = () => {
    setDescriptionToolTip(false);
  };

  const handleTooltipOpenDescription = () => {
    setDescriptionToolTip(true);
  };

  function loadProfessionalProfile() {
    if (professionalInfo === null) {
      setLoading(true);
      api.professional
        .getProfessionalProfile()
        .then(async (response) => {
          if (response.status === 200) {
            setProfessionalInfo(response.data);
            if (response.data.municipality) {
              if (response.data.municipality.province) {
                if (response.data.municipality.province.country) {
                  let provinces = await loadProvinces(
                    response.data.municipality.province.country.id
                  );
                  let province = provinces.find(
                    (x) => x.id === response.data.municipality.province.id
                  );
                  if (province && province.municipalities) {
                    setMunicipalities(province.municipalities);
                  }
                }
              }
            }
            mapProfessionalInfo(response.data);
          }
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  }

  function loadCountries() {
    if (countries.length === 0)
      api.countries.all().then((response) => {
        if (response.status === 200) {
          setCountries(response.data);
        }
      });
  }

  function loadProvinces(countryId) {
    //if (provinces.length === 0)
    setMunicipalities([]);
    return api.provinces.allByCountry(countryId).then((response) => {
      if (response.status === 200) {
        setProvinces(response.data);
        return response.data;
      }
    });
  }

  function mapProfessionalInfo(professional) {
    setConfirmationFirst(professional.firstName);
    setFormData({
      registrationDate: moment(new Date(professional.registryDate)).format(
        "DD/MM/YYYY"
      ),
      logo: professional.imageUrl,
      name: professional.firstName,
      lastName1: professional.lastName,
      lastName2: professional.surName,
      yearBirth: new Date(professional.birthday).getFullYear(),
      mobilePhone: professional.phoneNumber,
      professionalRegistrationEmail: professional.email,
      country:
        professional.municipality &&
        professional.municipality.province &&
        professional.municipality.province.country
          ? professional.municipality.province.country.id
          : null,
      province:
        professional.municipality && professional.municipality.province
          ? professional.municipality.province.id
          : null,
      municipality: professional.municipality
        ? professional.municipality.id
        : null,
      descriptiveTitleProfession: professional.professionTitle,
      higherLevelTraining: professional.academicTrainingHigher,
      mostImportantJobs: professional.moreImportantWork,
      attachCV: professional.cvUrl,
      socialEnvironmentalCollaborations: professional.socialCollaborations,
      achievementsAwards: professional.awards,
      linkedIn: professional.linkedInProfile,
      facebook: professional.facebookProfile,
      twitter: professional.twitterProfile,
      instagram: professional.instagramProfile,
      pinterest: professional.pinterestProfile,
      youtube: professional.youtubeProfile,
      attachValidatedReferences: professional.referencesUrl,
      curiosities: professional.curiosities,
      contactedWhatsApp: professional.acceptWhatsAppHired,
      imageVideoInterview: professional.interviewUrl,
      awardUrl: professional.awardUrl,
      receiveNotifications: professional.receiveNotifications,
      socialMediaProfile: professional.socialMediaProfile,
    });
  }

  async function handleSubmit(validate = true) {
    if (formData.mobilePhone.length <= 6) {
      setValidateMobile(false);
      console.info("numero no valido");
      return;
    }
    try {
      if (
        formData.imageVideoInterview ||
        !validate ||
        formData.mobilePhone.length > 3
      ) {
        setSaving(true);
        let dataPost = {
          firstName: formData.name,
          lastName: formData.lastName1,
          surName: formData.lastName2,
          receiveNotifications: formData.receiveNotifications,
          acceptResponsibilities: true,
          birthday: "2000-01-01T00:00:00.000Z",
          phone: formData.mobilePhone,
          whatsAppContact: formData.mobilePhone,
          linkedInProfile: formData.linkedIn,
          facebookProfile: formData.facebook,
          twitterProfile: formData.twitter,
          instagramProfile: formData.instagram,
          pinterestProfile: formData.pinterest,
          youtubeProfile: formData.youtube,
          municipalityId: formData.municipality,
          professionTitle: formData.descriptiveTitleProfession,
          moreImportantWork: formData.mostImportantJobs,
          academicTrainingHigher: formData.higherLevelTraining,
          curiosities: formData.curiosities,
          socialCollaborations: formData.socialEnvironmentalCollaborations,
          acceptWhatsAppHired: formData.contactedWhatsApp,
          awards: formData.achievementsAwards,
          awardUrl: formData.awardUrl,
          socialMediaProfile: formData.socialMediaProfile,
        };
        console.log(dataPost);
        let response = await api.professional.addOrUpdate(dataPost);

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          if (validate) {
            console.log({ confirmationFirst });
            if (confirmationFirst !== null)
              history.push("/professional/workspace");
            else history.push("/professional/success-register");
          }
        }
        setSaving(false);
      } else {
        setValidateView(false);
      }
    } catch (err) {
      setSaving(false);
    }
  }

  const handleChange = (event) => {
    let data = Object.assign({}, formData);
    data[event.target.name] = event.target.value;
    setFormData(data);
  };

  const handleChangeSocialMedia = (event) => {
    let data = Object.assign({}, formData);

    let socialMedias = data.socialMediaProfile ? data.socialMediaProfile : [];
    let mediaIndex = socialMedias.findIndex(
      (x) => x.socialMediaName === event.target.name
    );

    if (mediaIndex >= 0) {
      socialMedias[mediaIndex].profile = event.target.value;
    } else {
      socialMedias.push({
        socialMediaName: event.target.name,
        profile: event.target.value,
      });
    }

    data.socialMediaProfile = socialMedias;
    setFormData(data);
  };

  const handleChangeCountry = (event) => {
    let data = Object.assign({}, formData);
    data[event.target.name] = event.target.value;
    setFormData(data);
    loadProvinces(event.target.value);
  };

  const handleChangeProvinces = (event) => {
    let data = Object.assign({}, formData);
    data[event.target.name] = event.target.value;
    setFormData(data);

    let province = provinces.find((x) => x.id === event.target.value);
    setMunicipalities(province ? province.municipalities : []);
  };

  const handleChangeChecked = (event) => {
    let data = Object.assign({}, formData);
    data[event.target.name] = event.target.checked;
    setFormData(data);
  };

  async function handleKeyDown(event) {
    if (event.key === "Enter" || event === true) {
      setSearching(true);
      let identifier = formData.cIFCompanyIndividualNIF;
      if (identifier) {
        let response = await api.enterprises.detailsByIdentifier(identifier);
        let enterprise = response.data;

        let infoCopy = Object.assign({}, formData);
        infoCopy.sector =
          enterprise && enterprise.sector ? enterprise.sector.id : "";
        infoCopy.enterpriseName = enterprise ? enterprise.name : "";
        infoCopy.enterpriseId = enterprise ? enterprise.id : "";
        infoCopy.logo = enterprise ? enterprise.logoUrl : null;

        infoCopy.descriptionActivity = enterprise.activityDescription;
        infoCopy.web = enterprise.webUrl;
        infoCopy.TalentManagementPolicies = enterprise.policyManagementTalent;
        infoCopy.awardsCertificatesCollaborations = enterprise.awards;
        setFormData(infoCopy);
        setIsCompanyManager(
          enterprise ? enterprise.isUserCompanyManager : true
        );
      }
      setSearching(false);
    }
  }

  function renderInformation(title) {
    return (
      <Grid container>
        <Grid item xs={11} sm={6}>
          <Typography
            className={`${classes.information} ${classes.colorBlack}`}
          >
            <Translate id={title} />
          </Typography>
        </Grid>
        <Grid item xs={12} className="pt-2">
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  function renderFileAddorment() {
    return (
      <InputAdornment position="start" className={classes.adornment}>
        <img
          className={classes.image}
          src={
            formData.logo
              ? `${formData.logo}?image=${Date.now()}`
              : DefaultImage
          }
        />
      </InputAdornment>
    );
  }

  function handleSelectLogo(event) {
    if (event.target.files.length) {
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      setSelectedLogo(formData);
    }
  }

  function deleteAccount() {
    api.users.disableAccount().then((response) => {
      if (response.status === 200) {
        setConfirmation(false);
        dispatch(actions.logoutUser());
        history.push("/");
      }
    });
  }

  function renderRequired() {
    return (
      <Typography className={classes.required} color="error">
        *
      </Typography>
    );
  }

  function renderLabel(translate, icon = false, required = true) {
    return (
      <div className="d-flex" style={{ width: "100%" }}>
        <Typography
          className={`${classes.rootSecondary} ${classes.ellipsis} ${
            icon ? classes.ellipsisMin : ""
          }`}
        >
          <Translate id={translate}></Translate>
        </Typography>
        {required && renderRequired()}
      </div>
    );
  }

  async function uploadCv(formDataParam) {
    let response = await api.professional.uploadCv(formDataParam);
    if (response.status === 200) {
      let infoCopy = Object.assign({}, formData);
      infoCopy.attachCV = response.data;
      setFormData(infoCopy);
    }
  }

  async function uploadReferences(formDataParam) {
    let response = await api.professional.uploadReference(formDataParam);
    if (response.status === 200) {
      let infoCopy = Object.assign({}, formData);
      infoCopy.attachValidatedReferences = response.data;
      setFormData(infoCopy);
    }
  }

  async function uploadAwards(formDataParam) {
    let response = await api.professional.uploadAwards(formDataParam);
    if (response.status === 200) {
      let infoCopy = Object.assign({}, formData);
      infoCopy.awardUrl = response.data;
      setFormData(infoCopy);
    }
  }

  async function checCompletedProfile() {
    let response = { data: false };
    if (loguedUser.isEmployer) {
      response = await api.employers.checkCompletedProfile();
    } else if (loguedUser.isProfessional) {
      response = await api.professional.checkCompletedProfile();
    }
    return response.data;
  }

  async function goToProfile() {
    let compltedProfile = await checCompletedProfile();
    if (compltedProfile) history.push("/professional/workspace");
    else history.push("/professional/edit-profile");
  }

  function renderBreadCrumb() {
    return (
      <React.Fragment>
        <BreadcrumbsItem to="/">
          <Translate id={"Titles.Start"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem to="/professional/workspace" onClick={goToProfile}>
          <Translate id={"Professional.ProfessionalZone"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem to="/professional/workspace/some">
          <Translate id={"Professional.EditProfile"} />
        </BreadcrumbsItem>
      </React.Fragment>
    );
  }

  function resolveImage(languageTest) {
    try {
      if (languageTest && languageTest.isoCode)
        return require(`../../images/professional/languages/${languageTest.isoCode}.png`);
      return null;
    } catch (e) {
      return null;
    }
  }

  function renderLanguageTest() {
    if (professionalInfo) {
      let completed = professionalInfo.languageTests.filter((x) => x.completed);
      let available = professionalInfo.languageTests.filter(
        (x) => !x.completed
      );

      return (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            className={screenSmDown ? "py-4 px-3" : "py-5 px-5"}
          >
            {renderInformation("Tests.LanguageTestsTitle")}
          </Grid>

          <Grid
            item
            xs={12}
            className={screenSmDown ? "py-4 px-3" : "pb-5 px-5"}
          >
            <Grid container spacing={4}>
              {completed.length ? (
                <Grid item className={classes.itemContainer}>
                  <Typography className={classes.captionLanguage}>
                    <Translate id={"Tests.Completed"} />
                  </Typography>
                  <div className={classes.boxLanguage}>
                    {completed.map((x, i) => {
                      return (
                        <div
                          className={classes.grid}
                          role="button"
                          onClick={() => {
                            handleSubmit(false).then((response) => {
                              history.push(`/speaking/viewer/${x.id}`);
                            });
                          }}
                        >
                          <img
                            alt={`Videospeaking ${x.name}`}
                            src={resolveImage(x)}
                            className={classes.img}
                          />
                          <Typography className={classes.languageName}>
                            {x.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              ) : (
                <Grid></Grid>
              )}

              {available.length ? (
                <Grid item className={classes.itemContainer}>
                  <Typography className={classes.captionLanguage}>
                    <Translate id={"Tests.Available"} />
                  </Typography>
                  <div className={classes.boxLanguage}>
                    {available.map((x, i) => {
                      return (
                        <div
                          className={`${classes.grid} ${classes.opacity}`}
                          role="button"
                          key={i}
                          onClick={() => {
                            handleSubmit(false).then((response) => {
                              history.push(`/speaking/tips/${x.id}`);
                            });
                          }}
                        >
                          <img src={resolveImage(x)} className={classes.img} />
                          <Typography className={classes.languageName}>
                            {x.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  function renderSocialMedias() {
    return formData && formData.socialMediaProfile ? (
      formData.socialMediaProfile.map((x, i) => {
        if (x.socialMediaName === "LinkedIn")
          return (
            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              className={
                i % 4 !== 0 && !screenXsOnly ? classes.paddingLarge : 0
              }
            >
              <Translate>
                {({ translate }) => {
                  return (
                    <div className={classes.socialLink}>
                      <TextValidator
                        className={classes.responsiveWidth}
                        label={x.socialMediaName}
                        onChange={handleChangeSocialMedia}
                        name={x.socialMediaName}
                        value={x.profile}
                        validators={[
                          "matchRegexp:https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,}",
                        ]}
                        placeholder={translate("Professional.IndicateYourLink")}
                        errorMessages={[translate("Fields.WebValidation")]}
                        InputLabelProps={{
                          shrink: true,
                          classes: { root: classes.rootSecondary },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.adornment2}
                            >
                              <img
                                src={x.imageUrl}
                                style={{ width: 36, height: 36 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  );
                }}
              </Translate>
            </Grid>
          );
        return null;
      })
    ) : (
      <div></div>
    );
  }

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      instantValidate={false}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={`${classes.paddingXs}`}
      >
        {renderBreadCrumb()}
        <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
          <Typography className={classes.title} color="textSecondary">
            <Translate id={"Professional.ProfessionalRegistrationData"} />
          </Typography>
          <Grid item xs={12} md={8}>
            <Typography className={classes.completeSuggest} color="primary">
              {" "}
              {
                <Translate id="Professional.ProfessionalRegistrationDataDescription" />
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
              <Grid
                container
                spacing={0}
                direction="row"
                justify={screenXsOnly ? "flex-start" : "flex-end"}
                alignItems="center"
              >
                <Grid item style={{ minWidth: 170, marginLeft: 16 }}>
                  <div className="d-flex text-right mb-2">
                    {renderRequired()}
                    <Typography className={classes.text} color="textSecondary">
                      <Translate id={"Employer.RequiredFields"} />
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Paper square elevation={0} className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                >
                  <Grid
                    item
                    xs={12}
                    className={screenSmDown ? "py-4 px-3" : "py-5 px-5"}
                  >
                    {renderInformation("Professional.SummaryYourProfile")}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    className={screenSmDown ? "px-3 mb-5" : "pl-5"}
                    style={screenSmDown ? {} : { paddingBottom: 40 }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ backgroundColor: "#F7F7F7", height: "100%" }}
                    >
                      <Grid
                        item
                        xs={11}
                        md={12}
                        className="justify-content-center text-center"
                        style={{ display: "grid", justifyItems: "center" }}
                      >
                        <Typography
                          className={`${classes.titleInterview} pt-4 pb-3`}
                          color="textSecondary"
                        >
                          {<Translate id="Titles.VideoInterview" />}
                        </Typography>
                        <LogoPreview videoUrl={formData.imageVideoInterview} />

                        <Grid item md={formData.imageVideoInterview ? 9 : 12}>
                          {formData.imageVideoInterview ? (
                            <div className="pt-2">
                              <NavLink
                                to={"/seen-interview/continuos/professional"}
                                onClick={async () => await handleSubmit(false)}
                              >
                                <div className="d-flex" role="button">
                                  <img
                                    src={ImageRecord}
                                    className={`${classes.recordImage} mr-3`}
                                  />
                                  <Typography
                                    className={classes.subTitleInterview}
                                    color="textSecondary"
                                  >
                                    {
                                      <Translate id="Professional.RecordViewVideo" />
                                    }
                                  </Typography>
                                </div>
                              </NavLink>
                              <NavLink
                                to={"/home-interview"}
                                onClick={async () => await handleSubmit(false)}
                              >
                                <div className="d-flex" role="button">
                                  <img
                                    src={ImageRecordOther}
                                    className={`${classes.recordImage} mr-3`}
                                  />
                                  <div className="d-flex">
                                    <Typography
                                      className={classes.subTitleInterview}
                                      color="textSecondary"
                                    >
                                      {
                                        <Translate id="Professional.RecordAnotherVideo" />
                                      }
                                    </Typography>
                                    {renderRequired()}
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          ) : (
                            <NavLink
                              to={"/home-interview"}
                              onClick={async () => await handleSubmit(false)}
                            >
                              <div className="d-flex pt-2" role="button">
                                <img
                                  src={ImageRecordOther}
                                  className={`${classes.recordImage} mr-3`}
                                />
                                <div className="d-flex">
                                  <Typography
                                    className={classes.subTitleInterview}
                                    color="textSecondary"
                                  >
                                    {
                                      <Translate id="Professional.RecordVideo" />
                                    }
                                  </Typography>
                                  {renderRequired()}
                                </div>
                              </div>
                            </NavLink>
                          )}

                          <NavLink to={"/interview"}>
                            <div className="d-flex pb-3" role="button">
                              <div className={`${classes.help} mr-3`}>
                                <Help />
                              </div>
                              <div className="d-flex">
                                <Typography
                                  className={classes.subTitleInterview}
                                  color="textSecondary"
                                >
                                  {
                                    <Translate id="Professional.RecordVideoHelp" />
                                  }
                                </Typography>
                              </div>
                            </div>
                          </NavLink>
                        </Grid>
                      </Grid>
                    </Grid>
                    {!validateView ? (
                      <FormHelperText error className="pt-2">
                        <Translate id={"Fields.Required"} />
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={9}
                    className={`${
                      screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"
                    }`}
                  >
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <TextValidator
                              type="text"
                              id="name"
                              className={classes.responsiveWidth}
                              label={renderLabel("Professional.Name")}
                              onChange={handleChange}
                              name="name"
                              value={formData.name}
                              validators={["required"]}
                              placeholder={translate("Professional.Name")}
                              errorMessages={[translate("Fields.Required")]}
                              InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.rootSecondary },
                              }}
                            />
                          </div>
                        );
                      }}
                    </Translate>
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <TextValidator
                              type="text"
                              id="lastName1"
                              className={classes.responsiveWidth}
                              label={renderLabel("Professional.LastName")}
                              onChange={handleChange}
                              name="lastName1"
                              value={formData.lastName1}
                              validators={["required"]}
                              placeholder={translate("Professional.Surname")}
                              errorMessages={[translate("Fields.Required")]}
                              InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.rootSecondary },
                              }}
                            />
                          </div>
                        );
                      }}
                    </Translate>
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <TextValidator
                              type="text"
                              id="lastName2"
                              className={classes.responsiveWidth}
                              label={renderLabel("Professional.LastName2")}
                              onChange={handleChange}
                              name="lastName2"
                              value={formData.lastName2 || ""}
                              validators={["required"]}
                              placeholder={translate(
                                "Professional.SecondSurname"
                              )}
                              errorMessages={[translate("Fields.Required")]}
                              InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.rootSecondary },
                              }}
                            />
                          </div>
                        );
                      }}
                    </Translate>
                    {/* <Translate>
                                        {({ translate }) => {
                                            return <div
                                                className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth} position-relative`}>
                                                <TextValidator
                                                    type="number"
                                                    id="yearBirth"
                                                    className={classes.responsiveWidth}
                                                    label={renderLabel("Professional.YearBirth")}
                                                    onChange={handleChange}
                                                    name="yearBirth"
                                                    value={formData.yearBirth}
                                                    validators={['required']}
                                                    placeholder={translate("Professional.IndicateWhenBorn")}
                                                    errorMessages={[translate("Fields.Required")]}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: { root: classes.rootSecondary }
                                                    }}
                                                    isNumber
                                                    helperText={validateYear ? false : translate("Professional.InvalidField")}
                                                    error={!validateYear}
                                                />
                                                <Tooltip classes={{ tooltip: classes.toolTip2 }}
                                                    title={<Translate
                                                        id={screenXsOnly ? "Professional.DescriptiveYearBirthMovil" : "Professional.DescriptiveYearBirth"} />}
                                                    placement="bottom"
                                                    role="button"
                                                    disableTouchListener={screenXsOnly}>
                                                    <Icon className={`${classes.colorBlack} ${classes.iconInfo}`}
                                                        onClick={handleTooltipOpenYearBirth}>
                                                        <InfoIcon />
                                                    </Icon>
                                                </Tooltip>
                                            </div>
                                        }}
                                    </Translate> */}
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <FormLabel
                              className={`${classes.rootSecondary} ${classes.ellipsis}`}
                            >
                              {renderLabel("Professional.MobilePhone")}
                            </FormLabel>
                            <PhoneInput
                              country={"es"}
                              name="mobilePhone"
                              value={formData.mobilePhone}
                              onChange={(v) =>
                                setFormData({ ...formData, mobilePhone: v })
                              }
                              localization={es}
                              containerStyle={{
                                backgroundColor: "#f7f7f7",
                                width: "100%",
                              }}
                              inputStyle={{
                                backgroundColor: "#f7f7f7",
                                overflow: "hidden",
                                fontSize: 15,
                                family: "Roboto",
                                borderWidth: 0,
                                width: "100%",
                                outlineWidth: 0,
                              }}
                            />
                            {!validateMobile ? (
                              <FormHelperText error className="pt-2">
                                <Translate id={"Fields.Required"} />
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }}
                    </Translate>
                    {/*<Translate>
                                        {({ translate }) => {
                                            return <div
                                                className={`${screenSmDown ? "pb-4 mb-5" : "pb-5 ml-5 mt-4"} ${classes.checkboxBackground} ${classes.responsiveWidth} ${classes.divWidth} justify-content-center align-items-end`}
                                                style={{ display: "grid" }}>
                                                <FormControlLabel className="mt-1"
                                                    control={
                                                        <Checkbox
                                                            checked={formData.contactedWhatsApp}
                                                            onChange={handleChangeChecked}
                                                            name="contactedWhatsApp"
                                                            color="default"
                                                        />
                                                    }
                                                    label={translate("Professional.ContactedWhatsApp")}
                                                />
                                            </div>
                                        }}
                                    </Translate> */}
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <TextValidator
                              className={classes.responsiveWidth}
                              label={renderLabel(
                                "Professional.ProfessionalRegistrationEmail"
                              )}
                              onChange={handleChange}
                              name="professionalRegistrationEmail"
                              value={formData.professionalRegistrationEmail}
                              validators={["required", "isEmail"]}
                              placeholder={translate(
                                "Professional.ProfessionalRegistrationEmail"
                              )}
                              errorMessages={[
                                translate("Fields.Required"),
                                translate("Fields.EmailValidation"),
                              ]}
                              InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.rootSecondaryFull },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className={classes.adornment}
                                  >
                                    <IconButton disabled>
                                      <LockIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                            />
                          </div>
                        );
                      }}
                    </Translate>

                    <Hidden xsUp xsDown>
                      <Translate>
                        {({ translate }) => {
                          return (
                            <div
                              className={`${
                                screenSmDown ? "mb-4" : "mb-4 ml-5"
                              } ${classes.divWidth}`}
                            >
                              <TextValidator
                                className={classes.responsiveWidth}
                                type={typePass ? "password" : "text"}
                                label={renderLabel("Employer.Password")}
                                debounceTime={1500}
                                name="password"
                                value={formData.password}
                                placeholder={translate("Employer.Password")}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                  classes: { root: classes.rootSecondaryFull },
                                }}
                                disabled
                              />
                            </div>
                          );
                        }}
                      </Translate>
                    </Hidden>
                    {/* 
                                    <Grid
                                        className={`${screenSmDown ? "mb-5" : "mb-4 ml-5 pt-3"} ${classes.divWidth}`}>
                                        <NavLink to="/professional/selfchange-password">
                                            <Typography color="primary" style={{ fontWeight: "bold" }}
                                                className="mt-3"><Link /> <Translate
                                                    id="Employer.ChangePassword" /></Typography>
                                        </NavLink>
                                        <Typography variant={"body2"} color={"textSecondary"} style={{ fontSize: 15 }}>
                                            <Translate id={"Employer.SaveChangesBefore"} />
                                        </Typography>
                                    </Grid>

                                   
                                    <Translate>
                                        {({ translate }) => {
                                            return <div
                                                className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth}`}>
                                                <TextValidator
                                                    className={classes.responsiveWidth}
                                                    label={renderLabel("Employer.RegistrationDate")}
                                                    onChange={handleChange}
                                                    name="registrationDate"
                                                    value={formData.registrationDate}
                                                    placeholder={translate("Employer.RegistrationDate")}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (<InputAdornment position="end"
                                                            className={classes.adornment}>
                                                            <IconButton disabled>
                                                                <LockIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                        )
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                        }}
                                    </Translate>
                                    */}
                    <SelectInputField
                      screenSmDown={screenSmDown}
                      classes={classes}
                      renderLabel={renderLabel}
                      handleChange={handleChangeCountry}
                      label="Professional.Country"
                      name="country"
                      value={formData.country}
                      required
                      options={countries.map((country) => {
                        return (
                          <MenuItem value={country.id}>{country.name}</MenuItem>
                        );
                      })}
                    />
                    <SelectInputField
                      screenSmDown={screenSmDown}
                      classes={classes}
                      renderLabel={renderLabel}
                      handleChange={handleChangeProvinces}
                      label="Professional.Province"
                      name="province"
                      value={formData.province}
                      required
                      options={provinces.map((province) => {
                        return (
                          <MenuItem value={province.id}>
                            {province.name}
                          </MenuItem>
                        );
                      })}
                      disabled={formData.country === ""}
                    />
                    <SelectInputField
                      screenSmDown={screenSmDown}
                      classes={classes}
                      renderLabel={renderLabel}
                      disabled={formData.province === ""}
                      handleChange={handleChange}
                      label="Professional.Municipality"
                      name="municipality"
                      value={formData.municipality}
                      required
                      options={municipalities.map((municipality) => {
                        return (
                          <MenuItem value={municipality.id}>
                            {municipality.name}
                          </MenuItem>
                        );
                      })}
                    />
                    <Translate>
                      {({ translate }) => {
                        return (
                          <div
                            className={`${
                              screenSmDown ? "mb-4" : "mb-4 ml-5"
                            } ${classes.divWidth}`}
                          >
                            <TextValidator
                              type="text"
                              id="descriptiveTitleProfession"
                              className={classes.responsiveWidth}
                              label={renderLabel(
                                "Professional.DescriptiveTitleProfession",
                                true
                              )}
                              onChange={handleChange}
                              name="descriptiveTitleProfession"
                              value={formData.descriptiveTitleProfession}
                              validators={["required"]}
                              placeholder={translate(
                                "Professional.DescriptiveTitleProfession"
                              )}
                              errorMessages={[translate("Fields.Required")]}
                              InputLabelProps={{
                                shrink: true,
                                classes: { root: classes.rootSecondary },
                              }}
                            />
                            <Tooltip
                              classes={{ tooltip: classes.toolTip }}
                              title={
                                <Translate id={"Professional.IfExperience"} />
                              }
                              placement="bottom"
                              role="button"
                            >
                              <Icon
                                className={`${classes.colorBlack} ${classes.iconInfo2}`}
                                onClick={handleTooltipOpenDescription}
                              >
                                <InfoIcon />
                              </Icon>
                            </Tooltip>
                          </div>
                        );
                      }}
                    </Translate>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={screenSmDown ? "pb-4 px-3" : "pb-5 px-5"}
                  >
                    {renderInformation("Professional.OtherData")}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={3}
                        className={`${screenSmDown ? "px-3 mb-4" : "pl-5"} `}
                      >
                        {formData.attachCV ? (
                          <Translate>
                            {({ translate }) => {
                              return (
                                <div className="position-relative">
                                  <div className="d-flex">
                                    <FormLabel
                                      className={`${classes.rootSecondary} ${classes.ellipsis}`}
                                    >
                                      {translate("Professional.Profile.Cv")}
                                    </FormLabel>
                                    {renderRequired()}
                                    <div
                                      className={`${classes.colorBlack} ${classes.iconUpload}`}
                                    >
                                      <UploadFile
                                        id={"attachCVUpload"}
                                        uploadCallback={uploadCv}
                                      />
                                    </div>
                                  </div>
                                  <div className={classes.divGrey}>
                                    <a
                                      href={api.professional.resolveFile(
                                        professionalInfo.id,
                                        formData.attachCV.split("/").pop()
                                      )}
                                      download
                                      className={classes.responsiveWidth}
                                      target="_blank"
                                    >
                                      {formData.attachCV.split("/").pop()}
                                    </a>
                                  </div>
                                </div>
                              );
                            }}
                          </Translate>
                        ) : (
                          <Translate>
                            {({ translate }) => {
                              return (
                                <div className="position-relative">
                                  <div className="d-flex">
                                    <TextValidator
                                      id="attachCV"
                                      className={classes.responsiveWidth}
                                      label={translate("Professional.AttachCV")}
                                      onChange={handleChange}
                                      name="attachCV"
                                      value={formData.attachCV}
                                      placeholder={translate(
                                        "Professional.AttachCV"
                                      )}
                                      validators={["required"]}
                                      errorMessages={[
                                        translate("Fields.Required"),
                                      ]}
                                      InputLabelProps={{
                                        shrink: true,
                                        classes: {
                                          root: classes.rootSecondary,
                                        },
                                      }}
                                      inputProps={{
                                        readonly: true,
                                        disabled: true,
                                      }}
                                      onClick={() => {
                                        document
                                          .getElementById("attachCVUpload")
                                          .click();
                                      }}
                                    />
                                    {renderRequired()}
                                    <div
                                      className={`${classes.colorBlack} ${classes.iconUpload}`}
                                    >
                                      <UploadFile
                                        id={"attachCVUpload"}
                                        uploadCallback={uploadCv}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Translate>
                        )}
                        {/* formData.attachValidatedReferences ?
                                                <Translate>
                                                    {({ translate }) => {
                                                        return <div className="position-relative mt-4">
                                                            <div>
                                                                <FormLabel
                                                                    className={`${classes.rootSecondary} ${classes.ellipsis}`}>
                                                                    {translate("Professional.Profile.References")}
                                                                </FormLabel>
                                                                <div
                                                                    className={`${classes.colorBlack} ${classes.iconUpload}`}>
                                                                    <UploadFile id={"attachValidatedReferencesUpload"}
                                                                        uploadCallback={uploadReferences} />
                                                                </div>
                                                            </div>
                                                            <div className={classes.divGrey}>
                                                                <a href={api.professional.resolveFile(professionalInfo.id, formData.attachValidatedReferences.split('/').pop())}
                                                                    download={formData.attachValidatedReferences.split('/').pop()}
                                                                    className={classes.responsiveWidth}
                                                                    target="_blank">
                                                                    {formData.attachValidatedReferences.split('/').pop()}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    }}
                                                </Translate> :
                                                <Translate>
                                                    {({ translate }) => {
                                                        return <div className="position-relative mt-4">
                                                            <TextValidator
                                                                id="attachValidatedReferences"
                                                                className={classes.responsiveWidth}
                                                                label={renderLabel("Professional.AttachValidatedReferences", true, false)}
                                                                onChange={handleChange}
                                                                name="attachValidatedReferences"
                                                                value={formData.attachValidatedReferences}
                                                                placeholder={translate("Professional.AttachValidatedReferences")}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    classes: { root: classes.rootSecondary }
                                                                }}
                                                                inputProps={{
                                                                    readonly: true,
                                                                    disabled: true
                                                                }}
                                                                onClick={() => {
                                                                    document.getElementById("attachValidatedReferencesUpload").click()
                                                                }}
                                                            />
                                                            <div
                                                                className={`${classes.colorBlack} ${classes.iconUpload}`}>
                                                                <UploadFile id={"attachValidatedReferencesUpload"}
                                                                    uploadCallback={uploadReferences} />
                                                            </div>
                                                        </div>
                                                    }}
                                                </Translate>
                                             */}
                      </Grid>
                      <Grid item xs={12} md={10} lg={9}>
                        <Grid
                          container
                          spacing={4}
                          className={screenSmDown ? "px-3" : "px-5"}
                        >
                          {renderSocialMedias()}
                        </Grid>
                      </Grid>
                      {/*  <Grid item xs={12} md={8} lg={9} 
                                            className={`${screenSmDown ? "px-3" : "pr-5"} d-flex flex-wrap`}>

                                            <Translate>
                                                {({ translate }) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth}`}>
                                                        <TextField
                                                            name="socialEnvironmentalCollaborations"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Professional.SocialEnvironmentalCollaborations", false, false)}
                                                            onChange={handleChange}
                                                            value={formData.socialEnvironmentalCollaborations}
                                                            placeholder={translate("Professional.IndicateWhichOnes")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { root: classes.rootSecondary }
                                                            }}
                                                            multiline
                                                            rows={8}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            {
                                                formData.awardUrl ?
                                                    <Translate>
                                                        {({ translate }) => {
                                                            return <div
                                                                className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth} position-relative`}>
                                                                <div>
                                                                    <FormLabel
                                                                        className={`${classes.rootSecondary} ${classes.ellipsis}`}>
                                                                        {translate("Professional.AchievementsAwards")}
                                                                    </FormLabel>
                                                                    <div
                                                                        className={`${classes.colorBlack} ${classes.iconUpload}`}>
                                                                        <UploadFile uploadCallback={uploadAwards} />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`${classes.responsiveWidth} ${classes.divGrey}`}
                                                                    style={{
                                                                        height: 188,
                                                                        alignItems: "start",
                                                                        paddingTop: 16
                                                                    }}>
                                                                    <a href={api.professional.resolveFile(professionalInfo.id, formData.awardUrl.split('/').pop())}
                                                                        className={classes.responsiveWidth}
                                                                        target="_blank">
                                                                        {formData.awardUrl.split('/').pop()}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        }}
                                                    </Translate> :
                                                    <Translate>
                                                        {({ translate }) => {
                                                            return <div
                                                                className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth} position-relative`}>
                                                                <TextField
                                                                    name="achievementsAwards"
                                                                    className={classes.responsiveWidth}
                                                                    label={translate("Professional.AchievementsAwards")}
                                                                    onChange={handleChange}
                                                                    value={formData.achievementsAwards}
                                                                    placeholder={translate("Professional.IndicateWhichAttachFile")}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        classes: { root: classes.rootSecondary }
                                                                    }}
                                                                    multiline
                                                                    rows={8}
                                                                />
                                                                <div
                                                                    className={`${classes.colorBlack} ${classes.iconUpload}`}>
                                                                    <UploadFile uploadCallback={uploadAwards} />
                                                                </div>
                                                            </div>
                                                        }}
                                                    </Translate>
                                            }
                                            <Translate>
                                                {({ translate }) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth}`}>
                                                        <TextField
                                                            name="curiosities"
                                                            className={classes.responsiveWidth}
                                                            label={translate("Professional.Curiosities")}
                                                            onChange={handleChange}
                                                            value={formData.curiosities}
                                                            placeholder={translate("Professional.IndicateRelevantCuriosityKnowProfile")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { root: classes.rootSecondary }
                                                            }}
                                                            multiline
                                                            rows={8}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>  */}
                    </Grid>
                  </Grid>
                  {/*
                                <Grid item xs={12}>
                                    <Grid container spacing={4} className={screenSmDown ? "px-3" : "px-5>
                                         <Grid item xs={12} md={6}>
                                            <Translate>
                                                {({ translate }) => {
                                                    return <div
                                                        className={`${screenSmDown ? "pb-4 mb-5" : "pb-5 ml-5 mt-4"} ${classes.divWidth3} justify-content-center align-items-end`}
                                                        style={{ display: "grid" }}>
                                                        <FormControlLabel
                                                            className="mt-1"
                                                            control={
                                                                <Checkbox
                                                                    checked={formData.receiveNotifications}
                                                                    onChange={handleChangeChecked}
                                                                    name="receiveNotifications"
                                                                    color="default"
                                                                />
                                                            }
                                                            label={translate("Professional.IReceivePromotionalCommercialNotifications")}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                    </Grid>
                                </Grid>
                                        </Grid> */}
                  <Grid container spacing={1} item xs={12}>
                    {renderLanguageTest()}
                  </Grid>
                  <Grid container spacing={1} item xs={12}>
                    <Grid
                      container
                      direction="row-reverse"
                      justifycontent="space-between"
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={`${classes.submit} m-4`}
                        disabled={saving || loading}
                      >
                        {saving && (
                          <CircularProgress
                            size={30}
                            className={"mr-2 " + classes.colorWhite}
                          />
                        )}
                        <Typography className={`${classes.submit}`}>
                          <Translate id={"Buttons.Accept"} />
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11}
          className={`justify-content-center ${classes.butonContainer} ${
            screenSmDown ? "pb-5 pt-1" : "py-5"
          }`}
        >
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.deleteAccount}`}
            onClick={() => setConfirmation(true)}
          >
            <Typography variant="inherit" style={{ fontWeight: "bold" }}>
              <Translate id={"Buttons.DeleteAccount"} />
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ConfirmDialog
            id="end-record"
            open={confirmation}
            title={<Translate id={"Employer.SelectedDeleteYourProfessional"} />}
            question={<Translate id={"Employer.DeleteAccountMessage"} />}
            question2={
              <Translate id={"Employer.DeleteProfessionalAccountTitle"} />
            }
            handleCancel={() => setConfirmation(false)}
            handleAccept={deleteAccount}
          />
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

const SelectInputField = ({
  screenSmDown,
  classes,
  renderLabel,
  handleChange,
  label,
  name,
  value,
  required,
  options,
  disabled,
}) => {
  return (
    <Translate>
      {({ translate }) => {
        return (
          <div
            className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${
              classes.divWidth
            }`}
          >
            <Typography id={"Employer.RequiredFields"} />
            <TextValidator
              className={classes.responsiveWidth}
              label={renderLabel(label)}
              onChange={handleChange}
              name={name}
              value={value}
              validators={required ? ["required"] : null}
              placeholder={translate(label)}
              errorMessages={[translate("Fields.Required")]}
              InputLabelProps={{
                shrink: true,
                classes: { root: classes.rootSecondary },
              }}
              select
              disabled={disabled ? disabled : null}
            >
              {options ? options : null}
            </TextValidator>
          </div>
        );
      }}
    </Translate>
  );
};
