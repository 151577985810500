import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Grid} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import {withLocalize} from "react-localize-redux";

const styles = theme => ({
    circle: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        width: 218,
        height: 218,
        borderRadius: 150,
        opacity: 0.78,
          [theme.breakpoints.down('sm')]: {
            width: 150,
            height: 150,
          },
    },
  center: {
        textAlign: "center",
      height: 205,
      minHeight: 205,
        [theme.breakpoints.down('sm')]: {
          height: 142,
          minHeight: 142
        },
    }
});

const Root = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Menlo, monospace;
  font-size: 100px;
  
`

class Countdown extends Component {
    static propTypes = {
        countdownTime: PropTypes.number
    }

    constructor(props) {
        super(props)

        this.state = {
            number: props.countdownTime / 1000
        }
    }

    componentDidMount() {
        this.timeout = setTimeout(this.updateNumber, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    updateNumber = () => {
        const nextNumber = this.state.number - 1
        this.setState({
            number: nextNumber
        })
        if (nextNumber !== 0) {
            this.timeout = setTimeout(this.updateNumber, 1000)
        }
    }

    render() {
        let {classes} = this.props;
        return <Root>
            <div className={classes.circle}>
                <Grid container direction="row-reverse" justify="center" alignItems="center"
                      className={classes.center}>
                    <Grid item xs={6}>
                        <span style={{
                            fontSize: 96,
                            color: "#FB060F",
                            fontWeight: "bold"
                        }}>{this.state.number !== 0 ? this.state.number : null}</span>
                    </Grid>
                </Grid>
            </div>
        </Root>
    }
}


export default withStyles(styles)(Countdown);