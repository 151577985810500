import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slider, Typography } from "@material-ui/core";
import { Lens, PlayArrow, Pause, VolumeUp, VolumeOff, Fullscreen } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: 76,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.only('xl')]: {
            //marginTop: 92,
            minHeight: 74,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 50,
        }
    }
}));

const sliderStyle = makeStyles((theme) => {
    return {
        root: {
            height: 5,
            padding: '13px 0',
        },
        thumb: {
            height: 20,
            width: 20,
            backgroundColor: theme.palette.common.white,
            border: '0px solid currentColor',
            marginTop: -8,
            marginLeft: -13,
            '& .bar': {
                height: 9,
                width: 1,
                backgroundColor: 'currentColor',
                marginLeft: 1,
                marginRight: 1,
            },
        },
        active: {},
        track: {
            color: theme.palette.common.white,
            height: 5,
        },
        rail: {
            height: 5,
            color: theme.palette.secondary.main,
            opacity: 1
        },
        valueLabel: {
            top: -20,
            position: 'absolute',
            fontSize: 18,
            fontWeight: 500,
            color: "transparent",
            left: -7,
            [theme.breakpoints.down('sm')]: {
                top: -13,
                fontSize: 10,
                left: "-31%"
            },
        }
    };
});

const sliderVolumeStyle = makeStyles((theme) => {
    return {
        root: {
            height: 5,
            padding: '13px 0',
        },
        thumb: {
            height: 20,
            width: 20,
            backgroundColor: theme.palette.secondary.main,
            border: '0px solid currentColor',
            marginTop: -8,
            marginLeft: -13,
            '& .bar': {
                height: 9,
                width: 1,
                backgroundColor: 'currentColor',
                marginLeft: 1,
                marginRight: 1,
            },
        },
        active: {},
        track: {
            color: theme.palette.secondary.main,
            height: 5,
        },
        rail: {
            height: 5,
            color: theme.palette.secondary.main,
            opacity: 1
        }
    };
});

const customStyles = makeStyles((theme) => ({
    segment: {
        width: 7,
        backgroundColor: theme.palette.secondary.main,
        height: 16
    },
    text: {
        marginLeft: 10,
        marginTop: 3,
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: 26,
        fontWeight: 'bold',
        cursor: "pointer",
        zIndex: 999
    },
    iconFull: {
        marginLeft: 4,
        color: theme.palette.common.white,
        fontSize: 34,
        fontWeight: 'bold',
        cursor: "pointer",
        zIndex: 999
    },
    textTop: {
        top: -24,
        position: 'absolute',
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            top: -13,
            fontSize: 10,
        },
    },
    textRight: {
        top: -30,
        position: 'absolute',
        color: '#FFFFFF',
        fontSize: 20,
        fontWeight: 500,
        height: 28,
        width: 53,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    }


}));

export default function VideoControls(props) {
    const classes = useStyles();

    return (<div className={classes.container}>
        <Grid container spacing={2} className="mx-0">
            <Grid item xs={8} className="align-items-center d-flex pr-0">
                <Grid item xs={11}>
                    <TimerPalyerIndicator value={props.value ? parseInt(props.value) : 0} maxValue={props.maxValue}
                        handlePlay={props.handlePlay}
                        handlePause={props.handlePause}
                        paused={props.paused}
                        handleChange={props.handleChange} />
                </Grid>
            </Grid>
            <Grid item xs={4} className="pl-0">
                <VolumenIndicator volumen={props.volumen}
                    handleChangeVolumen={props.handleChangeVolumen}
                    openFullscreen={props.openFullscreen} />
            </Grid>
        </Grid>
    </div>)
}

function TimerPalyerIndicator(cProps) {
    let { value, maxValue, paused, handlePlay, handlePause } = cProps;
    const classes = sliderStyle();
    const customClasses = customStyles();

    function valueLabelFormat(value) {
        let minutes = parseInt(value / 60);
        let seconds = value % 60;
        return `0${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
    }

    const handleChange = (event, newValue) => {
        if (cProps.handleChange) cProps.handleChange(newValue);
    };

    function ThumbComponent(props) {
        return (
            <span {...props} id="icons-container">
                {cProps.value > 0 ?
                    <span className={customClasses.textTop}>
                        {valueLabelFormat(value)}
                    </span> : <div className={customClasses.textRight} />}
                <Lens fontSize="small" style={{ color: 'white' }} />
            </span>
        );
    }
    return (
        <div className="align-items-center d-flex">
            {paused
                ? <PlayArrow fontSize="large" className={customClasses.icon} onClick={() => {
                    if (handlePlay) handlePlay();
                }} />
                : <Pause fontSize="large" className={customClasses.icon} onClick={() => {
                    if (handlePause) handlePause();
                }} />}
            <Slider classes={classes} style={{ marginLeft: 15 }}
                //ThumbComponent={ThumbComponent}
                defaultValue={value}
                value={value}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="on"
                min={0}
                max={maxValue}
                onChange={handleChange}
            />
            <span className={customClasses.segment}></span>
            <Typography className={customClasses.text}>{valueLabelFormat(maxValue)}</Typography>
        </div>);
}


function VolumenIndicator(cProps) {
    let { volumen, openFullscreen } = cProps;
    const classes = sliderVolumeStyle();
    const customClasses = customStyles();

    const handleChange = (event, newValue) => {
        if (cProps.handleChangeVolumen) cProps.handleChangeVolumen(newValue / 10);
    };

    function ThumbComponent(props) {
        return (
            <span {...props} id="icons-container">
                <Lens fontSize="small" color="secondary" />
            </span>
        );
    }
    return (
        <div className="align-items-center d-flex">
            {volumen === 0
                ? <VolumeOff className={customClasses.icon} onClick={() => cProps.handleChangeVolumen(1)} />
                : <VolumeUp className={customClasses.icon} onClick={() => cProps.handleChangeVolumen(0)} />}
            <Slider classes={classes} style={{ marginLeft: 10 }}
                ThumbComponent={ThumbComponent}
                defaultValue={volumen}
                value={volumen}
                min={0}
                max={10}
                onChange={(event, v) => handleChange(event, v)}
            />
            <span className={customClasses.segment}></span>
            <Fullscreen className={customClasses.iconFull} onClick={openFullscreen} />
        </div>);
}