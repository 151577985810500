import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {Typography, Grid, Paper, useMediaQuery, Button, IconButton} from '@material-ui/core';
import {NavLink} from "react-router-dom";
import {ClickAwayListener, Grow, Popper, MenuItem, MenuList, Container} from '@material-ui/core';
import {MoreHoriz} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        right: 0,
        top: 0,
        [theme.breakpoints.only('xs')]: {
            position: "inherit",
            marginLeft: 8
        }
    },
    menuCircle: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 50,
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.only('xs')]: {
            width: 30,
            height: 30,
        },
    },
    nonPadding: {
        padding: 0
    },
    option: {
        fontSize: 16,
        fontWeight: 400,
        paddingTop: 4,
        paddingBottom: 4
    },
}))

function QuestionTypeMenu(props) {
    const classes = useStyles();
    const theme = useTheme();
    let {open, setOpen, handleOnClickMenu, index} = props;

    const prevOpen = React.useRef(open);
    const anchorRef = React.useRef(null);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open])

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    return (<div className={classes.menu}>
        <div>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.buttonMenu}>
                <ExpandMoreIcon color="primary"/>
            </IconButton>
        </div>
        <Popper style={{zIndex: 99}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', zIndex: 9999}}>
                    <Paper elevation={3} square>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow"
                                      onKeyDown={handleListKeyDown} style={{paddingTop: 0}}>
                                <MenuItem onClick={() => {
                                    handleOnClickMenu(index, true);
                                    setOpen(false)
                                }} className={classes.menuItem}>
                                    <Translate id={"Offers.QuestionYesNo"}/>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleOnClickMenu(index, false);
                                    setOpen(false)
                                }} className={classes.menuItem}>
                                    <Translate id={"Offers.FreeQuestion"}/>
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>);
}

export default withRouter(QuestionTypeMenu);