import React, {useEffect, useState, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import * as interviewActions from "../../actions/interview/index";
import {NavLink, useHistory, withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {Grid, Typography, Hidden, Paper} from "@material-ui/core";
import {useMediaQuery} from "@material-ui/core";
import VideoControls from './videoControls';
import QuestionStepper from "./run/questionStepper";
import * as constants from '../../utils/constants';
import {isIOS} from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        [theme.breakpoints.up('md')]: {
            width: 275
        },
        [theme.breakpoints.down('sm')]: {
            width: 194,
        },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        margin: 'auto'
    },
    itemSpacing: {
        marginTop: "1rem",
        marginBottom: "1rem",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
    },
    greyXs: {},
    questions: {
        marginTop: 33,
        minHeight: 117,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            minHeight: 73,
            paddingBottom: "0 !important"
        },
        [theme.breakpoints.only('lg')]: {
            minHeight: window.innerHeight >= 638 && window.innerHeight <= 720 ? 100 : 117,
        },
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 26,
        right: 26,
        minWidth: 63,
        width: 63,
        height: 63
    },
    icon: {
        height: 63,
        width: 63
    },
    titleInterView: {
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            marginLeft: 59
        },
    },
    paddingRightResponse: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "152px !important"
        }
    },
    video: {
        [theme.breakpoints.up('md')]: {
            margin: "auto",
        },
    },
    listItem: {
        [theme.breakpoints.down('sm')]: {
            width: 194,
            height: 28,
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 37,
        },
        height: 37,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 19,
        opacity: 1,
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            borderColor: theme.palette.primary.main
        },

    },
    listItemActive: {
        [theme.breakpoints.down('sm')]: {
            width: 194,
            height: 28,
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 37,
        },
        height: 37,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 19,
        opacity: 1,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",

        },

    },
    player: {
        "transform": "rotateY(180deg)",
        "-webkit-transform": "rotateY(180deg)",
        "-moz-transform": "rotateY(180deg)",
        maxHeight: "80vh"
    },
    paddingLeftResponse: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "3rem !important"
        },
    },
    topResponse: {
        [theme.breakpoints.up('md')]: {
            marginTop: "3rem !important"
        },
    },
    px3: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
            paddingRight: 5
        },
    },
    recorderMargins: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    },
    questionView: {
        ...theme.overrides.MuiTypography.questionView,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.questionView.fontSize
        },
    },
    top: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "0.5rem"
        },
    },
    centerXs: {
        textAlign: "center",
        [theme.breakpoints.up('xl')]: {
            paddingRight: "3rem !important",
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    heightMaxTime: {
        height: 61,
        [theme.breakpoints.only('xs')]: {
            height: 10
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 46 : 61,
        },
        [theme.breakpoints.up('xs')]: {
            // paddingBottom: "5px !important",
            //marginBottom: "5px !important",
        },
    },
    instructions: {
        ...theme.overrides.MuiTypography.question3,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        lineHeight: 1.1,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.question3.fontSize
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "0 !important",
            marginBottom: 0,

        },
    },
    textApplication: {
        fontSize: 30,
        fontWeight: 700,
        lineHeight: 1,
        [theme.breakpoints.only('lg')]: {
            fontSize: 26
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 22
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 21
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        },
    },
    paper: {
        borderRadius: 45,
        padding: 20,
        boxShadow: "0px 0px 15px #0000001A",
        marginTop: 25,
        [theme.breakpoints.down('lg')]: {
            padding: 14,
            marginTop: 10
        },
        [theme.breakpoints.only('xs')]: {
            padding: 14,
            marginTop: 10,
            borderRadius: 15
        },
    },
}));

function SeeInterViewDevice(props) {
    let classes = useStyles();
    let dispatch = useDispatch();
    let history = useHistory();
    const videoPalyer = useRef(null);
    let {questions, isLocalVideo, videoSegments, poster, application} = props;
    const type = props.match.params.usertype;

    const [sources, setSources] = useState([]);
    const [currentSource, setCurrentSources] = useState(null);
    const [indexActiveQuestion, setIndexActiveQuestion] = React.useState(-1);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [maxDuration, setMaxDuration] = useState(0);
    const [volumen, setVolumen] = React.useState(10);
    const [active, setActive] = React.useState(false);
    const [showPoster, setShowPoster] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [videoHeight, setVideoHeight] = useState(0);

    let [playing, setPlaying] = useState(false);

    //const [heightVideo, setHeightVideo] = React.useState(0);

    useEffect(() => {
        initPosterViewer();
        defineActionHeader();
        initVideoSources();
        initVideoPlayer();
        loadDuration();
    }, [poster]);

    function initPosterViewer() {
        if (showPoster === null) {
            if (poster) {
                if (isLocalVideo && isIOS) setShowPoster(true);
                else setShowPoster(false);

                if (!posterUrl) {
                    setPosterUrl(URL.createObjectURL(poster))
                }
            }
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "back",

            callBack: type ? () => {
                history.push("/professional/edit-profile")
            } : handleBack
        }))
    }

    function initVideoSources() {
        let videoSources = questions.map(x => `${x.videoUrl}#t=0.001`);
        setSources(videoSources);
    }

    function loadDuration() {
        if (questions) {
            let total = questions.map(x => x.duration).reduce((accumulator, currentValue) => accumulator + currentValue)
            setMaxDuration(total);
        }
    }

    function getPreviewDuration(index) {
        if (index === 0) return 0;
        let prevDuration = questions.slice(0, index).map(x => x.duration).reduce((accumulator, currentValue) => accumulator + currentValue);
        return prevDuration;
    }

    function initVideoPlayer() {
        if (videoPalyer && videoPalyer.current) {
            videoPalyer.current.addEventListener('ended', function (e) {
                setPlaying(false);
                setActive(false);
                if (this["onlyone"]) {
                    this["onlyone"] = false;
                    return;
                }
                let dataIndex = this["dataIndex"];
                let currentIndex = parseInt(dataIndex ? dataIndex : "0");
                let nextIndex = currentIndex + 1;

                if (questions.length - 1 === currentIndex) return;
                if (this.ended) {
                    this["dataIndex"] = nextIndex;
                    this.src = getVideoUrl(questions[nextIndex].videoUrl, nextIndex);
                    this.play();
                }
            });

            videoPalyer.current.addEventListener('play', function (e) {
                setActive(true);
                setPlaying(true);
                setShowPoster(false);
                let dataIndex = this["dataIndex"];
                let index = dataIndex >= 0 ? parseInt(dataIndex) : 0;
                setIndexActiveQuestion(index);
            });

            videoPalyer.current.addEventListener('timeupdate', function (v) {
                if (this["setter"] === true) {
                    let realTime = parseInt(this["realTime"]);
                    this.currentTime = realTime;
                    this["setter"] = false;
                }

                setActive(true);
                let dataIndex = this["dataIndex"];
                let index = dataIndex >= 0 ? parseInt(dataIndex) : 0;
                let prevDuration = getPreviewDuration(index);

                setCurrentTime(prevDuration + this.currentTime);

                let dataHeight = this["dataHeight"] ? this["dataHeight"] : 0;
                if (videoPalyer && videoPalyer.current && videoPalyer.current.clientHeight > dataHeight) {
                    this["dataHeight"] = videoPalyer.current.clientHeight;
                    setVideoHeight(videoPalyer.current.clientHeight);
                }

                if (this["onlyone"] === true) {
                    let question = questions[index];
                    if (this.currentTime >= question.duration) {
                        this.pause();
                        this["onlyone"] = false;
                    }
                }
            });

            videoPalyer.current.addEventListener('pause', function (v) {
                setActive(false);
                setPlaying(false);
            });
        }
    }

    const handleCurrentQuestion = (index) => {
        setIndexActiveQuestion(index);
        setRepleyTime(index);
    }

    function getRealTime(newValue) {
        let indexQuestion = questions.findIndex(x => x.start <= newValue && x.end > newValue);
        let previewDuration = getPreviewDuration(indexQuestion);
        let realTime = newValue - previewDuration;
        return {indexQuestion, realTime};
    }

    const handleChangeCurrentTime = (newValue) => {
        let {indexQuestion, realTime} = getRealTime(newValue);
        if (newValue === maxDuration) {
            indexQuestion = questions.length - 1;
        }
        try {
            if (videoPalyer.current) {
                let video = document.getElementById("replay-interview"); //videoPalyer.current;
                video["dataIndex"] = indexQuestion;
                video["setter"] = true;
                video["realTime"] = realTime;
                video.src = getVideoUrl(questions[indexQuestion].videoUrl, indexQuestion);
                video.currentTime = realTime;
                setIndexActiveQuestion(indexQuestion);

                if (playing) video.play();
            }
        } catch (err) {
        }

        setCurrentTime(newValue);
    }

    const setRepleyTime = (index) => {
        let video = videoPalyer.current;
        video["dataIndex"] = index;
        video["onlyone"] = true;
        let question = questions[index];
        video.src = getVideoUrl(question.videoUrl, index);
        video.play();
    }

    function getVideoUrl(url, index) {
        if (url && url.indexOf(constants.digitalocean) > -1) return `${url}#t=0.001`;
        else {
            /*if (videoSegments && videoSegments.length > index) {
                URL.revokeObjectURL(url);
                return URL.createObjectURL(videoSegments[index]);
            }*/
            return url;
        }
    }

    function renderVideo() {
        return (<Grid item xs={12} sm={6} lg={7} md={7} id="recorder-container" className={classes.recorderMargins}>
            <video
                preload="auto"
                hidden={showPoster}
                style={{minHeight: heightVideo}}
                className={classes.player}
                id="replay-interview"
                playsInline={true} ref={videoPalyer}>
                {sources.map((x, index) => {
                    return (<source key={index} src={x} type="video/mp4"/>)
                })}
            </video>
            {showPoster === true && posterUrl &&
            <img alt={"poster"} className={`${classes.player} poster`} src={posterUrl}/>}
        </Grid>)
    }

    function openFullscreen() {
        let video = videoPalyer.current;
        setShowPoster(false);
        if (!video.src) {
            video.src = questions && questions.length ? getVideoUrl(questions[0].videoUrl, 0) : "";
        }
        video.play();

        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
            video.msRequestFullscreen();
        } else if (video.webkitEnterFullScreen) {
            video.webkitEnterFullScreen();
        } else if (video.webkitEnterFullscreen) {
            video.webkitEnterFullscreen();
        } else {
            alert("no full screen supported")
        }
    }

    const renderApplicationWidget = () => {
        let {professional, process} = application;
        return professional && process && <Grid item xs={12}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={9}>
                    <Typography color="secondary" className={classes.textApplication}>
                        {`${professional.firstName} ${professional.lastName} ${professional.surName}`}
                    </Typography>
                    <Paper elevation={4} className={classes.paper}>
                        <Typography color="textSecondary" className={classes.textApplication}><Translate
                            id={"Offers.Process"}/></Typography>
                        <Typography color="textSecondary"
                                    className={classes.textApplication}>{process.title}</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    }


    const {innerWidth: width, innerHeight: height} = window;
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenXsUp = useMediaQuery(theme.breakpoints.up('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const maxSmHeightVideo = 484;
    const iphoneHeight = 667;
    let heightVideo = videoPalyer && videoPalyer.current && videoPalyer.current.clientHeight ? videoPalyer.current.clientHeight : 0;
    return (<Grid container spacing={2} className={classes.px3}>
        <Grid item xs={12} sm={6} md={7} className={`py-0 ${classes.centerXs}`}>
            <Typography variant="h1">
                <Translate id={"Titles.VideoInterview"}/> <Typography variant="caption"></Typography>
            </Typography>
        </Grid>
        {renderVideo()}
        <Grid item xs={12} sm={6} lg={5} md={5} className={`text-center pl-2 ${classes.paddingLeftResponse}`}>
            <Grid container spacing={2}
                  style={{minHeight: screenXsDown ? 50 : height > 900 ? heightVideo * 0.70 : heightVideo * 0.80}}>
                {application && renderApplicationWidget()}

                <div style={screenXsDown
                    ? {
                        height: `calc(100vh - ${(heightVideo) + 100}px)`,
                        overflowY: "scroll",
                        width: "100%"
                    } : (screenSmOnly && height <= maxSmHeightVideo ? {
                        height: heightVideo - 73,
                        overflowY: "scroll",
                        width: "100%"
                    } : (screenXlOnly ? {paddingTop: 16, width: "100%"} : {width: "100%"}))}>
                    <Grid item xs={12} style={{minHeight: width > 600 ? heightVideo * (application ? 0.1 : 0.75) : "auto"}}>
                        <Grid item xs={12}
                              className={`${classes.heightMaxTime} text-center ${screenXsDown && height > iphoneHeight ? "py-2" : "py-0"}`}>
                            <div className={classes.heightMaxTime}/>
                        </Grid>
                        <QuestionStepper steps={questions ? questions : []}
                                         activeStep={indexActiveQuestion}
                                         showTimes={true}
                                         className={`${classes.instructions} ${screenXsDown && (height > iphoneHeight ? "mt-3" : "mt-2")}`}
                                         hiddenTooltips={true}
                                         onclickCallback={handleCurrentQuestion}/>
                    </Grid>

                    <Hidden smUp>
                        <Grid item xs={12}
                              className={`text-center ${screenSmDown ? "px-3" : "px-5"} ${screenXsDown && "py-2"} ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "py-4")}`}
                              style={screenXlOnly ? {paddingTop: 82} : {}}>
                            <VideoControls value={currentTime} volumen={volumen} maxValue={maxDuration}
                                           paused={videoPalyer && videoPalyer.current ? videoPalyer.current.paused : true}
                                           handlePlay={() => {
                                               if (currentTime >= maxDuration)
                                                   handleChangeCurrentTime(0);
                                               else
                                                   handleChangeCurrentTime(parseInt(currentTime));
                                               let video = videoPalyer.current;
                                               video.play()
                                           }}
                                           handlePause={() => {
                                               videoPalyer.current.pause()
                                           }}
                                           handleChange={handleChangeCurrentTime}
                                           handleChangeVolumen={(volumen) => {
                                               if (videoPalyer.current) {
                                                   videoPalyer.current.volume = volumen;
                                                   setVolumen(volumen * 10);
                                               }
                                           }}
                                           openFullscreen={openFullscreen}/>
                        </Grid>
                    </Hidden>
                </div>
            </Grid>

            <Grid container>
                <Hidden only="xs">
                    <Grid item xs={12}
                          className={`text-center ${screenSmDown ? "px-3" : "px-5"} ${screenXsDown && "py-2"} ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "py-4")}`}
                          style={screenXlOnly ? {paddingTop: 82} : {}}>
                        <VideoControls value={currentTime} volumen={volumen} maxValue={maxDuration}
                                       paused={videoPalyer && videoPalyer.current ? videoPalyer.current.paused : true}
                                       handlePlay={() => {
                                           if (currentTime >= maxDuration)
                                               handleChangeCurrentTime(0);
                                           else
                                               handleChangeCurrentTime(parseInt(currentTime));
                                           let video = videoPalyer.current;
                                           video.play()
                                       }}
                                       handlePause={() => {
                                           videoPalyer.current.pause()
                                       }}
                                       handleChange={handleChangeCurrentTime}
                                       handleChangeVolumen={(volumen) => {
                                           if (videoPalyer.current) {
                                               videoPalyer.current.volume = volumen;
                                               setVolumen(volumen * 10);
                                           }
                                       }}
                                       openFullscreen={openFullscreen}/>
                    </Grid>
                </Hidden>
            </Grid>
        </Grid>
    </Grid>)
}

export default withRouter(SeeInterViewDevice);