import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import {isIOS, isChrome} from "react-device-detect";
import {withRouter} from "react-router-dom";
import api from "../../../api/index";
import {Container, useMediaQuery} from "@material-ui/core";
import BannerInfo from "./bannerInfo";
import Info from './info';
import {staticContent} from "../../../utils/constants";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            minHeight: 115
        },
        center: {
            textAlign: "center",
            justifyContent: "space-around",
            margin: "auto",
            [theme.breakpoints.up('md')]: {
                display: "flex",
                minWidth: 600
            },

        },
        title1: {
            fontSize: 35,
            fontWeight: 500,
            letterSpacing: 0,
            marginRight: 15,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30,
                marginLeft: 0,
                marginTop: 0
            },
            lineHeight: 1,
        },
        button2: {
            width: 340,
            margin: 8,
            lineHeight: "1.1",
            maxWidth: "100%",
            minHeight: 48,
            padding: "5px 10px", height: 48, minWidth: 220,
            [theme.breakpoints.only('sm')]: {
                width: 300,
            },
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.only('xs')]: {
                display: "block",
            },
        },
        speakingTest: {
            display: "flex",
            flexWrap: "wrap",
            [theme.breakpoints.only('xs')]: {
                justifyContent: "center",
                marginTop: 15
            },
        },
        imgLanguage: {
            marginLeft: 10,
            marginRight: 10,
            width: 29,
            height: 29,
            [theme.breakpoints.only('xs')]: {
                marginLeft: 5,
                marginRight: 5
            }
        }
    }
});

function VideoSpeakingTips(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenMdDow = useMediaQuery(theme.breakpoints.down('md'));
    const id = props.match.params["test"] ? parseInt(props.match.params["test"]) : null;


    const [to, setTo] = useState(null);
    let [advices, setAdiveces] = useState(null);
    let [languagesTest, setLanguagesTest] = useState(null);

    let [questions, setQuestions] = useState(null);

    useEffect(() => {
        api.home.getContent(isIOS
            ? staticContent.TipsVideoSpeakingIphone
            : staticContent.TipsVideoSpeaking).then(response => {
            setAdiveces(response);
        });
        loadLanguagesTest();
        loadLanguageTest();
    }, [languagesTest])


    function loadLanguagesTest() {
        if (languagesTest === null)
            api.languageTest.all().then(response => {
                if (response.status === 200) {
                    setLanguagesTest(response.data);
                }
            })
    }

    function loadLanguageTest() {
        if (questions === null && id)
            api.languageTest.getLanguageTest(id).then(response => {
                if (response.status === 200) {
                    if (response.data.testQuestions) {
                        let result = response.data.testQuestions.map(x => x.questionText);
                        setQuestions(result);
                    }else {
                        setQuestions([])
                    }
                }
            })
    }

    function resolveImage(languageTest) {
        try {
            if (languageTest && languageTest.isoCode)
                return require(`../../../images/offer/languages/${languageTest.isoCode}.png`)
            return null;
        } catch (e) {
            return null;
        }
    }

    return (advices
        ? <Grid container spacing={0} style={screenMdDow ? {marginTop: 0} : {marginTop: 20}}>
            <Grid container direction="row" justify="center">
                <Grid item item xs={11} sm={9} md={7} className={`${classes.titleContainer} pb-4`}>
                    <Typography className={classes.title1} color="textSecondary">
                        {advices.Title}
                    </Typography>
                    <div className={classes.speakingTest}>
                        {languagesTest && languagesTest.map((x, i)=> {
                            return <img alt={x.name} className={classes.imgLanguage} src={resolveImage(x)}/>
                        })}
                    </div>
                </Grid>
            </Grid>

            <BannerInfo info={advices}/>

            <Info info={advices} test={id} questions={questions}/>
        </Grid>
        : <Grid></Grid>);
}

export default withRouter(VideoSpeakingTips);