import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Card, CardActionArea, Typography, Grid, Paper, useMediaQuery, Button} from '@material-ui/core';
import {Translate} from "react-localize-redux";
import {useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";
import {PaymentType} from "../../../utils/constants.js";


export default function PricingCard(props) {
    let {data, index, scope, plan} = props;

    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 380,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 10,
            }
        },
        media: {
            /*width: 120,
            height: 130,
            objectFit: "contain",*/
            [theme.breakpoints.down('sm')]: {
                width: 90,
                marginTop: 25,

            },
        },
        area: {
            paddingTop: 40,
            paddingBottom: 20,
            minHeight: 300,
            [theme.breakpoints.down('xs')]: {
                minHeight: scope === "professional" ? window.innerHeight < 640 ? 233 : 189 : 180,
                paddingTop: 0,
                paddingBottom: 0,
                display: "flex",
                alignItems: "flex-end",
            },
            position: "relative",
            [theme.breakpoints.only('sm')]: {
                display: "flex",
                alignItems: "flex-end",

            },
        },
        imageContainer: {
            textAlign: "center",
            [theme.breakpoints.only('sm')]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"

            },
        },
        text: {
            fontSize: 18,
            fontWeight: 600,
            paddingLeft: 28,
            '& a, a:hover': {
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textDecoration: "underline !important"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                textAlign: "left",
                paddingLeft: 12,
            },
            [theme.breakpoints.only('sm')]: {
                textAlign: "left",
                paddingLeft: 0,
            },
            lineHeight: 1.5
        },
        segment1: {
            width: 60,
            height: 5,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        segment2: {
            width: 178,
            height: 2,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        index: {
            position: "absolute",
            color: scope === "professional" ? "#DAEEF5" : "#FDEBDB",
            left: 0,
            top: 0,
            fontSize: 71,
            fontWeight: "bold",
            [theme.breakpoints.only('xs')]: {
                left: 0,
                top: scope === "professional" ? window.innerHeight < 640 ? 5 : -20 : -20
            },
            [theme.breakpoints.only('sm')]: {
                left: 0,
                top: 37
            },

        },
        cardContent: {
            padding: 16,
            paddingBottom: 0,
            alignItems: "flex-end",
            display: "flex",
            [theme.breakpoints.down('xs')]: {
                padding: 5,
                paddingBottom: 10,
                right: 0,
                bottom: 0
            }
        },
        segments: {
            paddingTop: 20,
            [theme.breakpoints.down('xs')]: {
                paddingTop: 10
            }
        },
        fullContainer: {
            paddingLeft: 40,
            paddingTop: 20,
            [theme.breakpoints.only('xs')]: {
                paddingLeft: 10,
                paddingTop: 40
            }
        },
        point: {
            fontSize: 16,
            fontWeight: 400,
        },
        pointContainer: {
            marginBottom: 20
        },
        pointsContainer: {
            marginTop: 44
        },
        priceLabel: {
            fontSize: 16,
            fontWeight: 600
        },
        separatorLarge: {
            width: 238,
            height: 2,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        button: {
            borderRadius: 0,
            minHeight: 40,
            height: 40,
            width: 190,
            minWidth: 190
        },
        buttonLabel: {
            fontSize: 18,
            fontWeight: 700,
        },
        buttonContainer: {
            marginTop: 40
        },
        banner: {
            [theme.breakpoints.only('xs')]: {
                marginLeft: 30
            }
        }
    }));
    let history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const screenSmDown = useMediaQuery(theme.breakpoints.down('lg'));
    let loguedUser = useSelector(state => state.users.loggedUser);

    function resolveImage() {
        if (screenSmDown)
            return require(`../../../images/layuout/pricing/xs-${index}.png`)
        else
            return require(`../../../images/layuout/pricing/${index}.png`)
    }

    return (
        <Paper className={classes.root} elevation={0}>
            <Paper elevation={0} type="" className={classes.area}>
                <Grid container spacing={0} className={classes.fullContainer}>
                    <Grid item md={12} xs={11} className={classes.banner}>
                        <Grid container spacing={4}>
                            <Grid item xs={4} md={4} className={classes.imageContainer}>
                                <img title={data.alt} alt={data.alt} src={resolveImage()}/>
                            </Grid>

                            <Grid item xs={8} md={8} className={classes.cardContent}>
                                <h3 className={classes.text}>
                                    {data.Title}
                                </h3>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.segments} container spacing={2}>
                        <Grid item xs={12} md={12} style={{display: "flex"}}>
                            <div className={classes.segment1}></div>
                            <div className={classes.segment2}></div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.pointsContainer}>
                        {data.Points.map((value, index) => {
                            return <div className={classes.pointContainer}>
                                <Typography className={classes.point} component={"span"}>
                                    <span dangerouslySetInnerHTML={{__html: value}}/>
                                </Typography>
                            </div>
                        })}
                    </Grid>

                    <Translate>
                        {({translate}) => {
                            return <Grid item xs={10}>
                                <div className={`${classes.separatorLarge} mb-1`}></div>
                                <Typography className={`${classes.priceLabel} mb-0`}>
                                    {data.Price && data.Price.replace("{var}", plan ? plan.price : 0)}
                                </Typography>

                                <React.Fragment>
                                    {data.HowMuchLabel
                                        ? <Typography className={`${classes.priceLabel} mb-0`}>
                                            {translate("Payments.Promotion").replace("{val}", plan ? plan.wholesaleUnits : 0)}
                                        </Typography>
                                        : <div></div>}

                                    {data.HowMuchPrice &&
                                    <Typography className={`${classes.priceLabel} mb-0`}>

                                        {data.HowMuchPrice && data.HowMuchPrice.replace("{var}", plan ? plan.wholesalePrice : 0)}
                                      </Typography>}
                                </React.Fragment>


                                <Grid className={`${classes.segments} pt-1`} container spacing={2}>
                                    <Grid item xs={12} md={12} style={{display: "flex"}}>
                                        <div className={classes.segment1}></div>
                                        <div className={classes.segment2}></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }}
                    </Translate>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        {loguedUser === null || (loguedUser && loguedUser.isEmployer)
                            ? <Button className={classes.button} variant="contained" color="secondary"
                                      type="button"
                                      onClick={() => {
                                          if (loguedUser === null) history.push(`/employer/login`)
                                          else {
                                              let payUrl = data.Code === PaymentType.CreateProcess
                                                  ? "/employer/process/create"
                                                  : data.Code === PaymentType.ReactivateProcess
                                                      ? "/employer/workspace/3"
                                                      : data.Code === PaymentType.VideoSpeakingTest
                                                          ? "/employer/workspace/2" : "";

                                              history.push(payUrl);
                                          }
                                      }}>
                                <Typography className={classes.buttonLabel} color="textSecondary">
                                    <Translate id="Buttons.Hire"/>
                                </Typography>
                            </Button>
                            : <div></div>}
                    </Grid>
                </Grid>

                <Typography className={classes.index}>{index}</Typography>
            </Paper>
        </Paper>
    );
}