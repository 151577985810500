/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { isIOS, isWindows, isMacOs, isAndroid, isChrome, isFirefox, isSafari, isEdge } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    imgResponsive: {
        width: "100%",
        height: "auto",
        maxWidth: 40
    },
    text: {
        fontSize: 20,
        lineHeight: 1,
        marginLeft: 5
    },
    box: {
        verticalAlign: "middle",
        alignItems: "center"
    },
    title: {
        fontSize: 22,
        fontWeight: 400,
        color: theme.palette.common.error,
        marginBottom: 20,
        textAlign: "center"
    }
}));

function UnsupportedBrowser(props) {
    let { active } = props.match.params;
    let classes = useStyles();
    React.useEffect(() => {

    }, [])



    function resolveBrowserImage(browser) {
        return browser === 1 ?
            require("../../../../images/support/browsers/chrome.jpg")
            : browser === 2
                ? require("../../../../images/support/browsers/firefox.jpg")
                : browser === 3
                    ? require("../../../../images/support/browsers/edge.jpg")
                    : browser === 4
                        ? require("../../../../images/support/browsers/safari.jpg")
                        : "";
    }

    function getOperatingSystem() {
        return isWindows ? 1
            : isMacOs ? 2
                : isAndroid ? 3
                    : isIOS ? 4 : null;
    }

    function renderBrowserSupport() {
        let os = getOperatingSystem();
        switch (os) {
            case 1:
                return <Grid item xs={12}>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(1)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.GoogleChrome" /></Typography>
                    </Grid>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(2)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.MozillFirefox" /></Typography>
                    </Grid>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(3)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.MicrosoftEdge" /></Typography>
                    </Grid>
                </Grid>

            case 4:
                return <Grid item xs={12}>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(1)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.GoogleChrome" /></Typography>
                    </Grid>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(2)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.MozillFirefox" /></Typography>
                    </Grid>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(4)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.Safari" /></Typography>
                    </Grid>
                </Grid>

            default:
                return <Grid item xs={12}>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(1)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.GoogleChrome" /></Typography>
                    </Grid>
                    <Grid item xs={12} className={`d-flex mb-2 ${classes.box}`}>
                        <img className={classes.imgResponsive} src={resolveBrowserImage(2)} />
                        <Typography className={classes.text} color="primary"><Translate id="Support.MozillFirefox" /></Typography>
                    </Grid>
                </Grid>
        }
    }

    return (<Grid container direction="row" justify="center" alignItems="center">
        <Typography className={classes.title} color="error"><Translate id="Support.Title" /></Typography>
        <Grid item xs={12} lg={9} md={10}>
            {renderBrowserSupport()}
        </Grid>
        <Typography className={classes.title} color="error"><Translate id="Errors.NoBrowserSupported" /></Typography>

    </Grid>)
}

export default withRouter(UnsupportedBrowser);