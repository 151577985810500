import { openDB } from 'idb';

const dbPromise = openDB('keyval-store', 1, {
    upgrade(db) {
        if(!window.indexedDB){
            Object.defineProperty(window, 'indexedDB', { value: (window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB) ,configurable:true,enumerable:true,writable:true });
        }
        if(window.indexedDB){
            db.createObjectStore('keyval');
        }else{
            alert("Not suppor INDEXED DB")
        }
    },
});

export const idbKeyval = {
    async get(key) {
        return (await dbPromise).get('keyval', key);
    },
    async set(key, val) {
        alert("SET: "+key)
        return (await dbPromise).put('keyval', val, key).then(response=> response).catch(e=> alert("Err"+JSON.stringify(e)));
    },
    async delete(key) {
        return (await dbPromise).delete('keyval', key);
    },
    async clear() {
        return (await dbPromise).clear('keyval');
    },
    async keys() {
        return (await dbPromise).getAllKeys('keyval');
    },
};

export async function get(key) {
    return (await dbPromise).get('keyval', key);
}
export async function set(key, val) {
    //TODO: EL PROBLEMA ESTA DADO QUE EN IOS NO DEVUELVE
    return (await dbPromise).put('keyval', val, key);
}
export async function del(key) {
    return (await dbPromise).delete('keyval', key);
}
export async function clear() {
    return (await dbPromise).clear('keyval');
}
export async function keys() {
    return (await dbPromise).getAllKeys('keyval');
}