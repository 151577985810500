import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {Button, Typography, CircularProgress} from "@material-ui/core";
import {NavLink, withRouter} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {ValidatorForm} from "react-material-ui-form-validator";
import api from "../../api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FooterCompose from "./footerCompose";
import * as types from "../../actions/errors";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: 25,
        fontWeight: 700
    },
    flex: {
        display: "flex",
        flexWrap: "wrap"
    },
    subject: {
        marginTop: 35,
        fontSize: 18,
        fontWeight: 400,
        marginRight: 6
    },
    subjectBold: {
        marginTop: 35,
        fontSize: 18,
        fontWeight: 700,
        textDecoration: "underline",
        zIndex: 999999
    },
    regards: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.2
    },
    editor: {
        paddingTop: 30,
        "& .ql-container": {
            height: 200,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15
        },
        "& .ql-toolbar": {
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15
        },
        "& p": {
            fontSize: 18,
            fontFamily: "Barlow"
        }
    },
    regardsContainer: {
        marginTop: 35
    }
}));


function ComposeEmail(props) {
    let classes = useStyles();
    let history = useHistory();
    let dispatch = useDispatch();

    const applicationId = props.match.params["application"] ? parseInt(props.match.params["application"]) : null;
    const professionalId = props.match.params["professional"] ? parseInt(props.match.params["professional"]) : null;

    let [application, setApplication] = useState(null)
    let [process, setProcess] = useState(null);
    let [professional, setProfessional] = useState(null);
    let [sending, setSending] = useState(false);

    let [body, setBody] = useState("");

    useEffect(() => {
        loadApplication();
    }, [])

    function loadApplication() {
        if (!application && applicationId)
            api.application.getApplication(applicationId).then(response => {
                if (response.status === 200) {
                    let {process, professional, answer} = response.data;
                    setApplication(response.data);
                    setProcess(process);
                    setProfessional(professional);
                }
            })
    }

    const modules = {
        toolbar: [
            [{'header': '1'}, {'header': '2'}],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
                {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    }

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    const handleChange = (value, html) => {
        setBody(value);
    }

    function composeEmail() {
        setSending(true);
        let bodyMessage = window.btoa(unescape(encodeURIComponent(body)));
        api.professional.contactProfessional(applicationId, bodyMessage).then(response => {
            if(response.status === 200){
                setBody("");
                showMessage(<Translate id="Compose.SendingOk"/>, "success")
                props.history.goBack();
            }else{
                showMessage(<Translate id="Compose.SendingWrong"/>, "error")
            }
            setSending(false);
        }).catch(error => {
            showMessage(<Translate id="Compose.SendingWrong"/>, "error")
            setSending(false)
        });
    }

    return (application && professional
        ? <Translate>
            {({translate}) => {
                return <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                    <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                        <Typography className={classes.name} color="textSecondary">
                            {`${translate("Compose.Hello")}, ${professional.firstName} ${professional.lastName} ${professional.surName}:`}
                        </Typography>

                        <div className={classes.flex}>
                            <Typography className={classes.subject} color="textSecondary">
                                {translate("Compose.Subject")}
                            </Typography>
                            <NavLink to={`/professional/${professionalId}/${applicationId}`} style={{zIndex: 999}}>
                                <Typography className={classes.subjectBold} color="textSecondary">
                                    {process.title}
                                </Typography>
                            </NavLink>
                        </div>

                        <Grid item md={8} xs={12} className={classes.editor}>
                            <ReactQuill
                                theme="snow" value={body} onChange={handleChange}
                                modules={modules}
                                formats={formats}
                                bounds={'.app'}
                                placeholder={translate("Compose.EditorPlaceholder")}/>
                        </Grid>

                        <Grid item xs={12} className={classes.regardsContainer}>
                            <Typography className={classes.regards} color="textSecondary">
                                {`${translate("Compose.Regards")},`}
                            </Typography>

                            <Typography className={classes.regards} color="textSecondary">
                                {process.enterpriseName}
                            </Typography>
                            <Typography className={`${classes.regards} mx-5`} color="textSecondary">&</Typography>
                            <Typography className={classes.regards} color="textSecondary">
                                {translate("Compose.HihiredTeam")}
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} className="mt-5">
                        <FooterCompose composeEmail={composeEmail} sending={sending}/>
                    </Grid>
                </Grid>
            }}
        </Translate>
        : <Grid></Grid>);
}

export default withRouter(ComposeEmail);