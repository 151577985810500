import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {
    Button,
    Typography,
    Hidden,
    IconButton,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    FormHelperText
} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import api from "../../../api/index";
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import {RemoveRedEyeOutlined, VisibilityOffOutlined} from '@material-ui/icons';
import {useDispatch} from "react-redux";

const {innerWidth: width} = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 45,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    titlesFunctions: {
        display: "flex",
        [theme.breakpoints.down('xs')]: {
            display: "grid",
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
        '& .MuiFormHelperText-root': {
            color: "#FF0000",
            fontSize: 14
        },
    },
    form: {
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },
    submit: {
        minWidth: 227,
        borderRadius: 0,
        marginTop: 20
    },
    buttomtext: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        fontSize: 18,
        letterSpacing: 0.7

    },
    recoverText: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    recoverTextBold: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    mainTitle: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginTop: 8
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 54,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    errorText: {
        fontSize: 19,
        marginTop: 20
    },
    buttons: {
        marginTop: 0
    },
    linkedinButton: {
        width: 227,
        height: 50,
        color: "#FFFFFF",
        backgroundColor: "#1569BF",
        cursor: 'pointer',
        position: "relative",
        alignItems: "center",
        display: "flex",
    },
    linkedinImage: {
        float: "left",
        paddingLeft: 8
    },
    linkedinText: {
        position: "absolute",
        top: 4,
        left: 68,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1.2,
        textAlign: "left",
        letterSpacing: 0.7
    },
    or: {
        fontSize: 20,
        fontWeight: "bold",
        paddingTop: 7,
        paddingBottom: 7
    },
    buttonsContainer: {
        maxWidth: "max-content",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tooltip: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 1
    },
    iconInfo: {width: "auto", height: "auto", position: "absolute", top: -8, right: 0,},
    check: {
        fontSize: 18,
        letterSpacing: 0.9,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        }
    },
    errorCheck: {
        border: "1px solid #FF0000",
        backgroundColor: "#FDE8E8"
    },
    errorHelper: {
        color: "#FF0000",
        fontSize: 14
    }
}));

function EmployerRegister(props) {
    let classes = useStyles();
    let [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        acceptTerms: false,
        acceptNotifications: false
    });
    let [typePass, setTypePass] = useState(true);
    let [typeConfirmPass, setTypeConfirmPass] = useState(true);
    let [errorRegister, setErrorRegister] = useState(null);
    let [processing, setProcessing] = useState(false);
    let [errorCheckTerms, setErrorCheckTerms] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let pass = document.getElementsByName("password")[0];
            if (value !== pass.value) {
                return false;
            }
            return true;
        });
    }, [typePass, errorRegister])

    async function handleSubmit() {
        if (!formData.acceptTerms) setErrorCheckTerms(true);
        else {
            setProcessing(true);
            api.users.registerEmployer(formData).then(response => {
                let {data} = response;
                if (response.status === 200) {
                    props.history.push("/employer/confirmation-registration");
                } else {
                    handleError(data ? data.error : response.message)
                    setProcessing(false);
                }
            });
        }
    }

    function handleError(error) {
        if (error) {
            setErrorRegister(error)
        } else {
            setErrorRegister("")
        }
    }

    const handleChange = (event) => {
        setErrorCheckTerms(false);
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    const handleChangeChecks = (event) => {
        setErrorCheckTerms(false);
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.checked;
        setFormData(data);
    }

    return (<Grid container spacing={0}>
        <Grid item xs={12} className={classes.titles}>
            <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate
                id={"Employer.EmployerRegisterTitle"}/>} </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
            <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)} instantValidate={false}>
                <Translate>
                    {({translate}) => {
                        return <div className={classes.paddingResponse}>
                            <TextValidator
                                className={classes.responsiveWidth}
                                label={translate("Employer.Email")}
                                onChange={handleChange}
                                name="email"
                                value={formData.email}
                                validators={['required', 'isEmail']}
                                placeholder={translate("Employer.PlaceholderEmail")}
                                errorMessages={[translate("Fields.Required"), translate("Fields.EmailValidation")]}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={errorRegister}
                            />

                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typePass ? "password" : "text"}
                                    label={translate("Employer.Password")}
                                    debounceTime={1500}
                                    name="password"
                                    value={formData.password}
                                    validators={['required']}
                                    placeholder={translate("Employer.Password")}
                                    errorMessages={[translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setTypePass(!typePass)}>
                                                    {typePass ? <RemoveRedEyeOutlined/> : <VisibilityOffOutlined/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typeConfirmPass ? "password" : "text"}
                                    label={translate("Employer.RepeatPasswordPlaceholder")}
                                    debounceTime={1500}
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    placeholder={translate("Employer.RepeatPasswordPlaceholder")}
                                    errorMessages={[translate("Employer.PasswordMismatch"), translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setTypeConfirmPass(!typeConfirmPass)}>
                                                    {typeConfirmPass ? <RemoveRedEyeOutlined/> :
                                                        <VisibilityOffOutlined/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}/>
                            </Grid>

                            <Grid container className={`${classes.buttons} ${classes.centerXs}`}>
                                <Grid item className={classes.buttonsContainer}>
                                    <Button
                                        className={classes.submit}
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        onClick={() => {
                                            if (!formData.acceptTerms) setErrorCheckTerms(true)
                                        }}>
                                        {processing && <CircularProgress color="primary" size={30} className="mr-2"/>}
                                        <Typography className={classes.buttomtext}><Translate
                                            id={"Buttons.Begin"}/></Typography>
                                    </Button>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={11} className={"pb-3"}>
                                <FormControlLabel
                                    className={errorCheckTerms ? classes.errorCheck : ''}
                                    control={<Checkbox name="acceptTerms" color="primary" checked={formData.acceptTerms}
                                                       onChange={handleChangeChecks}/>}
                                    label={<div>
                                        <Typography className={classes.check} color="textSecondary">
                                            <Translate id={"Employer.RegisterTerms"}/>
                                            <b><a href={"/Hiring-Policy"} style={{color: "#141414"}} target={"_blank"}><Translate id={"Employer.RegisterTermsLink"}/></a></b>
                                            <Translate id={"Employer.La"}/>
                                            <b><a href={"/Access-Policy"} style={{color: "#141414"}} target={"_blank"}><Translate id={"Employer.RegisterTermsLink2"}/></a></b>
                                            <Translate id={"Employer.Yla"}/>
                                            <b><a href={"/Privacy-Policy"} style={{color: "#141414"}} target={"_blank"}><Translate id={"Employer.RegisterTermsLink3"}/></a></b>
                                        </Typography>
                                    </div>}
                                />
                                {errorCheckTerms && <FormHelperText className={classes.errorHelper}><Translate
                                    id="Fields.Required"/></FormHelperText>}

                                <FormControlLabel
                                    control={<Checkbox name="acceptNotifications" color="primary"
                                                       checked={formData.acceptNotifications}
                                                       onChange={handleChangeChecks}/>}
                                    label={<Typography className={classes.check} color="textSecondary"><Translate
                                        id={"Employer.RegisterNotifications"}/></Typography>}
                                />
                            </Grid>
                        </div>
                    }}
                </Translate>
            </ValidatorForm>
        </Grid>
    </Grid>);
}

export default withRouter(EmployerRegister);