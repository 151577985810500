import React, {useEffect, useState, createRef, useRef} from 'react';
import {Button, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {getBrowserSupport} from "../../../../utils/browser";
import {NavLink, Link} from "react-router-dom";
import * as requestPermissions from "../../../../utils/permissions";
import * as constants from "../../../../utils/constants";
import {makeStyles} from '@material-ui/core/styles';
import UnsupportedBrowser from "./unsupported-browser";
import {isIOS, isWindows, isMacOs, isAndroid, isChrome, isFirefox, isSafari, isEdge} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    error: {
        color: "#FB060F",
        fontSize: "1.5rem",
        "& a, a:hover": {
            cursor: "pointer !important",
            color: theme.palette.primary.main,
            textDecoration: "underline !important",
            fontWeight: 600
        }
    }
}));

const ErrorView = (props) => {
    let {error, userCancelPermission, deviceNotFound, devicesInUse, unsupportMedia, unsupportRecorder} = props;
    let support = getBrowserSupport();
    let [permissions, setPermissions] = useState(null);
    let classes = useStyles();

    useEffect(() => {
        loadAllPermissions();
    }, [permissions])

    function loadAllPermissions() {
        if (permissions === null)
            requestPermissions.getAllPermissions().then(response => {
                setPermissions(response.filter(x => x.state === constants.permissionsStates.granted));
            })
    }

    function renderSupportLink() {
        return (<Link target={"_blank"} to={"/interview/support"}>link</Link>);
    }

    return (permissions &&
        <div className="mt-4">
            {unsupportMedia || unsupportRecorder
                ? <div><UnsupportedBrowser/></div>
                : userCancelPermission ?
                    <div className="text-center">
                        <Typography variant="h5" color="error" className="text-center"><Translate
                            id={"Errors.PermissionsDenied"}/></Typography>
                        <span className={`${classes.error} text-center`}><Translate
                            id={"Errors.PermissionsDeniedInstruction"}/>{renderSupportLink()}</span>
                    </div>

                    : deviceNotFound
                        ? <div><Typography variant="h5" color="error"><Translate
                            id={"Errors.DeviceNotFound"}/></Typography></div>

                        : devicesInUse
                            ? <div><Typography variant="h5" color="error"><Translate
                                id={"Errors.UncapturedVideo"}/></Typography></div>

                            : <div><Typography variant="h5" color="error">{JSON.stringify(error)}</Typography></div>
            }
        </div>
    )
}

export default ErrorView