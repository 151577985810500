import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Paper, Typography, useMediaQuery} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {Button} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import api from "../../../api";
import {staticContent} from "../../../utils/constants";

function WorkCard(props) {
    let {scope, data, index} = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 380,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 10,
            }
        },
        media: {
            width: 134,
            height: 146,
            //marginRight: 36,
            objectFit: "cover",
            objectPosition: "center",
            [theme.breakpoints.down('md')]: {
                width: 76,
                height: 82,
                //marginRight: 48
            },
        },
        area: {
            /*paddingTop: 26,
            paddingBottom: 20,*/
            display: "flex",
            alignItems: "center",
            minHeight: 200,
            [theme.breakpoints.down('xs')]: {
                minHeight: 142,
                /*paddingTop: 0,
                paddingBottom: 0,*/
            },
            position: "relative",
        },
        imageContainer: {
            textAlign: "center"
        },
        text: {
            fontSize: 17,
            textAlign: "left",
            font: "normal normal normal 17px/27px Barlow",
            paddingLeft: 28,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                textAlign: "left",
                paddingLeft: 44,
            },
        },
        segment1: {
            width: 56,
            height: 5,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        segment2: {
            width: 180,
            height: 1,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        cardContent: {
            padding: 16,
            paddingBottom: 24,
            position: "absolute",
            bottom: 0,
            right: 0,
            [theme.breakpoints.down('xs')]: {
                padding: 5,
                paddingBottom: 10,
                right: 0,
                bottom: 0
            }
        },
        segments: {
            marginTop: 20,
            position: "absolute",
            bottom: 0,
        },
        container: {
            display: "flex",
            alignItems: "flex-end"
        }
    }));
    let classes = useStyles();

    const theme = useTheme();
    const screenSmDow = useMediaQuery(theme.breakpoints.down('lg'));

    function responseImages(name) {
        if (screenSmDow)
            return require(`../../../images/layuout/howToWork/${name}Movil${index}.png`)
        else
            return require(`../../../images/layuout/howToWork/${name}${index}.png`)
    }


    const resolveImage = () => scope === "professional"
        ? responseImages("wp")
        : responseImages("we")

    return (<Grid container spacing={0}>
        <Paper className={classes.root} elevation={0}>
            <Paper elevation={0} type="" className={classes.area}>
                <div className={classes.container}>
                    <img title={data.alt} alt={data.alt} src={resolveImage()}/>
                    <Typography color="textSecondary" className={classes.text}>{data.text}</Typography>
                </div>
                <Grid className={classes.segments} container spacing={2} direction="row" justify="center"
                      alignItems="center">
                    <Grid item xs={12} style={{display: "flex"}}>
                        <div className={classes.segment1}></div>
                        <div className={classes.segment2}></div>
                    </Grid>
                </Grid>
            </Paper>
        </Paper>
    </Grid>);
}

export default withRouter(WorkCard);