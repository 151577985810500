import api from "../../api";

export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const ARRAY_LANGUAGES = "ARRAY_LANGUAGES";

function fetchAllLanguage(languages) {
    return {
        type: GET_ALL_LANGUAGE,
        payload: languages
    }
}

function fetchArrayLanguage(Arraylanguages) {
    return {
        type: ARRAY_LANGUAGES,
        payload: Arraylanguages
    }
}

export const GetAllLanguage = (dispatch) =>
    async () => {
        try {
            let response = await api.language.getAllLanguages();

            if (response.status !== 200) {
                dispatch(fetchAllLanguage([{
                    "code": "en",
                    "name": "English",
                },
                    {
                        "code": "es",
                        "name": "Español",
                    }]))
            } else {
                let languages = response.data;
                //await localStorage.setItem('languagesApp', languages);
                dispatch(fetchAllLanguage(languages));
            }
            return response;
        } catch (error) {
            return "error";
        }
    }

    export const ArrayLanguages = (dispatch) =>  (languages) => {
        try {
            let aux = [];
            languages.map(v=>{
                aux.push({ name: v.name, code: v.code });
            })
            dispatch(fetchArrayLanguage(aux));
        } catch (e) {
            return "error";
        }
}