import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Grid, useMediaQuery} from "@material-ui/core";
import ReactivationImageXs from "../../../images/offer/calculator-xs.png";
import ReactivationImage from "../../../images/offer/calculator.png";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import moment from "moment";
import CustomConfirmation from "../../confirmation/customConfirmation";
import {useHistory} from "react-router-dom";
import api from "../../../api/index";
import {PaymentType} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    img: {
        width: 112,
        height: 115,
        [theme.breakpoints.only('xs')]: {
            width: 67,
            height: 69
        },
    },
    reactivationTitle: {
        fontSize: 22,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 18
        },
    },
    infoContainer: {
        textAlign: "left",
        maxWidth: 261,
        [theme.breakpoints.only('xs')]: {
            textAlign: "center"
        },
    },
    dateCaption: {
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        },
    },
    priceContainer: {
        padding: "5px 20px",
        backgroundColor: "#F0F0F0",
        marginTop: 10,
        [theme.breakpoints.only('xs')]: {
            justifyContent: "center"
        },
    },
    paddingXs: {
        [theme.breakpoints.only('xs')]: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important"
        }
    }
}));

export default function ReactiavteProcess(props) {
    const classes = useStyles();
    const theme = useTheme();
    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    let history = useHistory();
    let {offerId} = props;


    useEffect(() => {

    }, [props.reactiavteProcess])

    const renderReactivationForm = () => {
        return <Translate>
            {({translate}) => {
                return <Grid container spacing={4}>
                    <Grid item md={3} xs={12} className={"p-0"}>
                        <img src={xsOnly ? ReactivationImageXs : ReactivationImage} className={classes.img}/>
                    </Grid>
                    <Grid item md={9} xs={12} className={`${classes.infoContainer} ${classes.paddingXs} pt-1`}>
                        <Typography className={classes.reactivationTitle} color="secondary"><Translate
                            id="Offers.ManagementReactive"/></Typography>
                        <Typography className={classes.dateCaption}
                                    color="textSecondary">{`${translate("Offers.ReactivationDate")} ${moment(props.offer.willInitReactivationDate).format("DD/MM/YYYY")}`}</Typography>
                        <div className={`d-flex ${classes.priceContainer}`}>
                            <Typography className={classes.dateCaption}
                                        color="textSecondary">{`${translate("Offers.ReactivationPrice")}: 50€ + ${translate("Offers.Iva")}`}</Typography>
                        </div>
                    </Grid>
                </Grid>
            }}
        </Translate>
    }

    async function createPayment() {
        let payment = await api.payments.createOrder(PaymentType.ReactivateProcess, 0, offerId);
        return payment.status === 200 ? payment.data.id : null;
    }

    async function reactivateProcess() {
       try{
           if (!offerId) alert("Indefined processId");
           if (offerId) {
               let response = await api.payments.reactivateProcess(offerId);
               if (response.status) {
                   let result = response.data;
                   if (result) {
                       if (props.refreshData) {
                           props.refreshData();
                       }
                       props.setReactivateProcess(false);
                   } else {
                       props.setReactivateProcess(false);
                       let payment = await createPayment();
                       history.push(`/employer/pay/${PaymentType.ReactivateProcess}/${payment}/${offerId}`);
                   }
               }
           }
       }catch (e) {
           console.log(e)
       }
    }

    return (
        <CustomConfirmation open={props.reactiavteProcess} type="secondary"
                            handleCancel={() => {
                                props.setReactivateProcess(false);
                            }}
                            handleAccept={() => reactivateProcess()}>
            {renderReactivationForm()}
        </CustomConfirmation>
    );
}