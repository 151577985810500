import * as types from '../actions/interview/index';

/**
 * El formato de cada action es {path: "", component: "component"}
 */
const initialState = {
    actions: [

    ],
    support: {
        isInlineRecordingSupported: false,
        isVideoInputSupported: false
    },
    configuration: []
}

function workReducer(state = initialState, action) {
    let activity = action.payload;

    switch (action.type) {
        case types.SET_PAUSED_INTERVIEW_ACTION || types.SET_RUN_INTERVIEW_ACTION || types.SET_END_INTERVIEW_ACTION || types.SET_VIEW_INTERVIEW_ACTION:
            let copyActions = Object.assign([], state.actions);
            copyActions = copyActions.filter(x => x.path !== activity.path);
            copyActions.push(activity);
            return {
                ...state,
                actions: copyActions
            }

        case types.SET_VIEW_INTERVIEW_SUPPORT:
            return {
                ...state,
                support: action.payload
            }

        case types.SET_UNSUPPORT_CONFIGURATION:
            return {
                ...state,
                configuration: action.payload
            }

        case types.RESET_UNSUPPORT_CONFIGURATION:
            return {
                ...state,
                configuration: []
            }
        default:
            return state
    }
}

export default workReducer;