import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import {Grid, Hidden} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";
import {isIOS, isChrome} from "react-device-detect";
import {withRouter} from "react-router-dom";
import api from "../../../api/index";
import {Container, useMediaQuery} from "@material-ui/core";
import {staticContent, interviewTypes} from "../../../utils/constants";
import DemoLgImage from "../../../images/videospeaking/demo.png";
import DemoXsImage from "../../../images/videospeaking/demoXs.png";
import PausedVideo from "../../../images/interview/paused-video.png";
import MovilPausedVideo from "../../../images/interview/movil-paused-video.png";
import GoBack from "../../navigation/goBack";
import ViewDemo from "../../interview/tips/viewDemo";
import MovilInterview from "../../../images/interview/movil-interview.png";
import HeaderCircle from "../../../images/layuout/employer/header-circle.png";


const useStyles = makeStyles((theme) => {
    theme.overrides.MuiButton.root = {
        ...theme.overrides.MuiButton.root,
        padding: "5px 10px",
        height: 50,
        minHeight: 50,
        minWidth: 220,
    };
    return {
        container: {
            [theme.breakpoints.only('xs')]: {
                marginTop: 50,
                paddingBottom: 80
            },
            '& ::marker': {
                fontSize: 15
            }
        },
        root: {
            backgroundColor: theme.palette.background.default,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            minHeight: 115
        },
        center: {
            textAlign: "center",
            justifyContent: "space-around",
            margin: "auto",
            [theme.breakpoints.up('md')]: {
                display: "flex",
                minWidth: 600
            },

        },
        title1: {
            fontSize: 35,
            fontWeight: 600,
            letterSpacing: 0,
            maxWidth: 650,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30,
                marginLeft: 0,
                marginTop: 0,
                maxWidth: 290
            },
            lineHeight: 1,
            "& .orange": {
                color: theme.palette.secondary.main
            }
        },
        nickContainer: {
            maxWidth: "15.5%"
        },
        segment: {
            height: 100,
            border: "1px solid #141414",
            marginRight: 18,
            marginTop: 40,
            [theme.breakpoints.only('xs')]: {
                marginRight: 12
            }
        },
        modalityPoint: {
            ...theme.overrides.MuiTypography.body1,
            marginBottom: 0,
            [theme.breakpoints.only('xs')]: {
                marginBottom: 0,
                fontSize: 15
            },
            "& li": {
                marginLeft: 20
            }
        },
        poinContainer: {
            marginTop: 35,
            maxWidth: 1100,
            "& .section": {
                marginTop: 25,
                fontWeight: 600
            },
            "& .subtitle": {
                fontWeight: 600,
                [theme.breakpoints.only('xs')]: {
                    fontSize: 15
                }
            },
            "& .main-subtitle": {
                fontSize: 25,
                fontWeight: 600,
                color: theme.palette.text.secondary,
                [theme.breakpoints.only('xs')]: {
                    fontSize: 20
                }
            },
            "& .subtitle-container": {
                marginTop: 5
            }
        },
        imgResponsive: {
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            marginTop: 20,
            marginBottom: 20,

            boxShadow: "0px 0px 25px #00000026",
            borderRadius: 20,
            [theme.breakpoints.only('xs')]: {
                marginLeft: "auto",
                marginTop: 20,
            }
        },
        button: {
            minWidth: 220,
            width: 220,
            borderRadius: 0,
            height: 50,
            lineHeight: 1,
            marginBottom: 10,
            [theme.breakpoints.only('xs')]: {
                marginTop: 20,
                marginBottom: 10
            }
        },
        buttonLabel: {
            fontWeight: "bold",
            fontSize: 18,
        },
        buttonLabelSecondary: {
            fontWeight: "bold",
            fontSize: 18,
            color: theme.palette.text.secondary
        },
        containerRelative: {
            marginTop: 30,
            marginBottom: 30,
            position: "relative",
            "& a": {
                height: 50,
            },
            [theme.breakpoints.only('xs')]: {
                marginTop: 20,
                marginBottom: 0,
                "& button:nth-of-type(1)": {
                    order: 3
                }
            }
        },
        circle: {
            width: 50,
            height: 50,
            borderRadius: 50,
            border: `3px solid ${theme.palette.secondary.main}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: 0,
            [theme.breakpoints.only('xs')]: {
                top: 80,
            }
        },
        icon: {
            marginLeft: 7
        },
        videoResponsive: {
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            marginLeft: "auto",
            marginRight: 28,
            [theme.breakpoints.only('xs')]: {
                marginRight: 0
            }
        },
        videoContainer: {
            display: "flex",
            alignItems: "start",
            [theme.breakpoints.only('xs')]: {
                position: "absolute",
                right: 10
            }
        },
        hr: {
            width: 160,
            height: 1,
            backgroundColor: theme.palette.text.secondary,
            margin: 0,
            marginTop: 10,
            [theme.breakpoints.only('xs')]: {
                width: 112
            }
        },
        plxs2: {
            [theme.breakpoints.only('xs')]: {
                paddingLeft: 10,
                paddingRight: 10
            }
        },
        demo:{
            position: "relative",
            /*[theme.breakpoints.only('xs')]: {
                backgroundImage: `url('${DemoXsImage}')`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 375,
                width: "100%",
            }*/
        }
    }
});

function HowWork(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenMdDow = useMediaQuery(theme.breakpoints.down('md'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    let {type} = props.match.params;
    const id = props.match.params["test"] ? parseInt(props.match.params["test"]) : null;

    let [advices, setAdiveces] = useState(null)
    let [howWork, setHowWork] = useState(null);

    useEffect(() => {
        api.home.getContent(isIOS
            ? staticContent.TipsVideoSpeakingIphone
            : staticContent.TipsVideoSpeaking)
            .then(response => {
                setAdiveces(response);
                if (response) {
                    setHowWork(response[staticContent.HowWorkPaused])
                }
            });
    }, [howWork])


    return (<Grid container spacing={0} style={screenMdDow ? {marginTop: 0} : {marginTop: 20}}>
        {howWork && <Grid className={classes.container} container direction="row" justify="center">
            <Grid item md={2} className={classes.videoContainer}>
                <Hidden xsDown>
                    <img alt={howWork.TitleAlt} className={classes.videoResponsive} src={PausedVideo}/>
                </Hidden>
                <Hidden smUp>
                    <img alt={howWork.TitleAlt} className={classes.videoResponsive} src={MovilPausedVideo}/>
                </Hidden>
            </Grid>
            <Grid item item xs={11} sm={9} md={9} className={`d-flex pb-4 ${classes.plxs2}`}>
                <Grid item xs={12}>
                    <div className={classes.title1} dangerouslySetInnerHTML={{__html: howWork.Title}}/>
                    <Grid className={`${classes.questionContainer} d-flex`}>
                        <div className={classes.segment}></div>
                        <ul className="pl-3">
                            <Grid item md={8} xs={12} className={classes.poinContainer}>
                                {howWork.Points.map(x => {
                                    return <div className={classes.modalityPoint}
                                                dangerouslySetInnerHTML={{__html: x}}/>
                                })}
                            </Grid>
                        </ul>
                    </Grid>


                    <Grid className={classes.poinContainer}>
                        <div className={classes.subtitle}
                             dangerouslySetInnerHTML={{__html: howWork.SubTitleInterview}}/>
                        <hr className={classes.hr}/>

                        <Hidden xsDown>
                            <div className={classes.demo}>
                                <img alt={howWork.TitleAlt} className={classes.imgResponsive} src={DemoLgImage}/>
                                <ViewDemo/>
                            </div>
                        </Hidden>

                        <Hidden smUp>
                            <div className={classes.demo}>
                                <img alt={howWork.TitleAlt} id="img-demo-xs" className={classes.imgResponsive} src={DemoXsImage}/>
                                <ViewDemo/>
                            </div>
                        </Hidden>

                        <Grid className={classes.containerRelative} container direction="row" justify="center"
                              alignItems="center">
                            <GoBack type={"button"} color={"primary"} ordered={true}/>

                            <NavLink to={`/speaking/test/${id}`}>
                                <Button classes={{label: classes.buttonLabel}}
                                        className={classes.button}
                                        variant="contained" color={"primary"} type="submit">
                                    <Translate id={"Buttons.StartSpeakingRecording"}/>
                                </Button>
                            </NavLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>}
    </Grid>);
}

export default withRouter(HowWork);