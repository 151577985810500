import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, TextField, Container, Hidden, useMediaQuery } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ContactImage from "../../../images/layuout/contact/contact.png"
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import api from "../../../api/index";
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
    titleSection: {
        ...theme.overrides.MuiTypography.section,
        color: "#7A7A7B",
        fontWeight: 600,
        textAlign: "center",
        fontSize: 35,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,
            textAlign: "left"
        },
        marginBottom: 0
    },
    inputContainer: {
        marginTop: 50,
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    },
    paddingResponse: {
        paddingTop: 13
    },
    required: {
        fontSize: 16,
        color: theme.palette.text.secondary,
        fontWeight: "bold",
        marginLeft: 20,
        marginTop: 10
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        [theme.breakpoints.down('xs')]: {
            height: 130
        }
    },
    imageContainer: {
        paddingLeft: 40,
    },
    submit: {
        minWidth: 220,
        borderRadius: 0,
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 41,
        }
    },
    "#area .MuiFormLabel-filled": {
        transform: "translate(12px, 3px) scale(0.75);",
        fontSize: 21
    },
    responsiveWidth: {
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    submitContainer: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    inputArea: {
        backgroundColor: "transparent",
        padding: "4px 0 7px;"
    },
    queryText: {
        fontSize: 16,
        transform: "translate(0, -2.5px) scale(1) !important;",
        fontWeight: "bold",
        color: theme.palette.text.secondary
    },
    labelBold: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        height: 18
    },
    buttonLabel: {
        fontWeight: 700,
        fontSize: 18
    },
    container: {
        padding: "40px 24px",
        [theme.breakpoints.only('lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },

    },
    textArea: {
        minHeight: 108
    },
    borderRadius: {
        borderRadius: 0,
        marginTop: 21
    },
    notifyText: {
        fontSize: 18,
        letterSpacing: 0.9,
        color: "#54B169",
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        }
    },
    errorText: {
        fontSize: 18,
        letterSpacing: 0.9,
        color: theme.palette.common.error,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        }
    }
}));

function ConatctUs(props) {
    let classes = useStyles();
    let [sending, setSending] = useState(false);
    let [formData, setFormData] = useState({ fullName: "", email: "", query: "" });
    let [successContact, setSuccessContact] = useState(false);
    let [errorContact, setErrorContact] = useState(false);
    let emailRef = React.createRef();

    useEffect(() => {

    }, [formData])

    const handleSubmit = () => {
        setSending(true);
        cleanMessages();
        api.users.contactUs({ email: formData.email, name: formData.fullName, query: formData.query })
            .then(response => {
                if (response.status === 200) {
                    setSending(false);
                    setSuccessContact(true);
                    cleanForm();
                } else {
                    setSending(false);
                    setErrorContact(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorContact(true);
            })
    }
    const handleBlur = (event) => {
        emailRef.current.validate(event.target.value);
    }

    function cleanForm() {
        setFormData({ fullName: "", email: "", query: "" })
    }

    function cleanMessages() {
        setSuccessContact(false);
        setErrorContact(false);
    }

    const handleChange = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
        cleanMessages();
    }

    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    return (<Grid>
        <Grid item xs={12} style={{ minHeight: 540 }}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)} instantValidate={false}>
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={12} md={12}>
                            <h1 className={classes.titleSection}>{<Translate
                                id={"Menus.Contact"} />} </h1>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={6}>
                                <Typography color="primary" noWrap
                                    variant="overline"><h4><PhoneIcon />+34 626 969 750</h4></Typography>
                                <Translate>
                                    {({ translate }) => {
                                        return <img alt={translate("Titles.AltContact")} src={ContactImage}
                                            className={classes.image} />
                                    }}
                                </Translate>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid className={classes.inputContainer} container spacing={2}>
                        <Grid item sm={9} md={10}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} style={screenXsOnly ? { paddingBottom: 0 } : {}}>
                                    <Translate>
                                        {({ translate }) => {
                                            return <div className={classes.paddingResponse}>
                                                <TextValidator
                                                    className={classes.responsiveWidth}
                                                    label={`${translate("Fields.FullName")}*`}
                                                    onChange={handleChange}
                                                    onClick={cleanMessages}
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    validators={['required']}
                                                    errorMessages={[translate("Fields.Required")]}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.labelBold
                                                        }
                                                    }}
                                                />

                                                <TextValidator
                                                    ref={emailRef}
                                                    className={classes.responsiveWidth}
                                                    label={`${translate("Fields.Mail")}*`}
                                                    debounceTime={1500}
                                                    name="email"
                                                    value={formData.email}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={[translate("Fields.Required"), translate("Fields.EmailValidation")]}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    onClick={cleanMessages}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.labelBold
                                                        }
                                                    }} />
                                            </div>
                                        }}
                                    </Translate>
                                    <Hidden smDown>
                                        <Typography className={classes.required}>{<Translate
                                            id={"Fields.RequiredFields"} />} </Typography>
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} md={6} id="area"
                                    style={screenXsOnly ? { paddingBottom: 0 } : { paddingTop: 13 }}>
                                    <Translate>
                                        {({ translate }) => {
                                            return <div style={{ marginTop: 9 }}>
                                                <TextValidator
                                                    style={{ width: "100%" }}
                                                    label={`${translate("Fields.Query")}*`}
                                                    multiline
                                                    rows={6}
                                                    variant="filled"
                                                    name="query"
                                                    value={formData.query}
                                                    validators={['required']}
                                                    errorMessages={[translate("Fields.Required")]}
                                                    onChange={handleChange}
                                                    onClick={cleanMessages}
                                                    className={classes.inputArea}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {
                                                            root: classes.queryText,
                                                            inputMultiline: classes.textArea
                                                        }
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            inputMultiline: classes.textArea,
                                                            multiline: classes.borderRadius
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }}
                                    </Translate>
                                    <Hidden smUp>
                                        <Typography className={classes.required}>{<Translate
                                            id={"Fields.RequiredFields"} />} </Typography>
                                    </Hidden>
                                    <Grid item xs={12} className={classes.submitContainer}>
                                        <Button classes={{ label: classes.buttonLabel }} className={classes.submit}
                                            variant="contained" color="primary" type="submit"
                                            onClick={cleanMessages}>
                                            {sending && <CircularProgress color="secondary" className="mr-4" />}
                                            <Translate id={"Buttons.Send"} />
                                        </Button>

                                        {successContact && <Typography className={classes.notifyText}><Translate
                                            id="Titles.SuccessContactTitle" /></Typography>}
                                        {errorContact &&
                                            <Typography className={classes.errorText} color="error"><Translate
                                                id="Titles.ErrorContactTitle" /></Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Hidden xsDown>
                            <Grid item sm={3} md={2}>
                                <Typography color="primary" noWrap
                                    variant="overline"><h4><PhoneIcon />+34 626 969 750</h4></Typography>
                                <Translate>
                                    {({ translate }) => {
                                        return <img alt={translate("Titles.AltContact")} src={ContactImage}
                                            className={classes.image} />
                                    }}
                                </Translate>
                            </Grid>
                        </Hidden>
                    </Grid>
                </ValidatorForm>
            </Container>
        </Grid>
    </Grid>);
}

export default withRouter(ConatctUs);