import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, CircularProgress, Hidden } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import ProfessionalImageBackgroudLogin from "../../../images/professional/login-background.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import api from "../../../api/index";
import {useHistory} from "react-router-dom";
const { innerWidth: width, innerHeight: height } = window;
const isProfessional = window.location.pathname.indexOf("professional") > -1;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 45,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    submit: {
        minWidth: 200,
        borderRadius: 0,
        marginTop: 20
    },
    buttomtext: {
        fontWeight: "bold",
        color: isProfessional ? theme.palette.common.white : theme.palette.primary.main,
        fontSize: 18,
        letterSpacing: 0.7
    },
    mainTitle: {
        fontSize: 35,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    registerInstructions: {
        fontSize: 18,
        marginTop: 80,
        marginBottom: 40,
        color: theme.palette.common.green
    }
}));

function ConfirmRegisterEmail(props) {
    let classes = useStyles();
    const dispatch = useDispatch();
    let [isConfirmed, setIsConfirmed] = useState(null);
    let [error, setError] = useState(null);
    let { user } = props.match.params;
    let token = props.location.search.replace("?userId=","");
    let history = useHistory();

    useEffect(() => {
        api.users.confirmEmail({ userId: user, token: token }).then(response => {
            if (isConfirmed === null) {
                if (response.status === 200) {
                    history.push(isProfessional ? "/professional/login" : "/employer/login");
                    setIsConfirmed(true);
                } else {
                    setIsConfirmed(true);
                    setError(response.data.error);
                }
            }
        });
    }, [isConfirmed])



    return (isConfirmed
        ? <Grid container spacing={0}>
            <Grid item xs={12} className={classes.titles}>
                <Typography variant="h1" color="primary" className={classes.mainTitle}>
                    {isProfessional ? <Translate id={"Professional.EmployerRegisterTitle"} /> : <Translate id={"Employer.EmployerRegisterTitle"} />}
                </Typography>
            </Grid>

            {error
                ? <Grid item xs={12} md={8} lg={7} className={classes.form}>
                    <Typography className={classes.registerInstructions} style={{color: "#FB060F"}}>{error} </Typography>
                </Grid>
                : <Grid item xs={12} md={8} lg={7} className={classes.form}>
                    <Typography className={classes.registerInstructions}>{<Translate id={"Employer.ConfirmRegisterEmail"} />} </Typography>

                    <NavLink to={isProfessional ? "/professional/login" : "/employer/login"}>
                        <Button className={classes.submit} variant="contained" color={isProfessional ? "primary" : "secondary"} type="submit">
                            <Typography className={classes.buttomtext}><Translate id={"Buttons.GoLogin"} /></Typography>
                        </Button>
                    </NavLink>
                </Grid>}


        </Grid>
        : <CircularProgress color="primary" />);
}

export default withRouter(ConfirmRegisterEmail);