export const interviewTypes = {
    manual: "manual",
    continuous: "continuous"
}

export const permissionsStates = {
    granted: "granted",
    denied: "denied"
}

export const permisions = {
    camera: "camera",
    microphone: "microphone"
}

export const digitalocean = "digitaloceanspaces";

export const staticContent = {
    AboutUs: "AboutUs",
    Benefits: "Benefits",
    Professionals: "Professionals",
    Employers: "Employers",
    Tips: "Tips",
    HowToWork: "HowToWork",
    TipsInterview: "TipsInterview",
    TipsInterviewIphone: "TipsInterviewIphone",
    HowWorkContinuosly: "HowWorkContinuosly",
    HowWorkPaused: "HowWorkPaused",
    Pricing: "Pricing",
    TipsVideoSpeaking: "TipsVideoSpeaking",
    TipsVideoSpeakingIphone: "TipsVideoSpeakingIphone",
    PrivacyPolicy: "PrivacyPolicy",
    AccessPolicy: "AccessPolicy",
    HiringPolicy: "HiringPolicy",
    CookiesPolicy: "CookiesPolicy"
}

export const offersSatte = {
    open: "Open",
    closed: "Closed",
    draft: "Draft",

}

export const applicationStates = {
    valored: "valored",
    incorpored: "incorpored",
    suitable: "suitable",
    descarted: "descarted",
    hihired: "hihired"
}

export const PaymentType = {
    CreateProcess:  "CreateProcess",
    ReactivateProcess: "ReactivateProcess",
    VideoSpeakingTest: "VideoSpeakingTest",
    AddCredit: "AddCredit"
}