import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { Typography, Grid, Paper, useMediaQuery, Button, Tooltip } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import DefaultImage from "../../../images/employer/default-image.png";
import LogoPreview from "../../professional/interviewPreview";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: "0px 0px 25px #0000001A",
        width: "100%",
        minHeight: 380,
        padding: 40,
        [theme.breakpoints.down('xl')]: {
            minHeight: 330
        },
        [theme.breakpoints.only('xs')]: {
            padding: 15,
            minHeight: 280
        },
    },
    image: {
        width: 125,
        height: 125,
        objectFit: "cover",
        borderRadius: 25,
        [theme.breakpoints.down('xl')]: {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.only('xs')]: {
            width: 100,
            height: 100,
            borderRadius: 15,
        },
    },
    info: {
        [theme.breakpoints.only('xs')]: {
            //paddingTop: 10,
        },
    },
    topInfo: {
        marginTop: 20
    },
    button: {
        minHeight: 40,
        height: 40,
        borderRadius: 0,
        minWidth: 180,
        width: 187,
        marginTop: 25,
        [theme.breakpoints.only('xs')]: {
            marginTop: 20,
        }
    },
    buttonLabel: {
        fontSize: 18,
        fontWeight: 700,
    },
    badge: {
        width: 19,
        height: 19,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 50,
        textAlign: "center",
        position: "absolute",
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#141414",
        fontWeight: 700,
        fontSize: 14
    },
    valuation: {
        fontSize: 18,
        fontWeight: 700,
        marginTop: 30,
        minHeight: 27,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17,
            marginTop: 16
        }
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
        textAlign: "left",
        fontSize: 15,
        fontStyle: "italic",
        lineHeight: 1.2,
        padding: "5px 10px"
    },
    blur: {
        filter: " blur(.5rem)",
        WebkitFilter: "blur(.5rem)"
    }
}));

function ApplicantCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenSmDow = useMediaQuery(theme.breakpoints.down('lg'));
    let { applicant, index, isBlur } = props;

    return (<Translate>
        {({ translate }) => {
            return <Paper className={classes.paper} elevation={2}>
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item xs={4} md={4}>
                        {
                            applicant.profileImage ?
                                <img className={classes.image} src={applicant.profileImage} /> :
                                <div>
                                    {applicant.interviewUrl ?
                                        <LogoPreview videoUrl={applicant.interviewUrl} management={true} /> :
                                        <img className={classes.image} src={DefaultImage} />
                                    }
                                </div>
                        }
                    </Grid>
                    {isBlur ? (
                        <Grid item xs={8} md={8} className={classes.info + " " + classes.blur}>
                            <Typography className={props.classes.caption} color="textSecondary">Lorem  Ipsum</Typography>
                            <Typography className={`${props.classes.captionValue} ml-0`} color="textSecondary">Lorem</Typography>
                            <div className="d-flex">
                                <Typography className={props.classes.caption} style={{ fontWeight: "normal" }} color="textSecondary">
                                    Loremipsum
                                </Typography>
                                <Typography className={`${props.classes.caption} mx-1`} color="textSecondary" style={{ fontWeight: "normal" }}>
                                    -
                                </Typography>
                                <Typography className={props.classes.caption} color="textSecondary" style={{ fontWeight: "normal" }}>
                                    Lorem Ipsum
                                </Typography>
                            </div>
                        </Grid>
                    ) : (
                        <Grid item xs={8} md={8} className={classes.info}>
                            <Typography className={props.classes.caption} color="textSecondary">{applicant.fullName}</Typography>
                            <Typography className={`${props.classes.captionValue} ml-0`} color="textSecondary">{applicant.professionTitle}</Typography>
                            <div className="d-flex">
                                <Typography className={props.classes.caption} style={{ fontWeight: "normal" }} color="textSecondary">
                                    {applicant.municipality && applicant.municipality.province ? applicant.municipality.province.name : ""}
                                </Typography>
                                <Typography className={`${props.classes.caption} mx-1`} color="textSecondary" style={{ fontWeight: "normal" }}>
                                    {applicant.municipality && applicant.municipality.province ? "-" : ""}
                                </Typography>
                                <Typography className={props.classes.caption} color="textSecondary" style={{ fontWeight: "normal" }}>
                                    {applicant.municipality ? applicant.municipality.name : ""}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.topInfo}>
                    <div className="d-flex">
                        <Typography className={props.classes.caption} color="textSecondary">{`${translate("Offers.ManagementFilterDate")}:`}</Typography>
                        <Typography className={props.classes.captionValue + (isBlur ? (" " + classes.blur) : "")} color="textSecondary">{isBlur ? "00/00/0000" : applicant.inscriptionDateString}</Typography>
                    </div>

                    <div className="d-flex">
                        <Typography className={props.classes.caption} color="textSecondary">{`${translate("Offers.ManagementState")}:`}</Typography>
                        <Typography className={props.classes.captionValue + (isBlur ? (" " + classes.blur) : "")} color="textSecondary">{isBlur ? "Loremipsum" : (applicant.state ? applicant.state.name : "")}</Typography>
                    </div>

                    <div className={classes.valuation}>
                        <Tooltip title={applicant.valuation} placement="right" classes={{ tooltip: classes.tooltip }}>
                            <Typography className={classes.valuation + (isBlur ? (" " + classes.blur) : "")} color="textSecondary" role="button" component="span">
                                {isBlur ? "Lorem" : (applicant.valuation && translate("Offers.Valuation"))}
                            </Typography>
                        </Tooltip>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <NavLink to={`/professional/${applicant.id}/${applicant.applicationId}`}>
                        <Button variant="contained" color="secondary" type="submit" className={classes.button}>
                            <Typography className={classes.buttonLabel} variant="inherit" color="textSecondary"><Translate id={"Offers.ManagementButton"} /></Typography>
                        </Button>
                    </NavLink>
                </Grid>

                <Grid item xs={12} style={{ position: "relative" }}>
                    <div className={classes.badge}>{index}</div>
                </Grid>
            </Paper>
        }}
    </Translate>);
}

export default withRouter(ApplicantCard);