import React, {useEffect, useState, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Hidden, Paper} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import {NavLink, useHistory} from "react-router-dom";
import VideoControls from '../interview/videoControls';
import * as interviewActions from "../../actions/interview/index";
import {useMediaQuery, Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import api from "../../api/index";
import {withRouter} from "react-router-dom";
import QuestionStepper from "../interview/run/questionStepper";
import {isIOS} from 'react-device-detect';
import * as constants from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        [theme.breakpoints.up('md')]: {
            width: 275
        },
        [theme.breakpoints.down('sm')]: {
            width: 194,
        },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        margin: 'auto'
    },
    itemSpacing: {
        marginTop: "1rem",
        marginBottom: "1rem",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
    },
    greyXs: {},
    questions: {
        marginTop: 33,
        minHeight: 117,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            minHeight: 73,
            paddingBottom: "0 !important"
        },
        [theme.breakpoints.only('lg')]: {
            minHeight: window.innerHeight >= 638 && window.innerHeight <= 720 ? 100 : 117,
        },
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 26,
        right: 26,
        minWidth: 63,
        width: 63,
        height: 63
    },
    icon: {
        height: 63,
        width: 63
    },
    titleInterView: {
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            marginLeft: 59
        },
    },
    paddingRightResponse: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "152px !important"
        }
    },
    video: {
        [theme.breakpoints.up('md')]: {
            margin: "auto",
        },
    },
    listItem: {
        [theme.breakpoints.down('sm')]: {
            width: 194,
            height: 28,
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 37,
        },
        height: 37,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 19,
        opacity: 1,
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            borderColor: theme.palette.primary.main
        },

    },
    listItemActive: {
        [theme.breakpoints.down('sm')]: {
            width: 194,
            height: 28,
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 37,
        },
        height: 37,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 19,
        opacity: 1,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",

        },

    },
    player: {
        "transform": "rotateY(180deg)",
        "-webkit-transform": "rotateY(180deg)",
        "-moz-transform": "rotateY(180deg)",
        maxHeight: "80vh"
    },
    paddingLeftResponse: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "3rem !important"
        },
    },
    topResponse: {
        [theme.breakpoints.up('md')]: {
            marginTop: "3rem !important"
        },
    },
    px3: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
            paddingRight: 5
        },
    },
    recorderMargins: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    },
    instructions: {
        ...theme.overrides.MuiTypography.question3,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        lineHeight: 1.1,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.question3.fontSize
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "0 !important",
            marginBottom: 0,

        },
    },
    top: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "0.5rem"
        },
    },
    centerXs: {
        textAlign: "center",
        [theme.breakpoints.up('xl')]: {
            paddingRight: "3rem !important",
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    customWidth: {
        maxWidth: 500,
    },
    heightMaxTime: {
        height: 61,
        [theme.breakpoints.only('xs')]: {
            height: 10
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 46 : 61,
        },
    },
    textApplication: {
        fontSize: 25,
        fontWeight: 700,
        lineHeight: 1,
        [theme.breakpoints.only('lg')]: {
            fontSize: 24
        },
        [theme.breakpoints.only('md')]: {
            fontSize: 22
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 21
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        },
    },
    paper: {
        borderRadius: 35,
        padding: 20,
        boxShadow: "0px 0px 15px #0000001A",
        marginTop: 25,
        [theme.breakpoints.down('lg')]: {
            padding: 14,
            marginTop: 10
        },
        [theme.breakpoints.only('xs')]: {
            padding: 14,
            marginTop: 10,
            borderRadius: 15
        },
    },
    mergedTitle: {
        display: "flex",
        height: "max-content !important",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 17,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 3
        }
    },
    title: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    imgLanguage: {
        width: 43,
        height: 43,
        marginLeft: 36,
        marginRight: 10,
        [theme.breakpoints.only('xs')]: {
            width: 29,
            height: 29,
            marginLeft: 11,
            marginRight: 7,
        }
    },
    imgWidget: {
        width: 43,
        height: 43,
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.only('xs')]: {
            width: 40,
            height: 40,
            marginTop: 5,
            marginBottom: 0
        }
    },
}));

let video = null;

function VideoSpeakingViewer(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    let history = useHistory();
    let {questions, url, isLocalVideo, poster, application, test} = props;

    const [indexActiveQuestion, setIndexActiveQuestion] = React.useState(-1);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [volumen, setVolumen] = React.useState(10);
    const [maxDuration, setMaxDuration] = useState(0);
    const [questionInterview, setQuestionInterview] = useState(null);
    const [active, setActive] = useState(false);
    const [showPoster, setShowPoster] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [videoHeight, setVideoHeight] = useState(0);

    const interviewSupport = useSelector(state => state.interviews.support);
    const loguedUser = useSelector(state => state.users.loggedUser);

    const hiredRecorder = useRef(null);

    const {innerWidth: width, innerHeight: height} = window;
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    let heightVideo = hiredRecorder && hiredRecorder.current && hiredRecorder.current.clientHeight ? hiredRecorder.current.clientHeight : 0;
    const maxSmHeightVideo = 484;
    const iphoneHeight = 667;

    useEffect(() => {
        try {
            initPosterViewer();
            defineActionHeader();
            initProgressVideo();
            loadDuration();
        } catch (err) {
            alert(`Error en el visor de entrevista seguida: ${JSON.stringify(err)}`);
        }
    }, [indexActiveQuestion, url, questions, poster, showPoster, posterUrl]);

    function initPosterViewer() {
        if (showPoster === null) {
            if (poster) {
                if (isLocalVideo && isIOS) setShowPoster(true);
                else setShowPoster(false);

                if (!posterUrl) {
                    setPosterUrl(URL.createObjectURL(poster))
                }
            }
        }
    }

    const handleCurrentQuestion = (index) => {
        setIndexActiveQuestion(index);
        setRepleyTime(index);
    };

    const loadDuration = (metadata = []) => {
        if (interviewSupport.isInlineRecordingSupported) {
            let duration = 0;
            metadata = questions.length > 0 ? questions : metadata;
            metadata.map(x => duration += x.duration);
            setMaxDuration(duration);
        } else if (interviewSupport.isVideoInputSupported) {
            if (props.duration) setMaxDuration(props.duration)
        } else {
            let duration = 0;
            questions.map(x => duration += x.duration);
            setMaxDuration(duration);
        }
    }

    const setRepleyTime = (index) => {
        if (!video) initProgressVideo();
        video["dataIndex"] = index;
        let question = questions[index];
        video.currentTime = question.start;
        video.play();
    }

    function openFullscreen() {
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
            video.msRequestFullscreen();
        } else if (video.webkitEnterFullScreen) {
            video.webkitEnterFullScreen();
        } else if (video.webkitEnterFullscreen) {
            video.webkitEnterFullscreen();
        } else {
            alert("no full screen")
        }
    }

    const handleBack = () => {
        //history.push(`/seen-hired/${source}`);
        history.goBack();
    }

    function initProgressVideo() {
        video = document.getElementById("replay-interview");
        if (video) {
            video.addEventListener('timeupdate', function (v) {
                if (!this.paused) setActive(true);
                let currentIndex = this["dataIndex"];
                //currentIndex = currentIndex ? currentIndex : 0;

                if (currentIndex !== null && currentIndex !== undefined) {
                    let question = questions[currentIndex];
                    if (question) {
                        if (this.currentTime > question.start + question.duration) {
                            this.pause();
                            this["dataIndex"] = null;
                        }
                    }
                } else {
                    let currentQuestion = questions.findIndex(x => this.currentTime >= x.start && this.currentTime < x.end);
                    if (currentQuestion > -1) setIndexActiveQuestion(currentQuestion);
                }
                setCurrentTime(this.currentTime);
            });

            video.addEventListener("ended", function (e) {
                setCurrentTime(maxDuration);
                setActive(false);
            })

            video.addEventListener("pause", function (e) {
                setActive(false);
            })

            video.addEventListener("play", function (e) {
                setShowPoster(false);
            })

            video.addEventListener("loadedmetadata", (event) => {
                setVideoHeight(video.clientHeight);
            })
        }
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "back",
            callBack: handleBack
        }))
    }

    const renderApplicationWidget = () => {
        let {professional, process} = application;
        return professional && process && <Grid item xs={12}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={9}>
                    <Typography color="secondary"
                                className={classes.textApplication}>{`${professional.firstName} ${professional.lastName} ${professional.surName}`}</Typography>
                    <Paper elevation={4} className={classes.paper}>
                        <Typography color="textSecondary" className={classes.textApplication}><Translate
                            id={"Offers.Process"}/></Typography>
                        <Typography color="textSecondary"
                                    className={classes.textApplication}>{process.title}</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    }

    const renderTestWidget = () => {
        return <div></div>
        return test && <Grid item xs={12}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={9}>
                    <Typography color="secondary" className={classes.textApplication}>
                        {`${loguedUser.firstName && loguedUser.firstName} ${loguedUser.lastName && loguedUser.lastName} ${loguedUser.surName && loguedUser.surName}`}
                    </Typography>

                    <Paper elevation={4} className={classes.paper}>
                        <Typography color="textSecondary" className={classes.textApplication}>
                            <Translate id={"Tests.LanguageTestTitle"}/>
                        </Typography>
                        <Typography color="textSecondary" className={`${classes.textApplication} mt-1`}>{test.name}</Typography>
                        <Grid item xs={12} className={`${classes.mergedTitle} mb-0`}>
                            <img className={classes.imgWidget} src={resolveImage()}/>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    }

    function resolveImage() {
        if (test && test.isoCode)
            return require(`../../images/offer/languages/${test.isoCode}.png`)
        return null;
    }

    function renderTitle() {
        if (application || true)
            return <Grid item xs={12} sm={6} lg={7} md={7} className={classes.mergedTitle}>
                <Typography className={classes.title} color="textSecondary">
                    <Translate id="Tests.Title"/>
                </Typography>
                <img className={classes.imgLanguage} src={resolveImage()}/>
                <Typography className={classes.title} color="textSecondary">{test.name}</Typography>
            </Grid>
        else {
            return <Grid item xs={12} sm={6} lg={7} md={7} className={classes.mergedTitle}>
                <Typography className={classes.title} color="textSecondary">
                    <Translate id="Tests.Title"/>
                </Typography>
            </Grid>
        }
    }

    function getVideoUrl(url) {
        if (url && url.indexOf(constants.digitalocean) > -1) return `${url}#t=0.001`;
        else return url;
    }

    return (url ? <Grid container spacing={2} className={classes.px3}>
                <Grid container spacing={2}>
                    {renderTitle()}
                </Grid>
                <Grid key="1" item xs={12} sm={6} lg={7} md={7} id="recorder-container"
                      className={`${classes.recorderMargins} speaking`}>
                    <video className={classes.player} id="replay-interview" playsInline={true}
                           ref={hiredRecorder} src={getVideoUrl(url)} preload="auto">
                        <source src={getVideoUrl(url)} type="video/mp4"/>
                    </video>
                </Grid>
                <Grid item xs={12} sm={6} lg={5} md={5} className={`text-center pl-2 ${classes.paddingLeftResponse}`}>
                    <Grid container spacing={2}
                          style={screenSmUp ? {minHeight: heightVideo - (200)} : {}}>
                        {application ? renderApplicationWidget() : renderTestWidget()}

                        <div style={screenXsDown
                            ? {
                                height: `calc(100vh - ${(videoHeight + 130)}px)`,
                                overflowY: "scroll",
                                width: "100%"
                            } : (screenSmOnly && height <= maxSmHeightVideo ? {
                                height: heightVideo - 73,
                                overflowY: "scroll",
                                width: "100%"
                            } : (screenXlOnly ? {paddingTop: 16, width: "100%"} : {width: "100%"}))}>
                            <Grid item xs={12}
                                  style={width > 600 ? {minHeight: heightVideo * (0.1)} : {minHeight: "auto"}}>
                                <QuestionStepper
                                    steps={questions ? questions : []}
                                    activeStep={indexActiveQuestion}
                                    showTimes={true}
                                    className={`${classes.instructions} ${screenXsDown && (height > iphoneHeight ? "mt-3" : "mt-2")}`}
                                    hiddenTooltips={active}
                                    onclickCallback={handleCurrentQuestion}/>
                            </Grid>

                            <Hidden smUp>
                                <Grid container>
                                    <Grid item xs={12}
                                          className={`text-center ${screenSmDown ? "px-3" : "px-5"} ${screenXsDown && "py-2"} ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "py-4")}`}
                                          style={screenXlOnly ? {paddingTop: 82} : screenSmOnly ? {paddingTop: "0 !important"} : {}}>
                                        <VideoControls
                                            value={currentTime} volumen={volumen} maxValue={maxDuration}
                                            paused={video ? video.paused : true}
                                            handlePlay={() => {
                                                if (!video) initProgressVideo();
                                                video.play()
                                            }}
                                            handlePause={() => video.pause()}
                                            handleChange={(newValue) => {
                                                if (video) {
                                                    video.currentTime = newValue;
                                                    setCurrentTime(newValue);
                                                }
                                            }}
                                            handleChangeVolumen={(volumen) => {
                                                if (video) {
                                                    video.volume = volumen;
                                                    setVolumen(volumen * 10);
                                                }
                                            }}
                                            openFullscreen={openFullscreen}/>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </div>
                    </Grid>

                    <Hidden only="xs">
                        <Grid container>
                            <Grid item xs={12}
                                  className={`text-center ${screenSmDown ? "px-3" : "px-5"} ${screenXsDown && "py-2"} ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "py-4")}`}
                                  style={screenXlOnly ? {paddingTop: 82} : screenSmOnly ? {paddingTop: "0 !important"} : {}}>
                                <VideoControls
                                    value={currentTime} volumen={volumen} maxValue={maxDuration}
                                    paused={video ? video.paused : true}
                                    handlePlay={() => {
                                        if (!video) initProgressVideo();
                                        video.play()
                                    }}
                                    handlePause={() => video.pause()}
                                    handleChange={(newValue) => {
                                        if (video) {
                                            video.currentTime = newValue;
                                            setCurrentTime(newValue);
                                        }
                                    }}
                                    handleChangeVolumen={(volumen) => {
                                        if (video) {
                                            video.volume = volumen;
                                            setVolumen(volumen * 10);
                                        }
                                    }}
                                    openFullscreen={openFullscreen}/>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid> :
            <Grid container spacing={2} className="px-3">
                <Grid item xs={12}>
                    <Translate>
                        {({translate}) => (
                            <div>
                                <Typography variant="body1" gutterBottom noWrap>
                                    {translate("Errors.NoSaveInterview")}
                                    <NavLink to={"/home-interview"}>
                                        {translate("Errors.NoSavePauseInterview")}
                                    </NavLink>
                                    {translate("Errors.NoSaveOrInterview")}
                                    <NavLink to={"/run-interview"}>
                                        {translate("Errors.NoSaveRunInterview")}
                                    </NavLink>
                                </Typography>
                            </div>
                        )}
                    </Translate>
                </Grid>
            </Grid>
    );
}

export default withRouter(VideoSpeakingViewer);