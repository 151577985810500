import React, {useEffect, useState, createRef, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Typography, Hidden, useMediaQuery} from "@material-ui/core";
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import Questions from "./questions";
import QuestionsDevice from "./questionsDevice";
import TimerIndicator from "./timerIndicator";
import HiredRecorder from "./hiredRecorder";
import ConfirmDialog from "../confirmation/confirm";
import {useDispatch, useSelector} from "react-redux";
import * as types from '../../actions/users/index';
import Timer from "./customTimer";
import api from "../../api/index";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import * as interviewActions from "../../actions/interview/index";
import * as localforage from "../../indexdb/localforageManager";
import getBlobDuration from 'get-blob-duration';
import {isIOS} from "react-device-detect";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    titleInterView: {
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            marginLeft: 59
        }
    },
    paddingRightResponse: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "85px !important"
        },
    },
    video: {
        [theme.breakpoints.up('md')]: {
            //margin: "auto"
        },
        marginRight: 0
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 17,
        right: 21,
        border: "2px solid #585554",
        borderRadius: 50,
        cursor: "pointer"
    },
    icon: {
        height: 52,
        width: 53
    },
    paddingLeftResponse: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "3rem !important"
        },
    },
    topResponse: {
        [theme.breakpoints.up('md')]: {
            marginTop: "0"
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: "4rem"
        },
    },
    px3: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
            paddingRight: 5
        },
    },
    px5: {
        paddingLeft: "3rem",
        paddingRight: "3rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 15,
            paddingRight: 15
        },
    },
    recorderMargins: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    },
    marginTop: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: "0.5rem !important",
            paddingBottom: "0 !important",
            marginTop: "0.5rem !important",
            marginBottom: "0 !important"
        },
    },
    centerXs: {
        textAlign: "center",
        [theme.breakpoints.up('xl')]: {
            paddingRight: "3rem !important",
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    responsiveHeight: {
        [theme.breakpoints.up('lg')]: {
            //minHeight: window.innerHeight < 900 ? window.innerHeight  * 0.6 : "auto"
        }
    },
    py0: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    info: {
        fontSize: 18,
        fontWeight: 600
    }
}));
let hiredRecorder = null;
let metaBackups = [];
let isReady = false;
setTimeout(() => {
    isReady = true;
    let container = document.getElementById("recorder-container");
    if (container && container.hidden === true)
        container.hidden = false;
}, 2500);

function HomeInterView(props) {
    const dispatch = useDispatch();
    let classes = useStyles();
    let questionsDeviceRef = useRef(null);
    let [cancelConfirmation, setCancelConfirmation] = useState(false);
    let [endConfirmation, setEndConfirmation] = useState(false);
    let [closeConfirmation, setCloseConfirmation] = useState(false);

    const timeRecordConfiguration = useSelector(state => state.configurations.configs && state.configurations.configs.MaxRecordTime ? parseInt(state.configurations.configs.MaxRecordTime) : 180);
    let [maxValueRecord, setMaxValueRecord] = useState(timeRecordConfiguration);
    let [activeStep, setActiveStep] = useState(0);
    let [unsuportRecorder, setUnsupportRecorder] = useState(false);
    const savedMetadatas = useSelector(state => state.users.interview.metadata);

    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [recordCompleted, setRecordCompleted] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [unCancelLast, setUnCancel] = useState(false);
    const [steps, setSteps] = useState(null);
    const [chunks, setChunks] = useState([]);
    const interviewSupport = useSelector(state => state.interviews.support);
    const [videoRef, setVideoRef] = useState(null);
    const [handleNext, setHandleNext] = useState(null);
    let [recording, setRecording] = useState(false);

    const theme = useTheme();
    const {innerWidth: width, innerHeight: height} = window;
    let heightVideo = videoRef && videoRef.cameraVideo ? videoRef.cameraVideo.clientHeight : 0;
    let videoWidth = videoRef && videoRef.cameraVideo ? videoRef.cameraVideo.clientWidth : 0;
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const maxSmHeightVideo = 484;
    let isValidRecording = validateInputRecording();

    function toggleTimer() {
        setIsActive(!isActive);
    }

    useEffect(() => {
        defineActionHeader();
        loadSteps();
    }, [unCancelLast, steps, questionsDeviceRef, metadata, savedMetadatas, unsuportRecorder, activeStep, recording]);

    const loadSteps = async () => {
        if (steps === null) {
            let response = await api.interview.allQuestions();
            if (response.status === 200) {
                let result = response.data.map(x => x.text);
                setSteps(result)
            } else {
                setSteps(0);
            }
        }
    }

    async function handleStartRecorder() {
        setRecording(true);
        await localforage.clear();
        if (interviewSupport.isInlineRecordingSupported) {
            toggleTimer();
            if (hiredRecorder) {
                if (hiredRecorder.mediaRecorder) hiredRecorder.handleStopReplaying()
                else hiredRecorder.handleStartRecording()
            }
        } else if (interviewSupport.isVideoInputSupported) {
            if (hiredRecorder.handleOpenVideoInput) hiredRecorder.handleOpenVideoInput();
        }
    }

    function recordInputDevice(index) {
        setRecording(true);
        setActiveStep(index);
        if (interviewSupport.isVideoInputSupported) {
            if (hiredRecorder.handleOpenVideoInput) hiredRecorder.handleOpenVideoInput();
        }
    }

    const saveInputRecording = async (videoBlob, thumbnailBlob) => {
        if (videoBlob) {
            try {
                //1- Guardar el metadato
                let metas = Object.assign([], metadata);
                let lastmeta = metas.length > 0 ? metas[metas.length - 1] : null;
                if (lastmeta === null) {
                    await localforage.clear();
                    await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                }
                let blobMp4 = new Blob([videoBlob], {type: "video/mp4"});
                let duration = await getBlobDuration(blobMp4);

                duration = parseInt(duration);
                let start = lastmeta ? lastmeta.end : 0;
                metas.push({
                    start: start,
                    end: start + duration,
                    duration: duration,
                    durationFormat: formatDuration(duration),
                    question: steps[activeStep],
                    index: activeStep
                });
                setMetadata(metas);

                //2- Guardamos la parte de la grabacion
                let result = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                //alert(!!result)

                result = result ? result : [];
                if(result.length === 0) {
                    await localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW_POSTER, thumbnailBlob);
                }
                result.push(blobMp4);

                //await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                await localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS, result);

                if (activeStep === steps.length - 1) {
                    if (validateInputRecording(metas)) {
                        dispatch(types.setMetadata(metas));
                        window.location = "/seen-hired/manual";
                    }
                }
                let currentTime = parseInt(start) + parseInt(duration);
                setSeconds(currentTime);
            } catch (error) {
                alert(`Error -> Es probable que la cache permitida por el browser este llena: ${JSON.stringify(error)}`);
            }
        }
    }

    function validateInputRecording(metas = []) {
        let datas = metas && metas.length > 0 ? metas : metadata;
        if (datas.length > 0) {
            let lastMeta = datas[datas.length - 1];
            if (lastMeta.end > maxValueRecord) return false;
        }
        if (datas.some(x => x.duration === 0)) return false;
        return true;
    }

    function handleUnCancelRecorder() {
        setIsActive(active => false);
        if (hiredRecorder && hiredRecorder.mediaRecorder) {
            if (hiredRecorder.mediaRecorder.state === "recording") hiredRecorder.handlePauseRecording();
        }
        setUnCancel(false);
    }

    function handlePauseRecorder(indexQuestion) {
        toggleTimer();
        if (hiredRecorder) {
            hiredRecorder.handlePauseRecording();
        }
        pushMetadata(indexQuestion);
    }

    function handleResumeRecorder() {
        toggleTimer();
        if (hiredRecorder) {
            hiredRecorder.handleResumeRecording()
        }
    }

    async function handleStopRecorder() {
        setIsActive(false);
        if (hiredRecorder) {
            hiredRecorder.turnOffCamera();
            hiredRecorder.handleStopRecording();
        }
        saveMetadata();
        if (validateMetadatas()) {
            props.history.push("/seen-hired/manual");
        } else {
            props.history.push("/invalid-result");
        }
    }

    function formatDuration(secondsDuration) {
        let minutes = parseInt(secondsDuration / 60);
        let rest = secondsDuration % 60;
        return `${minutes > 9 ? minutes : `0${minutes}`}:${rest > 9 ? rest : `0${rest}`}`;
    }

    function pushMetadata(indexQuestion) {
        let lastMeta = metadata.length > 0 ? metadata[metadata.length - 1] : null;
        let start = lastMeta ? lastMeta.end : 0;
        let end = seconds;
        let duration = end - start;
        let metas = Object.assign([], metadata);

        var meta = {
            start: start,
            end: end,
            duration: duration,
            durationFormat: formatDuration(duration),
            question: steps[indexQuestion],
            index: indexQuestion
        };
        metas.push(meta);
        metaBackups = metas;
        setMetadata(metas);
        return metas;
    }

    function saveMetadata() {
        let metas = pushMetadata(metadata.length);
        dispatch(types.setMetadata(metas));
    }

    function notifyTime(s) {
        setSeconds(s);
        defineActionHeader();
        if (seconds === maxValueRecord) {
            handleStopRecorder();
            setRecordCompleted(true);
        }
    }

    function handleClosed(state) {
        setCloseConfirmation(true);
        if (state > 0) setUnCancel(true);
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: () => handleClosed(seconds)
        }))
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    function validateMetadatas() {
        if (!steps) return false;
        if (metaBackups.length !== steps.length) return false;
        if (metaBackups.some(x => x.duration === 0)) return false;
        return true;
    }

    function renderDeviceInfo() {
        return isIOS && recording === false
            ? <Translate>
                {({translate}) => {
                    return <Grid item xs={12} className="text-left px-4">
                        <Typography color="error" className={classes.info}
                                    component="span">{translate("Support.Important")}: </Typography>
                        <Typography color="textSecondary" component="span">{translate("Support.Info")}</Typography>
                        <NavLink to={"/interview/support"}>
                            <Typography color="textSecondary" component="span"
                                        style={{fontWeight: 600, textDecoration: "underline"}}>
                                {translate("Support.Link")}.
                            </Typography>
                        </NavLink>
                    </Grid>
                }}
            </Translate> : <div></div>
    }

    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (steps ? <Grid container spacing={2} className={classes.px3}>
                <Grid item xs={12} sm={6} md={7} className={`py-0 ${classes.centerXs}`}>
                    <Typography variant="h1">
                        <Translate id={"Titles.VideoInterview"}/>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={7} md={7} id="recorder-container" className={classes.recorderMargins}>
                    <HiredRecorder
                        onReady={(recorder) => {
                            hiredRecorder = recorder;
                        }}
                        onLoadedVideo={(videoCoponent) => {
                            setVideoRef(videoCoponent)
                        }}
                        onRecordingComplete={async (videoBlob, thumbnailBlob) => {
                            if (deviceSupport()) {
                                await saveInputRecording(videoBlob, thumbnailBlob);
                            } else {
                                dispatch(types.setBlob(videoBlob));
                            }
                        }}
                        notSupportRecorder={() => {
                            setUnsupportRecorder(true);
                        }}
                        notifySuccessRecorder={() => {
                            setUnsupportRecorder(false);
                        }}
                    />
                    <Timer isActive={isActive} notifyTime={notifyTime}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={5} md={5}
                      className={`text-center pl-2 ${classes.paddingLeftResponse} ${classes.py}`}>
                    <Grid container spacing={2} className={classes.topResponse}>
                        <Grid item xs={12} className={`text-center ${classes.px5} ${classes.responsiveHeight}`}
                              style={{minHeight: height > 900 ? heightVideo * 0.65 : heightVideo * 0.80}}>
                            {deviceSupport()
                                ? <QuestionsDevice steps={steps ? steps : []}
                                                   ref={questionsDeviceRef}
                                                   isValid={isValidRecording}
                                                   parentActiveStep={activeStep}
                                                   metadatas={metadata}
                                                   recordInputDevice={(index) => recordInputDevice(index)}
                                                   seconds={seconds}
                                                   heightVideo={heightVideo}
                                                   unsuportRecorder={unsuportRecorder}/>
                                : <Questions
                                    handleStartRecorder={handleStartRecorder}
                                    handlePauseRecorder={handlePauseRecorder}
                                    handleResumeRecorder={handleResumeRecorder}
                                    handleStopRecorder={() => {
                                        setEndConfirmation(true);
                                        setUnCancel(true);
                                    }}
                                    cancelRecordInterview={() => {
                                        setCancelConfirmation(true);
                                        setUnCancel(true);
                                    }}
                                    handleUnCancelRecorder={() => handleUnCancelRecorder()}
                                    recordCompleted={recordCompleted}
                                    steps={steps ? steps : []}
                                    seconds={seconds}
                                    unCancelLastStep={unCancelLast}
                                    paused={!isActive} play={isActive} value={seconds} maxValue={maxValueRecord}
                                    heightVideo={heightVideo}
                                    unsuportRecorder={unsuportRecorder}
                                />}

                            {deviceSupport()
                                ? <React.Fragment>
                                    <Grid item xs={12} lg={12}
                                          className={`text-center px-5 ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "pb-4")}`}
                                          style={screenXlOnly || screenXsOnly ? {paddingTop: 82} : {}}>
                                        <TimerIndicator key="recorder" paused={!isActive} play={isActive} value={seconds}
                                                        maxValue={maxValueRecord}/>
                                    </Grid>
                                    {renderDeviceInfo()}
                                </React.Fragment>
                                : <div></div>}
                        </Grid>

                        {((screenSmUp && height > maxSmHeightVideo)) &&
                        <Grid item xs={12} lg={12}
                              className={`text-center px-5 ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "pb-4")}`}
                              style={screenXlOnly ? {paddingTop: 82} : {}}>
                            {heightVideo > 0
                                ? <TimerIndicator
                                    key="recorder" paused={!isActive} play={isActive} value={seconds}
                                    maxValue={maxValueRecord}/>
                                : <Grid></Grid>}
                        </Grid>
                        }


                    </Grid>
                </Grid>
                <div>
                    <ConfirmDialog id="end-record" open={endConfirmation}
                                   question={<Translate id={"Confirmation.EndRecordInterview"}/>}
                                   handleCancel={() => {
                                       setEndConfirmation(false);
                                       setUnCancel(true)
                                   }}
                                   handleAccept={() => {
                                       handleStopRecorder();
                                       setEndConfirmation(false);
                                       setRecordCompleted(true)
                                   }}/>
                    <ConfirmDialog id="cancel-record" open={cancelConfirmation}
                                   question={<Translate id={"Confirmation.CancelRecordInterview"}/>}
                                   handleCancel={() => setCancelConfirmation(false)}
                                   handleAccept={() => {
                                       props.history.push("/home-interview")
                                   }}/>
                    <ConfirmDialog id="close-interview" open={closeConfirmation}
                                   question={<Translate id={"Confirmation.CloseInterview"}/>}
                                   title={seconds > 0 ?
                                       <Translate id={"Confirmation.CloseInterviewTitle"}/> : ""}
                                   handleCancel={() => setCloseConfirmation(false)}
                                   handleAccept={() => {
                                       setCloseConfirmation(false);
                                       props.history.push(`/professional/edit-profile`)
                                   }}/>
                </div>
            </Grid>
            :
            <CircularProgress/>
    );
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeInterView));