import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import imgSeenHired from "../../images/SeenHired.png";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import {Button, Hidden, Paper, useMediaQuery} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 319,
        height: 221,
        //marginTop: 19,
       // marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: 211,
            height: 145,
           // marginBottom: 40,
            marginTop: 14
        }
    },
    box: {
        borderRadius: 38,
        boxShadow: "21px 26px 85px #00000010",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
    },
    title2: {
        ...theme.overrides.MuiTypography.title2,
        fontSize: 38
    },
    subtitle3: {
        ...theme.overrides.MuiTypography.subtitle3,
    }
}));

export default function Congratulation() {
    const classes = useStyles();
    let history = useHistory();
    const handleBackProfile = () => {
        history.push("/professional/edit-profile");
    }
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (<Grid container spacing={2} className="justify-content-center">
            <Grid item xs={12} className={`${fullScreen ? 'text-center' : 'text-left'} py-0`}>
                <Typography variant="h1" gutterBottom className="text-center">
                    <Translate id={"Titles.VideoInterview"}/>
                </Typography>
            </Grid>
            <Grid item xs={12} className="py-0" style={{maxWidth: 980}}>
                <Paper elevation={fullScreen ? 0 : 3} className={`${fullScreen ? {} : classes.box}`}>
                    <Grid container spacing={2} className="text-center py-2">
                        <Hidden xsDown>
                            <Grid item xs={12}>
                                <img alt={""} src={imgSeenHired} className={classes.image}/>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <Typography variant="title2" className={classes.title2}>
                                <Translate id={"Titles.CONGRATULATIONS"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="pt-0">
                            <Translate>
                                {({translate}) => (
                                    <Typography variant="subtitle3" gutterBottom className={classes.subtitle3}>
                                        {translate("Titles.CONGRATULATIONSDescription")}
                                    </Typography>
                                )}
                            </Translate>
                        </Grid>
                        <Hidden smUp>
                            <Grid item xs={12}>
                                <img alt={""} src={imgSeenHired} className={classes.image}/>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <Translate>
                                {({translate}) => (
                                    <Button variant="contained" color="primary" onClick={handleBackProfile}>
                                        {translate("Buttons.BackProfile")}
                                    </Button>
                                )}
                            </Translate>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}