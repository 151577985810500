import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Hidden, Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const { innerWidth: width, innerHeight: height } = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 45,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
            maxWidth: 354,
        }
    },
    titlesFunctions: {
        display: "flex",
        [theme.breakpoints.down('xs')]: {
            display: "grid",
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    form: {
        marginTop: 25,
        [theme.breakpoints.down('xs')]: {
            marginTop: 16
        }
    },
    submit: {
        minWidth: 227,
        borderRadius: 0,
        marginTop: 20
    },
    buttomtext: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        fontSize: 18,
        letterSpacing: 0.7

    },
    recoverText: {
        fontSize: 18,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    recoverTextBold: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    mainTitle: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginTop: 8
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 54,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    errorText: {
        fontSize: 19,
        marginTop: 20
    },
    buttons: {
        marginTop: 20
    },
    linkedinImage: {
        width: 227,
        height: 50,
        position: "relative"
    },
    linkedinText: {
        position: "absolute",
        top: "10%",
        left: 60,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1.2,
        textAlign:"left",
        letterSpacing: 0.7
    },
    or: {
        fontSize: 20,
        fontWeight: "bold",
        paddingTop: 7,
        paddingBottom: 7
    },
    buttonsContainer: {
        maxWidth: "max-content",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tooltip: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 1
    },
    iconInfo: { width: "auto", height: "auto", position: "absolute", top: -8, right: 0, }
}));

function SuccessEmployerRegister(props) {
    let classes = useStyles();
    const dispatch = useDispatch();
    const isEmployer = window.location.pathname.indexOf("employer") > -1;

    useEffect(() => {
    }, [])

    return (<Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={10} xl={8} className={classes.titles}>
            <Typography color="textSecondary" className={classes.mainTitle}>{isEmployer ? <Translate id={"Employer.EmployerRegisterTitle"} /> : <Translate id={"Professional.EmployerRegisterTitle"} />} </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
            <Typography className={classes.recoverText}>{<Translate id={"Employer.RegisterEmployerSuccess"} />} </Typography>
            <Typography className={`${classes.recoverText} pt-3 pb-2`}>{<Translate id={"Employer.RegisterEmployerSuccess2"} />} </Typography>
            <Grid item xs={12} className={`${classes.buttonsContainer} pt-3 pb-5`}>
                <NavLink to="/">
                    <Button className={classes.submit} variant="contained" color={isEmployer ? "secondary" : "primary"} >
                        <Typography className={classes.buttomtext} style={isEmployer ? {} : {color: "#FFFFFF"}}><Translate id={"Buttons.CloseWindows"} /></Typography>
                    </Button>
                </NavLink>
            </Grid>
        </Grid>

    </Grid>);
}

export default withRouter(SuccessEmployerRegister);