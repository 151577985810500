const initialState = {
}

function workReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state
    }
}

export default workReducer;