import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {Button, Toolbar, Typography, useMediaQuery} from "@material-ui/core";
import {Twitter, Facebook, Instagram, IconButton} from '@material-ui/icons';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../actions/users/index";
import {NavLink} from "react-router-dom";
import {isIOS} from "react-device-detect";
import ConfirmDialog from "../confirmation/confirm";
import * as permissions from "../../utils/permissions";
import {withRouter} from "react-router-dom";
import api from "../../api/index";
import {staticContent} from "../../utils/constants";
import CarouselHome from "./carousel";
import HowToWork from "./howToWork/index";

const useStyles = makeStyles((theme) => ({
    title1: {
        font: "normal normal 600 50px/93px Barlow",
        letterSpacing: 0,
        [theme.breakpoints.down('xs')]: {
            fontSize: 39,
            marginLeft: 0,
            marginTop: 0
        },
        marginLeft: "10%",
        lineHeight: 1,
        color: theme.palette.primary.main,
        marginBottom: 0
    },
    title2: {
        font: "normal normal 300 30px/72px Barlow",
        letterSpacing: 0,
        [theme.breakpoints.down('xs')]: {
           // fontSize: 33,
            marginLeft: 0,
        },
        [theme.breakpoints.down('lg')]: {
            marginBottom: 8
        },
        marginLeft: "10%",
        marginBottom: 16,
        lineHeight: 1,
        color: theme.palette.secondary.main,

    },
    logo: {
        width: 97,
        [theme.breakpoints.down('sm')]: {
            width: 0,
            marginLeft: "8%",
        },
    },
}));

function Home(props) {
    let [professionalData, setProfessionalData] = useState([]);
    let [employerData, setEmployerData] = useState([]);
    let classes = useStyles();

    useEffect(() => {
        api.home.getContent(staticContent.HowToWork).then(response => {
            setProfessionalData(response[staticContent.Professionals]);
            setEmployerData(response[staticContent.Employers]);
        });
    })

    const theme = useTheme();
    const screenMdDow = useMediaQuery(theme.breakpoints.down('md'));

    return (<Grid container spacing={0} >
        <Grid container direction="row"
              justify="center">
            <Grid item xs={12} sm={10} md={10} lg={10} xl={11} className="d-flex">
                <div className={classes.logo}/>
                <h1 className={classes.title1} >
                    {<Translate id={"Titles.OnlineSelection"}/>}
                </h1>
            </Grid>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={11} className="d-flex">
                <div className={classes.logo}/>
                <h2 className={classes.title2}>
                    {<Translate id={"Titles.SeenHired"}/>}
                </h2>
            </Grid>
        </Grid>
        <CarouselHome/>
        <HowToWork professionalData={professionalData} employerData={employerData}/>
    </Grid>);
}

export default withRouter(Home);