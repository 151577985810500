export const SET_ERROR_TEXT = "SET_ERROR_TEXT";
export const SET_ERROR_TITLE = "SET_ERROR_TITLE";
export const MESSAGE_TYPE = "MESSAGE_TYPE";
export const OPEN = "OPEN";

export function fetchSetErrorText(error) {
    return {
        type: SET_ERROR_TEXT,
        payload: error
    }
}

export function fetchSetErrorTitle(error) {
    return {
        type: SET_ERROR_TITLE,
        payload: error
    }
}

export function fetchOpen(open) {
    return {
        type: OPEN,
        payload: open
    }
}

export function fetchMessageType(type) {
    return {
        type: MESSAGE_TYPE,
        payload: type
    }
}