import * as types from "../actions/users/index";
import * as localforage from "../indexdb/localforageManager";

const initialState = {
  loggedUser: null,
  interview: {
    metadata: [],
    blob: null,
    blobUrl: null,
    thumbnailUrl: null,
  },
  speakingTest: {
    metadata: [],
    attempts: 0,
  },
  defaultLanguage: null,
  selectedInterview: "/",
  offerId: null,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_LOGIN:
      return {
        ...state,
        loggedUser: action.payload,
      };

    case types.SET_INTERVIEW_METADATA:
      return {
        ...state,
        interview: {
          ...state.interview,
          metadata: action.payload,
        },
      };

    case types.SET_SPEAKING_METADATA:
      return {
        ...state,
        speakingTest: {
          ...state.speakingTest,
          metadata: action.payload,
        },
      };

    case types.RESET_SPEAKING:
      return {
        ...state,
        speakingTest: {
          ...state.speakingTest,
          metadata: [],
        },
      };

    case types.SET_INTERVIEW_BLOB:
      //localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW, new Blob([action.payload], { type: "video/webm;codecs=vp8" }));
      localforage.saveItem(
        localforage.PROFESSIONAL_INTERVIEW,
        new Blob([action.payload], { type: "video/mp4" })
      );
      return state;

    case types.SET_SPEAKING_ATTEMPTS:
      return {
        ...state,
        speakingTest: {
          ...state.speakingTest,
          attempts: action.payload,
        },
      };

    case types.SAVE_THUMBNAIL_URL:
      return {
        ...state,
        interview: {
          ...state.interview,
          thumbnailUrl: action.payload,
        },
      };

    case types.SET_MERGE_BLOB:
      localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW, action.payload);
      return {
        ...state,
        interview: {
          ...state.interview,
          blob: action.payload,
          blobUrl: URL.createObjectURL(action.payload),
        },
      };

    case types.RESET_INTERVIEW:
      localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW);
      localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW_POSTER);
      return {
        ...state,
        interview: {
          ...state.interview,
          blob: null,
          thumbnailUrl: null,
        },
      };

    case types.SET_DEFAULT_LANGUAGE:
      //localStorage.setItem("defaultLanguage", action.payload);
      return {
        ...state,
        defaultLanguage: action.payload,
      };

    case types.SET_SELECTED_INTERVIEW:
      return {
        ...state,
        selectedInterview: action.payload,
      };

    case types.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        loggedUser: null,
        interview: {
          metadata: [],
          blob: null,
          blobUrl: null,
          thumbnailUrl: null,
        },
      };

    case types.SET_CURRENT_OFFERID:
      return state;

    default:
      return state;
  }
}

export default userReducer;
