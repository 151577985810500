import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Card, CardActionArea, Typography, Grid, Paper, useMediaQuery} from '@material-ui/core';


export default function BenefitCard(props) {
    let {data, index, scope} = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 380,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 10,
            }
        },
        media: {
            /*width: 120,
            height: 130,
            objectFit: "contain",*/
            [theme.breakpoints.down('sm')]: {
                width: 90,
                marginTop: 25,

            },
        },
        area: {
            paddingTop: 40,
            paddingBottom: 20,
            minHeight: 300,
            [theme.breakpoints.down('xs')]: {
                minHeight: scope === "professional" ? window.innerHeight < 640 ?  233 : 189 : 180,
                paddingTop: 0,
                paddingBottom: 0,
                display: "flex",
                alignItems: "flex-end",
            },
            position: "relative",
            [theme.breakpoints.only('sm')]: {
                display: "flex",
                alignItems: "flex-end",

            },
        },
        imageContainer: {
            textAlign: "center",
            [theme.breakpoints.only('sm')]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"

            },
        },
        text: {
            fontSize: 17,
            textAlign: "center",
            paddingLeft: 28,
            '& a, a:hover': {
                color: theme.palette.primary.main,
                fontWeight: "bold",
                textDecoration: "underline !important"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                textAlign: "left",
                paddingLeft: 12,
            },
            [theme.breakpoints.only('sm')]: {
                textAlign: "left",
                paddingLeft: 0,
            },
        },
        segment1: {
            width: 60,
            height: 5,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        segment2: {
            width: 160,
            height: 1,
            background: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
        },
        index: {
            position: "absolute",
            color: scope === "professional" ? "#DAEEF5" : "#FDEBDB",
            left: 60,
            top: 0,
            fontSize: 71,
            fontWeight: "bold",
            [theme.breakpoints.only('xs')]: {
                left: 0,
                top: scope === "professional" ? window.innerHeight < 640 ?  5 : -20 : -20
            },
            [theme.breakpoints.only('sm')]: {
                left: 0,
                top: 37
            },

        },
        cardContent: {
            padding: 16,
            paddingBottom: 24,
            [theme.breakpoints.down('xs')]: {
                padding: 5,
                paddingBottom: 10,
                position: "absolute",
                right: 0,
                bottom: 0
            }
        },
        segments: {
            [theme.breakpoints.down('xs')]: {
                paddingTop: 10
            }
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const screenSmDow = useMediaQuery(theme.breakpoints.down('lg'));

    function responseImages(name) {
        if (screenSmDow)
            return require(`../../../images/layuout/benefits/${name}Movil${index}.png`)
        else
            return require(`../../../images/layuout/benefits/${name}${index}.png`)
    }

    const resolveImage = () => scope === "professional"
        ? responseImages("p")
        : responseImages("e");

    return (
        <Paper className={classes.root} elevation={0}>
            <Paper elevation={0} type="" className={classes.area}>
                <Grid container spacing={0}>
                    <Grid item xs={4} md={12} className={classes.imageContainer}>
                        <img title={data.alt}  alt={data.alt} src={resolveImage()}/>
                    </Grid>

                    <Grid item xs={8} md={12} className={classes.cardContent}>
                        <Typography className={classes.text}>
                            <div dangerouslySetInnerHTML={{__html: data.text}}/>
                        </Typography>
                    </Grid>

                    <Grid className={classes.segments} container spacing={2} direction="row" justify="center"
                          alignItems="center">
                        <Grid item xs={12} md={7} style={{display: "flex"}}>
                            <div className={classes.segment1}></div>
                            <div className={classes.segment2}></div>
                        </Grid>
                    </Grid>
                </Grid>

                <Typography className={classes.index}>{index}</Typography>
            </Paper>
        </Paper>
    );
}