import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";


const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "60vh"
    },
    text:{
        fontSize: 150,
        fontWeight: 700,
        lineHeight: 1
    }
}));

const NotFound = () => {
    let classes = useStyles();
    return <Grid className={classes.container} container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} className="text-center">
            <Typography className={classes.text}>404</Typography>
            <Typography variant="body1" className="font-weight-semibold"><em><Translate id="Errors.ResourceNotFound"/></em></Typography>
        </Grid>
    </Grid>
}

export default NotFound;