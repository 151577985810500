import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import imgSeenHired from "../../images/SeenHired.png";
import ConfirmDialog from "../confirmation/confirm";
import {useDispatch, useSelector, connect} from "react-redux";
import * as types from '../../actions/users/index';
import * as interviewTypes from '../../actions/interview/index';
import {withRouter, useHistory} from "react-router-dom";
import {Button, CircularProgress, Paper, useMediaQuery} from "@material-ui/core";
import * as localforage from "../../indexdb/localforageManager";
import api from "../../api/index";
import UploadProgress from "./uploadProgress";
import * as interviewActions from "../../actions/interview/index";
import * as constants from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1311,
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 26,
        right: 26,
        minWidth: 63,
        width: 63,
        height: 63
    },
    button: {
        width: "90%",
        lineHeight: 1,
        padding: "0 24px",
        height: 61,
        [theme.breakpoints.up('md')]: {
            width: "100%"
        },
        [theme.breakpoints.down('xs')]: {
            height: 46
        },
    },
    icon: {
        height: 63,
        width: 63
    },
    box: {
        borderRadius: 38,
        boxShadow: "21px 26px 85px #00000010",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
    },
    titleInterView: {
        textAlign: "center",
        fontWeight: "bold",
        //marginLeft: 59
    },
    subTitleInterView: {
        fontSize: 49,
        textAlign: "center",
        fontWeight: 400,
        letterSpacing: "0.34px",
        [theme.breakpoints.down('sm')]: {
            fontSize: 34,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            letterSpacing: "0.28px",
        },
    },
    progress: {
        /*top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.5,
        background: "white",
        transition: "opacity 225ms cubic- bezier(0.4, 0, 0.2, 1) 0ms"*/
    },
    image: {
        width: "75%",
        [theme.breakpoints.up('md')]: {
            width: 319,
            height: 221,
        }
    },
    padding: {
        padding: "2rem 3rem",
        //paddingTop: 113,
        justifyContent: "center",
        //paddingBottom: 96,
        [theme.breakpoints.down('sm')]: {
            padding: "1rem",
            textAlign: "center"
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0",
            textAlign: "center"
        },
    },
    buttonTop: {
        marginBottom: "1rem",
        marginTop: "1rem",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "0.25rem",
            marginTop: "0.25rem",
        },
    }
}));

function ModalConfirm({open, setCancelConfirmation, handleDeleteInterviewRecord}) {
    return (<Grid item xs={12}>
        <ConfirmDialog id="delete-interview" open={open}
                       question={<Translate id={"Confirmation.DeleteInterview"}/>}
                       handleCancel={() => setCancelConfirmation(x => false)}
                       handleAccept={handleDeleteInterviewRecord}/>
    </Grid>)
}

function SeenHired(props) {
    const classes = useStyles();
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [exitConfirmation, setExitConfirmation] = useState(false);
    const [unsavedInterview, setUnsavedInterview] = useState(false);
    const [existConfirmation, setExistConfirmation] = useState(false);
    const [upLoading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [professionalInterview, setProfessionalInterview] = useState(null);
    const interview = useSelector(state => state.users.interview);
    const dispatch = useDispatch();
    const interviewSupport = useSelector(state => state.interviews.support);
    const reconfigurations = useSelector(state => state.interviews.configuration);
    const metadatas = useSelector(state => state.users.interview.metadata)
    const [segmentIndex, setSegmentIndex] = useState(0);
    const [totalSegments, setTotalSegments] = useState(0);
    const source = props.match.params["interview"] ? props.match.params["interview"] : "";

    useEffect(() => {
        defineActionHeader();
        loadUserInterview();
    }, [unsavedInterview, progress, totalSegments, segmentIndex])

    const loadUserInterview = () => {
        if (metadatas.length > 0) setUnsavedInterview(true);
        else setUnsavedInterview(false);
    }

    const handleSeeInterview = () => {
        window.location = `/seen-interview/${source}`;
        //props.history.push(`/seen-interview/${source}`);
    };

    const handleAddInterviewProfile = () => {
        api.interview.getProfessionalInterview().then(async (response) => {
            if (!response.isAxiosError) {
                if (!response.data) {
                    setUploading(true);
                    if (deviceSupport()) {
                        let localVideo = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW);
                        if (localVideo) {
                            await handleAddInterview();
                        } else {
                            let segments = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                            if (segments && segments.length > 0) {
                                await handleAddSegmentsInterview();
                            }
                        }
                    } else {
                        await handleAddInterview();
                    }
                    setUploading(false);
                } else {
                    setProfessionalInterview(response.data);
                    setExistConfirmation(true);
                }
            }
        }).catch((error) => {
            console.log("ERRROR", error);
        })
    };

    async function handleDeleteInterviewRecord() {
        try {
            dispatch(types.resetInterview());
            await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW);
            await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);

            if (source === constants.interviewTypes.continuous) props.history.push("/run-interview/configure");
            else props.history.push("/home-interview");
        } catch (err) {
            alert(JSON.stringify(err));
        }
    }

    const handleClosed = () => {
        setExitConfirmation(true);
    }

    const handleExitInterview = () => {
        dispatch(types.resetInterview());
        localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW).then((del) => {
            if (source === constants.interviewTypes.continuous) props.history.push("/run-interview/configure");
            else props.history.push("/home-interview");
        }).catch((err) => {
            console.log(err);
        });
    }

    function notifyProgressCallback(upload) {
        let {total, loaded} = upload;
        let percent = loaded / total * 100;
        setProgress(progres => percent);
    }

    function notifyProgressSegmentCallback(upload) {
        let {total, loaded} = upload;
        let partPercent = totalSegments > 0 ? (100 / totalSegments) : 0;
        if (totalSegments > 0) {
            let partialPercent = (loaded / total * 100) / totalSegments;
            let totalPercent = ((segmentIndex - 1) * partPercent) + partialPercent;
            setProgress(progres => totalPercent);
        }
    }

    async function removeProfessionalInterview() {
        if (professionalInterview && professionalInterview.videoKey) {
            if (professionalInterview.videoKey && professionalInterview.videoKey.indexOf(".mp4") >= 0) {
                let deleted = await api.interview.removeVideoInterview(professionalInterview.videoKey);
                if (deleted) setProfessionalInterview(null);
            } else {
                if (professionalInterview.metadata) {
                    for (let i = 0; i < professionalInterview.metadata.length; i++) {
                        const meta = professionalInterview.metadata[i];
                        if (meta.videoKey) {
                            await api.interview.removeVideoInterview(meta.videoKey);
                        }
                    }
                    setProfessionalInterview(null);
                }
            }
        }
    }

    const handleAddInterview = async (verify = false) => {
        try {
            let videoBlob = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW);
            if (videoBlob) {
                if (verify && professionalInterview.videoKey) {
                    await removeProfessionalInterview()
                }
                let upload = await api.interview.uploadInterview(videoBlob, notifyProgressCallback);
                if (upload) {
                    let response = await api.interview.createInterview({
                        videoUrl: upload.Location,
                        videoKey: upload.key,
                        metadata: interview.metadata,
                        duration: 0
                    })
                    if (response.status === 200 || response.status === 204) {
                        dispatch(types.resetInterview());
                        dispatch(interviewTypes.resetUnsupportConfiguration());
                        await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW);
                        props.history.push("/congratulations-interview");
                    }
                } else {
                    setUploading(false);
                    setProgress(0);
                    console.log("ERROR UPLOADING FILE");
                    alert("ERROR");
                }
            } else {
                setUploading(false);
                setProgress(0);
            }
        } catch (err) {
            setUploading(false);
            setProgress(0);
            console.log("ERROR UPLOADING FILE");
            alert("ERROR");
        }
    }

    const handleAddSegmentsInterview = async (verify = false) => {
        try {
            let videoSegments = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
            let metadataCopy = Object.assign([], metadatas);
            if (videoSegments) {
                if (verify && professionalInterview.videoKey) {
                    await removeProfessionalInterview();
                }
                setTotalSegments(videoSegments.length);
                let keyFolder = Math.floor(Math.random() * 100000000000000000)
                for (let i = 0; i < videoSegments.length; i++) {
                    setSegmentIndex(i + 1);
                    let video = videoSegments[i];
                    let upload = await api.interview.uploadSegmentInterview(video, keyFolder);
                    let percent = ((i + 1) / videoSegments.length) * 100;
                    setProgress(progres => percent);
                    if (upload) {
                        metadataCopy[i] = {
                            ...metadataCopy[i],
                            videoUrl: upload.Location,
                            videoKey: upload.key
                        };
                    }
                }
                let response = await api.interview.createInterview({
                    videoUrl: "segment",
                    videoKey: `interviews_dev/${keyFolder}`,
                    metadata: metadataCopy,
                    duration: 0
                })
                if (response.status === 200 || response.status === 204) {
                    dispatch(types.resetInterview());
                    dispatch(interviewTypes.resetUnsupportConfiguration());
                    await localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                    props.history.push("/congratulations-interview");
                }
            } else {
                setUploading(false);
                setProgress(0);
            }
        } catch (err) {
            alert(`Error-> ${JSON.stringify(err)}`)
        }
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: handleClosed
        }))
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    return (<Grid container spacing={2}>
        <Grid item xs={12} className="py-0">
            <Typography variant="h1" gutterBottom className={`${classes.titleInterView}`}>
                <Translate id={"Titles.VideoInterview"}/>
            </Typography>
        </Grid>
        <Grid item xs={12} className="py-0">
            <Paper elevation={xsScreen ? 0 : 3} className={`${xsScreen ? {} : classes.box}`}>
                <Grid container spacing={2} id="conatinerRef" className={classes.padding}>
                    <Grid item xs={12} sm={6} md={6} className={"text-center"}>
                        <Typography variant="title1" gutterBottom className={`mb-5 ${classes.subTitleInterView}`}>
                            <Translate id={"Titles.CompletedInterview"}/>
                        </Typography>
                        <img alt={"suggest"} src={imgSeenHired} className={classes.image}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid container spacing={2} className="justify-content-center">
                            <Grid item xs={12} md={8}>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" onClick={handleSeeInterview}
                                                    disabled={upLoading}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.SeeInterview")}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" hidden={upLoading}
                                                    disabled={upLoading || !unsavedInterview}
                                                    onClick={handleAddInterviewProfile}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.AddInterviewProfile")}
                                                {upLoading &&
                                                <CircularProgress size={24} style={{marginLeft: 10, marginTop: -6}}
                                                                  color="secondary"/>}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" disabled={upLoading}
                                                    onClick={() => setCancelConfirmation(x => true)}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.DeleteInterviewRecord")}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {upLoading && <Grid item xs={12} className="p-5" style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%", padding: "0 !important",
                        opacity: 0.9,
                        background: "white",
                        zIndex: 5000
                    }}>
                        <div style={{
                            position: "relative", justifyContent: "center", display: "flex",
                            flexWrap: "wrap", width: "100%", height: "100%"
                        }}>
                            <UploadProgress value={progress} hidden={!upLoading}/>
                        </div>
                    </Grid>}

                </Grid>
            </Paper>
        </Grid>

        <ModalConfirm open={cancelConfirmation}
                      setCancelConfirmation={setCancelConfirmation}
                      handleDeleteInterviewRecord={handleDeleteInterviewRecord}/>

        <ConfirmDialog id="exit-interview" open={exitConfirmation}
                       question={<Translate id={"Confirmation.DeleteInterview"}/>}
                       title={unsavedInterview ? <Translate id={"Confirmation.ExitInterviewTitle"}/> : null}
                       handleCancel={() => setExitConfirmation(x => false)}
                       handleAccept={handleExitInterview}/>

        <ConfirmDialog
            id="exist-interview" open={existConfirmation}
            question={<Translate id={"Confirmation.ExistInterview"}/>}
            title={<Translate id={"Confirmation.ExistInterviewTitle"}/>}
            handleCancel={() => setExistConfirmation(x => false)}
            handleAccept={async () => {
                setExistConfirmation(false);
                setUploading(true);
                if (deviceSupport()) {
                    let localVideo = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW);
                    if (localVideo) {
                        await handleAddInterview(true);
                    } else {
                        let segments = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);
                        if (segments && segments.length > 0) {
                            await handleAddSegmentsInterview(true);
                        }
                    }
                } else {
                    await handleAddInterview(true);
                }
            }}/>
    </Grid>);
}

const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeenHired));