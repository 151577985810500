import * as constants from "./constants";

const permissionsNames = [
    "camera",
    "microphone",
]

export async function permissionsConceded() {
    if(!navigator.permissions) return true;
    let allpermissions = await getAllPermissions();
    return allpermissions.filter(x => x.state === constants.permissionsStates.granted).length === permissionsNames.length;
}


export const getAllPermissions = async () => {
    const allPermissions = []
    // We use Promise.all to wait until all the permission queries are resolved
    if(!navigator.permissions) return allPermissions;
    await Promise.all(
        permissionsNames.map(async permissionName => {
            try {
                let permission
                switch (permissionName) {
                    case 'push':
                        // Not necessary but right now Chrome only supports push messages with  notifications
                        permission = await navigator.permissions.query({ name: permissionName, userVisibleOnly: true })
                        break
                    default:
                        permission = await navigator.permissions.query({ name: permissionName })
                }
                allPermissions.push({ permissionName, state: permission.state })
            }
            catch (e) {
                allPermissions.push({ permissionName, state: 'granted', errorMessage: e.toString() })
            }
        })
    )
    return allPermissions
}