import React, {useState} from 'react';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {Button, Typography, Container, useMediaQuery} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import {isIOS} from "react-device-detect";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            marginTop: 40,
            marginBottom: 40,
            '& ::marker': {
                fontSize: 15
            }
        },
        titleSection: {
            fontSize: 25,
            fontWeight: 600,
            [theme.breakpoints.only('xs')]: {
                fontSize: 20
            },
        },
        subTitleSection: {
            fontSize: 18,
            fontWeight: 600,
            marginTop: 30,
            [theme.breakpoints.only('xs')]: {
                fontSize: 16,
                marginTop: 20
            }
        },
        question: {
            fontSize: 16,
            fontWeight: 400,
            marginTop: 15,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            }
        },
        advicePoint: {
            fontSize: 16,
            fontWeight: 400,
            marginBottom: 12,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            }
        },
        questionContainer: {
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            }
        },
        hr: {
            width: 160,
            height: 1,
            backgroundColor: theme.palette.text.secondary,
            margin: 0,
            marginTop: 10,
            [theme.breakpoints.only('xs')]: {
                width: 112
            }
        },
        sectionTop: {
            marginTop: 32,
            [theme.breakpoints.only('xs')]: {
                marginTop: 20
            }
        },
        subSectionTop: {
            marginTop: 23,
            [theme.breakpoints.only('xs')]: {
                left: 25,
                marginTop: 20
            }
        },
        modalityType: {
            fontSize: 18,
            fontWeight: 600,
            marginTop: 25,
            position: "absolute",
            '& .orange': {
                color: theme.palette.secondary.main,
            },
            [theme.breakpoints.only('xs')]: {
                left: 25,
                marginTop: -32
            }
        },
        number: {
            fontSize: 200,
            fontWeight: 800,
            color: "#F7F7F7",
            lineHeight: 1,
            [theme.breakpoints.only('xs')]: {
                fontSize: 175,
                fontWeight: 500,
                position: "absolute",
                zIndex: -1,
                right: 0
            }
        },
        smallNumber: {
            fontSize: 96,
            fontWeight: 800,
            color: "#F7F7F7",
            lineHeight: 0.7,
            float: "right",
            marginRight: 10
        },
        modalityContainer: {
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            [theme.breakpoints.only('xs')]: {
                justifyContent: "flex-end",
                minHeight: isIOS ? 70 : 125
            }
        },
        segment: {
            height: 100,
            border: "1px solid #141414",
            marginRight: 18,
        },
        modalityPoint: {
            ...theme.overrides.MuiTypography.body1,
            marginBottom: 18,
            maxWidth: 500,
            [theme.breakpoints.only('xs')]: {
                marginBottom: 2
            }
        },
        topModalityPoint: {
            marginTop: -60
        },
        button: {
            minWidth: 220,
            width: 255,
            borderRadius: 0,
            height: 50,
            lineHeight: 1,
            marginRight: 20,
            marginBottom: 10,
            [theme.breakpoints.only('xs')]: {
                marginRight: 0
            }
        },
        buttonLabel: {
            fontWeight: "bold",
            fontSize: 18,
        },
        buttonLabelSecondary: {
            fontWeight: "bold",
            fontSize: 18,
            color: theme.palette.text.secondary
        },
        floatModality: {
            minHeight: 200,
            display: "flex",
            [theme.breakpoints.only('xs')]: {
                minHeight: 100,
                marginBottom: 20
            }
        },
        ulContent: {
            "& li": {
                marginLeft: 13
            }
        },
        centerXs: {
            textAlign: "center"
        },
        indications: {
            fontWeight: 600
        }
    }
});

function Info(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenMdOnly = useMediaQuery(theme.breakpoints.only('md'));
    let {info} = props;
    let questions = info ? info.Questions : null;
    let indications = info ? info.Indications : null;
    let modalities = info ? info.Modalities : null;
    let advices = info ? info.Advices : null;


    return (<React.Fragment>
        <Container className={classes.container} maxWidth={screenXlOnly ? "xl" : "md"}
                   style={isIOS ? {marginBottom: 0} : {}}>
            {questions && <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography className={classes.titleSection} variant="h1"
                                color="textSecondary">{questions.Title}</Typography>
                    <hr className={classes.hr}/>

                    <Typography className={classes.subTitleSection} variant="h1"
                                color="textSecondary">{questions.Caption}</Typography>
                    <Grid className={`${classes.questionContainer} d-flex`}>
                        <div className={classes.segment} style={{marginTop: 15}}></div>
                        <ul className="pl-3">
                            {questions.Questions.map(x => {
                                return <li><Typography className={classes.question} variant="h1"
                                                       color="textSecondary">{x}</Typography></li>
                            })}
                        </ul>
                    </Grid>
                </Grid>
            </Grid>}

            {indications &&
            <Grid container direction="row" justify="center" alignItems="center" className={classes.sectionTop}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography className={classes.titleSection} variant="h1"
                                color="textSecondary">{indications.Title}</Typography>
                    <hr className={classes.hr}/>

                    <Grid>
                        <ul className="pl-3">
                            {indications.Points.map(x => {
                                return <li><Typography className={`${classes.question} ${classes.indications}`}
                                                       variant="h1" color="textSecondary">{x}</Typography></li>
                            })}
                        </ul>
                    </Grid>
                </Grid>
            </Grid>}
        </Container>

        <Container maxWidth={screenXlOnly ? "xl" : "lg"}>
            {modalities &&
            <Grid container direction="row" justify="center" alignItems="center" className={classes.sectionTop}
                  style={isIOS ? {marginTop: -20} : {}}>
                {modalities.Title && <Grid item xs={12} lg={10} xl={7}>
                    <Typography className={classes.titleSection} variant="h1"
                                color="textSecondary">{modalities.Title}</Typography>
                    <hr className={classes.hr}/>
                </Grid>}

                <Grid item xs={12} md={12} lg={11} xl={9}>
                    <Grid container>
                        {modalities.Continuosly && <Grid item xs={12} md={6}>
                            <Grid item xs={12} lg={10} xl={7} className="mt-4" hidden={!isIOS}>
                                <Typography className={classes.titleSection} variant="h1" color="textSecondary">
                                    <span className={classes.titleSection}
                                          dangerouslySetInnerHTML={{__html: modalities.Continuosly.Title}}/>
                                </Typography>
                                <hr className={classes.hr}/>
                            </Grid>

                            <div className={classes.modalityContainer}>
                                <span className={classes.number} hidden={isIOS}>1</span>
                                <div hidden={isIOS} className={classes.modalityType}
                                     dangerouslySetInnerHTML={{__html: modalities.Continuosly.Title}}/>
                            </div>

                            <Grid className={classes.topModalityPoint} container direction="row" justify="flex-end"
                                  alignItems="center">
                                <Grid item xs={12} md={10} lg={9} xl={10} className={classes.floatModality}>
                                    <div className={classes.segment}></div>
                                    <div>
                                        {modalities.Continuosly.Point.map(point => {
                                            return <React.Fragment>
                                                <Typography className={classes.modalityPoint}>
                                                    <span className={classes.modalityPoint}
                                                          dangerouslySetInnerHTML={{__html: point}}/>
                                                </Typography>
                                                <div style={{height: 10}}></div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                </Grid>
                                <Grid className={classes.centerXs} item xs={12} md={12} lg={12} xl={10}>
                                    <NavLink to="/interview/how-work/manual">
                                        <Button classes={{label: classes.buttonLabel}} className={classes.button}
                                                variant="contained" color="primary" type="submit">
                                            <Translate id={"Buttons.HowWork"}/>
                                        </Button>
                                    </NavLink>

                                    <NavLink to="/home-interview">
                                        <Button classes={{label: classes.buttonLabel}} className={classes.button}
                                                variant="contained" color="primary" type="submit">
                                            {isIOS ? <Translate id={"Buttons.GoInterviewIphone"}/> :
                                                <Translate id={"Buttons.GoInterview"}/>}
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Grid>}

                        {modalities.Paused && <Grid item xs={12} md={6}>
                            <div className={classes.modalityContainer}>
                                <span hidden={isIOS} className={classes.number}>2</span>
                                <div className={classes.modalityType}
                                     dangerouslySetInnerHTML={{__html: modalities.Paused.Title}}/>
                            </div>

                            <Grid className={classes.topModalityPoint} container direction="row" justify="flex-end"
                                  alignItems="center">
                                <Grid item xs={12} md={10} lg={9} xl={10} className={classes.floatModality}>
                                    <div className={classes.segment}></div>
                                    <div>
                                        {modalities.Paused.Point.map(point => {
                                            return <Typography className={classes.modalityPoint}>{point} </Typography>
                                        })}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={10} className={classes.centerXs}>
                                    <NavLink to="/interview/how-work/continuous">
                                        <Button classes={{label: classes.buttonLabelSecondary}}
                                                className={classes.button}
                                                variant="contained" color="secondary" type="submit">
                                            <Translate id={"Buttons.HowWork"}/>
                                        </Button>
                                    </NavLink>

                                    <NavLink to="/run-interview/configure">
                                        <Button classes={{label: classes.buttonLabelSecondary}}
                                                className={classes.button}
                                                variant="contained" color="secondary" type="submit">
                                            <Translate id={"Buttons.GoInterviewContinuosly"}/>
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>}
        </Container>

        <Container maxWidth={screenXlOnly ? "xl" : "md"}>
            {advices &&
            <Grid container direction="row" justify="center" alignItems="center" className={classes.sectionTop}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography className={classes.titleSection} variant="h1"
                                color="textSecondary">{advices.Title}</Typography>
                    <hr className={classes.hr}/>
                </Grid>

                <Grid item item xs={12} md={11} xl={9}>
                    <Typography className={classes.subTitleSection}>{advices.Section1.Title}</Typography>
                </Grid>

                <Grid item xs={12} xl={11} lg={12} className={classes.subSectionTop}>
                    <Grid container>
                        <Grid item md={2} xs={0}></Grid>
                        <Grid item md={10} xs={12}>
                            <Grid item xs={12} md={10} lg={9} xl={10} className="d-flex">
                                <div className={classes.segment}></div>
                                <div>
                                    <ul className="pl-3">
                                        {advices.Section1.Points.map(point => {
                                            return <li><Typography
                                                className={`${classes.question} mt-0`}>{point} </Typography></li>
                                        })}
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}

            {advices && <Grid container direction="row" justify="center" alignItems="center">
                <Grid item item xs={12} md={11} xl={9}>
                    <Typography className={classes.subTitleSection}>{advices.Section2.Title}</Typography>
                </Grid>

                <Grid item xs={12} xl={11} lg={12} className={classes.subSectionTop}>
                    {advices.Section2.Points.map((points, index) => {
                        return <Grid container className="mt-3">
                            <Grid item xs={2}>
                                <span className={classes.smallNumber}>{index + 1}</span>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid item xs={12} md={10} lg={9} xl={10} className="d-flex">
                                    {index === 0 ? <div className={classes.segment}></div> :
                                        <div style={{marginRight: 14}}></div>}
                                    <div className={classes.ulContent}>
                                        {points.map((point, i) => {
                                            return <div className={classes.advicePoint}
                                                        dangerouslySetInnerHTML={{__html: point}}/>
                                        })}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </Grid>}

            {advices && <Grid container direction="row" justify="center" alignItems="center">
                <Grid item item xs={12} md={11} xl={9}>
                    <Typography className={classes.subTitleSection}>{advices.Section3.Title}</Typography>
                </Grid>

                <Grid item xs={12} xl={11} lg={12} className={classes.subSectionTop}>
                    <Grid container className="mt-3">
                        <Grid item md={2} xs={0}>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Grid item xs={12} md={10} lg={9} xl={10} className="d-flex">
                                <div className={classes.segment}></div>
                                <ul className={`pl-2 ${classes.ulContent}`}>
                                    {advices.Section3.Points.map((point, i) => {
                                        return <div className={`${classes.advicePoint} mb-2`}
                                                    dangerouslySetInnerHTML={{__html: point}}/>
                                    })}
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}

        </Container>
    </React.Fragment>);
}

export default withRouter(Info);