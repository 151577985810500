import * as types from '../actions/configurations/index';
import * as localforage from '../indexdb/localforageManager';

const initialState = {
    configs: null
};

function configReducer(state = initialState, action) {
    switch (action.type) {
        case types.SAVE_ALL_CONFIGURATIONS:
            return {
                ...state,
                configs: action.payload
            }

        default:
            return state;
    }
}

export default configReducer;