import React, {useState, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {Button, Typography, Hidden, Paper, IconButton, InputAdornment, Tooltip, Icon} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import api from "../../../api/index";
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import ImageBackgroudLogin from "../../../images/professional/login-professional-background.png";
import LinkedInLogin from "../../../images/linkedin-sigin-blank.png";
import {RemoveRedEyeOutlined, VisibilityOffOutlined} from '@material-ui/icons';
import {useDispatch, useSelector} from "react-redux";
import {fetchUserLogin} from "../../../actions/users/index";
import {NavLink} from "react-router-dom";
import {LinkedIn} from "react-linkedin-login-oauth2";
import {useHistory} from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import FormHelperText from "@material-ui/core/FormHelperText";
import * as types from "../../../actions/errors";

const {innerWidth: width, innerHeight: height} = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    titlesFunctions: {
        display: "flex",
        [theme.breakpoints.down('xs')]: {
            display: "grid",
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    form: {
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },
    submit: {
        minWidth: 227,
        borderRadius: 0,
        marginTop: 20,
        height: 50
    },
    buttomtext: {
        fontWeight: "bold",
        color: theme.palette.common.white,
        fontSize: 18,
        letterSpacing: 0.7
    },
    recoverText: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    recoverTextBold: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    mainTitle: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginTop: 8
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 54,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    errorText: {
        fontSize: 19,
        marginTop: 20
    },
    buttons: {
        marginTop: 20
    },
    linkedinButton: {
        width: 227,
        height: 50,
    },
    linkedinImage: {
        float: "left",
        paddingLeft: 8
    },
    linkedinText: {
        position: "absolute",
        top: -2,
        left: 68,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1.2,
        textAlign: "left",
        letterSpacing: 0.7
    },
    or: {
        fontSize: 20,
        fontWeight: "bold",
        paddingTop: 7,
        paddingBottom: 7
    },
    buttonsContainer: {
        maxWidth: "max-content",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tooltip: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: "start"
    },
    iconInfo: {width: "auto", height: "auto", position: "absolute", top: -8, right: 0,}
}));

let isLogin = false;

function LoginProfessional(props) {
    let classes = useStyles();
    let [formData, setFormData] = useState({email: "", password: "", userType: 1});
    let [typePass, setTypePass] = useState(true);
    let [errorLogin, setErrorLogin] = useState(null);
    let [openTooltip, setOpenTooltip] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();

    const redirectUrl = window.location.search
        ? window.location.search.replace("?redirect=", "") : null;

    useEffect(() => {

    }, []);

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    async function handleSubmit() {
        let response = await fetchUserLogin(dispatch)(formData, null);
        if (response.status === 200) {
            let compltedProfile = await checCompletedProfile();
            if (compltedProfile) {
                if (redirectUrl) props.history.push(redirectUrl);
                else
                    props.history.push(`/professional/workspace`);
            } else props.history.push(`/professional/edit-profile/${window.location.search}`);
        } else {
            showMessage(response.data ? response.data.error : response.message, "error");
        }
    }

    const handleChange = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
        setOpenTooltip(false)
    }

    async function checCompletedProfile() {
        let response = await api.professional.checkCompletedProfile();
        return response.data;
    }

    async function handleLoginSuccess(data) {
        if (isLogin === false) {
            isLogin = true;
            let response = await fetchUserLogin(dispatch)(data.code, "code",false);
            if (response.status === 200) {
                let compltedProfile = await checCompletedProfile();
                if (compltedProfile) {
                    if (redirectUrl) props.history.push(redirectUrl);
                    else
                        props.history.push("/professional/workspace");
                } else props.history.push(`/professional/edit-profile/${window.location.search}`);
            } else {
                console.log("error de autenticacion")
                showMessage(response.data ? response.data.error : response.message, "error");
            }
            isLogin = false;
        }
    }

    const handleLoginFailure = (error) => {
        console.log("LINKEDIN-ERROR", JSON.stringify(error))
    }

    function getLinkedInRedirectUrl() {
        if (process.env.NODE_ENV === "development") return "http://localhost:3000/linkedin";
        return "https://www.hihired.com/linkedin";
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 260,
            fontSize: 15,
            marginLeft: -250,
            marginTop: 75,
            backgroundColor: theme.palette.common.white,
            border: 0,
            borderRadius: 0,
            boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);",
            padding: 10
        },
    }))(Tooltip);

    return (<Grid container spacing={0}>
        <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)} style={{width: "100%"}} instantValidate={false}>

            <Grid item xs={12} className={classes.titles}>
                <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate
                    id={"Professional.LoginTitle"}/>} </Typography>
                <Typography variant="h2" color="primary" className={classes.sencondaryTitle}>{<Translate
                    id={"Professional.LoginSubtitle"}/>} </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
                <Translate>
                    {({translate}) => {
                        return <div className={classes.paddingResponse}>
                            <div className="position-relative">
                                <TextValidator
                                    autoFocus={true}
                                    className={classes.responsiveWidth}
                                    label={translate("Employer.Email")}
                                    onChange={handleChange}
                                    name="email"
                                    value={formData.email}
                                    validators={['required', 'isEmail']}
                                    placeholder={translate("Employer.PlaceholderEmail")}
                                    errorMessages={[translate("Fields.Required"), translate("Fields.EmailValidation")]}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <HtmlTooltip
                                    placement="right"
                                    open={openTooltip}
                                    title={<Typography className={classes.tooltip} color="textSecondary">
                                        <Translate id="Help.RegisteredText1"/><br/>
                                        <Translate id="Help.RegisteredText2"/><br/>
                                        <strong>1.</strong><Translate id="Help.RegisteredText4"/><br/>
                                        <strong>2.</strong><Translate id="Help.RegisteredText5"/><br/>
                                        <Translate id="Help.RegisteredText6"/>
                                    </Typography>}>
                                    <Icon className={`${classes.colorBlack} ${classes.iconInfo}`}
                                          onMouseLeave={() => setOpenTooltip(false)}
                                          onMouseEnter={() => setOpenTooltip(true)}
                                          onMouseDown={() => setOpenTooltip(true)}>
                                        <InfoIcon/>
                                    </Icon>
                                </HtmlTooltip>
                            </div>


                            <Grid item xs={12} style={{position: "relative"}}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    style={{marginBottom: 15}}
                                    type={typePass ? "password" : "text"}
                                    label={translate("Employer.Password")}
                                    debounceTime={1500}
                                    name="password"
                                    value={formData.password}
                                    validators={['required']}
                                    placeholder={translate("Employer.Password")}
                                    errorMessages={[translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setTypePass(!typePass)}>
                                                    {typePass ? <RemoveRedEyeOutlined/> : <VisibilityOffOutlined/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}/>
                            </Grid>
                        </div>
                    }}
                </Translate>
            </Grid>

            <Grid item xs={12} className={`${classes.titlesFunctions} ${classes.centerXs}`}>
                <NavLink className="mr-3" to="/professional/recover-password">
                    <Typography className={classes.recoverText} color="primary">{<Translate
                        id={"Employer.ForgetPassword"}/>} </Typography>
                </NavLink>
                <NavLink to="/professional/register">
                    <Typography className={classes.recoverTextBold}>{<Translate
                        id={"Employer.CreateAccount"}/>} </Typography>
                </NavLink>
            </Grid>


            <Grid container className={`${classes.buttons} ${classes.centerXs}`}>
                <Grid item className={classes.buttonsContainer}>
                    <Grid item xs={12}>
                        <Button className={classes.submit} variant="contained" color="primary" type="submit">
                            <Typography className={classes.buttomtext}><Translate id={"Buttons.GetIn"}/></Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="text-center py-2">
                        <Typography className={classes.or}><Translate id="Professional.Or"/></Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12} style={{maxWidth: "max-content"}}>
                            <LinkedIn
                                className="btn-linkedin"
                                clientId="78gw3kj2flmiic"
                                onFailure={handleLoginFailure}
                                onSuccess={handleLoginSuccess}
                                redirectUri={getLinkedInRedirectUrl()}
                                scope={"r_emailaddress r_liteprofile"}>
                                <Grid style={{position: "relative"}}>
                                    <img src={LinkedInLogin} className={classes.linkedinImage}/>
                                    <Typography className={classes.linkedinText} color="primary">{<Translate
                                        id={"Professional.LinkedInLogin"}/>} </Typography>
                                </Grid>
                            </LinkedIn>
                        </Grid>
                    </Grid>
                    {errorLogin ? <FormHelperText error className="pt-5">
                            {errorLogin}
                        </FormHelperText>
                    : ""}
                </Grid>
            </Grid>

        </ValidatorForm>
    </Grid>);
}

export default withRouter(LoginProfessional);