import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoImage from '../../images/logo.png';
import { Grid, useMediaQuery, useScrollTrigger, Container } from "@material-ui/core";
import EmployerMenu from "./employerMenu";
import PropTypes from 'prop-types';
import { NavLink, useHistory } from "react-router-dom";
import LogoImageMovil from "../../images/logoMovil.png";
import Slide from '@material-ui/core/Slide';
import {
    Tooltip,
    ListItem,
    Typography
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import PhoneIcon from '@material-ui/icons/Phone';
import api from "../../api/index";

const useStyles = makeStyles((theme) => ({
    logo: {
        //background: `transparent url('${LogoImage}') 0% 0% no-repeat padding-box`,
        //backgroundSize: "cover",
        objectFit: "cover",
        marginTop: 18,
        //width: 97,
        height: 69,
        [theme.breakpoints.down('sm')]: {
            width: 72,
            marginTop: 11,
            //marginLeft: "8%",
            height: 50,
            // background: `transparent url('${LogoImageMovil}') 0% 0% no-repeat padding-box`,

        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 27,
        },
    },
    title: {
        fontSize: 35,
        fontWeight: 600,
        lineHeight: 1,
        [theme.breakpoints.only('xs')]: {
            fontSize: 30
        },
    },
    menu: {
        position: "absolute",
        right: "15%",
        [theme.breakpoints.only('xs')]: {
            right: "15%",
            //bottom: -10
        },
    },
    button: {
        color: "#141414"
    },
    menuItem: {
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        marginBottom: 12,
        marginTop: 12,
        paddingBottom: 0,
        paddingTop: 0,
        color: theme.palette.text.secondary
    },
    appBar: {
        //backgroundColor: theme.palette.common.white,
        minHeight: 90,
        [theme.breakpoints.down('xs')]: {
            minHeight: 75,
        },
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        id: trigger ? "scrolled" : "no-scrolled"
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function FindJobsHeader(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [logo, setLogo] = useState(null);
    const defaultLogo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFcAAABXCAYAAABxyNlsAAAACXBIWXMAAAsSAAALEgHS3X78AAADZklEQVR4nO2d0XXaMBSGb2kf+gYblA3oBrBB2CBskMgLJF3AohPE2YBukGxAHvuWEUoXoEfuNSchlrEt/RIW93tMjI71If+SjSV92u/31ESm1ISIvjcedJm85lq/NtW8Vm6m1JSIboloSUTfLt1iAzsi2hBRkWv9dHzYB7mZUvdEdBf/vAfHLyJa5Vr/qU78ndxMqYKIri/dkgMvRLSoBI+qcjKl1iLWmRkRHeKhlJsptSCim8FW6byYcbT+j4VMKWN7fulWPGI6uunnv7udGRnoZKp1Hnwlot8jGcPCWIhcHNMvHYr+MbTaAVm1ublqLTfX+v5MKxocHl2dlDsKf2qXg8gFInKBiFwgIheIyAUicoGIXCAiF4jIBSJygYhcICIXiMgFInKBiFwgIheIyAUicoGIXCAiF4jIBSJygYhcICIXiMgFInKBiFwgIheIyAWSrFye+RmVJOXybJpN7PNITi6/mGxmgM6rKUuxSEouR8HbFnvHsqOQWss18+nGR3/bxMrfZOTy9NpZzb/GsfI3CbmZUssT02uj5G9wueYS9VlRXhuiaHFo8PyN0XLXXNFbT+VtanLWemzI/A0ql4VWyw5ovpxdyrPlrI2g+RtMLl+SxxO4i0ypXtNjW+SsjWD5G0Qu52JdizEt6anrpdohZ20Eyd9QLbcpF/sI7pKz1jLQ+QuXy+vmnMrFWduW2CNnbcDzFyo3U2rVYd2cK/4imsrrm7M2oPkLk8sd1UPHj13zF2IrzyVnbcDyFyK35gFKFx6Oh2hcXuEhZ21A8hfVco1Yl5X0jodovnLWBiR/vcvlDsd1pafDCKJjbrvgPX+9yvXc4Yz5EeLaU3lt8Jq/3uSCOpwZMGdteMtfL3IDdDgh8Za/vlpumxuFITHnvsMJZ7n8pOsqIbEVN65P7ZzkWp50pUTBD4l60WXRtjpMq31OWC7xAm29hmhOcnOtnS6b1JF3xYCIXCAiF4jIBSJygYhcICIXiMgF0vomIvaLxGdGq1viLndosl9PR0wsbAd1xsNhO3q7l4zglW21fcwm0WeysdjlWk+q0YJ0Vn4pfZZyc623ssmGN55zrcufiA7jXN5k43HwVYvLC28tWVK3TeIyoV9yQ/Iz1/rdVATbBp8T/nljKfukNWJaavniyoedVInoHyfi7/STJFewAAAAAElFTkSuQmCC";

    useEffect(() => {
        let cancel = false;
        if (props.enterpriseId) {
            api.enterprises.getLogo(props.enterpriseId).then((response) => {
                if (!cancel) {
                    if (response.status === 200) {
                        if (response.data.logo)
                            setLogo(response.data.logo);
                        else
                            setLogo(defaultLogo);
                    } else {
                        console.error("setLogo", response);
                        setLogo(defaultLogo);
                    }
                }
            }).catch((error) => {
                console.error(error);
                setLogo(defaultLogo);
            });
        } else {
            setLogo(screenSmDown ? LogoImageMovil : LogoImage);
        }
        return () => {
            setLogo(null);
            cancel = true;
        }
    }, [props.enterpriseId]);

    function renderAppToolBar(props, classes) {
        return <Toolbar>
            <Container maxWidth="lg" >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} >
                        {logo &&
                            <Tooltip title={<Translate id={"HomeTitles.Home"} />}>
                                <img alt={"Logo"} src={logo && logo} className={classes.logo} role="button" />
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.title} color="textSecondary" variant="h1">
                            <Translate id={"Offers.Professional.JobsTitle"} />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Toolbar>
    }

    return (props.scroll ? <ElevationScroll {...props}>
        <AppBar position={props.scroll ? "fixed" : "static"} color="transparent" elevation={0}
            className={classes.appBar}>
            {renderAppToolBar(props, classes)}
        </AppBar>
    </ElevationScroll> : <AppBar position={props.scroll ? "fixed" : "static"} color="transparent" elevation={0}
        className={classes.appBar}>
        {renderAppToolBar(props, classes)}
    </AppBar>
    );
}