import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Container, useMediaQuery, Divider } from "@material-ui/core";
import { Twitter, Facebook, Instagram, IconButton } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { staticContent } from "../../../utils/constants";
import PromotionalVideo from "../../interview/recorder/video/video-sample.mp4";
import VideoPlayer from "./videoPlayer";
import ExpandFull from "../../../images/layuout/expand-video.png";
import Poster from "../../../images/layuout/poster/fotograma_Somos.jpg";

const useStyles = makeStyles((theme) => ({
    titleSection: {
        color: "#7A7A7B",
        fontWeight: 600,
        textAlign: "center",
        fontSize: 35,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: "left",
            paddingLeft: 28
        },
        marginBottom: 0
    },
    subTitleSection: {
        color: "#141414",
        fontSize: 35,
        fontWeight: 600,
        textAlign: "center",
        marginTop: 60,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            textAlign: "left"
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 24,
            textAlign: "left"
        },
    },
    number: {
        fontSize: 290,
        fontWeight: 700,
        color: "#F7F7F7",
        lineHeight: 0.9,
        [theme.breakpoints.down('lg')]: {
            fontSize: 155,
            textAlign: "left"
        }
    },
    numberContainer: {
        height: 270,
        [theme.breakpoints.down('lg')]: {
            height: 170,
            textAlign: "left"
        }
    },
    body4: {
        ...theme.overrides.MuiTypography.body4,
        fontSize: 24,
        textAlign: "center",
        font: "normal normal 300 24px/31px Barlow;",
        [theme.breakpoints.down('lg')]: {
            fontSize: 19,
            textAlign: "left"
        },
        marginBottom: 0
    },
    body1: {
        ...theme.overrides.MuiTypography.body1,
        fontSize: 18,
        font: "normal normal 300 18px/30px Barlow;",
        [theme.breakpoints.down('lg')]: {
            fontSize: 16,
            textAlign: "left"
        }
    },
    labelOver: {
        marginLeft: -80,
        [theme.breakpoints.down('lg')]: {
            marginLeft: -34
        }
    },
    segment: {
        height: 80,
        borderLeft: "2px solid #C6C6C6",
        marginRight: 10
    },
    apoint: {
        display: "flex",
    },
    paddingLeftResponsive: {
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            marginLeft: -49
        }
    },
    paddingLeftResponsiveExtra: {
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            marginLeft: -50
        }
    },
    singlePoint: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10
        }
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: -16, paddingLeft: 32
        },


    },
    baseline: {
        [theme.breakpoints.down('xs')]: {
            alignItems: "baseline"
        }
    },
    video: {
        objectFit: "cover",
        width: "100%",
        height: 620,
        maxHeight: 620,
        outline: "none",

        [theme.breakpoints.between('md', 'lg')]: {
            height: "calc(100vh - 280px)",
            minHeight: 470,
            maxHeight: 530,
            "@media (min-height: 1000px) and (max-height: 1380px)": {
                height: 590,
                maxHeight: "inherit",
            },
            "@media (min-height: 901px) and (max-height: 960px)": {
                height: 590,
                maxHeight: 590,
            },
            "@media (min-height: 800px) and (max-height: 890px)": {
                height: 500,
                maxHeight: 500,
            },
            "@media (min-height: 500px) and (max-height: 799px)": {
                height: 430,
                minHeight: 430,
                maxHeight: 440,
            },
        },
        [theme.breakpoints.only('sm')]: {
            height: "auto"
        },
        [theme.breakpoints.only('xs')]: {
            height: "auto"
        },
        [theme.breakpoints.only('md')]: {
            "@media (min-height: 700px) and (max-height: 800px)": {
                height: 500,
            },
            "@media (min-height: 500px) and (max-height: 700px)": {
                height: 460,
            }
        }
    },
    videoContainer: {
        position: "relative",
        //height: 620,
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            marginBottom: -5
        },
        [theme.breakpoints.down('xs')]: {
            //height: 350
        },
        [theme.breakpoints.up('md')]: {
            "@media (min-height: 700px) and (max-height: 800px)": {
                //height: 500,
            },
            "@media (min-height: 500px) and (max-height: 700px)": {
                //height: 450,
            }
        }
    },
    controlLocation: {
        position: "absolute",
        top: 110,
        right: 20,
        [theme.breakpoints.down('xs')]: {
            //top: 220,
            right: 50
        }
    },
    videoBox: {
        marginTop: 50,
        height: 401,
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            height: 350
        },
        [theme.breakpoints.up('md')]: {
            "@media (min-height: 800px) and (max-height: 900px)": {
                height: 340,
            },
            "@media (min-height: 700px) and (max-height: 800px)": {
                height: 340,
            },
            "@media (min-height: 500px) and (max-height: 700px)": {
                height: 310,
            }
        },
        [theme.breakpoints.only('lg')]: {
            marginTop: 35
        }
    },
    secondaryVideoBox: {
        height: 120,
        background: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
            height: 26
        },
        [theme.breakpoints.up('md')]: {
            "@media (min-height: 500px) and (max-height: 700px)": {
                height: 70,
            },
            "@media (min-height: 700px) and (max-height: 800px)": {
                height: 80,
            },
            "@media (min-height: 800px) and (max-height: 890px)": {
                height: 60
            },
        },
    },
    videoText: {
        paddingLeft: 70,
        paddingTop: 60,
        paddingRight: 10,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingTop: 20
        },
        [theme.breakpoints.up('md')]: {
            "@media (min-height: 400px) and (max-height: 800px)": {
                paddingTop: 20
            }
        }
    },
    greeting: {
        fontSize: 30,
        color: theme.palette.secondary.main,
        textAlign: "left",
        fontWeight: "bold",
        fontStyle: "italic",
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    greetingText: {
        fontSize: 30,
        fontWeight: 300,
        color: theme.palette.secondary.main,
        textAlign: "left",
        [theme.breakpoints.down('lg')]: {
            fontSize: 19
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 21
        }
    },
    videoInfo: {
        fontSize: 17,
        fontWeight: 300,
        color: theme.palette.common.white,
        maxWidth: 792,
        font: "normal normal 300 17px/30px Barlow",
        letterSpacing: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    "#axis:hover .move-right": {
        transform: "translate(350px,0)",
        "-webkit-transform": "translate(350px,0)", /** Chrome & Safari **/
        "-o-transform": "translate(350px,0)", /** Opera **/
        "-moz-transform": "translate(350px,0)" /** Firefox **/
    },
    object: {
        position: "absolute",
        right: 0,
        top: 0,

        "transition": "all 2s ease-in-out",
        "-webkit-transition": "all 2s ease-in-out", /** Chrome & Safari **/
        "-moz-transition": "all 2s ease-in-out", /** Firefox **/
        "-o-transition": "all 2s ease-in-out" /** Opera **/,

        [theme.breakpoints.down('sm')]: {
            position: "relative",
            top: 0
        },
        [theme.breakpoints.up('lg')]: {
            width: "56.33%"
        },
        /*[theme.breakpoints.up('md')]: {
            width: "56.33%"
        }*/
    },
    contentUnderLine: {
        display: "inline-block",
        [theme.breakpoints.down('xs')]: {
            marginLeft: 13
        }
    },
    divider: {
        width: 220,
        height: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        marginLeft: 25,
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    expand: {
        position: "absolute",
        top: 30,
        right: 30
    },
    titleConatiner: {
        marginTop: 70,
        marginBottom: 30,
        [theme.breakpoints.down('lg')]: {
            marginTop: 30,
            marginBottom: 20,
        },
        [theme.breakpoints.only('lg')]: {
            marginTop: 0,
            marginBottom: 20,
            "@media (min-height: 500px) and (max-height: 799px)": {
                marginBottom: 0
            },
        },
    },
    container1: {
        padding: "0px 24px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },

    },

}));


function AboutUs(props) {
    let classes = useStyles();
    const [content, setContent] = useState(null);
    let [progress, setProgress] = useState(0);
    let defaultLanguage = useSelector(state => state.users.defaultLanguage);
    let language = defaultLanguage || navigator.language || navigator.userLanguage || "es-ES";
    const videoPlayerRef = useRef(null);
    let video = videoPlayerRef.current;

    useEffect(() => {
        api.home.getContent(staticContent.AboutUs).then(response => setContent(response));
    }, [content, progress])


    function openFullscreen() {
        if (video) {
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            } else if (video.webkitEnterFullScreen) {
                video.webkitEnterFullScreen();
            } else if (video.webkitEnterFullscreen) {
                video.webkitEnterFullscreen();
            } else {
                alert("no full screen")
            }
        }
    }

    const theme = useTheme();
    const screenMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const screenXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const screenLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    return (<Grid container spacing={0} >
        <Grid item xs={12} className={classes.titleConatiner}>
            <h1 className={classes.titleSection}>{<Translate id={"Menus.AboutUs"} />} </h1>
        </Grid>
        <Grid item xs={12}>
            <Grid id="axis" container spacing={0} className="position-relative">
                <Grid item xs={12} md={6} className={classes.videoContainer}>
                    <video playsInline={true} poster={Poster} ref={videoPlayerRef} className={classes.video} src={language==="es-ES" ? "https://videoshihired.ams3.digitaloceanspaces.com/Votros/Storytelling_HIHIRED.mp4" : "https://videoshihired.ams3.digitaloceanspaces.com/Votros/Storytelling_HIHIRED_EN.mp4"} type="video/mp4"></video>
                    {screenXsDown && progress > 0 && <div className={classes.expand}>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Grid item md={6}>
                                <img src={ExpandFull} onClick={openFullscreen} />
                            </Grid>
                        </Grid>
                    </div>}
                    <div className={classes.controlLocation}>
                        <VideoPlayer video={videoPlayerRef.current}
                            onFirstPlay={() => {
                                if (screenMdUp) {
                                    let moved = document.getElementById("move-right");
                                    moved.style["maxWidth"] = "50%";
                                }
                            }}
                            notifyProgress={(value) => setProgress(value)} />
                    </div>
                </Grid>
                <Grid id="move-right" className={`${classes.object} move-right`} item xs={12} sm={12} md={7}>
                    <Grid spacing={0} container style={{ display: "block" }} className={classes.videoContainer}>
                        <Grid item xs={12} className={classes.videoBox}>
                            <Grid item xs={12} md={9} className={classes.videoText}>
                                <Typography className={classes.greeting}>{<Translate id={"Titles.VideoGreeting"} />} </Typography>
                                <div>
                                    <span className={classes.greetingText}><Translate id={"Titles.VideoGreetingBody"} /></span>
                                    <span className={`${classes.greetingText} font-weight-bold`}><Translate id={"Titles.Hihired"} /></span>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={11} className={classes.videoText}>
                                <Typography className={classes.videoInfo}>{<Translate id={"Titles.VideoInfo"} />} </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.secondaryVideoBox}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {content && <Grid item xs={12}>
            <Container spacing={0} className={classes.container1} maxWidth={screenXlOnly ? "lg" : "md"}>
                <Grid item xs={12} className="text-center">
                    <Grid className={classes.contentUnderLine}>
                        <h2 className={classes.subTitleSection}>{content.Title} </h2>
                        <Divider className={classes.divider} />
                    </Grid>
                </Grid>

                <Grid container className="mb-5">
                    {content.Points.map((points, i) => {
                        if (points.length > 1)
                            return (<Grid container spacing={4} className="mb-5">
                                {
                                    points.map((point, index) => {
                                        return (index === 0
                                            ? <Grid container spacing={2} direction="row" justify="center" alignItems={screenXsDown ? "flex-start" : "center"} >
                                                <Grid item xs={2} md={1} className={classes.numberContainer}>
                                                    <Typography className={classes.number}>{i + 1} </Typography>
                                                </Grid>
                                                <Grid item xs={11} sm={10} md={7} className={classes.labelOver}>
                                                    <h3 className={classes.body4}>{point} </h3>
                                                </Grid>
                                            </Grid>
                                            : <Grid item xs={12} sm={6} md={index === 8 ? 8 : 4} style={{ display: "flex" }}>
                                                <div className={classes.segment}></div>
                                                <Typography className={classes.body1}>{point} </Typography>
                                            </Grid>)
                                    })
                                }
                            </Grid>)
                        else {
                            return <Grid className="my-2" item xs={12} md={i === 7 ? 12 : 6}>
                                {points.map((point) => {
                                    return (<Grid className={i === 7 ? classes.baseline : ""} style={{ marginLeft: i === 7 && !screenLgDown ? -23 : 0 }} container spacing={0} direction="row" justify="center" alignItems="center" >
                                        <Grid item xs={2} md={1} className={`${classes.numberContainer} ${classes.singlePoint}`}>
                                            <Typography className={classes.number}>{i + 1} </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={11} className={`${classes.apoint} ${i === 7 ? classes.paddingLeftResponsiveExtra : classes.paddingLeftResponsive}`}>
                                            <div className={classes.segment}></div>
                                            <Typography className={classes.body1}>{point} </Typography>
                                        </Grid>
                                    </Grid>)
                                })}
                            </Grid>
                        }
                    })}
                </Grid>
            </Container>
        </Grid>}
    </Grid>);
}

export default withRouter(AboutUs);