import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import { isIOS, isChrome } from "react-device-detect";
import ConfirmDialog from "../confirmation/confirm";
import * as permissions from "../../utils/permissions";
import { withRouter } from "react-router-dom";
import api from "../../api/index";
import { Container, useMediaQuery } from "@material-ui/core";
import BannerInfo from "./tips/bannerInfo";
import Info from './tips/info';
import { staticContent } from "../../utils/constants";

const useStyles = makeStyles((theme) => {
    //theme.overrides.MuiButton.root = { ...theme.overrides.MuiButton.root, padding: "5px 10px", height: 48, minWidth: 220 };
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            minHeight: 115
        },
        center: {
            textAlign: "center",
            justifyContent: "space-around",
            margin: "auto",
            [theme.breakpoints.up('md')]: {
                display: "flex",
                minWidth: 600
            },

        },
        title1: {
            fontSize: 35,
            fontWeight: 500,
            letterSpacing: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30,
                marginLeft: 0,
                marginTop: 0
            },
            lineHeight: 1,
        },
        button2: {
            width: 340,
            margin: 8,
            lineHeight: "1.1",
            maxWidth: "100%",
            minHeight: 48,
            padding: "5px 10px", height: 48, minWidth: 220,
            [theme.breakpoints.only('sm')]: {
                width: 300,
            },
        },
    }
});

function Interview(props) {
    const classes = useStyles();
    const theme = useTheme();
    const screenMdDow = useMediaQuery(theme.breakpoints.down('md'));


    const [request, setRequest] = useState(false);
    const [to, setTo] = useState(null);
    let [advices, setAdiveces] = useState(null)

    useEffect(() => {
        api.home.getContent(isIOS ? staticContent.TipsInterviewIphone : staticContent.TipsInterview).then(response => {
            setAdiveces(response);
        });
    })

    function requestPermission(url) {
        setTo(url);
        api.interview.allQuestions().then(response => {
            if (response.status < 300) {
                if (isChrome === true) {
                    permissions.permissionsConceded().then(conceded => {
                        if (conceded) props.history.push(url);
                        else {
                            setRequest(true);
                        }
                    });
                }
                else if (isIOS === false) {
                    setRequest(true);
                } else {
                    props.history.push(url);
                }
            }
        });
    }


    return (<Grid container spacing={0} style={screenMdDow ? { marginTop: 0 } : { marginTop: 20 }}>
        <Grid container direction="row" justify="center">
            <Grid item item xs={10} sm={9} md={7} className="d-flex pb-4">
                <Typography className={classes.title1} color="textSecondary">
                    {<Translate id={"Tips.Title"} />}
                </Typography>
            </Grid>
        </Grid>

        <BannerInfo />

        <Info info={advices} />
    </Grid>);
}

export default withRouter(Interview);