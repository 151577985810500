import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import imgSafariHelpDev from "../../images/safari-help-1.png";
import imgSafariHelpSupport from "../../images/safari-help-2.png";
import { Translate } from "react-localize-redux";


const useStyles = makeStyles((theme) => ({
    imgResponsive:{
        width: "100%",
        height: "auto"
    }
}));

export default function SafariHelp(props) {
    let classes = useStyles();

    return (<Grid container spacing={2} >
        <Grid item xs={12} md={6}>
            <Typography variant="h2" >
                1- <Translate id={"Titles.SafariHelpDev"} />
            </Typography>
            <img alt={"safari-help-develop"} src={imgSafariHelpDev} className={classes.imgResponsive}/>
        </Grid>

        <Grid item xs={12} md={6}>
            <Typography variant="h2" >
                2- <Translate id={"Titles.SafariHelpExperimental"} />
            </Typography>
            <img alt={"safari-help-develop"} src={imgSafariHelpSupport} className={classes.imgResponsive}/>
        </Grid>
    </Grid>)
}