import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Slider, useMediaQuery } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { Button, Typography } from "@material-ui/core";
import { Fullscreen, PlayArrow, Pause } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { withRouter } from "react-router-dom";
import ExpandFull from "../../../images/layuout/expand-video.png";

const sliderStyle = makeStyles((theme) => ({
    root: {
        height: 5,
        padding: '13px 0',
        minWidth: 'auto',
        [theme.breakpoints.only('xl')]: {
            minWidth: 354,
        },
    },
    thumb: {
        height: 46,
        width: 46,
        backgroundColor: theme.palette.common.white,
        border: '0px solid currentColor',
        marginTop: -21,
        marginLeft: -13,
        '& .bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        color: "#FFFFFF",
        height: 2,
    },
    rail: {
        height: 2,
        color: "#FFFFFF",
        opacity: 1
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 500,
        [theme.breakpoints.down('md')]: {
            minWidth: 400,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 300,
            width: "100%",
            "@media (max-width: 320px)":{
                minWidth: 230,
            }
        }
    },
    container: {
        minHeight: 110
    },
    spacing: {
        height: 22
    },
    text: {
        marginLeft: 10,
        marginTop: 3,
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    icon: {
        color: theme.palette.common.white,
        fontSize: 40,
        fontWeight: 'bold',
        cursor: "pointer",
    },
    iconFull: {
        marginLeft: 4,
        color: theme.palette.common.white,
        fontSize: 24,
        fontWeight: 'bold',
        cursor: "pointer",
    },
    textTop: {
        top: -24,
        position: 'absolute',
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            top: -13,
            fontSize: 10,
        },
    },
    textRight: {
        top: -30,
        position: 'absolute',
        color: '#FFFFFF',
        fontSize: 20,
        fontWeight: 500,
        height: 28,
        width: 53,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    expand: {
        minWidth: 200,
        [theme.breakpoints.down('md')]: {
            minWidth: 150,
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 120,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 120,
        }
    },
    titleVideo: {
        fontSize: 18,
        color: theme.palette.common.white
    },
    subTitleVideo: {
        fontSize: 16,
        color: theme.palette.common.white
    }
}));

function VideoPlayer(props) {
    const classes = useStyles();
    let { video } = props;
    let [currentTime, setCurrentTime] = useState(0);
    let [duration, setDuration] = useState(0);

    useEffect(() => {
        initProgressVideo();
    }, [video])

    function initProgressVideo() {
        if (video) {
            video.addEventListener('timeupdate', function (v) {
                setCurrentTime(parseInt(this.currentTime));
                props.notifyProgress(this.currentTime);
            });

            video.addEventListener('loadedmetadata', function () {
                setDuration(this.duration);
            });
        }
    }

    function openFullscreen() {
        if (video) {
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                video.msRequestFullscreen();
            } else if (video.webkitEnterFullScreen) {
                video.webkitEnterFullScreen();
            } else if (video.webkitEnterFullscreen) {
                video.webkitEnterFullscreen();
            } else {
                alert("no full screen")
            }
        }
    }

    return (<Grid container className={classes.root}>
        <Grid item xs={12}>
            <PlayerIndicator time={currentTime} totalTime={duration}
                handlePlay={() => {
                    video.play()
                    if (currentTime === 0) {
                        if (props.onFirstPlay) props.onFirstPlay();
                    }
                }}
                handlePause={() => {
                    video.pause()
                }}
                handleChange={(newValue) => {
                    if (video) {
                        video.currentTime = newValue;
                        setCurrentTime(newValue);
                    }
                }}
                
                video={video}
                paused={video && video.paused}
                handleChangeTime={() => { }}
                openFullscreen={openFullscreen} />
        </Grid>
    </Grid>);
}

function PlayerIndicator(props) {
    let { time, totalTime, openFullscreen, handlePlay, handlePause, handleChange, paused, video } = props;
    const classes = useStyles();
    const customClasses = sliderStyle();

    useEffect(() => {
        //initThumb();
    }, [paused, video])

    const handleChangeValue = (event, newValue) => {
        if (handleChange) handleChange(newValue);
        if (video) {
            if (video.paused) {
                handlePlay();
            } else {
                handlePause();
            }
        }
    };

    function initThumb() {
        let th = document.getElementById("custom-thumb")
        th.addEventListener("click", function (e) {
            alert();
        })
    }

    function ThumbComponent(tProps) {
        function handleClick() {
            if (video) {
                if (video.paused) {
                    handlePlay();
                } else {
                    handlePause();
                }
            }
        }
        return (
            <span  {...tProps} onClick={handleClick}>
                <div id="custom-thumb">
                    {video && video.paused
                        ? <PlayArrow fontSize="large" className={customClasses.icon} onClick={handleClick} />
                        : <Pause fontSize="large" className={customClasses.icon} onClick={handleClick} />}
                </div>
            </span>
        );
    }

    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    return (<div className={classes.container}>
        <Grid item xs={12} className={classes.spacing}>
            {time === 0 && <Typography className={classes.titleVideo}><Translate id={"Titles.VideoTitle"} /></Typography>}
        </Grid>
        <div className="align-items-center d-flex" style={{ minHeight: 50 }}>
            <Slider classes={customClasses} style={{ marginLeft: 10 }}
                ThumbComponent={(component) => ThumbComponent({ ...component, paused: paused || time === 0, onClick: () => alert() })}
                defaultValue={time}
                value={time}
                min={0}
                max={totalTime}
                onChange={(event, v) => handleChangeValue(event, v)}
            />
            {screenXsDown === false && <div className={classes.expand}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item md={6}>
                        <img alt={"Hihired! Nuestra historia y valores"} src={ExpandFull} onClick={openFullscreen} />
                    </Grid>
                </Grid>
            </div>}
        </div>
        {time === 0 && <Typography className={classes.subTitleVideo}><Translate id={"Titles.VideoPlay"} /></Typography>}
    </div>);
}

export default withRouter(VideoPlayer);