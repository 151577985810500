import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Hidden, Paper, InputAdornment, IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ProfessionalImageBackgroudLogin from "../../../images/professional/login-background.png";
import * as types from "../../../actions/errors";

const { innerWidth: width, innerHeight: height } = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    form: {
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },
    submit: {
        borderRadius: 0,
        marginTop: 20,
        maxWidth: 100,
    },
    buttomtext: {
        fontWeight: "bold",
        color: window.location.pathname.indexOf("professional") > -1 ? theme.palette.common.white : theme.palette.primary.main,
        fontSize: 18,
        letterSpacing: 0.7
    },
    recoverText: {
        fontSize: 18
    },
    mainTitle: {
        fontSize: 35,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    notifyText: {
        color: theme.palette.common.green,
        fontSize: 19
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 53,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    normal: {
        fontWeight: "normal"
    }
}));

function ChangePassword(props) {
    const isProfessional = window.location.pathname.indexOf("professional") > -1;

    let classes = useStyles();
    let [typePass, setTypePass] = useState(true);
    let [typeConfirmPass, setTypeConfirmPass] = useState(true);
    let [typeOldPass, setTypeOldPass] = useState(true);

    let loguedUser = useSelector(state => state.users.loggedUser);

    let [errorType, setErrorType] = useState(false)
    let [errorResponse, setErrorResponse] = useState(false);
    let [errorResponseText, setErrorResponseText] = useState("");
    let [completed, setCompleted] = useState(false);
    let [formData, setFormData] = useState({ password: "", newPassword: "", confirmPassword: "", email: loguedUser.email });

    const dispatch = useDispatch();
    useEffect(() => {
        addValdationRule();
    }, [completed, errorResponse, setErrorResponseText])

    function addValdationRule() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let pass = document.getElementsByName("newPassword")[0];
            if (value !== pass.value) {
                return false;
            }
            return true;
        });
    }

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    function handleError(error) {
        if (error) {
            showMessage(error, "error");
        } else {
            setErrorResponse(false);
            setErrorResponseText("")
        }
    }

    async function handleSubmit() {
        api.users.changeSelfPassword(formData).then(response => {
            let { data } = response;
            if (response.status === 200) {
                props.history.push(`/changepassword-success/${isProfessional ? 'professional' : 'employer'}`);
            } else {
                handleError(data.error)
            }
        });
    }

    const handleChange = (event) => {
        handleError(null);
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    return (<Grid container spacing={0}>
        <Grid item xs={12} className={classes.titles}>
            <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate id={"Employer.ChangePassword"} />} </Typography>
        </Grid>

        <Grid item xs={12} md={8} lg={7} className={classes.form}>
            <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <Translate>
                    {({ translate }) => {
                        return <div className={classes.paddingResponse}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typeOldPass ? "password" : "text"}
                                    label={translate("Employer.CurrentPassword")}
                                    debounceTime={1500}
                                    name="password"
                                    value={formData.password}
                                    validators={['required']}
                                    placeholder={translate("Employer.CurrentPassword")}
                                    errorMessages={[translate("Fields.Required")]}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypeOldPass(!typeOldPass)}>
                                                {typeOldPass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.normal }
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typePass ? "password" : "text"}
                                    label={translate("Employer.NewPassword")}
                                    debounceTime={1500}
                                    name="newPassword"
                                    value={formData.newPassword}
                                    validators={['required']}
                                    placeholder={translate("Employer.NewPassword")}
                                    errorMessages={[translate("Fields.Required")]}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypePass(!typePass)}>
                                                {typePass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.normal }
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typeConfirmPass ? "password" : "text"}
                                    label={translate("Employer.RepeatNewPassword")}
                                    debounceTime={1500}
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    placeholder={translate("Employer.RepeatNewPassword")}
                                    errorMessages={[translate("Employer.PasswordMismatch"), translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypeConfirmPass(!typeConfirmPass)}>
                                                {typeConfirmPass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.normal }
                                    }} />
                            </Grid>

                            {!completed && <Grid item xs={12} className={classes.centerXs}>
                                <Button className={classes.submit} variant="contained" color={isProfessional ? "primary" : "secondary"} type="submit">
                                    <Typography className={classes.buttomtext}><Translate id={"Buttons.CnagePassword"} /></Typography>
                                </Button>
                            </Grid>}
                        </div>
                    }}
                </Translate>
            </ValidatorForm>
        </Grid>

        {completed && <Grid item xs={12}>
            <Typography className={classes.notifyText}>{<Translate id={"Employer.RecoverPassNotify"} />} </Typography>
        </Grid>}

        {errorResponse && <Grid item xs={12}>
            <Typography color="error">{errorResponseText} </Typography>
        </Grid>}

    </Grid>);
}

export default withRouter(ChangePassword);