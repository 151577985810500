import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import imgSeenHired from "../../images/SeenHired.png";
import ConfirmDialog from "../confirmation/confirm";
import {useDispatch, useSelector} from "react-redux";
import * as types from '../../actions/users/index';
import * as interviewTypes from '../../actions/interview/index';
import {withRouter} from "react-router-dom";
import {Button, CircularProgress, Paper, useMediaQuery} from "@material-ui/core";
import * as localforage from "../../indexdb/localforageManager";
import api from "../../api/index";
import UploadProgress from "../interview/uploadProgress";
import * as interviewActions from "../../actions/interview/index";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1311,
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 26,
        right: 26,
        minWidth: 63,
        width: 63,
        height: 63
    },
    button: {
        width: "90%",
        lineHeight: 1,
        padding: "0 24px",
        height: 61,
        [theme.breakpoints.up('md')]: {
            width: "100%"
        },
        [theme.breakpoints.down('xs')]: {
            height: 46
        },
    },
    icon: {
        height: 63,
        width: 63
    },
    box: {
        borderRadius: 38,
        boxShadow: "21px 26px 85px #00000010",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
    },
    titleInterView: {
        textAlign: "center",
        fontWeight: "bold",
        //marginLeft: 59
    },
    subTitleInterView: {
        fontSize: 49,
        textAlign: "center",
        fontWeight: 400,
        letterSpacing: "0.34px",
        [theme.breakpoints.down('sm')]: {
            fontSize: 34,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
            letterSpacing: "0.28px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            letterSpacing: "0.28px",
        },
    },
    progress: {},
    image: {
        width: "75%",
        [theme.breakpoints.up('md')]: {
            width: 319,
            height: 221,
        }
    },
    padding: {
        padding: "2rem 3rem",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            padding: "1rem",
            textAlign: "center"
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0",
            textAlign: "center"
        },
    },
    buttonTop: {
        marginBottom: "1rem",
        marginTop: "1rem",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "0.25rem",
            marginTop: "0.25rem",
        },
    },
    mergedTitle: {
        display: "flex",
        height: "max-content !important",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 17,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 8
        }
    },
    title: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    imgLanguage: {
        width: 43,
        height: 43,
        marginLeft: 36,
        marginRight: 10,
        [theme.breakpoints.only('xs')]: {
            width: 29,
            height: 29,
            marginLeft: 11,
            marginRight: 7,
        }
    },
}));

function EndSpeakingTest(props) {
    const classes = useStyles();
    const id = props.match.params["test"] ? parseInt(props.match.params["test"]) : null;

    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [exitConfirmation, setExitConfirmation] = useState(false);
    const [unsavedSpeakingTest, setUnsavedSpeakingTest] = useState(false);

    const [existConfirmation, setExistConfirmation] = useState(false);
    const [upLoading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const interview = useSelector(state => state.users.interview);
    const dispatch = useDispatch();
    const interviewSupport = useSelector(state => state.interviews.support);
    const reconfigurations = useSelector(state => state.interviews.configuration);

    const metadatas = useSelector(state => state.users.speakingTest.metadata);
    let [attempts, setAttempts] = useState(0);

    const [segmentIndex, setSegmentIndex] = useState(0);
    const [totalSegments, setTotalSegments] = useState(0);
    const source = props.match.params["interview"] ? props.match.params["interview"] : "";
    let [languageTest, setLanguageTest] = useState(null);
    let [professionalLanguageTest, setProfessionalLanguageTest] = useState(null);

    useEffect(() => {
        defineActionHeader();
        loadSpeakingTest();
        loadLanguageTest();
        loadTest();
    }, [unsavedSpeakingTest, progress, totalSegments, segmentIndex, languageTest])

    function loadLanguageTest() {
        if (languageTest === null && id)
            api.languageTest.getLanguageTest(id).then(response => {
                if (response.status === 200) {
                    setLanguageTest(response.data);
                }
            })
    }

    function loadTest() {
        if (professionalLanguageTest === null && id && attempts === 0)
            api.professional.getTest(id).then(response => {
                setProfessionalLanguageTest(response.data ? response.data : {});

                if (!response.data) {
                    setAttempts(2);
                } else {
                    let {attempts} = response.data;
                    setAttempts(2 - attempts);
                }
            })
    }

    const loadSpeakingTest = () => {
        if (metadatas.length > 0) setUnsavedSpeakingTest(true);
        else setUnsavedSpeakingTest(false);
    }

    const handleSeeInterview = () => {
        props.history.push(`/speaking/viewer/${id}`);
    };

    const handleAddInterviewProfile = async () => {
        setUploading(true);
        if (deviceSupport()) {
            let localVideo = await localforage.getItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST);
            if (localVideo) {
                await handleAddInterview();
            } else {
                let segments = await localforage.getItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);
                if (segments && segments.length > 0) {
                    await handleAddSegmentsInterview();
                }
            }
        } else {
            await handleAddInterview();
        }
        setUploading(false);
    };

    async function handleDeleteInterviewRecord() {
        try {
            dispatch(types.resetSpeaking());
            //dispatch(types.setSpeakingAttempts(attempts - 1));
            await localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST);
            await localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);

            props.history.goBack();
        } catch (err) {
            alert(JSON.stringify(err));
        }
    }

    const handleClosed = () => {
        setExitConfirmation(true);
    }

    const handleExitInterview = () => {
        dispatch(types.resetSpeaking());
        dispatch(types.setSpeakingAttempts(-1))


        //if (attempts > 1) dispatch(types.setSpeakingAttempts(attempts - 1))
        localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST).then((del) => {
            if (attempts > 0)
                props.history.push(`/speaking/test/${id}`);
            else
                props.history.push(`/professional/edit-profile`);
        }).catch((err) => {
            console.log(err);
            alert("ERROR")
        });
    }

    function notifyProgressCallback(upload) {
        let {total, loaded} = upload;
        let percent = loaded / total * 100;
        setProgress(progres => percent);
    }

    const handleAddInterview = async (verify = false) => {
        try {
            const videoBlob = await localforage.getItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST);
            if (videoBlob) {
                let upload = await api.professional.uploadVideoSpeakingTest(videoBlob, notifyProgressCallback);
                if (upload) {
                    let response = await api.professional.saveVideoSpeakingTest({
                        languageTestId: id,
                        videoUrl: upload.Location,
                        videoKey: upload.key,
                        metadata: metadatas,
                        duration: 0
                    });
                    if (response.status === 200 || response.status === 204) {
                        dispatch(types.resetSpeaking());
                        dispatch(types.setSpeakingAttempts(0));
                        await localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST);
                        props.history.push("/professional/edit-profile");
                    }
                } else {
                    setUploading(false);
                    setProgress(0);
                    console.log("ERROR UPLOADING FILE");
                    alert("ERROR");
                }
            } else {
                setUploading(false);
                setProgress(0);
            }
        } catch (err) {
            setUploading(false);
            setProgress(0);
            console.log("ERROR UPLOADING FILE");
            alert("ERROR");
        }
    }

    const handleAddSegmentsInterview = async () => {
        try {
            const videoSegments = await localforage.getItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);
            let metadataCopy = Object.assign([], metadatas);
            if (videoSegments) {
                setTotalSegments(videoSegments.length);
                let keyFolder = Math.floor(Math.random() * 1000000000000)
                for (let i = 0; i < videoSegments.length; i++) {
                    setSegmentIndex(i + 1);
                    let video = videoSegments[i];
                    let upload = await api.professional
                        .uploadVideoSpeakingTestSegments(video, keyFolder, () => {});

                    let percent = ((i + 1) / videoSegments.length) * 100;
                    setProgress(progres => percent);
                    if (upload) {
                        metadataCopy[i] = {
                            ...metadataCopy[i],
                            videoUrl: upload.Location,
                            videoKey: upload.key
                        };
                    }
                }

                let response = await api.professional.saveVideoSpeakingTest({
                    languageTestId: id,
                    videoUrl: "segment",
                    videoKey: `videospeaking/${keyFolder}`,
                    metadata: metadataCopy,
                    duration: 0
                });

                if (response.status === 200 || response.status === 204) {
                    dispatch(types.resetSpeaking());
                    dispatch(interviewTypes.resetUnsupportConfiguration());
                    await localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);
                    props.history.push("/professional/edit-profile");
                }
            } else {
                setUploading(false);
                setProgress(0);
            }
        } catch (err) {
            alert(`Error-> ${JSON.stringify(err)}`)
        }
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: handleClosed
        }))
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    function resolveImage() {
        if (languageTest && languageTest.isoCode)
            return require(`../../images/offer/languages/${languageTest.isoCode}.png`)
        return null;
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
    return (<Grid container spacing={2}>
        <Grid item xs={12} className="py-0">
            <Grid item xs={12} className={classes.mergedTitle}>
                <Typography className={classes.title} color="textSecondary">
                    <Translate id="Tests.Title"/>
                </Typography>
                <img className={classes.imgLanguage} src={resolveImage()}/>
                <Typography className={classes.title} color="textSecondary">{languageTest ? languageTest.name : ""}</Typography>
            </Grid>
        </Grid>

        <Grid item xs={12} className="py-0">
            <Paper elevation={xsScreen ? 0 : 3} className={`${xsScreen ? {} : classes.box}`}>
                <Grid container spacing={2} id="conatinerRef" className={classes.padding}>
                    <Grid item xs={12} sm={6} md={6} className={"text-center"}>
                        <Typography variant="title1" gutterBottom className={`mb-5 ${classes.subTitleInterView}`}>
                            <Translate id={"Titles.CompletedInterview"}/>
                        </Typography>
                        <img alt={"suggest"} src={imgSeenHired} className={classes.image}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid container spacing={2} className="justify-content-center">
                            <Grid item xs={12} md={8}>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" onClick={handleSeeInterview}
                                                    disabled={upLoading}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.SeeInterview")}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" hidden={upLoading}
                                                    disabled={upLoading || !unsavedSpeakingTest}
                                                    onClick={handleAddInterviewProfile}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.AddInterviewProfile")}
                                                {upLoading &&
                                                <CircularProgress size={24} style={{marginLeft: 10, marginTop: -6}}
                                                                  color="secondary"/>}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>
                                {attempts > 0 && <Grid item xs={12}>
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" disabled={upLoading}
                                                    onClick={() => setCancelConfirmation(x => true)}
                                                    className={`${classes.button} ${classes.buttonTop}`}>
                                                {translate("Buttons.DeleteInterviewRecord")}
                                            </Button>
                                        )}
                                    </Translate>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {upLoading &&
                    <Grid item xs={12} className="p-5"
                          style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%", padding: "0 !important",
                              opacity: 0.9,
                              background: "white",
                              zIndex: 5000
                          }}>
                        <div style={{
                            position: "relative", justifyContent: "center", display: "flex",
                            flexWrap: "wrap", width: "100%", height: "100%"
                        }}>
                            <UploadProgress value={progress} hidden={!upLoading}/>
                        </div>
                    </Grid>}

                </Grid>
            </Paper>
        </Grid>

        <ConfirmDialog id="delete-interview" open={cancelConfirmation}
                       title={<Translate id={"Tests.DeleteRecordedSpeakingTitle"}/>}
                       question={<Translate id={"Tests.DeleteRecordedSpeakingQuestion"}/>}
                       handleCancel={() => setCancelConfirmation(x => false)}
                       handleAccept={handleDeleteInterviewRecord}/>

        <ConfirmDialog id="exit-interview" open={exitConfirmation}
                       question={<Translate id={"Tests.DeleteRecordedSpeakingQuestion"}/>}
                       title={unsavedSpeakingTest ? <React.Fragment>
                           <Translate id={"Confirmation.ExitInterviewTitle"}/>
                           {attempts > 0 ? <Translate id={"Tests.OneMoreChance"}/> :
                               <Translate id={"Tests.NoMoreChance"}/>}
                       </React.Fragment> : null}
                       handleCancel={() => setExitConfirmation(x => false)}
                       handleAccept={handleExitInterview}/>
    </Grid>);
}

export default withRouter(EndSpeakingTest);