import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {Typography, Grid, Paper, useMediaQuery, Button, Tooltip} from '@material-ui/core';
import {NavLink} from "react-router-dom";
import DownloadIcon from "../../images/payment/download-pdf.png";
import RepeatIcon from '@material-ui/icons/Repeat';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: "0px 0px 25px #0000001A",
        width: "100%",
        display: "flex",
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            minHeight: 250,
            marginBottom: 25
        },
    },
    button: {
        minHeight: 40,
        height: 40,
        borderRadius: 0,
        minWidth: 180,
        width: 187,
        marginTop: 25,
        [theme.breakpoints.only('xs')]: {
            marginTop: 20,
        }
    },
    buttonLabel: {
        fontSize: 18,
        fontWeight: 700,
    },
    infoBox: {
        backgroundColor: "#F7F7F7",
        height: "100%",
        padding: "18px 27px"
    },
    column: {
        fontWeight: 700,
        fontSize: 16
    },
    data: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1
    },
    downloadBox: {
        padding: 18,
        textAlign: "center"
    }
}));

function PaymentCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    let {payment, index} = props;

    return (<Translate>
        {({translate}) => {
            return <Paper className={classes.paper} elevation={2}>
                <Grid container>
                    <Grid item xs={8} className={classes.infoBox}>
                        <Typography color="textSecondary" className={classes.column}><Translate
                            id={"Payments.DateColumn"}/>:</Typography>
                        <Typography color="textSecondary" className={classes.data}>
                            {payment.date ? moment(payment.date).format("DD/MM/yyyy") : ""}
                        </Typography>
                        <br/>

                        <Typography color="textSecondary" className={classes.column}><Translate
                            id={"Payments.InvoiceColumn"}/>:</Typography>
                        <Typography color="textSecondary" className={classes.data}>{payment.invoiceNumber}</Typography>
                        <br/>

                        <Typography color="textSecondary" className={classes.column}><Translate
                            id={"Payments.InitDateColumn"}/>:</Typography>
                        <Typography color="textSecondary" className={classes.data}>
                            {payment.paymentDate ? moment(payment.paymentDate).format("DD/MM/yyyy") : ""}
                        </Typography>
                        <br/>

                        <Typography color="textSecondary" className={classes.column}><Translate
                            id={"Payments.ConceptColumn"}/>:</Typography>
                        <Typography color="textSecondary" className={classes.data}>{payment.concept}</Typography>
                        <br/>

                        <Typography color="textSecondary" className={classes.column}><Translate
                            id={"Payments.TotalColumn"}/>:</Typography>
                        <Typography color="textSecondary" className={classes.data}>{payment.import}{payment.currencySymbol ? payment.currencySymbol : ''}</Typography>
                    </Grid>

                    <Grid item xs={4} className={classes.downloadBox}>

                        {payment.success === true ?
                            <React.Fragment>
                                <Typography color="textSecondary" className={classes.column}><Translate
                                    id={"Payments.DownloadColumnS"}/></Typography>
                                <img src={DownloadIcon} style={{marginTop: 10}}
                                     role="button"
                                     onClick={() => props.handleDownloadInvoice(payment.id)}/>
                            </React.Fragment>
                            : <RepeatIcon color="secondary" style={{fontSize: 40}}
                                          onClick={() => props.handleRetryPayment(payment.id)}/>}
                    </Grid>
                </Grid>
            </Paper>
        }}
    </Translate>);
}

export default withRouter(PaymentCard);