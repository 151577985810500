import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {Grid, Paper, useMediaQuery} from '@material-ui/core';
import {ClickAwayListener, Grow, Popper, MenuItem, MenuList} from '@material-ui/core';
import {MoreHoriz} from '@material-ui/icons';
import api from "../../../api/index";
import ReactiavteProcess from "./reactivationForm";
import * as types from "../../../actions/errors";
import {useDispatch} from "react-redux";
import {applicationStates, offersSatte} from "../../../utils/constants";
import BasicConfirmation from "../../confirmation/basicConfirmation";


function OfferMenu(props) {
    const dispatch = useDispatch();
    const useStyles = makeStyles((theme) => ({
        root: {
            position: "absolute",
            right: 0,
            top: 0,
            [theme.breakpoints.only('xs')]: {
                position: props.position ? props.position : "inherit",
                marginLeft: 8
            }
        },
        menuCircle: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 50,
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            [theme.breakpoints.only('xs')]: {
                width: 30,
                height: 30,
            },
        },
        nonPadding: {
            padding: 0
        },
        option: {
            fontSize: 16,
            fontWeight: 400,
            paddingTop: 4,
            paddingBottom: 4
        },
    }))
    const classes = useStyles();
    const theme = useTheme();
    const screenMdDow = useMediaQuery(theme.breakpoints.down('md'));
    const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    let {
        open, setOpen, handleReactivateProcesses, handleEditProcess, managementOption,
        handleManagement, handleCopy, offerId, draft, reopen,
        refreshData, stateCode, expired, offer
    } = props;
    let reactivated = props.reactivated ? props.reactivated : false;

    let [reactiavteProcess, setReactivateProcess] = useState(false);
    const prevOpen = React.useRef(open);
    const anchorRef = React.useRef(null);
    let [closeProcess, setCloseProcess] = useState(false);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open])

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    function handleReopenOffer() {
        api.offers.reopenOffer(offerId).then(response => {
            if (response.status === 200) {
                if (refreshData) refreshData();
            } else {
                showMessage(response.data ? response.data.error : response.message, "error");
            }
        })
        handleToggle();
    }

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    const handleCloseProcess = (event) => {
        if (offer && !offer.hired)
            setCloseProcess(true);
        else {
            closeSelectionProcess();
        }
        handleToggle()
    };

    function closeSelectionProcess() {
        api.offers.closeSelectionProcess(offerId).then(response => {
            if (response.status === 200) {
                if (refreshData) refreshData();
            } else {
                showMessage(response.data ? response.data.error : response.message, "error");
            }
            setCloseProcess(false);
        })
    }

    const allowManagement = () => {
        return /*stateCode !== offersSatte.closed &&*/ managementOption && !draft;
    }

    const allowReopen = () => {
        return (offer && offer.canBeReopened) /*|| (!draft && !reopen && expired === false)*/;
    }

    return (<div className={classes.root}>
        <div className={classes.menuCircle}>
            <MoreHoriz ref={anchorRef} fontSize={xsOnly ? "small" : "large"}
                       aria-controls={open ? 'menu-list-grow' : undefined}
                       aria-haspopup="true" onClick={handleToggle}/>
        </div>

        <Popper style={{zIndex: 99}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList className={classes.nonPadding} autoFocusItem={open} id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}>
                                {allowManagement() &&
                                <MenuItem className={classes.option} onClick={(event) => {
                                    if (handleManagement) handleManagement(event);
                                    handleClose(event);
                                }}>
                                    <Translate id={"Offers.Management"}/>
                                </MenuItem>}

                                <MenuItem className={classes.option} onClick={(event) => {
                                    if (handleEditProcess) handleEditProcess(event);
                                    handleClose(event);
                                }}>
                                    <Translate id={"Offers.ManagementEdit"}/>
                                </MenuItem>

                                {!draft && !reactivated && expired === false &&
                                <MenuItem
                                    className={classes.option}
                                    onClick={(event) => {
                                        setReactivateProcess(true);
                                        if (handleReactivateProcesses) handleReactivateProcesses(event)
                                        handleClose(event)
                                    }}>
                                    <Translate id={"Offers.ManagementReactive"}/>
                                </MenuItem>}

                                {!draft && <MenuItem className={classes.option}
                                                     onClick={(event) => {
                                                         if (handleCopy) handleCopy(offerId);
                                                         handleClose(event)
                                                     }}>
                                    <Translate id={"Offers.ManagementCopy"}/>
                                </MenuItem>}

                                {!draft && stateCode !== offersSatte.closed
                                && <MenuItem className={classes.option} onClick={handleCloseProcess}><Translate
                                    id={"Offers.ManagementClose"}/>
                                </MenuItem>}

                                {allowReopen() &&
                                <MenuItem className={classes.option} onClick={handleReopenOffer}><Translate
                                    id={"Offers.ManagementReopen"}/>
                                </MenuItem>}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
        <ReactiavteProcess reactiavteProcess={reactiavteProcess}
                           setReactivateProcess={setReactivateProcess}
                           offer={offer}
                           offerId={offerId}
                           refreshData={() => {
                               if (props.refreshData) props.refreshData();
                           }}/>

        <BasicConfirmation open={closeProcess} type="secondary"
                           title={<Translate id={"Offers.CloseProcess"}/>}
                           question={<Translate id={"Offers.CloseProcessQuestion"}/>}
                           handleCancel={() => setCloseProcess(false)}
                           handleAccept={() => closeSelectionProcess()}/>
    </div>);
}

export default withRouter(OfferMenu);