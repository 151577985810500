import React from 'react';
import { Route, Switch } from 'react-router';

export const routers = [<Route key={1} path='/' showSiteMap={true} isRoot={true} hasFather={false} isUrl={true} text={"Titles.SearchSelectionProcesses"} order={6} />,
<Route key={2} path='/about-us' showSiteMap={true} isRoot={true} hasFather={false} isUrl={true} text={"Menus.AboutUs"} order={0} />,
<Route key={3} path='/benefits/professional' showSiteMap={true} isRoot={true} hasFather={"Buttons.Benefits"} isUrl={false} idFather={"Buttons.Benefits"} text={"Menus.ProfessionalBenefits"} order={1} />,
<Route key={4} path='/benefits/employers' showSiteMap={true} isRoot={true} hasFather={"Buttons.Benefits"} idFather2={"Menus.EmployerBenefits"} text={"Menus.EmployerBenefits"} order={1} />,
<Route key={5} path='/employer/pricing' showSiteMap={true} isRoot={true} hasFather={"Buttons.Benefits"} hasFather2={"Menus.EmployerBenefits"} text={"Menus.PricingServices"} order={1} />,
<Route key={6} path='/tips/professional' showSiteMap={true} isRoot={true} hasFather={"Buttons.Tips"} text={"Menus.ProfessionalTips"} isUrl={false} idFather={"Buttons.Tips"} order={2} />,
<Route key={7} path='/tips/employers' showSiteMap={true} isRoot={true} hasFather={"Buttons.Tips"} text={"Menus.EmployerTips"} order={2} />,

<Route key={8} path='/contact' showSiteMap={true} isRoot={true} hasFather={false} isUrl={true} text={"Menus.Contact"} order={3} />,

<Route key={9} path='/professional/login' showSiteMap={true} isRoot={true} hasFather={false} isUrl={true} idFather={1} text={"Menus.ProfessionalAccess"} order={4} />,
<Route key={10} path='/professional/workspace/0' showSiteMap={true} isRoot={false} hasFather={1} text={"Offers.ManageSelectionProcesses"} />,
<Route key={11} path='/professional/workspace/1' showSiteMap={true} isRoot={false} hasFather={1} text={"Menus.ManageSelectionProcessAlerts"} />,
<Route key={12} path='/tips/professional' showSiteMap={true} isRoot={false} hasFather={1} isUrl={true} text={"Menus.ProfessionalTips"} />,
<Route key={13} path='/benefits/professional' showSiteMap={true} isRoot={false} hasFather={1} text={"Menus.ProfessionalBenefits"} />,
<Route key={14} path='#' showSiteMap={true} isRoot={false} hasFather={1} text={"Titles.SearchSelectionProcesses"} />,
<Route key={15} path='/professional/edit-profile' showSiteMap={true} isRoot={false} hasFather={1} idFather2={"Menus.EditAccount"} text={"Menus.EditAccount"} />,
<Route key={16} path='#' showSiteMap={true} isRoot={false} hasFather={1} hasFather2={"Menus.EditAccount"} text={"Offers.ViewNewVideoInterview"} />,
<Route key={17} path='#' showSiteMap={true} isRoot={false} hasFather={1} hasFather2={"Menus.EditAccount"} text={"Offers.RecordNewVideoInterview"} />,
<Route key={18} path='#' showSiteMap={true} isRoot={false} hasFather={1} hasFather2={"Menus.EditAccount"} text={"Offers.ViewVideospeakingTest"} />,
<Route key={19} path='#' showSiteMap={true} isRoot={false} hasFather={1} hasFather2={"Menus.EditAccount"} text={"Offers.RecordVideospeakingTest"} />,

<Route key={20} path='/employer/login' showSiteMap={true} isRoot={true} hasFather={false} isUrl={true} idFather={2} text={"Menus.EmployerAccess"} order={5} />,
<Route key={21} path='/employer/workspace/0' showSiteMap={true} isRoot={false} isUrl={false} hasFather={2} idFather2={"Offers.ManageSelectionProcesses"} text={"Offers.ManageSelectionProcesses"} />,
<Route key={22} path='#' showSiteMap={true} isRoot={false} hasFather={2} isUrl={false} hasFather2={"Offers.ManageSelectionProcesses"} text={"Offers.ManageProfessionalProfiles"} />,
<Route key={23} path='/employer/process/create' showSiteMap={true} isRoot={false} hasFather={2} text={"Titles.PublishSelectionProcesses"} />,
<Route key={24} path='/employer/workspace/2' showSiteMap={true} isRoot={false} hasFather={2} text={"Offers.VideospeakingTest"} />,
<Route key={25} path='/employer/workspace/3' showSiteMap={true} isRoot={false} hasFather={2} text={"Offers.ReactivateSelectionProcess"} />,
<Route key={26} path='/employer/pricing' showSiteMap={true} isRoot={false} isUrl={true} hasFather={2} text={"Offers.PricingServices"} />,
<Route key={27} path='/employer/payments' showSiteMap={true} isRoot={false} hasFather={2} text={"Payments.Title"} />,
<Route key={28} path='/tips/employers' showSiteMap={true} isRoot={false} hasFather={2} isUrl={true} text={"Menus.EmployerTips"} />,
<Route key={29} path='/benefits/employers' showSiteMap={true} isRoot={false} hasFather={2} text={"Buttons.BenefitsForEmployers"} />,
<Route key={30} path='/employer/edit-profile' showSiteMap={true} isRoot={false} hasFather={2} text={"Menus.EditAccount"} />,

<Route key={31} path='/Access-Policy' showSiteMap={true} isRoot={true} hasFather={"Buttons.OtherPages"} idFather={"Buttons.OtherPages"} text={"Titles.ConditionsUse"} order={7} />,
<Route key={32} path='/Cookies-Policy' showSiteMap={true} isRoot={true} hasFather={"Buttons.OtherPages"} text={"Titles.TitleCookiesPolicy"} order={7} />,
<Route key={33} path='/Privacy-Policy' showSiteMap={true} isRoot={true} hasFather={"Buttons.OtherPages"} text={"Titles.TitlePrivacyPolicy"} order={7} />,
<Route key={34} path='/Hiring-Policy' showSiteMap={true} isRoot={true} hasFather={"Buttons.OtherPages"} text={"Titles.ContractConditions"} order={7} />,
<Route key={35} path='/professional/sample/0/0' />,
<Route key={36} path='/offer/:seo' />,];
export default (
    <Switch>
        {routers.map(r => r)}
    </Switch>
);