import React, {Component, useEffect, useState} from "react";
import VideoRecorder from '../../components/interview/recorder/video-recorder';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../actions/interview/index";
import * as localforage from "../../indexdb/localforageManager";

function HiredRecorder(props) {
    let videoRecorder = React.createRef();
    const recorder = () => videoRecorder && videoRecorder.current;
    const dispatch = useDispatch();

    let [video, setVideo] = useState(null);

    useEffect(() => {
        if (props.onReady)
            props.onReady(recorder());
    }, [video])

    function onLoadedVideo(videoComponent) {
        setVideo(videoComponent);
        if (props.onLoadedVideo) {
            props.onLoadedVideo(videoComponent);
        }
    }

    function handleTurnOffCamera(){
        if (video) video.turnOffCamera();
    }

    return (<VideoRecorder
        key="video-recorder"
        onRecordingComplete={async (videoBlob, thumbnailBlob) => {
            /*let thumbnails = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_POSTER);
            if (!thumbnails) await localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW_POSTER, thumbnailBlob);*/
            //await localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW_POSTER, thumbnailBlob);
            if (props.onRecordingComplete) props.onRecordingComplete(videoBlob, thumbnailBlob);
            handleTurnOffCamera()
        }}
        onPauseRecording={() => {
            if (props.onPauseRecording) props.onPauseRecording();
        }}
        notifySupport={(support) => {
            if (support) dispatch(actions.setInterviewSupport(support));
        }}
        notSupportRecorder={() => {
            if (props.notSupportRecorder)
                props.notSupportRecorder()
        }}
        notifySuccessRecorder={() => {
            if (props.notifySuccessRecorder)
                props.notifySuccessRecorder()
        }}
        constraints={{audio: true, video: true}}
        type="video/mp4"
        showReplayControls={true}
        replayVideoAutoplayAndLoopOff={true}
        ref={videoRecorder}
        getRecorder={() => recorder()}
        isFlipped={true}
        isOnInitially={false}
        countdownTime={props.countdownTime}
        onLoadedVideo={onLoadedVideo}/>);
}


export default HiredRecorder;