import * as types from '../actions/errors/index';

const initialState = {
    errorText: "",
    errorTitle: "",
    open: false,
    type: "error"
};

function errorsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_ERROR_TEXT:
            return {
                ...state,
                errorText: action.payload
            }
        case types.SET_ERROR_TITLE:
            return {
                ...state,
                errorTitle: action.payload
            }
        case types.MESSAGE_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case types.OPEN:
            return {
                ...state,
                open: action.payload
            }

        default:
            return state
    }

}

export default errorsReducer;