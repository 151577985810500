import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, List, ListItem, ListItemText, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Translate, withLocalize } from "react-localize-redux";
import { filter, findIndex, split } from "lodash";
import { connect } from 'react-redux';
import * as types from '../../../actions/users/index';
import * as interviewTypes from '../../../actions/interview/index';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 390,
        [theme.breakpoints.up('md')]: {
            width: 365
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 320
        },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        margin: 'auto'
    },
    listItem: {
        height: 36,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            borderColor: theme.palette.primary.main,
            '& .MuiInputBase-input': {
                color: "#FFFFFF",
            },
        },
        display: "flex",
        justifyContent: "center"
    },
    listItemActive: {
        height: 36,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        textTransform: "uppercase",
        fontSize: 18,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            '& .MuiInputBase-input': {
                color: "#FFFFFF",
            },
            '& .MuiInput-underline:hover': {
                '& .MuiInputBase-input': {
                    color: theme.palette.secondary.main,
                }
            }
        },
        display: "flex",
        justifyContent: "center"
    },
    timePicker: {
        width: 73,
        '& .MuiIconButton-root': {
            padding: "0 !important"
        },
        '& .MuiInput-underline': {
            border: "none",
        },
        '&$focused &:focus &:hover': {
            border: "none",
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        }
    },
    timePickerActive: {
        width: 75,
        '& input': {
            backgroundColor: "white",
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 600
        },
        '& .MuiButtonBase-root':{
            padding: 0
        },
        [theme.breakpoints.down('sm')]: {
            width: 75,
            '& .MuiIconButton-root': {
                paddingRight: 0
            }
        },
        '& .MuiInput-underline': {
            //color: "#FFFFFF",
        },
        '& .MuiInput-underline:hover': {
            border: "none",
            color: theme.palette.secondary.main,
            //backgroundColor: theme.palette.background.paper,
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: "none"
        },
        "& .MuiInputBase-input": {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            },
        }
    },
    questionView: {
        ...theme.overrides.MuiTypography.questionView
    },
    body4: {
        ...theme.overrides.MuiTypography.body4
    }
}));


function AdjustTimes(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [questions, setQuestions] = useState([]);
    const [indexActiveQuestion, setIndexActiveQuestion] = useState(-1);
    const [error, setError] = useState("");
    const [change, setChange] = useState(false);
    const [maxValueRecord, setMaxValueRecord] = useState(0);
    const reconfigurations = useSelector(state => state.interviews.configuration);
    let { isLocalVideo } = props;
    const timeRecordConfiguration = useSelector(state=> state.configurations.configs && state.configurations.configs.MaxRecordTime ? parseInt(state.configurations.configs.MaxRecordTime) : 180);

    useEffect(() => {
        mapQuestions();
        initMaxDuration();
    }, [props.questions, props.maxValue, props.isLocalVideo])

    function mapQuestions() {
        if (questions.length === 0) {
            if (reconfigurations.length > 0) setQuestions(reconfigurations);
            else if (props.questions) {
                let totalDuration = 0;
                let reconfig = [];
                props.questions.map((value, index) => {
                    reconfig.push({
                        start: "00:00",
                        startDuration: 0,
                        duration: 0,
                        durationFormat: "00:00",
                        index: index
                    });
                })
                setQuestions(reconfig);
            }
        }
    }

    function initMaxDuration() {
        if (props.maxValue > 0) setMaxValueRecord(props.maxValue);
    }

    const handleCurrentQuestion = (index) => {
        setIndexActiveQuestion(index);
    };

    const handleChangeTime = (mon, index) => {
        if (mon._isValid) handleError("");
        else { handleError("InvalidTimeFormat"); }

        let aux = [];
        let seconds = parseInt(moment(mon).format('ss'));
        let min = parseInt(moment(mon).format('mm'));
        let maxTime = 0;
        Object.assign(aux, questions);
        let indexQuestion = findIndex(questions, function (q) {
            return q.index === index;
        })
        if (indexQuestion !== -1) {
            aux[indexQuestion].start = moment(mon).format('mm:ss');
            aux[indexQuestion].startDuration = min * 60 + seconds;

            aux.map(question => {
                maxTime = maxTime + question.duration
            })
            setQuestions(aux);
        }
        if (mon._isValid) adjustDuration(aux, index);
    };

    function adjustDuration(aux, i) {
        if (i > 0) {
            let previewQuestion = aux[i - 1];
            let currentQuestion = aux[i];
            let nextTime = getNextTime(aux, i);
            let currentTime = getTime(aux[i].start);

            if (currentQuestion.startDuration > 0) {
                if (previewQuestion.startDuration > 0 || i === 1) {
                    let duration = currentTime - (previewQuestion.startDuration ? previewQuestion.startDuration : 0);
                    if (i === 1) aux[i - 1].duration = duration;
                    if (duration >= 0) {
                        aux[i - 1].durationFormat = moment("2000-01-01").startOf("hour").seconds(duration).format("mm:ss");
                        aux[i - 1].duration = getTime(aux[i - 1].durationFormat);
                    }
                }
            }

            //Actualizar el tiempo de la propia pregunta
            let currentDuration = nextTime - currentQuestion.startDuration;
            let errrorMax = currentDuration < 0 ? true : false;
            currentDuration = errrorMax ? 0 : currentDuration;
            aux[i].durationFormat = moment("2000-01-01").startOf("hour").seconds(currentDuration).format("mm:ss");
            aux[i].duration = getTime(aux[i].durationFormat);
            aux[i].error = errrorMax;
            setQuestions(aux);
        }
    }

    function getNextTime(aux, i) {
        if (i === aux.length - 1) return maxValueRecord;
        let minutes = parseInt(moment(aux[i + 1].start, "mm:ss").minutes());
        let seconds = parseInt(moment(aux[i + 1].start, "mm:ss").seconds());
        return minutes * 60 + seconds;
    }

    function getTime(time) {
        let minutes = parseInt(moment(time, "mm:ss").minutes());
        let seconds = parseInt(moment(time, "mm:ss").seconds());
        return minutes * 60 + seconds;
    }

    const handleError = (errorId) => {
        if (errorId !== "") {
            setError(props.translate(`Titles.${errorId}`));
        } else {
            setError("");
        }
    };

    function saveMetadata() {
        if (validateMetadatas()) {
            let metadatas = [];
            let endDuration = 0;
            for (let i = 0; i < questions.length; i++) {
                let question = questions[i];
                endDuration += question.duration;

                let lastMeta = metadatas.length > 0 ? metadatas[metadatas.length - 1] : null;
                let start = lastMeta ? lastMeta.end : 0;
                let end = endDuration;

                let meta = {
                    start: start,
                    end: end,
                    duration: question.duration,
                    durationFormat: question.durationFormat,
                    suggestDurationSeconds: question.duration,
                    question: props.questions[i].text,
                    index: i
                };
                metadatas.push(meta);
            }
            dispatch(types.setMetadata(metadatas));
            dispatch(interviewTypes.setUnsupportConfiguration(questions));
            setChange(false);
        }
    }

    function validateMetadatas() {
        let totalDuration = 0;
        let errorMaxTime = false;
        for (let index = 0; index < questions.length; index++) {
            const element = questions[index];
            totalDuration += element.duration;
            errorMaxTime = element.error;
        }
        if (totalDuration > maxValueRecord + 5 || errorMaxTime) {
            handleError("DurationExceded");
            return false;
        }

        if (totalDuration > timeRecordConfiguration) {
            handleError("MaxTimeViolation");
            return false;
        }

        let violationOrder = false;
        for (let index = 1; index < questions.length; index++) {
            const element = questions[index];
            for (let j = 0; j < index; j++) {
                const intro = questions[j];
                if (intro.startDuration > element.startDuration) {
                    violationOrder = true;
                    break;
                }
            }
        }
        if (violationOrder) {
            handleError("ViolationSatrtQuestion");
            return false;
        }

        handleError("");
        return true;
    }

    return (isLocalVideo && <Grid container spacing={0}>
        <Grid item xs={12} className={`text-center pb-0 mt-2`}>
            <Typography className={classes.questionView}>
                <Translate id={"Titles.AdjustInterviewTimes"} /> <Edit hidden={change} onClick={() => setChange(true)} />
            </Typography>

            <Grid hidden={!change} item xs={12}>
                {error && <Typography variant="body1" color="error">
                    {error}
                </Typography>}

                {questions && <Translate>
                    {({ translate }) => (
                        <List className={classes.root}>
                            {questions && questions.length > 0 && questions.map((question, index) => {
                                return <ListItem button key={question.index} alignItems="center"
                                    disabled={question.index === 0}
                                    autoFocus={question.index === indexActiveQuestion}
                                    onClick={() => handleCurrentQuestion(question.index)}
                                    className={`px-3 ${question.index === indexActiveQuestion ? classes.listItemActive : classes.listItem} ${index === 0 ? 'mb-3' : 'my-3'}`}>

                                    <Typography gutterBottom className={`${classes.body4} mb-0`}>
                                        {`${translate("Titles.Question")} ${question.index + 1} -`}
                                    </Typography>

                                    <ListItemText primary={question.durationFormat} className={`${classes.body4} pl-1 pt-1`} style={{ textAlign: "left", marginTop: 2 }} />

                                    <Typography className={`${classes.body4} mb-0 pr-2`} style={{ fontWeight: "bold" }}>
                                        {translate("Titles.Start")}
                                    </Typography>

                                    <KeyboardTimePicker key={index}
                                        open={false}
                                        className={`${question.index === indexActiveQuestion ? classes.timePickerActive : classes.timePicker}`}
                                        ampm={false}
                                        variant="inline"
                                        openTo="minutes"
                                        views={["minutes", "seconds"]}
                                        format="mm:ss"
                                        keyboardIcon={<Edit />}
                                        value={moment({
                                            year: 2020,
                                            month: 1,
                                            day: 1,
                                            hour: 0,
                                            minute: split(question.start, ':')[0],
                                            second: split(question.start, ':')[1]
                                        })}
                                        onChange={(mon) => handleChangeTime(mon, question.index)}
                                    />
                                </ListItem>
                            })}
                        </List>
                    )}
                </Translate>}

                <Translate>
                    {({ translate }) => (
                        <Button variant="contained" color="primary" onClick={saveMetadata}>
                            {translate("Buttons.SaveTimes")}
                        </Button>
                    )}
                </Translate>
            </Grid>
        </Grid>
    </Grid>);
}

const mapStateToProps = state => ({});
export default connect(mapStateToProps)(withLocalize(AdjustTimes));