import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Hidden, Paper, InputAdornment, IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import ProfessionalImageBackgroudLogin from "../../../images/professional/login-background.png";
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as types from "../../../actions/errors";

function ResetPassword(props) {
    const { innerWidth: width, innerHeight: height } = window;
    const isProfessional = window.location.pathname.indexOf("professional") > -1;
    const useStyles = makeStyles((theme) => ({
        titles: {
            marginTop: 50,
            [theme.breakpoints.down('xs')]: {
                marginTop: 10
            }
        },
        titlesFunctions: {
            display: "flex",
            [theme.breakpoints.down('xs')]: {
                display: "grid",
            }
        },
        responsiveWidth: {
            width: "100%",
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        form: {
            marginTop: 24,
            [theme.breakpoints.down('xs')]: {
                marginTop: 20
            }
        },
        submit: {
            minWidth: 227,
            borderRadius: 0,
            marginTop: 20
        },
        buttomtext: {
            fontWeight: "bold",
            color: isProfessional ? theme.palette.common.white : theme.palette.text.secondary,
            fontSize: 18,
            letterSpacing: 0.7,
            lineHeight: 1,
            width: 125

        },
        recoverText: {
            fontSize: 18,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            },
        },
        recoverTextBold: {
            fontSize: 18,
            fontWeight: "bold",
            color: theme.palette.text.secondary,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            },
        },
        mainTitle: {
            fontSize: 35,
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30
            },
            lineHeight: 1
        },
        sencondaryTitle: {
            fontSize: 25,
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
                marginTop: 8
            }
        },
        centerXs: {
            [theme.breakpoints.down('xs')]: {
                textAlign: "center"
            }
        },
        image: {
            maxWidth: width,
            height: "auto",
            marginLeft: -18
        },
        icon: {
            padding: 15,
            background: "#EDEDED",
            width: 54,
            height: 54,
            position: "absolute",
            right: 0,
            top: 20,
            cursor: "pointer",
        },
        errorText: {
            fontSize: 19,
            marginTop: 20
        },
        buttons: {
            marginTop: 0
        },
        linkedinButton: {
            width: 227,
            height: 50,
            color: "#FFFFFF",
            backgroundColor: "#1569BF",
            cursor: 'pointer',
            position: "relative",
            alignItems: "center",
            display: "flex",
        },
        linkedinImage: {
            float: "left",
            paddingLeft: 8
        },
        linkedinText: {
            position: "absolute",
            top: 4,
            left: 68,
            color: theme.palette.common.white,
            fontWeight: "bold",
            fontSize: 18,
            lineHeight: 1.2,
            textAlign: "left",
            letterSpacing: 0.7
        },
        or: {
            fontSize: 20,
            fontWeight: "bold",
            paddingTop: 7,
            paddingBottom: 7
        },
        buttonsContainer: {
            maxWidth: "max-content",
            marginBottom: 50,
            [theme.breakpoints.down('xs')]: {
                marginLeft: "auto",
                marginRight: "auto"
            }
        },
        tooltip: {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1
        },
        iconInfo: {width: "auto", height: "auto", position: "absolute", top: -8, right: 0,},
        check: {
            fontSize: 18,
            letterSpacing: 0.9,
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
            }
        },
        errorCheck: {
            border: "1px solid #FF0000",
            backgroundColor: "#FDE8E8"
        },
        errorHelper: {
            color: "#FF0000"
        }
    }));
    let classes = useStyles();
    let [typePass, setTypePass] = useState(true);
    let [typeConfirmPass, setTypeConfirmPass] = useState(true);

    let [errorType, setErrorType] = useState(false)
    let [errorResponse, setErrorResponse] = useState(false);
    let [errorResponseText, setErrorResponseText] = useState("");
    let [completed, setCompleted] = useState(false);
    const dispatch = useDispatch();
    let code = props.history.location.search.replace("?code=", "");
    let { email } = props.match.params;
    let [formData, setFormData] = useState({ password: "", confirmPassword: "", code: code, email: email });


    useEffect(() => {
        addValdationRule();
    }, [completed, errorResponse, setErrorResponseText])

    function addValdationRule() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let pass = document.getElementsByName("password")[0];
            if (value !== pass.value) {
                return false;
            }
            return true;
        });
    }

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    function handleError(error) {
        if (error) {
            showMessage(error, "error");
        } else {
            setErrorResponse(false);
            setErrorResponseText("")
        }
    }



    async function handleSubmit() {
        api.users.changePassword(formData).then(response => {
            let { data } = response;
            if (response.status === 200) {
                props.history.push(`/changepassword-success/${isProfessional ? "professional" : "employer"}`);
            } else {
                handleError(data.error)
            }
        });
    }

    const handleChange = (event) => {
        handleError(null);
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    return (<Grid container spacing={0}>
        <Grid item xs={12} className={classes.titles}>
            <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate id={"Employer.ResetPasswordTitle"} />} </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
            <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <Translate>
                    {({ translate }) => {
                        return <div className={classes.paddingResponse}>
                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typePass ? "password" : "text"}
                                    label={translate("Employer.Password")}
                                    debounceTime={1500}
                                    name="password"
                                    value={formData.password}
                                    validators={['required']}
                                    placeholder={translate("Employer.Password")}
                                    errorMessages={[translate("Fields.Required")]}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypePass(!typePass)}>
                                                {typePass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.normal }
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    type={typeConfirmPass ? "password" : "text"}
                                    label={translate("Employer.RepeatPasswordPlaceholder")}
                                    debounceTime={1500}
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    placeholder={translate("Employer.RepeatPasswordPlaceholder")}
                                    errorMessages={[translate("Employer.PasswordMismatch"), translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypeConfirmPass(!typeConfirmPass)}>
                                                {typeConfirmPass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: classes.normal }
                                    }} />
                            </Grid>

                            {!completed && <Grid item xs={12} className={classes.centerXs}>
                                <Button className={classes.submit} variant="contained" color={isProfessional ? "primary" : "secondary"} type="submit">
                                    <Typography className={classes.buttomtext}><Translate id={"Buttons.SaveNewPass"} /></Typography>
                                </Button>
                            </Grid>}
                        </div>
                    }}
                </Translate>
            </ValidatorForm>
        </Grid>

        {completed && <Grid item xs={12}>
            <Typography className={classes.notifyText}>{<Translate id={"Employer.RecoverPassNotify"} />} </Typography>
        </Grid>}

        <Grid item xs={12}>
            {errorResponse && <Typography variant="h2" color="error" className={classes.errorText}>{errorResponseText} </Typography>}
        </Grid>

    </Grid>);
}

export default withRouter(ResetPassword);