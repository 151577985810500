import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from "@material-ui/core";
import ScrollSpyTabs from "./scrollSpyTabs";
import {Translate} from "react-localize-redux";
import ChromePc from "./sections/chromePc";
import FirefoxPc from "./sections/firefoxPc";
import EdgePc from "./sections/edgePc";
import ChromeAndroid from "./sections/chromeAndroid";
import FirefoxAndroid from "./sections/firefoxAndroid";
import ChromeIphone from "./sections/chromeIphone";
import FirefoxIphone from "./sections/firefoxIphone";
import SafariIphone from "./sections/safariIphone";
import {withRouter, useHistory} from "react-router-dom";
import RefreshImage from "../../../images/support/refresh.jpg";

const useStyles = makeStyles(() => ({
    imgResponsive: {
        width: "100%",
        height: "auto"
    }
}));

function Help(props) {
    let {active} = props.match.params;

    React.useEffect(() => {

    }, [])

    function renderRefreshPage() {
        return <Grid item xs={12} className="d-flex">
            <Typography>(<Translate id="Help.PulseSimbol"/></Typography>
            <img alt={"image"} src={RefreshImage} style={{marginTop: -5}}/>
            <Typography><Translate id="Help.PulseKey"/></Typography>
            <Typography style={{fontWeight: "bold", marginLeft: 5}}> F5 <span
                style={{fontWeight: 400}}>)</span></Typography>
        </Grid>
    }

    return (<Container>
        <Translate>
            {({translate}) => {
                return <ScrollSpyTabs
                    activeTab={null}
                    history={props.history}
                    refresPageMessage={() => renderRefreshPage()}
                    tabsInScroll={[
                        {
                            text: translate("Help.CromeDescktop"),
                            component: <ChromePc refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.FirefoxDescktop"),
                            component: <FirefoxPc refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.EdgeDescktop"),
                            component: <EdgePc refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.CromeforAndroid"),
                            component: <ChromeAndroid refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.FirefoxforAndroid"),
                            component: <FirefoxAndroid refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.ChromeforIphone"),
                            component: <ChromeIphone refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.FirefoxIphone"),
                            component: <FirefoxIphone refresPageMessage={() => renderRefreshPage()}/>
                        },
                        {
                            text: translate("Help.SafariIphone"),
                            component: <SafariIphone refresPageMessage={() => renderRefreshPage()}/>
                        }
                    ]}
                />
            }}
        </Translate>
    </Container>)
}

export default withRouter(Help);