import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from "@material-ui/core";



export default function RegresiveTimer(props) {

    const useStyles = makeStyles((theme) => ({
        main: {
            fontSize: 44,
            fontWeight: 'bold',
            color: props.value > 6 ? theme.palette.primary.main : "red",
            marginBottom: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 34,
            },
        }
    }));

    const classes = useStyles();
    let { value, index, total } = props;

    function valueLabelFormat() {
        if (index < 0) return "";
        value = index > 0 ? value + 1 : value;
        let minutes = parseInt(value / 60);
        let seconds = value % 60;
        return `0${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
    }

    return (<h1 className={classes.main}>{valueLabelFormat()}</h1>);
}