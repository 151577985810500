import React, { useEffect } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import {Button, Typography, useMediaQuery,} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import ImageProfessional from "../../../images/employer/success-large.png";
import ImageProfessionalMovil from "../../../images/employer/success-movil.png";

const { innerWidth: width, innerHeight: height } = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 45,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
            maxWidth: 354,
        }
    },
    titlesFunctions: {
        display: "flex",
        [theme.breakpoints.down('xs')]: {
            display: "grid",
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    form: {
        /*marginTop: 24,*/
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    submit: {
        minWidth: 227,
        borderRadius: 0,
        // marginTop: 20
    },
    buttomtext: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        fontSize: 18,
        letterSpacing: 0.7

    },
    recoverText: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    recoverTextBold: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    mainTitle: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginTop: 8
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 54,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    errorText: {
        fontSize: 19,
        marginTop: 20
    },
    buttons: {
        marginTop: 0
    },
    linkedinButton: {
        width: 227,
        height: 50,
        color: "#FFFFFF",
        backgroundColor: "#1569BF",
        cursor: 'pointer',
        position: "relative",
        alignItems: "center",
        display: "flex",
    },
    linkedinImage: {
        float: "left",
        paddingLeft: 8
    },
    linkedinText: {
        position: "absolute",
        top: 4,
        left: 68,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1.2,
        textAlign: "left",
        letterSpacing: 0.7
    },
    or: {
        fontSize: 20,
        fontWeight: "bold",
        paddingTop: 7,
        paddingBottom: 7
    },
    buttonsContainer: {
        maxWidth: "max-content",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tooltip: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 1
    },
    iconInfo: {width: "auto", height: "auto", position: "absolute", top: -8, right: 0,},
    img: {
        marginTop: 40,
        marginBottom: 60,
        marginLeft: 136,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            /*width: 153,
            height: 193,
            objectFit: "cover"*/
        }
    }
}));

function SuccessEmployerRegister(props) {
    let classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
    }, [])

    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (<Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={10} xl={10}  className={classes.titles}>
            <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>
                {<Translate id={"Titles.CompletedRegister"} />}
            <br/>
                {<Translate id={"Titles.CompletedRegister2"} />} </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={10} className={classes.form}>
            <img alt={"register"} src={screenXsOnly ? ImageProfessionalMovil : ImageProfessional} className={classes.img}/>
            <Grid item xs={12} className={`${classes.buttonsContainer} pb-5`}>
                <NavLink to="/employer/workspace">
                    <Button className={classes.submit} variant="contained" color="secondary" type="submit">
                        <Typography className={classes.buttomtext}><Translate id={"Buttons.AccessArea"} /></Typography>
                    </Button>
                </NavLink>
            </Grid>
        </Grid>

    </Grid>);
}

export default withRouter(SuccessEmployerRegister);