import React, { useEffect } from 'react';
import { LinearProgress, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    percent: {
        textAlign: "left",
        marginTop: -12,
        marginLeft: 5,
        [theme.breakpoints.only('xs')]: {
            marginTop: -8
        },
    }
}))


export default function UploadProgress(props) {
    let { value } = props;
    const classes = useStyles();

    useEffect(() => {

    }, [value]);

    return (<Grid container {...props} style={{
        position: "absolute",
        width: "100%",
        maxWidth: 1113,
        alignSelf: "center"
    }}>
        <Grid item xs={11}>
            <LinearProgress variant="determinate" {...props} />
        </Grid>
        <Grid item xs={1}>
            <Typography className={classes.percent} variant="body2" color="textSecondary">{`${Math.round(value, 2)}%`}</Typography>
        </Grid>
    </Grid>);
}