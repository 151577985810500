import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {routers} from "./router";
import {orderBy} from "lodash";
import {Translate} from "react-localize-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse/Collapse";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {Container, Typography, useMediaQuery} from "@material-ui/core";
import {start} from "../utils/camerakit";

const useStyles = makeStyles((theme) => ({
    item: {
        width: "auto",
        //marginRight: 54,

    },
    itemText: {
        fontSize: 16,
        letterSpacing: 0.71,
        color: "inherit",
        '& .MuiTypography-body1': {
            color: "inherit",
            fontWeight: "inherit"
        }

    },
    nested: {
        //paddingLeft: theme.spacing(4),
        //marginRight: 54
        width: "auto",
        minWidth: 214,
        '& a': {
            color: theme.palette.text.secondary,
            fontWeight: 600
        },
        '& a:hover': {
            color: "#0056b3 !important",
        },
        '&.MuiListItem-button:hover': {
            color: "#0056b3 !important",
            backgroundColor: "transparent"
        }
    },
    nested2: {
        //paddingLeft: theme.spacing(8),
        width: "auto",
        minWidth: 306,
        [theme.breakpoints.only('sm')]: {
            maxWidth: 200,
        },
        '& a': {
            color: theme.palette.text.secondary,
            fontWeight: 600
        },
        '& a:hover': {
            color: "#0056b3 !important",
        },
        '&.MuiListItem-button:hover': {
            color: "#0056b3 !important",
            backgroundColor: "transparent"
        }
        // marginRight: 54
    },
    nested3: {
        //paddingLeft: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 200,
        },
        '& a': {
            color: theme.palette.text.secondary,
            fontWeight: 600
        },
        '& a:hover': {
            color: "#0056b3 !important",
        },
        '&.MuiListItem-button:hover': {
            color: "#0056b3 !important",
            backgroundColor: "transparent"
        },
        minWidth: 200,
        width: "auto"
        // marginRight: 54
    },
    display: {
        display: "flex",
        alignItems: "start",
        [theme.breakpoints.only('xs')]: {
            display: "Grid",
        },
    },
    titleEmployer: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: "#7a7a7b",
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left"
        },
    },
    container: {
        paddingBottom: 100,
        paddingLeft: 8,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 50,
        },
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 0,
        },

    }
}));

export default function SimplePaper() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [rootsOpen, setRootsOpen] = React.useState([{
        idElement: "Buttons.Benefits",
        openElement: true
    }, {idElement: "Buttons.Tips", openElement: true}, {idElement: 1, openElement: true}, {
        idElement: 2,
        openElement: true
    }, {
        idElement: "Menus.EmployerBenefits",
        openElement: true
    }, {idElement: "Buttons.OtherPages", openElement: true},
        {idElement: "Offers.ManageSelectionProcesses", openElement: true},
        {idElement: "Menus.EditAccount", openElement: true}]);

    useEffect(() => {
    }, [open, rootsOpen]);

    function handleClick() {
        setOpen(!open);
    };

    function handleClickOrder(index) {
        let data = Object.assign([], rootsOpen);
        if (index !== -1) {
            data[index].openElement = !data[index].openElement;
            setRootsOpen(data);
        }
    };

    function renderSiteMap() {
        let rootsOll = routers.filter(r => r.props.showSiteMap);
        let rootsOrder = routers.filter(r => r.props.isRoot && r.props.showSiteMap);
        let roots = orderBy(rootsOrder, [function (o) {
            return o.props.order
        }], "asc");

        return roots.map(rootElement => {
            if (rootElement.props.hasFather === false) {

                let childrens = rootElement.props.idFather ? rootsOll.filter(c => c.props.hasFather === rootElement.props.idFather && c.props.hasFather2 === undefined) : [];
                let index = rootsOpen.findIndex(d => d.idElement === rootElement.props.idFather);
                let father = childrens.length > 0 ? childrens.filter(c => c.props.idFather2 !== undefined) : [];
                let index2 = father.length > 0 ? rootsOpen.findIndex(d => d.idElement === father[0].props.idFather2) : -1;
                let childrens2 = rootElement.props.idFather ? rootsOll.filter(c => c.props.hasFather === rootElement.props.idFather && c.props.hasFather2 !== undefined) : [];

                return <Translate>
                    {({translate}) => {
                        return <div className={classes.display}>
                            <ListItem disableGutters button={rootElement.props.isUrl} className={`${classes.nested} `}
                            >
                                {
                                    rootElement.props.isUrl ?
                                        <a href={rootElement.props.path} target={"_blank"} style={{width: "100%"}}>
                                            <ListItemText primary={translate(rootElement.props.text)}
                                                          className={classes.itemText}/>
                                        </a> :
                                        <ListItemText primary={translate(rootElement.props.text)}
                                                      className={classes.itemText}/>
                                }
                                {rootElement.props.idFather ? ((index !== -1 ? rootsOpen[index].openElement : false) ?
                                    <ExpandLess onClick={() => handleClickOrder(index)} className="mr-3"/> :
                                    <ExpandMore onClick={() => handleClickOrder(index)} className="mr-3"/>) : ""}

                            </ListItem>
                            {
                                rootElement.props.idFather !== undefined ?
                                    <Collapse in={index !== -1 ? rootsOpen[index].openElement : false} timeout="auto"
                                              unmountOnExit>
                                        <List component="div" disablePadding
                                              className="pl-3 pt-sm-5 pt-md-5 pt-lg-5 pt-xl-5">
                                            {childrens.map(children => {

                                                return <div className={classes.display}>

                                                    <ListItem disableGutters button={children.props.isUrl}
                                                              className={`${classes.nested2}`}>
                                                        {
                                                            children.props.isUrl ?
                                                                <a href={children.props.path} target={"_blank"}>
                                                                    <ListItemText
                                                                        primary={translate(children.props.text)}
                                                                        className={classes.itemText}/>
                                                                </a> :
                                                                <ListItemText primary={translate(children.props.text)}
                                                                              className={classes.itemText}/>
                                                        }
                                                        {children.props.idFather2 ? ((index2 !== -1 ? rootsOpen[index2].openElement : false) ?
                                                            <ExpandLess onClick={() => handleClickOrder(index2)}
                                                                        className="ml-3"/> :
                                                            <ExpandMore
                                                                onClick={() => handleClickOrder(index2)}
                                                                className="ml-3"/>) : ""}
                                                    </ListItem>
                                                    {
                                                        children.props.idFather2 !== undefined ?
                                                            <Collapse
                                                                in={index2 !== -1 ? rootsOpen[index2].openElement : false}
                                                                timeout="auto"
                                                                unmountOnExit>
                                                                <List component="div" disablePadding
                                                                      className="pl-3 pt-sm-5 pt-md-5 pt-lg-5 pt-xl-5">
                                                                    {childrens2.map(children2 => {
                                                                        return <div>

                                                                            <ListItem disableGutters button={children.props.isUrl}
                                                                                      className={`${classes.nested3}`}>
                                                                                {
                                                                                    children.props.isUrl ?
                                                                                        <a href={children2.props.path}
                                                                                           target={"_blank"}>
                                                                                            <ListItemText
                                                                                                primary={translate(children2.props.text)}
                                                                                                className={classes.itemText}/>
                                                                                        </a> :
                                                                                        <ListItemText
                                                                                            primary={translate(children2.props.text)}
                                                                                            className={classes.itemText}/>
                                                                                }

                                                                            </ListItem>
                                                                        </div>
                                                                    })}
                                                                </List>
                                                            </Collapse>
                                                            : ""
                                                    }
                                                </div>
                                            })}
                                        </List>
                                    </Collapse>
                                    : ""
                            }

                        </div>
                    }}
                </Translate>
            } else {

                if (isNaN(rootElement.props.hasFather) && rootElement.props.idFather) {
                    let childrens = rootElement.props.idFather ? rootsOll.filter(c => c.props.hasFather === rootElement.props.idFather && c.props.hasFather2 === undefined) : [];
                    let index = rootsOpen.findIndex(d => d.idElement === rootElement.props.idFather);
                    let father = childrens.length > 0 ? childrens.filter(c => c.props.idFather2 !== undefined) : [];
                    let index2 = father.length > 0 ? rootsOpen.findIndex(d => d.idElement === father[0].props.idFather2) : -1;
                    let childrens2 = rootElement.props.idFather ? rootsOll.filter(c => c.props.hasFather === rootElement.props.idFather && c.props.hasFather2 !== undefined) : [];

                    return <Translate>
                        {({translate}) => {
                            return <div className={classes.display}>
                                <ListItem disableGutters button={rootElement.props.isUrl}
                                          className={`${classes.nested}`}
                                >
                                    {
                                        rootElement.props.isUrl ?
                                            <a href={rootElement.props.path} target={"_blank"} style={{width: "100%"}}>
                                                <ListItemText primary={translate(rootElement.props.hasFather)}
                                                              className={classes.itemText}/>
                                            </a>
                                            :
                                            <ListItemText primary={translate(rootElement.props.hasFather)}
                                                          className={classes.itemText}/>
                                    }

                                    {rootElement.props.idFather ? ((index !== -1 ? rootsOpen[index].openElement : false) ?
                                        <ExpandLess onClick={() => handleClickOrder(index)} className="mr-3"/> :
                                        <ExpandMore onClick={() => handleClickOrder(index)} className="mr-3"/>) : ""}

                                </ListItem>
                                {
                                    rootElement.props.idFather !== undefined ?
                                        <Collapse in={index !== -1 ? rootsOpen[index].openElement : false}
                                                  timeout="auto"
                                                  unmountOnExit>
                                            <List component="div" disablePadding
                                                  className="pl-3 pt-sm-5 pt-md-5 pt-lg-5 pt-xl-5">
                                                {childrens.map(children => {
                                                    return <div className={classes.display}>
                                                        <ListItem disableGutters button
                                                                  className={`${classes.nested2}`}>
                                                            <a href={children.props.path} target={"_blank"}>
                                                                <ListItemText primary={translate(children.props.text)}
                                                                              className={classes.itemText}/>
                                                            </a>
                                                            {children.props.idFather2 ? ((index2 !== -1 ? rootsOpen[index2].openElement : false) ?
                                                                <ExpandLess onClick={() => handleClickOrder(index2)}
                                                                            className="ml-3"/> :
                                                                <ExpandMore
                                                                    onClick={() => handleClickOrder(index2)}
                                                                    className="ml-3"/>) : ""}
                                                        </ListItem>
                                                        {
                                                            children.props.idFather2 !== undefined ?
                                                                <Collapse
                                                                    in={index2 !== -1 ? rootsOpen[index2].openElement : false}
                                                                    timeout="auto"
                                                                    unmountOnExit>
                                                                    <List component="div" disablePadding
                                                                          className="pl-3 pt-sm-5 pt-md-5 pt-lg-5 pt-xl-5">
                                                                        {childrens2.map(children2 => {
                                                                            return <div>
                                                                                <ListItem disableGutters button
                                                                                          className={`${classes.nested3}`}>
                                                                                    <a href={children2.props.path}
                                                                                       target={"_blank"}>
                                                                                        <ListItemText
                                                                                            primary={translate(children2.props.text)}
                                                                                            className={classes.itemText}/>
                                                                                    </a>
                                                                                </ListItem>
                                                                            </div>
                                                                        })}
                                                                    </List>
                                                                </Collapse>
                                                                : ""
                                                        }
                                                    </div>
                                                })}
                                            </List>
                                        </Collapse>
                                        : ""
                                }

                            </div>
                        }}
                    </Translate>
                }
            }

        })
    }

    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <Grid container direction="row"
              justify="center">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={11} className={classes.container}>
                <Typography className={`${classes.titleEmployer} my-4`}>{<Translate
                    id={"Titles.SiteMap"}/>} </Typography>
                <List component="div" disablePadding>
                    {renderSiteMap()}
                </List>

            </Grid>
        </Grid>
    );
}