import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles((theme) => ({
    imgResponsive: {
        width: "auto",
        height: "auto",
        maxWidth: "50%",
        [theme.breakpoints.only('xs')]: {
            maxWidth: "100%"
        }
    },
    title: {
        fontWeight: 600,
        marginBottom: 20
    }
}));

export default function ChromeIphone(props) {

    let classes = useStyles();
    return (<Translate>
        {({translate}) => {
            return <Grid container className="mb-5">
                <Typography className={classes.title} variant="h2"><Translate id="Help.ChromeIphoneTitle"/></Typography>
                <Grid item xs={12} className="mb-3" hidden={true}>
                    <Typography color="error"><Translate id="Help.GenericText1"/></Typography>
                    <Typography color="error"><Translate id="Help.GenericText2"/></Typography>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.ChromeIphoneStep1"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/9.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.ChromeIphoneStep2"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/10.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.ChromeIphoneStep3"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/1.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>4. <Translate id="Help.ChromeIphoneStep4"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/2.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>5. <Translate id="Help.ChromeIphoneStep5"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/7.jpg")}/>
                    <Grid item xs={12}>
                        <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                             src={require("../../../../images/support/chromeiphone/3.jpg")}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography><Translate id="Help.ChromeIphoneStep6"/></Typography>
                    <Typography>6. <Translate id="Help.ChromeIphoneStep7"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/4.jpg")}/>
                    <Grid item xs={12} className="mt-2">
                        <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                             src={require("../../../../images/support/chromeiphone/5.jpg")}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>7. <Translate id="Help.ChromeIphoneStep8"/></Typography>
                    <img className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/6.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>8. <Translate id="Help.ChromeIphoneStep5"/></Typography>
                    <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeiphone/7.jpg")}/>
                    <Grid item xs={12}>
                        <img alt={translate("Help.ChromeIphoneTitle")} className={classes.imgResponsive}
                             src={require("../../../../images/support/chromeiphone/8.jpg")}/>
                    </Grid>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography>9. <Translate id="Help.ChromeStep3"/></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                    </div>
                </Grid>
            </Grid>
        }}
    </Translate>)
}