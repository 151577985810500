import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepConnector, StepLabel, Typography, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepper: {
        minHeight: 50,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
    },
    stepRoot: {
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 7,
            paddingRight: 7
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 9,
            paddingRight: 9
        },
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        minHeight: 120,
        //paddingTop: "1rem",
        [theme.breakpoints.down('sm')]: {
            minHeight: window.innerHeight <= 667 ? 75 : 88,
        },
    },
    questionView: {
        ...theme.overrides.MuiTypography.questionView,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.questionView.fontSize
        },
    },
    paddingXs: {
        [theme.breakpoints.only('xs')]: {
            paddingTop: 10,
        },
    }
}));

const ColorlibConnector = withStyles({
    root: {
        display: "none"
    },
    active: {
        '& $line': {
            border: 'none',
        },
    },
    completed: {
        '& $line': {
            border: 'none',
        },
    },
    line: {
        height: 0,
        border: 0,
    },
    step: {
        flex: 0
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
        color: '#eaeaf0',
        //display: 'flex',
        //height: 22,
        //alignItems: 'center',
    },
    active: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        opacity: 1,
        lineHeight: 1.5,
        paddingTop: 2,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            width: 26,
            height: 26,
            lineHeight: 1.4,
            paddingTop: 1
        },
    },
    circle: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        opacity: 1,
        lineHeight: "normal",
        [theme.breakpoints.only('xs')]: {
            width: 20,
            height: 20,
            lineHeight: 1, 
            paddingTop: 1
        },
    },
    backgroundPrimary: {
        background: theme.palette.primary.main,
        fontSize: 18,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        },
    },
    backgroundSecondary: {
        background: theme.palette.secondary.main,
        zIndex: 1,
        fontSize: 18,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        },
    },
    thumb: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    customWidth: {
        maxWidth: 300,
        textAlign: 'center',
    },
    transparent: {
        background: "transparent",
        display: "none"
    },
    pointer: {
        cursor: "pointer"
    },
    customToolTip: {
        backgroundColor: "rgb(255, 255, 225);",
        border: "1px solid rgb(195, 195, 195)",
        color: "rgb(20, 20, 20)",
        textAlign: "center",
        paddingBottom: 3,
        paddingTop: 0,
        fontWeight: 400
    },
}));




export default function HorizontalLinearStepper(props) {
    const classes = useStyles();
    const { steps, activeStep, showTimes, hiddenTooltips } = props;
    const [indexStep, setIndexStep] = useState(0);

    useEffect(() => {
        if (activeStep >= 0 && activeStep !== indexStep) setIndexStep(activeStep);
    }, [indexStep, activeStep]);

    function getStepContent(step) {
        if (step >= 0 && step < steps.length) return steps[step].text ? steps[step].text : steps[step].question;
        return "";
    }

    function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed, icon, steps, showTimes, hiddenTooltips, index } = props;
        const activeQuestion = steps[icon - 1];
        return (<div id={index} className={clsx(classes.root, { [classes.active]: active })}>
            {completed || active
                ? <Tooltip classes={{ tooltip: hiddenTooltips ? classes.transparent : `${classes.customWidth} ${classes.customToolTip}` }} title={activeQuestion.text ? activeQuestion.text : activeQuestion.question} placement="top">
                    <div className={classes.pointer}>
                        <div className={`${classes.backgroundPrimary} ${active ? classes.active : classes.circle} ${classes.thumb}`}>
                            <Typography variant="inherit">{icon}</Typography>
                        </div>
                        <Typography variant="inherit" color="primary">{activeQuestion.durationFormat}</Typography>
                    </div>
                </Tooltip>
                : <Tooltip classes={{ tooltip: hiddenTooltips ? classes.transparent : `${classes.customWidth} ${classes.customToolTip}` }} title={activeQuestion.text ? activeQuestion.text : activeQuestion.question} placement="top">
                    <div className={classes.pointer}>
                        <div className={`${classes.circle} ${classes.backgroundSecondary} ${classes.thumb}`}>
                            <Typography variant="inherit">{icon}</Typography>
                        </div>
                        <Typography variant="inherit" color="primary">{activeQuestion.durationFormat}</Typography>
                    </div>
                </Tooltip>}
        </div>);
    }

    function QontoStepIconSimple(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed, icon, steps, showTimes, hiddenTooltips, index } = props;
        const activeQuestion = steps[icon - 1];
        return (<Tooltip classes={{ tooltip: hiddenTooltips ? classes.transparent : `${classes.customWidth} ${classes.customToolTip}` }} title={activeQuestion.text ? activeQuestion.text : activeQuestion.question} placement="top">
            <div className={classes.pointer}>
                <div className={`${active ? classes.active : classes.circle} ${completed || active ? classes.backgroundPrimary : classes.backgroundSecondary} ${classes.thumb}`}>
                    <Typography className={classes.root} variant="inherit">{icon}</Typography>
                </div>
                <Typography variant="inherit" color="primary">{activeQuestion.durationFormat}</Typography>
            </div>
        </Tooltip>);
    }

    const theme = useTheme();
    const { innerWidth: width, innerHeight: height } = window;
    return (
        <div className={classes.root}>
            <Stepper classes={{ root: classes.stepper }} activeStep={indexStep} alternativeLabel={false} connector={<ColorlibConnector />}
                className={`${classes.stepper} justify-content-center`}>
                {steps.map((label, index) => (
                    <Step id={`step_${index}`}
                        onClick={() => {
                            if (props.onclickCallback) props.onclickCallback(index);
                            setIndexStep(index)
                        }}
                        className={classes.stepRoot} key={index} indexActive={index}>
                        <StepLabel indexActive={index}
                            StepIconComponent={(props) =>
                                QontoStepIconSimple({ ...props, hiddenTooltips: hiddenTooltips, active: index === indexStep, showTimes: showTimes, steps: steps, index })} />
                    </Step>
                ))}
            </Stepper>
            <div className={`${height <= 568 && classes.paddingXs} ${classes.instructions}`} >
                <Typography className={props.className}>{getStepContent(indexStep)}</Typography>
            </div>
        </div>
    );
}