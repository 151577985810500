import * as types from '../actions/language/index';

const initialState = {
    languagesApp: [{
        "code": "en",
        "isoCode": "en-US",
        "name": "English",
        "order": 1
        },
        {
            "code": "es",
            "isoCode": "es-ES",
            "name": "Español",
            "order": 2
        }],
    arrayLanguages: [{
        "code": "en",
        "name": "English",
    },
        {
            "code": "es",
            "name": "Español",
        }]
};

function languagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_LANGUAGE:
            return {
                ...state,
                languagesApp: action.payload
            }
        case types.ARRAY_LANGUAGES:
            return {
                ...state,
                arrayLanguages: action.payload
            }

        default:
            return state
    }

}

export default languagesReducer;