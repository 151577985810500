import AWS from "aws-sdk";

let bucket = "hi-hired";
let location = "ams3";

let s3 = null;

export function configureS3(configs = {}) {
    //if (s3 === null)
    s3 = new AWS.S3({
        endpoint: configs.endpoint,
        accessKeyId: configs.accessKey,
        secretAccessKey: configs.secretKey,
        httpOptions: {
            timeout: (50 * 1000 * 60)
        }
    });
}

export const uploadVideo = async (video, progressCallBack, folder = 'interviews', name = 'interview') => {
    let params = {
        ContentType: "video/mp4",
        Body: video,
        Bucket: bucket,
        ACL: 'public-read',
        Key: `${folder}/${name}-${Math.floor(Math.random() * 10000000000000000)}.mp4`,
        Expires: 60 * 3
    };

    const options = {partSize: 100 * 1024 * 1024, queueSize: 1};//Subirlo en trozos de 5 mb

    return new Promise(async (resolve, reject) => {
        s3.upload(params, options).on("build", request => {
            request.httpRequest.headers.Host = "https://hihired.ams3.digitaloceanspaces.com";
            request.httpRequest.headers["Content-Length"] = video.size;
            request.httpRequest.headers["ExposeHeader"] = "ETag";
            request.httpRequest.headers["Content-Type"] = "application/octet-stream";
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
            request.httpRequest.headers["Access-Control-Max-Age"] = 3600;
            request.httpRequest.headers["MaxAgeSeconds"] = 3600;
            request.httpRequest.headers["ExposeHeader"] = "Location";
            console.log(request.httpRequest.headers);
        }).on("httpUploadProgress", function (progress) {
            console.log("progres-up", progress)
            if (progressCallBack) progressCallBack(progress)
        }).send((err, data) => {
            if (err) {
                console.log("Failed to upload file", `${err}`);
                resolve(false)
            }
            if (data) {
                console.log("Success upload video", data);
                data.Location = generateCdnUrl(data.Location, bucket, location);
                resolve(data);
            }
        });
    });
}

export const uploadSegmentVideo = async (video, folderkey, progressCallBack, foldername = "interviews", name = 'interview') => {
    const params = {
        ContentType: "video/mp4",
        Body: video,
        Bucket: bucket,
        ACL: 'public-read',
        Key: `${foldername}/${folderkey}/${name}-${Math.floor(Math.random() * 10000000000000000)}.mp4`,
        Expires: 60 * 3
    };

    const options = {partSize: 100 * 1024 * 1024, queueSize: 1};//Subirlo en trozos de 5 mb


    return new Promise(async (resolve, reject) => {
        s3.upload(params, options).on("build", request => {
            request.httpRequest.headers.Host = "https://hihired.ams3.digitaloceanspaces.com";
            request.httpRequest.headers["Content-Length"] = video.size;
            request.httpRequest.headers["ExposeHeader"] = "ETag";
            request.httpRequest.headers["Content-Type"] = "application/octet-stream";
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
            request.httpRequest.headers["Access-Control-Max-Age"] = 3600;
            request.httpRequest.headers["MaxAgeSeconds"] = 3600;
            request.httpRequest.headers["ExposeHeader"] = "Location";
            console.log(request.httpRequest.headers);
        }).on("httpUploadProgress", function (progress) {
            if (progressCallBack) progressCallBack(progress)
        }).send((err, data) => {
            if (err) {
                console.log("Failed to upload file", `${err}`);
                resolve(false)
            }
            if (data) {
                console.log("Success upload video", data);
                data.Location = generateCdnUrl(data.Location, bucket, location);
                resolve(data);
            }
        });
    });
}

export const removeVideo = async (videoKey, progressCallBack) => {
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: bucket,
            Key: videoKey
        };

        s3.deleteObject(params, (err, data) => {
            if (err) {
                console.log("Failed to upload file", `${err}`);
                resolve(false)
            }
            if (data) {
                console.log("Success upload video", data);
                resolve(data);
            }
        }).on("build", request => {
            request.httpRequest.headers.Host = "https://hi-hired.ams3.digitaloceanspaces.com";
            request.httpRequest.headers["Content-Type"] = "application/octet-stream";
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
        }).on("httpUploadProgress", function (progress) {
            if (progressCallBack) progressCallBack(progress)
        });
    });
}

function generateCdnUrl(url, bucket, location) {
    if (url.substr(0, 8) != 'https://') {
        url = `https://${bucket}.${url}`;
    }

    url = url.replace(`https://${bucket}.${location}`, `https://${bucket}.${location}.cdn`);
    return url;
}

export async function putCoors() {
    const thisConfig = {
        AllowedMethods: ['GET', 'POST', 'PUT', 'HEAD', 'DELETE'],
        AllowedHeaders: ['*'],
        AllowedOrigins: ['*'],
        ExposeHeaders: ['*'],
        MaxAgeSeconds: 3000,
    };

    const corsRules = new Array(thisConfig);
    const corsParams = {
        Bucket: bucket,
        CORSConfiguration: {CORSRules: corsRules},
    };

    return s3.putBucketCors(corsParams).on("build", request => {
        request.httpRequest.headers.Host = "https://hi-hired.ams3.digitaloceanspaces.com";
        request.httpRequest.headers["ExposeHeader"] = "ETag";
        request.httpRequest.headers["Content-Type"] = "application/octet-stream";
        request.httpRequest.headers["x-amz-acl"] = "public-read";
        request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
        request.httpRequest.headers["Access-Control-Max-Age"] = 3600;
        request.httpRequest.headers["MaxAgeSeconds"] = 3600;
        request.httpRequest.headers["ExposeHeader"] = "Location";
        console.log(request.httpRequest.headers);
    }).send((err, data) => {
        if (err) {
            console.log('Error', err);
        } else {
            console.log('Success', data);
        }
    });
}

export default s3;