import React, {useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FooterImage from "../../images/layuout/footer.png";
import FooterImageMovil from "../../images/layuout/footerMovil.png";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../actions/users/index";
import {Link, useMediaQuery} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FooterPoint from "../../images/layuout/footerPoints.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import api from "../../api/index";

const useStyles = makeStyles((theme) => ({
    footer: {
        zIndex: 2,
        backgroundImage: `url('${FooterImage}')`,
        width: "100%",
        height: 130,
        objectFit: "cover",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only('xs')]: {
            backgroundImage: `url('${FooterImageMovil}')`,
            height: 203,
            alignItems: "unset",
        },
        [theme.breakpoints.only('xl')]: {
            width: 1920,
        },
    },
    footerPoints: {
        //zIndex: 1,
        backgroundImage: `url('${FooterPoint}')`,
        backgroundSize: "cover",
        width: 860,
        height: 230,
        position: "absolute",
        left: "21%",
        bottom: 0,
        [theme.breakpoints.only('xs')]: {
            left: 0,
            width: "100%",
            backgroundSize: "auto",
            backgroundPositionX: "left",
            backgroundPositionY: "center",
        },
        [theme.breakpoints.only('sm')]: {
            left: 0,
            width: "100%",
            backgroundSize: "auto",
            backgroundPositionX: "left",
            backgroundPositionY: "center",
        },
        [theme.breakpoints.only('md')]: {
            width: 450,
            left: "16%"
        },
        [theme.breakpoints.only('lg')]: {
            width: 536,
            left: "23%",
        },
    },
    icon: {
        backgroundColor: "#FFFFFF",
        marginLeft: 10,
        marginRight: 10,
        height: 60,
        width: 60,
        paddingTop: 0,
        paddingBottom: 0,
        cursor: "pointer",

        //zIndex: 5550,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        '& .MuiSvgIcon-root': {
            height: 27,
            width: 27,
        }
    },
    followUs: {
        fontSize: 17,
        letterSpacing: 0.71,
        color: "#FFFFFF"
    },
    fontLanguage: {
        fontSize: 20,
        fontStyle: "italic",
        justifyContent: "space-evenly",
        zIndex: 5
    },
    divider: {
        width: 54,
        height: 1,
        position: "absolute",
    },
    colorPrimary: {
        backgroundColor: theme.palette.secondary.main
    },
    colorBlack: {
        backgroundColor: "#FFFFFF"
    },
    colorTextPrimary: {
        // cursor: "pointer",
        zIndex: 50,
        fontSize: 20,
        color: theme.palette.secondary.main,
        /*'&:hover': {
            color: theme.palette.secondary.main
        }*/
    },
    colorTextBlack: {
        color: "#FFFFFF",
        cursor: "pointer",
        zIndex: 50,
        '&:hover': {
            color: "#FFFFFF"
        }
    },
    Arrow: {
        color: "#FFF",
        position: "absolute",
        right: -18,
        top: 35
    },
    item: {
        width: "auto",
        marginRight: 54
    },
    itemText: {
        fontSize: 16,
        letterSpacing: 0.71,
        color: "#7A7A7B",
        //'& .MuiTypography-body1': {
        //    fontFamily: "Lorem ipsum",
        // }
    },
    dividerItem: {
        width: 0,
        height: 23,
        border: "1px solid #7A7A7B",
        marginRight: 12,
    },
    list: {
        width: "100%",
        display: "flex",
        alignItems: "start",
        [theme.breakpoints.up('lg')]: {
            width: 1174,
        },
        [theme.breakpoints.only('md')]: {
            width: 880,
        },
        [theme.breakpoints.only('sm')]: {
            width: 600,
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap",
        },
    },
    displayGrid: {
        display: "grid",
        justifyContent: "center"
    },
    topCopy: {
        paddingTop: 37,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 42,
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: 56,
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
        //marginRight: 54
    },
    nested2: {
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 200,
        },
        // marginRight: 54
    },
    nested3: {
        paddingLeft: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 200,
        },
        // marginRight: 54
    },
}));

export default function FooterLayout() {
    const classes = useStyles();
    const dispatch = useDispatch();
    let loggedUser = useSelector(state => state.users.loggedUser);

    useEffect(() => {
    }, []);


    // const translateState = useSelector(state => getTranslate(state.localize));

    function setApplicationLanguage(setActiveLanguage, isoCode) {
        if (isoCode) {
            let code = isoCode.split("-")[0];
            setActiveLanguage(code);
            dispatch(actions.setDefaultLanguage(isoCode));
            if (loggedUser) {
                api.language.setUserLanguage(code).then(response => {
                    window.location = window.location;
                }).catch(err => {
                    window.location = window.location;
                })
            }else{
                window.location = window.location;
            }
        }
    }


    function renderItem(text, url) {
        return <ListItem disableGutters className={classes.item}>
            <Divider className={classes.dividerItem}/>
            <Translate>
                {({translate}) => {
                    return <a href={url}>
                        <ListItemText primary={translate(text)} className={classes.itemText}/>
                    </a>
                }}
            </Translate>
        </ListItem>
    }

    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.footer}>
                <Grid container spacing={0}>
                    <Grid item xs={0} sm={1} md={4}/>
                    <Grid item xs={12} sm={10} md={5} style={screenXsOnly ? {display: "grid"} : {display: "flex"}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}
                                  className={`${classes.followUs} d-flex justify-content-center pb-3 ${screenXsOnly && "align-items-end"}`}>
                                <Typography variant="inherit">
                                    <Translate id="Titles.FollowUs"/>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="d-flex justify-content-center">
                                <IconButton color="primary" aria-label="Facebook" className={classes.icon}
                                            href={"https://www.facebook.com/Hi_Hired-100460992021050"}
                                            target={"_blank"}>
                                    <FacebookIcon/>
                                </IconButton>
                                <IconButton color="primary" aria-label="YouTube" className={classes.icon}
                                            href={"https://www.youtube.com/channel/UCKW3F-CyGRTzeDJaXS-ogXA"}
                                            target={"_blank"}>
                                    <YouTubeIcon/>
                                </IconButton>
                                <IconButton color="primary" aria-label="LinkedIn" className={classes.icon}
                                            href={"https://www.linkedin.com/company/hi-hired"} target={"_blank"}>
                                    <LinkedInIcon/>
                                </IconButton>
                                <IconButton color="primary" aria-label="Instagram" className={classes.icon}
                                            href={"https://www.instagram.com/hi_hired/"} target={"_blank"}>
                                    <InstagramIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12}
                                  className={`${classes.fontLanguage} d-flex ${!screenXsOnly && "align-items-center"}`}>
                                <div className="position-relative">
                                    <Translate>
                                        {({translate, activeLanguage, setActiveLanguage}) => {
                                            let active = activeLanguage && activeLanguage.code === "es";
                                            return <div>
                                                {active ? <Typography className={classes.colorTextPrimary}>
                                                        {translate("Buttons.Spanish")}
                                                    </Typography> :
                                                    <Link color="inherit" underline="none" variant="inherit"
                                                          onClick={() => setApplicationLanguage(setActiveLanguage, "es-ES")}
                                                          className={classes.colorTextBlack}>
                                                        {translate("Buttons.Spanish")}
                                                    </Link>
                                                }
                                                <Divider
                                                    className={active ? `${classes.colorPrimary} ${classes.divider}` : `${classes.colorBlack} ${classes.divider}`}/>
                                                {!active && <ArrowForwardIcon className={classes.Arrow}/>}
                                            </div>
                                        }}
                                    </Translate>

                                </div>
                                <div className="position-relative">
                                    <Translate>
                                        {({translate, activeLanguage, setActiveLanguage}) => {
                                            let active = activeLanguage && activeLanguage.code === "en";
                                            return <div>
                                                {active ? <Typography className={classes.colorTextPrimary}>
                                                        {translate("Buttons.English")}
                                                    </Typography> :
                                                    <Link color="inherit" underline="none" variant="inherit"
                                                          onClick={() => setApplicationLanguage(setActiveLanguage, "en-US")}
                                                          className={classes.colorTextBlack}>
                                                        {translate("Buttons.English")}
                                                    </Link>
                                                }

                                                <Divider
                                                    className={active ? `${classes.colorPrimary} ${classes.divider}` : `${classes.colorBlack} ${classes.divider}`}/>
                                                {!active && <ArrowForwardIcon className={classes.Arrow}/>}
                                            </div>
                                        }}
                                    </Translate>

                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={1} md={5}/>
                </Grid>
            </Grid>
            <Grid item xs={12} className="position-relative">
                {<div className={classes.footerPoints}/>}
                <Grid container spacing={0} direction="row" justify="center" alignItems="center"
                      style={{minHeight: 160}}>
                    <Grid item xs={12} className={classes.displayGrid} style={{backgroundColor: "#FFFFFF"}}>
                        <List component="nav" aria-label="footerMap" className={!screenXsOnly && classes.list}>
                            {renderItem("Titles.WebMap", "/site-map")}
                            {renderItem("Titles.ConditionsUse", "/Access-Policy")}
                            {renderItem("Titles.CookiesPolicy", "/Cookies-Policy")}
                            {renderItem("Titles.PrivacyPolicy", "/Privacy-Policy")}
                            {renderItem("Titles.ContractConditions", "/Hiring-Policy")}
                        </List>
                        <div className="justify-content-center d-flex">
                            <Typography variant="inherit" style={{marginTop: 10}} className={classes.itemText}>
                                {`© Hihired! ${new Date().getFullYear()}`}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}