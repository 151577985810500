import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Barlow',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    "palette": {
        "common": { "black": "#193C47", "white": "#fff", "gray": "#EFEFEF", green: "#54B169", lightGreen: "#E5FFEB" },
        "background": { "paper": "#fff", "default": "#fafafa" },
        "secondary": {
            "light": "rgba(255, 199, 149, 1)",
            "main": "#FFB170",
            "dark": "#FFB170",
            "contrastText": "#fff"
        },
        "primary": {
            "light": "rgba(154, 194, 216, 1)",
            "main": "#193C47",
            "dark": "#193C47",
            "contrastText": "#fff"
        },
        "error": {
            "light": "rgba(251, 156, 152, 1)",
            "main": "#FB060F",
            "dark": "rgba(246, 58, 45, 1)",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "#193C47",
            "secondary": "#141414",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "#141414",
            "success": "#54B169"
        },
        "success": {
            "light": "#54B169",
            "main": "#54B169",
            "dark": "#54B169",
            "contrastText": "#54B169"
        },
    },
    overrides: {
        MuiDialog: {
            paperWidthLg: {
                minHeight: 247,
                borderRadius: 40,
                textAlign: "center",
                padding: "40px 50px"
            },
            paperWidthMd: {
                minWidth: 1006,
                minHeight: 200,
                borderRadius: 40,
                textAlign: "center",
                padding: "15px 30px"
            }
        },
        MuiTypography: {
            h1: {
                '@media (max-width: 900px)': {
                    fontSize: 27
                },
                fontSize: 41,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "bold",
                letterSpacing: "0.74px",
            },
            h2: {
                '@media (max-width: 900px)': {
                    fontSize: 22,
                    lineHeight: 1
                },
                fontSize: 29,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.23px"
            },
            h3: {
                fontSize: 22,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "bold",
                letterSpacing: "0.18px"
            },
            h4: {
                '@media (max-width: 900px)': {
                    fontSize: 16
                },
                fontSize: 22,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.55px"
            },
            button: {
                fontSize: 18,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: 300,
                letterSpacing: "0.13px"
            },
            subtitle1: {
                '@media (max-width: 900px)': {
                    fontSize: 18,
                },
                fontSize: 24,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.19px",
            },
            title1: {
                fontSize: 49,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: 300,
                letterSpacing: "0.34px"
            },
            title2: {
                fontSize: 47,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.38px",
                '@media (max-width: 900px)': {
                    fontSize: 31,
                    letterSpacing: "0.26px",
                },
            },
            subtitle2: {
                fontSize: 24,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.19px"
            },
            subtitle3: {
                fontSize: 32,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.26px",
                lineHeight: 1,
                '@media (max-width: 900px)': {
                    fontSize: 26,
                    letterSpacing: "0.7px",
                },
            },
            questionView: {
                fontSize: 32,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.26px",
                lineHeight: 1,
                '@media (max-width: 900px)': {
                    fontSize: 22,
                    letterSpacing: "0.19px",
                },
            },
            question2: {
                fontSize: 40,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.32px",
                '@media (max-width: 900px)': {
                    fontSize: 28
                },
            },
            question3: {
                fontSize: 36,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.32px",
                '@media (max-width: 900px)': {
                    fontSize: 22,
                    letterSpacing: "0.19px",
                },
            },
            body1: {
                '@media (max-width: 900px)': {
                    fontSize: 16,
                    letterSpacing: "0.08px"
                },
                fontSize: 16,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.32px",
                color: "#141414"
            },
            body2: {
                '@media (max-width: 900px)': {
                    fontSize: 15,
                    letterSpacing: "0.08px"
                },
                fontSize: 16,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "1.1px"
            },
            body3: {
                '@media (max-width: 900px)': {
                    fontSize: 18
                },
                fontSize: 24,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.22px"
            },
            body4: {
                '@media (max-width: 900px)': {
                    fontSize: 10,
                    letterSpacing: "0.08px"
                },
                fontSize: 18,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.32px"
            },
            section: {
                '@media (max-width: 900px)': {
                    fontSize: 30
                },
                fontSize: 38,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
            },
        },
        MuiDialogActions: {
            root: {
                textAlign: "center",
                display: "block"
            }
        },
        MuiButton: {
            root: {
                borderRadius: 66,
                height: 50,
                minWidth: 220,
                padding: 0,
                textTransform: "none",
                '@media (min-width: 1280px)': {
                    minWidth: 220,
                },
                '@media (max-width: 900px)': {
                    minWidth: 220,
                }
            },
            contained: {},
            label: {
                fontSize: 18,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "0.45px"
            },
            outlined: {
                border: "2px solid #585554",
                minWidth: 220
            }
        },
        MuiButtonBase: {
            root: {
                "&:focus": {
                    outline: "none"
                },
            },
        },
        MuiPaper: {
            root: {
                '@media (max-width: 900px)': {
                    //margin: "0 !important"
                    margin: 0
                },
            },
            rounded: {
                borderRadius: 0
            }
        },
        DialogButton: {
            minWidth: 161
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                marginTop: 30
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 16,
                backgroundColor: "rgb(255, 255, 225);",
                border: "1px solid rgb(195, 195, 195)",
                color: "rgb(20, 20, 20)",
                textAlign: "center",
                paddingBottom: 3,
                paddingTop: 0,
                fontWeight: 400
            }
        },
        MuiInput: {
            underline: {
                borderBottom: "none",
                "&:before": {
                    borderBottom: "none",
                },
                "&:after": {
                    borderBottom: "none"
                },
                "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none !important",
                },
                '&.Mui-disabled:before': {
                    borderBottomStyle: "none",
                }
            },
            root: {
                backgroundColor: "#F7F7F7",
                borderRadius: 0,
                minHeight: 50,
                marginBottom: 10,
                marginTop: "26px !important",
                '@media (min-width: 960px)': {
                    minWidth: 200,
                }
            },
            inputMultiline: {
                padding: "8.5px 16px 12.5px 16px"
            }
        },
        MuiInputLabel: {
            root: {
                fontWeight: "bold",
                fontSize: 20,
                marginLeft: 16,
                color: "#193C47",
                '&.Mui-required': {
                    color: "#FFB170"
                }
            },
            filled: {
                "&.MuiInputLabel-shrink": {
                    transform: "translate(0px, 8px) scale(0.75) !important;"
                }
            },


        },
        MuiInputBase: {
            input: {
                fontSize: 16,
                padding: 16,
                backgroundColor: "#F7F7F7",
                borderRadius: 0,
                '@media (min-width: 960px)': {
                    minWidth: 200,
                },
                '@media (max-width: 900px)': {
                    fontSize: 15,
                    letterSpacing: "0.08px"
                },

            },
            root: {
                '&.Mui-disabled': {
                    border: "1px solid #141414",
                    color: "#141414",
                    '& .MuiInputAdornment-root': {
                        backgroundColor: "transparent",
                    }
                }
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 0,
                backgroundColor: "#F7F7F7",
            },
            multiline: {
                padding: 16,
                backgroundColor: "#F7F7F7",
                marginTop: 24,
                //borderBottom: "none",
                "&:before": {
                    height: 0,
                    borderBottom: "none",
                    backgroundColor: "#F7F7F7",
                },
                "&:hover": {
                    "&:before": {
                        borderBottom: "none",
                        height: 0
                    },
                    borderBottom: "none",
                },
                "& textarea": {
                    background: "transparent"
                }
            },
            underline: {
                "&:after": {
                    borderBottom: "none !important",
                    backgroundColor: "#F7F7F7",
                },
            }
        },
        MuiFormControl: {
            root: {
                marginBottom: 4
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: -12,
                '&.Mui-error': {
                    fontSize: 14,
                }
            },
            contained: {
                marginTop: 0,
                marginLeft: 0
            }
        },
        MuiContainer: {
            root: {
                paddingLeft: 25,
                paddingRight: 25
            }
        },
        Mui: {},
        MuiInputAdornment: {
            root: {
                background: "#EDEDED",
                height: 48,
                width: 55,
                justifyContent: "center",
                maxHeight: "initial",
                top: "1px !important"
            },
            positionEnd: {
                marginLeft: 0,
                position: "absolute",
                right: 1,
                top: 1
            },
            positionStart: {
                minWidth: 56,
            }
        },

    }
});

export default theme;