import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useDispatch, useSelector, connect } from "react-redux";
import * as types from '../../actions/users/index';
import * as localforage from "../../indexdb/localforageManager";


const useStyles = makeStyles((theme) => ({
    root:{
        background: theme.palette.common.white
    },
    imgResponsive: {
        width: "100%",
        height: "auto"
    },
    box: {
        borderRadius: 38,
        padding: 50,
        textAlign: "center",
        boxShadow: "21px 26px 85px #00000010",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        [theme.breakpoints.down('sm')]: {
            padding: "20px 5px"
        }
    },
    buttonTop: {
        marginBottom: "3rem",
        marginTop: "3rem",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "1rem",
            marginTop: "1rem",
        },
    }
}));

export default function InvalidResult(props) {
    let classes = useStyles();
    let dispatch = useDispatch();

    const handleDeleteInterviewRecord = () => {
        dispatch(types.resetInterview());
        localforage.removeItem(localforage.PROFESSIONAL_INTERVIEW).then((del) => {
            props.history.push("/home-interview");
        }).catch((err) => {
            console.log(err);
        });
    };

    return (<Grid container spacing={2} className={`pt-5 ${classes.root}`} direction="row" justify="center" >
        <Grid item xs={12} md={9}>
            <Paper elevation={3} className={classes.box}>
                <Typography variant="h2" color="error">
                    <Translate id={"Errors.InvalidResult"} />
                </Typography>

                <Translate>
                    {({ translate }) => (
                        <Button variant="contained" color="primary"
                            onClick={handleDeleteInterviewRecord}
                            className={`${classes.button} ${classes.buttonTop}`}>
                            {translate("Buttons.RecordAgain")}
                        </Button>
                    )}
                </Translate>
            </Paper>
        </Grid>
    </Grid>)
}