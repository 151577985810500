import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {Translate} from "react-localize-redux";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
        typography: {
            padding: theme.spacing(2),
        },
        button: {
            height: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            textAlign: "start",
            borderRadius: 0,
            padding: 0,
            '&.MuiButton-root:hover': {
                textDecoration: "underline",
                backgroundColor: "transparent",
            },
            '& .MuiButton-label': {
                fontSize: 16,
                fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
                fontWeight: "normal",
                letterSpacing: "1.45px",
                [theme.breakpoints.only('xs')]: {
                    fontSize: 15,
                    letterSpacing: "1.8px"
                }
            }

        },
        paper: {
            maxWidth: 285
        },
        textField: {
            '& .MuiInputBase-root.Mui-disabled': {
                border: "none",
                backgroundColor: "transparent",
                margin: "0 !important",
                padding: 0
            },
            '& .MuiInput-inputMultiline': {
                padding: "14.5px 16px 8.5px 16px",
                backgroundColor: "transparent",
            }
        }

    }))
;

export default function SimplePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button onClick={handleClick} className={classes.button}>
                <Translate id={props.text}/>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{paper: classes.paper}}
            >
                <TextField className={classes.textField}
                    value={props.description ? props.description :
                        <Translate id={"Employer.ThereIsNoAssociatedDescription"}/>}
                    multiline
                    rows={8} disabled={true}
                />
            </Popover>
        </div>
    );
}