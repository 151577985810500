import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Grid, useMediaQuery } from '@material-ui/core';
import {
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
    Hidden,
    useScrollTrigger
} from '@material-ui/core';
import { Menu, MenuItem, MenuList } from "@material-ui/core";
import { VerticalAlignBottom } from '@material-ui/icons';
import { NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import hamburger from "../../images/layuout/hamburger.png";
import LogoImage from "../../images/logo.png";
import { Translate } from "react-localize-redux";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import LoguedMenu from "./employerMenu";
import LogoImageMovil from "../../images/logoMovil.png";
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: "transparent",
        [theme.breakpoints.down('xs')]: {
            "@media (max-width: 360px)": {
                padding: 0
            }
        },
    },
    appBar: {
        //backgroundColor: "transparent",
        minHeight: 90,
        [theme.breakpoints.down('xs')]: {
            minHeight: 104,
        },
    },
    menuButton: {
        right: 0,
        position: 'absolute',
        width: 50,
        height: 50,
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            "@media (max-width: 360px)": {
                marginRight: 7
            }
        },
    },
    sectionDesktop: {
        display: 'none',
        right: 0,
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    logo: {
        // background: `transparent url('${LogoImage}') 0% 0% no-repeat padding-box`,
        // backgroundSize: "cover",
        objectFit: "cover",
        marginTop: 18,
        width: 97,
        height: 69,
        [theme.breakpoints.down('sm')]: {
            width: 72,
            height: 50,
            marginTop: 11,
            marginLeft: "8%",
            // background: `transparent url('${LogoImageMovil}') 0% 0% no-repeat padding-box`,

        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 27,
        },
    },
    toolbarResponsive: {
        width: "100%",
        paddingLeft: 100,
        paddingRight: 100,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 4,
            paddingRight: 4,
        },
    },
    primary: {
        color: theme.palette.primary.main,
        font: "normal normal bold 18px/22px Barlow",
        letterSpacing: 0.4,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15
        },
        [theme.breakpoints.down('xs')]:
        {
            fontSize: window.innerWidth < 360 ? 13 : 15
        }
        ,
    },
    primary2: {
        color: theme.palette.primary.main,
        font: "normal normal 600 16px/19px Barlow",
        letterSpacing: 0.4,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15
        },
    },
    simpleOption: {
        color: "#7A7A7B",
        font: "normal normal medium 16px/19px",
        letterSpacing: 0.8,
    },
    secondary: {
        color: theme.palette.secondary.main,
        font: "normal normal bold 18px/22px Barlow",
        letterSpacing: 0.4,
        [theme.breakpoints.only('sm')]:
        {
            fontSize: 15
        }
        ,
        [theme.breakpoints.only('xs')]:
        {
            fontSize: window.innerWidth < 360 ? 13 : 15
        }
        ,
    },
    secondary2: {
        color: theme.palette.secondary.main,
        font: "normal normal 600 16px/19px Barlow",
        letterSpacing: 0.4,
        [theme.breakpoints.down('sm')]:
        {
            fontSize: 15
        }
        ,
    },
    flex: {
        display: 'flex',
        margin:
            'auto'
    }
    ,
    listItem: {
        font: "normal normal bold 18px/22px",
        letterSpacing:
            0.45,
        minWidth:
            224,
        [theme.breakpoints.down('sm')]:
        {
            width: 'auto',
            minWidth:
                0,
            paddingLeft:
                6,
            paddingRight:
                6
        }
        ,
    }
    ,
    separator: {
        height: 18,
        borderLeft:
            "2px solid",
        marginRight:
            16,
        [theme.breakpoints.only('xs')]:
        {
            marginRight: 8
        }
        //marginBottom: 2
    }
    ,
    simpleListItem: {
        width: 'auto',
        [theme.breakpoints.down('md')]:
        {
            paddingRight: 4
        }
        ,
    }
    ,
    paper: {
        left: "82px !important",
        boxShadow:
            "none !important"
    }
    ,
    paperMenu: {
        borderRadius: 0
    }
    ,
    "#scrolled":
    {
        backgroundColor: theme.palette.common.white
    }
    ,
    menu: {
        backgroundColor: "white",
        height:
            34,
        alignItems:
            "center",
        paddingLeft:
            "10%",
        paddingRight:
            "10%",
        [theme.breakpoints.only('lg')]:
        {
            paddingRight: "14%",
        }
        ,
        //marginTop: 22
    },
    menuAcces2: {
        //position: props.isglobal ? "none" : "absolute",
        position: "absolute",
        right: "2%",
        [theme.breakpoints.only('xs')]: {
            top: 54,
            left: "6%",
            /*"@media (max-height: 639px)": {
                top: 44
            },*/
        },
        [theme.breakpoints.only('sm')]: {
            right: "11%",
            top: 6
        },
        [theme.breakpoints.only('md')]: {
            top: 48,
        },
    },
    active: {
        fontWeight: 600
    }
}));


function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        id: trigger ? "scrolled" : "no-scrolled"
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

function ShellAppBar(props) {
    let { handleDrawerToggle } = props;
    let classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openTips, setOpenTips] = React.useState(false);
    const anchorRef = React.useRef(null);
    const anchorRefTips = React.useRef(null);

    const prevOpen = React.useRef(open);
    const prevOpenTips = React.useRef(open);
    let loguedUser = useSelector(state => state.users.loggedUser);

    useEffect(() => {
        if (prevOpen.current === true && open === false)
            anchorRef.current.focus();

        if (prevOpenTips.current === true && openTips === false)
            anchorRefTips.current.focus();

        prevOpen.current = open;
        prevOpenTips.current = openTips;
    }, [open])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggleTips = () => {
        setOpenTips((prevOpen) => !prevOpen);
    };

    const handleCloseTips = (event) => {
        if (anchorRefTips.current && anchorRefTips.current.contains(event.target)) {
            return;
        }
        setOpenTips(false);
    };

    function handleListKeyDownTips(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenTips(false);
        }
    }

    const theme = useTheme();
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    function renderSubMenuBenefits() {
        return (<Popper open={open} className={classes.paper} anchorEl={anchorRef.current} role={undefined} transition
            disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper elevation={3} className={classes.paperMenu}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <NavLink to="/benefits/professional">
                                    <MenuItem onClick={handleClose}>
                                        <Typography className={classes.separator} color="primary"></Typography>
                                        <Typography className={classes.primary2}>{<Translate
                                            id={"Menus.ProfessionalBenefits"} />} </Typography>
                                    </MenuItem>
                                </NavLink>
                                <NavLink to="/benefits/employers">
                                    <MenuItem onClick={handleClose}>
                                        <Typography className={classes.separator} color="secondary"></Typography>
                                        <Typography className={classes.secondary2}>{<Translate
                                            id={"Menus.EmployerBenefits"} />} </Typography>
                                    </MenuItem>
                                </NavLink>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>)
    }

    function markActive(option) {
        return window.location.pathname.indexOf(option) > -1 ? classes.active : '';
    }


    function renderSubMenuTips() {
        return (<Popper open={openTips} className={classes.paper} anchorEl={anchorRefTips.current} role={undefined}
            transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper elevation={3} className={classes.paperMenu}>
                        <ClickAwayListener onClickAway={handleCloseTips}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDownTips}>
                                <NavLink to="/tips/professional">
                                    <MenuItem onClick={handleCloseTips}>
                                        <Typography className={classes.separator} color="primary"></Typography>
                                        <Typography className={classes.primary2}>{<Translate
                                            id={"Menus.ProfessionalTips"} />} </Typography>
                                    </MenuItem>
                                </NavLink>
                                <NavLink to="/tips/employers">
                                    <MenuItem onClick={handleCloseTips}>
                                        <Typography className={classes.separator} color="secondary"></Typography>
                                        <Typography className={classes.secondary2}>{<Translate
                                            id={"Menus.EmployerTips"} />} </Typography>
                                    </MenuItem>
                                </NavLink>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>)
    }

    function renderAccessMenu() {
        if (loguedUser) {
            return (<LoguedMenu isglobal={true} />)
        }

        return (<List className={`${classes.flex} ${classes.menuAcces2}`}>
           {/*  <ListItem className={classes.listItem} style={window.innerWidth <= 640 ? { padding: 0, paddingRight: 8 } : {}}>
                <PhoneIcon />
                <Typography className={classes.primary} color="primary"> +34 626 969 750</Typography>
            </ListItem> */}
            <NavLink to="/professional/login">
                <ListItem button className={classes.listItem} style={window.innerWidth <= 640 ? { padding: 0, paddingRight: 8 } : {}}>
                    <Typography className={classes.separator} color="primary"></Typography>
                    <Typography className={classes.primary}>{<Translate id={"Menus.ProfessionalAccess"} />} </Typography>
                </ListItem>
            </NavLink>

            <NavLink to="/employer/login">
                <ListItem button className={classes.listItem}
                    style={window.innerWidth <= 640 ? { padding: 0 } : {}}>
                    <Typography className={classes.separator} color="secondary"></Typography>
                    <Typography className={classes.secondary}>{<Translate id={"Menus.EmployerAccess"} />} </Typography>
                </ListItem>
            </NavLink>
        </List>)
    }

    return (<ElevationScroll {...props}>
        <AppBar position="fixed" color="transparent" elevation={0} className={classes.appBar}>
            <Toolbar className="p-0">

                <Grid container direction="row"
                    justify="center">
                    <Grid item xs={12} sm={10} md={10} lg={10} xl={11} className="d-flex align-items-center">
                        <NavLink to="/">
                            <img alt={"Hihired!"} src={screenSmDown ? LogoImageMovil : LogoImage} className={classes.logo} />
                        </NavLink>
                        <Hidden smDown>
                            <List className={`${classes.menu} d-flex`}>
                                <NavLink to="/about-us">
                                    <ListItem button className={classes.simpleListItem}>
                                        <Typography className={`${classes.simpleOption} ${markActive("about-us")}`}>{
                                            <Translate id={"Menus.AboutUs"} />} </Typography>
                                    </ListItem>
                                </NavLink>

                                <ListItem ref={anchorRef} button className={classes.simpleListItem}
                                    onClick={handleToggle}>
                                    <Typography className={`${classes.simpleOption} ${markActive("benefits")}`}>{
                                        <Translate id={"Menus.Beneficial"} />} </Typography>
                                </ListItem>
                                <ListItem ref={anchorRefTips} button className={classes.simpleListItem}
                                    onClick={handleToggleTips}>
                                    <Typography className={`${classes.simpleOption} ${markActive("tips")}`}>{<Translate
                                        id={"Menus.Tips"} />} </Typography>
                                </ListItem>

                                <NavLink to="/contact">
                                    <ListItem button className={classes.simpleListItem}>
                                        <Typography className={`${classes.simpleOption} ${markActive("contact")}`}>{
                                            <Translate id={"Menus.Contact"} />} </Typography>
                                    </ListItem>
                                </NavLink>
                            </List>
                        </Hidden>
                    </Grid>
                </Grid>
                <IconButton color="inherit" className={classes.menuButton}
                    aria-label="open drawer" edge="start" onClick={handleDrawerToggle}>
                    <img src={hamburger} />
                </IconButton>
                <Hidden smDown>
                    {renderAccessMenu()}
                </Hidden>
                {renderSubMenuBenefits()}
                {renderSubMenuTips()}
            </Toolbar>
            <Hidden mdUp>
                {renderAccessMenu()}
            </Hidden>
        </AppBar>

    </ElevationScroll>);
}

export default withRouter(ShellAppBar);