import React, {useEffect, useState, createRef, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Typography, Hidden, useMediaQuery} from "@material-ui/core";
import {connect} from 'react-redux'
import {NavLink, withRouter} from "react-router-dom";
import {Translate} from "react-localize-redux";
import Questions from "../interview/questions";
import TimerIndicator from "../interview/timerIndicator";
import HiredRecorder from "../interview/hiredRecorder";
import ConfirmDialog from "../confirmation/confirm";
import {useDispatch, useSelector} from "react-redux";
import * as types from '../../actions/users/index';
import Timer from "../interview/customTimer";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import * as interviewActions from "../../actions/interview/index";
import * as localforage from "../../indexdb/localforageManager";
import getBlobDuration from 'get-blob-duration';
import api from "../../api/index";
import QuestionsDevice from "./ios/questionsDevice";
import moment from "moment";
import {isIOS} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    titleInterView: {
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            marginLeft: 59
        }
    },
    paddingRightResponse: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "85px !important"
        },
    },
    video: {
        [theme.breakpoints.up('md')]: {
            //margin: "auto"
        },
        marginRight: 0
    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 17,
        right: 21,
        border: "2px solid #585554",
        borderRadius: 50,
        cursor: "pointer"
    },
    icon: {
        height: 52,
        width: 53
    },
    paddingLeftResponse: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "3rem !important"
        },
    },
    topResponse: {
        [theme.breakpoints.up('md')]: {
            marginTop: "0"
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: "4rem"
        },
    },
    px3: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
            paddingRight: 5
        },
    },
    px5: {
        paddingLeft: "3rem",
        paddingRight: "3rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 15,
            paddingRight: 15
        },
    },
    recorderMargins: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    },
    marginTop: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: "0.5rem !important",
            paddingBottom: "0 !important",
            marginTop: "0.5rem !important",
            marginBottom: "0 !important"
        },
    },
    centerXs: {
        textAlign: "center",
        [theme.breakpoints.up('xl')]: {
            paddingRight: "3rem !important",
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    responsiveHeight: {
        [theme.breakpoints.up('lg')]: {
            //minHeight: window.innerHeight < 900 ? window.innerHeight  * 0.6 : "auto"
        },

    },
    py0: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    mergedTitle: {
        display: "flex",
        height: "max-content !important",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 17,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 3
        }
    },
    title: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.only('xs')]: {
            fontSize: 20
        }
    },
    imgLanguage: {
        width: 43,
        height: 43,
        marginLeft: 36,
        marginRight: 10,
        [theme.breakpoints.only('xs')]: {
            width: 29,
            height: 29,
            marginLeft: 11,
            marginRight: 7,
        }
    },
    attempts: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
            marginTop: isIOS ? 10 : 6,
            marginBottom: isIOS ? 8 : 0
        }
    },
    pxoXs:{
        [theme.breakpoints.only('xs')]: {
            paddingTop: "0px !important",
            paddingBottom: "0px !important"
        }
    },
    info:{
        fontSize: 18,
        fontWeight: 600
    }
}))

let hiredRecorder = null;
let metaBackups = [];

function VideoSpeaking(props) {
    let classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const maxSmHeightVideo = 484;

    const [videoRef, setVideoRef] = useState(null);

    const id = props.match.params["test"] ? parseInt(props.match.params["test"]) : null;
    let questionsDeviceRef = useRef(null);

    const {innerWidth: width, innerHeight: height} = window;
    let heightVideo = videoRef && videoRef.cameraVideo ? videoRef.cameraVideo.clientHeight : 0;
    let videoWidth = videoRef && videoRef.cameraVideo ? videoRef.cameraVideo.clientWidth : 0;
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const interviewSupport = useSelector(state => state.interviews.support);
    //const attempts = useSelector(state => state.users.speakingTest.attempts);

    let [closeConfirmation, setCloseConfirmation] = useState(false);
    const [unCancelLast, setUnCancel] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [metadata, setMetadata] = useState([]);
    let [maxValueRecord, setMaxValueRecord] = useState(60);
    const [recordCompleted, setRecordCompleted] = useState(false);

    let [languageTest, setLanguageTest] = useState(null);
    let [professionalLanguageTest, setProfessionalLanguageTest] = useState(null);

    const [steps, setSteps] = useState(null);
    let [activeStep, setActiveStep] = useState(0);
    let [unsuportRecorder, setUnsupportRecorder] = useState(false);
    let [endConfirmation, setEndConfirmation] = useState(false);
    let [cancelConfirmation, setCancelConfirmation] = useState(false);
    let [recording, setRecording] = useState(false);

    let [attempts, setAttempts] = useState(0);


    useEffect(() => {
        loadTest();
        defineActionHeader();
        loadLanguageTest();
    }, [languageTest, professionalLanguageTest, attempts, activeStep, recording])

    function loadLanguageTest() {
        if (languageTest === null && id)
            api.languageTest.getLanguageTest(id).then(response => {
                if (response.status === 200) {
                    setLanguageTest(response.data);
                    if (response.data.testQuestions) {
                        //setMetadata(response.data.testQuestions);
                        let result = response.data.testQuestions.map(x => x.questionText);
                        setSteps(result)
                    }
                }
            })
    }

    function loadTest() {
        if (professionalLanguageTest === null && id && attempts === 0)
            api.professional.getTest(id).then(response => {
                setProfessionalLanguageTest(response.data ? response.data : {});

                if (!response.data) {
                    setAttempts(2);
                    //dispatch(types.setSpeakingAttempts(2))
                } else {
                    let {attempts} = response.data;
                    setAttempts(2 - attempts);
                    //dispatch(types.setSpeakingAttempts(2 - attempts))
                }
            })
    }

    function toggleTimer() {
        setIsActive(!isActive);
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    function handleClosed(state) {
        setCloseConfirmation(true);
        if (state > 0) setUnCancel(true);
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: () => handleClosed(seconds)
        }))
    }

    function notifyTime(s) {
        setSeconds(s);
        defineActionHeader();
        if (seconds === maxValueRecord) {
            handleStopRecorder();
            setRecordCompleted(true);
        }
    }

    async function handleStartRecorder() {
        setRecording(true);
        await localforage.clear();
        if (interviewSupport.isInlineRecordingSupported) {
            toggleTimer();
            if (hiredRecorder) {
                if (hiredRecorder.mediaRecorder) hiredRecorder.handleStopReplaying()
                else hiredRecorder.handleStartRecording()
            }
        } else if (interviewSupport.isVideoInputSupported) {
            if (hiredRecorder.handleOpenVideoInput) hiredRecorder.handleOpenVideoInput();
        }
    }

    function handlePauseRecorder(indexQuestion) {
        toggleTimer();
        if (hiredRecorder) {
            hiredRecorder.handlePauseRecording();
        }
        pushMetadata(indexQuestion);
    }

    function handleResumeRecorder() {
        toggleTimer();
        if (hiredRecorder) {
            hiredRecorder.handleResumeRecording()
        }
    }

    async function handleStopRecorder() {
        setIsActive(false);
        if (hiredRecorder) {
            hiredRecorder.turnOffCamera();
            hiredRecorder.handleStopRecording();
        }
        saveMetadata();
        if (validateMetadatas()) {
            api.professional.saveVideoSpeakingAttempt(id).then(response => {
                props.history.push(`/speaking/end-test/${id}`);
            }).catch(error => {
                alert("Error")
            })
        } else {
            props.history.push("/speaking/invalid-result");
        }
    }

    function handleUnCancelRecorder() {
        setIsActive(active => false);
        if (hiredRecorder && hiredRecorder.mediaRecorder) {
            if (hiredRecorder.mediaRecorder.state === "recording") hiredRecorder.handlePauseRecording();
        }
        setUnCancel(false);
    }

    function formatDuration(secondsDuration) {
        return moment("2015-01-01").startOf('day')
            .seconds(secondsDuration)
            .format('mm:ss');
    }

    function pushMetadata(indexQuestion) {
        let lastMeta = metadata.length > 0 ? metadata[metadata.length - 1] : null;
        let start = lastMeta ? lastMeta.end : 0;
        let end = seconds;
        let duration = end - start;
        let metas = Object.assign([], metadata);

        let meta = {
            start: start,
            end: end,
            duration: duration,
            durationFormat: formatDuration(duration),
            question: steps[indexQuestion],
            index: indexQuestion
        };
        metas.push(meta);
        metaBackups = metas;
        setMetadata(metas);
        return metas;
    }

    const saveInputRecording = async (videoBlob, thumbnailBlob) => {
        if (videoBlob) {
            try {
                //1- Guardar el metadato
                let metas = Object.assign([], metadata);
                let lastmeta = metas.length > 0 ? metas[metas.length - 1] : null;
                if (lastmeta === null) {
                    await localforage.removeItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);
                }
                let blobMp4 = new Blob([videoBlob], {type: "video/mp4"});
                let duration = await getBlobDuration(blobMp4);

                duration = parseInt(duration);
                let start = lastmeta ? lastmeta.end : 0;
                metas.push({
                    start: start,
                    end: start + duration,
                    duration: duration,
                    durationFormat: formatDuration(duration),
                    question: steps[activeStep],
                    index: activeStep
                });
                setMetadata(metas);

                //2- Guardamos la parte de la grabacion
                let result = await localforage.getItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS);
                result = result ? result : [];
                if(result.length === 0) {
                    await localforage.saveItem(localforage.PROFESSIONAL_INTERVIEW_POSTER, thumbnailBlob);
                }
                result.push(blobMp4);

                await localforage.saveItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS, result);
                if (activeStep === steps.length - 1) {
                    if (validateInputRecording(metas)) {
                        dispatch(types.setSpeakingMetadata(metas));

                        api.professional.saveVideoSpeakingAttempt(id).then(response => {
                            window.location = `/speaking/end-test/${id}`;
                        }).catch(error => {
                            alert("Error")
                        })
                    }
                }
                let currentTime = parseInt(start) + parseInt(duration);
                setSeconds(currentTime);
            } catch (error) {
                alert(`Error -> ${JSON.stringify(error)}`);
            }
        }
    }

    function validateMetadatas() {
        if (!steps) return false;
        if (metaBackups.length !== steps.length) return false;
        if (metaBackups.some(x => x.duration === 0)) return false;
        return true;
    }

    function saveMetadata() {
        let metas = pushMetadata(metadata.length);
        dispatch(types.setSpeakingMetadata(metas));
    }

    function resolveImage() {
        try {
            if (languageTest && languageTest.isoCode)
                return require(`../../images/offer/languages/${languageTest.isoCode}.png`)
            return null;
        } catch (e) {
            return null;
        }
    }

    function recordInputDevice(index) {
        setRecording(true);
        setActiveStep(index);
        if (interviewSupport.isVideoInputSupported) {
            if (hiredRecorder.handleOpenVideoInput) hiredRecorder.handleOpenVideoInput();
        }
    }

    function validateInputRecording(metas = []) {
        let data = metas && metas.length > 0 ? metas : metadata;
        if (data.length > 0) {
            let lastMeta = data[data.length - 1];
            if (lastMeta.end > maxValueRecord) return false;
        }
        if (data.some(x => x.duration === 0)) return false;
        return true;
    }

    let isValidRecording = validateInputRecording();

    function renderDeviceInfo() {
        return isIOS  && recording === false
            ? <Translate>
                {({translate}) => {
                    return <Grid item xs={12} className="text-left px-4">
                        <Typography color="error" className={classes.info} component="span">{translate("Support.Important")}: </Typography>
                        <Typography color="textSecondary" component="span">{translate("Support.Info")}</Typography>
                        <NavLink to={"/interview/support"}>
                            <Typography color="textSecondary" component="span"
                                        style={{fontWeight: 600, textDecoration: "underline"}}>
                                {translate("Support.Link")}.
                            </Typography>
                        </NavLink>
                    </Grid>
                }}
            </Translate> : <div></div>
    }

    return (<Translate>
        {({translate}) => {
            return languageTest
                ? <Grid container spacing={2} className={classes.px3}>
                    <Grid item xs={12} sm={6} lg={7} md={7} id="recorder-container"
                          className={`${classes.recorderMargins} speaking`}>
                        <Grid item xs={12} className={classes.mergedTitle}>
                            <Typography className={classes.title} color="textSecondary">
                                <Translate id="Tests.Title"/>
                            </Typography>
                            <img className={classes.imgLanguage} src={resolveImage()}/>
                            <Typography className={classes.title} color="textSecondary">{languageTest.name}</Typography>
                        </Grid>
                        <HiredRecorder
                            onReady={(recorder) => {
                                hiredRecorder = recorder;
                            }}
                            onLoadedVideo={(videoComponent) => {
                                setVideoRef(videoComponent)
                            }}
                            onRecordingComplete={async (videoBlob, thumbnailBlob) => {
                                if (deviceSupport()) {
                                    await saveInputRecording(videoBlob, thumbnailBlob);
                                } else {
                                    await localforage.saveItem(localforage.PROFESSIONAL_VIDEO_SPEAKING_TEST,
                                        new Blob([videoBlob], {type: "video/mp4"}));
                                }
                            }}
                            notSupportRecorder={() => {
                                setUnsupportRecorder(true);
                            }}/>
                        <Timer isActive={isActive} notifyTime={notifyTime}/>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={5} md={5}
                          className={`text-center pl-2 ${classes.paddingLeftResponse} ${classes.py} ${classes.pxoXs}`}>
                        <Grid container spacing={2} className={classes.topResponse}>
                            <Translate>
                                {({translate}) => {
                                    return <Grid item xs={12} className={classes.pxoXs}>
                                        <Typography color="primary" className={classes.attempts}>
                                            {`${attempts} ${attempts > 1 || attempts === 0 ? translate("Tests.PluralAttempts") : translate("Tests.SingularAttempts")}`}
                                        </Typography>
                                    </Grid>
                                }}
                            </Translate>

                            <Grid item xs={12} className={`text-center ${classes.px5} ${classes.responsiveHeight} ${classes.pxoXs}`}
                                  style={{minHeight: height > 900 ? heightVideo * 0.65 : heightVideo * 0.80}}>
                                {deviceSupport()
                                    ? <QuestionsDevice steps={steps ? steps : []}
                                                       ref={questionsDeviceRef}
                                                       isValid={isValidRecording}
                                                       parentActiveStep={activeStep}
                                                       metadatas={metadata}
                                                       recordInputDevice={(index) => recordInputDevice(index)}
                                                       seconds={seconds}
                                                       heightVideo={heightVideo}
                                                       unsuportRecorder={unsuportRecorder}/>
                                    : <Questions
                                        extraHeight={10}
                                        endButtonLabel={translate("Tests.EndRecord")}
                                        handleStartRecorder={handleStartRecorder}
                                        handlePauseRecorder={handlePauseRecorder}
                                        handleResumeRecorder={handleResumeRecorder}
                                        handleStopRecorder={() => {
                                            setEndConfirmation(true);
                                            setUnCancel(true);
                                        }}
                                        cancelRecordInterview={() => {
                                            setCancelConfirmation(true);
                                            setUnCancel(true);
                                        }}
                                        handleUnCancelRecorder={() => handleUnCancelRecorder()}
                                        recordCompleted={recordCompleted}
                                        steps={steps ? steps : []}
                                        seconds={seconds}
                                        unCancelLastStep={unCancelLast}
                                        paused={!isActive} play={isActive} value={seconds} maxValue={maxValueRecord}
                                        heightVideo={heightVideo}
                                        unsuportRecorder={unsuportRecorder || attempts === 0}
                                    />}

                                {deviceSupport() &&
                                <React.Fragment>
                                    <Grid item xs={12} lg={12}
                                          className={`text-center px-5 ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "pb-4")}`}
                                          style={screenXlOnly || screenXsOnly ? {paddingTop: 82} : {}}>
                                        <TimerIndicator key="recorder" paused={!isActive} play={isActive} value={seconds}
                                                        maxValue={maxValueRecord}/>
                                    </Grid>

                                    {renderDeviceInfo()}
                                </React.Fragment>}
                            </Grid>

                            {((screenSmUp && height > maxSmHeightVideo)) &&
                            <Grid item xs={12} lg={12}
                                  className={`text-center px-5 ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "pb-4")}`}
                                  style={screenXlOnly ? {paddingTop: 82} : {}}>
                                {heightVideo > 0
                                    ? <TimerIndicator key="recorder" paused={!isActive} play={isActive} value={seconds}
                                                      maxValue={maxValueRecord}/>
                                    : <Grid></Grid>}
                            </Grid>}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <ConfirmDialog
                            id="end-record" open={endConfirmation}
                            question={<Translate id={"Confirmation.EndRecordInterview"}/>}
                            handleCancel={() => {
                                setEndConfirmation(false);
                                setUnCancel(true)
                            }}
                            handleAccept={async () => {
                                await handleStopRecorder();
                                setEndConfirmation(false);
                                setRecordCompleted(true)
                            }}/>
                        <ConfirmDialog
                            id="cancel-record" open={cancelConfirmation}
                            title={<Translate id={"Tests.CancelTestTitle"}/>}
                            question={<Translate id={"Tests.CancelTestQuestion"}/>}
                            handleCancel={() => setCancelConfirmation(false)}
                            handleAccept={() => {
                                props.history.push(window.location.pathname)
                            }}/>
                        <ConfirmDialog
                            id="close-interview" open={closeConfirmation}
                            question={<Translate id={"Confirmation.CloseInterview"}/>}
                            title={seconds > 0 ?
                                <Translate id={"Confirmation.CloseInterviewTitle"}/> : ""}
                            handleCancel={() => setCloseConfirmation(false)}
                            handleAccept={() => {
                                setCloseConfirmation(false);
                                props.history.push(`/professional/edit-profile`)
                            }}/>
                    </Grid>
                </Grid>
                : <CircularProgress color="primary"/>
        }}
    </Translate>)
}

export default withRouter(VideoSpeaking);