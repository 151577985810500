import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Translate} from "react-localize-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {Link, Tooltip, useMediaQuery} from "@material-ui/core";
import api from "../../../api/index";


const useStyles = makeStyles((theme) => {
    /*theme.overrides.MuiInput.root = {};
    theme.overrides.MuiInputLabel = {};
    theme.overrides.MuiInputBase = {};*/
    return {
        specialty: {
            width: 141,
            margin: 8,
            [theme.breakpoints.only('xs')]: {
                marginTop: 20,
            },
            '& .MuiFormControl-marginNormal': {
                marginTop: 0,
                marginBottom: 0,
            },
            [theme.breakpoints.only('xl')]: {
                width: "100%",
                marginRight: 36,
            },
            [theme.breakpoints.between('md', 'lg')]: {
                width: "100%",
                marginRight: 36,
            },
            "& .MuiInputLabel-formControl": {
                top: 14
            }

        },
        popperSpecialty: {
            [theme.breakpoints.only('xs')]: {
                width: "319px !important"
            },

        },
        option: {
            '&.MuiAutocomplete-option[aria-selected="true"]': {
                background: "#B8DEEA 0% 0% no-repeat padding-box"
            },
            '&.MuiAutocomplete-option[data-focus="true"]': {
                background: "#B8DEEA 0% 0% no-repeat padding-box"
            }
        },
        province: {
            width: 120,
            margin: 8,
            [theme.breakpoints.only('xs')]: {
                marginTop: 20,
                marginRight: 36,
            },
            [theme.breakpoints.only('sm')]: {
                marginRight: 12,
            },
            '& .MuiFormControl-marginNormal': {
                marginTop: 0,
                marginBottom: 0,
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: 36,
                width: "100%",
            },
            [theme.breakpoints.between('md', 'lg')]: {
                marginRight: 36,
                width: "100%",
            },
            "& .MuiInputLabel-formControl": {
                top: 14
            }
        },
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            [theme.breakpoints.only('sm')]: {
                marginTop: 12,
                "@media (min-height: 768px)": {
                    flexWrap: "nowrap",
                },
            },
            [theme.breakpoints.only('md')]: {
                justifyContent: "center",
                marginTop: 14
            },
            [theme.breakpoints.only('lg')]: {
                justifyContent: "space-between",
            },
            [theme.breakpoints.only('xl')]: {
                //marginTop: 14,
                justifyContent: "space-between",
            },
        },
        rootLg: {
            marginTop: "4%",
        },
        rootLgTop: {
            //marginTop: "4%",
        },
        rootXl: {
            marginTop: -10,
        },
        rootXlTop: {
            marginTop: "20%",
        },

        textField: {
            '& .MuiFormLabel-root': {
                color: "#FFFFFF",
                fontSize: 14,
                letterSpacing: 0.55,
                [theme.breakpoints.up('lg')]: {
                    fontSize: 20,
                    letterSpacing: 0.68,
                },

            }
        },
        icon: {
            color: "white",
            /*width: 38,
            height: 31,*/
        },
        inputRoot: {
            color: "#45CCE5",
            fontSize: 14,
            letterSpacing: 0.55,
            backgroundColor: "transparent",
            marginBottom: 0,
            [theme.breakpoints.up('lg')]: {
                fontSize: 20,
                letterSpacing: 0.68,
            },
        },
        customToolTip: {
            backgroundColor: "rgb(255, 255, 225);",
            border: "1px solid rgb(195, 195, 195)",
            color: "rgb(20, 20, 20)",
            textAlign: "center",
            paddingBottom: 3,
            paddingTop: 0,
            fontWeight: 400
        },
        inputBaseAlert: {
            backgroundColor: "transparent",
            color: "#05BEF8",
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            }
        },
        autoLabel: {
            top: 10
        }
    }
});

const useStylesSearch = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 215,
        marginRight: 36,
        marginBottom: "20px !important",
        height: 40,
        [theme.breakpoints.only('lg')]: {
            width: 183,
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        marginLeft: 8,
        backgroundColor: "#FFFFFF",
        color: theme.palette.primary.main,
        [theme.breakpoints.only('xs')]: {
            marginLeft: "15%",
            marginBottom: 8,
        },
        [theme.breakpoints.only('sm')]: {
            marginLeft: "18%",
            marginBottom: 8,
        },
        '&.MuiIconButton-root:hover': {
            backgroundColor: "#FFFFFF"
        }
    },
    divider: {
        height: 28,
        margin: 4,
    },

}));

export default function Name() {
    const classes = useStyles();
    let classesSearch = useStylesSearch();
    const [specialty, setSpecialty] = React.useState(null);
    const [province, setProvince] = React.useState(null);
    const theme = useTheme();
    let history = useHistory();
    const screenBetweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const screenMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const screenLgOnly = useMediaQuery(theme.breakpoints.only('lg'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));

    let [provinces, setProvinces] = useState(null);
    let [specialties, setSpecialties] = useState(null);
    let [country, setCountry] = useState(null);

    useEffect(() => {
        loadFilters();
    }, [provinces, province, specialty])


    function loadFilters(reload = false) {
        if ((provinces === null && specialties === null) || reload) {
            api.filters.getFilters(province ? province.id : 0, specialty ? specialty.id : 0)
                .then(response => {
                    if (response.status === 200) {
                        let {provinces, specialties} = response.data;
                        setProvinces(provinces);
                        setSpecialties(specialties);
                    }
                })
        }
    }

    function immediatelyLoadFilters(provinceId, specialtyId) {
        api.filters.getFilters(provinceId, specialtyId)
            .then(response => {
                if (response.status === 200) {
                    let {provinces, specialties} = response.data;
                    setProvinces(provinces);
                    setSpecialties(specialties);
                }
            })
    }

    const defaultPropsSpecialty = {
        options: specialties ? specialties : [],
        getOptionLabel: (option) => option.name,
    };
    const defaultProvince = {
        options: provinces ? provinces : [],
        getOptionLabel: (option) => option.name,
    };


    function renderSearch() {
        return (
            <Tooltip classes={{tooltip: classes.customToolTip}}
                     title={<Translate id={"Titles.Search"}/>}
                     placement="bottom">
                <IconButton className={classesSearch.iconButton} aria-label="search"
                            onClick={() => {
                                if (province || specialty) {
                                    history.push(`/jobs/${specialty ? specialty.id : 0}/${province ? province.id : 0}`);
                                }
                            }}>
                    <SearchIcon/>
                </IconButton>
            </Tooltip>
        );
    }


    return (<Grid container spacing={0}>
            <Grid item xs={12} className={`${screenSmOnly ? "d-flex justify-content-center" : ""} my-0`}>
                <div
                    className={`${classes.root} ${screenLgOnly ? specialty !== null || province !== null ? classes.rootLgTop : classes.rootLg : ""} ${screenXlOnly ? specialty !== null || province !== null ? classes.rooXlTop : classes.rootXl : ""}`}
                    id="AutocompleteSearch">
                    <Autocomplete
                        {...defaultPropsSpecialty}
                        id="auto-highlight-speciality"
                        value={specialty}
                        onChange={(event, newValue) => {
                            setSpecialty(newValue);
                            immediatelyLoadFilters(province ? province.id : 0, newValue ? newValue.id : 0);
                        }}
                        autoHighlight
                        popupIcon={<ExpandMoreIcon className={classes.icon} fontSize="small"/>}
                        closeIcon={<CloseIcon className={classes.icon} fontSize="small"/>}
                        className={`${classes.specialty} my-0`}
                        classes={{
                            popper: classes.popperSpecialty,
                            inputRoot: classes.inputRoot,
                            input: classes.inputBaseAlert,
                        }}
                        renderInput={(params) =>
                            <TextField className={classes.textField}
                                       InputLabelProps={{shrink: false}}
                                       inputProps={{classes, disableUnderline: true}}  {...params}
                                       label={<Translate id={"Titles.Specialty"}/>}
                                       margin="none"/>}
                    />
                    <Autocomplete
                        {...defaultProvince}
                        id="auto-highlight-province"
                        value={province}
                        onChange={(event, newValue) => {
                            setProvince(newValue);
                            immediatelyLoadFilters(newValue ? newValue.id : 0, specialty ? specialty.id : 0);
                        }}
                        autoHighlight
                        popupIcon={<ExpandMoreIcon className={classes.icon} fontSize="small"/>}
                        closeIcon={<CloseIcon className={classes.icon} fontSize="small"/>}
                        className={`${classes.province} my-0`}
                        classes={{
                            popper: classes.popperSpecialty,
                            inputRoot: classes.inputRoot,
                            input: classes.inputBaseAlert,
                        }}
                        renderInput={(params) => <TextField className={classes.textField}
                                                            InputLabelProps={{shrink: false}}
                                                            InputProps={{classes, disableUnderline: true}} {...params}
                                                            label={<Translate id={"Titles.Province"}/>}
                                                            margin="none"/>}
                    />
                </div>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-start">
                {specialty !== null || province !== null ? renderSearch() : ""}
            </Grid>
        </Grid>

    );
}