import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import {Translate} from "react-localize-redux";
import confirmationDialogImage from "../../images/confirmation-dialog.png";
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery, useTheme} from '@material-ui/core';

export default function ConfirmDialog(props) {

    const useStyles = makeStyles((theme) => ({
        image: {
            [theme.breakpoints.up('md')]: {
                display: "none",
            },
            [theme.breakpoints.up('md')]: {
                width: 142,
                height: 118,
                position: "absolute",
                bottom: 0,
                left: 20
            }
        },
        dialogTitle: {
            paddingTop: 0,
            lineHeight: 1,
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                textAlign: "center",
                letterSpacing: "0.17px",
                padding: "30px 20px"
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: "center",
                letterSpacing: "0.17px",
                padding: 12
            },
        },
        dialogContent: {
            padding: 0,
            [theme.breakpoints.up('md')]: {
                padding: "8px 24px"
            },
        },
        button: {
            ...theme.overrides.DialogButton,
            [theme.breakpoints.down('sm')]: {
                marginLeft: "10px !important",
                marginTop: 8,
                marginBottom: 8
            },
        },
        dialog: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "21px 26px 85px #0000003B",
            borderRadius: 38,
            opacity: 1
        },
        title: {
            lineHeight: 1.2
        }
    }));
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let classes = useStyles();
    let {acceptLabel, cancelLabel} = props;

    const handleAccept = async () => {
        setIsProcessing(true);
        if (props.handleAccept) await props.handleAccept();
        setIsProcessing(false);
    }

    const [isProcessing, setIsProcessing] = useState(false);
    return (
        <div>
            <Dialog maxWidth={fullScreen ? "xs" : "md"} fullWidth={true} classes={{paper: classes.dialog}}
                    open={props.open}>
                {props.title && <DialogTitle classes={{root: classes.dialogTitle}}>
                    <Typography variant="subtitle1" className={classes.title}>{props.title}</Typography>
                </DialogTitle>}
                <DialogContent classes={{root: classes.dialogContent}}>
                    <Typography variant="subtitle1" className={classes.dialogTitle}>{props.question}</Typography>
                    {props.question2 ? <Typography variant="subtitle1" className={`${classes.dialogTitle} pt-2`}>{props.question2}</Typography> : ""}
                    <Hidden smDown>
                        <img alt={""} src={confirmationDialogImage} className={classes.image}/>
                    </Hidden>
                </DialogContent>
                <DialogActions style={{marginBottom: 10}}>
                    <Button className={classes.button}
                            onClick={handleAccept} color="primary" variant="contained">
                        {acceptLabel ? acceptLabel : <Translate id={"Buttons.Yes"}/>}
                        {isProcessing &&
                        <CircularProgress size={24} style={{marginLeft: 10, marginTop: -6}} color="secondary"/>}
                    </Button>
                    <Button className={classes.button} style={{marginLeft: 35}}
                            onClick={() => {
                                if (props.handleCancel) props.handleCancel();
                            }} color="primary" variant="outlined">
                        {cancelLabel ? cancelLabel : <Translate id={"Buttons.No"}/>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}