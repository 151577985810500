import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Hidden, Paper, IconButton, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLogin } from "../../../actions/users/index";
import { NavLink } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as types from "../../../actions/errors";

const { innerWidth: width, innerHeight: height } = window;
const useStyles = makeStyles((theme) => ({
    titles: {
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    titlesFunctions: {
        display: "flex",
        [theme.breakpoints.down('xs')]: {
            display: "grid",
        }
    },
    responsiveWidth: {
        width: "100%",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    form: {
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },
    submit: {
        minWidth: 227,
        borderRadius: 0,
        marginTop: 20,
        height: 50
    },
    buttomtext: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        fontSize: 18,
        letterSpacing: 0.7

    },
    recoverText: {
        fontSize: 18,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    recoverTextBold: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15
        },
    },
    mainTitle: {
        fontSize: 35,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30
        },
        lineHeight: 1
    },
    sencondaryTitle: {
        fontSize: 25,
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            marginTop: 8
        }
    },
    centerXs: {
        [theme.breakpoints.down('xs')]: {
            textAlign: "center"
        }
    },
    image: {
        maxWidth: width,
        height: "auto",
        marginLeft: -18
    },
    icon: {
        padding: 15,
        background: "#EDEDED",
        width: 54,
        height: 54,
        position: "absolute",
        right: 0,
        top: 20,
        cursor: "pointer",
    },
    errorText: {
        fontSize: 19,
        marginTop: 20
    },
    buttons: {
        marginTop: 20
    },
    linkedinImage: {
        width: 227,
        height: 50,
        position: "relative"
    },
    linkedinText: {
        position: "absolute",
        top: "10%",
        left: 60,
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1.2,
        textAlign:"left",
        letterSpacing: 0.7
    },
    or: {
        fontSize: 20,
        fontWeight: "bold",
        paddingTop: 7,
        paddingBottom: 7
    },
    buttonsContainer: {
        maxWidth: "max-content",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    tooltip: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 1
    },
    iconInfo: { width: "auto", height: "auto", position: "absolute", top: -8, right: 0, }
}));

function LoginEmployer(props) {
    let classes = useStyles();
    let [formData, setFormData] = useState({ email: "", password: "", userType: 0 });
    let [typePass, setTypePass] = useState(true);
    let [errorLogin, setErrorLogin] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {

    }, [typePass, errorLogin])

    async function handleSubmit() {
        let response = await fetchUserLogin(dispatch)(formData, null);
        if (response.status === 200) {
            let compltedProfile = await checCompletedProfile();
            if (compltedProfile) props.history.push("/employer/workspace");
            else props.history.push("/employer/edit-profile");
        } else {
            showMessage(response.data ? response.data.error : response.message, "error");
        }
    }

    const handleChange = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    async function checCompletedProfile() {
        let response = await api.employers.checkCompletedProfile();
        return response.data;
    }

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    return (<Grid container spacing={0}>
        <ValidatorForm style={{ width: "100%" }} onSubmit={handleSubmit} onError={errors => console.log(errors)} instantValidate={false}>
            <Grid item xs={12} className={classes.titles}>
                <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate id={"Employer.LoginTitle"} />} </Typography>
                <Typography variant="h2" color="secondary" className={classes.sencondaryTitle}>{<Translate id={"Employer.LoginSubtitle"} />} </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
                <Translate>
                    {({ translate }) => {
                        return <div className={classes.paddingResponse}>
                            <TextValidator
                                autoFocus={true}
                                className={classes.responsiveWidth}
                                label={translate("Employer.Email")}
                                onChange={handleChange}
                                name="email"
                                value={formData.email}
                                validators={['required', 'isEmail']}
                                placeholder={translate("Employer.PlaceholderEmail")}
                                errorMessages={[translate("Fields.Required"), translate("Fields.EmailValidation")]}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />

                            <Grid item xs={12} style={{ position: "relative" }}>
                                <TextValidator
                                    className={classes.responsiveWidth}
                                    style={{ marginBottom: 15 }}
                                    type={typePass ? "password" : "text"}
                                    label={translate("Employer.Password")}
                                    debounceTime={1500}
                                    name="password"
                                    value={formData.password}
                                    validators={['required']}
                                    placeholder={translate("Employer.Password")}
                                    errorMessages={[translate("Fields.Required")]}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setTypePass(!typePass)}>
                                                {typePass ? <RemoveRedEyeOutlined /> : <VisibilityOffOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }} />
                            </Grid>
                        </div>
                    }}
                </Translate>
            </Grid>

            <Grid item xs={12} className={`${classes.titlesFunctions} ${classes.centerXs}`}>
                <NavLink className="mr-3" to="/employer/recover-password">
                    <Typography className={classes.recoverText} color="primary">{<Translate id={"Employer.ForgetPassword"} />} </Typography>
                </NavLink>
                <NavLink to="/employer/register">
                    <Typography className={classes.recoverTextBold} >{<Translate id={"Employer.CreateAccount"} />} </Typography>
                </NavLink>
            </Grid>

            <Grid container className={`${classes.buttons} ${classes.centerXs}`}>
                <Grid item className={classes.buttonsContainer} >
                    <Grid item xs={12}>
                        <Button className={classes.submit} variant="contained" color="secondary" type="submit">
                            <Typography className={classes.buttomtext}><Translate id={"Buttons.GetIn"} /></Typography>
                        </Button>
                    </Grid>
                    {errorLogin ? <FormHelperText error className="pt-5">
                            {errorLogin}
                        </FormHelperText>
                        : ""}
                </Grid>

            </Grid>


        </ValidatorForm>
    </Grid>);
}

export default withRouter(LoginEmployer);