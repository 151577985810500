import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from "@material-ui/core";
import { Translate } from "react-localize-redux";

const useStyles = makeStyles(() => ({
    imgResponsive: {
        width: "auto",
        height: "auto",
        maxWidth: "100%"
    },
    title: {
        fontWeight: 600,
        marginBottom: 20
    }
}));

export default function EdgePc(props) {

    let classes = useStyles();
    return (<Translate>
        {({translate})=>{
            return <Grid container className="mb-5">
                <Typography className={classes.title} variant="h2"><Translate id="Help.EdgeTitle" /></Typography>
                <Grid item xs={12}>
                    <div hidden>
                        <Typography color="error"><Translate id="Help.GenericText1" /></Typography>
                        <Typography color="error"><Translate id="Help.GenericText2" /></Typography>
                    </div>
                    <Typography className="mt-3 font-weight-bold" color="primary"><Translate id="Help.GenericText3" /></Typography>
                </Grid>
                <Typography className={`${classes.title} my-4`} variant="h3"><Translate id="Help.Option1" /></Typography>

                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.EdgeStep1" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/1.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.EdgeStep2" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/2.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography className="mr-1">3. <Translate id="Help.ChromeStep3" /></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                        <Typography className="ml-1"><Translate id="Help.AllowAccess" />.</Typography>
                    </div>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/8.jpg")} />
                </Grid>


                <Typography className={`${classes.title} my-4`} variant="h3"><Translate id="Help.Option2" /></Typography>
                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.EdgeStep3" /></Typography>
                    <img alt={translate("Help.EdgeTitle")}  className={classes.imgResponsive} src={require("../../../../images/support/edgepc/3.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.EdgeStep4" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/4.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.EdgeStep5" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/5.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>4. <Translate id="Help.EdgeStep6" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/6.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>5 <Translate id="Help.EdgeStep7a" /></Typography>
                    <Typography>5 <Translate id="Help.EdgeStep7b" /></Typography>
                    <img alt={translate("Help.EdgeTitle")} className={classes.imgResponsive} src={require("../../../../images/support/edgepc/7.jpg")} />
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>6. <Translate id="Help.EdgeStep8" /></Typography>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography className="mr-1">7. <Translate id="Help.ChromeStep3" /></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                        <Typography className="ml-1"><Translate id="Help.AllowAccess" />.</Typography>
                    </div>
                    <img alt={translate("Help.EdgeTitle")}  className={classes.imgResponsive} src={require("../../../../images/support/edgepc/8.jpg")} />
                </Grid>

            </Grid>
        }}
    </Translate>)
}