import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Paper, Typography, Grid, Hidden, useMediaQuery} from '@material-ui/core';
import ReactQuill from 'react-quill';



export default function TipsCard(props) {
    let { data, index, scope } = props;
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: "100%",
            paddingBottom: 24,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 10,
            }
        },
        media: {
            width: 120,
            height: 130,
            [theme.breakpoints.down('xs')]: {
                width: 90,
                height: 100,
                marginTop: 0,
            },
        },
        area: {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 100,
            borderRadius: 0,
            [theme.breakpoints.down('xs')]: {
                minHeight: 50,
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        imageContainer: {
            display: "flex",
            alignItems: "flex-start",
            paddingTop: 16,
            [theme.breakpoints.down('lg')]: {
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 0,
            },
            [theme.breakpoints.down('sm')]: {
                justifyContent: "flex-start"
            },

        },
        text: {
            fontSize: 17,
            textAlign: "center",
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                textAlign: "left",
            },
        },
        cardContent: {
            padding: 16,
            paddingBottom: 24,
        },
        title: {
            fontSize: 28,
            marginLeft: scope === "professional" ? "21%" : "16%",
            fontWeight: 600,
            color: scope === "professional" ? theme.palette.primary.main : theme.palette.secondary.main,
            lineHeight: 1.2,
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
                marginLeft: 0,
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: 24,
                marginLeft: "17%"
            },
            marginBottom:0
        },
        ul: {
            paddingLeft: 0,
            '& li': {
                //listStyleType: "disc",
                fontSize: 17,
                color: "#141414",
                '& a, a:hover': {
                    color: "#141414",
                    fontWeight: "bold",
                    textDecoration: "underline !important"
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: 16
                },
            },
            '& ::marker': {
                fontSize: 18
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: 8,
                paddingLeft: 16
            },

        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    const screenSmDow = useMediaQuery(theme.breakpoints.down('lg'));

    function responseImages(name) {
        if (screenSmDow)
            return require(`../../../images/layuout/tips/${name}Movil${index}.png`)
        else
            return require(`../../../images/layuout/tips/${name}${index}.png`)
    }

    const resolveImage = () => scope === "professional"
        ? responseImages("tp")
        : responseImages("te");

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container>
                <Hidden mdDown>
                    {index % 2 === 0 && <Grid item md={2} xl={3}></Grid>}
                </Hidden>
                <Grid item xs={12} md={11} lg={10} xl={9}>
                    <Paper elevation={0} className={classes.area}>
                        <Grid container spacing={0}>
                            <Hidden xsDown>
                                <Grid item sm={12} md={12}>
                                    {<h2 className={`${classes.title} pb-3`}>{data.title}</h2>}
                                </Grid>
                                <Grid item sm={3} md={3} className={classes.imageContainer}>
                                    <img title={data.alt} alt={data.alt} src={resolveImage()} />
                                </Grid>
                            </Hidden>

                            <Hidden smUp >
                                <Grid item xs={4} className={classes.imageContainer}>
                                    <img title={data.alt} alt={data.alt}  src={resolveImage()}  />
                                </Grid>
                                <Grid item xs={8} className="d-flex align-items-center" >
                                    {<Typography className={classes.title}>{data.title}</Typography>}
                                </Grid>
                            </Hidden>

                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <ul className={classes.ul}>
                                    {data.tips.map((value, index) => {
                                        return <div dangerouslySetInnerHTML={{ __html: value }} />
                                    })}
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}