import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoImage from '../../images/logo.png';
import { Grid, useMediaQuery, useScrollTrigger } from "@material-ui/core";
import EmployerMenu from "./employerMenu";
import PropTypes from 'prop-types';
import { NavLink, useHistory } from "react-router-dom";
import LogoImageMovil from "../../images/logoMovil.png";
import Slide from '@material-ui/core/Slide';
import {
    Tooltip,
    ListItem,
    Typography
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
    logo: {
        //background: `transparent url('${LogoImage}') 0% 0% no-repeat padding-box`,
        //backgroundSize: "cover",
        objectFit: "cover",
        marginTop: 18,
        width: 97,
        height: 69,
        [theme.breakpoints.down('sm')]: {
            width: 72,
            marginTop: 11,
            //marginLeft: "8%",
            height: 50,
            // background: `transparent url('${LogoImageMovil}') 0% 0% no-repeat padding-box`,

        },
        [theme.breakpoints.only('xs')]: {
            marginLeft: 27,
        },
    },
    menu: {
        position: "absolute",
        right: "15%",
        [theme.breakpoints.only('xs')]: {
            right: "15%",
            //bottom: -10
        },
    },
    button: {
        color: "#141414"
    },
    menuItem: {
        borderLeft: `1px solid ${theme.palette.text.secondary}`,
        marginBottom: 12,
        marginTop: 12,
        paddingBottom: 0,
        paddingTop: 0,
        color: theme.palette.text.secondary
    },
    appBar: {
        //backgroundColor: theme.palette.common.white,
        minHeight: 90,
        [theme.breakpoints.down('xs')]: {
            minHeight: 75,
        },
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        id: trigger ? "scrolled" : "no-scrolled"
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};



export default function EmployerHearder(props) {
    const classes = useStyles();
    let loggedUser = useSelector(state => state.users.loggedUser);
    let history = useHistory();
    const theme = useTheme();
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    function renderAppToolBar(props, classes) {
        return <Toolbar className="p-0">
            <Grid container direction="row" justify="center">
                <Grid item xs={12} sm={9} md={7}>
                    <Tooltip title={<Translate id={"HomeTitles.Home"} />}>
                        <img alt={"Hihired!"} src={screenSmDown ? LogoImageMovil : LogoImage} className={classes.logo} role="button" onClick={() => history.push("/")} />
                    </Tooltip>
                </Grid>
            </Grid>
            {props.menu && loggedUser ? <EmployerMenu /> : ""}
        </Toolbar>
    }

    return (props.scroll ? <ElevationScroll {...props}>
        <AppBar position={props.scroll ? "fixed" : "static"} color="transparent" elevation={0}
            className={classes.appBar}>
            {renderAppToolBar(props, classes)}
        </AppBar>
    </ElevationScroll> : <AppBar position={props.scroll ? "fixed" : "static"} color="transparent" elevation={0}
        className={classes.appBar}>
        {renderAppToolBar(props, classes)}
    </AppBar>
    );
}