import axios from 'axios';
import { uploadVideo, removeVideo, uploadSegmentVideo, configureS3 } from "./s3";
import staticContent from "../resources/home.json";

export const BASE_PATH = "https://api.hihired.com/api";
// process.env.NODE_ENV === "development"
//? 
//:    "http://localhost:5005/api";

const merchantClientUrl = process.env.NODE_ENV === "development"
    ? "http://192.168.0.20:3000"
    : "https://www.hihired.com";

const userLanguage = () => {
    let stateStorage = localStorage.getItem("state");
    if (stateStorage) {
        let state = JSON.parse(localStorage.getItem("state"));
        if (state && state.users) return state.users.defaultLanguage;
    }
    return null;
}

const loggedUser = () => {
    let stateStorage = localStorage.getItem("state");
    if (stateStorage) {
        let state = JSON.parse(localStorage.getItem("state"));
        if (state && state.users) return state.users.loggedUser;
    }
    return { id: 0 };
}

let culture = userLanguage() || navigator.language || navigator.userLanguage || "es-ES";
culture = culture === "es" ? "es-ES" : culture === "en" ? "en-US" : culture;
culture = culture === "es-es" ? "es-ES" : culture === "en-us" ? "en-US" : culture;
culture = culture === "es-ES" || culture === "en-US" ? culture : "es-ES";

const api = axios.create({
    baseURL: BASE_PATH,
    timeout: 120000,
    transformRequest: [function (data, headers) {
        return JSON.stringify(data);
    }],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
    }
});


api.interceptors.request.use(async function (request) {
    if (request.url === "/login") return request;
    request.headers['Authorization'] = `Bearer ${await localStorage.getItem('token') || ''}`;
    return request
})

api.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    if (error.response && error.response.status === 401) {
        localStorage.setItem("state", "{}");
        localStorage.setItem("token", "");

        let reg = new RegExp('https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].com\\/professional\\/[0-9]{1,10}\\/[0-9]{1,10}$');
        if (window.location.pathname.indexOf("employer") > -1 || reg.test(window.location.href)) {
            window.location = `/employer/login`;
        } else {
            window.location = `/professional/login/?redirect=${window.location.pathname}`;
        }
    }
    if (error.response && error.response.status === 400) {
        return error.response;
    }
    return Promise.reject(error)
});

export default class Api {
    static home = {
        getContent: async (section) => {
            return staticContent[culture][section];
        },
        getSeo: async () => {
            return await this.fetchData('/Configuration/SeoTag', { url: window.location.pathname }, 'GET');
        },
    }

    static users = {
        sigInUser: async (user) => {
            return await this.fetchData('/User/SignIn', user, "POST");
        },
        sigInLinkedInUser: async (code, register = true, formData) => {
            return await this.fetchData(`/User/SingInLinkedIn?code=${code}&register=${register}
            &acceptNotifications=${formData.acceptNotifications}&acceptTerms=${formData.acceptTerms}`, {
                ...formData,
                code,
                register
            }, "POST", false);
        },
        contactUs: async (data) => {
            return await this.fetchData(`/User/ContactUs`, data, "POST", true);
        },
        forgotPassword: async (data) => {
            return await this.fetchData(`/User/ForgotPassword`, data, "POST", true);
        },
        changePassword: async (data) => {
            return await this.fetchData(`/User/ResetPassword`, data, "POST", true);
        },
        changeSelfPassword: async (data) => {
            return await this.fetchData(`/User/ChangePassword`, data, "POST", true);
        },
        registerEmployer: async (data) => {
            return await this.fetchData(`/User/RegisterEmployer`, data, "POST", true);
        },
        confirmEmail: async (data) => {
            return await this.fetchData(`/User/ConfirmEmail`, data, "GET", true);
        },
        disableAccount: async () => {
            return await this.fetchData(`/User/DisableAccount`, '', "POST", true);
        },

        registerProfessional: async (data) => {
            return await this.fetchData(`/User/RegisterProfessional`, data, "POST", true);
        },
    }

    static language = {
        getAllLanguages: async () => {
            return await this.fetchData('/Language/All', '', 'GET');
        },
        setUserLanguage: async (defaultLanguage) => {
            return await this.fetchData('/Language/SetUserDefaultLanguage', { defaultLanguage }, 'POST');
        },
        setLanguage: async () => {
            let code = culture ? culture.split("-")[0] : "es";
            code = code == "es" || code == "en" ? code : "es";
            await this.language.setUserLanguage(code);
        }
    }

    static filters = {
        getFilters: async (provinceId, specialtyId) => {
            return await this.fetchData('/Filters/Filters', { provinceId, specialtyId }, 'GET');
        }
    }

    static languageTest = {
        all: async () => {
            return await this.fetchData('/LanguageTest/All', '', 'GET');
        },
        getLanguageTest: async (id) => {
            return await this.fetchData('/LanguageTest/Get', { id }, 'GET');
        },
        viewLanguageTest: async (professionalId, languageTestId) => {
            return await this.fetchData('/LanguageTest/CanViewLanguageTests', { professionalId, languageTestId }, 'POST');
        }
    }

    static interview = {
        allQuestions: async () => {
            return await this.fetchData('/Interview/AllQuestions', '', 'GET');
        },
        get: async (id) => {
            return await this.fetchData('/Interview/Get', { id }, 'GET');
        },
        getProfessionalInterview: async () => {
            return await this.fetchData('/Interview/ProfessionalInterview', '', 'GET');
        },
        getProfessionalInterviewQuestions: async () => {
            return await this.fetchData('/Interview/ProfessionalInterviewQuestions', '', 'GET');
        },
        createInterview: async (interview) => {
            return await this.fetchData('/Interview/Create', interview, 'POST');
        },
        uploadInterview: async (video, progressCallback) => {
            await this.interview.configureUpload();
            return uploadVideo(video, progressCallback, "interviews", `prof${loggedUser().id}`);

            /*let response = await this.interview.getPreSignedUrl();
            let presigndUrl = response.data;
            alert(presigndUrl)

            return fetch(presigndUrl, {
                mode: 'cors',
                method: "PUT",
                body: video,
                headers: {
                    "Access-Control-Allow-Origin": "https://example.com",
                    "Access-Control-Allow-Credentials": true,
                    "Access-Control-Expose-Headers": "ETag,Access-Control-Allow-Origin",
                    "Content-Type": "video/mp4",
                    "x-amz-acl": "public-read",
                    "x-amz-meta-key": "12345",
                    "x-amz-meta-otherInfo": "other_data",
                }
            });
            return axios.put(presigndUrl, video, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Expose-Headers": "ETag,Access-Control-Allow-Origin",
                    "Content-Type": "multipart/form-data",
                    "x-amz-acl": "public-read",
                    "x-amz-meta-key": "12345",
                    "Access-Control-Max-Age": 5600
                },
                timeout: 1000 * 60 * 10,
                onUploadProgress: function (progressEvent) {
                    if (progressCallback) progressCallback(progressEvent);
                }
            });*/
        },
        uploadSegmentInterview: async (video, folderkey, progressCallback) => {
            await this.interview.configureUpload();
            return uploadSegmentVideo(video, folderkey, progressCallback);
        },
        removeVideoInterview: async (videoKey, progressCallback) => {
            await this.interview.configureUpload();
            return removeVideo(videoKey, progressCallback);
        },
        getPreSignedUrl: async () => {
            return await this.fetchData('/Interview/GetPreSignedUrl', '', 'GET');
        },
        getConfigs: async () => {
            return this.fetchData('/Configuration/UploadSettings', '', 'GET');
        },
        configureUpload: async () => {
            let configsResponse = await this.interview.getConfigs();
            if (configsResponse.status === 200) {
                let configs = JSON.parse(window.atob(configsResponse.data));
                //let configs = configsResponse.data;
                configs = {
                    ...configs,
                    accessKey: configs.AccessKey,
                    secretKey: configs.SecretKey,
                    endpoint: configs.Endpoint
                };
                configureS3(configs);
            }
        }
    }

    static configuration = {
        getAllConfigurations: async () => {
            return await this.fetchData('/Configuration/All', '', 'GET');
        },
        getSeoTag: async (url) => {
            return await this.fetchData('/Configuration/SeoTag', url, 'GET');
        }
    }

    static enterpriseTypes = {
        all: async () => {
            return await this.fetchData('/EnterpriseType/All', '', 'GET');
        }
    }

    static enterprises = {
        detailsByIdentifier: async (identifier) => {
            return await this.fetchData('/Enterprise/DetailsByIdentifier', { identifier }, 'GET');
        },
        saveLogo: async (imageForm) => {
            return await fetch(`${BASE_PATH}/Enterprise/SaveLogo`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                    },
                    body: imageForm
                }
            );
        },
        all: async () => {
            return await this.fetchData('/Enterprise/All', '', 'GET');
        },
        getLogo: async (enterpriseId) => {
            return await this.fetchData('/Enterprise/GetLogo', { enterpriseId }, 'GET');
        }
    }

    static employerTypes = {
        all: async () => {
            return await this.fetchData('/EmployerType/All', '', 'GET');
        }
    }

    static sectors = {
        all: async () => {
            return await this.fetchData('/Sectors/All', '', 'GET');
        }
    }

    static employers = {
        getEmployerProfile: async () => {
            return await this.fetchData('/Employer/GetEmployerProfile', '', 'GET');
        },
        addOrUpdate: async (data) => {
            let response = await this.fetchData('/Employer/AddOrUpdate', data, 'POST');
            if (response.status === 200 && data.logo) {
                let imageForm = data.logo;
                imageForm.append("enterpriseId", response.data.enterprise.id);
                await this.enterprises.saveLogo(imageForm);
            }
            return response;
        },
        checkCompletedProfile: async () => {
            return await this.fetchData('/Employer/CheckCompletedProfile', '', 'GET');
        },
    }

    static professional = {
        checkCompletedProfile: async () => {
            return await this.fetchData('/Professional/CheckCompletedProfile', '', 'GET');
        },
        getProfessionalProfile: async () => {
            return await this.fetchData('/Professional/Details', '', 'GET');
        },
        getProfessional: async (id) => {
            return await this.fetchData('/Professional/GetProfessional', { id }, 'GET');
        },
        getProfessionalApplications: async () => {
            return await this.fetchData('/Professional/Applications', '', 'GET');
        },
        getProfessionalAlerts: async () => {
            return await this.fetchData('/Professional/Alerts', '', 'GET');
        },
        getProfessionalLanguageTest: async () => {
            return await this.fetchData('/Professional/GetLanguagesTest', '', 'GET');
        },

        uploadVideoSpeakingTest: async (video, progressCallback) => {
            await this.interview.configureUpload();
            return uploadVideo(video, progressCallback, 'videospeaking', `test${loggedUser().id}`);
        },
        uploadVideoSpeakingTestSegments: async (video, folderkey, progressCallback) => {
            await this.interview.configureUpload();
            return uploadSegmentVideo(video, folderkey, progressCallback, 'videospeaking', `test${loggedUser().id}`);
        },

        saveVideoSpeakingTest: async (data) => {
            return await this.fetchData('/Professional/SaveLanguageTest', data, 'POST');
        },
        saveVideoSpeakingAttempt: async (languageTestId) => {
            return await this.fetchData('/Professional/SaveLanguageTestAttempt', { languageTestId }, 'POST');
        },
        getTest: async (languageTestId, professionalId = 0) => {
            return await this.fetchData('/Professional/GetTest', { languageTestId, professionalId }, 'GET');
        },
        contactProfessional: async (applicationId, bodyBase64) => {
            return await this.fetchData('/Professional/ContactProfessional', { applicationId, bodyBase64 }, 'POST');
        },


        saveLogo: async (imageForm) => {
            return await fetch(`${BASE_PATH}/Professional/SaveLogo`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
                    },
                    body: imageForm
                }
            );
        },
        uploadCv: async (formData) => {
            return await axios.post(`${BASE_PATH}/Professional/UploadCv`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
        },
        uploadReference: async (formData) => {
            return await axios.post(`${BASE_PATH}/Professional/UploadReferences`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
        },
        uploadAwards: async (formData) => {
            return await axios.post(`${BASE_PATH}/Professional/UploadAwards`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token') || ''}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
        },
        addOrUpdate: async (data) => {
            let response = await this.fetchData('/Professional/AddOrUpdate', data, 'POST');
            return response;
        },

        downloadFile: async () => {
            const url = 'http://localhost:5000/files/professionals/31/200717_ERRORES OPTIMA-PRODUCCIÓN.docx'
            return await fetch(url,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
                    },
                }
            );
        },
        resolveFile: (professionalId, fileName) => {
            return `${BASE_PATH}/Professional/DownloadFile?filename=${fileName}&professionalId=${professionalId}`
        },
    }

    static states = {
        all: async () => {
            return await this.fetchData('/State/All', '', 'GET');
        }
    }

    static applicationStates = {
        all: async () => {
            return await this.fetchData('/ApplicationStates/All', '', 'GET');
        }
    }

    static application = {
        getApplication: async (id) => {
            return id
                ? await this.fetchData('/OfferApplication/GetApplication', { id }, 'GET')
                : await this.fetchData('/OfferApplication/GetMockedApplication', '', 'GET');
        },
        changeStateProcess: async (process) => {
            return await this.fetchData('/Process/ChangeProcessState', process, 'PUT');
        },
        applyProcess: async (application) => {
            return await this.fetchData('/Offer/Apply', application, 'POST');
        },
        unApplyProcess: async (application) => {
            return await this.fetchData('/Offer/UnApply', application, 'POST');
        }
    }

    static offers = {
        getOffer: async (id) => {
            return await this.fetchData('/Offer/Get', { id }, 'GET');
        },
        getOfferBySeo: async (seo) => {
            return await this.fetchData('/Offer/GetOffer', { seo }, 'GET');
        },
        employerOffers: async () => {
            return await this.fetchData('/Offer/GetEmployerOffers', '', 'GET');
        },
        getSelectionProcess: async (id) => {
            let res = await this.fetchData('/Offer/GetSelectionProcess', { id }, 'GET');
            console.log('getSelectionProcess', res);
            return res;
        },
        copyOffer: async (id) => {
            return await this.fetchData('/Offer/Copy', { id }, 'POST');
        },
        reopenOffer: async (id) => {
            return await this.fetchData('/Offer/Reopen', { id }, 'POST');
        },
        closeSelectionProcess: async (id) => {
            return await this.fetchData('/Offer/CloseSelectionProcess', { id }, 'POST');
        },
        create: async (offer) => {
            return await this.fetchData('/Offer/Create', offer, 'POST');
        },
        update: async (offer) => {
            return await this.fetchData('/Offer/Update', offer, 'PUT');
        },
        deleteOffer: async (id) => {
            return await this.fetchData('/Offer/Delete', { id }, 'DELETE');
        },
        getFilters: async (provinceId, specialtyId) => {
            return await this.fetchData('/Offer/GetFilters', { provinceId, specialtyId }, 'GET');
        },
        getOffersByAlert: async (filters) => {
            return await this.fetchData('/Offer/GetOffersByAlert', filters, 'GET');
        },
        getReactivatedOffer: async () => {
            return await this.fetchData('/Offer/GetReactivatableEmployerOffers', '', 'GET');
        },
        getOffersSeo: async (pageNumber = 0, pageSize = 100) => {
            return await this.fetchData('/Offer/GetOfferSeoTitles', { pageNumber, pageSize }, 'GET');
        },
        GetOffersByCountryAndCategory: async (countryId, categoryId, enterpriseId, pageNumber) => {
            return await this.fetchData('/Offer/GetOffersByCountryAndCategory', { StateId: 0, countryId, categoryId, pageNumber, PageSize: 12, enterpriseId }, 'GET');
        },
        GetOffersByCountryAndSpecialty: async (countryId, specialtyId, enterpriseId, pageNumber) => {
            return await this.fetchData('/Offer/GetOffersByCountryAndSpecialty', { StateId: 0, countryId, specialtyId, pageNumber, PageSize: 9, enterpriseId }, 'GET');
        }
    }

    static countries = {
        all: async () => {
            return await this.fetchData('/Country/All', '', 'GET');
        },
        getDefaultCountry: async () => {
            return await this.fetchData('/Country/GetDefault', '', 'GET');
        }
    }

    static provinces = {
        all: async () => {
            return await this.fetchData('/Province/All', '', 'GET');
        },
        allByCountry: async (countryId) => {
            return await this.fetchData('/Province/All', { countryId }, 'GET');
        }
    }

    static specialties = {
        all: async () => {
            return await this.fetchData('/Specialties/All', '', 'GET');
        }
    }

    static categories = {
        all: async () => {
            return await this.fetchData('/Categories/All', '', 'GET', false);
        }
    }

    static employmentRelationships = {
        all: async () => {
            return await this.fetchData('/EmploymentRelationship/All', '', 'GET');
        }
    }

    static workdays = {
        all: async () => {
            return await this.fetchData('/Workday/All', '', 'GET');
        }
    }

    static alerts = {
        all: async () => {
            return await this.fetchData('/Alert/All', '', 'GET');
        },
        addOrUpdate: async (jsonAlert) => {
            return await this.fetchData('/Alert/AddOrUpdate', jsonAlert, 'POST');
        },
        allByProfessional: async (id) => {
            return await this.fetchData('/Alert/AllByProfessional', { id }, 'GET');
        },
        delete: async (id) => {
            return await this.fetchData('/Alert/Remove', { id }, 'DELETE');
        },
        changeSchedule: async (frequency = 1) => {
            return await this.fetchData('/Alert/ChangeFrequency', { frequency }, 'PUT');
        }
    }
    static purchases = {
        hasViewOffers: async () => {
            return await this.fetchData('/Purchases/HasViewOffers', '', 'GET');
        }
    }

    static payments = {
        getEmployerPayments: async (page = 0, pageSize = 20, orderBy = 0, asc = null) => {
            return await this.fetchData('/Payment/GetEmployerPayments', { page, pageSize, orderBy, asc }, 'GET');
        },

        getCompletedUrl: async (planId, offerId, paymentId) => {
            return await this.fetchData('/Payment/GetCompletedUrl', { planId, offerId, paymentId }, 'GET');
        },

        getPayment: async (id) => {
            return await this.fetchData('/Payment/GetPayment', { id }, 'GET');
        },

        getRetryPayment: async (id) => {
            return await this.fetchData('/Payment/GetRetryPayment', { id }, 'GET');
        },

        getPaymentRequest: async (merchantUrl, order, amount, merchantIdentifier = "REQUIRED", paymentType = "CreateProcess", paymentId = 0) => {
            merchantIdentifier = merchantIdentifier === null ? "REQUIRED" : merchantIdentifier;
            let parsedAmount = amount.toFixed(2).replace(".", "");
            if (parsedAmount === "00" || parsedAmount === "000") parsedAmount = "0";

            let dataPost = {
                paymentId,
                merchantOrder: order,
                merchantIdentifier: merchantIdentifier,
                merchantUrl: merchantUrl,
                merchantUrlKo: `${merchantClientUrl}/employer/notify-payment/failed/${paymentType}`,
                merchantUrlOk: `${merchantClientUrl}/employer/notify-payment/success/${paymentType}`,
                merchantTransactionType: "0",
                merchantAmount: parsedAmount,
                merchantPan: "",
                merchantCvv2: "",
                expirationDate: ""
            }
            return await this.fetchData("/Redsys/GetPaymentRequest", dataPost, 'POST');
        },

        getEmployerPaymentInfos: async () => {
            return await this.fetchData('/PaymentInfo/GetEmployerPaymentsInfo', '', 'GET');
        },

        /*createOrder: async (planId, amount, invoiceNumber, languageTestCount, cost = 0, ivaPercent = 0, offerId = 0) => {
            return await this.fetchData('/Payment/AddPayment',
                {planId, amount, invoiceNumber, languageTestCount, cost, ivaPercent, offerId}, 'POST');
        },*/

        createOrder: async (planCode, languageTestCount, offerId = 0) => {
            return await this.fetchData('/Payment/AddPayment',
                { planCode, languageTestCount, offerId }, 'POST');
        },

        reactivateProcess: async (offerId) => {
            return await this.fetchData(`/Purchases/ReactivateProcess?offerId=${offerId}`, { offerId }, 'POST', false);
        },

        publishProcess: async (offerId) => {
            return await this.fetchData(`/Purchases/PublishProcess?offerId=${offerId}`, { offerId }, 'POST', false);
        },

        getInvoice: async (id) => {
            return await this.fetchData(`/payment/generateinvoice`, { id }, 'GET', false);
        },

        decodePayment: async (payment) => {
            return await this.fetchData(`/payment/GetPaymentResponsePlan${payment}`, '', 'GET', false);
        },

        getPendingPayment: async (planId) => {
            return await this.fetchData('/Payment/GetPendingPayment', { planId }, 'GET');
        },
    }

    static plans = {
        all: async () => {
            return await this.fetchData('/Plan/All', '', 'GET');
        },
        getPlan: async (code, cant = 0, offerId = 0) => {
            return await this.fetchData('/Plan/GetByCode', { code, cant, offerId }, 'GET');
        }
    }


    static async fetchData(url, params, method = 'POST', addCulture = true) {
        try {
            let response = null;

            if (addCulture) {
                url = `${url}?culture=${culture}`
            }

            switch (method) {
                case "POST":
                    response = await api.post(url, params)
                    break;
                case "UPLOAD":
                    response = await axios.post(`${BASE_PATH}${url}`, params, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token') || ''}`
                        }
                    })
                    break;
                case "GET":
                    response = await api.get(url, { params: params })
                    break;
                case "PUT":
                    response = await api.put(url, params)
                    break;
                case "DELETE":
                    response = await api.delete(url, { params: params })
                    break;
                case "DEL":
                    response = await api.delete(url, params)
                    break;
                case "FILE":
                    response = await api.post(url, params,
                        {
                            responseType: 'blob',
                        })
                    break;
            }
            return response;
        } catch (e) {
            console.log(`Error fetch data ${url}`, e);
            return e;
        }
    }
}