import React, { useState, useEffect } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import {Button, Typography, Container, useMediaQuery} from "@material-ui/core";
import { Twitter, Facebook, Instagram, IconButton } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { staticContent } from "../../../utils/constants";
import TipsCard from "./tipCard";

const useStyles = makeStyles((theme) => ({
    titleProfessional: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.primary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left",
            marginLeft: 0
        },
    },
    titleEmployer: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.secondary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left",
            marginLeft: 0
        }
    },
    containerBox: {
        paddingBottom: 100,
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    },
    boxContent: {

    },
    container: {
        padding: "40px 24px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },

    },
}));

function Tips(props) {
    let classes = useStyles();
    const scope = props.match.params["scope"] ? props.match.params["scope"] : "";
    let [professionalData, setProfessionalData] = useState([]);
    let [employerData, setEmployerData] = useState([]);

    useEffect(() => {
        api.home.getContent(staticContent.Tips).then(response => {
            setProfessionalData(response[staticContent.Professionals]);
            setEmployerData(response[staticContent.Employers]);
        });
    }, [professionalData, employerData])

    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    function renderProfessionalsTips() {
        return (<Grid item xs={12}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <h1 className={`${classes.titleProfessional} my-4`}>{<Translate id={"HomeTitles.ProfessionalTips"} />} </h1>
                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center" alignItems="center">
                    {professionalData.map((data, index) => {
                        return (<Grid className={classes.boxContent} item xs={12}>
                            <TipsCard index={index + 1} data={data} scope={scope}/>
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Grid >)
    }

    function renderEmployersTips() {
        return (<Grid item xs={12}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <h1 className={`${classes.titleEmployer} my-4`}>{<Translate id={"HomeTitles.EmployerTips"} />} </h1>
                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center" alignItems="center">
                    {employerData.map((data, index) => {
                        return (<Grid className={classes.boxContent} item xs={12} md-offset-3>
                            <TipsCard index={index + 1} data={data} scope={scope}/>
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Grid>)
    }


    return (<Grid container spacing={0}>
        {scope === "employers"
            ? renderEmployersTips()
            : renderProfessionalsTips()}
    </Grid>);
}

export default withRouter(Tips);