export const SET_PAUSED_INTERVIEW_ACTION = 'SET_PAUSED_INTERVIEW_ACTION';
export const SET_RUN_INTERVIEW_ACTION = 'SET_RUN_INTERVIEW_ACTION';
export const SET_END_INTERVIEW_ACTION = 'SET_END_INTERVIEW_ACTION';
export const SET_VIEW_INTERVIEW_ACTION = 'SET_VIEW_INTERVIEW_ACTION';
export const SET_VIEW_INTERVIEW_SUPPORT = 'SET_VIEW_INTERVIEW_SUPPORT';
export const SET_UNSUPPORT_CONFIGURATION = 'SET_UNSUPPORT_CONFIGURATION';
export const RESET_UNSUPPORT_CONFIGURATION = 'RESET_UNSUPPORT_CONFIGURATION';


export function setPausedInterviewAction(action) {
    return {
        type: SET_PAUSED_INTERVIEW_ACTION,
        payload: action
    }
}

export function setRunInterviewAction(action) {
    return {
        type: SET_RUN_INTERVIEW_ACTION,
        payload: action
    }
}

export function setEndInterviewAction(action) {
    return {
        type: SET_END_INTERVIEW_ACTION,
        payload: action
    }
}

export function setViwInterviewAction(action) {
    return {
        type: SET_VIEW_INTERVIEW_ACTION,
        payload: action
    }
}

export function setInterviewSupport(support) {
    return {
        type: SET_VIEW_INTERVIEW_SUPPORT,
        payload: support
    }
}

export function setUnsupportConfiguration(configuration) {
    return {
        type: SET_UNSUPPORT_CONFIGURATION,
        payload: configuration
    }
}

export function resetUnsupportConfiguration() {
    return {
        type: RESET_UNSUPPORT_CONFIGURATION,
        payload: null
    }
}