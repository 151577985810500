import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {ValidatorForm} from "react-material-ui-form-validator";
import {Translate} from "react-localize-redux";
import {
    Paper,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Divider,
    TextField,
    CircularProgress, Button, useMediaQuery
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as types from "../../actions/errors";
import DefaultImage from "../../images/employer/default-image.png";
import api from "../../api";
import {NavLink} from "react-router-dom";
import GoBack from "../navigation/goBack";
import BasicConfirmation from "../confirmation/basicConfirmation";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import SimplePopover from "../employer/popover";

const useStyles = makeStyles((theme) => ({
        paddingXs: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "1rem",
                paddingLeft: "1rem"
            }
        },
        title: {
            fontSize: 35,
            fontWeight: 600,
            //marginTop: 20,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
                marginTop: 0
            }
        },
        title2: {
            fontSize: 35,
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
            }
        },
        title3: {
            fontSize: 20,
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: 0.5,
        },
        completeSuggest: {
            fontSize: 25,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
                marginTop: 8
            }
        },
        paper: {
            [theme.breakpoints.up('sm')]: {
                boxShadow: "0px 0px 36px #00000015"
            }
        },
        ellipsis: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        textLinkedin: {
            fontSize: 16,
            letterSpacing: 0.8,
            color: theme.palette.text.secondary,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15,
            },
        },
        img: {
            width: 86,
            height: 86,
            objectFit: "contain",
            [theme.breakpoints.only('xs')]: {
                width: 62,
                height: 62,
            }
        },
        body4: {
            fontSize: 18,
            letterSpacing:
                0.45,
            color:
            theme.palette.text.secondary,
            //fontWeight: 600,
            [theme.breakpoints.only('xs')]:
                {
                    fontSize: 17,
                }
            ,
        }
        ,
        divider: {
            width: 70,
            height:
                0,
            border:
                `1px solid ${theme.palette.text.secondary}`,
        }
        ,
        divider2: {
            width: 190,
            height:
                0,
            border:
                `1px solid #A3A3A3`,
        }
        ,
        divBorder: {
            borderLeft: "1px solid #A3A3A3",
            borderTop:
                "1px solid #A3A3A3",
        }
        ,
        circle: {
            width: 20,
            height:
                20,
            border:
                "2px solid #141414",
            borderRadius:
                10,
            backgroundColor:
                "#FFFFFF",
            marginRight:
                8
        }
        ,
        responsiveWidth: {
            width: "100%",
            "& .MuiInput-multiline":
                {
                    marginTop: "0 !important"
                }
        }
        ,
        buttonContainer: {
            display: "flex",
            position:
                "relative",
            flexWrap:
                "wrap",
            [theme.breakpoints.down('xs')]:
                {
                    //display: "block",
                    position:
                        "unset",
                    textAlign:
                        "center"
                }
        }
        ,
        submitLabel: {
            fontWeight: 700,
            fontSize:
                18,
            color:
            theme.palette.common.white,
            lineHeight:
                1
        }
        ,
        submit: {
            borderRadius: 0,
            width:
                225,
            padding:
                "5px 10px"
        }
        ,
        success: {
            color: theme.palette.common.green,
            fontWeight:
                600,
            [theme.breakpoints.only('xs')]:
                {
                    fontSize: 15
                }
        }
        ,
        error: {
            fontWeight: 600,
            [theme.breakpoints.only('xs')]:
                {
                    fontSize: 15
                }
        }
        ,
        applied: {
            border: "1px solid #141414",
            pointerEvents:
                "none"
        }
        ,
        delete:
            {
                borderRadius: 0,
                width:
                    225,
                padding:
                    "5px 10px",
                border:
                    `2px solid ${theme.palette.error.main}`,
            }
        ,
        deleteLabel: {
            color: theme.palette.error.main,
            fontWeight:
                600,
            lineHeight:
                1,
            [theme.breakpoints.only('xs')]:
                {
                    fontSize: 15
                }
        }
        ,
        textField: {
            '& .MuiInputBase-root.Mui-disabled':
                {
                    border: "none",
                    backgroundColor:
                        "transparent",
                    margin:
                        "0 !important",
                    padding:
                        0,
                    minWidth: 100
                }
            ,
            '& .MuiInput-inputMultiline':
                {
                    padding: 0,
                    backgroundColor:
                        "transparent",
                }
            ,
            '&.MuiFormControl-root':
                {
                    width: "100%"
                },
            '& .MuiInputBase-input':
                {
                    minWidth: 100
                }
        }
    }))


export default function ViewOffer(props) {
    const classes = useStyles();
    const isEmployer = useSelector(state => state.users.loggedUser ? state.users.loggedUser.isEmployer : false);
    const isProfessional = useSelector(state => state.users.loggedUser ? state.users.loggedUser.isProfessional : false);


    const seoTitle = props.match.params["seo"] ? props.match.params["seo"] : null;
    const processId = props.match.params["id"] ? parseInt(props.match.params["id"]) : null;

    const dispatch = useDispatch();
    let [offer, setOffer] = useState(null);

    let [applying, setApplying] = useState(false);
    let [incompletedAnswers, setIncompletedAnswers] = useState(false);
    let [successApply, setSuccessApply] = useState(false);
    let [successUnApply, setSuccessUnApply] = useState(false);
    let [deleteConfirmation, setDeleteConfirmation] = useState(false);
    let [errorApplyMessage, setErrorApplyMessage] = useState(null);


    useEffect(() => {
        loadOffer();
    }, [offer])

    function showError(error) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
    }
    
    function loadOffer(reload = false) {
        try {
            if (seoTitle && (offer === null || reload))
                return api.offers.getOfferBySeo(seoTitle).then(response => {
                    if (response.status === 200) {
                        setOffer(response.data);
                    }
                })

            if (processId && (offer === null || reload))
                return api.offers.getOffer(processId).then(response => {
                    if (response.status === 200) {
                        setOffer(response.data);
                    }
                })
        } catch (e) {
            showError(e.message)
        }
    }

    function handleChange(event, value, index) {
        setIncompletedAnswers(false);
        let copy = Object.assign({}, offer);
        if (copy.killerQuestions) {
            copy.killerQuestions[index].result = value;
            setOffer(copy);
        }
    }

    function handleChangeFreeAnswer(event, index) {
        setIncompletedAnswers(false);
        let copy = Object.assign({}, offer);
        if (copy.killerQuestions) {
            copy.killerQuestions[index].answer = event.target.value;
            setOffer(copy);
        }
    }

    function applyOffer() {
        setApplying(true);
        if (offer.killerQuestions.some(x => x.free === false && (x.result === null || x.result === undefined))
            || offer.killerQuestions.some(x => x.free && !x.answer)) {
            setIncompletedAnswers(true);
        } else {
            api.application.applyProcess({
                offerId: offer.id,
                answers: offer.killerQuestions.map(x => {
                    return {
                        answerId: x.id,
                        answer: x.answer,
                        result: x.result ? x.result : false,
                        id: x.id,
                        free: x.free
                    }
                })
            }).then(response => {
                if (response.status === 200) {
                    setSuccessApply(true);
                    setSuccessUnApply(false);
                    loadOffer(true);
                } else {
                    setErrorApplyMessage(response.data.error);
                }
            })
        }
        setApplying(false);
    }

    function unAppliedProcess() {
        api.application.unApplyProcess({offerId: offer.id}).then(response => {
            if (response.status === 200) {
                setDeleteConfirmation(false);
                loadOffer(true);
                setSuccessUnApply(true);
                setSuccessApply(false);
            }
        }).catch(x => setDeleteConfirmation(false))
    }


    function renderItemDescription(translate, value) {
        if (value) {
            return <div className="pt-4" style={{height: "min-content"}}>
                <Typography className={classes.title3} color="textSecondary">
                    <Translate id={translate}/>
                </Typography>
                <TextField className={classes.textField}
                           value={value}
                           multiline
                           disabled={true}
                />
            </div>
        } else {
            return ""
        }

    }

    const theme = useTheme();
    const screenMdOnly = useMediaQuery(theme.breakpoints.down('md'));
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    function renderItemRight(translate, value, textMultiple = false) {
        if (value) {
            return <div className="pt-3">
                <Typography variant="body2" color="textSecondary"
                            className="font-weight-bold py-1 pl-4"
                            style={{background: "#F7F7F7 0% 0% no-repeat padding-box"}}>
                    <Translate id={translate}/>
                </Typography>
                {textMultiple ?
                    <TextField className={`${classes.textField} pt-1  pl-4`}
                               value={value}
                               multiline
                               disabled={true}
                    /> :
                    <Typography variant="body2" color="textSecondary"
                                className="pt-1  pl-4">
                        {value}
                    </Typography>}

            </div>
        } else {
            return ""
        }

    }

    function renderQuestions(questions) {
        return <div>
            <div className="d-grid pt-4">
                <Typography className={classes.title3}
                            color="textSecondary">
                    <Translate id={"Offers.PreSelectionQuestionsTitle"}/>
                </Typography>
                <Divider className={`${classes.divider2} mt-2`}/>
            </div>
            {
                questions.map((question, index) => {
                    return <div>
                        <div
                            className={`${!question.free ? "d-md-flex d-lg-flex d-xl-flex flex-wrap" : ""} mt-4 justify-content-between align-items-center ${offer.appliedByProfessional ? classes.applied : ""}`}
                            style={{
                                minHeight: !question.free ? 50 : 'auto',
                                background: "#F7F7F7 0% 0% no-repeat padding-box"
                            }}>
                            <div className={`d-flex pl-4 ${isEmployer ? "py-3" : ""}`}>
                                <Typography variant="body2" color="textSecondary"
                                            className="font-weight-bold pr-1">
                                    {index + 1}.
                                </Typography>
                                <Typography variant="body2" color="textSecondary" className="font-weight-bold">
                                    {question.questionText}
                                </Typography>
                            </div>
                            {
                                !question.free
                                    ? <div className="px-4">
                                        {isEmployer
                                            ? <div className="d-flex align-items-center">
                                                <Typography variant="body2" color="textSecondary" className="pr-1">
                                                    <Translate id={"Offers.Yes"}/>
                                                </Typography>
                                                <div className={classes.circle}/>
                                                <Typography variant="body2" color="textSecondary" className="pr-1">
                                                    <Translate id={"Offers.No"}/>
                                                </Typography>
                                                <div className={classes.circle}/>
                                            </div>
                                            : isProfessional || !isEmployer
                                                ? <RadioGroup row aria-label="position" name="position" defaultValue="top"
                                                              className="flex-nowrap">
                                                    <FormControlLabel
                                                        value="start"
                                                        control={<Radio color="default"
                                                                        checked={question.result === true}
                                                                        onChange={(event) => handleChange(event, true, index)}
                                                                        value={<Translate id={"Offers.Yes"}/>}
                                                        />}
                                                        label={<Translate id={"Offers.Yes"}/>}
                                                        labelPlacement="start"
                                                    />
                                                    <FormControlLabel
                                                        value="start"
                                                        control={<Radio color="default"
                                                                        checked={question.result === false}
                                                                        onChange={(event) => handleChange(event, false, index)}
                                                                        value={<Translate id={"Offers.No"}/>}
                                                        />}
                                                        label={<Translate id={"Offers.No"}/>}
                                                        labelPlacement="start"
                                                    />
                                                </RadioGroup>
                                                : <div></div>}
                                    </div>
                                    : !isEmployer ? <div className="pl-4">
                                        <Translate>
                                            {({translate}) => {
                                                return <TextField
                                                    className={classes.responsiveWidth}
                                                    value={question.answer ? question.answer : ""}
                                                    defaultValue={question.answer ? question.answer : ""}
                                                    onChange={(event) => handleChangeFreeAnswer(event, index)}
                                                    inputProps={{
                                                        className: classes.padding0
                                                    }}
                                                    multiline
                                                    rows={4}
                                                    placeholder={translate("Offers.Professional.WriteAnswerHere")}/>
                                            }}
                                        </Translate>
                                    </div> : ""
                            }
                        </div>
                    </div>
                })
            }
        </div>
    }

    function renderBreadCrumb() {
        return isProfessional
            ? <React.Fragment>
                <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
                <BreadcrumbsItem to='/professional/workspace'><Translate
                    id={"Professional.ProfessionalZone"}/></BreadcrumbsItem>
                <BreadcrumbsItem to='/professional/workspace/some'><Translate
                    id={"Offers.Professional.SelectionProcess"}/></BreadcrumbsItem>
            </React.Fragment>
            : isEmployer
                ? <React.Fragment>
                    <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
                    <BreadcrumbsItem to='/employer/workspace'><Translate
                        id={"Employer.EmployerArea"}/></BreadcrumbsItem>
                    <BreadcrumbsItem to='/employer/workspace/some'>
                        <Translate id={"Offers.PreviewTitle"}/>
                    </BreadcrumbsItem>
                </React.Fragment>

                : <React.Fragment></React.Fragment>
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={`mt-4 ${classes.paddingXs}`}>
            {renderBreadCrumb()}
            <Grid item xs={12}>
                <BasicConfirmation
                    open={deleteConfirmation} type="primary"
                    title={<Translate id={"Offers.Professional.UnAppliedQuestion"}/>}
                    question={<Translate id={"Offers.Professional.UnAppliedConfirm"}/>}
                    handleCancel={() => setDeleteConfirmation(false)}
                    handleAccept={() => unAppliedProcess()}/>
            </Grid>

            {isEmployer
                ? <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                    <Typography className={classes.title} color="textSecondary">
                        <Translate id={"Offers.PreviewSelectionProcess"}/>
                    </Typography>
                    <Typography className={classes.completeSuggest} color="secondary">
                        <Translate id="Offers.HowProfessionalsYourPublication"/>
                    </Typography>
                </Grid>
                : <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                    <Typography className={classes.title} color="textSecondary">
                        <Translate id="Offers.Professional.SelectionProcess"/>
                    </Typography>
                </Grid>}

            {offer ? <Grid item xs={12} className="pt-sm-5">
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
                            <Paper square elevation={0} className={`${classes.paper} pb-3`}>
                                <Grid container direction="row"
                                      justify="center"
                                      alignItems="center">
                                    {offer.hiddenEnterprise ? "" :
                                        <Grid item xs={11} sm={10} md={8} className="pl-md-4 pb-4">
                                            <Grid container>
                                                <Grid item xs={12} md={6} className="d-flex pt-4">
                                                    {offer.enterprise && <Grid container>
                                                        <Grid item xs={2}>
                                                            {offer.enterprise ? offer.enterprise.logoUrl ?
                                                                <img alt={offer.enterprise.name} src={offer.enterprise.logoUrl}
                                                                     className={classes.img}/> :
                                                                <img alt={"Logo"} src={DefaultImage} className={classes.img}/> : ""
                                                            }
                                                        </Grid>
                                                        <Grid item xs={10} className="pl-4 pl-md-5">
                                                            <Typography
                                                                className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                                color="textSecondary">
                                                                {offer.enterprise ? offer.enterprise.name : ""}
                                                            </Typography>
                                                            <Typography
                                                                className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                                color="textSecondary">
                                                                {offer.enterprise && offer.enterprise.employerType ? offer.enterprise.employerType.name : ""}
                                                            </Typography>

                                                            <Typography
                                                                className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                                color="textSecondary">
                                                                {offer.enterprise && offer.enterprise.type ? offer.enterprise.type.name : ""}
                                                            </Typography>
                                                            <Typography
                                                                className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                                color="textSecondary">
                                                                {offer.enterprise && offer.enterprise.sector ? offer.enterprise.sector.name : ""}
                                                            </Typography>
                                                            {offer.enterprise && offer.enterprise.webUrl &&
                                                            <a href={offer.enterprise.webUrl} target={"_blank"}>
                                                                <Typography
                                                                    className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                                    color="textSecondary">
                                                                    {offer.enterprise ? offer.enterprise.webUrl : ""}
                                                                </Typography>
                                                            </a>}
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                                <Grid item xs={12} md={6} className={screenXsOnly ? "pt-4" : "pl-2 pt-4"}>
                                                    <Grid container className={screenSmUp ? "justify-content-end" : ""}>
                                                        <Grid item xs={12} md={9} lg={9} xl={10}>
                                                            {offer.enterprise && offer.enterprise.activityDescription
                                                                 ?
                                                                    <SimplePopover text={"Employer.DescriptionActivity"}
                                                                                   description={offer.enterprise && offer.enterprise.activityDescription
                                                                                       ? offer.enterprise.activityDescription : ""}/> :
                                                                    ""}
                                                            {offer.enterprise && offer.enterprise.policyManagementTalent
                                                                 ?
                                                                    <SimplePopover
                                                                        text={"Employer.TalentManagementPolicies"}
                                                                        description={offer.enterprise && offer.enterprise.policyManagementTalent
                                                                            ? offer.enterprise.policyManagementTalent : ""}/>
                                                                    : ""}
                                                            {offer.enterprise && offer.enterprise.awards ?
                                                                <SimplePopover
                                                                    text={"Employer.AwardsCertificatesCollaborations"}
                                                                    description={offer.enterprise && offer.enterprise.awards ? offer.enterprise.awards : ""}/>
                                                            : ""}

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    <Grid item xs={11} sm={10} md={8} className="pl-md-4">
                                        <Grid container>
                                            <Grid item xs={12}
                                                  style={offer.hiddenEnterprise ? {} : {borderTop: "1px solid #C1C1C3"}}
                                                  className={"py-3"}>
                                                <Typography className={classes.title2} color="secondary">
                                                    {offer.title && offer.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{borderTop: "1px solid #C1C1C3"}}
                                                  className={"py-3"}>
                                                <Grid container>
                                                    <Grid item xs={12} lg={9} xl={9}
                                                          className="d-flex flex-wrap pr-lg-4 pr-xl-5">
                                                        <Typography variant="body2" color="textSecondary">
                                                            <strong><Translate
                                                                id={"Offers.NoVacancies"}/>:</strong> {offer.cantPositions}
                                                        </Typography>
                                                        <Typography variant="body2" className="px-2"
                                                                    color="textSecondary">-</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            <strong><Translate
                                                                id={"Offers.Category"}/>:</strong> {offer.specialty && offer.specialty.category ? offer.specialty.category.name : ""}
                                                        </Typography>
                                                        <Typography variant="body2" className="px-2"
                                                                    color="textSecondary">-</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            <strong><Translate
                                                                id={"Offers.Specialty"}/>:</strong> {offer.specialty ? offer.specialty.name : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={9} xl={9}
                                                          className="d-flex flex-wrap pr-lg-4 pr-xl-5">
                                                        <Typography variant="body2" color="textSecondary">
                                                            {offer.municipality && offer.municipality.province && offer.municipality.province.country ? offer.municipality.province.country.name : ""}
                                                        </Typography>
                                                        <Typography variant="body2" className="px-2">-</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {offer.municipality && offer.municipality.province ? offer.municipality.province.name : ""}
                                                        </Typography>
                                                        <Typography variant="body2" className="px-2">-</Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {offer.municipality ? offer.municipality.name : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={3} xl={3} className={screenMdOnly ? "pt-3" : ""}>
                                                        <div className="d-flex">
                                                            <Typography variant="body2" color="textSecondary"
                                                                        className="font-weight-bold pr-2">
                                                                <Translate id={"Offers.ManagementState"}/>:
                                                            </Typography>
                                                            <Typography variant="body2" color="secondary"
                                                                        className="font-weight-bold pr-2">
                                                                {offer.state.name}
                                                            </Typography>
                                                        </div>
                                                        <Divider className={`${classes.divider} mt-2`}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} style={{borderTop: "1px solid #C1C1C3"}}
                                                  className={"py-3"}>
                                                <Grid container>
                                                    <Grid item xs={12} lg={9} xl={9}
                                                          className="pr-lg-4 pr-xl-5">
                                                        {renderItemDescription("Offers.Description", offer.description)}
                                                        {renderItemDescription("Offers.Features", offer.functions)}
                                                        {renderItemDescription("Offers.Requirements", offer.requirements)}
                                                        {renderItemDescription("Offers.OtherData", offer.otherData)}
                                                        {offer.killerQuestions.length > 0 ? renderQuestions(offer.killerQuestions) : ""}
                                                    </Grid>
                                                    <Grid item xs={12} lg={3} xl={3}>
                                                        <Typography color="textSecondary"
                                                                    className={`${classes.title3} font-weight-bold pt-4 pb-2`}>
                                                            <Translate id={"Offers.LaborConditions"}/>
                                                        </Typography>
                                                        <div className={classes.divBorder}>
                                                            {renderItemRight("Offers.EmploymentRelationship", offer.employmentRelationship ? offer.employmentRelationship.name : "")}
                                                            {renderItemRight("Offers.Duration", offer.employmentRelationshipDuration)}
                                                            {renderItemRight("Offers.Workday", offer.workday ? offer.workday.name : "")}
                                                            {renderItemRight("Offers.Schedule", offer.schedule)}
                                                            {renderItemRight("Offers.NetSalary", offer.salary)}
                                                            {renderItemRight("Offers.OtherBenefits", offer.otherBenefits, true)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}
                              className={`justify-content-center align-items-center ${classes.buttonContainer} ${screenXsOnly ? "mb-5" : "mt-5"}`}>
                            <div style={screenXsOnly ? {order: 2} : {}}>
                                <GoBack title={null} type="button" color={isProfessional ? "primary" : "secondary"}
                                        dialog={false}/>
                            </div>

                            {offer && offer.appliedByProfessional && isProfessional
                                ? <div style={screenXsOnly ? {order: 1} : {}}>
                                    <Button variant="outlined" color="error" type="button"
                                            className={`${classes.delete} m-3`}
                                            disabled={applying}
                                            classes={{label: classes.deleteLabel}}
                                            onClick={() => setDeleteConfirmation(true)}>
                                        <Translate id={"Offers.Professional.UnApplyButton"}/>
                                    </Button>
                                </div>

                                : isEmployer
                                    ? <div></div>
                                    : <div style={screenXsOnly ? {order: 1} : {}}>
                                        <Button variant="contained" color="primary" type="button"
                                                className={`${classes.submit} m-3`}
                                                disabled={applying}
                                                classes={{label: classes.submitLabel}}
                                                onClick={() => applyOffer()}>
                                            {applying && <CircularProgress size={30} className="mr-2" color="secondary"/>}
                                            <Translate id={"Offers.Professional.ApplyOfferButton"}/>
                                        </Button>
                                    </div>}
                        </Grid>

                        <Grid item xs={12}
                              className={`justify-content-center mb-5 text-center ${screenXsOnly ? "pb-5 " : ""}`}>
                            {incompletedAnswers && <Typography className={classes.error} color="error">
                                <Translate id="Offers.Professional.CompleteFieldsError"/>
                            </Typography>}

                            {successApply && <div>
                                <Typography className={classes.success}>
                                    <Translate id="Offers.Professional.SuccessApply"/>
                                </Typography>
                                <Typography className={`pt-2`} variant={"body2"} color="textSecondary">
                                    <Translate id="Offers.Professional.RememberCompletedProfile"/>
                                </Typography>
                            </div>}

                            {successUnApply && <Typography className={classes.success}>
                                <Translate id="Offers.Professional.SuccessUnApplied"/>
                            </Typography>}

                            {errorApplyMessage && <Typography className={classes.error} color="error">
                                {errorApplyMessage}
                            </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
                : <Grid item xs={12}>

                </Grid>
            }

        </Grid>
    );
}