import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow/Grow";
import Paper from "@material-ui/core/Paper/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { Translate } from "react-localize-redux";
import { withRouter, useHistory } from "react-router-dom";
import Popper from "@material-ui/core/Popper/Popper";
import IconButton from "@material-ui/core/IconButton";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneIcon from '@material-ui/icons/Phone';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { NavLink } from "react-router-dom";
import api from "../../api/index";
import * as actions from "../../actions/users/index";
import { useDispatch, useSelector } from "react-redux";
import EuroIcon from '@material-ui/icons/Euro';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

function EmployerMenu(props) {
    const useStyles = makeStyles((theme) => ({
        menu: {
            //position: props.isglobal ? "none" : "absolute",
            position: "absolute",
            right: "15%",
            zIndex: 2,
            [theme.breakpoints.only('xs')]: {
                //right: 15,
                //bottom: -10
                right: "17%",
                top: 6
            },
            [theme.breakpoints.only('sm')]: {
                top: "9%"
            },

        },
        button: {
            color: "#141414",
            [theme.breakpoints.only('xs')]: {
                padding: "12px 9px"
            },

        },
        menuItem: {
            borderLeft: `1px solid ${theme.palette.text.secondary}`,
            marginBottom: 12,
            marginTop: 12,
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.text.secondary,
            [theme.breakpoints.only('xs')]: {
                minHeight: 22
            },
            fontWeight: "bold"
        },
        popper: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 10px 21px #00000015",
            padding: "0 10px",
            opacity: 1,
            zIndex: 11
        }
    }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openPages, setOpenPages] = React.useState(false);

    const dispatch = useDispatch();
    let loguedUser = useSelector(state => state.users.loggedUser);

    let { isProfessional, isEmployer } = loguedUser ? loguedUser : { isProfessional: false, isEmployer: false };

    const anchorRef = React.useRef(null);
    const anchorPagesRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setOpenPages(false);
    };

    const handlePagesToggle = () => {
        setOpenPages((prevOpen) => !openPages);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handlePagesClose = (event) => {
        if (anchorPagesRef.current && anchorPagesRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    const prevPagesOpen = React.useRef(openPages);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        if (prevPagesOpen.current === true && openPages === false) {
            anchorPagesRef.current.focus();
        }

        prevOpen.current = open;
        prevPagesOpen.current = openPages;
    }, [open, openPages]);

    async function checCompletedProfile() {
        let response = { data: false };
        if (loguedUser.isEmployer) {
            response = await api.employers.checkCompletedProfile();
        } else if (loguedUser.isProfessional) {
            response = await api.professional.checkCompletedProfile();
        }
        return response.data;
    }

    async function goToProfile() {
        let compltedProfile = await checCompletedProfile();
        if (isEmployer) {
            if (compltedProfile) props.history.push("/employer/workspace");
            else props.history.push("/employer/edit-profile");
        } else if (isProfessional) {
            if (compltedProfile) props.history.push("/professional/workspace");
            else props.history.push("/professional/edit-profile");
        }
    }

    function handleLogout() {
        dispatch(actions.logoutUser())
        props.history.push("/")
    }

    return (<Translate>
        {({ translate }) => {
            return <div className={classes.menu}>
                { /* <Tooltip
                    title="+34 626 969 750"
                    placement="top">
                    <IconButton disableRipple disableFocusRipple color="primary">
                        <PhoneIcon />
                        <span>+34 626 969 750</span>
                    </IconButton>
                </Tooltip> */}

                <Tooltip
                    title={isEmployer ? translate("Employer.EmployerArea") : translate("Professional.ProfessionalZone")}
                    placement="top">
                    <IconButton
                        onClick={goToProfile}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        className={classes.button}>
                        <PermIdentityIcon />
                    </IconButton>
                </Tooltip>

                <IconButton
                    ref={anchorPagesRef}
                    onClick={handlePagesToggle}
                    aria-label="tips"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    className={classes.button}>
                    <AddCircleOutlinedIcon />
                </IconButton>

                {isEmployer && <NavLink to={"/employer/pricing"}>
                    <Tooltip title={translate("Offers.Pricing")} placement="top">
                        <IconButton
                            aria-haspopup="true"
                            className={classes.button}>
                            <EuroIcon />
                        </IconButton>
                    </Tooltip>
                </NavLink>}

                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button}>
                    <SettingsOutlinedIcon />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper elevation={0} square>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow"
                                        onKeyDown={handleListKeyDown} className="p-0">
                                        <NavLink
                                            to={isEmployer ? "/employer/edit-profile" : isProfessional ? "/professional/edit-profile" : handleLogout()}>
                                            <MenuItem onClick={handleClose} className={classes.menuItem}>
                                                <Translate id={"Menus.EditAccount"} />
                                            </MenuItem>
                                            <MenuItem className={classes.menuItem}>
                                                <NavLink to="/professional/selfchange-password">
                                                    <Typography color="primary" style={{ fontWeight: "bold" }} >
                                                        <Translate id="Employer.ChangePassword" />
                                                    </Typography>
                                                </NavLink>
                                            </MenuItem>
                                        </NavLink>

                                        {isEmployer && <MenuItem
                                            onClick={() => {
                                                props.history.push("/employer/payments")
                                            }} className={classes.menuItem}>
                                            <Translate id={"Payments.PaymentsBilling"} />
                                        </MenuItem>}

                                        <MenuItem onClick={handleClose} className={classes.menuItem}
                                            onClick={handleLogout}>
                                            <Translate id={"Menus.Exit"} />
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>


                <Popper open={openPages} anchorEl={anchorPagesRef.current} role={undefined} transition
                    disablePortal className={classes.popper}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper elevation={0} square>
                                <ClickAwayListener onClickAway={handlePagesClose}>
                                    <MenuList autoFocusItem={openPages}
                                        onKeyDown={() => {
                                        }} className="p-0">
                                        <NavLink to={isEmployer ? "/tips/employers" : "/tips/professional"}>
                                            <MenuItem onClick={handlePagesClose} className={classes.menuItem}>
                                                <Translate id={"Buttons.Tips"} />
                                            </MenuItem>
                                        </NavLink>

                                        <NavLink to={isEmployer ? "/benefits/employers" : "/benefits/professional"}>
                                            <MenuItem onClick={handlePagesClose} className={classes.menuItem}>
                                                <Translate id={"Buttons.Benefits"} />
                                            </MenuItem>
                                        </NavLink>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        }}
    </Translate>);
}

export default withRouter(EmployerMenu);