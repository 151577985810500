import * as idb from "./idbManager";

export const PROFESSIONAL_INTERVIEW = 'professional-interview';
export const PROFESSIONAL_INTERVIEW_SEGMENTS = 'professional-interview-segments';
export const PROFESSIONAL_INTERVIEW_POSTER = 'professional-interview-poster';

export const PROFESSIONAL_VIDEO_SPEAKING_TEST = 'professional-speaking-test';
export const PROFESSIONAL_VIDEO_SPEAKING_TEST_SEGMENTS = 'professional-speaking-test-segments'


export async function saveItem(key, value) {
    return idb.set(key, value);
}

export async function getItem(key) {
    return idb.get(key);
}

export async function removeItem(key) {
   return idb.del(key);
}

export async function clear() {
    return idb.clear();
}