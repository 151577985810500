import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Typography, Container, useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { staticContent } from "../../../utils/constants";
import PricingCard from "./pricingCard";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.secondary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left"
        },
        marginBottom: 0
    },
    hr: {
        width: 240,
        height: 1,
        backgroundColor: theme.palette.secondary.main,
        margin: 0,
        marginTop: 10,
        marginLeft: 15,
        [theme.breakpoints.only('xl')]: {
            marginLeft: 57
        },
        [theme.breakpoints.only('sm')]: {
            width: 280,
            marginLeft: 25
        },
        [theme.breakpoints.only('xs')]: {
            width: 206,
            marginLeft: 0
        }
    },
    titleEmployer: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.secondary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left"
        },
    },
    containerBox: {
        paddingBottom: 25
    },
    boxContent: {},
    container: {
        padding: "10px 24px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },

    },
}));

function Pricing(props) {
    const history = useHistory();
    let classes = useStyles();
    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));

    const scope = props.match.params["scope"] ? props.match.params["scope"] : "";
    let [pricingData, setPricingData] = useState(null);
    let [plans, setPlans] = useState(null);

    useEffect(() => {
        history.push('/');
    }, []);

    useEffect(() => {
        api.home.getContent(staticContent.Pricing).then(response => {
            setPricingData(response);
        });
        loadPlans();
    }, [pricingData, plans])

    function loadPlans() {
        if (plans === null) {
            api.plans.all().then(response => {
                if (response.status === 200) {
                    setPlans(response.data);
                }
            })
        }
    }


    function renderBreadCrumb() {
        return (<React.Fragment>
            <BreadcrumbsItem weight={1} to='/'><Translate id={"Titles.Start"} /></BreadcrumbsItem>
            <BreadcrumbsItem weight={2} to='/employer/workspace'><Translate id={"Offers.Pricing"} /></BreadcrumbsItem>
        </React.Fragment>)
    }

    return (pricingData ? <Grid container spacing={0}>
        {renderBreadCrumb()}
        <Grid item xs={12}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <h1 className={`${classes.title}`} color="secondary">{pricingData.Title} </h1>
                        <hr className={classes.hr} />
                    </Grid>
                </Grid>


                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center">
                    {pricingData.Plans.map((data, index) => {
                        return (<Grid className={classes.boxContent} item xs={12} sm={6} md={4}>
                            <PricingCard
                                data={data} index={index + 1}
                                plan={plans ? plans.find(x => x.code === data.Code) : null} />
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Grid>
    </Grid> : <Grid></Grid>);
}

export default withRouter(Pricing);