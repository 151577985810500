import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Tooltip, Modal, Backdrop, Fade } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { useMediaQuery } from "@material-ui/core";
import {
  PhoneOutlined,
  WhatsApp,
  MailOutlineOutlined,
  LocationOnOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import {
  LinkedIn,
  Facebook,
  Twitter,
  Instagram,
  Pinterest,
  YouTube,
} from "@material-ui/icons";
import {
  FormControl,
  Hidden,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from "@material-ui/core";
import GoBack from "../../navigation/goBack";
import moment from "moment";
import { NavLink, Link } from "react-router-dom";
import Preview from "./preview";
import CustomConfirmation from "../../confirmation/customConfirmation";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import MockedImage from "../../../images/videospeaking/mocked-profile.png";
import ModalPay from "../../payments/modalPay";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => {
  return {
    title1: {
      fontSize: 35,
      fontWeight: 600,
      lineHeight: 1.3,
      marginTop: 20,
      [theme.breakpoints.only("xs")]: {
        fontSize: 30,
        marginTop: 0,
        lineHeight: 1,
      },
      color: theme.palette.text.secondary,
    },
    topPaper: {
      marginTop: 35,
      padding: "24px 26px",
      boxShadow: "0px 0px 25px #0000001A",
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
        padding: 15,
      },
    },
    downPaper: {
      marginTop: 35,
      padding: "28px 60px",
      boxShadow: "0px 0px 25px #0000001A",
      paddingBottom: 47,
      [theme.breakpoints.only("xs")]: {
        boxShadow: "none",
        padding: 0,
        marginTop: 0,
      },
    },
    languagePaper: {
      marginTop: 25,
      padding: "25px 35px",
      boxShadow: "0px 0px 25px #0000001A",
      [theme.breakpoints.only("xs")]: {
        boxShadow: "none",
        padding: 0,
        marginTop: 0,
      },
    },
    profilePaper: {
      marginTop: 25,
      padding: 35,
      boxShadow: "0px 0px 25px #0000001A",
      [theme.breakpoints.only("xs")]: {
        boxShadow: "none",
        padding: 0,
        marginTop: 0,
        "& .flex-item:nth-of-type(2)": {
          order: -1,
        },
      },
    },
    caption: {
      fontWeight: 600,
      fontSize: 25,
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    subCaption: {
      fontWeight: 700,
      fontSize: 18,
      [theme.breakpoints.only("xs")]: {
        fontSize: 17,
      },
    },
    interviewCaption: {
      fontWeight: 700,
      fontSize: 17,
      textAlign: "center",
    },
    flex: {
      display: "flex",
      [theme.breakpoints.only("xs")]: {
        display: "block",
      },
    },
    separator: {
      width: 88,
      border: "1px solid #141414",
      margin: 0,
      [theme.breakpoints.only("xs")]: {
        width: 90,
      },
    },
    professionalSubTitle: {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    box: {
      padding: "25px 32px",
      backgroundColor: "#F7F7F7",
      height: "100%",
      flexWrap: "wrap",
      [theme.breakpoints.only("xs")]: {
        padding: 15,
      },
    },
    boxStates: {
      padding: "20px 32px",
      backgroundColor: "#F7F7F7",
      height: "auto",
      [theme.breakpoints.only("xs")]: {
        padding: 15,
      },
    },
    boxContact: {
      padding: "20px 32px",
      backgroundColor: "#F7F7F7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "91%",
      [theme.breakpoints.only("xs")]: {
        padding: 15,
      },
    },
    profileText: {
      fontSize: 16,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    profileTextRegular: {
      fontSize: 16,
      fontWeight: 400,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    wrap: {
      wordBreak: "break-word",
    },
    link: {
      color: "#141414",
      "&:hover": {
        color: "#141414",
      },
    },
    social: {
      backgroundColor: "#141414",
      borderRadius: 50,
    },
    infoSection: {
      paddingLeft: 35,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 20,
        paddingLeft: 0,
      },
    },
    languageBox: {
      textAlign: "center",
      paddingLeft: 15,
      paddingRight: 15,
      cursor: "pointer",
    },
    languageText: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: 12,
    },
    opacity: {
      opacity: 0.4,
    },
    dialogTitle: {
      textAlign: "center",
      fontSize: 18,
      fontWeight: 600,
      padding: 0,
      lineHeight: 1.3,
      [theme.breakpoints.only("xs")]: {
        fontSize: 16,
      },
    },
    content: {
      marginBottom: 10,
      fontFamily: "Barlow",
      [theme.breakpoints.only("xs")]: {
        marginBottom: 0,
      },
    },
    panelSubtitle: {
      fontSize: 16,
      fontWeight: 700,
      marginTop: 20,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    stateLabel: {
      fontSize: 16,
      fontWeight: 600,
    },
    buttonLabel: {
      color: "#141414",
      fontWeight: 700,
      fontSize: 18,
    },
    button: {
      marginBottom: 28,
      width: 270,
      borderRadius: 0,
    },
    infoMovil: {
      border: "1px solid #141414",
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 270,
      fontSize: 18,
      fontWeight: 600,
    },
    responsiveWidth: {
      width: "100%",
      "& .MuiInput-multiline": {
        marginTop: "0 !important",
      },
    },
    badge: {
      width: 25,
      height: 25,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 50,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#141414",
      fontWeight: 700,
      fontSize: 14,
      marginRight: 10,
      marginTop: 3,
    },
    answerBox: {
      display: "flex",
    },
    rightAnswer: {
      marginLeft: 35,
    },
    boxXs: {
      padding: 15,
      [theme.breakpoints.only("xs")]: {
        backgroundColor: "#F7F7F7",
      },
    },
    buttonValuation: {
      marginRight: 30,
      borderRadius: 0,
      [theme.breakpoints.only("xs")]: {
        marginRight: 0,
        marginBottom: 20,
      },
    },
    btn: {
      borderRadius: 0,
    },
    centerXs: {
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    padding0: {
      padding: 0,
    },
    paddingXs0: {
      [theme.breakpoints.only("xs")]: {
        padding: 0,
      },
    },
    flag: {
      width: 36,
      height: 36,
    },
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    responsive: {
      width: "100%",
      height: "auto",
      maxWidth: 376,
      maxHeight: 376,
    },
    textField: {
      "& .MuiInputBase-root.Mui-disabled": {
        border: "none",
        backgroundColor: "transparent",
        margin: "0 !important",
        padding: 0,
        minWidth: 100,
      },
      "& .MuiInput-inputMultiline": {
        padding: 0,
        backgroundColor: "transparent",
      },
      "&.MuiFormControl-root": {
        width: "100%",
      },
      "& .MuiInputBase-input": {
        minWidth: 100,
      },
      "& .MuiInput-root": {
        minHeight: 0,
      },
    },
    blur: {
      filter: " blur(.5rem)",
      WebkitFilter: "blur(.5rem)",
    },
    modal: {
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1rem solid #FFB170",
      borderRadius: ".2rem",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  };
});

function BootstrapTooltip(props) {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.white,
      "&:before": {
        border: `1px solid #141414`,
      },
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "#141414",
      border: `1px solid #141414`,
      padding: 2,
      lineHeight: 1,
      maxWidth: 100,
    },
  }));
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} role="button" />;
}

function ProfessionalProfile(props) {
  const classes = useStyles();
  const theme = useTheme();
  const screenMdDow = useMediaQuery(theme.breakpoints.down("md"));
  const xsOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const professionalId = props.match.params["id"]
    ? parseInt(props.match.params["id"])
    : null;
  const applicationId = props.match.params["process"]
    ? parseInt(props.match.params["process"])
    : null;

  let [application, setApplication] = useState(null);
  let [process, setProcess] = useState(null);
  let [professional, setProfessional] = useState(null);
  let [languagesTests, setLanguagesTests] = useState(null);
  let [applicationStates, setApplicationStates] = useState(null);
  let [languages, setLanguages] = useState(null);
  let [answers, setAnswers] = useState([]);
  let [processing, setProcessing] = useState(false);

  let [requestTest, setRequestTest] = useState(false);
  let [payTest, setPayTest] = useState(false);
  let [selectedTest, setSelectedTest] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  let [employerPurchases, setEmployerPurchases] = useState({
    languageTestCount: 0,
  });
  const u = useSelector((state) => state.users.offerId);

  let [isBlur, setIsBlur] = useState(false);

  useEffect(() => {
    // console.info("ProfessionalProfile", u);
    loadApplication();
    //loadLanguageTest();
    loadApplicationStates();
    loadLAnguages();
    api.purchases
      .hasViewOffers()
      .then((res) => {
        if (res.status === 200) {
          setIsBlur(!res.data);
          setModalOpen(!res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    api.payments.getEmployerPayments().then((res) => {
      if (res.status === 200) {
        res.data.payments.forEach((payment) => {
          if (payment.offerId !== u) {
            setIsBlur(true);
          }
        });
      } else {
        console.info(res);
      }
    });
  }, []);

  const resolveLanguageImage = (isoCode) => {
    try {
      return require(`../../../images/professional/languages/${isoCode}.png`);
    } catch (e) {
      return null;
    }
  };

  function handleRequestTest(languageTest) {
    setSelectedTest(languageTest);
    if (languageTest.paid) {
      props.history.push(
        `/speaking/viewer/${languageTest.id}/${professionalId}/${applicationId}`
      );
    } else {
      if (employerPurchases.languageTestCount > 0) setRequestTest(true);
      else setPayTest(true);
    }
  }

  function handleAcquireTest() {
    props.history.push("/employer/workspace/2");
  }

  function handleConsumeTest() {
    api.languageTest
      .viewLanguageTest(professionalId, selectedTest.id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data === true) {
            props.history.push(
              `/speaking/viewer/${selectedTest.id}/${professionalId}/${applicationId}`
            );
          } else {
            alert("a pagar");
          }
        }
      });
  }

  function handleChangeApplicationState(event) {
    if (application.state) {
      setApplication((app) => ({
        ...application,
        state: { ...application.state, id: parseInt(event.target.value) },
      }));
    }
  }

  const handleChangeValuation = (event) => {
    setApplication({ ...application, valuation: event.target.value });
  };

  function handleSaveApplication() {
    setProcessing(true);
    api.application
      .changeStateProcess({
        processId: applicationId,
        stateId: application.state.id,
        valuation: application.valuation,
      })
      .then((response) => {
        if (response.status === 200) loadApplication();
        setProcessing(false);
      });
  }

  function loadApplication() {
    if (!application && applicationId >= 0)
      api.application.getApplication(applicationId).then((response) => {
        if (response.status === 200) {
          let { process, professional, answer, employerPurchases } =
            response.data;
          setApplication(response.data);
          console.debug("application");
          console.debug(response.data);
          setProcess(process);
          setProfessional(professional);
          setAnswers(answer);
          setEmployerPurchases(employerPurchases);

          setLanguagesTests(professional.languageTests);
        }
      });
  }

  function loadApplicationStates() {
    if (!applicationStates)
      api.applicationStates.all().then((response) => {
        if (response.status === 200) {
          setApplicationStates(response.data);
        }
      });
  }

  function loadLAnguages() {
    if (!languages)
      api.language.getAllLanguages().then((response) => {
        if (response.status === 200) {
          setLanguages(response.data);
        }
      });
  }

  const resolveUrl = (url) => {
    if (!url) return "";
    if (url.indexOf("https://") > -1) return url;
    return `https://${url}`;
  };

  const renderProfessionalInfo = () => {
    return (
      <Translate>
        {({ translate }) => {
          return (
            <Paper elevation={2} className={classes.profilePaper}>
              <Grid container spacing={4}>
                <Grid className="flex-item" item xs={12} md={4}>
                  <div className={classes.box}>
                    {isBlur ? (
                      <>
                        <Typography
                          color="secondary"
                          className={classes.subCaption}
                        >
                          {translate("Professional.Profile.AbstractTitle")}
                        </Typography>
                        <div className={classes.blur}>
                          <Typography
                            color="textSecondary"
                            className={`${classes.profileText} mt-2`}
                          >
                            Lorem ipsum dolor sit amet
                          </Typography>
                          {professional.phoneNumber && (
                            <div className="d-flex mt-2">
                              <PhoneOutlined />
                              <Typography
                                color="textSecondary"
                                className={`${classes.profileTextRegular} ml-1`}
                              >
                                00T00-0I00-0E0L
                              </Typography>
                              <WhatsApp fontSize="small" />
                            </div>
                          )}

                          {professional.email && (
                            <div className="d-flex mt-2">
                              <MailOutlineOutlined />
                              <Typography
                                color="textSecondary"
                                className={`${classes.profileTextRegular} ml-1`}
                              >
                                example@email.com
                              </Typography>
                            </div>
                          )}

                          <div className="d-flex mt-2">
                            <LocationOnOutlined />
                            <Typography
                              className={`${classes.profileTextRegular} ml-1`}
                              color="textSecondary"
                            >
                              Lorep Ipsum
                            </Typography>
                            <Typography
                              className={`${classes.profileTextRegular} mx-1`}
                              color="textSecondary"
                            >
                              Lorem
                            </Typography>
                            <Typography
                              className={classes.profileTextRegular}
                              color="textSecondary"
                            >
                              Lorem Ipsum
                            </Typography>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Typography
                          color="secondary"
                          className={classes.subCaption}
                        >
                          {translate("Professional.Profile.AbstractTitle")}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          className={`${classes.profileText} mt-2`}
                        >{`${professional.firstName} ${professional.lastName} ${professional.surName}`}</Typography>
                        {professional.phoneNumber && (
                          <div className="d-flex mt-2">
                            <PhoneOutlined />
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileTextRegular} ml-1`}
                            >
                              {professional.phoneNumber}
                            </Typography>
                            <WhatsApp fontSize="small" />
                          </div>
                        )}

                        {professional.email && (
                          <div className="d-flex mt-2">
                            <MailOutlineOutlined />
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileTextRegular} ml-1`}
                            >
                              {professional.email}
                            </Typography>
                          </div>
                        )}

                        <div className="d-flex mt-2">
                          <LocationOnOutlined />
                          <Typography
                            className={`${classes.profileTextRegular} ml-1`}
                            color="textSecondary"
                          >
                            {professional.municipality &&
                            professional.municipality.province
                              ? professional.municipality.province.name
                              : ""}
                          </Typography>
                          <Typography
                            className={`${classes.profileTextRegular} mx-1`}
                            color="textSecondary"
                          >
                            {professional.municipality &&
                            professional.municipality.province
                              ? "-"
                              : ""}
                          </Typography>
                          <Typography
                            className={classes.profileTextRegular}
                            color="textSecondary"
                          >
                            {professional.municipality
                              ? professional.municipality.name
                              : ""}
                          </Typography>
                        </div>
                      </>
                    )}
                    <Typography
                      color="textSecondary"
                      className={`${classes.profileText} mt-2`}
                    >
                      {translate("Professional.Profile.DescriptiveTitle")}
                    </Typography>
                    <li>
                      <Typography
                        component="span"
                        color="textSecondary"
                        className={classes.profileTextRegular}
                      >
                        {professional.professionTitle}
                      </Typography>
                    </li>
                    {professional.academicTrainingHigher != null && (
                      <>
                        <Typography
                          color="textSecondary"
                          className={`${classes.profileText} mt-2`}
                        >
                          {translate("Professional.HigherLevelTraining")}
                        </Typography>
                        <li>
                          <Typography
                            component="span"
                            color="textSecondary"
                            className={classes.profileTextRegular}
                          >
                            {professional.academicTrainingHigher}
                          </Typography>
                        </li>
                      </>
                    )}
                    {professional.moreImportantWork != null && (
                      <>
                        <Typography
                          color="textSecondary"
                          className={`${classes.profileText} mt-2`}
                        >
                          {translate("Professional.Profile.MoreImportantWork")}
                        </Typography>
                        <TextField
                          className={classes.textField}
                          value={professional.moreImportantWork}
                          multiline
                          disabled={true}
                        />
                      </>
                    )}
                    {isBlur ? (
                      <div>
                        <div
                          className={"d-flex mt-2" + " " + classes.blur}
                          role="button"
                        >
                          <VisibilityOutlined />
                          <Typography
                            color="textSecondary"
                            className={`${classes.profileText} ${classes.wrap} ml-1`}
                          >
                            {translate("Professional.Profile.Cv")}
                          </Typography>
                        </div>
                      </div>
                    ) : professional.cvUrl ? (
                      <div>
                        {professionalId === 0 ? (
                          <div
                            className="d-flex mt-2"
                            style={{ color: "#141414" }}
                          >
                            <VisibilityOutlined color="textSecondary" />
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileText} ${classes.wrap} ml-1`}
                            >
                              {translate("Professional.Profile.Cv")}
                            </Typography>
                          </div>
                        ) : (
                          <a
                            target="_blank"
                            href={api.professional.resolveFile(
                              professional.id,
                              professional.cvUrl.split("/").pop()
                            )}
                            className={classes.link}
                          >
                            <div className="d-flex mt-2" role="button">
                              <VisibilityOutlined />
                              <Typography
                                color="textSecondary"
                                className={`${classes.profileText} ${classes.wrap} ml-1`}
                              >
                                {translate("Professional.Profile.Cv")}
                              </Typography>
                            </div>
                          </a>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {professional.referencesUrl ? (
                      <div>
                        {professionalId === 0 ? (
                          <div
                            className="d-flex mt-2"
                            style={{ color: "#141414" }}
                          >
                            <VisibilityOutlined color="textSecondary" />
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileText} ${classes.wrap} ml-1`}
                            >
                              {translate("Professional.Profile.References")}
                            </Typography>
                          </div>
                        ) : (
                          <a
                            target="_blank"
                            href={api.professional.resolveFile(
                              professional.id,
                              professional.referencesUrl.split("/").pop()
                            )}
                            className={classes.link}
                          >
                            <div className="d-flex mt-2" role="button">
                              <VisibilityOutlined />
                              <Typography
                                color="textSecondary"
                                className={`${classes.profileText} ${classes.wrap} ml-1`}
                              >
                                {translate("Professional.Profile.References")}
                              </Typography>
                            </div>
                          </a>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>

                <Grid className="flex-item" item xs={12} md={4}>
                  <div className={classes.box} style={{ textAlign: "center" }}>
                    <Typography
                      color="textSecondary"
                      className={classes.interviewCaption}
                    >
                      {translate("Professional.Profile.Interview")}
                    </Typography>
                    {applicationId ? (
                      <Preview
                        videoUrl={professional.interviewUrl}
                        application={application}
                      />
                    ) : (
                      <img
                        title={
                          professionalId === 0
                            ? "Ejemplo perfil. Videoentrevista grabada por el profesional"
                            : `${professional.firstName} ${professional.lastName} ${professional.surName}`
                        }
                        alt={
                          professionalId === 0
                            ? "Ejemplo perfil. Videoentrevista grabada por el profesional"
                            : `${professional.firstName} ${professional.lastName} ${professional.surName}`
                        }
                        src={MockedImage}
                        className={classes.responsive}
                      />
                    )}
                  </div>
                </Grid>

                <Grid className="flex-item" item xs={12} md={4}>
                  <div className={classes.box}>
                    <Typography
                      color="secondary"
                      className={classes.subCaption}
                    >
                      {translate("Professional.Profile.OtherData")}
                    </Typography>

                    {isBlur ? (
                      <p className={classes.blur}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum ut sem eros. Nam rutrum ultricies risus, id
                        bibendum ante interdum ac.
                      </p>
                    ) : (
                      <>
                        {professional.socialCollaborations ? (
                          <div>
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileText}`}
                            >
                              {translate(
                                "Professional.SocialEnvironmentalCollaborations"
                              )}
                            </Typography>
                            <TextField
                              className={classes.textField}
                              value={professional.socialCollaborations}
                              multiline
                              disabled={true}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {professional.awardUrl ? (
                          <div>
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileText} mt-1`}
                            >
                              {translate("Professional.AchievementsAwards")}
                            </Typography>
                            <a
                              href={api.professional.resolveFile(
                                professional.id,
                                professional.awardUrl.split("/").pop()
                              )}
                              className={classes.responsiveWidth}
                              target="_blank"
                            >
                              {professional.awardUrl.split("/").pop()}
                            </a>
                          </div>
                        ) : (
                          <div>
                            {professional.awards ? (
                              <div>
                                <Typography
                                  color="textSecondary"
                                  className={`${classes.profileText} mt-1`}
                                >
                                  {translate("Professional.AchievementsAwards")}
                                </Typography>
                                <TextField
                                  className={classes.textField}
                                  value={professional.awards}
                                  multiline
                                  disabled={true}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}

                        {professional.curiosities ? (
                          <div>
                            <Typography
                              color="textSecondary"
                              className={`${classes.profileText} mt-1`}
                            >
                              {translate("Professional.Curiosities")}
                            </Typography>
                            <TextField
                              className={classes.textField}
                              value={professional.curiosities}
                              multiline
                              disabled={true}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {professional.socialMediaProfile ? (
                          professional.socialMediaProfile.map((x) => {
                            if (!x.profile) return <div></div>;
                            if (professionalId === 0) {
                              return (
                                <div className="d-flex mt-2">
                                  <img
                                    alt={x.profile}
                                    src={x.imageUrl}
                                    style={{ width: 24, height: 24 }}
                                  />
                                  <Typography
                                    color="textSecondary"
                                    className={`${classes.profileTextRegular} ${classes.wrap} ml-1`}
                                  >
                                    {x.profile}
                                  </Typography>
                                </div>
                              );
                            } else {
                              return (
                                <a
                                  role="button"
                                  target="_blank"
                                  href={resolveUrl(x.profile)}
                                  className={classes.link}
                                >
                                  <div className="d-flex mt-2" role="button">
                                    <img
                                      alt={x.profile}
                                      src={x.imageUrl}
                                      style={{ width: 24, height: 24 }}
                                    />
                                    <Typography
                                      color="textSecondary"
                                      className={`${classes.profileTextRegular} ${classes.wrap} ml-1`}
                                    >
                                      {x.profile}
                                    </Typography>
                                  </div>
                                </a>
                              );
                            }
                          })
                        ) : (
                          <div></div>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          );
        }}
      </Translate>
    );
  };

  const renderLanguageSection = () => {
    return (
      <Translate>
        {({ translate }) => {
          return (
            languagesTests && (
              <Grid container className="mt-4">
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Grid item xs={12} className={classes.infoSection}>
                        <div>
                          <Typography
                            className={classes.professionalSubTitle}
                            color="textSecondary"
                          >
                            <Translate
                              id={"Professional.Profile.LanguageTest"}
                            />
                          </Typography>
                        </div>
                        <hr className={classes.separator} />
                      </Grid>
                      <Paper elevation={2} className={classes.languagePaper}>
                        <Typography
                          color="secondary"
                          className={classes.subCaption}
                        >
                          {translate("Professional.Profile.Tests")}
                        </Typography>
                        <div
                          className={`${classes.box} mt-2 d-flex ${
                            applicationId === 0 ? classes.disabled : ""
                          }`}
                        >
                          {languagesTests &&
                            languagesTests
                              .filter((x) => x.completed)
                              .map((x) => {
                                return (
                                  <div
                                    className={`${classes.languageBox} ${
                                      !x.paid ? classes.opacity : ""
                                    }`}
                                    role="button"
                                  >
                                    <BootstrapTooltip
                                      title={
                                        x.paid
                                          ? translate(
                                              "Professional.Profile.SeeTest"
                                            )
                                          : translate(
                                              "Professional.Profile.RequestReviewing"
                                            )
                                      }
                                    >
                                      <img
                                        title={`Videospeaking en ${x.name}`}
                                        alt={`Videospeaking en ${x.name}`}
                                        src={resolveLanguageImage(x.isoCode)}
                                        role="button"
                                        className={classes.flag}
                                        onClick={() => handleRequestTest(x)}
                                      />
                                    </BootstrapTooltip>
                                    <Typography
                                      className={classes.languageText}
                                      color="textSecondary"
                                    >
                                      {x.name}
                                    </Typography>
                                  </div>
                                );
                              })}
                        </div>
                      </Paper>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Grid item xs={12} className={classes.infoSection}>
                        <div>
                          <Typography
                            className={classes.professionalSubTitle}
                            color="textSecondary"
                          >
                            <Translate
                              id={"Professional.Profile.PreselectionText"}
                            />
                          </Typography>
                        </div>
                        <hr className={classes.separator} />
                      </Grid>
                      <Paper
                        elevation={2}
                        className={`${classes.languagePaper} ${classes.boxXs}`}
                      >
                        {answers.map((x, i) => {
                          return (
                            <div>
                              <Grid className={classes.answerBox} item xs={12}>
                                <div className={classes.badge}>{i + 1}</div>
                                <Typography
                                  color="secondary"
                                  className={classes.subCaption}
                                >
                                  {x.questionText}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  color="textSecondary"
                                  className={`${classes.profileTextRegular} ${classes.rightAnswer}`}
                                >
                                  {x.answer}
                                </Typography>
                              </Grid>
                            </div>
                          );
                        })}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          );
        }}
      </Translate>
    );
  };

  const renderValuation = () => {
    return (
      <Translate>
        {({ translate }) => {
          return (
            <React.Fragment>
              <Typography
                color="textSecondary"
                className={classes.panelSubtitle}
              >
                {translate("Professional.Profile.ProfessionalValoration")}
              </Typography>
              <div
                className={`${classes.boxStates} ${classes.paddingXs0} mt-2`}
              >
                <TextField
                  className={classes.responsiveWidth}
                  value={application.valuation}
                  onChange={handleChangeValuation}
                  inputProps={{
                    className: classes.padding0,
                  }}
                  multiline
                  rows={4}
                />
              </div>
              <div className={`${classes.centerXs} pt-4`}>
                <Button
                  className={classes.buttonValuation}
                  classes={{ label: classes.buttonLabel }}
                  variant="contained"
                  color="secondary"
                  onClick={handleSaveApplication}
                  disabled={processing}
                >
                  {processing && (
                    <CircularProgress
                      color="primary"
                      size={30}
                      className="mr-2"
                    />
                  )}
                  {translate("Buttons.Save")}
                </Button>
              </div>
            </React.Fragment>
          );
        }}
      </Translate>
    );
  };

  const renderPanelManager = () => {
    return (
      <Translate>
        {({ translate }) => {
          return applicationId ? (
            <Grid container className="mt-4">
              <Grid item xs={12} className={classes.infoSection}>
                <div>
                  <Typography
                    className={classes.professionalSubTitle}
                    color="textSecondary"
                  >
                    <Translate id={"Professional.Profile.PanelManagerTitle"} />
                  </Typography>
                </div>
                <hr className={classes.separator} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <Paper elevation={2} className={classes.languagePaper}>
                      <Grid container spacing={4}>
                        <Grid item md={8} xs={12}>
                          <Hidden xsDown>
                            <Typography
                              color="secondary"
                              className={classes.subCaption}
                            >
                              {translate(
                                "Professional.Profile.ProfessionalEvaluation"
                              )}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              className={classes.panelSubtitle}
                            >
                              {translate(
                                "Professional.Profile.EvaluationState"
                              )}
                            </Typography>
                          </Hidden>

                          <div className={`${classes.boxStates} mt-2`}>
                            <Hidden smUp>
                              <Typography
                                color="secondary"
                                className={classes.subCaption}
                              >
                                {translate(
                                  "Professional.Profile.ProfessionalEvaluation"
                                )}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                className={classes.panelSubtitle}
                              >
                                {translate(
                                  "Professional.Profile.EvaluationState"
                                )}
                              </Typography>
                            </Hidden>

                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                value={
                                  application.state ? application.state.id : -1
                                }
                                className="mb-0"
                                onChange={handleChangeApplicationState}
                              >
                                {applicationStates &&
                                  applicationStates.map((x, i) => {
                                    return (
                                      <FormControlLabel
                                        role="button"
                                        key={i}
                                        value={x.id}
                                        className="mb-0"
                                        control={
                                          <Radio color="textSecondary" />
                                        }
                                        label={
                                          <Typography
                                            className={classes.stateLabel}
                                            style={{
                                              color:
                                                x.code === "descarted"
                                                  ? theme.palette.error.main
                                                  : x.code === "hihired"
                                                  ? theme.palette.success.main
                                                  : "",
                                            }}
                                          >
                                            {x.name}
                                          </Typography>
                                        }
                                      />
                                    );
                                  })}
                              </RadioGroup>
                            </FormControl>

                            <Hidden smUp>{renderValuation()}</Hidden>
                          </div>

                          <Hidden smDown>{renderValuation()}</Hidden>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <Typography
                            color="secondary"
                            className={`${classes.subCaption} ${classes.centerXs}`}
                          >
                            {translate(
                              "Professional.Profile.ContactProfessional"
                            )}
                          </Typography>
                          {isBlur ? (
                            <div
                              className={`${classes.boxContact} mt-2 text-center`}
                            >
                              <div style={{ width: "min-content" }}>
                                <div
                                  className={classes.infoMovil}
                                  style={{ marginBottom: 28, lineHeight: 1 }}
                                >
                                  {`${translate(
                                    "Professional.Profile.InfoEmail"
                                  )}: `}
                                  <span className={classes.blur}>
                                    example@email.com
                                  </span>
                                </div>
                                <Translate>
                                  {({ translate }) => {
                                    return (
                                      <Button
                                        className={classes.button}
                                        classes={{ label: classes.buttonLabel }}
                                        variant="contained"
                                        color="secondary"
                                        disabled
                                      >
                                        <WhatsApp />
                                        <Typography
                                          className={`${classes.buttonLabel} ml-2`}
                                        >
                                          {translate(
                                            "Professional.Profile.ByWhatsapp"
                                          )}
                                        </Typography>
                                      </Button>
                                    );
                                  }}
                                </Translate>

                                <div className={classes.infoMovil}>
                                  {`${translate(
                                    "Professional.Profile.InfoLabel"
                                  )}: `}
                                  <span className={classes.blur}>00000000</span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`${classes.boxContact} mt-2 text-center`}
                            >
                              <div style={{ width: "min-content" }}>
                                <div
                                  className={classes.infoMovil}
                                  style={{ marginBottom: 28, lineHeight: 1 }}
                                >
                                  {`${translate(
                                    "Professional.Profile.InfoEmail"
                                  )}: ${professional.email}`}
                                </div>

                                {/*<Button className={classes.button}
                                                            classes={{label: classes.buttonLabel}}
                                                            variant="contained" color="secondary"
                                                            onClick={() => props.history.push(`/employer/compose-email/${applicationId}/${professionalId}`)}>
                                                        <MailOutlineOutlined/>
                                                        <Typography
                                                            className={`${classes.buttonLabel} ml-2`}>{translate("Professional.Profile.ByEmail")}</Typography>
                                                    </Button>*/}

                                {professional.acceptWhatsAppHired && (
                                  <Translate>
                                    {({ translate }) => {
                                      return (
                                        <Button
                                          className={classes.button}
                                          classes={{
                                            label: classes.buttonLabel,
                                          }}
                                          variant="contained"
                                          color="secondary"
                                          onClick={() => {
                                            let message = `${translate(
                                              "Offers.WhatsAppMessage"
                                            )}...`;
                                            let phone =
                                              professional.phoneNumber.indexOf(
                                                "34"
                                              ) === 0
                                                ? professional.phoneNumber
                                                : `34${professional.phoneNumber}`;
                                            window.open(
                                              `https://api.whatsapp.com/send?phone=${phone}&text=${message}`,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          <WhatsApp />
                                          <Typography
                                            className={`${classes.buttonLabel} ml-2`}
                                          >
                                            {translate(
                                              "Professional.Profile.ByWhatsapp"
                                            )}
                                          </Typography>
                                        </Button>
                                      );
                                    }}
                                  </Translate>
                                )}

                                <div className={classes.infoMovil}>
                                  {`${translate(
                                    "Professional.Profile.InfoLabel"
                                  )}: ${professional.phoneNumber}`}
                                </div>
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div></div>
          );
        }}
      </Translate>
    );
  };

  function renderBreadCrumb() {
    return (
      <React.Fragment>
        <BreadcrumbsItem to="/">
          <Translate id={"Titles.Start"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem
          to={professionalId === 0 ? "# " : "/employer/workspace"}
        >
          <Translate id={"Employer.EmployerArea"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem
          to={
            professionalId === 0
              ? "#"
              : `/employer/process/management/${process ? process.id : 0}`
          }
        >
          <Translate id={"Offers.ManagementTitle"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem
          to={`/employer/process/management/${process ? process.id : 0}/some`}
        >
          <Translate id={"Professional.Profile.Title"} />
        </BreadcrumbsItem>
      </React.Fragment>
    );
  }

  return professional && process ? (
    <Translate>
      {({ translate }) => {
        return (
          <React.Fragment>
            {renderBreadCrumb()}

            <Grid container direction="row" justify="center">
              <Grid item xs={11} sm={9} md={7} className="pb-4">
                <h1 className={classes.title1}>
                  <Translate id={"Professional.Profile.Title"} />
                </h1>
                <Grid item xs={12}>
                  <Paper elevation={2} className={classes.topPaper}>
                    <Grid item xs={12}>
                      <div className={classes.flex}>
                        <Typography
                          className={classes.caption}
                          color="secondary"
                        >{`${translate(
                          "Professional.Profile.SelectionProcess"
                        )}:`}</Typography>
                        <Typography
                          className={`${classes.caption} ml-1`}
                          color="textSecondary"
                        >
                          {process.title}
                        </Typography>
                      </div>
                      <div className={classes.flex}>
                        <Typography
                          className={classes.caption}
                          color="secondary"
                        >{`${translate(
                          "Professional.Profile.Applicant"
                        )}:`}</Typography>
                        {isBlur ? (
                          <Typography
                            className={`${classes.caption} ml-1 ${
                              isBlur ? classes.blur : ""
                            }`}
                            color="textSecondary"
                          >{`Lorem Ipsum Dolor`}</Typography>
                        ) : (
                          <Typography
                            className={`${classes.caption} ml-1`}
                            color="textSecondary"
                          >{`${professional.firstName} ${professional.lastName} ${professional.surName}`}</Typography>
                        )}
                      </div>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="row" justify="center">
              <Grid item xs={11} sm={9} md={11} className="pb-4">
                <Paper elevation={2} className={classes.downPaper}>
                  <Grid container>
                    <Grid item xs={12} className={classes.infoSection}>
                      <div>
                        <Typography
                          className={classes.professionalSubTitle}
                          color="textSecondary"
                        >
                          <Translate id={"Professional.Profile.Profile"} />
                        </Typography>
                      </div>
                      <hr className={classes.separator} />
                    </Grid>

                    <Grid item xs={12}>
                      {renderProfessionalInfo()}
                    </Grid>
                  </Grid>

                  {renderLanguageSection()}

                  {renderPanelManager()}
                </Paper>
              </Grid>
              <Grid item xs={11} sm={9} md={11} className="my-4 text-center">
                <GoBack
                  title={
                    professionalId === 0
                      ? ""
                      : translate("Professional.Profile.GoBackList")
                  }
                  type="button"
                />
              </Grid>
            </Grid>

            <CustomConfirmation
              open={requestTest}
              type="secondary"
              acceptLabel={translate("Buttons.Acquire")}
              cancelLabel={translate("Buttons.Cancel")}
              handleCancel={() => {
                setRequestTest(false);
                setSelectedTest(null);
              }}
              handleAccept={() => handleConsumeTest()}
            >
              <Grid item xs={12} className={classes.content}>
                <Translate>
                  {({ translate }) => {
                    return (
                      <React.Fragment>
                        <Typography
                          variant="subtitle1"
                          className={classes.dialogTitle}
                        >
                          {translate(
                            "Professional.Profile.DisponibilityTest"
                          ).replace("[x]", employerPurchases.languageTestCount)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.dialogTitle}
                        >
                          <Translate
                            id={"Professional.Profile.DisponibilityConcens"}
                          />
                        </Typography>
                      </React.Fragment>
                    );
                  }}
                </Translate>
              </Grid>
            </CustomConfirmation>

            <CustomConfirmation
              open={payTest}
              type="secondary"
              acceptLabel={translate("Buttons.Acquire")}
              cancelLabel={translate("Buttons.Cancel")}
              handleCancel={() => setPayTest(false)}
              handleAccept={() => {
                handleAcquireTest();
                setPayTest(false);
              }}
            >
              <Grid item xs={12} className={classes.content}>
                <Typography variant="subtitle1" className={classes.dialogTitle}>
                  <pre className={classes.content}>
                    <Translate
                      id={"Professional.Profile.NoDisponibilityTest"}
                    />
                  </pre>
                </Typography>
              </Grid>
            </CustomConfirmation>
            <ModalPay open={isBlur} />
          </React.Fragment>
        );
      }}
    </Translate>
  ) : (
    <Grid container direction="row" justify="center">
      <Grid item xs={11} sm={9} md={7} className="pb-4">
        <CircularProgress color="secondary" />
      </Grid>
    </Grid>
  );
}

export default withRouter(ProfessionalProfile);
