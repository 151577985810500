import React, {useEffect, useState, useRef} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {List, ListItem, ListItemText, Typography, Button, useMediaQuery, Tooltip} from "@material-ui/core";
import {Translate, withLocalize} from "react-localize-redux";
import {filter, findIndex, split} from "lodash";
import {useHistory} from "react-router-dom";
import TimerIndicator from "../timerIndicator";
import HiredRecorder from "../hiredRecorder";
import Timer from "../customTimer";
import {useDispatch, useSelector} from "react-redux";
import * as types from '../../../actions/users/index';
import api from "../../../api/index";
import RegresiveTimer from "./regresiveTimer";
import {KeyboardTimePicker} from "@material-ui/pickers";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as interviewActions from "../../../actions/interview/index";
import * as permissions from "../../../utils/permissions";
import QuestionStepper from "./questionStepper";
import * as localforage from "../../../indexdb/localforageManager";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        [theme.breakpoints.up('md')]: {
            width: 280
        },
        position: 'relative',
        overflow: 'auto',
        margin: 'auto',
        "&>div": {
            [theme.breakpoints.down('sm')]: {
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        [theme.breakpoints.only('xl')]: {
            marginBottom: "49px !important"
        },

    },
    closed: {
        margin: theme.spacing(1),
        padding: 0,
        position: "absolute",
        top: 26,
        right: 26,
        minWidth: 63,
        width: 63,
        height: 63
    },
    icon: {
        height: 63,
        width: 63
    },
    titleInterView: {
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            marginLeft: 59
        },
    },
    paddingRightResponse: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "152px !important"
        },
    },
    paddingLeftResponse: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "3rem !important"
        },
    },
    video: {
        [theme.breakpoints.up('md')]: {
            margin: "auto",
        },
    },
    listItem: {
        [theme.breakpoints.only('xs')]: {
            height: 28,
        },
        [theme.breakpoints.down('sm')]: {
            width: 234
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 32,
        },
        height: 32,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            borderColor: theme.palette.primary.main,
            '& .MuiInputBase-input': {
                color: "#FFFFFF"
            },
        },
        display: "flex",
        justifyContent: "center"

    },
    listItemActive: {
        [theme.breakpoints.only('xs')]: {
            height: 28,
        },
        [theme.breakpoints.down('sm')]: {
            width: 234
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 32,
        },
        height: 32,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        textTransform: "uppercase",
        fontSize: 18,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            //color: "#FFFFFF",
            '& .MuiInputBase-input': {
                //color: "#FFFFFF",
            },
            '& .MuiInput-underline:hover': {
                '& .MuiInputBase-input': {
                    color: theme.palette.secondary.main,
                }
            }
        },
        display: "flex",
        justifyContent: "center"

    },
    timePicker: {
        width: 100,
        [theme.breakpoints.down('sm')]: {
            width: 75,
            '& .MuiIconButton-root': {
                paddingRight: 0
            }
        },
        '& .MuiInput-underline': {
            border: "none",
        },
        '&$focused &:focus &:hover': {
            border: "none",
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        },
        "& .MuiInputBase-input": {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            },
        }
    },
    timePickerActive: {
        width: 100,
        '& input': {
            backgroundColor: "white",
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 600
        },
        [theme.breakpoints.down('sm')]: {
            width: 75,
            '& .MuiIconButton-root': {
                paddingRight: 0
            }
        },
        '& .MuiInput-underline': {
            //color: "#FFFFFF",
        },
        '& .MuiInput-underline:hover': {
            border: "none",
            color: theme.palette.secondary.main,
            //backgroundColor: theme.palette.background.paper,
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: "none"
        },
        "& .MuiInputBase-input": {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            },
        }
    },
    colorEndButton: {
        background: '#45BA4E',
        '&:hover': {
            backgroundColor: '#45BA4E',
        }
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    customDisabled: {
        pointerEvents: 'none'
    },
    question2: {
        ...theme.overrides.MuiTypography.question3,
        lineHeight: 1.1,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.question3.fontSize
        },
    },
    px3: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
            paddingRight: 5
        },
    },
    recorderMargins: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important",

    },
    questions: {
        marginTop: 6,
        minHeight: 117,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            minHeight: 73,
            paddingBottom: "0 !important"
        },
        [theme.breakpoints.only('lg')]: {
            minHeight: window.innerHeight >= 638 && window.innerHeight <= 720 ? 100 : 117,
        },

    },
    questionsMargin: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: "0 !important",
            marginTop: "0 !important"
        },
    },
    paddingTop: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: "0 !important",
        },
    },
    heightMaxTime: {
        height: 61,
        display: "flex",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            height: 38
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 46 : 61,
        },
        [theme.breakpoints.up('xs')]: {
            // paddingBottom: "5px !important",
            //marginBottom: "5px !important",
        },
    },
    mb: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8
        },
        marginBottom: 12
    },
    my: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
            marginTop: 8
        },
        marginBottom: 12,
        marginTop: 12
    },
    body1: {
        ...theme.overrides.MuiTypography.body1,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 12 : theme.overrides.MuiTypography.body1.fontSize
        }
    },
    backgroundGrey: {
        [theme.breakpoints.only('xs')]: {
            backgroundColor: theme.palette.common.gray,
        }
    },
    centerXs: {
        textAlign: "center",
        [theme.breakpoints.up('xl')]: {
            paddingRight: "3rem !important",
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    instructions: {
        ...theme.overrides.MuiTypography.question3,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        lineHeight: 1.1,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 30 : theme.overrides.MuiTypography.question3.fontSize
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "0 !important",
            marginBottom: 0,

        },
    },
    button: {
        marginBottom: "1.5rem",
        [theme.breakpoints.down('xs')]: {
            maxWidth: 240,
        },
        [theme.breakpoints.down('sm')]: {
            padding: "5px 10px",
            maxWidth: "100%",
            height: 40,
            marginBottom: "1rem",
        },
        [theme.breakpoints.up('md')]: {
            //minWidth: 240,
        }
    },
}));

let hiredRecorder = null;

function RunInterView(props) {
    const classes = useStyles();
    let history = useHistory();
    let dispatch = useDispatch();
    const [hiddenButton, setHiddenButton] = React.useState(0);
    const [indexActiveQuestion, setIndexActiveQuestion] = React.useState(-1);

    const timeRecordConfiguration = useSelector(state => state.configurations.configs && state.configurations.configs.MaxRecordTime ? parseInt(state.configurations.configs.MaxRecordTime) : 180);
    const [maxValueRecord, setMaxValueRecord] = useState(timeRecordConfiguration);
    const [maxValueRecordConfig, setMaxValueRecordConfig] = useState(timeRecordConfiguration);
    const [maxValueTextRecord, setMaxValueTextRecord] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [metadata, setMetadata] = useState([]);
    const [regresiveTime, setRegresiveTime] = useState(0);
    const [videoRef, setVideoRef] = useState(null);
    const [countdownTime, setCountdownTime] = useState(5);
    let [unsuportRecorder, setUnsupportRecorder] = useState(false);
    let [granted, setGranted] = useState(false);

    const activeQuestions = indexActiveQuestion === -1 ? [] : (questions.length > 0 ? filter(questions, ["index", indexActiveQuestion]) : []);
    const interviewSupport = useSelector(state => state.interviews.support);
    const interviewMetadatas = useSelector(state => state.users.interview.metadata);

    useEffect(() => {
        defineActionHeader();
        loadQuestions();
        loadGranted().then(response => setGranted(response));
    }, [unsuportRecorder, granted]);

    async function loadGranted() {
        return await permissions.permissionsConceded();
    }

    function loadQuestions() {
        if (questions === null) {
            api.interview.allQuestions().then((response) => {
                let {data} = response;
                let maxDurationValue = 0;
                if (data) {
                    let mapped = interviewMetadatas && interviewMetadatas.length
                        ? data.map((quest, index) => {
                            let meta = interviewMetadatas.find(x => x.index === quest.index);
                            if (meta) {
                                quest.suggestDurationSeconds = meta.duration;
                                quest.durationFormat = meta.durationFormat;
                            }
                            return quest;
                        })
                        : data;

                    setQuestions(mapped)
                    maxDurationValue = mapped.map(x => x.suggestDurationSeconds).reduce((accumulator, value) => accumulator + value);
                    setMaxValueRecord(maxDurationValue)
                }
            }).catch((err) => {
                setQuestions([]);
            })
        }
    }

    const handleClosed = () => {
        dispatch(types.resetInterview());
        props.history.push(`/professional/edit-profile`)
    }

    const handleCancelInterview = () => {
        setHiddenButton(0)
        dispatch(types.resetInterview());
        history.push("/run-interview");
    };

    function notifyTime(seconds) {
        setSeconds(seconds);
        if (seconds > maxValueRecord) {
            handleStopRecorder();
        }
        let {indexActive, indexMetadata} = getActiveQuestion(seconds);
        if (indexActiveQuestion !== indexActive) {
            setIndexActiveQuestion(indexActive);
            if (indexActive > 0) {
                pushMetadata(indexActive);
            }
        }

        let question = questions[indexActive];

        seconds === 0
            ? setRegresiveTime(question.suggestDurationSeconds)
            : setRegresiveTime(regresiveTime => regresiveTime === 0 ? question.suggestDurationSeconds - 1 : regresiveTime - 1)
    }

    const validQuestions = () => questions ? questions.filter(x => x.suggestDurationSeconds > 0) : [];

    function getActiveQuestion(seconds) {
        let totalSeconds = 0;
        let indexActive = -1;
        let indexMetadata = -1;

        let validData = validQuestions();
        for (let i = 0; i < validData.length; i++) {
            let quest = validData[i];
            totalSeconds += quest.suggestDurationSeconds;
            if (totalSeconds >= seconds) {
                indexActive = quest.index;
                indexMetadata = i;
                break;
            }
        }
        return {indexActive, indexMetadata};
    }

    async function handleStartRecorder() {
        await localforage.clear();
        if (deviceSupport()) {
            if (hiredRecorder) {
                if (hiredRecorder.handleOpenVideoInput) {
                    hiredRecorder.handleOpenVideoInput();
                }
            }
        } else {
            if (interviewSupport.isInlineRecordingSupported === true) {
                if (await loadGranted()) {
                    setHiddenButton(1)
                    setIsActive(true);
                    if (hiredRecorder) {
                        if (hiredRecorder.mediaRecorder) hiredRecorder.handleStopReplaying()
                        else hiredRecorder.handleStartRecording()
                    }
                }
            }
        }
    }

    function handleStopRecorder() {
        setIsActive(false);
        if (interviewSupport.isInlineRecordingSupported === true) {
            if (hiredRecorder) {
                hiredRecorder.turnOffCamera();
                hiredRecorder.handleStopRecording();
            }
            saveMetadata();
            history.push("/seen-hired/continuous");
        } else if (interviewSupport.isVideoInputSupported === true) {
            //TODO: En la face uno del componete esto no funciona
            //if (hiredRecorder && hiredRecorder.stopStreamedVideo)
            //hiredRecorder.stopStreamedVideo();
        }
    }

    function pushMetadata(indexQuestion) {
        let auxQuestions = validQuestions();
        let indexCurrent = auxQuestions.findIndex(x => x.index === indexQuestion);
        if (indexCurrent === 0) return;

        let question = indexCurrent > -1 ? auxQuestions[indexCurrent - 1] : auxQuestions[auxQuestions.length - 1];
        let lastMeta = metadata.length > 0 ? metadata[metadata.length - 1] : null;
        let start = lastMeta ? lastMeta.end : 0;
        let end = seconds;
        let duration = end - start;
        let metas = Object.assign([], metadata);

        let meta = {
            start: start,
            end: end,
            duration: duration,
            durationFormat: question.durationFormat,
            question: question.text,
            index: question.index
        };
        metas.push(meta);
        setMetadata(metas);
        return metas;
    }

    function saveMetadata() {
        dispatch(types.setMetadata(pushMetadata(questions.length)));
    }

    function defineActionHeader() {
        dispatch(interviewActions.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: handleClosed
        }))
    }

    function deviceSupport() {
        return !interviewSupport.isInlineRecordingSupported && interviewSupport.isVideoInputSupported;
    }

    const {innerWidth: width, innerHeight: height} = window;
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    let heightVideo = videoRef && videoRef.cameraVideo ? videoRef.cameraVideo.clientHeight : 0;
    const maxSmHeightVideo = 484;
    const iphoneHeight = 667;
    return (questions ? <Grid container spacing={2} className={classes.px3}>
            <Grid item xs={12} sm={6} md={7} className={`py-0 ${classes.centerXs}`}>
                <Typography variant="h1">
                    <Translate id={"Titles.VideoInterview"}/>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={7} md={7} id="recorder-container" className={classes.recorderMargins}>
                <HiredRecorder
                    onReady={(recorder) => {
                        hiredRecorder = recorder;
                    }}
                    onLoadedVideo={(videoCoponent) => {
                        setVideoRef(videoCoponent)
                    }}
                    onRecordingComplete={(videoBlob, thumbnailBlob) => {
                        dispatch(types.setBlob(videoBlob));
                        if (interviewSupport.isVideoInputSupported === true) {
                            history.push("/seen-hired/continuous");
                        }
                    }}
                    countdownTime={(countdownTime) * 1000}

                    notSupportRecorder={() => {
                        setUnsupportRecorder(true);
                    }}
                    notifySuccessRecorder={() => {
                        setUnsupportRecorder(false);
                        setGranted(true);
                    }}
                />
                <Timer isActive={isActive} notifyTime={notifyTime} countdownTime={countdownTime}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={5} md={5} className={`text-center pl-2 ${classes.paddingLeftResponse}`}>
                <Grid container spacing={2}>
                    <div style={screenXsDown ? {
                        height: `calc(100vh - ${(heightVideo) + 100}px)`,
                        overflowY: "scroll",
                        width: "100%"
                    } : (screenSmOnly && height <= maxSmHeightVideo ? {
                        height: heightVideo,
                        overflowY: "scroll",
                        width: "100%"
                    } : (screenXlOnly ? {width: "100%"} : {width: "100%"}))}>
                        <Grid item xs={12} style={{minHeight: width > 600 ? heightVideo * 0.75 : "auto"}}>
                            <Grid item xs={12}
                                  className={`${classes.heightMaxTime} text-center ${screenXsDown && height > iphoneHeight ? "py-2" : "py-0"}`}>
                                {isActive && <RegresiveTimer value={regresiveTime} index={indexActiveQuestion}/>}
                            </Grid>
                            <Grid item xs={12}
                                  className={`${screenXsDown && height > iphoneHeight ? "pt-2 pb-0" : "py-0"}`}>
                                <QuestionStepper
                                    className={`${classes.instructions} ${screenXsDown && (height > iphoneHeight ? "mt-3" : "mt-2")}`}
                                    steps={questions ? questions : []} activeStep={indexActiveQuestion}
                                    showTimes={true}
                                    hiddenTooltips={isActive}
                                    un/>
                            </Grid>

                            <Grid item xs={12}
                                  className={`text-center ${screenXsDown && height > iphoneHeight ? "pb-5" : "pb-0"}`}>
                                {hiddenButton === 0 ?
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" className={classes.button}
                                                    disabled={isActive || maxValueRecord > maxValueRecordConfig || maxValueTextRecord !== "" || unsuportRecorder || !granted}
                                                    color="primary" onClick={handleStartRecorder}>
                                                {translate("Buttons.StartRecording")}
                                            </Button>
                                        )}
                                    </Translate>
                                    : <Translate>
                                        {({translate}) => (
                                            <Button variant="outlined" color="default"
                                                    disabled={seconds <= 0 || unsuportRecorder || !granted}
                                                    className={classes.button}
                                                    onClick={handleCancelInterview}>
                                                {translate("Buttons.CancelRecording")}
                                            </Button>
                                        )}
                                    </Translate>
                                }
                            </Grid>
                        </Grid>


                        {heightVideo > 0 ? <Grid item xs={12}
                                                 className={`text-center px-5 ${height > 855 && height < 1080 ? "py-5" : (height > 720 && "pb-4")}`}
                                                 style={screenXlOnly ? {paddingTop: 82} : {}}>
                            <TimerIndicator key="recorder" paused={!isActive} play={isActive} value={seconds}
                                            maxValue={maxValueRecord} maxValueConfig={maxValueRecordConfig}/>
                        </Grid> : <div/>}
                    </div>
                </Grid>
            </Grid>
        </Grid>
        : <CircularProgress/>);
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withLocalize(RunInterView));