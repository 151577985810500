/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./App.css";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import Main from "./main";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { ArrayLanguages, GetAllLanguage } from "./actions/language";
import { sortBy } from "lodash";
import { renderToStaticMarkup } from "react-dom/server";
import resourcesTranslations from "./resources/resources";
import VideoHeader from "./components/headers/videoHeader";
import { getAllConfigurations } from "./actions/configurations/index";
import Errors from "./components/error/index";
import AppDrawerShell from "./components/navigation/appShell";
import EmployerHearder from "./components/headers/employerHearder";
import ImageBackgroudLogin from "./images/employer/login-background.png";
import ImageBackgroudProfessionalLogin from "./images/professional/login-professional-background.png";
import HeaderCircle from "./images/layuout/employer/header-circle.png";
import { Hidden } from "@material-ui/core";
import ImageMovilProfessional from "./images/layuout/movil3.png";
import ImageMovilEmployer from "./images/layuout/movil4.png";
import LogoImageXlProfessional from "./images/layuout/xl-home3.png";
import LogoImageXlEmployer from "./images/layuout/xl-hom4.png";
import LogoImageXlProfessionalB from "./images/layuout/xl-home3-b.png";
import LogoImageXlEmployerB from "./images/layuout/xl-hom4-b.png";
import AlertError from "./components/error/alertError";
import DynamicBreadCrumb from "./components/navigation/dynamicBreadcrumb";
import CookieConsents from "./components/confirmation/cookies";
import Helmet from "react-helmet";
import api from "./api/index";

const styles = (theme) => {
  theme.overrides.MuiButton.root = {
    ...theme.overrides.MuiButton.root,
    padding: "5px 10px",
    height: 40,
    minWidth: 220,
  };
  return {
    container: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "98%",
      [theme.breakpoints.only("xs")]: {
        width: "95%",
      },
    },
    body: {
      display: "inline-block",
      [theme.breakpoints.up("xl")]: {
        marginLeft: 100,
      },
    },
    paper: {
      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
      },
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: 1,
    },
    marginTop: {
      marginTop: 105,
      [theme.breakpoints.down("sm")]: {
        marginTop: 70,
      },
      /* '@media (min-width: 900px) and (max-width: 1400px)': {
                 '@media (min-height: 400px) and (max-height: 800px)': {
                     marginTop: 80
                 }
             }*/
    },
    interViewLayout: {
      minHeight: "100vh",
    },
  };
};

const loginStyle = (theme) => {
  theme.overrides.MuiButton.root = {
    ...theme.overrides.MuiButton.root,
    padding: 0,
    height: 50,
    minWidth: 200 /*, borderRadius: 0*/,
  };
  theme.overrides.MuiInputBase = {
    input: {
      fontSize: 16,
      padding: 16,
      backgroundColor: "#F7F7F7",
      borderRadius: 0,
      "@media (min-width: 960px)": {
        minWidth: 200,
      },
      /*maxHeight: 18,*/
      paddingTop: 15,
      paddingBottom: 16,
    },
    root: {
      "&.Mui-disabled": {
        border: "1px solid #141414",
        color: "#141414",
        "& .MuiInputAdornment-root": {
          backgroundColor: "transparent",
        },
        "& .MuiInputBase-input.Mui-disabled": {
          color: "#141414",
        },
      },
      "&.Mui-error.MuiInputBase-multiline": {
        background: "#FDE8E8",
        border: "1px solid #FF0000",
        borderBottom: "1px solid #FF0000",
      },
      "&.Mui-error textarea": {
        minHeight: 113,
      },
    },
  };
  theme.overrides.MuiInputLabel = {
    root: {
      fontSize: 16,
      fontWeight: "normal",
      color: "#141414",
      "&.Mui-required": {
        color: "#FFB170",
      },
    },
    shrink: {
      transform: "translate(0, -2.5px) scale(1)",
      "transform-origin": "top left",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      width: "100%",
      height: 22,
    },
  };
  theme.overrides.MuiInput = {
    ...theme.overrides.MuiInput,
    input: {
      ...theme.overrides.MuiInput.input,
      "&[type=error]": {
        background: "#FDE8E8",
        border: "1px solid #FF0000",
      },
    },
    root: {
      "&.Mui-error input": {
        background: "#FDE8E8",
        border: "1px solid #FF0000",
      },
      "&.Mui-error .MuiSelect-select": {
        background: "#FDE8E8",
        border: "1px solid #FF0000",
      },
      "&.Mui-error .MuiInputAdornment-root": {
        top: 1,
      },
      backgroundColor: "#F7F7F7",
      borderRadius: 0,
      minHeight: 50,
      marginBottom: 10,
      marginTop: "26px !important",
      "@media (min-width: 960px)": {
        minWidth: 200,
      },
    },
  };
  theme.overrides.Mui = {
    ...theme.overrides.Mui,
    error: {
      ...theme.overrides.Mui.error,
      "& input[type=text]": {
        background: "#FDE8E8",
        border: "1px solid #FF0000",
      },
    },
  };
  theme.overrides.MuiInputAdornment = {
    ...theme.overrides.MuiInputAdornment,
    root: {
      ...theme.overrides.MuiInputAdornment.root,
      top: 0,
    },
  };
  return {
    toolbar: {
      ...theme.mixins.toolbar,
      height: 96,
      [theme.breakpoints.down("xs")]: {
        height: 110,
      },
    },
    container: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "98%",
      [theme.breakpoints.only("xs")]: {
        width: "95%",
      },
    },
    body: {
      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
      },
      background: "white",
    },
    bodyContainer: {
      [theme.breakpoints.up("md")]: {
        backgroundImage:
          window.location.pathname.indexOf("employer") > -1
            ? `url('${ImageBackgroudLogin}')`
            : `url('${ImageBackgroudProfessionalLogin}')`,
        backgroundSize:
          window.location.pathname.indexOf("employer") > -1
            ? "contain"
            : "cover",
        backgroundPosition: "right",
        minHeight: "100vh",
        backgroundRepeat: "repeat-x",
      },
    },
    contentResponsive: {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 30,
        paddingRight: 30,
        //marginTop: 0
      },
    },
    circle: {
      backgroundImage: `url('${HeaderCircle}')`,
      backgroundSize: "cover",
      width: 536,
      height: 230,
      top: 0,
      position: "absolute",
      left: "33%",
      [theme.breakpoints.only("xs")]: {
        left: "28%",
        width: "50%",
        height: 63,
        backgroundSize: "auto",
        backgroundPosition: "center",
      },
      [theme.breakpoints.only("sm")]: {
        left: "28%",
        width: 461,
      },
      [theme.breakpoints.only("md")]: {
        left: "28%",
      },
      [theme.breakpoints.only("lg")]: {
        left: "28%",
      },
    },
    marginTop: {
      marginTop: 100,
    },
    xl3: {
      position: "fixed",
      right: 0,
      width: 263,
      height: "100vh",
      objectFit: "cover",
      objectPosition: "right",
      [theme.breakpoints.only("lg")]: {
        width: 223,
      },
      [theme.breakpoints.only("md")]: {
        width: 131,
        objectPosition: "center",
      },
      [theme.breakpoints.only("sm")]: {
        width: 60,
        objectPosition: "center",
      },
    },
    xl4: {
      position: "fixed",
      left: 0,
      width: 244,
      height: "100vh",
      objectFit: "cover",
      objectPosition: "left",
      [theme.breakpoints.only("lg")]: {
        width: 208,
      },
      [theme.breakpoints.only("md")]: {
        width: 122,
      },
      [theme.breakpoints.only("sm")]: {
        width: 55,
      },
    },
  };
};

const overrydeStyle = (theme) => {
  theme.overrides.MuiButton.root = {
    ...theme.overrides.MuiButton.root,
    minWidth: 200,
    minHeight: 50,
  };
  theme.overrides.MuiInput.root = {};
  theme.overrides.MuiInputLabel = {};
  theme.overrides.MuiInputAdornment = {};
  theme.overrides.MuiInputBase = {
    input: {
      padding: "10px 0 7px;",
    },
  };
  return {
    container: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "98%",
      [theme.breakpoints.only("xs")]: {
        width: "95%",
      },
    },
    body: {
      display: "inline-block",
      [theme.breakpoints.up("xl")]: {
        marginLeft: 100,
      },
    },
    paper: {
      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
      },
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: 1,
    },
    marginTop: {
      marginTop: 105,
      [theme.breakpoints.down("sm")]: {
        marginTop: 70,
      },
      "@media (min-width: 900px) and (max-width: 1400px)": {
        "@media (min-height: 400px) and (max-height: 800px)": {
          marginTop: 80,
        },
      },
    },
    interViewLayout: {
      minHeight: "100vh",
    },
  };
};

const MainLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        <CookieConsents />
        <div className={classes.circle} style={{ zIndex: 1 }} />
        <main className={classes.marginTop}>
          <EmployerHearder menu={true} scroll={true} />
          {props.children}
        </main>
      </Container>
    </React.Fragment>
  );
};

const FindJobsLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        <CookieConsents />
        <div className={classes.circle} style={{ zIndex: 1 }} />
        <main>{props.children}</main>
      </Container>
    </React.Fragment>
  );
};

const InterViewLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed={false} maxWidth={false} disableGutters>
        <CookieConsents />
        <main>
          <Paper
            className={`${classes.paper} ${classes.interViewLayout}`}
            elevation={0}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <VideoHeader />
              </Grid>
              <Grid item xs={12} id="headerGrid" className={classes.marginTop}>
                <DynamicBreadCrumb xl={9} disableTop={true} />
                <div className={classes.container}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={classes.body}
                      xs={12}
                      sm={11}
                      md={11}
                      lg={11}
                      xl={10}
                    >
                      {props.children}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Errors />
          </Paper>
        </main>
      </Container>
    </React.Fragment>
  );
};

const Layout = (props) => {
  return (
    <React.Fragment>
      <AppDrawerShell>
        <CookieConsents />
        {props.children}
      </AppDrawerShell>
    </React.Fragment>
  );
};

const EmployerLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        <AlertError />
        <CookieConsents />
        <div className={classes.circle} style={{ zIndex: -1 }} />
        <main>
          <EmployerHearder menu={true} scroll={false} />
          <DynamicBreadCrumb />
          {props.children}
        </main>
      </Container>
    </React.Fragment>
  );
};

const SupportrLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        <CookieConsents />
        <div className={classes.circle} style={{ zIndex: 1 }} />
        <main className={classes.marginTop}>
          <EmployerHearder menu={true} scroll={true} />
          {props.children}
        </main>
      </Container>
    </React.Fragment>
  );
};

const LoginLayout = (props) => {
  let classes = props.classes;
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed={false} maxWidth={false} disableGutters>
        <CookieConsents />
        <AlertError />
        <div className={classes.circle} />
        <Hidden xsDown>
          <img
            alt="loginHome"
            src={
              window.location.pathname.indexOf("employer") > -1
                ? LogoImageXlEmployerB
                : LogoImageXlProfessionalB
            }
            className={classes.xl4}
          />
          <img
            alt="loginHome"
            src={
              window.location.pathname.indexOf("employer") > -1
                ? LogoImageXlEmployer
                : LogoImageXlProfessional
            }
            className={classes.xl3}
          />
        </Hidden>
        <main>
          <EmployerHearder menu={false} scroll={false} />
          <Paper elevation={0}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={9} md={7}>
                <Grid item xs={12} className={classes.contentResponsive}>
                  {props.children}
                </Grid>
              </Grid>
            </Grid>
            <Errors />
            <Hidden smUp>
              <img
                alt="loginHome"
                style={{ width: "100%" }}
                src={
                  window.location.pathname.indexOf("employer") > -1
                    ? ImageMovilEmployer
                    : ImageMovilProfessional
                }
              />
            </Hidden>
          </Paper>
        </main>
      </Container>
    </React.Fragment>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    let defaultLanguage = props.user.defaultLanguage;
    defaultLanguage = defaultLanguage ? defaultLanguage.split("-")[0] : "es";

    let browserLanguage = navigator.language || navigator.userLanguage;
    browserLanguage = browserLanguage ? browserLanguage.split("-")[0] : "es";

    const defaultLanguages = [
      {
        code: "en",
        name: "English",
      },
      {
        code: "es",
        name: "Español",
      },
    ];
    this.props.initialize({
      languages: defaultLanguages,
      translation: resourcesTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: defaultLanguage || browserLanguage || "en",
      },
    });

    this.state = {
      excludeRoutes: [
        "/home-interview",
        "/seen-hired/manual",
        "/seen-interview/manual",
        "/congratulations-interview",
        "/run-interview/configure",
        "/run-interview",
        "/seen-hired/continuous",
        "/seen-interview/continuous",
        "seen-hired/continuous",
        "/speaking/test",
        "/speaking/end-test/",
        "/speaking/viewer/",
        "/speaking/invalid-result",
        "/employer/seen-interview",
        "/seen-interview/continuos/professional",
      ],
      seo: null,
      currentRoute: null,
      language: "",
    };
  }

  async componentWillMount() {
    this.initializeLanguages().then((r) => true);
    await this.props.getAllConfigurations();
  }

  initChatra() {
    let chatra = document.getElementById("chatra");
    if (
      this.state.excludeRoutes.some(
        (x) => window.location.pathname.indexOf(x) > -1
      )
    ) {
      if (chatra) chatra.style.display = "none";
    } else {
      if (chatra) chatra.style.display = "block";
    }
  }

  async initializeLanguages() {
    try {
      await this.props.getAllLanguages();

      let languges =
        this.props.languagesApp.languagesApp &&
        this.props.languagesApp.languagesApp.length
          ? this.props.languagesApp.languagesApp
          : [
              {
                code: "en",
                isoCode: "en-US",
                name: "Inglés",
                order: 1,
                active: true,
              },
              {
                code: "es",
                isoCode: "es-ES",
                name: "Español",
                order: 2,
                active: true,
              },
            ];
      let languagesOrder = sortBy(languges, ["order"]).filter((x) => x.active);
      await this.props.arrayLanguages(languagesOrder);
    } catch (e) {
      console.log("La API no está dando respuesta: " + e.message);
    }
  }

  async loadSeoInfo() {
    if (
      this.state.seo === null ||
      this.state.currentRoute !== window.location.pathname
    ) {
      let response = await api.home.getSeo();
      await this.setState({
        seo: response.data,
        currentRoute: window.location.pathname,
      });
    }
  }

  getLanguage() {
    let { defaultLanguage } = this.props.user;
    return defaultLanguage ? defaultLanguage.split("-")[0] : "es";
  }

  render() {
    this.initChatra();
    this.loadSeoInfo();

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang: this.getLanguage() }}>
          <title>{this.state.seo ? this.state.seo.title : ""}</title>
          <meta
            name="description"
            content={this.state.seo ? this.state.seo.description : ""}
            data-react-helmet="true"
          />
          <meta
            property="og:locale"
            content={this.state.seo ? this.state.seo.code : ""}
          />
        </Helmet>
        <Main
          mainLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(styles)(MainLayout)))}
          interViewLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(overrydeStyle)(InterViewLayout)))}
          findJobsLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(overrydeStyle)(FindJobsLayout)))}
          layout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(loginStyle)(Layout)))}
          loginLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(loginStyle)(LoginLayout)))}
          employerLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(loginStyle)(EmployerLayout)))}
          supportLayout={connect(
            mapStateToProps,
            mapDispatchToProps
          )(withLocalize(withStyles(loginStyle)(SupportrLayout)))}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languagesApp: state.languagesApp,
    user: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLanguages: dispatch(GetAllLanguage),
    arrayLanguages: dispatch(ArrayLanguages),
    getAllConfigurations: dispatch(getAllConfigurations),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLocalize(withStyles(styles)(App)))
);
