import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

class Timer extends Component {
    static propTypes = {
        timeLimit: PropTypes.number,
        defaultText: PropTypes.string,
        countdownTime: PropTypes.number
    }

    constructor(props) {
        super(props)
        const nextSeconds = props.timeLimit ? props.timeLimit / 1000 : 0
        this.state = this.getState(nextSeconds)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    getCountDownTime = () => this.props.countdownTime ? this.props.countdownTime : 3;

    componentDidMount() {
        const { timeLimit } = this.props
        this.timer = setInterval(() => {
            if (this.props.isActive) {
                const { seconds } = this.state
                const nextSeconds = timeLimit ? seconds - 1 : seconds + 1

                const nextState = this.getState(nextSeconds)
                this.setState(nextState)

                if (this.props.notifyTime) this.props.notifyTime(nextSeconds > this.getCountDownTime() ? nextSeconds - this.getCountDownTime() : 0)
            }
        }, 1000)
    }

    pad(unit) {
        var str = '' + unit
        var pad = '00'
        return pad.substring(0, pad.length - str.length) + str
    }

    getState(seconds) {
        const minutes = Math.floor(seconds / 60)

        const humanTime =
            minutes !== 0
                ? `${minutes}:${this.pad(seconds - minutes * 60)}`
                : `${seconds - minutes * 60}s`

        return {
            seconds: seconds,
            human: humanTime
        }
    }

    render() {
        const defaultText = this.props.defaultText || '0:00'
        return (
            <div hidden>
                <h1> {this.state.human || defaultText}</h1>
                <h2> {this.state.seconds > this.getCountDownTime() ? (this.state.seconds - this.getCountDownTime()) : 0}</h2>
            </div>
        )
    }
}

export default Timer
