import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import { Translate } from "react-localize-redux";
import confirmationDialogImage from "../../images/confirmation-dialog.png";
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: 600,
        padding: 0,
        lineHeight: 1.5,
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        },
    },
    dialogContent: {
        padding: 0,
        paddingTop: "5px !important",
        flex: 0,
        height: "max-content",
        overflowY: "unset",
        [theme.breakpoints.only('xs')]: {

        },
    },
    button: {
        ...theme.overrides.DialogButton,
        borderRadius: 0,
        minHeight: 40,
        height: 40,
        minWidth: 180,
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0 !important",
            marginTop: 8,
            marginBottom: 8,
            width: "100%"
        },
    },
    dialog: {
        textAlign: "center",
        boxShadow: "0px 0px 25px #0000004D",
        borderRadius: 10,
        padding: "15px 48px 29px 48px",
        [theme.breakpoints.only('xs')]: {
            padding: "15px 24px 29px 24px",
        },
    },
    label: {
        color: theme.palette.text.secondary,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        },
    },
    buttonContainer: {
        marginTop: 10,
        padding: 0,
        [theme.breakpoints.only('xs')]: {
            marginTop: 24
        },
    },
    marginLeft: {
        marginLeft: "35px !important",
        [theme.breakpoints.only('xs')]: {
            marginLeft: "0px !important",
        },
    }
}));

export default function BasicConfirmation(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let classes = useStyles();
    let { acceptLabel, cancelLabel, type } = props;

    const handleAccept = async () => {
        if (props.handleAccept) await props.handleAccept();
    }

    return (
        <div>
            <Dialog classes={{ paper: classes.dialog }}
                open={props.open}>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {props.children}
                </DialogContent>

                <DialogActions className={classes.buttonContainer}>
                    <Button className={classes.button} classes={{ label: classes.label }}
                        onClick={handleAccept} color={type} variant="contained">
                        {acceptLabel ? acceptLabel : <Translate id={"Buttons.Hire"} />}
                    </Button>

                    <Button className={`${classes.button} ${classes.marginLeft}`} color={type}
                        classes={{ label: classes.label }}
                        variant="contained" color={type}
                        onClick={() => {
                            if (props.handleCancel) props.handleCancel();
                        }}>
                        {cancelLabel ? cancelLabel : <Translate id={"Buttons.Cancel"} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}