let deferredPrompt;

export function register() {
    let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swUrl, { scope: '/' })
            .then(function (response) {
                console.log('Hihired -> Service worker has registered !!!', response);
            });
    }

    window.addEventListener("beforeinstallprompt", function (event) {
        console.log("before install prompt fired", event);
        event.preventDefault();
        deferredPrompt = event;
        return false;
    });

    const buttonsAdd = document.querySelectorAll('.buttonInstall');
    for (let i = 0; i < buttonsAdd.length; i++) {
        buttonsAdd[i].addEventListener('click', (e) => {
            if (deferredPrompt) {
                // Show the prompt
                deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice
                    .then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            //console.log('User accepted the A2HS prompt');
                        } else {
                            //console.log('User dismissed the A2HS prompt');
                        }
                        //deferredPrompt = null;
                    });
            }
        });
    }
}