import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { ValidatorForm } from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from "react-redux";
import * as types from "../../../actions/errors";



function RecoverPassword() {
    const { innerWidth: width } = window;
    const isProfessional = window.location.pathname.indexOf("professional") > -1 ;
    const useStyles = makeStyles((theme) => ({
        titles: {
            marginTop: 50,
            [theme.breakpoints.down('xs')]: {
                marginTop: 10
            }
        },
        titlesFunctions: {
            display: "flex",
            [theme.breakpoints.down('xs')]: {
                display: "grid",
            }
        },
        responsiveWidth: {
            width: "100%",
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        form: {
            marginTop: 24,
            [theme.breakpoints.down('xs')]: {
                marginTop: 20
            }
        },
        submit: {
            minWidth: 227,
            borderRadius: 0,
            marginTop: 20
        },
        buttomtext: {
            fontWeight: "bold",
            color: isProfessional ? theme.palette.common.white : theme.palette.text.secondary,
            fontSize: 18,
            letterSpacing: 0.7

        },
        recoverText: {
            fontSize: 18,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            },
        },
        recoverTextBold: {
            fontSize: 18,
            fontWeight: "bold",
            color: theme.palette.text.secondary,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            },
        },
        mainTitle: {
            fontSize: 35,
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30
            },
            lineHeight: 1
        },
        sencondaryTitle: {
            fontSize: 25,
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
                marginTop: 8
            }
        },
        centerXs: {
            [theme.breakpoints.down('xs')]: {
                textAlign: "center"
            }
        },
        image: {
            maxWidth: width,
            height: "auto",
            marginLeft: -18
        },
        icon: {
            padding: 15,
            background: "#EDEDED",
            width: 54,
            height: 54,
            position: "absolute",
            right: 0,
            top: 20,
            cursor: "pointer",
        },
        errorText: {
            fontSize: 19,
            marginTop: 20
        },
        buttons: {
            marginTop: 0
        },
        linkedinButton: {
            width: 227,
            height: 50,
            color: "#FFFFFF",
            backgroundColor: "#1569BF",
            cursor: 'pointer',
            position: "relative",
            alignItems: "center",
            display: "flex",
        },
        linkedinImage: {
            float: "left",
            paddingLeft: 8
        },
        linkedinText: {
            position: "absolute",
            top: 4,
            left: 68,
            color: theme.palette.common.white,
            fontWeight: "bold",
            fontSize: 18,
            lineHeight: 1.2,
            textAlign: "left",
            letterSpacing: 0.7
        },
        or: {
            fontSize: 20,
            fontWeight: "bold",
            paddingTop: 7,
            paddingBottom: 7
        },
        buttonsContainer: {
            maxWidth: "max-content",
            marginBottom: 50,
            [theme.breakpoints.down('xs')]: {
                marginLeft: "auto",
                marginRight: "auto"
            }
        },
        tooltip: {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1
        },
        iconInfo: { width: "auto", height: "auto", position: "absolute", top: -8, right: 0, },
        check: {
            fontSize: 18,
            letterSpacing: 0.9,
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
            }
        },
        errorCheck: {
            border: "1px solid #FF0000",
            backgroundColor: "#FDE8E8"
        },
        errorHelper: {
            color: "#FF0000"
        },
        notifyText: {
            fontSize: 18,
            letterSpacing: 0.9,
            color: "#54B169",
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
            }
        },
        caption: {
            fontSize: 14
        }
    }));
    let classes = useStyles();
    let [formData, setFormData] = useState({ email: "", userType: isProfessional ? 1 : 0 });
    let [invalidRequired, setInvalidRequired] = useState(false)
    let [invalidFormat, setInvalidFormat] = useState(false)
    let [errorResponse, setErrorResponse] = useState(false);
    let [errorResponseText, setErrorResponseText] = useState("");
    let [completed, setCompleted] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {

    }, [completed, errorResponse, invalidRequired, invalidFormat, errorResponseText])

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    function handleError(error) {
        if (error) {
            showMessage(error, "error");
        } else {
            setErrorResponse(false);
            setErrorResponseText("")
        }
    }


    async function handleSubmit() {
        if (validateEmail(formData.email)) {
            api.users.forgotPassword(formData).then(response => {
                let { data } = response;
                if (response.status === 200) {
                    setCompleted(true);
                } else {
                    handleError(data ? data.error : response.message)
                }
            });
        }
    }

    const handleChange = (event) => {
        handleError(null);
        //validateEmail(event.target.value);
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    function validateEmail(email) {
        setInvalidRequired(false);
        setInvalidFormat(false);
        if (email === "" || email === undefined || email === " ") {
            setInvalidRequired(true);
            return false;
        }
        let format = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
        if (!format) setInvalidFormat(true);
        return format;
    }

    function renderError() {
        if (invalidRequired) return (
            <Typography className={classes.caption} variant="caption" color="error"><Translate id="Fields.Required" /></Typography>)
        if (invalidFormat) return (
            <Typography className={classes.caption} variant="caption" color="error"><Translate id="Fields.EmailValidation" /></Typography>)
        if (errorResponseText) return (<Typography className={classes.caption} variant="caption" color="error">{errorResponseText}</Typography>)
        else {
            return "";
        }
    }

    function isInvalidValid() {
        let valid = invalidRequired || invalidFormat || errorResponseText != "";
        return valid;
    }

    return (<Grid container spacing={0}>
        <Grid item xs={12} className={classes.titles}>
            <Typography variant="h1" color="textSecondary" className={classes.mainTitle}>{<Translate
                id={"Employer.RecoverPassTitle"} />} </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
            <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <Translate>
                    {({ translate }) => {
                        return <div className={classes.paddingResponse}>
                            <TextField
                                className={`${classes.responsiveWidth} ${isInvalidValid() ? "Mui-error" : "valid"}`}
                                id="standard-helperText"
                                label={isProfessional ? translate("Employer.RecoverPassCaption2") : translate("Employer.RecoverPassCaption")}
                                onChange={handleChange}
                                name="email"
                                value={formData.email}
                                helperText={renderError()}
                                placeholder={translate("Employer.PlaceholderEmail")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            {!completed && <Grid container className={`${classes.buttons} ${classes.centerXs}`}>
                                <Grid item className={`${classes.buttonsContainer} pb-5`}>
                                    <Button className={classes.submit} variant="contained"
                                        color={isProfessional ? "primary" : "secondary"} type="submit">
                                        <Typography className={classes.buttomtext}><Translate
                                            id={"Buttons.RecoverPass"} /></Typography>
                                    </Button>
                                </Grid>
                            </Grid>}
                        </div>
                    }}
                </Translate>
            </ValidatorForm>
        </Grid>

        {completed && <Grid item xs={12}>
            <Typography className={classes.notifyText}>{<Translate id={"Employer.RecoverPassNotify"} />} </Typography>
        </Grid>}

    </Grid>);
}

export default withRouter(RecoverPassword);