import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {Button, Hidden, useMediaQuery} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StepConnector from "@material-ui/core/StepConnector";
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import TimerIndicator from "./timerIndicator";
import * as permissions from "../../utils/permissions";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem"
        }

    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        minHeight: 110,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            minHeight: 85,
            paddingBottom: "0 !important"
        },
    },
    backgroundSecondary: {
        background: theme.palette.secondary.main,
    },
    colorEndButton: {
        background: '#45BA4E',
        '&:hover': {
            backgroundColor: '#45BA4E',
        }
    },
    questionTimer: {
        margin: -5,
        background: "transparent",
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },
    },
    stepper: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },
        [theme.breakpoints.up('xs')]: {
            '@media (min-height: 200px) and (max-height: 700px)': {
                padding: 0
            }
        }
    },
    backgroundGrey: {
        [theme.breakpoints.only('xs')]: {
            backgroundColor: theme.palette.common.gray,
        }
    },
    ready: {
        minHeight: 45,
        paddingTop: 18,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 5,
            minHeight: 30
        },
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 240,
        },
        [theme.breakpoints.down('sm')]: {
            padding: "5px 10px",
            maxWidth: "100%",
            height: 40
        },
        [theme.breakpoints.up('md')]: {
            //minWidth: 240,
        }
    },
    z2: {
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "0 !important"
        },

    },
    buttonCancel: {
        //marginBottom: 65,
    },
    p0: {
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    py0: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    pt3: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: "1rem",
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: 10
        }
    },
    mtxs1: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 5
        }
    }
}));

const ColorlibConnector = withStyles({
    root: {
        display: "none"
    },
    active: {
        '& $line': {
            border: 'none',
        },
    },
    completed: {
        '& $line': {
            border: 'none',
        },
    },
    line: {
        height: 0,
        border: 0,
    },
    step: {
        flex: 0
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        opacity: 1,
        display: "table-cell !important",
        verticalAlign: "middle",
        paddingTop: 0,
        fontWeight: 600,
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            width: 26,
            height: 26,
        },
    },
    circle: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        opacity: 1,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            width: 20,
            height: 20,
        },
    },
    backgroundPrimary: {
        background: theme.palette.primary.main,
        fontSize: 18,
        display: 'flex',
    },
    backgroundSecondary: {
        background: theme.palette.secondary.main,
        zIndex: 1,
        fontSize: 18,
        display: 'flex',
    },
    text: {
        //margin: "auto",
        marginTop: -2,
        fontSize: 18,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
            marginTop: 0,
        },
    },
    hiddenTooltip: {
        background: "transparent",
        display: "none"
    },
    customToolTip: {
        backgroundColor: "rgb(255, 255, 225);",
        border: "1px solid rgb(195, 195, 195)",
        color: "rgb(20, 20, 20)",
        textAlign: "center",
        paddingBottom: 3,
        paddingTop: 0,
        fontWeight: 400
    }
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed, icon, steps, play} = props;
    const activeQuestion = steps[icon - 1];
    return (<div className={clsx(classes.root, {[classes.active]: active,})}>
            {completed || active ?
                <Tooltip classes={{tooltip: play ? classes.hiddenTooltip : classes.customToolTip}}
                         title={activeQuestion ? activeQuestion : activeQuestion.question} placement="top">
                    <div className={`${classes.backgroundPrimary} ${active ? classes.active : classes.circle}`}>
                        <Typography variant="inherit" className={classes.text}>{icon}</Typography>
                    </div>
                </Tooltip>
                : <Tooltip classes={{tooltip: play ? classes.hiddenTooltip : classes.customToolTip}}
                           title={activeQuestion ? activeQuestion : activeQuestion.question} placement="top">
                    <div className={`${classes.circle} ${classes.backgroundSecondary}`}>
                        <Typography variant="inherit" className={classes.text}>{icon}</Typography>
                    </div>
                </Tooltip>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};


export default function HorizontalLabelPositionBelowStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [hiddenButton, setHiddenButton] = React.useState(0);
    const [startRecording, setStartRecording] = React.useState(0);
    const [endRecord, setEndRecord] = React.useState(0);
    const [granted, setGranted] = React.useState(false);

    const {steps, seconds, unCancelLastStep, play, unsuportRecorder, endButtonLabel} = props;
    let extraHeight = props.extraHeight ? props.extraHeight : 0;

    const getStepContent = (stepIndex) => {
        if (stepIndex < steps.length) return steps[stepIndex];
        return 'Unknown stepIndex';
    }

    useEffect(() => {
        if (props.recordCompleted) completeEndInterview();
        if (unCancelLastStep) {
            setHiddenButton(1);
            setEndRecord(0);
            props.handleUnCancelRecorder();
        }
        loadGranted().then(response => {
            if (response) setGranted(true);
        })
    }, [seconds, activeStep, unCancelLastStep, granted, unsuportRecorder]);

    useEffect(()=>{
        loadGranted().then(response => {
            if (response) setGranted(true);
        })
    }, [props])

    async function loadGranted() {
        return await permissions.permissionsConceded();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setHiddenButton(1)
        handlePauseRecord();
    };

    const handleKeepRecording = () => {
        setHiddenButton(0)
        handleResumeRecord();
        if (activeStep + 1 === steps.length)
            setEndRecord(1);
    };

    const handleEndInterview = () => {
        if (activeStep + 1 === steps.length) handleStopRecord();
    };

    const completeEndInterview = () => {
        setActiveStep(0);
        setHiddenButton(0)
        setStartRecording(0)
        setEndRecord(0);
    }

    const handleCancel = () => {
        if (props.cancelRecordInterview) props.cancelRecordInterview();
    };

    const handleStartRecording = async () => {
        if (await loadGranted()) {
            setStartRecording(1)
            handleRecord();
        }
    }

    const handleRecord = () => {
        if (activeStep === 0) {
            if (props.handleStartRecorder) props.handleStartRecorder();
        }
    }

    const handlePauseRecord = () => {
        if (props.handlePauseRecorder) props.handlePauseRecorder(activeStep);
    }

    const handleResumeRecord = () => {
        if (props.handleResumeRecorder) props.handleResumeRecorder();
    }

    const handleStopRecord = () => {
        if (props.handleStopRecorder) props.handleStopRecorder(activeStep);
    }
    const {innerWidth: width, innerHeight: height} = window;
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLgOnly = useMediaQuery(theme.breakpoints.only('lg'));
    const maxSmHeightVideo = 484;
    const iphoneHeight = 667;
    return (
        <div className={classes.root}>
            <Grid container spacing={2} className="text-center">
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep} alternativeLabel={false} connector={<ColorlibConnector/>}
                             className={`${classes.stepper} justify-content-center ${screenXsDown && height > iphoneHeight ? "py-3" : "py-1"}`}>
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel
                                    StepIconComponent={(props) => QontoStepIcon({...props, steps: steps, play: play})}/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <div style={screenXsDown ? {
                    height: `calc(100vh - ${(props.heightVideo) + 140 + extraHeight}px)`,
                    overflowY: "scroll",
                    width: "100%"
                } : (screenSmOnly && height <= maxSmHeightVideo ? {
                    height: props.heightVideo - 73,
                    overflowY: "scroll",
                    width: "100%"
                } : (screenXlOnly ? {paddingTop: 16, width: "100%"} : {width: "100%"}))}>
                    <Grid container spacing={0} className={`${classes.questionTimer} `}>
                        <Grid item xs={12} className={`${classes.z2} ${classes.p0}`}>
                            <Typography variant="h2" className={`${classes.instructions} px-2`}>
                                {getStepContent(activeStep)}
                            </Typography>
                        </Grid>
                        {(screenXsDown || height <= maxSmHeightVideo) &&
                        <Grid item xs={12} lg={12}
                              className={`text-center px-5 ${screenXsDown && height > iphoneHeight && "py-3"}`}
                              style={{zIndex: 3}}>
                            <TimerIndicator key="recorder" paused={props.paused} play={props.play}
                                            value={props.value}
                                            maxValue={props.maxValue}/>
                        </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} className={`${classes.ready}`}>
                        {hiddenButton !== 0 ? <Translate>
                            {({translate}) => (
                                <Typography variant="h4">
                                    {translate("Titles.IamReady")}
                                </Typography>
                            )}
                        </Translate> : <div/>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.pt3}>
                        {hiddenButton === 0 && endRecord === 0 ? <div>
                                {startRecording === 0 ?
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" onClick={handleStartRecording}
                                                    disabled={!granted || unsuportRecorder}
                                                    className={classes.button}>
                                                {translate("Buttons.StartRecording")}
                                            </Button>
                                        )}
                                    </Translate> :
                                    <Translate>
                                        {({translate}) => (
                                            <Button variant="contained" color="primary" onClick={handleNext}
                                                    disabled={seconds === 0 || !granted || unsuportRecorder}
                                                    className={classes.button}>
                                                {translate("Buttons.NextQuestion")}
                                            </Button>
                                        )}
                                    </Translate>
                                }

                            </div>
                            : <div>
                                {
                                    activeStep !== steps.length - 1 || endRecord === 0 ?
                                        <Translate>
                                            {({translate}) => (
                                                <Button variant="contained" color="secondary"
                                                        className={`${classes.backgroundSecondary} ${classes.button}`}
                                                        onClick={handleKeepRecording}>
                                                    {translate("Buttons.KeepRecording")}
                                                </Button>
                                            )}
                                        </Translate> :
                                        <Translate>
                                            {({translate}) => (
                                                <Button variant="contained" color="secondary"
                                                        className={`${classes.colorEndButton} ${classes.button}`}
                                                        onClick={handleEndInterview}>
                                                    {endButtonLabel ? endButtonLabel : translate("Buttons.EndInterview")}
                                                </Button>
                                            )}
                                        </Translate>
                                }

                            </div>}
                    </Grid>
                    <Grid item xs={12}
                          className={`${classes.pt3} ${screenXsDown && height > iphoneHeight ? "pt-2" : classes.mtxs1}`}
                          style={{paddingBottom: screenXsDown ? extraHeight : 0}}>
                        <Translate>
                            {({translate}) => (
                                <Button variant="outlined" color="default" onClick={handleCancel}
                                        className={`${classes.button} ${classes.buttonCancel}`}
                                        hidden={props.seconds === 0}
                                        disabled={activeStep === 0 && props.seconds <= 0}
                                        style={screenLgOnly ? (height > 638 && height < 720 ? {marginBottom: -5} : (height > 719 && height < 855 ? {marginBottom: 20} : (height > 854 && height < 1120 ? {marginBottom: 75} : (height > 1119 && height < 1280 ? {marginBottom: -43} : {})))) : {}}>
                                    {translate("Buttons.CancelRecording")}
                                </Button>
                            )}
                        </Translate>
                    </Grid>
                </div>
            </Grid>
        </div>
    );
}