import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles(() => ({
    imgResponsive: {
        width: "auto",
        height: "auto",
        maxWidth: "100%"
    },
    title: {
        fontWeight: 600,
        marginBottom: 20
    }
}));

export default function FirefoxPc(props) {

    let classes = useStyles();
    return (<Translate>
        {({translate}) => {
           return <Grid id="firefox" key="firefox" container className="mb-5">
                <Typography className={classes.title} variant="h2"><Translate id="Help.FirefoxTitle"/></Typography>
                <Grid item xs={12}>
                    <div hidden>
                        <Typography color="error"><Translate id="Help.GenericText1"/></Typography>
                        <Typography color="error"><Translate id="Help.GenericText2"/></Typography>
                    </div>
                    <Typography className="mt-3 font-weight-bold" color="primary"><Translate
                        id="Help.GenericText3"/></Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={`${classes.title} my-4`} variant="h3"><Translate
                        id="Help.Option1"/></Typography>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.FirefoxStep1"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")}  className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/1.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.FirefoxStep2"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")}  className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/2.jpg")}/>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography className="mr-1">3. <Translate id="Help.ChromeStep3"/></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                        <Typography className="ml-1"><Translate id="Help.AllowAccess"/>.</Typography>
                    </div>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/3.jpg")}/>
                </Grid>


                <Typography className={`${classes.title} my-4`} variant="h3"><Translate id="Help.Option2"/></Typography>
                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.FirefoxStep3"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/4.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.FirefoxStep4"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/5.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.FirefoxStep5"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/6.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>4. <Translate id="Help.FirefoxStep6"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/7.jpg")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>5. <Translate id="Help.FirefoxStep7"/></Typography>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/8.jpg")}/>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <Typography>7. <Translate id="Help.FirefoxStep8"/></Typography>
                </Grid>

                <Grid item xs={12} className="mb-5">
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Typography className="mr-1">8. <Translate id="Help.ChromeStep3"/></Typography>
                        <div style={{maxWidth: 300}}>
                            {props.refresPageMessage()}
                        </div>
                        <Typography className="ml-1"><Translate id="Help.AllowAccess"/>.</Typography>
                    </div>
                    <img alt={translate("Help.FirefoxTitle")} className={classes.imgResponsive} src={require("../../../../images/support/firefoxpc/3.jpg")}/>
                </Grid>
            </Grid>
        }}
    </Translate>)
}