import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Container, Grid, useMediaQuery} from "@material-ui/core";
import 'react-quill/dist/quill.bubble.css';
import api from "../../api";
import {staticContent} from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
    container: {
        '& .ql-container': {
            fontSize: 16,
            fontFamily: 'Barlow, Arial, Helvetica, sans-serif',
            fontWeight: "normal",
            letterSpacing: "1.1px",
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
                letterSpacing: "0.08px"
            }
        }
    },
    text: {
        '& li': {
            textAlign: "justify",
            padding: "8px 0"
        },
        '& span': {
            paddingBottom: 8,
            paddingTop: 8,
            textAlign: "justify",
        },
        '& h1': {
            fontSize: 35,
            textAlign: "center",
            fontWeight: 600,
            color: "#7A7A7B",
            lineHeight: 1.2,
            paddingTop: 40,
            paddingBottom: 24,
        },
        '& h2': {
            fontSize: 22,
            fontWeight: 600,
            color: "#141414",
            paddingTop: 24,
            paddingBottom: 8,
        },
        [theme.breakpoints.only('xs')]: {
            '& h1': {
                fontSize: 26,
                textAlign: "left",
                paddingTop: 26,
            },
            '& h2': {
                fontSize: 20,
            },
            '& .table-scroll': {
                overflowX: "scroll"
            }
        },

    }
}));

export default function SimplePaper() {
    const classes = useStyles();
    let [textValue, setTextValue] = useState([]);
    useEffect(() => {
        api.home.getContent(staticContent.HiringPolicy).then(response => {
            setTextValue(response);
        });
    }, [textValue])
    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    return (<Grid>
            <Grid item xs={12} style={{minHeight: 310}}>
                <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                    <Grid item xs={12} className="pb-5">
                        <div className={classes.text} dangerouslySetInnerHTML={{__html: textValue}}/>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}