import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Container, Typography} from "@material-ui/core";
import {Translate} from "react-localize-redux";

const useStyles = makeStyles((theme) => ({
    imgResponsive: {
        width: "auto",
        height: "auto",
        maxWidth: "50%",
        [theme.breakpoints.only('xs')]: {
            maxWidth: "100%"
        }
    },
    title: {
        fontWeight: 600,
        marginBottom: 20
    }
}));

export default function ChromeAndroid(props) {

    let classes = useStyles();
    return (<Translate>
        {({translate}) => {
            return <Grid container className="mb-5">
                <Typography className={classes.title} variant="h2"><Translate
                    id="Help.ChromeAndroidTitle"/></Typography>
                <Grid item xs={12}>
                    <div hidden>
                        <Typography color="error"><Translate id="Help.GenericText1"/></Typography>
                        <Typography color="error"><Translate id="Help.GenericText2"/></Typography>
                    </div>
                    <Typography className="mt-3 font-weight-bold" color="primary"><Translate
                        id="Help.GenericText3"/></Typography>
                </Grid>
                <Typography className={`${classes.title} my-4`} variant="h3"><Translate id="Help.Option1"/></Typography>

                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.ChromeAndroidStep1"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/1.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.ChromeAndroidStep2"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/1-2.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.ChromeAndroidStep3"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/2.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>4. <Translate id="Help.ChromeAndroidStep4"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/3.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>5. <Translate id="Help.ChromeAndroidStep5"/></Typography>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>6. <Translate id="Help.ChromeStep3"/></Typography>
                    {props.refresPageMessage()}
                </Grid>

                <Typography className={`${classes.title} my-4`} variant="h3"><Translate id="Help.Option2"/></Typography>
                <Grid item xs={12} className="mb-5">
                    <Typography>1. <Translate id="Help.ChromeAndroidStep6"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/4.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>2. <Translate id="Help.ChromeAndroidStep7"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/5.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>3. <Translate id="Help.ChromeAndroidStep8"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/6.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>4. <Translate id="Help.ChromeAndroidStep9"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/7.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>5. <Translate id="Help.ChromeAndroidStep10"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/8.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>6. <Translate id="Help.ChromeAndroidStep11"/></Typography>
                    <img alt={translate("Help.ChromeTitle")} className={classes.imgResponsive}
                         src={require("../../../../images/support/chromeandroid/2.png")}/>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>7. <Translate id="Help.ChromeAndroidStep12"/></Typography>
                </Grid>
                <Grid item xs={12} className="mb-5">
                    <Typography>8. <Translate id="Help.ChromeStep3"/></Typography>
                    {props.refresPageMessage()}
                </Grid>
            </Grid>
        }}
    </Translate>)
}