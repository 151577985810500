import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, List, ListItem, ListItemText, Button, Paper, useMediaQuery, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Translate, withLocalize } from "react-localize-redux";
import { filter, findIndex, split } from "lodash";
import { connect } from 'react-redux';
import * as types from '../../../actions/users/index';
import * as interviewTypes from '../../../actions/interview/index';
import api from "../../../api/index";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 390,
        [theme.breakpoints.up('md')]: {
            width: 365
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 320
        },
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        margin: 'auto'
    },
    listItem: {
        [theme.breakpoints.only('xs')]: {
            height: 28,
        },
        [theme.breakpoints.down('sm')]: {
            width: 234
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 32,
        },
        height: 32,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 19,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            borderColor: theme.palette.primary.main,
            '& .MuiInputBase-input': {
                color: "#FFFFFF"
            },
        },
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto"
    },
    listItemActive: {
        [theme.breakpoints.only('xs')]: {
            height: 28,
        },
        [theme.breakpoints.down('sm')]: {
            width: 234
        },
        [theme.breakpoints.only('lg')]: {
            height: window.innerHeight >= 638 && window.innerHeight <= 720 ? 28 : 32,
        },
        height: 32,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 18,
        opacity: 1,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        textTransform: "uppercase",
        fontSize: 18,
        fontFamily: '"Kumbh Sans", Arial, Helvetica, sans-serif',
        fontWeight: 300,
        letterSpacing: "0.13px",
        "&:focus, &:hover, &$active": {
            backgroundColor: theme.palette.primary.main,
            //color: "#FFFFFF",
            '& .MuiInputBase-input': {
                //color: "#FFFFFF",
            },
            '& .MuiInput-underline:hover': {
                '& .MuiInputBase-input': {
                    color: theme.palette.secondary.main,
                }
            }
        },
        display: "flex",
        justifyContent: "center",
        "&.Mui-focusVisible": {
            backgroundColor: theme.palette.primary.main
        },
        marginLeft: "auto",
        marginRight: "auto"
    },
    timePicker: {
        width: 100,
        [theme.breakpoints.down('sm')]: {
            width: 75,
            '& .MuiIconButton-root': {
                paddingRight: 0
            }
        },
        '& .MuiInput-underline': {
            border: "none",
        },
        '&$focused &:focus &:hover': {
            border: "none",
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        },
        "& .MuiInputBase-input": {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            },
        }
    },
    timePickerActive: {
        width: 100,
        '& input': {
            //backgroundColor: "white",
            color: `${theme.palette.common.white} !important`,
            fontWeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            width: 75,
            '& .MuiIconButton-root': {
                paddingRight: 0
            }
        },
        '& .MuiInput-underline': {
            //color: "#FFFFFF",
        },
        '& .MuiInput-underline:hover': {
            border: "none",
            color: theme.palette.secondary.main,
            //backgroundColor: theme.palette.background.paper,
        },
        '& .MuiInput-underline:before': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:after': {
            border: "none",
            '&$focused &:focus &:hover': {
                border: "none",
            }
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: "none",
        },
        '& .MuiFormHelperText-root.Mui-error': {
            display: "none"
        },
        "& .MuiInputBase-input": {
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            },
        }
    },
    questionView: {
        ...theme.overrides.MuiTypography.questionView
    },
    body4: {
        ...theme.overrides.MuiTypography.body4
    },
    box: {
        borderRadius: 38,
        boxShadow: "21px 26px 85px #00000010",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
    },
    mb: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8
        },
        marginBottom: 12
    },
    my: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
            marginTop: 8
        },
        marginBottom: 12,
        marginTop: 12
    },
    customWidth: {
        maxWidth: 500,
        textAlign: 'center',
        display: "none"
    },
    questions: {
        minHeight: 40,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            minHeight: 72,
        }
    },
    question2: {
        ...theme.overrides.MuiTypography.question3,
        lineHeight: 1.1,
        fontSize: 30,
    },
    adjustTimes2: {
        ...theme.overrides.MuiTypography.body3,
        [theme.breakpoints.only('xs')]: {
            marginTop: 8
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 18 : theme.overrides.MuiTypography.body3.fontSize
        },
    },
    adjustTimes: {
        ...theme.overrides.MuiTypography.body3,
        [theme.breakpoints.only('lg')]: {
            fontSize: window.innerHeight >= 638 && window.innerHeight <= 720 ? 18 : theme.overrides.MuiTypography.body3.fontSize
        },
    },
    readyMax: {

    },
    edit: {
        '&.active input': {
            backgroundColor: "white",
            color: `${theme.palette.primary.main} !important`
        },
    }
}));


function ConfigureTimes(props) {
    let { history } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [questions, setQuestions] = useState(null);
    const [indexActiveQuestion, setIndexActiveQuestion] = useState(-1);

    const timeRecordConfiguration = useSelector(state=> state.configurations.configs && state.configurations.configs.MaxRecordTime ? parseInt(state.configurations.configs.MaxRecordTime) : 180);
    const [maxValueRecordConfig, setMaxValueRecordConfig] = useState(timeRecordConfiguration);
    const [maxValueTextRecord, setMaxValueTextRecord] = useState("");

    const [maxValueRecord, setMaxValueRecord] = useState(timeRecordConfiguration);
    const interviewMetadatas = useSelector(state => state.users.interview.metadata);
    let [indexEdit, setIndexEdit] = useState(-1);


    useEffect(() => {
        defineActionHeader();
        loadQuestions();
    }, [questions, indexActiveQuestion])

    function loadQuestions() {
        if (questions === null) {
            api.interview.getProfessionalInterviewQuestions().then((response) => {
                let { data } = response;
                let maxDurationValue = 0;
                if (data) {
                    let mapped = interviewMetadatas && interviewMetadatas.length
                        ? data.map((quest, index) => {
                            let meta = interviewMetadatas.find(x => x.index === quest.index);
                            if (meta) {
                                quest.suggestDurationSeconds = meta.duration;
                                quest.durationFormat = meta.durationFormat;
                            }
                            return quest;
                        })
                        : data;

                    setQuestions(mapped)
                    if (mapped && mapped.length > 0) {
                        maxDurationValue = mapped.map(x => x.suggestDurationSeconds).reduce((accumulator, value) => accumulator + value);
                        setMaxValueRecord(maxDurationValue)
                    }
                    validateQuestions(mapped);
                }
            }).catch((err) => {
                setQuestions([]);
            })
        }
    }

    const handleCurrentQuestion = (index) => {
        setIndexActiveQuestion(index);
        //if (index !== indexEdit) setIndexEdit(-1);
    };

    const handleChangeTime = (mon, index) => {
        let aux = [];
        let seconds = parseInt(moment(mon).format('ss'));
        let min = parseInt(moment(mon).format('mm'));
        let maxTime = 0;
        Object.assign(aux, questions);
        let indexQuestion = findIndex(questions, function (q) {
            return q.index === index;
        })
        if (indexQuestion !== -1) {
            aux[indexQuestion].durationFormat = moment(mon).format('mm:ss');
            aux[indexQuestion].suggestDurationSeconds = min * 60 + seconds;
            aux.map(question => {
                maxTime = maxTime + question.suggestDurationSeconds
            })

            if (maxTime > maxValueRecordConfig) {
                setMaxValueTextRecord(props.translate('Titles.AdjustTotalTimes'))
            } else if (min === 0 && seconds === 0) {
                setMaxValueTextRecord(props.translate('Titles.AdjustTimesCero'))
            } else {
                setMaxValueTextRecord("");
            }
            if (mon._isValid === true)
                setMaxValueRecord(maxTime);
            setQuestions(aux);
            validateQuestions();
        }
    };

    const handleError = (error) => {
        if (error !== "")
            setMaxValueTextRecord(props.translate('Titles.InvalidTimeFormat'));
    };

    function validateQuestions(data = []) {
        data = data && data.length > 0 ? data : questions ? questions : [];
        if (data.length === 0) return;

        if (data && data.some(x => x.suggestDurationSeconds === 0)) {
            setMaxValueTextRecord(props.translate('Titles.AdjustTimesCero'));
        }
        else if (data && data.map(x => x.suggestDurationSeconds).reduce((accumulator, value) => accumulator + value) > maxValueRecordConfig) {
            setMaxValueTextRecord(props.translate('Titles.AdjustTotalTimes'))
        }
        else {
            setMaxValueTextRecord("");
        }
    }

    function defineActionHeader() {
        dispatch(interviewTypes.setPausedInterviewAction({
            path: window.location.pathname,
            component: "close",
            callBack: () => history.push("/interview")
        }))
    }

    function valueLabelFormat() {
        let minutes = parseInt(maxValueRecord / 60);
        let seconds = maxValueRecord % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
    }

    function saveConfigTimes() {
        let metadatas = [];
        let endDuration = 0;
        for (let i = 0; i < questions.length; i++) {
            let question = questions[i];
            endDuration += question.suggestDurationSeconds;

            let lastMeta = metadatas.length > 0 ? metadatas[metadatas.length - 1] : null;
            let start = lastMeta ? lastMeta.end : 0;
            let end = endDuration;

            let meta = {
                start: start,
                end: end,
                duration: question.suggestDurationSeconds,
                durationFormat: question.durationFormat,
                suggestDurationSeconds: question.suggestDurationSeconds,
                question: questions[i].text,
                index: i
            };
            metadatas.push(meta);
        }
        dispatch(types.setMetadata(metadatas));
        history.push("/run-interview")
    }

    function focusInput(index) {
        let input = document.getElementById(`picker_${index}`);
        let item = document.getElementById(`item_${index}`);
        item.focus();
        input.focus();
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (<Grid container spacing={0} className="justify-content-center">
        <Grid item xs={12} className={`${fullScreen ? 'text-center' : 'text-left'} py-0`}>
            <Typography variant="h1" gutterBottom className="text-center">
                <Translate id={"Titles.VideoInterview"} />
            </Typography>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: 980 }} className="justify-content-center" >
            <Paper elevation={fullScreen ? 0 : 3} className={`${fullScreen ? {} : classes.box} pb-2`}>
                <Grid item xs={12}
                    className={`${classes.heightMaxTime} text-center pb-0 position-relative`}>
                    <Typography color={maxValueTextRecord === "" ? "primary" : "error"}
                        className={`${classes.adjustTimes2} mb-0`}>
                        {maxValueTextRecord === "" ? <Translate id={"Titles.SuggestStatrt"} /> : maxValueTextRecord}
                    </Typography>
                    <Typography color={maxValueTextRecord === "" ? "primary" : "error"}
                        className={`${classes.adjustTimes} mt-0 ${classes.readyMax}`}>
                        <Translate id={"Titles.MaxTime"} />
                    </Typography>
                </Grid>

                <Grid item xs={12} className={`text-center mt-3 ${classes.questions}`}>
                    <Typography className={`${classes.question2} `}>
                        {indexActiveQuestion !== -1 && questions && questions.length > 0 ? questions[indexActiveQuestion].text : ""}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={`text-center pb-0 ${classes.questionsMargin} ${classes.backgroundGrey}`}>
                    {questions && <Translate>
                        {({ translate }) => (
                            <List className={classes.root}>
                                {questions && questions.length > 0 && questions.map((question, index) => {
                                    return <Tooltip title={question.text} placement="top" classes={{ tooltip: classes.customWidth }}>
                                        <ListItem button key={question.index} alignItems="center"
                                            id={`item_${index}`}
                                            autoFocus={question.index === indexActiveQuestion}
                                            onClick={() => { handleCurrentQuestion(question.index); focusInput(index); }}
                                            className={`${question.index === indexActiveQuestion ? classes.listItemActive : classes.listItem} ${index === 0 ? classes.mb : classes.my} `}>

                                            <Typography gutterBottom style={{color: question.index === indexActiveQuestion ? "white" : "#141414"}} className={`mb-0 pr-2 ${classes.body1}`}>
                                                {`${translate("Titles.Question")} ${question.index + 1} -`}
                                            </Typography>

                                            <KeyboardTimePicker key={index}
                                                open={false}
                                                id={`picker_${index}`}
                                                className={`${index === indexActiveQuestion ? classes.timePickerActive : classes.timePicker} ${question.index === indexActiveQuestion ? "active" : ""} ${indexEdit === index ? classes.edit : ""}`}
                                                ampm={false}
                                                variant="inline"
                                                openTo="minutes"
                                                views={["minutes", "seconds"]}
                                                format="mm:ss"
                                                keyboardIcon={<Tooltip title={translate("Titles.ChangeTime")}>
                                                    <Edit disabled={false} onClick={() => {
                                                        setIndexEdit(index)
                                                        focusInput(index);
                                                    }} />
                                                </Tooltip>}
                                                value={moment({
                                                    year: 2020,
                                                    month: 1,
                                                    day: 1,
                                                    hour: 0,
                                                    minute: split(question.durationFormat, ':')[0],
                                                    second: split(question.durationFormat, ':')[1]
                                                })}
                                                onChange={(mon) => handleChangeTime(mon, question.index)}
                                                onError={(error) => handleError(error)}
                                            />
                                        </ListItem>
                                    </Tooltip>
                                })}
                            </List>
                        )}
                    </Translate>}
                </Grid>

                <Grid item xs={12} className="py-2">
                    <Typography className="text-center" color={maxValueTextRecord !== "" ? "error" : "primary"} variant="h2">
                        Total {valueLabelFormat()}
                    </Typography>
                </Grid>


                <Grid item xs={12} className="text-center">
                    <Translate>
                        {({ translate }) => (
                            <Button variant="contained" color="primary"
                                disabled={maxValueRecord > maxValueRecordConfig || maxValueTextRecord !== ""}
                                onClick={saveConfigTimes}>
                                {translate("Buttons.Accept")}
                            </Button>
                        )}
                    </Translate>
                </Grid>
            </Paper>
        </Grid>
    </Grid>);
}

export default withLocalize(ConfigureTimes);