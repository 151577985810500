import React from 'react'
import { Typography, Grid } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { isSafari } from "react-device-detect";
import { NavLink } from "react-router-dom";

const UnsupportedView = () => {
    return (<Grid container>
        <Grid item xs={12}>
            <Typography variant="h5" color="error"><Translate id={"Errors.UnsupportedRecord"} /></Typography>
        </Grid>
        <Grid>
            {isSafari &&
                <Typography variant="h5" color="error"><Translate id={"Errors.SafariSuggest"} /> <NavLink to="/safari-help">link.</NavLink></Typography>}
        </Grid>
    </Grid>);
}

export default UnsupportedView