import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FooterImage from "../../images/offer/footer-opacity.png";
import FooterImageMovil from "../../images/offer/footer-opacity.png";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import * as actions from "../../actions/users/index";
import {Link, useMediaQuery} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FooterPoint from "../../images/layuout/footerPoints.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Button, CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundImage: `url('${FooterImage}')`,
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: 100,
        objectFit: "cover",
        display: "flex",
        alignItems: "center",
        backgroundSize: '100%',
        [theme.breakpoints.only('xs')]: {
            backgroundImage: `url('${FooterImageMovil}')`,
            height: 203,
            alignItems: "unset",
        },
        [theme.breakpoints.only('xl')]: {
            width: 1920,
        },
    },
    footerPoints: {
        zIndex: 1,
        backgroundImage: `url('${FooterPoint}')`,
        backgroundSize: "cover",
        width: 860,
        height: 230,
        position: "absolute",
        left: "21%",
        bottom: 0,
        [theme.breakpoints.only('xs')]: {
            left: 0,
            width: "100%",
            backgroundSize: "auto",
            backgroundPositionX: "left",
            backgroundPositionY: "center",
        },
        [theme.breakpoints.only('sm')]: {
            left: 0,
            width: "100%",
            backgroundSize: "auto",
            backgroundPositionX: "left",
            backgroundPositionY: "center",
        },
        [theme.breakpoints.only('md')]: {
            width: 450,
            left: "16%"
        },
        [theme.breakpoints.only('lg')]: {
            width: 536,
            left: "23%",
        },
    },
    icon: {
        backgroundColor: "#FFFFFF",
        marginLeft: 10,
        marginRight: 10,
        height: 40,
        width: 40,
        paddingTop: 0,
        paddingBottom: 0,
        cursor: "pointer",
        //zIndex: 5550,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        '& .MuiSvgIcon-root': {
            height: 27,
            width: 27,
        }
    },
    followUs: {
        fontSize: 17,
        letterSpacing: 0.71,
        color: "#FFFFFF"
    },
    fontLanguage: {
        fontSize: 20,
        fontStyle: "italic",
        justifyContent: "space-evenly",
        zIndex: 5
    },
    divider: {
        width: 54,
        height: 1,
        position: "absolute",
    },
    colorPrimary: {
        backgroundColor: theme.palette.secondary.main
    },
    colorBlack: {
        backgroundColor: "#FFFFFF"
    },
    colorTextPrimary: {
        // cursor: "pointer",
        zIndex: 50,
        fontSize: 20,
        color: theme.palette.secondary.main,
        /*'&:hover': {
            color: theme.palette.secondary.main
        }*/
    },
    colorTextBlack: {
        color: "#FFFFFF",
        cursor: "pointer",
        zIndex: 50,
        '&:hover': {
            color: "#FFFFFF"
        }
    },
    Arrow: {
        color: "#FFF",
        position: "absolute",
        right: -18,
        top: 35
    },
    item: {
        width: "auto",
        marginRight: 54
    },
    itemText: {
        fontSize: 17,
        letterSpacing: 0.71,
        color: "#7A7A7B",
    },
    dividerItem: {
        width: 0,
        height: 23,
        border: "1px solid #7A7A7B",
        marginRight: 12,
    },
    list: {
        width: "100%",
        display: "flex",
    },
    displayGrid: {
        display: "grid",
        justifyContent: "center"
    },
    topCopy: {
        paddingTop: 37,
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 42,
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: 56,
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "center"
    },
    btn: {
        borderRadius: 0,
        marginBottom: 20
    },
    label: {
        fontSize: 18
    }
}));

export default function FooterCompose(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    let {sending} = props;

    function renderItem(text) {
        return <ListItem disableGutters className={classes.item}>
            <Divider className={classes.dividerItem}/>
            <Translate>
                {({translate}) => {
                    return <ListItemText primary={translate(text)} className={classes.itemText}/>
                }}
            </Translate>

        </ListItem>
    }

    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.footer}>
                <Grid container spacing={0}>
                    <Grid item xs={0} sm={1} md={4}/>
                    <Grid container spacing={0} justify="center">

                        <Grid item xs={12}
                              className={`${classes.followUs} d-flex justify-content-center pb-3 ${screenXsOnly && "align-items-end"}`}>
                            <Typography variant="inherit">
                                <Translate id="Titles.FollowUs"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className="d-flex justify-content-center">
                            <IconButton color="primary" aria-label="Facebook" className={classes.icon}>
                                <FacebookIcon/>
                            </IconButton>
                            <IconButton color="primary" aria-label="YouTube" className={classes.icon}>
                                <YouTubeIcon/>
                            </IconButton>
                            <IconButton color="primary" aria-label="Twitter" className={classes.icon}>
                                <TwitterIcon/>
                            </IconButton>
                            <IconButton color="primary" aria-label="LinkedIn" className={classes.icon}>
                                <LinkedInIcon/>
                            </IconButton>
                            <IconButton color="primary" aria-label="Instagram" className={classes.icon}>
                                <InstagramIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="position-relative">
                {<div className={classes.footerPoints}/>}
                <Grid container spacing={0} direction="row" justify="center" alignItems="center"
                      style={{minHeight: 90}}>
                    <Grid item xs={3} className={classes.displayGrid} style={{backgroundColor: "#FFFFFF"}}>
                        <List component="nav" aria-label="footerMap" className={!screenXsOnly && classes.list}>
                            {renderItem("Titles.PrivacyPolicy")}
                            <ListItem disableGutters className={classes.item}>
                                <Divider className={classes.dividerItem}/>
                                <ListItemText primary={`© Hihired! ${new Date().getFullYear()}`}
                                              className={classes.itemText}/>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.btnContainer}>
                <Button variant="contained" color="secondary" className={classes.btn}
                        onClick={() => {
                            if (props.composeEmail) props.composeEmail();
                        }}
                        disabled={sending}>
                    {sending && <CircularProgress color="primary" className="mx-2" size={30}/>}
                    <Typography className={`font-weight-bold ${classes.label}`}>
                        <Translate id={"Buttons.Send"}/>
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}