import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import {
    Grid, Paper, Typography, FormControlLabel, Tooltip, TextField, ListItemSecondaryAction, InputBase, Popper, Grow,
    ClickAwayListener, MenuItem, MenuList, Button, Avatar, ListItem
} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import api from "../../api";
import {Translate} from "react-localize-redux";
import {CircularProgress, IconButton, InputAdornment, useMediaQuery} from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import InfoIcon from '@material-ui/icons/Info';
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import List from "@material-ui/core/List";
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import AddIcon from '@material-ui/icons/Add';
import {NavLink, useHistory} from "react-router-dom";
import GoBack from "../navigation/goBack";
import QuestionTypeMenu from "./questionTypeMenu";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import OrangeLaptop from "../../images/employer/2Lg.png";
import OrangeLaptopM from "../../images/employer/2Movil.png";
import Alert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import * as types from "../../actions/errors";
import {useDispatch} from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import {offersSatte, PaymentType} from "../../utils/constants";

function ProcessOffer(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const processId = props.match.params["id"] ? parseInt(props.match.params["id"]) : null;

    let [process, setProcess] = useState(null);

    let [countries, setCountries] = useState([]);
    let [provinces, setProvinces] = useState([]);
    let [municipalities, setMunicipalities] = useState([]);
    let [specialties, setSpecialties] = useState(null);
    let [categories, setCategories] = useState(null);
    let [employer, setEmployer] = useState(null);
    let [employmentRelationships, setEmploymentRelationships] = useState(null);
    let [workdays, setWorkdays] = useState(null);
    let [saving, setSaving] = useState(false);

    //publish: true => significa que se inicio una peticion al servidor para publicar
    let [publish, setPublish] = useState(false);

    //published: true => publicar, published: false => guardar borrador
    let [published, setPublished] = useState(true);

    let [deleting, setDeleting] = useState(false);
    let [country, setCountry] = useState(null);
    let [openDialogDelete, setOpenDialogDelete] = useState(false);
    let [ifChange, setIfChange] = useState(false);
    let [showView, setShowView] = useState(true);


    const useStyles = makeStyles((theme) => {
        theme.overrides.MuiInputLabel = {
            ...theme.overrides.MuiInputLabel,
            root: {
                ...theme.overrides.MuiInputLabel.root,
                fontWeight: "bold"
            }
        }
        return {
            ul: {
                zIndex: 1,
                "&>li": {
                    zIndex: -1
                }
            },
            text: {
                fontSize: 16,
                letterSpacing: 0.9,
                textAlign: "right",
                fontWeight: 600
            },
            title: {
                fontSize: 35,
                fontWeight: 600,
                //marginTop: 20,
                lineHeight: "normal",
                letterSpacing: 0,
                [theme.breakpoints.only('xs')]: {
                    fontSize: 30,
                    marginTop: 0
                }
            },
            responsiveWidth: {
                width: "100%",
                "& input": {
                    overflow: "hidden",

                },
                '& .MuiInputBase-root.Mui-error textarea': {
                    backgroundColor: "#FDE8E8"
                }

            },
            responsiveWidthXs: {
                width: "100%",
                "& input": {
                    overflow: "hidden",
                    minWidth: 88
                },
                '& .MuiInput-root': {
                    minWidth: 88
                }
            },
            divWidth: {
                [theme.breakpoints.only('md')]: {
                    width: "44.2%"
                },
                [theme.breakpoints.only('lg')]: {
                    width: "20.3%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "25.3%"
                },
                width: "100%"
            },
            divWidth33: {
                [theme.breakpoints.only('md')]: {
                    width: "27.5%",
                    "& input": {
                        overflow: "hidden",
                        minWidth: 100
                    },
                    '& .MuiInput-root': {
                        minWidth: 100
                    }
                },
                [theme.breakpoints.only('lg')]: {
                    width: "29%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "30.3%"
                },
                width: "100%"
            },
            divWidth4: {
                [theme.breakpoints.only('md')]: {
                    width: "27.2%",

                },
                [theme.breakpoints.only('lg')]: {
                    width: "20.6%",
                    "& input": {
                        overflow: "hidden",
                        '@media (min-width: 1366px) and (max-width: 1535px)': {
                            minWidth: 210
                        },
                        '@media (min-width: 1536px)': {
                            minWidth: 242
                        }
                    },
                    '& .MuiInput-root': {
                        '@media (min-width: 1366px) and (max-width: 1535px)': {
                            minWidth: 210
                        },
                        '@media (min-width: 1536px)': {
                            minWidth: 242
                        }
                    }
                },
                [theme.breakpoints.only('xl')]: {
                    width: "22%",
                    "& input": {
                        overflow: "hidden",
                        minWidth: 346

                    },
                    '& .MuiInput-root': {
                        minWidth: 346,

                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                        minWidth: 331
                    }
                },
                width: "100%"
            },
            divWidth2: {
                [theme.breakpoints.only('md')]: {
                    width: "44.2%",
                    "& input": {
                        overflow: "hidden",
                        minWidth: 100
                    },
                    '& .MuiInput-root': {
                        minWidth: 100
                    }
                },
                [theme.breakpoints.only('lg')]: {
                    width: "45.6%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "46.9%"
                },
                width: "100%",
                '&.MuiListItem-secondaryAction': {
                    width: "100%"
                }
            },
            divWidth5: {
                [theme.breakpoints.only('md')]: {
                    width: "100%",
                },
                [theme.breakpoints.only('lg')]: {
                    width: "45.3%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "46.9%"
                },
                width: "100%"
            },

            divWidthXs: {
                [theme.breakpoints.only('md')]: {
                    width: "14.3%"
                },
                [theme.breakpoints.only('lg')]: {
                    width: "10.3%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "7.3%"
                },
                width: "100%"
            },
            divWidthLg: {
                [theme.breakpoints.only('md')]: {
                    width: "74.1%"
                },
                [theme.breakpoints.only('lg')]: {
                    width: "31.8%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "30.3%"
                },
                width: "100%"
            },
            icon: {
                padding: 15,
                background: "#EDEDED",
                width: 54,
                height: 54,
                position: "absolute",
                right: 0,
                top: 20,
                cursor: "pointer",
            },
            information: {
                fontSize: 22,
                fontWeight: 600,
                fontFamily: "Barlow",
                [theme.breakpoints.only('xs')]: {
                    fontSize: 20
                }
            },
            colorBlack: {
                color: theme.palette.text.secondary
            },
            toolTip: {
                backgroundColor: "white",
                color: theme.palette.text.secondary,
                fontSize: 12,
                maxWidth: 218,
                border: `1px solid ${theme.palette.text.secondary}`,
                borderRadius: "initial",
                letterSpacing: 0.58,
                textAlign: "left"

            },
            divider: {
                width: 136,
                height: 0,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
            divider2: {
                width: 88,
                height: 0,
                border: `1px solid ${theme.palette.text.secondary}`,
            },
            adornment: {
                '& .MuiIconButton-root.Mui-disabled': {
                    color: theme.palette.text.secondary
                }
            },
            submit: {
                minWidth: 200,
                borderRadius: 0,
                fontSize: 18,
                marginTop: 16
            },
            submit2: {
                minWidth: 82,
                borderRadius: 0,
                fontSize: 18,
                fontWeight: "bold"
            },
            paperAlert: {
                textAlign: "center",
                boxShadow: "0px 0px 25px #0000004D",
                borderRadius: 10,
                padding: "15px 48px 29px 48px",
                [theme.breakpoints.only('xs')]: {
                    padding: "15px 24px 29px 24px",
                },
            },
            submitLabel: {
                fontSize: 18
            },
            stateTitle: {
                fontSize: 18,
                fontWeight: 600,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                }
            },
            marginUpMd: {
                marginTop: 112,
                [theme.breakpoints.down('sm')]: {
                    marginTop: 0,
                }
            },
            image: {
                width: "100%",
                objectFit: "cover"
            },
            completeSuggest: {
                fontSize: 25,
                lineHeight: "normal",
                letterSpacing: 0,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 22,
                    marginTop: 8
                }
            },
            deleteAccount: {
                position: "absolute",
                right: 0,
                borderRadius: 0,
                [theme.breakpoints.down('xs')]: {
                    position: "unset",
                    marginTop: 20
                }
            },
            rootSecondary: {
                color: theme.palette.text.secondary,
                fontWeight: 600,
                display: "flex",
                letterSpacing: 0.8,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 15
                },
                '&.MuiFormLabel-root.Mui-disabled': {
                    color: theme.palette.text.secondary,
                }
            },
            rootSecondaryFull: {
                color: theme.palette.secondary.main,
                "&.Mui-disabled": {
                    color: theme.palette.secondary.main
                }
            },
            butonContainer: {
                display: "flex",
                position: "relative",
                flexWrap: "wrap",
                [theme.breakpoints.down('xs')]: {
                    display: "block",
                    position: "unset",
                    textAlign: "center"
                }
            },
            required: {
                fontWeight: 600,
                fontSize: 16
            },
            paddingXs: {
                [theme.breakpoints.down('xs')]: {
                    paddingRight: "1rem",
                    paddingLeft: "1rem"
                }

            },
            ellipsis: {
                textOverflow: "ellipsis",
                display: "inline-block",
                overflow: "hidden",
                maxWidth: "95%",
                whiteSpace: "nowrap",
                verticalAlign: "middle"
            },
            ellipsisMin: {
                maxWidth: "90%"
            },
            paper: {
                [theme.breakpoints.up('md')]: {
                    boxShadow: "0px 0px 36px #00000015"
                }
            },
            textField: {
                '& .MuiFormLabel-root': {
                    color: theme.palette.text.secondary,
                    fontWeight: 600,
                    display: "flex",
                    letterSpacing: 0.8,
                    [theme.breakpoints.down('xs')]: {
                        fontSize: 15
                    },
                },
                '& .MuiInputBase-root.Mui-disabled .MuiInputBase-input.Mui-disabled': {
                    visibility: "hidden"
                }
                /*'& .MuiAutocomplete-inputRoot': {
                    paddingRight: "0 !important",
                    '& input': {
                        minHeight: 48,
                        padding: "0 16px !important"
                    }
                }*/
            },
            iconInfo2: {
                width: "auto",
                height: "auto",
                position: "absolute",
                top: -11,
                left: 121,
                cursor: "pointer"
            },
            listItemPublic: {
                border: "1px solid #141414",
                backgroundColor: "#FFFFFF",
                '& .MuiListItemSecondaryAction-root': {
                    [theme.breakpoints.down('sm')]: {
                        position: "absolute",
                        bottom: 0,
                        top: "77%",
                        left: 16,
                    },
                    [theme.breakpoints.up('md')]: {
                        top: "48%",
                    },
                }
            },
            listItem: {
                backgroundColor: "#F7F7F7",
                '& .MuiListItemSecondaryAction-root': {
                    [theme.breakpoints.down('sm')]: {
                        position: "absolute",
                        bottom: 0,
                        top: "77%",
                        left: 16,
                    },
                    [theme.breakpoints.up('md')]: {
                        top: "48%",
                    },
                }
            },
            avatar: {width: 27, height: 27},
            avatarColor: {
                backgroundColor: "#bdbdbd",
                marginRight: 8
            },
            bottomAvatar: {
                background: "#F7F7F7 0% 0% no-repeat padding-box",
                color: "#bdbdbd",
                minWidth: 176
            },
            textAvatar: {
                color: "#bdbdbd",
                fontWeight: "bold"
            },
            button: {
                '&:hover': {
                    backgroundColor: "transparent"
                }
            },
            inputAvatar: {
                backgroundColor: "transparent",
                width: "100%",
                marginRight: 91,
                '&.MuiInputBase-root.Mui-disabled': {
                    border: "none",
                    marginRight: 76
                }
            },
            transparent: {
                backgroundColor: "transparent !important"
            },
            divWidthAvatar: {
                //zIndex: -1,
                marginRight: 48,
                marginTop: 24,
                minHeight: 52,
                backgroundColor: process && process.published ? "#FFFFFF" : "#F7F7F7",
                [theme.breakpoints.down('sm')]: {
                    marginRight: 24,
                    minHeight: 100,
                    alignItems: "start",
                },
                [theme.breakpoints.only('md')]: {
                    width: "60%",
                    "& input": {
                        overflow: "hidden",
                        minWidth: 100
                    },
                    '& .MuiInput-root': {
                        minWidth: 100
                    }
                },
                [theme.breakpoints.only('lg')]: {
                    width: "45.6%"
                },
                [theme.breakpoints.only('xl')]: {
                    width: "46.9%"
                },
                width: "100%",
                '&.MuiListItem-secondaryAction': {
                    width: "100%",
                    marginTop: 0,
                    //minHeight: 96,
                    padding: 0
                },
                '& .MuiInputBase-root.Mui-disabled .MuiInputBase-input.Mui-disabled': {
                    backgroundColor: "#FFFFFF"
                }
            },
            autocompleteLabel: {
                /*"& label[data-shrink=\"false\"]": {
                    transform: "translate(0, -2.5px) scale(1);",
                    color: "red"
                },
                "& label[data-shrink=\"true\"]": {
                    transform: "translate(0, -2.5px) scale(1);",
                    color: "red"
                },*/
                '& .MuiInputLabel-formControl': {
                    transform: "translate(0, 17px) scale(1)",
                    paddingLeft: 24,
                    zIndex: 3,
                    opacity: 0.3,
                    fontWeight: 400,
                },
                '& .MuiInputLabel-shrink': {
                    transform: "translate(0, -13.5px) scale(1)",
                    marginBottom: 0,
                    height: 26,
                    width: "auto",
                    display: "none"
                },
                '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
                    marginTop: "2px !important",
                    marginBottom: 0,
                    paddingBottom: 0
                },
                '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
                    paddingLeft: 24,
                },
                '& .MuiInput-root.Mui-error input': {
                    border: "none"
                },
                '& .MuiAutocomplete-inputRoot.Mui-error': {
                    border: "1px solid #FF0000",
                    background: "#FDE8E8"
                },
                '& .MuiFormHelperText-root.Mui-error': {
                    marginTop: -2
                },
                '& .MuiInputBase-root.Mui-disabled': {
                    marginTop: "0 !important"
                }

            },
            circle: {
                width: 20,
                height: 20,
                border: "2px solid #141414",
                borderRadius: 10,
                backgroundColor: "#FFFFFF",
                marginRight: 16
            },
            badge: {
                width: 25,
                height: 25,
                borderRadius: 50,
                background: "#141414  0% 0% no-repeat padding-box",
                marginLeft: 10,
                cursor: "pointer"
            },
            textBadge: {
                fontWeight: 700,
                color: theme.palette.common.white,
                fontSize: 17,
                marginLeft: 4
            },
            tooltip: {
                lineHeight: 1.2,
                fontSize: 15
            },
            imgTitle: {
                position: "absolute",
                top: 0,
                [theme.breakpoints.down('sm')]: {
                    right: 24
                },
                [theme.breakpoints.between('md', 'lg')]: {
                    width: 158,
                    height: 206,
                    objectFit: "contain",
                    left: "6%",
                },
                [theme.breakpoints.only('xl')]: {
                    left: "6%",
                },
                [theme.breakpoints.only('md')]: {
                    width: 120,
                },
                [theme.breakpoints.only('sm')]: {
                    top: 21,
                },
            },
            snackbar: {
                borderRadius: 0,
                borderColor: "#00B52A",
                height: 25,
                padding: 0,
                width: 200,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: 85,
                //margin: "auto",
                [theme.breakpoints.only('xs')]: {
                    top: 259,
                },
                '& .MuiAlert-outlinedSuccess': {
                    borderRadius: 0,
                    borderColor: "#00B52A",
                    padding: "0 16px",
                    width: 200,
                    justifyContent: "center"
                },
                '& .MuiAlert-message': {
                    padding: 0
                },
                '& p': {
                    fontWeight: "600 !important",
                    color: "#00B52A"
                }
            },
            opacity: {
                opacity: 0.4
            },
            visible: {
                visibility: "visible !important"
            }
        }
    });

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            maxWidth: 190,
            fontSize: 12,
            marginTop: 15,
            backgroundColor: theme.palette.common.white,
            border: "1px solid #141414",
            borderRadius: 0,
            boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);",
            padding: 10,
            textAlign: "left",
        },
    }))(Tooltip);

    const classes = useStyles();


    const defaultPropsSpecialty = {
        options: specialties ? specialties : [],
        getOptionLabel: (option) => option.name,
    };
    const defaultCategory = {
        options: categories ? categories : [],
        getOptionLabel: (option) => option.name,
    };

    const [specialty, setSpecialty] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [questionNew, setQuestionNew] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [draftSaved, setDraftSaved] = React.useState(false);
    const [requiredCheckbox, setRequiredCheckbox] = React.useState(false);
    let [preview, setPreview] = useState(false);
    const [requiredQuestion, setRequiredQuestion] = React.useState(false);
    const [publicSaved, setPublicSaved] = React.useState(false);

    let [formData, setFormData] = useState({
        nameCompanySelfEmployedIndividual: "",
        hideWillNotVisiblePost: false,
        noVacancies: 1,
        position: "",
        category: "",
        specialty: "",
        country: "",
        province: "",
        municipality: "",
        description: "",
        features: "",
        requirements: "",
        otherData: "",
        employmentRelationship: "",
        duration: "",
        workday: "",
        schedule: "",
        netSalary: "",
        otherBenefits: "",
        questions: [],
        public: false,
        confirmPublishingSelectionProcess: false,
        stateId: null
    });

    const prevOpen = React.useRef(open);

    useEffect(() => {
        prevOpen.current = open;
    }, [formData, open, specialties, categories, process, country, published, preview, category, specialty, requiredQuestion, showView])

    useEffect(() => {
        loadDefaultCountry().then(async res => {
            await loadEmployerProfile();
            await loadEmploymentRelationship();
            await loadWorkdays();
            await loadCountries();

            let responseProvince = await loadProvinces(0);
            let responseCategories = await loadCategories();
            if (processId) {
                let responseOffer = await loadSelectionProcess();
                fillSelectedCategories(responseCategories, responseOffer);
                await completeSelectedCountry(responseOffer);
            }
        });
    }, [])

    const fillSelectedCategories = (loadedCategories, loadedOffer) => {
        let offer = loadedOffer;
        if (!offer) return;

        if (loadedCategories) {
            if (offer.specialty && offer.specialty.category) {
                let selectCategory = loadedCategories.find(x => x.id === offer.specialty.category.id)
                if (selectCategory) {
                    setCategory(selectCategory)
                    if (selectCategory.specialties) {
                        setSpecialties(selectCategory.specialties);
                        if (offer.specialty) {
                            let selectSpecialty = selectCategory.specialties.find(x => x.id === offer.specialty.id);
                            setSpecialty(selectSpecialty);
                        }
                    }
                }
            }
        }
    }

    async function createPayment(offerId) {
        let payment = await api.payments.createOrder(PaymentType.CreateProcess, 0, offerId);
        return payment.status === 200 ? payment.data.id : null;
    }

    function showError(error) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
    }

    async function publishOfferByPayment(id) {
        return api.payments.publishProcess(id).then(response => {
            return response.status === 200 ? response.data : false;
        }).catch(error => false);
    }

    async function redirectToPayment(id) {
        let payment = await createPayment(id);
        history.push(`/employer/process/update/${id}`);
        history.push(`/employer/pay/${PaymentType.CreateProcess}/${payment}/${id}`);
    }

    async function handleSubmit() {
        if (preview) {
            handlePreview();
            return;
        }

        let questionsValidate = formData.questions.length > 0 ? formData.questions.filter(q => q.question === "") : [];
        if (questionsValidate.length > 0) {
            setQuestionNew(false);
            setRequiredQuestion(true);
            return;
        }

        if (formData.confirmPublishingSelectionProcess || !published) {
            setQuestionNew(false);
            setRequiredQuestion(false);
            setRequiredCheckbox(false);

            if (published) setPublish(true);
            else setSaving(true);

            let datapost = {
                id: processId,
                enterpriseId: employer.enterprise.id,
                hideEnterprise: formData.hideWillNotVisiblePost,
                employerId: employer.employerId,
                cantPositions: formData.noVacancies ? parseInt(formData.noVacancies) : 1,
                specialtyId: specialty ? specialty.id : null,
                municipalityId: formData.municipality ? formData.municipality : null,
                title: formData.position,
                description: formData.description,
                functions: formData.features,
                requirements: formData.requirements,
                otherData: formData.otherData,
                employmentRelationshipId: formData.employmentRelationship ? parseInt(formData.employmentRelationship) : null,
                employmentRelationshipDuration: formData.duration,
                workdayId: formData.workday ? formData.workday : null,
                schedule: formData.schedule,
                salary: formData.netSalary,
                otherBenefits: formData.otherBenefits,
                stateId: formData.stateId,
                employerIn: formData.confirmPublishingSelectionProcess,
                //published: published,
                published: false, //TODO: Primero se guarda borrardor y luego se publica con pago
                killerQuestions: formData.questions.map(x => {
                    return {
                        id: x.id,
                        questionText: x.question,
                        free: !x.type
                    }
                })
            };
            if (processId) {
                api.offers.update(datapost).then(response => {
                    if (response.status === 200) {
                        let offer = response.data;
                        setProcess(offer);

                        if (!published) setDraftSaved(true);
                        if (published) setPublicSaved(true);

                        setIfChange(false);

                        if (published && !offer.published) {
                            publishOfferByPayment(offer.id).then(response => {
                                if (response) loadSelectionProcess(true);
                                else redirectToPayment(processId)
                            })
                        } else {
                            loadSelectionProcess(true);
                        }
                    } else {
                        showError(response.data.error);
                    }

                    if (published) setPublish(false);
                    else setSaving(false);

                }).catch(err => {
                    showError(err.message);
                    if (published) setPublish(false);
                    else setSaving(false);
                })
            } else {
                api.offers.create(datapost).then(response => {
                    if (response.status === 200) {
                        setIfChange(false);
                        let offer = response.data;

                        if (preview) {
                            history.push(`/employer/process/update/${offer.id}`);
                            history.push(`/employer/process/view/${offer.id}`)
                        }

                        if (published)
                            publishOfferByPayment(offer.id).then(response => {
                                if (response) history.push("/employer/workspace");
                                else redirectToPayment(offer.id)
                            })
                        else history.push(`/employer/process/update/${offer.id}`);
                    } else {
                        showError(response.data.error);
                    }

                    if (published) setPublish(false);
                    else setSaving(false);
                }).catch(err => {
                    showError(err.message);
                    if (published) setPublish(false);
                    else setSaving(false);
                })
            }

            setShowView(true);
        } else {
            setRequiredCheckbox(true);
        }
    }

    function handlePreview() {
        if (processId && !(process.state.code === offersSatte.draft)) {
            history.push(`/employer/process/view/${processId}`);
        } else {
            let datapost = {
                id: processId,
                enterpriseId: employer.enterprise.id,
                hideEnterprise: formData.hideWillNotVisiblePost,
                employerId: employer.employerId,
                cantPositions: formData.noVacancies ? parseInt(formData.noVacancies) : 1,
                specialtyId: specialty ? specialty.id : null,
                municipalityId: formData.municipality ? formData.municipality : null,
                title: formData.position,
                description: formData.description,
                functions: formData.features,
                requirements: formData.requirements,
                otherData: formData.otherData,
                employmentRelationshipId: formData.employmentRelationship ? parseInt(formData.employmentRelationship) : null,
                employmentRelationshipDuration: formData.duration,
                workdayId: formData.workday ? formData.workday : null,
                schedule: formData.schedule,
                salary: formData.netSalary,
                otherBenefits: formData.otherBenefits,
                stateId: formData.stateId,
                employerIn: formData.confirmPublishingSelectionProcess,
                //published: published,
                published: false, //Primero se guarda borrardor y luego se publica con pago
                killerQuestions: formData.questions.map(x => {
                    return {
                        id: x.id,
                        questionText: x.question,
                        free: !x.type
                    }
                })
            };
            if (process && process.state && process.state.code === offersSatte.draft){
                api.offers.update(datapost).then(response => {
                    if (response.status === 200) {
                        setIfChange(false);
                        let offer = response.data;

                        history.push(`/employer/process/view/${offer.id}`)
                    } else {
                        showError(response.data.error);
                    }
                }).catch(err => {
                    showError(err.message);
                    if (published) setPublish(false);
                    else setSaving(false);
                })
            }else {
                api.offers.create(datapost).then(response => {
                    if (response.status === 200) {
                        setIfChange(false);
                        let offer = response.data;

                        history.push(`/employer/workspace`);
                        history.push(`/employer/process/update/${offer.id}`);
                        history.push(`/employer/process/view/${offer.id}`)
                    } else {
                        showError(response.data.error);
                    }
                }).catch(err => {
                    showError(err.message);
                    if (published) setPublish(false);
                    else setSaving(false);
                })
            }

        }
        setShowView(true);
    }

    async function loadCountries() {
        if (countries.length === 0) {
            let response = await api.countries.all();
            if (response.status === 200) {
                setCountries(response.data);
                return response.data;
            }
            return null;
        }
        return countries;
    }

    async function loadProvinces(countryId, reload = false) {
        if (provinces.length === 0 || reload) {
            let response = await api.provinces.allByCountry(countryId);
            if (response.status === 200) {
                setProvinces(response.data);
                return response.data;
            }
            return null;
        }
        return provinces;
    }

    async function loadDefaultCountry() {
        return new Promise(async (resolve, reject) => {
            if (country === null) {
                let response = await api.countries.getDefaultCountry();
                if (response.status === 200) {
                    let {data} = response;
                    setCountry(data);
                    if (data)
                        setFormData(prevFormData => ({...prevFormData, country: data.id}))
                    resolve(data);
                }
                resolve(null);
            }
            resolve(country);
        });
    }

    function onClickPublic() {
        setPublished(true)

        if (formData.confirmPublishingSelectionProcess) {
            setRequiredCheckbox(false);
        } else {
            setRequiredCheckbox(true);
        }
    }

    function loadEmployerProfile() {
        if (employer === null)
            api.employers.getEmployerProfile().then(response => {
                if (response.status === 200) {
                    setEmployer(response.data);
                }
            })
    }

    function loadSelectionProcess(reload = false) {
        if ((!process && processId) || reload)
            return api.offers.getOffer(processId).then(response => {
                if (response.status === 200) {
                    setProcess(response.data);
                    mapSelectionProcess(response.data);
                    return response.data;
                }
            })
        else {
            return process;
        }
    }

    function mapSelectionProcess(offer) {
        setFormData(form => ({
            ...form,
            hideWillNotVisiblePost: offer.hiddenEnterprise,
            noVacancies: offer.cantPositions ? offer.cantPositions : 1,
            position: offer.title,
            category: offer.specialty && offer.specialty.category ? offer.specialty.category.id : "",
            specialty: offer.specialty ? offer.specialty.id : "",
            country: offer.municipality?.province?.country?.id ?? "",
            province: offer.municipality && offer.municipality.province ? offer.municipality.province.id : "",
            municipality: offer.municipality ? offer.municipality.id : "",
            description: offer.description,
            features: offer.functions,
            requirements: offer.requirements,
            otherData: offer.otherData,
            employmentRelationship: offer.employmentRelationship ? offer.employmentRelationship.id : "",
            duration: offer.employmentRelationshipDuration,
            workday: offer.workday ? offer.workday.id : "",
            schedule: offer.schedule,
            netSalary: offer.salary,
            otherBenefits: offer.otherBenefits,
            questions: offer.killerQuestions.map(x => ({
                id: x.id,
                question: x.questionText,
                type: !x.free,
                open: false,
                typeDefined: true
            })),
            public: offer.published,
            confirmPublishingSelectionProcess: false, //offer.published === false ? false : (offer.id ? true : false),
            stateId: offer.state ? offer.state.id : null
        }))
        if (categories) {
            if (offer.specialty && offer.specialty.category) {
                let selectCategory = categories.find(x => x.id === offer.specialty.category.id)
                if (selectCategory) {
                    setCategory(selectCategory)
                    if (selectCategory.specialties) {
                        setSpecialties(selectCategory.specialties);
                        if (offer.specialty) {
                            let selectSpecialty = selectCategory.specialties.find(x => x.id === offer.specialty.id);
                            setSpecialty(selectSpecialty);
                        }
                    }
                }
            }
        }
        // if (provinces) {
        //     if (offer.municipality && offer.municipality.province) {
        //         let selectedProvince = provinces.find(x => x.id === offer.municipality.province.id);
        //         if (selectedProvince) setMunicipalities(selectedProvince.municipalities);
        //     }
        // }
        // completeSelectedCountry(offer).then();
    }

    async function completeSelectedCountry(offer) {
        if (!offer) return;

        let countryId = offer.municipality?.province?.country?.id ?? 0;
        let selectedProvince = null;
        if (offer.municipality && offer.municipality.province) {
            selectedProvince = provinces?.find(x => x.id === offer.municipality.province.id);
            if (selectedProvince) setMunicipalities(selectedProvince.municipalities);
            else {
                let loadedProvinces = await loadProvinces(countryId, true);
                if (loadedProvinces) {
                    selectedProvince = loadedProvinces?.find(x => x.id === offer.municipality.province.id);
                    if (selectedProvince) {
                        setMunicipalities(selectedProvince.municipalities);
                    }
                }
            }
        }
    }

    async function loadCategories() {
        if (!categories) {
            let response = await api.categories.all();
            if (response.status === 200) {
                setCategories(response.data);
                return response.data;
            }
            return null;
        }
        return categories;
    }

    function loadEmploymentRelationship() {
        if (!employmentRelationships)
            api.employmentRelationships.all().then(response => {
                if (response.status === 200) {
                    setEmploymentRelationships(response.data);
                }
            })
    }

    function loadWorkdays() {
        if (!workdays)
            api.workdays.all().then(response => {
                if (response.status === 200) {
                    setWorkdays(response.data);
                }
            })
    }

    const handleChange = (event) => {
        let data = Object.assign({}, formData);
        if (event.target.name === "noVacancies") {
            let cant = parseInt(event.target.value);
            data[event.target.name] = cant > 0 ? cant : 1;
        } else {
            data[event.target.name] = event.target.value;
        }
        setFormData(data);
        setIfChange(true);
        if(process && process.state && process.state.code !== offersSatte.draft){
            setShowView(false);
        }

    }

    const handleChangeChecked = (event) => {
        let value = event.target.checked;
        if (value === true) {
            setRequiredCheckbox(false)
        }

        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.checked;
        setFormData(data);
        setIfChange(true);
    };

    const handleChangeCheckedConfirmation = (event) => {
        let value = event.target.checked;
        if (value === true) {
            setRequiredCheckbox(false)
        }

        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.checked;
        setFormData(data);
        setIfChange(true);
    };

    async function handleChangeCountry(event) {
        let countryId = event.target.value;
        setFormData(prevState => ({
            ...prevState,
            country: countryId,
            province: "",
            municipality: ""
        }));
        setMunicipalities([]);
        setProvinces([]);
        await loadProvinces(countryId, true);
    }

    const handleChangeProvinces = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
        setIfChange(true);
        let province = provinces.find(x => x.id === event.target.value);
        setMunicipalities(province ? province.municipalities : []);
        if (process && process.state && process.state.code !== offersSatte.draft) {
            setShowView(false);
        }
    }


    const handleChangeQuestion = (index) => (event) => {
        let data = Object.assign({}, formData);
        data.questions[index].question = event.target.value;
        setFormData(data);
        setIfChange(true);
        if(process && process.state && process.state.code !== offersSatte.draft){
            setShowView(false);
        }

    }

    function addQuestion(question, type) {
        setRequiredQuestion(false)
        let data = Object.assign({}, formData);
        if (data.questions.some(x => !x.typeDefined)) return;

        data.questions.push({question, type});
        setFormData(data);
        setQuestionNew(true);
        if(process && process.state && process.state.code !== offersSatte.draft){
            setShowView(false);
        }
    }

    function deletedQuestion(index) {
        let data = Object.assign({}, formData);
        data.questions.splice(index, 1);
        setFormData(data);
        setQuestionNew(true);
    }

    function handleOnClickMenu(index, type) {
        setQuestionNew(false);
        let data = Object.assign({}, formData);
        data.questions[index].type = type;
        data.questions[index].typeDefined = true;
        setFormData(data);
        setOpen(false);
    }

    function deleteOffer() {
        setDeleting(true);
        api.offers.deleteOffer(processId).then(response => {
            if (response.status === 200) {
                setDeleting(false);
                history.push("/employer/workspace")
            }
        }).catch(err => setDeleting(false));
    }

    function renderRequired() {
        return <Typography className={classes.required} color="error">*</Typography>
    }

    function renderLabel(translate, icon = false) {
        return (<div className="d-flex" style={{width: "100%"}}>
            <Typography
                className={`${classes.rootSecondary} ${classes.ellipsis} ${icon ? classes.ellipsisMin : ""}`}>
                <Translate id={translate}/>
            </Typography>
            {renderRequired()}
        </div>);
    }

    function renderDialogDelete() {
        return <Dialog
            open={openDialogDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
            classes={{paper: classes.paperAlert}}>
            <DialogTitle id="alert-dialog-title">
                <Typography className={`${classes.submit2} font-weight-bold`}><Translate
                    id={"Offers.DoEliminateProcess"}/></Typography>
            </DialogTitle>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => deleteOffer()}
                        className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}><Translate
                        id={"Buttons.Yes"}/></Typography>
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialogDelete(false)}
                        className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}><Translate
                        id={"Buttons.No"}/></Typography>
                </Button>
            </DialogActions>
        </Dialog>
    }

    function renderInformation(title) {
        return <Grid container>
            <Grid item xs={12}>
                <Typography className={`${classes.information} ${classes.colorBlack}`}>
                    <Translate id={title}/>
                </Typography>
            </Grid>
            <Grid item xs={12} className="pt-2">
                <Divider className={classes.divider}/>
            </Grid>
        </Grid>
    }

    function renderListQuestions() {
        let data = Object.assign({}, formData);
        let questions = data.questions;
        return <Grid container>
            <Grid item xs={12}>
                <List className={`d-flex flex-wrap align-items-center ${classes.ul}`}>
                    {
                        questions.map((value, index) => {
                            return <Translate>
                                {({translate}) => {
                                    return <ListItem
                                        style={process && process.state.code === offersSatte.closed ? {border: "1px solid #141414"} : {}}
                                        className={`menu-item ${process && process.published ? classes.listItemPublic : classes.listItem}`}
                                        classes={{root: classes.divWidthAvatar, container: classes.divWidthAvatar}}>
                                        <IconButton onClick={() => deletedQuestion(index)}
                                                    disabled={process && process.published

                                                    || (process && process.state.code === offersSatte.closed)}>
                                            <Avatar className={classes.avatar}>
                                                <RemoveOutlinedIcon/>
                                            </Avatar>
                                        </IconButton>
                                        <div className="d-flex align-items-center" style={{width: "100%"}}>
                                            <Typography variant="body2" color="textSecondary"
                                                        style={screenSmDown ? {} : {fontSize: 22}}
                                                        className="font-weight-bold">
                                                {index + 1}.
                                            </Typography>
                                            <InputBase
                                                autoFocus={true}
                                                inputProps={{autofocus: true}}
                                                multiline={screenSmDown}
                                                rows={2}
                                                value={value.question}
                                                onChange={handleChangeQuestion(index)}
                                                className={classes.inputAvatar}
                                                classes={{input: classes.transparent}}
                                                placeholder={(questionNew && index === questions.length - 1) ? translate("Offers.ChooseTypeQuestion") : translate("Offers.WriteQuestion")}
                                                disabled={process && process.published || (!formData.questions[index].typeDefined) || (process && process.state.code === offersSatte.closed)}
                                            />
                                        </div>
                                        {value.type &&
                                        <ListItemSecondaryAction className={screenSmDown ? "pl-5" : "d-flex"}>
                                            <div className="d-flex align-items-center">
                                                <Typography variant="body2" color="textSecondary" className="pr-1">
                                                    <Translate id={"Offers.Yes"}/>
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" className="pr-1">
                                                    o
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" className="pr-1">
                                                    <Translate id={"Offers.No"}/>
                                                </Typography>

                                            </div>
                                        </ListItemSecondaryAction>}
                                        {
                                            <ListItemSecondaryAction>
                                                {!formData.questions[index].typeDefined && renderQuestionMenu(index)}
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                }}
                            </Translate>
                        })
                    }
                    {questions.length + 1 <= 4 ?
                        <Button
                            variant="contained" onClick={() => addQuestion("", null)}
                            disabled={formData.questions.length > 0 && formData.questions.some(x => !x.typeDefined) || (process && process.published) || (process && process.state && process.state.code === offersSatte.closed)}
                            style={{borderRadius: 0}}
                            className={`${classes.button} ${classes.bottomAvatar} mt-4`}
                            startIcon={<Avatar
                                className={`${classes.avatar} ${classes.avatarColor}`}>
                                <AddIcon style={{color: "#FFF"}}/>
                            </Avatar>}>
                            <Typography variant={"body1"} className={classes.textAvatar}>
                                <Translate id={"Offers.Question"}/>
                                {` ${questions.length + 1}`}
                            </Typography>
                        </Button>
                        : ""
                    }

                </List>
            </Grid>
            <Grid item xs={12} className="pt-3">
                {formData.questions.some(x => !x.typeDefined) ?
                    <Typography variant={"body1"} color={"error"}>
                        <Translate id={"Offers.questionsNewDescription"}/>
                    </Typography>
                    : ""}
                {requiredQuestion ?
                    <Typography variant={"body1"} color={"error"}>
                        <Translate
                            id={!published ? "Offers.Employer.EmptyQuestionsSave" : "Offers.Employer.EmptyQuestions"}/>
                    </Typography>
                    : ""}
            </Grid>
        </Grid>
    }

    function renderQuestionMenu(index) {
        return <QuestionTypeMenu
            index={index}
            open={formData.questions[index].open}
            setOpen={(isOpen) => {
                let prevFormData = Object.assign({}, formData);
                prevFormData.questions[index].open = isOpen;
                setFormData(prevFormData);
            }}
            handleOnClickMenu={handleOnClickMenu}
        />
    }

    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace'><Translate id={"Employer.EmployerArea"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace/some'><Translate
                id={processId ? "Offers.EditSelectionProcess" : "Employer.PublishSelectionProcesses"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    const isClosed = () => {
        return process && process.state && process && process.state && process.state.code === offersSatte.closed;
    }

    const allowPublishOrSave = () => {
        if (isClosed()) return false;

        if (process === null) return true;
        if (process && process.reactivated) return true;
        if (process && !process.published) return true;
        if (process && process.state && process.state.code === offersSatte.open) return true;

        return false;
    }

    const allowSaveDraft = () => {
        //return ((process && !process.publishDate) || processId === null);
        return ((process && process.state && process.state.code === offersSatte.draft) || processId === null);
    }

    return (provinces && categories && (processId ? process !== null : true)
            ? <ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <Grid container direction="row" justify="center" alignItems="center"
                      className={`my-4 ${classes.paddingXs} position-relative`}>
                    {renderBreadCrumb()}
                    <img alt={""} src={screenSmDown ? OrangeLaptopM : OrangeLaptop} className={classes.imgTitle}/>
                    <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                        <div className={`${screenSmDown ? "" : "d-flex justify-content-between"}`}>
                            <Typography className={classes.title} color="textSecondary">
                                {processId ? <Translate id={"Offers.EditSelectionProcess"}/> :
                                    <Translate id={"Offers.PublishSelectionProcess"}/>}
                            </Typography>
                            {processId && <div className={`d-inline align-self-center`}>
                                <div className={`d-flex align-items-center ${screenSmDown ? "mt-3" : ""}`}>
                                    <Typography className={`${classes.stateTitle}`} color="textSecondary">
                                        <Translate id={"Offers.ManagementState"}/>:
                                    </Typography>
                                    <Typography className={`${classes.stateTitle} pl-2`} color="secondary">
                                        {process.state.name}
                                    </Typography>
                                </div>
                                <Divider className={`${classes.divider2} mt-2`}/>
                            </div>}
                        </div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Translate>
                                    {({translate}) => {
                                        return <Grid item xs={12} className="pt-4">
                                            <div className="position-relative">
                                                <TextValidator
                                                    className={classes.responsiveWidth}
                                                    label={translate("Offers.NameCompanySelfEmployedIndividual")}
                                                    onChange={handleChange}
                                                    name="nameCompanySelfEmployedIndividual"
                                                    value={employer && employer.enterprise ? employer.enterprise.name : ""}
                                                    placeholder={translate("Offers.NameCompanySelfEmployedIndividual")}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        classes: {root: classes.rootSecondary}
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (<InputAdornment position="end"
                                                                                       className={classes.adornment}>
                                                                <IconButton disabled>
                                                                    <LockIcon/>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Grid>
                                    }}
                                </Translate>
                                <Translate>
                                    {({translate}) => {
                                        return <Grid item xs={12}>
                                            <div className="position-relative d-flex flex-wrap">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.hideWillNotVisiblePost}
                                                            onChange={handleChangeChecked}
                                                            name="hideWillNotVisiblePost"
                                                            color="default"
                                                            disabled={process && process.state.code === offersSatte.closed}
                                                        />
                                                    }
                                                    label={translate("Offers.HideWillNotVisiblePost")}
                                                />

                                            </div>
                                        </Grid>
                                    }}
                                </Translate>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
                                <Grid container spacing={0} direction="row"
                                      justify={screenXsOnly ? "flex-start" : "flex-end"} alignItems="center">
                                    <Grid item style={{minWidth: 170, marginLeft: 16}}>
                                        <div className="d-flex text-right mb-2">
                                            {renderRequired()}<Typography className={classes.text}
                                                                          color="textSecondary"><Translate
                                            id={"Employer.RequiredFields"}/></Typography>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Paper square elevation={0} className={classes.paper}>
                                    <Grid container direction="row" justify="center" alignItems="stretch">
                                        <Grid item xs={12} className={screenSmDown ? "pt-4 px-3" : "pt-5 pl-5 pr-4"}>
                                            {renderInformation("Offers.DescriptionSelectionProcess")}
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"} pt-5`}>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidthXs}`}>
                                                        <TextValidator
                                                            type="number"
                                                            id="noVacancies"
                                                            className={classes.responsiveWidthXs}
                                                            label={renderLabel("Offers.NoVacancies")}
                                                            onChange={handleChange}
                                                            name="noVacancies"
                                                            value={formData.noVacancies}
                                                            validators={published === false ? [] : ['required']}
                                                            placeholder={"1"}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            isNumber
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && (process.state.code === offersSatte.closed)}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidthLg}`}>
                                                        <TextValidator
                                                            type="text"
                                                            id="position"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Position")}
                                                            onChange={handleChange}
                                                            name="position"
                                                            value={formData.position}
                                                            validators={published === false ? [] : ['required']}
                                                            placeholder={translate("Offers.IndicatePositionCollaboration")}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            InputProps={process && process.state && (process.state.code === offersSatte.closed || process.state.code === offersSatte.open) ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && (process.state.code === offersSatte.closed || process.state.code === offersSatte.open)}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth} ${classes.autocompleteLabel}`}>
                                                        {renderLabel("Offers.Category")}
                                                        {process && process.state && process.state.code === offersSatte.closed ?
                                                            <Autocomplete
                                                                {...defaultCategory}
                                                                id="auto-highlight-category"
                                                                value={category}
                                                                disabled
                                                                onChange={(event, newValue) => {
                                                                    setSpecialties([]);
                                                                    if (newValue) {
                                                                        setSpecialties(newValue.specialties);
                                                                    }
                                                                    setSpecialty(null);
                                                                    setCategory(newValue);
                                                                }}
                                                                autoHighlight
                                                                closeIcon={<CloseIcon fontSize="small"/>}
                                                                renderInput={(params) => <TextValidator
                                                                    className={classes.textField}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        disableAnimation: true,
                                                                    }}
                                                                    {...params}
                                                                    value={category}
                                                                    validators={published === false ? [] : ['required']}
                                                                    errorMessages={[translate("Fields.Required")]}
                                                                    label={translate("Offers.ChooseCategory")}
                                                                    margin="none"
                                                                    InputProps={{
                                                                        endAdornment: (<InputAdornment position="end"
                                                                                                       className={classes.adornment}>
                                                                                <IconButton disabled>
                                                                                    <LockIcon/>
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                        classes: {input: classes.visible}
                                                                    }}/>}
                                                                renderOption={(option, {inputValue}) => {
                                                                    const matches = match(option.name, inputValue);
                                                                    const parts = parse(option.name, matches);
                                                                    return (
                                                                        <div>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index}
                                                                                      style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                                {part.text}
                                                                          </span>
                                                                            ))}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                            :
                                                            <Autocomplete
                                                                {...defaultCategory}
                                                                id="auto-highlight-category"
                                                                value={category}
                                                                onChange={(event, newValue) => {
                                                                    setSpecialties([]);
                                                                    if (newValue) {
                                                                        setSpecialties(newValue.specialties);
                                                                    }
                                                                    setSpecialty(null);
                                                                    setCategory(newValue);
                                                                }}
                                                                autoHighlight
                                                                closeIcon={<CloseIcon fontSize="small"/>}
                                                                renderInput={(params) => <TextValidator
                                                                    className={classes.textField}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        disableAnimation: true,
                                                                    }}
                                                                    {...params}
                                                                    value={category}
                                                                    validators={published === false ? [] : ['required']}
                                                                    errorMessages={[translate("Fields.Required")]}
                                                                    label={translate("Offers.ChooseCategory")}
                                                                    margin="none"
                                                                />}
                                                                renderOption={(option, {inputValue}) => {
                                                                    const matches = match(option.name, inputValue);
                                                                    const parts = parse(option.name, matches);
                                                                    return (
                                                                        <div>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index}
                                                                                      style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                        }

                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth} ${classes.autocompleteLabel}`}>
                                                        {renderLabel("Titles.Specialty")}
                                                        {process && process.state && process.state.code === offersSatte.closed ?
                                                            <Autocomplete
                                                                {...defaultPropsSpecialty}
                                                                id="auto-highlight-speciality"
                                                                value={specialty}
                                                                onChange={(event, newValue) => {
                                                                    setSpecialty(newValue);
                                                                }}
                                                                autoHighlight
                                                                disabled
                                                                closeIcon={<CloseIcon fontSize="small"/>}
                                                                renderInput={(params) => <TextValidator
                                                                    className={classes.textField}
                                                                    InputLabelProps={{shrink: false}}
                                                                    inputProps={{
                                                                        classes,
                                                                        disableUnderline: true
                                                                    }}
                                                                    {...params}
                                                                    value={specialty}
                                                                    validators={published === false ? [] : ['required']}
                                                                    errorMessages={[translate("Fields.Required")]}
                                                                    label={translate("Offers.ChooseSpecialty")}
                                                                    margin="none"
                                                                    InputProps={{
                                                                        endAdornment: (<InputAdornment position="end"
                                                                                                       className={classes.adornment}>
                                                                                <IconButton disabled>
                                                                                    <LockIcon/>
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                        classes: {input: classes.visible}
                                                                    }}/>}
                                                                renderOption={(option, {inputValue}) => {
                                                                    const matches = match(option.name, inputValue);
                                                                    const parts = parse(option.name, matches);
                                                                    return (
                                                                        <div>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index}
                                                                                      style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />
                                                            :
                                                            <Autocomplete
                                                                {...defaultPropsSpecialty}
                                                                id="auto-highlight-speciality"
                                                                value={specialty}
                                                                onChange={(event, newValue) => {
                                                                    setSpecialty(newValue);
                                                                }}
                                                                autoHighlight
                                                                closeIcon={<CloseIcon fontSize="small"/>}
                                                                renderInput={(params) => <TextValidator
                                                                    className={classes.textField}
                                                                    InputLabelProps={{shrink: false}}
                                                                    inputProps={{
                                                                        classes,
                                                                        disableUnderline: true
                                                                    }}
                                                                    {...params}
                                                                    value={specialty}
                                                                    validators={published === false ? [] : ['required']}
                                                                    errorMessages={[translate("Fields.Required")]}
                                                                    label={translate("Offers.ChooseSpecialty")}
                                                                    margin="none"
                                                                />}
                                                                renderOption={(option, {inputValue}) => {
                                                                    const matches = match(option.name, inputValue);
                                                                    const parts = parse(option.name, matches);
                                                                    return (
                                                                        <div>
                                                                            {parts.map((part, index) => (
                                                                                <span key={index}
                                                                                      style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    );
                                                                }}
                                                            />}

                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"}`}>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth33}`}>
                                                        <TextValidator
                                                            type="text"
                                                            id="country"
                                                            className={classes.responsiveWidth}
                                                            label={translate("Offers.Country")}
                                                            onChange={handleChangeCountry}
                                                            name="country"
                                                            value={formData.country}
                                                            placeholder={translate("Offers.Country")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            select
                                                            SelectProps={{
                                                                displayEmpty: true,
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null
                                                                },

                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed
                                                                ? {
                                                                    endAdornment: (<InputAdornment position="end"
                                                                                                   className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>)
                                                                }
                                                                : {}
                                                            }
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}>
                                                            {countries.map(country => {
                                                                return <MenuItem
                                                                    value={country.id}>{country.name}</MenuItem>
                                                            })}
                                                        </TextValidator>
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth33}`}>
                                                        <TextValidator
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Province")}
                                                            onChange={handleChangeProvinces}
                                                            name="province"
                                                            value={formData.province}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            select
                                                            SelectProps={{
                                                                displayEmpty: true,
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null
                                                                },

                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed
                                                                ? {
                                                                    endAdornment: (<InputAdornment position="end"
                                                                                                   className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>)
                                                                }
                                                                : {}
                                                            }
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}>
                                                            <MenuItem disabled value="">
                                                                <span
                                                                    className={classes.opacity}>{translate("Offers.SelectProvince")}</span>
                                                            </MenuItem>
                                                            {provinces.map(province => {
                                                                return <MenuItem
                                                                    value={province.id}>{province.name}</MenuItem>
                                                            })}

                                                        </TextValidator>
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth33}`}>
                                                        <TextValidator
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Municipality")}
                                                            onChange={handleChange}
                                                            name="municipality"
                                                            value={formData.municipality}
                                                            validators={published === false ? [] : ['required']}
                                                            placeholder={translate("Offers.SelectMunicipality")}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            SelectProps={{
                                                                displayEmpty: true,
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null
                                                                },

                                                            }}
                                                            select
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}>
                                                            <MenuItem disabled value="">
                                                                <span
                                                                    className={classes.opacity}>{translate("Offers.SelectMunicipality")}</span>
                                                            </MenuItem>
                                                            {municipalities.map(municipality => {
                                                                return <MenuItem
                                                                    value={municipality.id}>{municipality.name}</MenuItem>
                                                            })}

                                                        </TextValidator>
                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"}`}>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth2}`}
                                                    >
                                                        <TextValidator
                                                            name="description"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Description")}
                                                            onChange={handleChange}
                                                            value={formData.description}
                                                            placeholder={translate("Offers.ProvideDescription")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            multiline
                                                            rows={8}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth2}`}
                                                    >
                                                        <TextValidator
                                                            name="features"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Features")}
                                                            onChange={handleChange}
                                                            value={formData.features}
                                                            placeholder={translate("Offers.IndicateTasks")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            multiline
                                                            rows={8}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"}`}>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth2}`}
                                                    >
                                                        <TextValidator
                                                            name="requirements"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Requirements")}
                                                            onChange={handleChange}
                                                            value={formData.requirements}
                                                            placeholder={translate("Offers.IndicatesTraining")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            multiline
                                                            rows={8}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth2}`}
                                                    >
                                                        <TextField
                                                            name="otherData"
                                                            className={classes.responsiveWidth}
                                                            label={translate("Offers.OtherData")}
                                                            onChange={handleChange}
                                                            value={formData.otherData}
                                                            placeholder={translate("Offers.IndicateOtherInformation")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            multiline
                                                            rows={8}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>
                                        <Grid item xs={12} className={screenSmDown ? "pt-4 px-3" : "pl-5 pr-4"}>
                                            {renderInformation("Offers.LaborConditions")}
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "pr-5 d-flex flex-wrap"} pt-5`}>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth4}`}>
                                                        <TextValidator
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.EmploymentRelationship")}
                                                            onChange={handleChange}
                                                            name="employmentRelationship"
                                                            value={formData.employmentRelationship}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            select
                                                            SelectProps={{
                                                                displayEmpty: true,
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null
                                                                },

                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}>
                                                            <MenuItem disabled value="">
                                                                <span
                                                                    className={classes.opacity}>{translate("Offers.ChooseOption")}</span>
                                                            </MenuItem>
                                                            {employmentRelationships && employmentRelationships.map(title => {
                                                                return <MenuItem
                                                                    value={title.id}>{title.name}</MenuItem>
                                                            })}

                                                        </TextValidator>
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth4}`}>
                                                        <TextValidator
                                                            type="text"
                                                            id="duration"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Duration")}
                                                            onChange={handleChange}
                                                            name="duration"
                                                            value={formData.duration}
                                                            validators={published === false ? [] : ['required']}
                                                            placeholder={translate("Offers.IndicateDuration")}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            isNumber
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth4}`}>
                                                        <TextValidator
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Workday")}
                                                            onChange={handleChange}
                                                            name="workday"
                                                            value={formData.workday}
                                                            validators={published === false ? [] : ['required']}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            select
                                                            SelectProps={{
                                                                displayEmpty: true,
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null
                                                                },

                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}>
                                                            <MenuItem disabled value="">
                                                                <span
                                                                    className={classes.opacity}> {translate("Offers.ChooseOption")}</span>
                                                            </MenuItem>
                                                            {workdays && workdays.map(title => {
                                                                return <MenuItem
                                                                    value={title.id}>{title.name}</MenuItem>
                                                            })}

                                                        </TextValidator>
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth4}`}>
                                                        <TextValidator
                                                            type="text"
                                                            id="schedule"
                                                            className={classes.responsiveWidth}
                                                            label={renderLabel("Offers.Schedule")}
                                                            onChange={handleChange}
                                                            name="schedule"
                                                            value={formData.schedule}
                                                            validators={published === false ? [] : ['required']}
                                                            placeholder={translate("Offers.IndicateSchedule")}
                                                            errorMessages={[translate("Fields.Required")]}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} d-flex position-relative ${classes.divWidth4}`}>
                                                        <TextValidator
                                                            type="text"
                                                            id="netSalary"
                                                            className={classes.responsiveWidth}
                                                            label={translate("Offers.NetSalary")}
                                                            onChange={handleChange}
                                                            name="netSalary"
                                                            value={formData.netSalary}
                                                            placeholder={translate("Offers.IndicateSalary")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                        <Tooltip classes={{tooltip: classes.toolTip}}
                                                                 title={<Translate
                                                                     id={"Offers.RememberIndicateSalary"}/>}
                                                                 placement="bottom">
                                                            <Icon
                                                                className={`${classes.colorBlack} ${classes.iconInfo2}`}>
                                                                <InfoIcon/>
                                                            </Icon>
                                                        </Tooltip>
                                                    </div>

                                                }}
                                            </Translate>
                                            <Translate>
                                                {({translate}) => {
                                                    return <div
                                                        className={`${screenSmDown ? "mb-4" : "mb-4 ml-5"} ${classes.divWidth5}`}
                                                    >
                                                        <TextField
                                                            name="otherBenefits"
                                                            className={classes.responsiveWidth}
                                                            label={translate("Offers.OtherBenefits")}
                                                            onChange={handleChange}
                                                            value={formData.otherBenefits}
                                                            placeholder={translate("Offers.IndicatesCompensationSalary")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: {root: classes.rootSecondary}
                                                            }}
                                                            multiline
                                                            rows={8}
                                                            InputProps={process && process.state && process.state.code === offersSatte.closed ? {
                                                                endAdornment: (<InputAdornment position="end"
                                                                                               className={classes.adornment}>
                                                                        <IconButton disabled>
                                                                            <LockIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            } : {}}
                                                            disabled={process && process.state && process.state.code === offersSatte.closed}
                                                        />
                                                    </div>
                                                }}
                                            </Translate>
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "pt-4 px-3" : "pt-5 pl-5 pr-4"} pb-4`}>
                                            {renderInformation("Offers.PreSelectionQuestions")}
                                        </Grid>
                                        <Grid item xs={12}
                                              className={`${screenSmDown ? "px-3" : "px-5 d-flex flex-wrap"}`}>
                                            {renderListQuestions()}
                                        </Grid>
                                        <Grid item xs={12} style={screenSmDown ? {
                                            marginTop: 51,
                                            paddingBottom: 51
                                        } : {marginTop: 185, paddingBottom: 51}}
                                              className={`${screenSmDown ? "px-3" : "px-5 d-flex flex-wrap"}`}>
                                            {process && process.state && process.state.code === offersSatte.closed ? "" :
                                                <Translate>
                                                    {({translate}) => {
                                                        return <Grid item xs={12}>
                                                            <div className="position-relative">
                                                                <FormControlLabel style={requiredCheckbox ? {
                                                                    border: "1px solid #FB060F",
                                                                    backgroundColor: "#FDE8E8",
                                                                    paddingRight: 16
                                                                } : {}}
                                                                                  control={
                                                                                      <Checkbox
                                                                                          checked={formData.confirmPublishingSelectionProcess}
                                                                                          onChange={handleChangeCheckedConfirmation}
                                                                                          name="confirmPublishingSelectionProcess"
                                                                                          color="default"
                                                                                          disabled={process && process.state && process.state.code === offersSatte.closed}
                                                                                      />
                                                                                  }
                                                                                  label={translate("Offers.ConfirmPublishingSelectionProcess")}
                                                                />
                                                                {requiredCheckbox ?
                                                                    <FormHelperText variant={"body2"} color={"error"}
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        color: "#FB060F"
                                                                                    }}>
                                                                        <Translate id={"Fields.RequiredCheckbox"}/>
                                                                    </FormHelperText> : ""}
                                                            </div>
                                                        </Grid>
                                                    }}
                                                </Translate>
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}
                          className={`justify-content-center ${classes.butonContainer} ${screenSmDown ? 'pb-5 pt-1' : 'py-5'}`}>
                        <div className="mt-3">
                            <GoBack title={null} type="button" dialog={ifChange}/>
                        </div>

                        <Button variant="contained" color="secondary" type="submit"
                                disabled={process && process.state && process.state.code !== offersSatte.draft ? !showView : false}
                                className={`${classes.submit} mx-3`}
                                onClick={() => {
                                    setPublished(false);
                                    setPreview(true);
                                }}>
                            <Typography className={`${classes.submitLabel} font-weight-bold`}>
                                <Translate id={"Buttons.Preview"}/></Typography>
                        </Button>


                        {allowPublishOrSave()
                        && <div className="position-relative">
                            <Button variant="contained" color="secondary" type="submit"
                                    className={`${classes.submit} mx-3`}
                                    onClick={() => onClickPublic()} disabled={publish}>
                                {publish && <CircularProgress size={30} className="mr-2"/>}
                                <Typography className={`${classes.submitLabel} font-weight-bold`}>
                                    <Translate
                                        id={processId && process.published ? "Buttons.SaveChanges" : "Buttons.Publish"}/>
                                </Typography>
                            </Button>
                            <Snackbar open={publicSaved}
                                      onClose={() => setPublicSaved(false)} autoHideDuration={5000}
                                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                      className={classes.snackbar}>
                                <Alert severity={"success"} icon={false} variant="outlined" color="success">
                                    <Typography variant={"body2"}>
                                        <Translate id={"Offers.ChangesMadeSuccessfully"}/>
                                    </Typography>
                                </Alert>
                            </Snackbar>
                        </div>}

                        {allowSaveDraft() &&
                        <div className="position-relative">
                            <Button variant="contained" color="secondary" type="submit"
                                    className={`${classes.submit} mx-3`}
                                    onClick={() => setPublished(false)}
                                    disabled={saving}>
                                {saving && <CircularProgress size={30} className="mr-2"/>}
                                <Typography className={`${classes.submitLabel} font-weight-bold`}>
                                    <Translate id={"Buttons.SaveDraft"}/></Typography>
                            </Button>
                            <Snackbar open={draftSaved} onClose={() => setDraftSaved(false)} autoHideDuration={5000}
                                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                      className={classes.snackbar}>
                                <Alert severity={"success"} icon={false} variant="outlined" color="success">
                                    <Typography variant={"body2"}>
                                        <Translate id={"Offers.DraftSaved"}/>
                                    </Typography>
                                </Alert>
                            </Snackbar>
                        </div>}

                        {processId && <Button variant="contained" color="secondary" type="button"
                                              className={`${classes.submit} mx-3`}
                                              disabled={deleting || (process && !process.canBeDeleted)}
                                              onClick={() => setOpenDialogDelete(true)}>
                            {deleting && <CircularProgress size={30} className="mr-2"/>}
                            <Typography className={`${classes.submitLabel} font-weight-bold`}><Translate
                                id={"Buttons.Delete"}/></Typography>
                        </Button>}
                    </Grid>
                    {renderDialogDelete()}
                </Grid>
            </ValidatorForm>
            : <Grid container direction="row" justify="center"
                    alignItems="center" className={`mt-4 ${classes.paddingXs}`}>
                <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                    <CircularProgress color="secondary" size={40}/>
                </Grid>
            </Grid>
    );
}

export default ProcessOffer;