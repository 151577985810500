import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import { Button, Typography, Hidden, Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ImageBackgroudLogin from "../../../images/employer/login-background.png";
import ProfessionalImageBackgroudLogin from "../../../images/professional/login-background.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as actions from "../../../actions/users";

const { innerWidth: width, innerHeight: height } = window;



function SuccessResetPassword(props) {

    const dispatch = useDispatch();
    let { userType } = props.match.params;

    const useStyles = makeStyles((theme) => ({
        titles: {
            marginTop: 50,
            [theme.breakpoints.down('xs')]: {
                marginTop: 10
            }
        },
        submit: {
            minWidth: 227,
            borderRadius: 0,
            marginTop: 40
        },
        buttomtext: {
            fontWeight: "bold",
            color: userType === "1" ? theme.palette.common.white : theme.palette.text.secondary,
            fontSize: 18,
            letterSpacing: 0.7,
            lineHeight: 1,
            //width: 125

        },
        mainTitle: {
            fontSize: 35,
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                fontSize: 30
            },
            lineHeight: 1
        },
    }));
    let classes = useStyles();

    useEffect(() => {
    }, [])

    function handleLogout() {
        dispatch(actions.logoutUser())
        props.history.push("/")
    }

    return (<Grid container spacing={0}>
        <Grid item xs={12} className={classes.titles}>
            <FormHelperText style={{color: "#54B169", fontSize: 16}} >
                {<Translate id={"Employer.ChangepasswordSuccess"} />}
            </FormHelperText>

        </Grid>

        <Grid item xs={12} md={12} lg={10} xl={7} className={classes.form}>
            <NavLink to={`/${userType === "professional" ? "professional" : "employer"}/login`}>
                <Button className={classes.submit} variant="contained" color={userType === "professional" ? "primary" : "secondary"} onClick={()=>{handleLogout();}}>
                    <Typography className={classes.buttomtext} style={userType === "professional" ? {color: "#FFFFFF"} : {}}><Translate id={"Buttons.Close"} /></Typography>
                </Button>
            </NavLink>
        </Grid>
    </Grid>);
}

export default withRouter(SuccessResetPassword);