import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useHistory} from 'react-router-dom'
import {Tooltip, Button, Typography} from '@material-ui/core';
import {Translate} from "react-localize-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";


function GoBack(props) {
    let {type, color, dialog} = props;
    color = color ? color : "secondary";

    const useStyles = makeStyles((theme) => ({
        circle: {
            width: 50,
            height: 50,
            borderRadius: 50,
            border: `3px solid ${theme.palette[color].main}`,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
                backgroundColor: theme.palette[color].main,
                "& .iconWhite": {
                    color: "white"
                }
            }
        },
        icon: {
            paddingLeft: 8,
            fontSize: 32
        },
        tooltip: {
            backgroundColor: "transparent",
            border: "none",
            maxWidth: 200,
            fontSize: 16,
            fontWeight: 700,
            color: "#141414",
            textAlign: "left",
            lineHeight: 1.3
        },
        buttonLabel: {
            color: "#141414",
            fontWeight: 700,
            fontSize: 18
        },
        button: {
            ...theme.overrides.MuiButton.root,
            borderRadius: 0,
            border: `2px solid ${theme.palette[color].main}`,
            "&:hover": {
                border: `2px solid ${theme.palette[color].main}`,
            },
            [theme.breakpoints.only('xs')]: {
                minWidth: 220
            }
        },
        margin: {
            marginLeft: 16,
            marginRight: 16,
            [theme.breakpoints.only('xs')]: {
                margin: 0,
                marginTop: props.ordered ? 50 : 0
            }
        },
        submit2: {
            minWidth: 82,
            borderRadius: 0,
            fontSize: 18,
            fontWeight: "bold"
        },
        paperAlert: {
            textAlign: "center",
            boxShadow: "0px 0px 25px #0000004D",
            borderRadius: 10,
            padding: "15px 48px 29px 48px",
            [theme.breakpoints.only('xs')]: {
                padding: "15px 24px 29px 24px",
            },
        },
    }));

    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory()
    let [openGoBack, setOpenGoBack] = useState(false);

    function goBack() {
        history.goBack();
    }

    function renderDialogGoBack() {
        return <Dialog
            open={openGoBack}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
            classes={{paper: classes.paperAlert}}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography className={`${classes.submit2} font-weight-bold`}><Translate
                    id={"Buttons.GoBackMessage"}/></Typography>
            </DialogTitle>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => goBack()}
                        className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}><Translate
                        id={"Buttons.Yes"}/></Typography>
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setOpenGoBack(false)}
                        className={`${classes.submit2} mx-3`}>
                    <Typography className={`${classes.submit2}`}><Translate
                        id={"Buttons.No"}/></Typography>
                </Button>
            </DialogActions>
        </Dialog>
    }

    function back() {
        if (dialog !== null && dialog===true){
            setOpenGoBack(true);
        }
        else{
            goBack();
        }

    }

    return (<React.Fragment>
        <Tooltip title={props.title} placement="right" classes={{tooltip: classes.tooltip}}>
            {type === "button"
                ? <Button variant="outlined" color={color} className={classes.margin}
                          classes={{root: classes.button, label: classes.buttonLabel}}
                          onClick={()=> {back()}}>
                    <Translate id="Buttons.GoBack"/>
                </Button>
                : <div className={classes.circle} onClick={()=> {back()}}>
                    <ArrowBackIosIcon color="secondary" className={`${classes.icon} iconWhite`}/>
                </div>}
        </Tooltip>
        {renderDialogGoBack()}
    </React.Fragment>);
}

export default withRouter(GoBack);