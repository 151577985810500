import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import {Translate} from "react-localize-redux";
import confirmationDialogImage from "../../images/confirmation-dialog.png";
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery, useTheme} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: 600,
        padding: 0,
        lineHeight: 1.3,
        [theme.breakpoints.only('xs')]: {
            fontSize: 16
        },
    },
    dialogContent: {
        padding: 0,
        flex: 0,
        [theme.breakpoints.only('xs')]: {},
    },
    button: {
        ...theme.overrides.DialogButton,
        borderRadius: 0,
        minHeight: 40,
        height: 40,
        minWidth: 81,
        [theme.breakpoints.down('sm')]: {
            marginLeft: "10px !important",
            marginTop: 8,
            marginBottom: 8
        },
    },
    dialog: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "21px 26px 85px #0000003B",
        borderRadius: 10,
        opacity: 1,
        padding: "30px 50px",
        minHeight: 150,
        width: "auto",
        minWidth: "auto",
        maxWidth: 500,
        [theme.breakpoints.only('xs')]: {
            padding: "18px 28px",
            maxWidth: 260,
        },
    },
    label: {
        color: theme.palette.text.secondary,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        },
    },
    labelWhite: {
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.only('xs')]: {
            fontSize: 17
        },
    },
    buttonContainer: {
        marginTop: 20,
        [theme.breakpoints.only('xs')]: {
            marginTop: 0
        },
    }
}));

export default function BasicConfirmation(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let classes = useStyles();
    let {acceptLabel, cancelLabel, type} = props;

    const handleAccept = async () => {
        setIsProcessing(true);
        if (props.handleAccept) await props.handleAccept();
        setIsProcessing(false);
    }

    const [isProcessing, setIsProcessing] = useState(false);
    return (
        <div>
            <Dialog  classes={{paper: classes.dialog}}
                    open={props.open}>
                {props.title &&
                <DialogTitle classes={{root: classes.dialogTitle}}>
                    <Typography variant="subtitle1" className={classes.dialogTitle}>{props.title}</Typography>
                </DialogTitle>}

                <DialogContent classes={{root: classes.dialogContent}}>
                    <Typography variant="subtitle1" className={classes.dialogTitle}>{props.question}</Typography>
                </DialogContent>

                <DialogActions className={classes.buttonContainer}>
                    <Button className={classes.button}
                            classes={{label: type === "primary" ? classes.labelWhite : classes.label}}
                            onClick={handleAccept} color={type} variant="contained">
                        {acceptLabel ? acceptLabel : <Translate id={"Buttons.Yes"}/>}
                    </Button>

                    <Button className={classes.button} style={{marginLeft: 35}}
                            classes={{label: type === "primary" ? classes.labelWhite : classes.label}}
                            variant="contained" color={type}
                            onClick={() => {
                                if (props.handleCancel) props.handleCancel();
                            }}>
                        {cancelLabel ? cancelLabel : <Translate id={"Buttons.No"}/>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}