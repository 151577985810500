let camerakit = require("camerakit");

let preview = null;
export async function initCamera() {
  const devices = await camerakit.getDevices();

  preview = await camerakit.createCaptureStream({
    audio: devices.audio[0],
    video: devices.video[0]
  });
  preview.setResolution({ width: 1920, height: 1080 });
}

export async function start() {
  if (preview)
    preview.recorder.start();
}

export async function pause() {
  if (preview)
    preview.recorder.pause();
}

export async function resume() {
  if (preview)
    preview.recorder.start();
}

export async function stop() {
  if (preview) {
    const recordedVideo = await preview.recorder.stop();
    return recordedVideo;
  }
}

export async function dispose() {
  if (preview) {
    preview.destroy();
    preview = null;
  }
}

