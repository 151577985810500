import React, {Component, useState, useEffect} from "react";
import {connect} from 'react-redux'
import {Translate, withLocalize} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import SeeInterview from '../../components/interview/seeInterview';
import SeeInterviewDevice from '../../components/interview/seeInterviewDevice';
import {CircularProgress} from "@material-ui/core";
import api from "../../api/index";
import * as localforage from "../../indexdb/localforageManager";
import getBlobDuration from 'get-blob-duration';
import {isSafari} from 'react-device-detect';
import {useDispatch, useSelector} from "react-redux";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";

function Viewer(props) {
    const professionalId = props.match.params["id"] ? parseInt(props.match.params["id"]) : null;
    const applicationId = props.match.params["application"] ? parseInt(props.match.params["application"]) : null;

    const [url, setUrl] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [localVideo, setLocalVideo] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [videoSegments, setVideoSegments] = useState(null);
    const [duration, setDuration] = useState(0);
    const [interview, setInterview] = useState(null);
    const [poster, setPoster] = useState(null);

    let [application, setApplication] = useState(null);
    const savedMetadatas = useSelector(state => state.users.interview.metadata);


    useEffect(() => {
        if (professionalId && applicationId) loadInterview();
        else {
            loadUserInterview().then(async () => {
                await loadPoster();
            });
        }
    }, [])

    async function loadUserInterview() {
        try {
            if (videoBlob === null && interview === null && questions === null) {
                let localVideo = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW);
                let localVideoSegments = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_SEGMENTS);

                if (localVideo) {
                    let url = window.URL.createObjectURL(new Blob([localVideo], {type: getMimetype()}));
                    let duration = await getDuration(localVideo);
                    setUrl(url);
                    setQuestions(savedMetadatas);
                    setLocalVideo(true);
                    setVideoBlob(localVideo);
                    setDuration(duration);
                } else if (localVideoSegments) {
                    let mappedQuestions = savedMetadatas.map((x, index) => {
                        let segment = localVideoSegments.length > index ? localVideoSegments[index] : null;
                        if (segment) {
                            x.videoUrl = URL.createObjectURL(segment);
                        }
                        return x;
                    })
                    setQuestions(mappedQuestions);
                    setLocalVideo(true);
                    setVideoSegments(localVideoSegments);
                } else {
                    let responseInterview = await api.interview.getProfessionalInterview();
                    if (responseInterview.data) {
                        let {videoUrl, metadata} = responseInterview.data;
                        setUrl(videoUrl);
                        setQuestions(metadata);
                        setLocalVideo(false);
                        setInterview(responseInterview.data);
                    }
                }
            }
        } catch (err) {
            //alert(`Error in loadUserInterview: ${JSON.stringify(err)}`);
        }
    }

    async function loadInterview() {
        let applicationResponse = await api.application.getApplication(applicationId);
        if (applicationResponse.status === 200) {
            setApplication(applicationResponse.data);
        }

        let responseInterview = await api.interview.get(professionalId);
        if (responseInterview.data) {
            let {videoUrl, metadata} = responseInterview.data;
            setUrl(videoUrl);
            setQuestions(metadata);
            setLocalVideo(false);
            setInterview(responseInterview.data);
        }
    }

    async function loadPoster() {
        if (poster === null) {
            let localPoster = await localforage.getItem(localforage.PROFESSIONAL_INTERVIEW_POSTER);
            setPoster(localPoster);
        }
    }

    function getMimetype() {
        console.log("isSafari: " + isSafari);
        return isSafari ? "video/webm" : "video/mp4";
    }

    async function getDuration(localVideo) {
        try {
            let duration = await getBlobDuration(new Blob([localVideo], {type: getMimetype()}));
            if (duration >= 0) duration = parseInt(duration);
            return duration;
        } catch (err) {
            return 0;
        }
    }

    function renderViewer() {
        if ((interview && interview.videoUrl === "segment") || videoSegments) {
            return (<SeeInterviewDevice
                questions={questions}
                isLocalVideo={localVideo}
                videoSegments={videoSegments}
                poster={poster}
                application={application}
            />)
        } else {
            return (<SeeInterview
                url={url}
                videoBlob={localVideo}
                questions={questions}
                isLocalVideo={localVideo}
                duration={duration}
                poster={poster}
                application={application}
            />)
        }
    }

    function renderBreadCrumb() {
        return (applicationId
            ? <React.Fragment>
                <BreadcrumbsItem weight={1} to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>

                <BreadcrumbsItem weight={2}  to='/employer/workspace'><Translate id={"Employer.EmployerArea"}/></BreadcrumbsItem>

                <BreadcrumbsItem weight={3}
                                 to={`/employer/process/management/${application && application.process ? application.process.id : 0}`}>
                    <Translate id={"Offers.ManagementTitle"}/>
                </BreadcrumbsItem>

                <BreadcrumbsItem weight={4}  to={`/professional/${professionalId}/${applicationId}`}>
                    <Translate id={"Professional.Profile.Title"}/>
                </BreadcrumbsItem>

                <BreadcrumbsItem weight={5}  to={`/professional/${professionalId}/${applicationId}/some`}>
                    <Translate id={"Titles.VideoInterview"}/>
                </BreadcrumbsItem>
            </React.Fragment> : <div></div>)
    }

    return (<div>
        {questions !== null
            ? renderViewer()
            : <CircularProgress size={40} color="primary"/>}
    </div>);
}

export default withRouter(Viewer);