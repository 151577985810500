import React from "react";
import throttle from "lodash/throttle";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Tabs, Tab, Grid, Hidden} from "@material-ui/core";
import {isIOS, isWindows, isMacOs, isAndroid, isChrome, isFirefox, isSafari, isEdge} from "react-device-detect";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Translate} from "react-localize-redux";

const tabHeight = 30;
const StyledTabs = withStyles(theme => ({
    indicator: {
        display: "flex",
        justifyContent: "center",
        width: 3,
        backgroundColor: "transparent",
        "& > div": {
            maxWidth: 30,
            width: "100%",
            backgroundColor: theme.palette.primary.main,

        },
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    },
    flexContainer: {
        [theme.breakpoints.only('xs')]: {
            textAlign: "center",
            alignItems: "center"
        }
    }
}))(props => <Tabs {...props} TabIndicatorProps={{children: <div/>}}/>);

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: "none",
        height: tabHeight,
        fontSize: 18,
        fontWeight: 500,
        cursor: "pointer",
        marginRight: 0,
        padding: 0,
        "&:focus": {
            opacity: 1
        }
    },
    wrapper: {
        textAlign: "right",
        alignItems: "flex-end",
        [theme.breakpoints.only('xs')]: {
            textAlign: "center",
            alignItems: "center"
        }
    }
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    indicator: {
        padding: theme.spacing(1),
    },
    demo2: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 120,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: 10,
    },
    accordionRoot: {
        width: "100%",
        position: "fixed",
        right: 0,
        left: 0,
        top: 75
    },
    topResponsive: {
        [theme.breakpoints.only('xs')]: {
            marginTop: 40
        }
    }
}));

const makeUnique = (hash, unique, i = 1) => {
    const uniqueHash = i === 1 ? hash : `${hash}-${i}`;
    if (!unique[uniqueHash]) {
        unique[uniqueHash] = true;
        return uniqueHash;
    }
    return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
    return makeUnique(
        encodeURI(
            text
                .toLowerCase()
                .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
                .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
                .replace(/-+/g, "-")
                .replace(/^-|-$/g, "")
        ),
        unique
    );
};
const noop = () => {
};

function useThrottledOnScroll(callback, delay) {
    const throttledCallback = React.useMemo(
        () => (callback ? throttle(callback, delay) : noop),
        [callback, delay]
    );

    React.useEffect(() => {
        if (throttledCallback === noop) return undefined;

        window.addEventListener("scroll", throttledCallback);
        return () => {
            window.removeEventListener("scroll", throttledCallback);
            throttledCallback.cancel();
        };
    }, [throttledCallback]);
}

const isUnsupportedHelp = isIOS && !isFirefox && !isChrome && !isSafari;

function getActiveIndex() {
    if (isUnsupportedHelp) return 5;

    let section = (isWindows || isMacOs) && isChrome ? 0
        : (isWindows || isMacOs) && isFirefox ? 1
            : (isWindows || isMacOs) && isEdge ? 2
                : isAndroid && isChrome ? 3
                    : isAndroid && isFirefox ? 4
                        : isIOS && isChrome ? 5
                            : isIOS && isFirefox ? 6
                                : isIOS && isSafari ? 7 : null;
    return section;
}

function ScrollSpyTabs(props) {
    const {tabsInScroll} = props;
    let [activeTab, setActiveTab] = React.useState(null);
    let activeBrowser = getActiveIndex();

    let helpTabs = activeBrowser === null ? tabsInScroll : [tabsInScroll[activeBrowser]];

    let itemsServer = helpTabs.map(tab => {
        const hash = textToHash(tab.text);
        return {
            icon: tab.icon || "",
            text: tab.text,
            component: tab.component,
            hash: hash,
            node: document.getElementById(hash)
        };
    });
    const [activeState, setActiveState] = React.useState(null);

    const itemsClientRef = React.useRef([]);

    React.useEffect(() => {
        itemsClientRef.current = itemsServer;
        if (activeTab === null) {
            let index = getActiveIndex();
            setActiveTab(index);
            if (index !== null && index < itemsServer.length)
                handleClick(itemsServer[index].hash)
        }
    }, [itemsServer, activeTab]);


    const clickedRef = React.useRef(false);
    const unsetClickedRef = React.useRef(null);
    const findActiveIndex = React.useCallback(() => {
        // set default if activeState is null
        if (activeState === null) setActiveState(itemsServer[0].hash);

        // Don't set the active index based on scroll if a link was just clicked
        if (clickedRef.current) return;

        let active;
        for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
            // No hash if we're near the top of the page
            if (document.documentElement.scrollTop < 0) {
                active = {hash: null};
                break;
            }

            const item = itemsClientRef.current[i];

            if (
                item.node &&
                item.node.offsetTop <
                document.documentElement.scrollTop +
                document.documentElement.clientHeight / 8 +
                tabHeight
            ) {
                active = item;
                break;
            }
        }

        if (active && activeState !== active.hash) {
            setActiveState(active.hash);
        }
    }, [activeState, itemsServer]);

    // Corresponds to 10 frames at 60 Hz
    useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166);

    function handleClick(hash) {
        // Used to disable findActiveIndex if the page scrolls due to a click
        clickedRef.current = true;
        unsetClickedRef.current = setTimeout(() => {
            clickedRef.current = false;
        }, 1000);

        if (activeState !== hash) {
            setActiveState(hash);

            if (window)
                window.scrollTo({
                    top:
                        document.getElementById(hash).getBoundingClientRect().top +
                        window.pageYOffset - 120,
                    behavior: "smooth"
                });
        }
    };


    React.useEffect(() => () => {
        clearTimeout(unsetClickedRef.current);
    }, [])

    const classes = useStyles();

    function renderAccordion() {
        return (
            <div className={classes.accordionRoot}>
                <Accordion style={{borderRadius: 0}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography variant="h2" color="primary" className={classes.heading}><Translate
                            id="Help.TableContent"/></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StyledTabs style={{margin: "auto"}} value={activeState ? activeState : itemsServer[0].hash}
                                    orientation="vertical">
                            {itemsServer.map(item2 => (
                                <StyledTab
                                    key={item2.hash}
                                    label={item2.text}
                                    onClick={() => handleClick(item2.hash)}
                                    value={item2.hash}
                                />
                            ))}
                        </StyledTabs>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }

    return (<Grid container spacing={0}>
            <Grid item md={3} sm={4} xs={12}>
                <nav className={classes.demo2}>
                    <Hidden smUp>
                        {renderAccordion()}
                    </Hidden>

                    <Hidden xsDown>
                        <StyledTabs value={activeState ? activeState : itemsServer[0].hash} orientation="vertical">
                            {itemsServer.map(item2 => (
                                <StyledTab
                                    key={item2.hash}
                                    label={item2.text}
                                    onClick={() => handleClick(item2.hash)}
                                    value={item2.hash}
                                />
                            ))}
                        </StyledTabs>
                        <div className={classes.indicator}/>
                    </Hidden>
                </nav>
            </Grid>
            <Grid className={classes.topResponsive} item md={8} sm={8} xs={12}>
                {isUnsupportedHelp ? <div className="p-3">
                    <Typography color="textSecondary" style={{fontWeight: 600}}><Translate id="Support.Generic"/></Typography>
                </div> : <div></div>}
                <div className="container">
                    {itemsServer.map(item1 => (
                        <article id={item1.hash} key={item1.text}>
                            {item1.component}
                        </article>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
}

export default ScrollSpyTabs;