import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {Button, Hidden, useMediaQuery} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StepConnector from "@material-ui/core/StepConnector";
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import ConfirmDialog from "../../confirmation/confirm";
import {withRouter} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem"
        }

    },
    instructions: {
        minHeight: 110,
        [theme.breakpoints.down('sm')]: {
            marginTop: 7,
            minHeight: 78,
            paddingBottom: "0 !important"
        },
    },
    backgroundSecondary: {
        background: theme.palette.secondary.main,
    },
    questionTimer: {
        margin: -5,
        background: "transparent",
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },
    },
    stepper: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },
        [theme.breakpoints.up('xs')]: {
            '@media (min-height: 200px) and (max-height: 700px)': {
                padding: 0
            }
        }

    },
    ready: {
        minHeight: 45,
        [theme.breakpoints.down('xs')]: {
            minHeight: 22,
            paddingBottom: "0 !important",
            paddingTop: "0 !important"
        }
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 240,
            //height: 48
        },
        [theme.breakpoints.down('sm')]: {
            padding: "5px 10px",
            maxWidth: "100%"
        },
        [theme.breakpoints.up('md')]: {
            //minWidth: 240,
        }
    },
    z2: {
        zIndex: 2,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: "0 !important"
        },

    },
    buttonHeight: {
        height: 61,
        [theme.breakpoints.down('xs')]: {
            height: 48
        },

    },
    p0: {
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    pt3: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: "1rem",
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: 10
        }
    },
    mtxs1: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: "0 !important"
        }
    }
}));

const ColorlibConnector = withStyles({
    root: {
        display: "none"
    },
    active: {
        '& $line': {
            border: 'none',
        },
    },
    completed: {
        '& $line': {
            border: 'none',
        },
    },
    line: {
        height: 0,
        border: 0,
    },
    step: {
        flex: 0
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        opacity: 1,
        [theme.breakpoints.only('xs')]: {
            width: 26,
            height: 26,
        },
    },
    circle: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        opacity: 1,
        [theme.breakpoints.only('xs')]: {
            width: 20,
            height: 20,
        },
    },
    backgroundPrimary: {
        background: theme.palette.primary.main,
        fontSize: 18,
        display: 'flex',
    },
    backgroundSecondary: {
        background: theme.palette.secondary.main,
        zIndex: 1,
        fontSize: 18,
        display: 'flex',
    },
    text: {
        margin: "auto",
        fontSize: 18,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        },
    }

}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed, icon, steps} = props;
    const activeQuestion = steps[icon - 1];
    return (<div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed || active ?
                <Tooltip title={activeQuestion ? activeQuestion : activeQuestion.question} placement="top">
                    <div className={`${classes.backgroundPrimary} ${active ? classes.active : classes.circle}`}>
                        <Typography
                            variant="inherit" className={classes.text}>{icon}</Typography></div>
                </Tooltip> :
                <Tooltip title={activeQuestion ? activeQuestion : activeQuestion.question} placement="top">
                    <div className={`${classes.circle} ${classes.backgroundSecondary}`}>
                        <Typography
                            variant="inherit" className={classes.text}>{icon}</Typography></div>
                </Tooltip>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};


function HorizontalLabelPositionBelowStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [hiddenButton, setHiddenButton] = React.useState(0);
    const [startRecording, setStartRecording] = React.useState(0);
    const [endRecord, setEndRecord] = React.useState(0);
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    const {steps, seconds, parentActiveStep, metadatas, isValid} = props;
    const totalQuestions = steps ? steps.length : 5;

    const getStepContent = (stepIndex) => {
        if (stepIndex < steps.length) return steps[stepIndex];
        return 'Unknown stepIndex';
    }

    useEffect(() => {
        if (props.onload) props.onload(handleCancel);
        if (metadatas.length > activeStep) handleNext();
    }, [steps, parentActiveStep, metadatas, isValid, totalQuestions]);

    const handleNext = () => {
        if (activeStep + 1 < totalQuestions) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setHiddenButton(1)
        }
    };

    const handleCancel = () => {
        setCancelConfirmation(true)
        props.history.push(window.location.pathname)
    };

    const handleStartRecording = () => {
        //handleNext();
        setStartRecording(1)
        if (props.recordInputDevice) props.recordInputDevice(activeStep);
    }

    const {innerWidth: width, innerHeight: height} = window;
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLgOnly = useMediaQuery(theme.breakpoints.only('lg'));
    const maxSmHeightVideo = 484;
    const iphoneHeight = 667;

    return (
        <div className={classes.root}>
            <Grid container spacing={2} className="text-center">
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep} alternativeLabel={false} connector={<ColorlibConnector/>}
                             className={`${classes.stepper} justify-content-center ${screenXsDown && height > iphoneHeight && "py-3"}`}>
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel StepIconComponent={(props) => QontoStepIcon({...props, steps: steps})}/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

                <Grid container spacing={0} className={classes.questionTimer}>
                    <Grid item xs={12} className={`${classes.z2} ${classes.p0}`}>
                        <Typography variant="h2" className={`${classes.instructions} px-2`}>
                            {getStepContent(activeStep)}
                        </Typography>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    {!isValid ? metadatas.some(x => x.duration === 0)
                        ? <Typography variant="caption" color="error"><Translate
                            id={"Titles.AdjustTimesCero"}/></Typography>
                        : <Typography variant="caption" color="error"><Translate
                            id={"Titles.MaxTimeViolation"}/></Typography>
                        : ""}
                    {!isValid && <br/>}
                    {!isValid &&
                    <Typography variant="caption" color="error"><Translate id={"Errors.StartAgain"}/></Typography>}
                </Grid>
                <Grid item xs={12} className={classes.pt3}>
                    <div>
                        <Translate>
                            {({translate}) => (
                                <Button disabled={!isValid} variant="contained" color="primary"
                                        onClick={handleStartRecording} className={classes.button}>
                                    {activeStep === 0 ? translate("Buttons.StartRecording") : `${translate("Buttons.RecordingX")} ${activeStep + 1}`}
                                </Button>
                            )}
                        </Translate>
                    </div>

                </Grid>
                <Grid item xs={12}
                      className={`${classes.pt3} ${screenXsDown && height > iphoneHeight ? "pt-2" : classes.mtxs1}`}>
                    <div>
                        {activeStep !== 0 &&
                        <Translate>
                            {({translate}) => (
                                <Button variant="outlined" color="default" onClick={() => setCancelConfirmation(true)}
                                        className={classes.button}>
                                    {translate("Buttons.CancelRecording")}
                                </Button>
                            )}
                        </Translate>}
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <ConfirmDialog
                        id="cancel-record" open={cancelConfirmation}
                        title={<Translate id={"Tests.CancelTestTitle"}/>}
                        question={<Translate id={"Tests.CancelTestQuestion"}/>}
                        handleCancel={() => setCancelConfirmation(false)}
                        handleAccept={() => {
                            handleCancel()
                        }}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default withRouter(HorizontalLabelPositionBelowStepper);