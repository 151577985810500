import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Translate} from "react-localize-redux";
import Paper from "@material-ui/core/Paper";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {RemoveRedEyeOutlined, VisibilityOffOutlined, Search, Image} from "@material-ui/icons";
import {Button, IconButton, InputAdornment, useMediaQuery, Hidden, CircularProgress} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InfoIcon from '@material-ui/icons/Info';
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import LockIcon from '@material-ui/icons/Lock';
import 'react-quill/dist/quill.snow.css';
import TextField from "@material-ui/core/TextField";
import api from "../../api/index";
import moment from "moment";
import DefaultImage from "../../images/employer/default-image.png";
import {NavLink, useHistory} from "react-router-dom";
import Link from '@material-ui/icons/Link';
import ConfirmDialog from "../../components/confirmation/confirm";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../actions/users/index";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => {
    theme.overrides.MuiInputLabel = {
        ...theme.overrides.MuiInputLabel,
        root: {
            ...theme.overrides.MuiInputLabel.root,
            fontWeight: "bold"
        }
    }
    return {
        text: {
            fontSize: 16,
            letterSpacing: 0.9,
            textAlign: "right",
            fontWeight: 600
        },
        linkPassword: {
            fontSize: 16,
            fontWeight: "bold",
            color: theme.palette.secondary.main,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15,
            }
        },
        title: {
            fontSize: 35,
            fontWeight: 600,
            //marginTop: 20,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
                marginTop: 0
            }
        },
        responsiveWidth: {
            width: "100%",
            "& input": {
                overflow: "hidden"
            },
            '& .MuiInputBase-root.Mui-error textarea': {
                backgroundColor: "#FDE8E8"
            }
        },
        icon: {
            padding: 15,
            background: "#EDEDED",
            width: 54,
            height: 54,
            position: "absolute",
            right: 0,
            top: 20,
            cursor: "pointer",
        },
        information: {
            fontSize: 22,
            fontWeight: 600,
            fontFamily: "Barlow",
            [theme.breakpoints.only('xs')]: {
                fontSize: 20
            }
        },
        colorBlack: {
            color: theme.palette.text.secondary
        },
        toolTip: {
            backgroundColor: "white",
            color: theme.palette.text.secondary,
            fontSize: 12,
            maxWidth: 244,
            border: `1px solid ${theme.palette.text.secondary}`,
            borderRadius: "initial",
            letterSpacing: 0.58

        },
        divider: {
            width: 136,
            height: 0,
            border: `1px solid ${theme.palette.text.secondary}`,
        },
        adornment: {
            '& .MuiIconButton-root.Mui-disabled': {
                color: theme.palette.text.secondary
            }
        },
        submit: {
            minWidth: 200,
            borderRadius: 0,
            fontSize: 18
        },
        marginUpMd: {
            marginTop: 382,
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
            }
        },
        image: {
            maxHeight: 48,
            maxWidth: 42,
            objectFit: "contain"
        },
        completeSuggest: {
            fontSize: 25,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
                marginTop: 8
            }
        },
        deleteAccount: {
            minWidth: 200,
            position: "absolute",
            right: 0,
            borderRadius: 0,
            [theme.breakpoints.down('xs')]: {
                position: "unset",
                marginTop: 20
            }
        },
        rootSecondary: {
            color: theme.palette.text.secondary,
            fontWeight: 600,
            display: "flex",
            letterSpacing: 0.8,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            }
        },
        rootSecondaryFull: {
            color: theme.palette.secondary.main,
            "&.Mui-disabled": {
                color: theme.palette.secondary.main
            }
        },
        butonContainer: {
            display: "flex",
            position: "relative",
            [theme.breakpoints.down('xs')]: {
                display: "block",
                position: "unset",
                textAlign: "center"
            }
        },
        required: {
            fontWeight: 600,
            fontSize: 16
        },
        paddingXs: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "1rem",
                paddingLeft: "1rem"
            }
        },
        ellipsis: {
            textOverflow: "ellipsis",
            display: "inline-block",
            overflow: "hidden",
            maxWidth: "95%",
            whiteSpace: "nowrap",
            verticalAlign: "middle"
        },
        ellipsisMin: {
            maxWidth: "90%"
        },
        paper: {

            [theme.breakpoints.up('sm')]: {
                boxShadow: "0px 0px 36px #00000015",
                paddingBottom: 48,
            }
        },
        fileInput: {
            top: 29,
            left: 74,
            display: "flex",
            zIndex: 2,
            position: "absolute",
            alignItems: "center",
            justifyContent: "spaceAround",
            flexWrap: "wrap"
        },
        fileInputHidden: {
            '& input': {
                opacity: 0,
                zIndex: 3
            },
            '& .MuiInputBase-input.Mui-disabled': {
                display: "none"
            }
        }
    }
});

export default function Employer() {

    const theme = useTheme();
    let history = useHistory();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    let [sectors, setSectors] = useState([]);
    let [employerTypes, setEmployerTypes] = useState([]);
    const classes = useStyles();
    let [typePass, setTypePass] = useState(true);
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const [openTooltipNo, setOpenTooltipNo] = React.useState(false);
    let [employerInfo, setEmployerInfo] = useState(null);
    let [saving, setSaving] = useState(false);
    let [searching, setSearching] = useState(false);
    let [isCompanyManager, setIsCompanyManager] = useState(false);
    let [selectedLogo, setSelectedLogo] = useState(null);
    let [confirmation, setConfirmation] = useState(false);
    let [confirmationFirst, setConfirmationFirst] = useState(false);
    let [formData, setFormData] = useState({
        cIFCompanyIndividualNIF: "",
        enterpriseName: "",
        password: "",
        receivePromotionalCommercialNotifications: false,
        nameCompanyIndividual: "",
        sector: "",
        web: "",
        awardsCertificatesCollaborations: "",
        logoCorporateImage: "",
        companyIndividualRegistrationEmail: "",
        registrationDate: "",
        employerType: "",
        descriptionActivity: "",
        facturationAddress: "",
        TalentManagementPolicies: "",
        enterpriseId: -1,
        logo: null
    });
    const dispatch = useDispatch();
    let loguedUser = useSelector(state => state.users.loggedUser);
    let [imageBase64, setImageBase64] = useState(false);
    let [validateFile, setValidateFile] = useState(true);
    let [validateCIF, setValidateCIF] = useState(true);
    const inicio = ['X', 'Y', 'Z'];
    const control = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E'];

    let searchRef = React.createRef();

    useEffect(() => {
        loadEmployerProfile();
        loadEmployerTypes();
        loadSectors();
    }, [sectors, employerTypes, employerInfo, formData])

    function loadEmployerTypes() {
        if (employerTypes.length === 0)
            api.employerTypes.all().then(response => {
                if (response.status === 200) {
                    setEmployerTypes(response.data);
                }
            })
    }

    function loadSectors() {
        if (sectors.length === 0)
            api.sectors.all().then(response => {
                if (response.status === 200) {
                    setSectors(response.data);
                }
            })
    }

    function loadEmployerProfile() {
        if (employerInfo === null)
            api.employers.getEmployerProfile().then(response => {
                if (response.status === 200) {
                    setEmployerInfo(response.data);
                    mapEmployerInfo(response.data);
                }
            })
    }

    function mapEmployerInfo(employer) {
        let infoCopy = Object.assign({}, formData);
        let {enterprise} = employer;

        infoCopy.companyIndividualRegistrationEmail = employer.email;
        infoCopy.registrationDate = moment(new Date(employer.registrationDate)).format("DD/MM/YYYY");
        infoCopy.receivePromotionalCommercialNotifications = employer.receiveNotifications;
        infoCopy.employerType = employer.employerType.id > 0 ? employer.employerType.id : null;

        infoCopy.descriptionActivity = enterprise.activityDescription;
        infoCopy.facturationAddress = enterprise.facturationAddress;
        infoCopy.web = enterprise.webUrl;
        infoCopy.TalentManagementPolicies = enterprise.policyManagementTalent;
        infoCopy.cIFCompanyIndividualNIF = enterprise.identifier;
        infoCopy.awardsCertificatesCollaborations = enterprise.awards;

        infoCopy.sector = enterprise.sector ? enterprise.sector.id : "";
        infoCopy.enterpriseName = enterprise.name;
        infoCopy.enterpriseId = enterprise.id;
        infoCopy.logo = enterprise.logoUrl;

        setConfirmationFirst(enterprise.identifier);
        setFormData(infoCopy);
        setIsCompanyManager(enterprise.isUserCompanyManager);
    }

    async function handleSubmit() {
        try {
            setSaving(true);
            let dataPost = {
                receiveNotifications: formData.receivePromotionalCommercialNotifications,
                webUrl: formData.web,
                awards: formData.awardsCertificatesCollaborations,
                activityDescription: formData.descriptionActivity,
                policyManagementTalent: formData.TalentManagementPolicies,
                employerTypeId: formData.employerType ? formData.employerType : null,
                enterpriseId: formData.enterpriseId ? formData.enterpriseId : null,
                enterpriseCifOrNif: formData.cIFCompanyIndividualNIF,
                enterpriseName: formData.enterpriseName,
                enterpriseSectorId: formData.sector ? formData.sector : null,
                enterpriseFacturationAddress: formData.facturationAddress ? formData.facturationAddress : "",
                logo: selectedLogo
            };
            let response = await api.employers.addOrUpdate(dataPost);
            setSaving(false);
            if (confirmationFirst !== null)
                history.push("/employer/workspace");
            else
                history.push("/employer/success-register");
        } catch (err) {
            setSaving(false);
        }
    }

    async function checCompletedProfile() {
        let response = {data: false};
        if (loguedUser.isEmployer) {
            response = await api.employers.checkCompletedProfile();
        } else if (loguedUser.isProfessional) {
            response = await api.professional.checkCompletedProfile();
        }
        return response.data;
    }

    async function goToProfile() {
        let compltedProfile = await checCompletedProfile();
        if (compltedProfile) history.push("/employer/workspace");
        else history.push("/employer/edit-profile");
    }

    const handleChange = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.value;
        setFormData(data);
    }

    const handleChangeChecked = (event) => {
        let data = Object.assign({}, formData);
        data[event.target.name] = event.target.checked;
        setFormData(data);
    };

    const handleChangeHtml = (html) => {
        let data = Object.assign({}, formData);
        data["DescriptionActivity"] = html;
        setFormData(data);
    };

    const handleTooltipClose = (isNo) => {
        if (isNo)
            setOpenTooltip(false);
        else
            setOpenTooltipNo(false);
    };

    const handleTooltipOpen = (isNo) => {
        if (isNo)
            setOpenTooltip(!openTooltip);
        else
            setOpenTooltipNo(!openTooltipNo);
    };

    function validateDNI(value) {
        let regExp = new RegExp('(^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$)|(^([a-z]|[A-Z]){1}[0-9]{8}$)');

        let ascii = value.toUpperCase().charCodeAt(0);
        let exist = inicio.findIndex(i => i === value.toUpperCase().charAt(0));
        let identification_type_id = ascii > 64 && ascii < 91 && exist !==-1 ? "NIE" : "";
        let valid = true;
        if (identification_type_id === "NIE" && value.length !== 9) {
            valid = false;
        }
        if (identification_type_id === "NIE") {
            let number = 0;
            let data = value.substr(1, 7);
            let position = inicio.findIndex(i => i === value.substr(0, 1));
            if (position === -1) {
                valid = false;
            } else {
                number = parseInt(position + data.toString());
            }

            let resto = number % 23;
            if (control[resto] !== value.substr(8, 1).toUpperCase()) {
                valid = false;
            }
            return valid;
        } else {
            return regExp.test(value);
        }
    }

    async function handleKeyDown(event) {

        if (event.key === 'Enter' || event === true) {
            if (validateDNI(formData.cIFCompanyIndividualNIF)) {
                setValidateCIF(true);
                setSearching(true);
                setImageBase64(false);
                let identifier = formData.cIFCompanyIndividualNIF;
                if (identifier) {
                    let response = await api.enterprises.detailsByIdentifier(identifier);
                    let enterprise = response.data;

                    let infoCopy = Object.assign({}, formData);
                    infoCopy.sector = enterprise && enterprise.sector ? enterprise.sector.id : "";
                    infoCopy.enterpriseName = enterprise ? enterprise.name : "";
                    infoCopy.enterpriseId = enterprise ? enterprise.id : "";
                    infoCopy.logo = enterprise ? enterprise.logoUrl : null;

                    infoCopy.descriptionActivity = enterprise ? enterprise.activityDescription : "";
                    infoCopy.facturationAddress = enterprise ? enterprise.facturationAddress : "";
                    infoCopy.web = enterprise ? enterprise.webUrl : "";
                    infoCopy.TalentManagementPolicies = enterprise ? enterprise.policyManagementTalent : "";
                    infoCopy.awardsCertificatesCollaborations = enterprise ? enterprise.awards : "";
                    infoCopy.employerType = enterprise ? enterprise.employerManagerTypeId : null;

                    setFormData(infoCopy);
                    setIsCompanyManager(enterprise ? enterprise.isUserCompanyManager : true);
                }
                setSearching(false);
            } else {
                setValidateCIF(false)
            }
        }

    }

    function renderInformation(title, toolTipText, isNo) {
        return <Grid container>
            <Grid item xs={11} sm={6} md={4} lg={3} xl={2}>
                <Typography className={`${classes.information} ${classes.colorBlack}`}>
                    <Translate id={title}/>
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Tooltip classes={{tooltip: classes.toolTip}}
                         PopperProps={{
                             disablePortal: true,
                         }}

                         title={<Translate id={toolTipText}/>}
                         placement="right">
                    <Icon style={{width: "auto", height: "auto", cursor: "pointer"}}
                          className={classes.colorBlack}>
                        <InfoIcon/>
                    </Icon>
                </Tooltip>
            </Grid>
            <Grid item xs={12} className="pt-2">
                <Divider className={classes.divider}/>
            </Grid>
        </Grid>
    }

    function renderFileAddorment() {
        return (<InputAdornment position="start" className={classes.adornment}>
            <img className={classes.image}
                 src={imageBase64 ? imageBase64 : formData.logo ? `${formData.logo}?image=${Math.random()}` : DefaultImage}/>
        </InputAdornment>)
    }

    function handleSelectLogo(event) {
        let data = Object.assign({}, formData);
        if (event.target.files.length) {
            let file = event.target.files[0];
            let size = file.size;
            if (size / 1000 <= 300) {
                setValidateFile(true);
                let formDataFile = new FormData();
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    setImageBase64(reader.result);
                };
                data["logo"] = file.name;
                formDataFile.append("file", file);
                setSelectedLogo(formDataFile);
                setFormData(data);
            } else {
                setValidateFile(false);
            }

        }
    }

    function deleteAccount() {
        api.users.disableAccount().then(response => {
            if (response.status === 200) {
                setConfirmation(false);
                dispatch(actions.logoutUser())
            }
        })
    }

    function renderRequired() {
        return <Typography className={classes.required} color="error">*</Typography>
    }

    function renderLabel(translate, icon = false) {
        return (<div className="d-flex" style={{width: "100%"}}>
            <Typography
                className={`${classes.rootSecondary} ${classes.ellipsis} ${icon ? classes.ellipsisMin : ""}`}>
                <Translate id={translate}></Translate>
            </Typography>
            {renderRequired()}
        </div>);
    }

    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace' onClick={goToProfile}><Translate
                id={"Employer.EmployerArea"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace/some'><Translate
                id={"Employer.EmployerRegistrationData"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    return (<ValidatorForm onSubmit={handleSubmit} onError={errors => console.log(errors)} instantValidate={false}>
            <Grid container direction="row" justify="center"
                  alignItems="center" className={`mt-4 ${classes.paddingXs}`}>
                {renderBreadCrumb()}
                <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
                    <Typography className={classes.title} color="textSecondary">
                        <Translate id={"Employer.EmployerRegistrationData"}/>
                    </Typography>
                    <Grid item xs={12} md={8}>
                        <Typography className={classes.completeSuggest} color="secondary">
                            <Translate id="Employer.CompleteProfile"/>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
                            <Grid container spacing={0} direction="row"
                                  justify={screenXsOnly ? "flex-start" : "flex-end"} alignItems="center">
                                <Grid item style={{minWidth: 170, marginLeft: 16}}>
                                    <div className="d-flex text-right mb-2">
                                        {renderRequired()}<Typography className={classes.text}
                                                                      color="textSecondary"><Translate
                                        id={"Employer.RequiredFields"}/></Typography>
                                    </div>
                                </Grid>
                            </Grid>

                            <Paper square elevation={0} className={classes.paper}>
                                <Grid container direction="row" justify="center" alignItems="stretch">
                                    <Grid item xs={12} className={screenSmDown ? "pt-4 px-3" : "pt-5 pl-5 pr-4"}>
                                        {renderInformation("Employer.InformationNotVisible", "Employer.InformationNotVisibleDescription", false)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Grid container direction="row">
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            helperText={!validateCIF ? translate("Employer.IncorrectCIF") : ""}
                                                                            error={!validateCIF}
                                                                            ref={searchRef}
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.CIFCompanyIndividualNIF")}
                                                                            onChange={handleChange}
                                                                            onBlur={handleKeyDown}
                                                                            onKeyDown={handleKeyDown}
                                                                            name="cIFCompanyIndividualNIF"
                                                                            value={formData.cIFCompanyIndividualNIF}
                                                                            validators={['required', 'matchRegexp:(^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$)|(^([a-z]|[A-Z]){1}[0-9]{8}$)']}
                                                                            placeholder={translate("Employer.CIFCompanyIndividualNIFHolder")}
                                                                            errorMessages={[translate("Fields.Required"), translate("Employer.IncorrectCIF")]}
                                                                            disabled={employerInfo && employerInfo.enterprise && employerInfo.enterprise.identifier !== null}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: employerInfo && employerInfo.enterprise && employerInfo.enterprise.identifier !== null ?
                                                                                    (<InputAdornment position="end"
                                                                                                     className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>)
                                                                                    :
                                                                                    (
                                                                                        <InputAdornment
                                                                                            position="end">
                                                                                            <IconButton
                                                                                                aria-label="toggle password visibility"
                                                                                                onClick={() => setTypePass(!typePass)}>
                                                                                                {searching ?
                                                                                                    <CircularProgress
                                                                                                        size={30}/> :
                                                                                                    <Search
                                                                                                        onClick={() => handleKeyDown(true)}/>}
                                                                                            </IconButton>
                                                                                        </InputAdornment>
                                                                                    )
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.CompanyIndividualRegistrationEmail")}
                                                                            onChange={handleChange}
                                                                            name="companyIndividualRegistrationEmail"
                                                                            value={formData.companyIndividualRegistrationEmail}
                                                                            validators={['required', 'isEmail']}
                                                                            placeholder={translate("Employer.CompanyIndividualRegistrationEmail")}
                                                                            errorMessages={[translate("Fields.Required"), translate("Fields.EmailValidation")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondaryFull}
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <div className={"mt-4"}>
                                                            <NavLink to="/employer/selfchange-password"
                                                                     className={`${classes.linkPassword}`}>
                                                                <Link/>
                                                                <Translate id="Employer.ChangePassword"/>
                                                            </NavLink>
                                                            <Typography variant={"body2"} color={"textSecondary"}
                                                                        style={{fontSize: 15}}>
                                                                <Translate id={"Professional.SaveChangesBefore"}/>
                                                            </Typography>
                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.RegistrationDate")}
                                                                            onChange={handleChange}
                                                                            name="registrationDate"
                                                                            value={formData.registrationDate}
                                                                            placeholder={translate("Employer.RegistrationDate")}
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>

                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12}
                                                                             className={screenSmDown ? "pt-4" : ""}>
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            name="facturationAddress"
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.FacturationAddress")}
                                                                            onChange={handleChange}
                                                                            value={formData.facturationAddress}
                                                                            placeholder={translate("Employer.FacturationAddressHolder")}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            multiline
                                                                            rows={7}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                            validators={['required']}
                                                                            errorMessages={[translate("Fields.Required")]}
                                                                        />
                                                                    </div>

                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={formData.receivePromotionalCommercialNotifications}
                                                                                    onChange={handleChangeChecked}
                                                                                    name="receivePromotionalCommercialNotifications"
                                                                                    color="default"
                                                                                />
                                                                            }
                                                                            label={translate("Employer.ReceivePromotionalCommercialNotifications")}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} className={screenSmDown ? "pt-5 px-3" : "pt-5 pl-5 pr-4"}>
                                        {renderInformation("Employer.VisibleInformation", "Employer.VisibleInformationDescription", true)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Grid container direction="row">
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div>
                                                                        <TextValidator
                                                                            id="enterpriseName"
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.EnterpriseName")}
                                                                            onChange={handleChange}
                                                                            name="enterpriseName"
                                                                            value={formData.enterpriseName}
                                                                            validators={['required']}
                                                                            placeholder={translate("Employer.EnterpriseName")}
                                                                            errorMessages={[translate("Fields.Required")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className={`pt-4`}>
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.EmployerType")}
                                                                            onChange={handleChange}
                                                                            name="employerType"
                                                                            value={formData.employerType}
                                                                            validators={['required']}
                                                                            placeholder={translate("Employer.EmployerType")}
                                                                            errorMessages={[translate("Fields.Required")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager || formData.cIFCompanyIndividualNIF === null || formData.cIFCompanyIndividualNIF === "" ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                            select
                                                                            disabled={!isCompanyManager || formData.cIFCompanyIndividualNIF === null || formData.cIFCompanyIndividualNIF === ""}>
                                                                            {employerTypes.map(employerType => {
                                                                                return <MenuItem
                                                                                    value={employerType.id}>{employerType.name}</MenuItem>
                                                                            })}
                                                                        </TextValidator>
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={renderLabel("Employer.Sector")}
                                                                            onChange={handleChange}
                                                                            name="sector"
                                                                            value={formData.sector}
                                                                            validators={['required']}
                                                                            placeholder={translate("Employer.Sector")}
                                                                            errorMessages={[translate("Fields.Required")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            select
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}>
                                                                            {sectors.map(sector => {
                                                                                return <MenuItem
                                                                                    value={sector.id}>{sector.name}</MenuItem>
                                                                            })}
                                                                        </TextValidator>
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                        <Hidden smDown>
                                                            <Translate>
                                                                {({translate}) => {
                                                                    return <Grid item xs={12} className="pt-4">
                                                                        <div className="position-relative">
                                                                            <TextValidator
                                                                                className={classes.responsiveWidth}
                                                                                label={"Web"}
                                                                                onChange={handleChange}
                                                                                name="web"
                                                                                value={formData.web}
                                                                                validators={['matchRegexp:https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,}']}
                                                                                placeholder={"http://www.web.com"}
                                                                                errorMessages={[translate("Fields.WebValidation")]}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                    classes: {root: classes.rootSecondary}
                                                                                }}
                                                                                disabled={!isCompanyManager}
                                                                                InputProps={{
                                                                                    endAdornment: !isCompanyManager ? (
                                                                                        <InputAdornment
                                                                                            position="end"
                                                                                            className={classes.adornment}>
                                                                                            <IconButton disabled>
                                                                                                <LockIcon/>
                                                                                            </IconButton>
                                                                                        </InputAdornment>) : null
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                }}
                                                            </Translate>
                                                        </Hidden>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextField
                                                                            name="descriptionActivity"
                                                                            className={classes.responsiveWidth}
                                                                            label={translate("Employer.DescriptionActivity")}
                                                                            onChange={handleChange}
                                                                            value={formData.descriptionActivity}
                                                                            placeholder={translate("Employer.DescriptionActivityHolder")}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            multiline
                                                                            rows={7}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Hidden mdUp>
                                                        <Grid item xs={12} md={6} spacing={2}
                                                              className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                            <Translate>
                                                                {({translate}) => {
                                                                    return <Grid item xs={12} className="pt-4">
                                                                        <div className="position-relative">
                                                                            <TextValidator
                                                                                className={classes.responsiveWidth}
                                                                                label={"Web"}
                                                                                onChange={handleChange}
                                                                                name="web"
                                                                                value={formData.web}
                                                                                validators={['matchRegexp:https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,}']}
                                                                                placeholder={"http://www.web.com"}
                                                                                errorMessages={[translate("Fields.WebValidation")]}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                    classes: {root: classes.rootSecondary}
                                                                                }}
                                                                                disabled={!isCompanyManager}
                                                                                InputProps={{
                                                                                    endAdornment: !isCompanyManager ? (
                                                                                        <InputAdornment
                                                                                            position="end"
                                                                                            className={classes.adornment}>
                                                                                            <IconButton disabled>
                                                                                                <LockIcon/>
                                                                                            </IconButton>
                                                                                        </InputAdornment>) : null
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                }}
                                                            </Translate>
                                                        </Grid>
                                                    </Hidden>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={translate("Employer.AwardsCertificatesCollaborations")}
                                                                            onChange={handleChange}
                                                                            name="awardsCertificatesCollaborations"
                                                                            value={formData.awardsCertificatesCollaborations}
                                                                            placeholder={translate("Employer.linkCopy")}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            multiline
                                                                            rows={7}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <TextValidator
                                                                            className={classes.responsiveWidth}
                                                                            label={translate("Employer.TalentManagementPolicies")}
                                                                            onChange={handleChange}
                                                                            name="TalentManagementPolicies"
                                                                            value={formData.TalentManagementPolicies}
                                                                            placeholder={translate("Employer.linkCopy")}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            multiline
                                                                            rows={7}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-5 pr-4"}>
                                                        <Translate>
                                                            {({translate}) => {
                                                                return <Grid item xs={12} className="pt-4">
                                                                    <div className="position-relative">
                                                                        <div className={classes.fileInput}
                                                                             style={screenXsOnly ? {top: 30} : {top: 35}}>
                                                                            <Button variant="outlined"
                                                                                    style={screenXsOnly ? {
                                                                                        borderRadius: 0,
                                                                                        height: 39,
                                                                                        minWidth: 130,
                                                                                        lineHeight: 1,
                                                                                        width: !isCompanyManager ? 164 : 130,
                                                                                        padding: 0
                                                                                    } : {
                                                                                        borderRadius: 0,
                                                                                        height: 32,
                                                                                        minWidth: formData.logo ? 250 : 202
                                                                                    }}>
                                                                                <Grid item xs zeroMinWidth
                                                                                      className="pl-1">
                                                                                    <Typography noWrap component={"p"}
                                                                                                style={screenXsOnly ? {fontSize: 12} : {}}
                                                                                                variant={"body2"}
                                                                                                color={"textSecondary"}
                                                                                                className={`"pl-3 pr-5"`}>
                                                                                        <Translate
                                                                                            id={formData.logo ? "Buttons.SelectNewFile" : "Buttons.SelectFile"}/>
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Button>
                                                                            {!isCompanyManager && screenXsOnly ? "" :
                                                                                <Grid item xs zeroMinWidth
                                                                                      className="pl-1">
                                                                                    <Typography noWrap component={"p"}
                                                                                                style={screenXsOnly ? {fontSize: 12} : {}}
                                                                                                variant={"body2"}
                                                                                                color={"textSecondary"}
                                                                                                className={`"pl-3 pr-5"`}>
                                                                                        {formData.logo ? formData.logo.substr(formData.logo.lastIndexOf("/") + 1) :
                                                                                            <Translate
                                                                                                id={"Employer.NoSelectedFile"}/>}
                                                                                    </Typography>
                                                                                </Grid>}
                                                                        </div>
                                                                        <TextValidator
                                                                            type="file"
                                                                            accept="image/x-png,image/gif,image/jpeg"
                                                                            className={`${classes.responsiveWidth} ${classes.fileInputHidden}`}
                                                                            label={translate("Employer.LogoCorporateImage")}
                                                                            onChange={handleSelectLogo}
                                                                            name="logoCorporateImage"
                                                                            //value={formData.logoCorporateImage}
                                                                            placeholder={translate("Employer.LogoCorporateImage")}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                classes: {root: classes.rootSecondary}
                                                                            }}
                                                                            disabled={!isCompanyManager}
                                                                            InputProps={{
                                                                                endAdornment: !isCompanyManager ? (
                                                                                    <InputAdornment position="end"
                                                                                                    className={classes.adornment}>
                                                                                        <IconButton disabled>
                                                                                            <LockIcon/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>) : null,
                                                                                startAdornment: renderFileAddorment(),

                                                                            }}
                                                                            inputProps={{
                                                                                accept: "image/x-png,image/gif,image/jpeg",
                                                                            }}
                                                                        />
                                                                        {!validateFile ?
                                                                            <FormHelperText error className="pt-2">
                                                                                <Translate
                                                                                    id={"Employer.MaximumSize"}/>
                                                                            </FormHelperText>
                                                                            : ""}
                                                                    </div>
                                                                </Grid>
                                                            }}
                                                        </Translate>

                                                    </Grid>
                                                    <Grid item xs={12} md={6} spacing={2}
                                                          className={screenSmDown ? "px-3" : "pl-4 pr-5"}>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} className={`justify-content-center py-5 ${classes.butonContainer}`}>
                    <Button variant="contained" color="secondary" type="submit" className={`${classes.submit}`}>
                        {saving && <CircularProgress size={30} className={`mr-2 ${classes.colorBlack}`}/>}
                        <Typography variant="inherit" color="textSecondary" style={{fontWeight: "bold"}}><Translate
                            id={"Buttons.Accept"}/></Typography>
                    </Button>


                    <Button variant="outlined" color="secondary" className={`${classes.deleteAccount}`}
                            onClick={() => setConfirmation(true)}>
                        <Typography variant="inherit" color="textSecondary" style={{fontWeight: "bold"}}>
                            <Translate id={"Buttons.DeleteAccount"}/>
                        </Typography>
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <ConfirmDialog id="end-record" open={confirmation}
                                   title={<Translate id={"Employer.DeleteAccountTitle"}/>}
                                   question={<Translate id={"Employer.DeleteAccountMessage"}/>}
                                   question2={<Translate id={"Employer.DeleteProfessionalAccountTitle"}/>}
                                   handleCancel={() => setConfirmation(false)}
                                   handleAccept={deleteAccount}/>
                </Grid>
            </Grid>
        </ValidatorForm>

    );
}
