import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Container, Divider, MenuList, useMediaQuery} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import {Button, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import WordCard from "./workCard";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "40px 24px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },

    },
    titleProfessional: {
        fontSize: 35,
        letterSpacing: 0,
        textAlign: "left",
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginTop: 10,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: "26px",
            marginTop: 0
        },
    },
    titleEmployer: {
        fontSize: 35,
        letterSpacing: 0,
        textAlign: "left",
        fontWeight: 600,
        marginTop: 80,
        color: theme.palette.secondary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: "26px",
            marginTop: 0
        },
    },
    dividerProfessional: {
        width: 239,
        height: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 21

    },
    dividerEmployer: {
        width: 239,
        height: 0,
        border: `1px solid ${theme.palette.secondary.main}`,
        marginTop: 21
    },
    button: {
        minWidth: 220,
        borderRadius: 0,
        marginTop: 50,
        height: 50,
    },
    buttonLabel: {
        fontWeight: "bold",
        fontSize: 18,
        letterSpacing: 0.7
    },
    titleContainer: {
        width: 324,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    }
}));

function HowToWork(props) {
    let classes = useStyles();
    let {professionalData, employerData} = props;
    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (<Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
        <Grid item xs={12}>
            <Grid item md={8} xs={12}>
                <Grid container spacing={2} direction="row" justify={screenSmDown ?  screenXsOnly ? "flex-start" :"center" : "flex-end"}
                      alignItems="center">
                    <Grid item md={7} sm={6} xl={6} xs={9}>
                        <h3 className={`${classes.titleProfessional}`}>{<Translate
                            id={"HomeTitles.HowToWorkProfessional"}/>} </h3>
                        <Divider className={classes.dividerProfessional}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
                {professionalData.map((x, i) => {
                    return <Grid item xs={12} sm={6} md={4}>
                        <WordCard scope="professional" data={x} index={i + 1}/>
                    </Grid>
                })}
            </Grid>

            <Grid item md={8} xs={12}>
                <Grid container spacing={2} direction="row" justify={screenSmDown ? "center" : "flex-end"}
                      alignItems="center">
                    <Grid item md={7} sm={6} xl={6} xs={9}>
                        <NavLink to="/tips/professional">
                            <Button classes={{label: classes.buttonLabel}} className={classes.button}
                                    variant="contained" color="primary" type="submit">
                                <Translate id={"Buttons.MoreTips"}/>
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} className="mt-4">
            <Grid item md={8} xs={12}>
                <Grid container spacing={2} direction="row" justify={screenSmDown ?  screenXsOnly ? "flex-start" :"center" : "flex-end"}
                      alignItems="center">
                    <Grid item md={7} sm={6} xl={6} xs={9}>
                        <h3 className={`${classes.titleEmployer}`}>{<Translate
                            id={"HomeTitles.HowToWorkEmployer"}/>} </h3>
                        <Divider className={classes.dividerEmployer}/>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} className="mt-4">
                {employerData.map((x, i) => {
                    return <Grid item xs={12} sm={6} md={4}>
                        <WordCard scope="employer" data={x} index={i + 1}/>
                    </Grid>
                })}
            </Grid>

            <Grid item md={8} xs={12} className="mb-5">
                <Grid container spacing={2} direction="row" justify={screenSmDown ? "center" : "flex-end"}
                      alignItems="center">
                    <Grid item md={7} sm={6} xl={6} xs={9}>
                        <NavLink to="/tips/employers">
                            <Button classes={{label: classes.buttonLabel}} className={classes.button} style={{color: "#141414"}}
                                    variant="contained" color="secondary" type="submit">
                                <Translate id={"Buttons.MoreTips"}/>
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>);
}

export default withRouter(HowToWork);