import React, {useState, useEffect} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {Grid, Typography, Paper, FormControl, Select, MenuItem} from "@material-ui/core";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import api from "../../api/index";
import Filters from "./filters";
import Pagination from "./pagination";
import OfferCard from "./offerCard";
import {Close, ExpandLess, ExpandMore} from "@material-ui/icons"
import * as types from "../../actions/errors";
import {useDispatch} from "react-redux";
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic'


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 35,
        fontWeight: 600,
        lineHeight: 1,
        [theme.breakpoints.only('xs')]: {
            fontSize: 30
        },
    },
    paper: {
        padding: "25px 40px",
        boxShadow: "0px 0px 25px #0000001A",
        marginTop: 40,
        [theme.breakpoints.only('xs')]: {
            padding: 0,
            marginTop: 0
        },
    },
    select: {
        backgroundColor: "transparent",
        minWidth: 250,
        "&:focus": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.only('xs')]: {
            minWidth: 150
        }
    },
    paperSelect: {
        width: 150
    },
    formControl: {
        width: "max-content",
        "& .MuiInput-root": {
            marginTop: '6px !important'
        }
    },
    sotByLabel: {
        fontWeight: 600
    },
    sortContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    offers: {
        [theme.breakpoints.only('xs')]: {
            padding: 18,
        }
    },
    divWidth: {
        width: "max-content",
        "& .MuiInput-root": {
            marginTop: '6px !important'
        },
        [theme.breakpoints.only('xs')]: {
            width: "100%",
            padding: 15
        }
    }
}));

export default function Jobs(props) {
    let classes = useStyles();
    let history = useHistory();
    let dispatch = useDispatch();

    const specialtyId = props.match.params["specialty"] ? parseInt(props.match.params["specialty"]) : null;
    const provinceId = props.match.params["province"] ? parseInt(props.match.params["province"]) : null;

    let [filters, setFilters] = useState(null);
    let [selectedFilters, setSelectedFilters] = useState({
        pageSize: 20,
        pageNumber: 0,
        orderBy: 1,
        specialtyId: specialtyId ? specialtyId : 0,
        provinceId: provinceId ? provinceId : 0,
        enterpriseId: 0,
        employmentRelationshipId: 0,
        workdayId: 0,
        sortAscending: false,
    })
    //alert(JSON.stringify(selectedFilters))

    let [offerResponse, setOfferResponse] = useState(null);
    let [offers, setOffers] = useState([]);
    let [pagination, setPagination] = useState(null);

    let [openSort, setOpenSort] = useState(false);
    let [allowSearch, setAllowSearch] = useState(false);

    useEffect(() => {
        loadFilters();
        loadOffers();

        if (!provinceId && !specialtyId && !allowSearch) setAllowSearch(true);
    }, [filters, offers, pagination, selectedFilters])


    function loadFilters(reload = false) {
        if (filters === null || reload) {
            api.offers.getFilters(selectedFilters.provinceId, selectedFilters.specialtyId).then(response => {
                if (response.status === 200) {
                    setFilters(response.data);
                }
            })
        }
    }

    function immediatelyLoadFilters(provinceId, specialtyId) {
        api.offers.getFilters(provinceId, specialtyId).then(response => {
            if (response.status === 200) {
                setFilters(response.data);
            }
        })
    }

    function loadOffers(reload = false) {
        if (offerResponse === null || reload) {
            api.offers.getOffersByAlert(selectedFilters).then(response => {
                if (response.status === 200) {
                    let {data} = response;
                    setOfferResponse(data)
                    setOffers(data.offers);
                    setPagination(data.paging);
                }
            })
        }
    }

    function sortOffers(filters) {
        api.offers.getOffersByAlert(filters).then(response => {
            if (response.status === 200) {
                let {data} = response;
                setOfferResponse(data)
                setOffers(data.offers);
                setPagination(data.paging);
            }
        })
    }

    function handleChangeFilter(propertyName, propertyValue) {
        setAllowSearch(true);
        setSelectedFilters(prevFilter => ({...prevFilter, [propertyName]: propertyValue}));

        if (propertyName === "provinceId" || propertyName === "enterpriseId") {
            immediatelyLoadFilters(propertyValue, selectedFilters.specialtyId)
        }
        if (propertyName === "specialtyId") {
            immediatelyLoadFilters(selectedFilters.provinceId, propertyValue)
        }
    }

    function handleChangeSort(event) {
        let {value} = event.target;
        let copyFilters = Object.assign({}, selectedFilters);

        if (copyFilters.orderBy !== value){
            copyFilters = {...copyFilters, orderBy: value ? value : null, sortAscending: false};
        }
        else{
            copyFilters = {...copyFilters, sortAscending: !copyFilters.sortAscending};
        }
        setSelectedFilters(copyFilters);
        sortOffers(copyFilters);
    }

    function handleChangePage(currentPage) {
        let copyFilters = Object.assign({}, selectedFilters);
        copyFilters = {...copyFilters, pageNumber: currentPage - 1};
        setSelectedFilters(copyFilters);
        sortOffers(copyFilters);
    }

    function handleAddAlert() {
        api.alerts.addOrUpdate(selectedFilters).then(response => {
            if (response.status === 200) {
                showMessage(<Translate id="Offers.Professional.AlertOk"/>, "success")
            } else showMessage(<Translate id="Offers.Professional.AlertError"/>, "error")
        });
    }

    function showMessage(error, type) {
        dispatch(types.fetchSetErrorText(error));
        dispatch(types.fetchOpen(true));
        dispatch(types.fetchMessageType(type));
    }

    function handleChangeCleanSort() {
        setSelectedFilters(prevFilter => ({...prevFilter, orderBy: -1, sortAscending: null}));
    }

    function renderLoadingSkeleton(cant = 2) {
        return <Grid container spacing={8} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={4}>
                <Skeleton style={{fontSize: 40}}/>
                <Skeleton style={{fontSize: 18}}/>
                <Skeleton style={{fontSize: 18}}/>
            </Grid>
            <Grid item xs={8}>
                <Skeleton style={{fontSize: 140}}/>
            </Grid>
        </Grid>
    }

    function renderSortBy() {
        return <Translate>
            {({translate}) => {
                return <FormControl className={classes.divWidth}>
                    <Typography className={classes.sotByLabel}>
                        <Translate id={"Offers.OrderedFor"}/>:
                    </Typography>
                    <Select
                        open={openSort}
                        defaultValue={1}
                        onOpen={() => setOpenSort(true)}
                        onClose={() => setOpenSort(false)}
                        labelId="select-label"
                        id="simple-select"
                        displayEmpty={true}
                        classes={{select: classes.select, root: classes.underline}}
                        //onChange={handleChangeSort}
                        IconComponent={() => {
                            return <div className="d-flex px-2" style={{alignItems: "center"}}>
                                {openSort
                                    ? <ExpandLess style={{fontSize: 30}} role="button"
                                                  onClick={() => setOpenSort(false)}/>
                                    : <ExpandMore style={{fontSize: 30}} role="button"
                                                  onClick={() => setOpenSort(true)}/>}
                            </div>
                        }}
                        renderValue={(value) => value === 1 ? translate("Offers.Professional.DateOfPublish")
                            : value === 2 ? translate("Offers.Professional.Province")
                                : value === 3 ? translate("Offers.Professional.Name")
                                    : translate("Offers.Professional.Select")}
                        value={selectedFilters.orderBy}>

                        <MenuItem value={1} onClick={()=> handleChangeSort({target: {value: 1}})}> <Translate
                            id={"Offers.Professional.DateOfPublish"}/></MenuItem>
                        <MenuItem value={2} onClick={()=> handleChangeSort({target: {value: 2}})}>
                            <Translate id={"Offers.Professional.Province"}/></MenuItem>
                        <MenuItem value={3} onClick={()=> handleChangeSort({target: {value: 3}})}> <Translate
                            id={"Offers.Professional.Name"}/></MenuItem>
                    </Select>
                </FormControl>
            }}
        </Translate>
    }


    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/professional/workspace'><Translate
                id={"Professional.ProfessionalZone"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/professional/workspace/some'><Translate
                id={"Offers.Professional.JobsTitle"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    return <Grid container direction="row" justify="center" alignItems="center">
        {renderBreadCrumb()}

        <Grid item xs={11} sm={9} md={7} className="pb-3 pb-md-0">
            <Typography className={classes.title} color="textSecondary">
                <Translate id={"Offers.Professional.JobsTitle"}/>
            </Typography>
        </Grid>

        <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
            <Filters filters={filters ? filters : {}}
                     specialtyId={selectedFilters.specialtyId}
                     provinceId={selectedFilters.provinceId}
                     search={() => {
                         loadOffers(true);
                         setAllowSearch(false);
                     }}
                     handleChangeFilter={(propertyName, propertyValue) => handleChangeFilter(propertyName, propertyValue)}
                     handleAddAlert={handleAddAlert}
                     allowSearch={allowSearch}/>


            <Grid item xs={12} className={classes.offers}>
                <Paper square className={classes.paper}>
                    <Grid item xs={12} className={classes.sortContainer}>
                        {renderSortBy()}
                    </Grid>
                    <Grid container spacing={2}>
                        {offers.map((value, index) => <Grid item xs={12}><OfferCard offer={value}/></Grid>)}
                    </Grid>

                    <Grid item xs={12} className="py-4">
                        {pagination !== null && offers.length ?
                            <Pagination pagination={pagination} handleChangePage={handleChangePage}/> :
                            <div></div>}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    </Grid>
}