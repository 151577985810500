import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Translate} from "react-localize-redux";
import {Button, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import BannerImage from "../../../images/videospeaking/lg.jpg";

const useStyles = makeStyles((theme) => {
    return {
        banner: {
            height: 375,
            width: "100%",
            backgroundImage: `url('${BannerImage}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.only('xs')]: {
                height: 169
            },
            [theme.breakpoints.only('sm')]: {},
            [theme.breakpoints.only('md')]: {},
            [theme.breakpoints.only('lg')]: {},
            [theme.breakpoints.only('xl')]: {}
        },
        info: {
            right: 0,
            zIndex: 2,
            width: "55%",
            position: "absolute",
            height: 375,
            [theme.breakpoints.only('xs')]: {
                position: "inherit",
                height: "auto"
            }
        },
        primaryBox: {
            height: 132,
            paddingLeft: 100,
            alignItems: "center",
            display: "flex",
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.only('xs')]: {
                height: 85,
                paddingLeft: 20,
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: 30
            }
        },
        secondaryBox: {
            height: 183,
            paddingLeft: 100,
            alignItems: "center",
            display: "flex",
            backgroundColor: theme.palette.secondary.main,
            [theme.breakpoints.only('xs')]: {
                height: 95,
                paddingLeft: 20
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: 30
            }
        },
        mainSubtitle: {
            fontSize: 25,
            fontWeight: 400,
            color: theme.palette.common.white,
            letterSpacing: 0,
            marginTop: -7,
            [theme.breakpoints.only('xs')]: {
                fontSize: 20,
                lineHeight: 1.2
            }
        },
        secondarySubtitle: {
            fontSize: 25,
            fontWeight: 400,
            color: theme.palette.primary.main,
            letterSpacing: 0,
            marginTop: -7,
            [theme.breakpoints.only('xs')]: {
                fontSize: 20,
                lineHeight: 1.3
            }
        },
        divMainSubtitle: {
            height: 33,
            [theme.breakpoints.only('xs')]: {
                height: 50,
                maxWidth: 320,
                lineHeight: 1.3
            },
            [theme.breakpoints.only('sm')]: {
                height: 50
            }
        },
        dividerWhite: {
            width: 0,
            height: 53,
            border: "1px solid #FFFFFF",
            margin: 0,
            marginRight: 26,
            [theme.breakpoints.between('md', 'lg')]: {
                marginRight: 18,
            },
            [theme.breakpoints.only('xs')]: {
                height: 50
            }
        },
        dividerBalck: {
            width: 0,
            height: 90,
            border: `1px solid ${theme.palette.primary.main}`,
            margin: 0,
            marginRight: 26,
            [theme.breakpoints.between('md', 'lg')]: {
                marginRight: 18,
            },
            [theme.breakpoints.only('xs')]: {
                height: 50
            }
        },
        divSecondarySubtitle: {
            maxWidth: 650,
            [theme.breakpoints.only('xs')]: {
                maxWidth: 310
            }
        }
    }
});

function BannerInfo(props) {
    const classes = useStyles();
    let {info} = props;
    let points = info ? info.Banner : [];


    return (<Grid container spacing={0} className="position-relative">
        <Grid item xs={12} sm={7} lg={6} xl={6}>
            <div className={classes.banner}></div>
        </Grid>

        <Grid item xs={12} sm={7} lg={7} xl={7} className={classes.info}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center" style={{height: "100%"}}>
                {points && <Grid item xs={12}>
                    <Grid item xs={12} className={classes.primaryBox}>
                        <div className={`${classes.divMainSubtitle} d-flex`}>
                            <hr className={classes.dividerWhite}/>
                            <Typography className={classes.mainSubtitle}>{points[0]}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.secondaryBox}>
                        <div className={`${classes.divSecondarySubtitle} d-flex`}>
                            <hr className={classes.dividerBalck}/>
                            <Typography className={classes.secondarySubtitle}>{points[1]}</Typography>
                        </div>
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    </Grid>);
}

export default withRouter(BannerInfo);