import React, { useEffect } from 'react';
import {withStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import {Slider, Typography, Grid, useMediaQuery} from '@material-ui/core';
import { PauseCircleFilled, RadioButtonCheckedRounded, Lens } from '@material-ui/icons';
import { Translate } from "react-localize-redux";



function valueLabelFormat(value) {
    let minutes = parseInt(value / 60);
    let seconds = value % 60;
    return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
}

export default function TimerIndicator(cProps) {
    let { value, maxValue, maxValueConfig } = cProps;

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                color: '#294264',
                height: 8,
                padding: '13px 0',
            },
            thumb: {
                height: 27,
                width: 27,
                backgroundColor: '#fff',
                border: '0px solid currentColor',
               marginTop: -10,
                marginLeft: -13,
                boxShadow: '#ebebeb 0 2px 2px',
                '&:focus, &:hover, &$active': {
                    boxShadow: '#ccc 0 2px 3px 1px',
                },
                '& .bar': {
                    height: 9,
                    width: 1,
                    backgroundColor: 'currentColor',
                    marginLeft: 1,
                    marginRight: 1,
                },
                [theme.breakpoints.down('sm')]: {
                   height: 20,
                    width: 20,
                    marginTop: -8
                }
            },
            active: {},
            track: {
                height: 6,
            },
            rail: {
                height: 6,
                color: value + 10 >= maxValue ? 'red' : 'rgba(255, 177, 112, 1)',
                opacity: 1,
                [theme.breakpoints.down('sm')]: {
                    height: 4,
                }

            }
        };
    });

    const customStyles = makeStyles((theme) => ({
        segment: {
            width: 7,
            backgroundColor: value + 10 >= maxValue ? 'red' : 'rgba(255, 177, 112, 1)',
            /*marginTop: 25,
            marginBottom: 8,*/
            height: 22,
            [theme.breakpoints.down('sm')]: {
                width: 6,
                height: 20,
                marginTop: -4
            }
        },
        text: {
            minWidth: 60,
           // marginTop: 26,
            marginLeft: 11,
            color: maxValue > maxValueConfig ? theme.palette.error.main : theme.palette.primary.main
        },
        smFont: {
            [theme.breakpoints.down('sm')]: {
                fontSize: "18px !important"
            }
        },
        span: {
            top: -30,
            position: 'absolute',
            color: value + 10 >= maxValue ? 'red' : 'rgba(41, 66, 100, 1)',
            fontSize: 22,
            fontWeight: 'bold',
            letterSpacing: "0.18px",
            [theme.breakpoints.down('sm')]: {
                fontSize:  14 ,
                top:  -18
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: window.innerHeight <= 720 ? 14 : 22,
                top: window.innerHeight <= 720 ? -22 : -30
            }
        },
        xsMarginTop: {
            [theme.breakpoints.only('sm')]: {
                marginTop: "0 !important"
            }
        }
    }));

    const classes = useStyles();
    const customClasses = customStyles();
    const theme = useTheme();
    const screenXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const iphoneHeight = 667;
    function renderIcons() {
        if (cProps.play && cProps.value > 0) {
            return (<RadioButtonCheckedRounded fontSize={screenSmDown ? "small" :  "large" }
                style={{ color: value + 10 >= maxValue ? 'red' : 'rgba(41, 66, 100, 1)' }} />);
        } else {
            if (cProps.value === 0) return (<Lens fontSize={screenSmDown ? "small" :  "large" } />);
            else {
                return (<PauseCircleFilled fontSize={screenSmDown ? "small" :  "large" }
                    style={{ color: value + 10 >= maxValue ? 'red' : 'rgba(41, 66, 100, 1)' }} />);
            }
        }
    }


    function ThumbComponent(props) {
        return (
            <span {...props} id="icons-container">
                {cProps.value > 0 && <span className={customClasses.span} >
                    {valueLabelFormat(value)}
                </span>}
                {renderIcons()}
            </span>
        );
    }

    const { innerWidth: width, innerHeight: height } = window;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Slider classes={classes}
                ThumbComponent={ThumbComponent}
                defaultValue={value}
                value={value}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="on"
                min={0}
                max={maxValue}
            />
            <span className={customClasses.segment}></span>
            <Grid item xs={4} md={2}>
                <Typography variant="h3" className={`${customClasses.text} ${customClasses.smFont}`}>{valueLabelFormat(maxValue)} </Typography>
                {maxValue > maxValueConfig && <Typography variant="h4" color="error" className="text-center ml-2">
                    <Translate id={"Errors.Error"} />
                </Typography>}
            </Grid>
        </div>);
}