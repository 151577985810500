import React, {useState, useEffect} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {
    Grid,
    Typography,
    Paper,
    useMediaQuery,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button
} from "@material-ui/core";
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";
import api from "../../api/index";
import CloseIcon from '@material-ui/icons/Close';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ExpandMore} from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSelector} from "react-redux";
import Hidden from "@material-ui/core/Hidden";

export default function Filters(props) {
    let loggedUser = useSelector(state => state.users.loggedUser);
    let isEmployer = loggedUser ? loggedUser.isEmployer : false;
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: "25px 40px",
            boxShadow: "0px 0px 25px #0000001A",
            marginTop: 40,
            [theme.breakpoints.only('xs')]: {
                padding: 15,
                boxShadow: 'none',
                marginTop: 0
            },
        },
        textField: {
            '& .MuiFormLabel-root': {
                color: theme.palette.text.secondary,
                fontWeight: 600,
                display: "flex",
                letterSpacing: 0.8,
                [theme.breakpoints.down('xs')]: {
                    fontSize: 15
                },
            },
            '& .MuiAutocomplete-inputRoot': {
                paddingRight: "0 !important",
                '& input': {
                    minHeight: 48,
                    padding: "0 16px !important"
                }
            }
        },
        autocompleteLabel: {
            /*"& label[data-shrink=\"false\"]": {
                transform: "translate(0, -2.5px) scale(1);",
                fontWeight: 600
            },
            "& label[data-shrink=\"true\"]": {
                transform: "translate(0, -2.5px) scale(1);",
                fontWeight: 600
            },*/
            '& .MuiInputLabel-formControl': {
                transform: "translate(0, 17px) scale(1)",
                paddingLeft: 24,
                zIndex: 3,
                opacity: 0.3,
                fontWeight: 400,
            },
            '& .MuiInputLabel-shrink': {
                transform: "translate(0, -13.5px) scale(1)",
                marginBottom: 0,
                height: 26,
                width: "auto",
                display: "none"
            },
            '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
                marginTop: "2px !important",
                marginBottom: 0,
                paddingBottom: 0
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
                paddingLeft: 24,
            },
            '& .MuiInput-root.Mui-error input': {
                border: "none"
            },
            '& .MuiAutocomplete-inputRoot.Mui-error': {
                border: "1px solid #FF0000",
                background: "#FDE8E8"
            },
            '& .MuiFormHelperText-root.Mui-error': {
                marginTop: -2
            }
        },
        divWidth: {
            width: "100%",
            '& .MuiInput-root': {
                minWidth: "auto"
            }
        },
        item: {
            fontSize: 16,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            },
        },
        autocomplete: {
            [theme.breakpoints.only('md')]: {
                width: "44.2%"
            },
            [theme.breakpoints.only('lg')]: {
                width: "20.3%"
            },
            [theme.breakpoints.only('xl')]: {
                width: "25.3%"
            },
            width: "100%"
        },
        popupIndicator: {
            marginRight: 10
        },
        labelShrink: {
            transform: "translate(0, 1.5px) scale(1)",
            fontWeight: 600,
            fontSize: 16,
            [theme.breakpoints.only('xs')]: {
                fontSize: 15
            },
        },
        buttonAdd: {
            minWidth: 50,
            maxWidth: 120,
            width: "100%",
            borderRadius: 0,
            height: 48
        },
        buttonAddLabel: {
            fontWeight: 700
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            minHeight: 130,
            [theme.breakpoints.only('xs')]: {
                minHeight: 90,
                justifyContent: "center"
            },
        },
        button: {
            borderRadius: 0,
            height: 48,
            color: isEmployer ? theme.palette.text.secondary : "#FFFFFF",
            fontWeight: "bold"
        },
        buttonLabel: {
            fontWeight: 600
        },
        btnContainer: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            textAlign: "center",
            alignContent: "stretch",
            justifyContent: "center",
            [theme.breakpoints.only('xs')]: {
                minHeight: 150,
                padding: "0 !important"
            },
        },
        rootSecondary: {
            color: theme.palette.text.secondary,
            fontWeight: 600,
            display: "flex",
            letterSpacing: 0.8,
            [theme.breakpoints.down('xs')]: {
                fontSize: 15
            },
            '&.MuiFormLabel-root.Mui-disabled': {
                color: theme.palette.text.secondary,
            }
        },
    }));
    let classes = useStyles();
    let history = useHistory();
    const theme = useTheme();

    const screenSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    let {filters, provinceId, specialtyId, search, handleChangeFilter, allowSearch, handleAddAlert} = props;

    let employmentRelationships = filters.employmentRelationships ? filters.employmentRelationships : [];
    let enterprises = filters.enterprises ? filters.enterprises : [];
    let provinces = filters.provinces ? filters.provinces : [];
    let specialties = filters.specialties ? filters.specialties : [];
    let workdays = filters.workdays ? filters.workdays : [];

    let [selectedSpecialty, setSelectedSpecialty] = useState(null);
    let [selectedProvince, setSelectedProvince] = useState(null);
    let [selectedEnterprise, setSelectedEnterprise] = useState(null);
    let [selectedEmploymentRelationship, setEmploymentRelationship] = useState(null);
    let [selectedWorkday, setSelectedWorkday] = useState(null);


    const defaultPropsSpecialty = {
        options: specialties,
        getOptionLabel: (option) => option.name,
    };

    const defaultPropsEnterprise = {
        options: enterprises,
        getOptionLabel: (option) => option.name,
    };

    useEffect(() => {
        if (specialtyId) setSelectedSpecialty(specialties.find(x => x.id === specialtyId));
        if (provinceId) setSelectedProvince(provinceId);
    }, [specialties, specialtyId, provinceId, selectedProvince, selectedSpecialty])

    function renderLabel(translate, icon = false) {
        return (<div className="d-flex" style={{width: "100%"}}>
            <Typography
                className={`${classes.rootSecondary} ${classes.ellipsis} ${icon ? classes.ellipsisMin : ""}`}>
                <Translate id={translate}/>
            </Typography>
        </div>);
    }

    return filters && <Paper square className={classes.paper}>
        <ValidatorForm>
            <Translate>
                {({translate}) => {
                    return <Grid container spacing={4}>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={screenSmDown ? 2 : 4}>
                                <Grid item xs={12} md={6}>
                                    <div
                                        className={`${classes.divWidth} ${classes.autocompleteLabel}`}>
                                        {renderLabel("Titles.Specialty")}
                                        <Autocomplete
                                            {...defaultPropsSpecialty}
                                            id="auto-speciality"
                                            value={selectedSpecialty}
                                            onChange={(event, newValue) => {
                                                setSelectedSpecialty(newValue);
                                                handleChangeFilter("specialtyId", newValue ? parseInt(newValue.id) : 0)
                                            }}
                                            autoHighlight
                                            closeIcon={<CloseIcon fontSize="small"/>}
                                            renderInput={(params) => <TextField
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: false,
                                                    disableAnimation: true
                                                }}
                                                inputProps={{
                                                    classes,
                                                    disableUnderline: true
                                                }}
                                                {...params}
                                                value={selectedSpecialty}
                                                label={translate("Offers.Professional.Select")}
                                                margin="none"/>}
                                            renderOption={(option, {inputValue}) => {

                                                if (!option) return <div>Nada</div>
                                                const matches = match(option.name, inputValue);
                                                const parts = parse(option.name, matches);
                                                return (
                                                    <div>
                                                        {parts.map((part, index) => (
                                                            <span key={index}
                                                                  style={{fontWeight: part.highlight ? 700 : 400}}>
                                                        {part.text}
                                                    </span>
                                                        ))}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl className={classes.divWidth}>
                                        <InputLabel className={classes.labelShrink} color="textSecondary">
                                            <Translate id={"Offers.Province"}/>
                                        </InputLabel>
                                        <Select
                                            value={selectedProvince}
                                            displayEmpty={true}
                                            onChange={(event) => {
                                                setSelectedProvince(event.target.value)
                                                handleChangeFilter("provinceId", parseInt(event.target.value))
                                            }}
                                            renderValue={(value) => {
                                                let province = provinces.length ? provinces.find(x => x.id === value) : null;
                                                return value
                                                    ? province ? province.name
                                                        : translate("Offers.Professional.Select") : translate("Offers.Professional.Select")
                                            }}
                                            className={classes.divWidth}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null
                                            }}>
                                            <MenuItem value={0}>
                                                <em>{translate("Offers.Professional.None")}</em>
                                            </MenuItem>
                                            {provinces.map(x => <MenuItem className={classes.item}
                                                                          value={x.id}>{x.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} md={6} className="pt-0">
                                    <Grid container spacing={screenSmDown ? 2 : 4}>
                                        <Hidden xsUp>
                                            <Grid item xs={12} md={3} className={classes.buttonContainer}>
                                                <Button variant="contained" className={classes.buttonAdd}
                                                        classes={{label: classes.buttonAddLabel}}>
                                                    {translate("Offers.Professional.MoreFilters")}
                                                </Button>
                                            </Grid>
                                        </Hidden>
                                        <Hidden xsUp>
                                            <Grid item xs={12} md={9}>
                                                <div
                                                    className={`${classes.divWidth} ${classes.autocompleteLabel}`}>
                                                    {renderLabel("Offers.Professional.Enterprise")}
                                                    <Autocomplete
                                                        {...defaultPropsEnterprise}
                                                        id="auto-enterprise"
                                                        value={selectedEnterprise}
                                                        onChange={(event, newValue) => {
                                                            setSelectedEnterprise(newValue)
                                                            handleChangeFilter("enterpriseId", newValue ? parseInt(newValue.id) : 0)
                                                        }}
                                                        autoHighlight
                                                        closeIcon={<CloseIcon fontSize="small"/>}
                                                        renderInput={(params) => <TextValidator
                                                            className={classes.textField}
                                                            InputLabelProps={{
                                                                shrink: false,
                                                                disableAnimation: true
                                                            }}
                                                            inputProps={{
                                                                classes,
                                                                disableUnderline: true
                                                            }}
                                                            {...params}
                                                            value={selectedEnterprise}
                                                            label={translate("Offers.Professional.Select")}
                                                            margin="none"/>}
                                                        renderOption={(option, {inputValue}) => {

                                                            if (!option) return <div></div>
                                                            const matches = match(option.name, inputValue);
                                                            const parts = parse(option.name, matches);
                                                            return (
                                                                <div>
                                                                    {parts.map((part, index) => (
                                                                        <span key={index}
                                                                              style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                    {part.text}
                                                                </span>
                                                                    ))}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>

                                <Hidden xsUp>
                                    <Grid item xs={12} md={6} className="pt-0">
                                        <Grid container spacing={screenSmDown ? 2 : 4}>
                                            <Grid item md={6} xs={12}>
                                                <FormControl className={classes.divWidth}>
                                                    <InputLabel className={classes.labelShrink} color="textSecondary">
                                                        <Translate id={"Offers.EmploymentRelationship"}/>
                                                    </InputLabel>
                                                    <Select
                                                        value={selectedEmploymentRelationship}
                                                        displayEmpty={true}
                                                        renderValue={(value) => value
                                                            ? employmentRelationships.find(x => x.id === value).name
                                                            : translate("Offers.Professional.Select")}
                                                        onChange={(event) => {
                                                            setEmploymentRelationship(event.target.value)
                                                            handleChangeFilter("employmentRelationshipId", event.target.value)
                                                        }}
                                                        className={classes.divWidth}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>

                                                        <MenuItem
                                                            value={0}><em>{translate("Offers.Professional.None")}</em></MenuItem>
                                                        {employmentRelationships.map(x =>
                                                            <MenuItem className={classes.item}
                                                                      value={x.id}>{x.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <FormControl className={classes.divWidth}>
                                                    <InputLabel className={classes.labelShrink} color="textSecondary">
                                                        <Translate id={"Offers.Workday"}/>
                                                    </InputLabel>
                                                    <Select
                                                        value={selectedWorkday}
                                                        onChange={(event) => {
                                                            setSelectedWorkday(event.target.value)
                                                            handleChangeFilter("workdayId", event.target.value)
                                                        }}
                                                        displayEmpty={true}
                                                        renderValue={(value) => value
                                                            ? workdays.find(x => x.id === value).name
                                                            : translate("Offers.Professional.Select")}
                                                        className={classes.divWidth}
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            },
                                                            getContentAnchorEl: null
                                                        }}>
                                                        <MenuItem
                                                            value={0}><em>{translate("Offers.Professional.None")}</em></MenuItem>
                                                        {workdays.map(x => <MenuItem className={classes.item}
                                                                                     value={x.id}>{x.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} className={`${classes.btnContainer}`}>
                            <Button variant="contained" color={isEmployer ? "secondary" : "primary"}
                                    className={classes.button}
                                    disabled={!allowSearch || (selectedSpecialty === null && (selectedProvince === null || selectedProvince === 0))}
                                    onClick={() => {
                                        //if (search) search()
                                        history.push(`/jobs/${selectedSpecialty ? selectedSpecialty.id : 0}/${selectedProvince ? selectedProvince : 0}`)
                                    }}
                                    classes={{label: classes.buttonLabel}}>
                                {translate("Offers.Professional.Search")}
                            </Button>
                            { loggedUser ? <Button variant="contained" color={isEmployer ? "secondary" : "primary"}
                                    className={`${classes.button} mt-4`}
                                    onClick={handleAddAlert} disabled={isEmployer || loggedUser === null || allowSearch}
                                    classes={{label: classes.buttonLabel}}>
                                {translate("Offers.Professional.AddMyAlerts")}
                            </Button> : ""}
                        </Grid>
                    </Grid>
                }}
            </Translate>
        </ValidatorForm>
    </Paper>
}