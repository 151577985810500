import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { NavLink, Link } from "react-router-dom";
import ImageVideoEntrevista from "../../../images/professional/group-522.png";
import Play from "../../../images/professional/play.png";
import * as constants from "../../../utils/constants";



export default function Upload(props) {
    let { videoUrl, responsive, application } = props;

    const useStyles = makeStyles((theme) => ({
        image: {
            width: "100%",
            height: "auto",
            maxHeight: 376,
            maxWidth: 376,
            minHeight: 376,
            [theme.breakpoints.only('xs')]: {
                maxHeight: 324,
                maxWidth: 324,
                minHeight: 324,
            }
        },
        player: {
            "transform": "rotateY(180deg)",
            "-webkit-transform": "rotateY(180deg)",
            "-moz-transform": "rotateY(180deg)",
            maxHeight: 376,
            minHeight: 376,
            maxWidth: 376,
            width: "100%",
            height: "auto",
            borderRadius: 50,
            objectFit: "cover",
            [theme.breakpoints.only('xs')]: {
                maxHeight: 324,
                maxWidth: 324,
                minHeight: 324,
            }
        },
        previewContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            position: "relative",
            alignItems: "center"
        },
        play: {
            position: "absolute",
            cursor: "pointer",
            [theme.breakpoints.only('xs')]: {
                maxWidth: 80,
                maxHeight: 80
            }
        }
    }));

    let classes = useStyles();

    useEffect(() => {
    }, [videoUrl])

    function getVideoUrl() {
        return videoUrl && videoUrl.indexOf(constants.digitalocean) > -1 ? `${videoUrl}#t=0.001` : videoUrl;
    }

    return (<Grid item xs={12} className={classes.previewContainer}>
        {!videoUrl
            ? <img className={classes.image} src={ImageVideoEntrevista} />
            : <video src={getVideoUrl()} className={classes.player} id="replay-interview" playsInline={true}>
                <source src={getVideoUrl()} type="video/mp4" />
            </video>}

        <NavLink to={`/employer/seen-interview/${application.professional.id}/${application.id}`} className={classes.play}>
            <img src={Play} role="button" />
        </NavLink>
    </Grid>);
}