import api from "../../api";

export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const SET_INTERVIEW_METADATA = "SET_INTERVIEW_METADATA";
export const SET_INTERVIEW_BLOB = "SET_INTERVIEW_BLOB";
export const RESET_INTERVIEW = "RESET_INTERVIEW";
export const SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE";
export const LOGOUT = "LOGOUT";
export const SET_INTERVIEW_SEGMENT_BLOB = "SET_INTERVIEW_SEGMENT_BLOB";
export const SET_MERGE_BLOB = "SET_MERGE_BLOB";
export const SET_SELECTED_INTERVIEW = "SET_SELECTED_INTERVIEW";
export const SAVE_THUMBNAIL_URL = "SAVE_THUMBNAIL_URL";

export const SET_SPEAKING_METADATA = "SET_SPEAKING_METADATA";
export const SET_SPEAKING_ATTEMPTS = "SET_SPEAKING_ATTEMPTS";
export const RESET_SPEAKING = "RESET_SPEAKING";
export const SET_CURRENT_OFFERID = "SET_CURRENT_OFFERID";

function fetchLoginUser(user) {
  return {
    type: SET_USER_LOGIN,
    payload: user,
  };
}

export function setMetadata(interviewMetadata) {
  return {
    type: SET_INTERVIEW_METADATA,
    payload: interviewMetadata,
  };
}

export function setSpeakingMetadata(metadata) {
  return {
    type: SET_SPEAKING_METADATA,
    payload: metadata,
  };
}

export function resetSpeaking() {
  return {
    type: RESET_SPEAKING,
    payload: null,
  };
}

export function setSpeakingAttempts(attempts) {
  return {
    type: SET_SPEAKING_ATTEMPTS,
    payload: attempts,
  };
}

export function setBlob(interviewBlob) {
  return {
    type: SET_INTERVIEW_BLOB,
    payload: interviewBlob,
  };
}

export function saveThumbnailUrl(thumbnailUrl) {
  return {
    type: SAVE_THUMBNAIL_URL,
    payload: thumbnailUrl,
  };
}

export function setMergeBlob(mergedBlob) {
  return {
    type: SET_MERGE_BLOB,
    payload: mergedBlob,
  };
}

export function setSegmentBlob(segmentBlob) {
  return {
    type: SET_INTERVIEW_SEGMENT_BLOB,
    payload: segmentBlob,
  };
}

export function resetInterview() {
  return {
    type: RESET_INTERVIEW,
    payload: {},
  };
}

export function setDefaultLanguage(language) {
  return {
    type: SET_DEFAULT_LANGUAGE,
    payload: language,
  };
}

export const fetchUserLogin =
  (dispatch) =>
  async (user, type = null, register = true, formData = {}) => {
    try {
      let response =
        type === "code"
          ? await api.users.sigInLinkedInUser(user, register, formData)
          : await api.users.sigInUser(user);

      if (response.status !== 200) {
        console.log(response);
      } else {
        let userLogged = response.data;
        await localStorage.setItem("token", userLogged.token);
        dispatch(fetchLoginUser(userLogged.user));
        await api.language.setLanguage();
      }
      return response;
    } catch (error) {
      return "error";
    }
  };

export async function login(user) {
  try {
    let response = await api.users.sigInUser(user);
    if (response.status !== 200) {
      console.log(response);
    } else {
      let userLogged = response.data;
      localStorage.setItem("token", userLogged.token);
    }
    return response;
  } catch (error) {
    return "error";
  }
}

export function logoutUser() {
  return {
    type: LOGOUT,
    payload: null,
  };
}

export function setSelecetdInerview() {
  return {
    type: SET_SELECTED_INTERVIEW,
    payload: window.location.pathname,
  };
}

export function setCurrentOfferid(offerId) {
  console.info("#setCurrentOfferid#", offerId);
  return {
    type: SET_CURRENT_OFFERID,
    payload: offerId,
  };
}
