import React, { useState, useEffect } from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import {Typography, Container, useMediaQuery} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import api from "../../../api/index";
import { staticContent } from "../../../utils/constants";
import BenefitCard from "./benefitCard";

const useStyles = makeStyles((theme) => ({
    titleProfessional: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.primary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left"
        },
        marginBottom: 0
    },
    titleEmployer: {
        fontSize: 35,
        textAlign: "center",
        fontWeight: 600,
        color: theme.palette.secondary.main,
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
            fontSize: 26,
            textAlign: "left"
        },
        marginBottom: 0
    },
    containerBox: {
        paddingBottom: 100
    },
    boxContent: {

    },
    container: {
        padding: "40px 24px",
        [theme.breakpoints.between('md', 'lg')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: 48,
            paddingRight: 48
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: 0,
            paddingBottom: 0
        },

    },
}));

function Benefits(props) {
    let classes = useStyles();
    const scope = props.match.params["scope"] ? props.match.params["scope"] : "";
    let [professionalData, setProfessionalData] = useState([]);
    let [employerData, setEmployerData] = useState([]);

    useEffect(() => {
        api.home.getContent(staticContent.Benefits).then(response => {
            setProfessionalData(response[staticContent.Professionals]);
            setEmployerData(response[staticContent.Employers]);
        });
    }, [professionalData, employerData])

    const theme = useTheme();
    const screenXlOnly = useMediaQuery(theme.breakpoints.only('xl'));

    function renderProfessionalsBenefits() {
        return (<Grid item xs={12}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <h1 className={`${classes.titleProfessional} my-4`}>{<Translate id={"HomeTitles.ProfessionalBenefits"} />} </h1>
                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center" alignItems="center">
                    {professionalData.map((data, index) => {
                        return (<Grid className={classes.boxContent} item xs={12} sm={6} md={4}>
                            <BenefitCard data={data} index={index + 1} scope={scope} />
                        </Grid>)
                    })}
                </Grid>
            </Container >
        </Grid >)
    }

    function renderEmployersBenefits() {
        return (<Grid item xs={12}>
            <Container className={classes.container} maxWidth={screenXlOnly ? "lg" : "md"}>
                <h1 className={`${classes.titleEmployer} my-4`}>{<Translate id={"HomeTitles.EmployerBenefits"} />} </h1>
                <Grid className={classes.containerBox} container spacing={2} direction="row" justify="center" alignItems="center">
                    {employerData.map((data, index) => {
                        return (<Grid className={classes.boxContent} item xs={12} sm={6} md={4}>
                            <BenefitCard data={data} index={index + 1} scope={scope} />
                        </Grid>)
                    })}
                </Grid>
            </Container>
        </Grid>)
    }


    return (<Grid container spacing={0}>
        {scope === "employers"
            ? renderEmployersBenefits()
            : renderProfessionalsBenefits()}
    </Grid>);
}

export default withRouter(Benefits);