import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import imageProfessional from "../../../images/layuout/banner-home.jpg";
import imageEmployer from "../../../images/layuout/banner-home3.jpg";
import imageProfessionalPng from "../../../images/layuout/banner-home.png";
import imageEmployerPng from "../../../images/layuout/banner-home3.png";
import responsiveProfessional from "../../../images/layuout/home3.png";
import responsiveEmployer from "../../../images/layuout/home4.png";
import MinimizeIcon from '@material-ui/icons/Remove';
import Icon from "@material-ui/core/Icon";
import {useMediaQuery} from "@material-ui/core";
import {Translate} from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SearchAuto from './searchAuto';
import {isChrome, isMacOs} from 'react-device-detect';
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {useSelector} from "react-redux";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const imagesHome = [{"img": 0}, {"img": 1}];

const useStyles = makeStyles((theme) => ({
    imgProfessional: {
        backgroundImage: `url('${responsiveProfessional}')`,
        height: 582,
        backgroundSize: "cover",
        backgroundPosition: "center",
       /* backgroundPositionY: "top",
        backgroundPositionX: "center",*/
        [theme.breakpoints.only('xs')]: {
            height: 192,
        },
        [theme.breakpoints.only('sm')]: {
            "@media (min-height: 768px)": {
                height: 574,
            },
            backgroundImage: `url('${imageProfessional}')`,
            zIndex: 1,
        },
        [theme.breakpoints.only('md')]: {
            height: 568,
            "@media (max-height: 767px)": {
                height: 404,
            },
            backgroundImage: `url('${imageProfessional}')`,
            zIndex: 1,
        },
        [theme.breakpoints.only('lg')]: {
            height: 450,
            backgroundImage: `url('${imageProfessional}')`,
            zIndex: 1,
        },
        [theme.breakpoints.only('xl')]: {
            backgroundImage: `url('${imageProfessional}')`,
            zIndex: 1,
        },

    },
    imgEmployer: {
        backgroundImage: `url('${responsiveEmployer}')`,
        height: 582,
        backgroundSize: "cover",
        backgroundPosition: "center",
        /*backgroundPositionY: "bottom",
        backgroundPositionX: "center",*/
        [theme.breakpoints.only('xs')]: {
            height: 192,
        },
        [theme.breakpoints.only('sm')]: {
            "@media (min-height: 768px)": {
                height: 574,
            },
            backgroundImage: `url('${imageEmployer}')`,
        },
        [theme.breakpoints.only('md')]: {
            height: 568,
            "@media (max-height: 767px)": {
                height: 404,
            },
            backgroundImage: `url('${imageEmployer}')`,
            zIndex: 1,
        },
        [theme.breakpoints.only('lg')]: {
            height: 450,
            backgroundImage: `url('${imageEmployer}')`,
            zIndex: 1,
        },
        [theme.breakpoints.only('xl')]: {
            backgroundImage: `url('${imageEmployer}')`,
            zIndex: 1,
        },
    },
    icon: {
        fontSize: 40,
        paddingTop: 22,
        paddingBottom: 22,
    },
    iconLine: {
        height: 6, width: 28
    },
    divLine: {
        bottom: 0,
        right: 107,
        position: "absolute",
        color: "#FFFFFF",
        [theme.breakpoints.only('md')]: {
            bottom: 4,
            right: 159,
        },
        [theme.breakpoints.only('lg')]: {
            bottom: 22,
            right: 105,
        },
        [theme.breakpoints.only('xl')]: {
            bottom: 22,
            right: 139,
        },
    },
    divEmployer: {
        minHeight: 234,
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 205,
        },
        [theme.breakpoints.only('sm')]: {
            "@media (max-height: 767px)": {
                minHeight: 186,
            },
            "@media (min-height: 768px)": {
                minHeight: 181,
                bottom: 15,
            },
            position: "absolute",
            bottom: 45,
            right: 0,
            width: "56%",
            zIndex: 3
        },
        [theme.breakpoints.only('md')]: {
            minHeight: 234,
            "@media (max-height: 767px)": {
                minHeight: 165,
            },
            "@media (min-height: 768px)": {
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            },
            position: "absolute",
            bottom: 25,
            right: 0,
            width: "56%",
            zIndex: 3
        },
        [theme.breakpoints.only('lg')]: {
            minHeight: 182,
            position: "absolute",
            bottom: 25,
            right: 0,
            width: "56%",
            zIndex: 3
        },
        [theme.breakpoints.only('xl')]: {
            position: "absolute",
            bottom: 45,
            right: 0,
            width: "56%",
            zIndex: 3,
            display: "flex",
            alignItems: "center"
        },


    },
    divProfessional: {
        minHeight: 259,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
        },
        [theme.breakpoints.only('xs')]: {
            minHeight: 226,
        },
        [theme.breakpoints.only('sm')]: {
            "@media (max-height: 767px)": {
                minHeight: 306,
            },
            "@media (min-height: 768px)": {
                minHeight: 363,
                top: 15,
            },
            position: "absolute",
            top: 45,
            right: 0,
            width: "56%",
            zIndex: 2,
        },
        [theme.breakpoints.only('md')]: {
            minHeight: 284,
            "@media (max-height: 767px)": {
                minHeight: 189,
            },
            "@media (min-height: 768px)": {
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            },
            position: "absolute",
            top: 25,
            right: 0,
            width: "56%",
            zIndex: 2,
        },
        [theme.breakpoints.only('lg')]: {
            minHeight: 222,
            position: "absolute",
            top: 25,
            right: 0,
            width: "56%",
            zIndex: 2,
        },
        [theme.breakpoints.only('xl')]: {
            position: "absolute",
            top: 45,
            right: 0,
            width: "56%",
            zIndex: 2,
            display: "flex",
            alignItems: "center"
        },

    },
    dividerTopWhite: {
        width: 0,
        height: 94,
        border: "1px solid #FFFFFF",
        marginRight: 30,
        [theme.breakpoints.between('md', 'lg')]: {
            marginRight: 18,
        }
    },
    dividerTopBlack: {
        width: 0,
        height: 94,
        border: "1px solid #141414",
        marginRight: 30,
        [theme.breakpoints.only('xs')]: {
            height: 64,
        }
        ,
        [theme.breakpoints.between('md', 'lg')]: {
            marginRight: 20,
        }


    },
    dividerBottomWhite: {
        width: 69,
        height: 0,
        border: "1px solid #FFFFFF",
        position: "absolute",
        right: 0,
        marginTop: 12,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    dividerBottomBlack: {
        width: 69,
        height: 0,
        border: "1px solid #141414",
        position: "absolute",
        right: 0,
        marginTop: 12,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    titleProfessional: {
        font: "normal normal bold 30px/69px Barlow",
        letterSpacing: 1.17,
        [theme.breakpoints.down('md')]: {
            fontSize: 23,
            letterSpacing: 0.66,
            fontWeight: "bold"
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: 26,
        },
        marginBottom: 0
    },
    searchProfessional: {
        font: "italic normal bold 20px/33px Barlow",
        letterSpacing: 0.78,
        marginRight: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            letterSpacing: 0.66,
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: 16,
        },
    },
    onlineProfessional: {
        font: " normal normal normal 25px/30px Barlow",
        letterSpacing: 0.97,
        maxWidth: 250,
        marginTop: 30,
        [theme.breakpoints.only('xl')]: {
            /*fontSize: 24,
            letterSpacing: 1.13,*/
            marginTop: 22,
            width: 421,
            maxWidth: 421,
            lineHeight: 1.3
        },
        [theme.breakpoints.only('lg')]: {
            width: 328,
            maxWidth: 328,
            fontSize: 18,
            marginTop: 20,
            lineHeight: 1.5
        },
        [theme.breakpoints.only('md')]: {
            width: 273,
            maxWidth: 273,
            fontSize: 16,
            marginTop: 0,
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: 0,
            fontSize: 14,
            letterSpacing: 0.55,
        },

    },

    colorBlack: {
        color: "#141414"
    },
    colorWhite: {
        color: "#FFFFFF"
    },
    divMax: {
        position: "relative",
        width: "max-content",
        [theme.breakpoints.down('md')]: {
            order: 1,
            marginTop: 0,
            fontWeight: "bold"
        }
    },
    divXs: {
        alignItems: "center",
        alignSelf: "center",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            height: "100%",
        }
    },
    dividerTopXs: {
        position: "absolute",
        top: "14%",
        left: window.innerWidth > 385 ? "9%" : "5%",
        [theme.breakpoints.only('xl')]: {
            top: "20%",
            left: "6%"
        },
        [theme.breakpoints.only('md')]: {
            top: "14%",
            left: window.innerWidth >= 1124 ? "6%" : "3%",
            "@media (min-height: 768px)": {
                top: "31%",
            },
        },
        [theme.breakpoints.only('sm')]: {
            top: "5%",
            left: window.innerWidth >= 650 ? "9%" : "6%"
        },
        [theme.breakpoints.only('lg')]: {
            top: "17%",
            left: "5%"
        },
    },
    dividerTopXsBlack: {
        position: "absolute",
        top: "14%",
        left: window.innerWidth > 385 ? "9%" : "5%",
        [theme.breakpoints.only('xl')]: {
            top: "20%",
            left: "6%"
        },
        [theme.breakpoints.only('md')]: {
            top: "14%",
            left: window.innerWidth >= 1124 ? "6%" : "3%",
            "@media (min-height: 768px)": {
                top: "31%",
            },
        },
        [theme.breakpoints.only('sm')]: {
            top: "5%",
            left: window.innerWidth >= 650 ? "9%" : "6%"
        },
        [theme.breakpoints.only('lg')]: {
            top: "17%",
            left: "5%"
        },
    },
    processLink: {
        '&.MuiIconButton-root.Mui-disabled': {
            color: "#141414"
        },
        '&.MuiIconButton-root:hover': {
            color: "#141414"
        }
    }

}));

export default function CarouselHome() {
    const classes = useStyles();
    /** true empleador false profecional **/
    const [activeImage, setActiveImage] = useState(true);
    const [activeSearch, setActiveSearch] = useState(false);
    const [activeStep, setActiveStep] = React.useState(1);
    const maxSteps = imagesHome.length;
    let loggedUser = useSelector(state => state.users.loggedUser);
    let isEmployer = loggedUser ? loggedUser.isEmployer : false;
    let isProfessional = loggedUser ? loggedUser.isProfessional : false;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    function handleChangeImage(value) {
        setActiveImage(value)
    }

    function renderIcons() {
        let primary = activeStep === 0 ? "#193C47" : "#FFFFFF";
        let secondary = activeStep === 1 ? "#FFB170" : "#FFFFFF"
        return <div className={classes.divLine}>
            <IconButton color={activeStep === 1 ? "secondary" : "inherit"} className={classes.icon} disabled={activeStep === 0} onClick={() => {
                handleStepChange(0)
            }}>
                <MinimizeIcon style={{height: 6, width: 28, backgroundColor: secondary, color: secondary}}/>
            </IconButton>
            <IconButton color={activeStep === 0 ? "primary" : "inherit"} className={classes.icon} disabled={activeStep === 1} onClick={() => {
                handleStepChange(1)
            }}>
                <MinimizeIcon style={{height: 6, width: 28, backgroundColor: primary, color: primary}}/>
            </IconButton>
        </div>
    }

    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const screenMdOnly = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmDow = useMediaQuery(theme.breakpoints.down('sm'));
    const screenLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Grid container spacing={0} className="position-relative">
            {
                screenXsOnly ?
                    <Grid item xs={12} sm={7} className={`${classes.imgProfessional} position-relative`}>
                        {!screenXsOnly && renderIcons()}
                    </Grid> : ""
            }
            <Grid item xs={12} sm={7} lg={6} xl={6}>
                {!screenXsOnly ? <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                       >
                        {imagesHome.map((step, index) => {
                            if (activeStep === index)
                                return <div
                                    className={`${step.img === 1 ? classes.imgEmployer : classes.imgProfessional} position-relative`}>
                                    {
                                        renderIcons()
                                    }
                                </div>
                        })}
                    </AutoPlaySwipeableViews> :
                    ""}
            </Grid>

            <Grid item xs={12} sm={7} lg={7} xl={7} className={`${classes.divProfessional} ${classes.colorWhite}`}>
                <Grid container spacing={0} direction="row"
                      justify="center"
                      style={{height: "100%"}}>
                    <Grid item xs={12} md={7} lg={7} xl={7}
                          className={`${screenSmDow && classes.divXs} d-flex justify-content-center align-items-center`}>
                        <div className={classes.dividerTopXs}>
                            <Divider className={classes.dividerTopWhite}/>
                        </div>
                        <div style={{display: "grid"}}>
                            <h3 variant="inherit" className={classes.titleProfessional}>
                                <Translate id="Titles.ImProfessional"/>
                            </h3>
                            <div className={classes.divMax}>
                                <Typography variant="inherit" className={classes.searchProfessional}>
                                    <Translate id="Titles.SearchSelectionProcesses"/>
                                </Typography>
                                <IconButton
                                    className={classes.colorWhite}
                                    onClick={() => {
                                        setActiveSearch(!activeSearch)
                                    }}>
                                    {activeSearch ? <ExpandLessIcon style={screenXsOnly ? {} : {fontSize: 32}}/> :
                                        <ExpandMoreIcon style={screenXsOnly ? {} : {fontSize: 32}}/>}
                                </IconButton>
                                <Divider className={classes.dividerBottomWhite}/>
                            </div>
                            <Typography variant="inherit" className={classes.onlineProfessional}>
                                <Translate id="Titles.ParticipateOnline"/>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} xl={5}>
                        {activeSearch ? <SearchAuto/> : ""}
                    </Grid>
                </Grid>
            </Grid>
            {
                screenXsOnly ?
                    <Grid item xs={12} sm={7} className={`${classes.imgEmployer} position-relative`}>
                        {!screenXsOnly && renderIcons()}
                    </Grid> : ""
            }
            <Grid item xs={12} sm={7} lg={7} xl={7} className={`${classes.divEmployer} ${classes.colorBlack}`}>
                <Grid container spacing={0} direction="row"
                      justify="center"
                      style={{height: "100%"}}>
                    <Grid item xs={12} md={7} lg={7} xl={7}
                          className={`${screenSmDow && classes.divXs} d-flex justify-content-center align-items-center`}>
                        <div className={classes.dividerTopXsBlack}>
                            <Divider className={classes.dividerTopBlack}/>
                        </div>
                        <div style={{display: "grid"}}>
                            <h3 variant="inherit" className={classes.titleProfessional}>
                                <Translate id="Titles.IamEmployer"/>
                            </h3>
                            <div className={classes.divMax}>
                                <Typography variant="inherit" className={classes.searchProfessional}>
                                    <Translate id="Titles.PublishSelectionProcesses"/>
                                </Typography>
                                <IconButton className={`${classes.colorBlack} ${classes.processLink}`} disabled={isProfessional} href={isEmployer ? "/employer/process/create" : loggedUser === null ? "/employer/login" : ""}>
                                    <ArrowForwardIcon/>
                                </IconButton>
                                <Divider className={classes.dividerBottomBlack}/>
                            </div>
                            <Typography variant="inherit"
                                        className={classes.onlineProfessional}>
                                <Translate id="Titles.SelectStaffOnline"/>
                            </Typography>
                        </div>
                    </Grid>
                    {!screenXsOnly &&  <Grid item xs={12} md={5} lg={5} xl={5}>
                    </Grid>}

                </Grid>
            </Grid>
        </Grid>

    );
}