import React, {useEffect, useState, createRef, useRef} from 'react';
import {withRouter} from "react-router-dom";
import api from "../../api/index";
import {Translate} from "react-localize-redux";
import {Button, Grid, Hidden, Paper, Tooltip, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import SuccessImage from "../../images/payment/success-payment.png";
import FailedImage from "../../images/payment/failed-payment.png";
import {useHistory} from 'react-router-dom';
import {PaymentType} from "../../utils/constants";

const useStyles = makeStyles((theme) => {
    theme.overrides.MuiInputLabel = {
        ...theme.overrides.MuiInputLabel,
        root: {
            ...theme.overrides.MuiInputLabel.root,
            fontWeight: "bold"
        }
    }
    return {
        paddingXs: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "1rem",
                paddingLeft: "1rem"
            }
        },
        title: {
            fontSize: 35,
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: 0,
            marginTop: 18,
            marginBottom: 20,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
            }
        },
        subTitle: {
            fontSize: 25,
            fontWeight: 600,
            lineHeight: "normal",
            marginBottom: 40,
            [theme.breakpoints.only('xs')]: {
                fontSize: 18,
            }
        },
        paper: {
            padding: "28px 60px",
            boxShadow: "0px 0px 25px #0000001A",
            paddingBottom: 47,
            [theme.breakpoints.only('xs')]: {
                boxShadow: "none",
                padding: 0,
                marginTop: 0
            },
        },
        status: {
            width: "max-content",
            display: "contents",
            justifyContent: "center"
        },
        container: {
            minHeight: 460
        },
        buttonLabel: {
            color: "#141414",
            fontWeight: 700,
            fontSize: 18
        },
        button: {
            ...theme.overrides.MuiButton.root,
            borderRadius: 0,
            border: `2px solid ${theme.palette.secondary.main}`,
            "&:hover": {
                border: `2px solid ${theme.palette.secondary.main}`,
            },
            [theme.breakpoints.only('xs')]: {
                minWidth: 220
            }
        },
        margin: {
            marginLeft: 16,
            marginRight: 16,
            [theme.breakpoints.only('xs')]: {
                margin: 0
            }
        },
    }
});

function PaymentNotify(props) {
    let classes = useStyles();
    const theme = useTheme();
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
    const status = props.match.params["status"] ? props.match.params["status"] : null;
    const paymentType = props.match.params["paymentType"] ? props.match.params["paymentType"] : null;
    let history = useHistory();

    let [plan, setPlan] = useState(null);

    useEffect(() => {
        loadParams();
        decodePayment();
    }, [plan])

    function decodePayment() {
        if (plan === null && window.location.search) {
            api.payments.decodePayment(window.location.search)
                .then(response => {
                    if (response.status === 200) {
                        setPlan(response.data);
                    }else{
                        setPlan({});
                    }
                })
        }
    }

    function loadParams() {
        let url = new URL(window.location.href);
        let signatureVersion = url.searchParams.get("Ds_SignatureVersion");
        let merchantParameters = url.searchParams.get("Ds_MerchantParameters");
        let signature = url.searchParams.get("Ds_Signature");

        return {signature, signatureVersion, merchantParameters};
    }


    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace'><Translate id={"Employer.EmployerArea"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace/segment'><Translate id={"Payments.Invoice"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    function complementaryMessage() {
        if (paymentType) {
            if (status === "success") {
                return paymentType === PaymentType.CreateProcess
                    ? "Payments.SuccessPublish"
                    : paymentType === PaymentType.ReactivateProcess
                        ? "Payments.SuccessReactivation"
                        : paymentType === PaymentType.VideoSpeakingTest
                            ? "Payments.SuccessTest"
                            : paymentType === PaymentType.AddCredit
                                ? "Payments.SuccessAddCredit"
                                : null;
            } else {
                return paymentType === PaymentType.CreateProcess
                    ? "Payments.FailedPublish"
                    : paymentType === PaymentType.ReactivateProcess
                        ? "Payments.FailedReactivation"
                        : paymentType === PaymentType.VideoSpeakingTest
                            ? "Payments.FailedTest"
                            : paymentType === PaymentType.AddCredit
                                ? "Payments.FailedAddCredit"
                            : null;
            }
        }
    }

    function renderContent() {
        return <div className={classes.status}>
            <img src={status === "success" ? SuccessImage : FailedImage}/>
            <Typography color={status === "success" ? "textSecondary" : "error"} className={classes.title}>
                <Translate id={status === "success" ? 'Payments.Success' : 'Payments.Failed'}/>
            </Typography>

            {complementaryMessage() && <Typography color={status === "success" ? "textSecondary" : "error"}
                                                   className={classes.subTitle}>
                <Translate id={complementaryMessage()}/>
            </Typography>}
        </div>
    }

    return (<Translate>
        {({translate}) => {
            return <Grid container spacing={0}>
                {renderBreadCrumb()}
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item item xs={11} sm={9} md={11} className="pb-4">
                        <Paper elevation={2} className={classes.paper}>
                            <Grid className={classes.container} container direction="column" justify="center"
                                  alignItems="center">
                                {renderContent()}
                                <Button variant="outlined" color={"secondary"} className={classes.margin}
                                        classes={{root: classes.button, label: classes.buttonLabel}}
                                        onClick={() => {
                                            if (paymentType === null) history.push("/employer/workspace")
                                            else {
                                                if (paymentType === PaymentType.ReactivateProcess)
                                                    history.push("/employer/workspace/3")
                                                if (paymentType === PaymentType.VideoSpeakingTest)
                                                    history.push("/employer/workspace/2")
                                                if (paymentType === PaymentType.AddCredit)
                                                    history.push("/employer/workspace/0")
                                                else history.push("/employer/workspace")
                                            }
                                        }}>
                                    <Translate id="Buttons.GoBack"/>
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        }}
    </Translate>)
}

export default withRouter(PaymentNotify);