import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Translate } from "react-localize-redux";
import OrangeLaptop from "../../images/professional/Group 472.png";
import OrangeOk from "../../images/professional/3.png";
import OrangeLaptopM from "../../images/professional/Group 1916Movil.png";
import OrangeOkM from "../../images/professional/3Movil.png";
import PersonalUnidad from "../../images/professional/persona-unidad.png";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CircularProgress, Tooltip, useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import api from "../../api/index";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@material-ui/core/Icon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import ImageVideoEntrevista from "../../images/professional/group-522.png";
import Select from "@material-ui/core/Select";
import { offersSatte } from "../../utils/constants";
import { orderBy } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import * as types from "../../actions/errors";
import { useDispatch } from "react-redux";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Alert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import Hidden from "@material-ui/core/Hidden";
import LogoPreview from "./interviewPreview";

const useStyles = makeStyles((theme) => {
  return {
    titleAlert: {
      fontSize: 18,
      fontWeight: "bold",
      letterSpacing: 0.7,
    },
    colorBlack: {
      color: theme.palette.text.secondary,
    },
    paper: {
      [theme.breakpoints.up("sm")]: {
        boxShadow: "0px 0px 36px #00000017",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 21,
      },
    },
    title: {
      fontSize: 35,
      fontWeight: 600,
      //marginTop: 20,
      lineHeight: "normal",
      [theme.breakpoints.only("xs")]: {
        fontSize: 30,
        marginTop: 0,
      },
    },
    logo: {
      backgroundImage: `url('${ImageVideoEntrevista}')`,
      backgroundSize: "cover",
      width: 85,
      height: 85,
      borderRadius: 20,
      [theme.breakpoints.only("xs")]: {
        width: 63,
        height: 63,
      },
    },
    img: {
      width: 85,
      height: 85,
      objectFit: "cover",
      objectPosition: "center",
      borderRadius: 10,
      [theme.breakpoints.only("xs")]: {
        width: 63,
        height: 63,
      },
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      "& .MuiTabs-indicator": {
        height: 0,
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 25px #00000041",
        border: "1px solid #141414",
        minHeight: 185,
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
        marginLeft: 24,
        marginTop: 24,
        [theme.breakpoints.only("xs")]: {
          minWidth:
            window.innerWidth < 360
              ? 128
              : window.innerWidth >= 375
              ? 154
              : 140,
          maxWidth:
            window.innerWidth < 360
              ? 128
              : window.innerWidth >= 375
              ? 154
              : 140,
          marginBottom: 8,
          minHeight: 149,
        },
        [theme.breakpoints.only("sm")]: {
          minWidth: window.innerWidth < 708 ? 200 : 300,
          maxWidth: window.innerWidth < 708 ? 200 : 300,
          marginBottom: 8,
          minHeight: 149,
        },
        [theme.breakpoints.only("md")]: {
          maxWidth: "46.3%",
        },
        [theme.breakpoints.only("lg")]: {
          maxWidth: "47.3%",
        },
        [theme.breakpoints.only("xl")]: {
          maxWidth: "48.2%",
        },
      },
      "& .MuiTab-textColorInherit": {
        opacity: "inherit",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 25px #0000001A",
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
        marginLeft: 24,
        marginTop: 24,
        [theme.breakpoints.only("xs")]: {
          minWidth:
            window.innerWidth < 360
              ? 128
              : window.innerWidth >= 375
              ? 154
              : 140,
          maxWidth:
            window.innerWidth < 360
              ? 128
              : window.innerWidth >= 375
              ? 154
              : 140,
          marginBottom: 8,
          minHeight: 149,
        },
        [theme.breakpoints.only("sm")]: {
          minWidth: window.innerWidth < 708 ? 200 : 300,
          maxWidth: window.innerWidth < 708 ? 200 : 300,
          marginBottom: 8,
          minHeight: 149,
        },
        [theme.breakpoints.only("md")]: {
          maxWidth: "46.3%",
        },
        [theme.breakpoints.only("lg")]: {
          maxWidth: "47.3%",
        },
        [theme.breakpoints.only("xl")]: {
          maxWidth: "48.2%",
        },
      },
    },
    tabText: {
      fontSize: 21,
      fontWeight: 600,
      textTransform: "none",
      color: theme.palette.text.secondary,
      letterSpacing: 0.53,
      lineHeight: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    rootSearch: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F7F7F7",
      height: 51,
    },
    inputSearch: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButtonSearch: {
      padding: 10,
    },
    itemList: {
      height: 51,
      marginTop: 24,
      border: `1px solid ${theme.palette.primary.main}`,
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 16,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: 35,
      },
    },
    tabTop: {
      height: 51,
      marginTop: 32,
      backgroundColor: theme.palette.primary.main,
      alignItems: "center",
      [theme.breakpoints.only("sm")]: {
        marginTop: 24,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: 16,
      },
    },
    divider: {
      width: 134,
      height: 0,
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    tabRoot: {
      marginLeft: -20,
      marginRight: -20,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      "& .MuiTabs-flexContainer": {
        flexWrap: "wrap",
        [theme.breakpoints.only("sm")]: {
          justifyContent: "center",
        },
      },
    },
    unselectTab: {
      boxShadow: "0px 0px 36px #00000017",
      margin: 20,
    },
    button: {
      background: "#FFFFFF 0% 0% no-repeat padding-box !important",
      boxShadow: "0px 0px 10px #00000029 !important",
      fontSize: 18,
      color: theme.palette.primary.main,
      width: 185,
      minWidth: 185,
      textTransform: "none",
      font: "normal normal bold 18px/33px Barlow",
      marginBottom: 33,
      // marginLeft: 28,
      borderRadius: 0,
    },
    searchProfessional: {
      textAlign: "left",
      font: "italic normal 600 20px/33px Barlow",
      letterSpacing: 0,
      color: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    root2: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      [theme.breakpoints.only("sm")]: {
        marginTop: 12,
      },
      [theme.breakpoints.only("md")]: {
        justifyContent: "center",
        marginTop: 14,
      },
      [theme.breakpoints.only("lg")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.only("xl")]: {
        marginTop: "10%",
        justifyContent: "space-between",
      },
    },
    colorWhite: {
      color: "#FFFFFF",
    },
    specialty: {
      width: 141,
      margin: 8,
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: 0,
        marginBottom: 0,
      },
      /*'& .MuiInputLabel-shrink': {
                display: "none"
            }*/
      [theme.breakpoints.only("xl")]: {
        width: 250,
      },
      [theme.breakpoints.only("md")]: {
        width: 250,
      },
      [theme.breakpoints.only("lg")]: {
        width: "-webkit-fill-available",
      },
    },
    popperSpecialty: {
      [theme.breakpoints.only("xs")]: {
        //width: "300px !important"
      },
    },
    popperXs: {
      //paddingLeft: 24,
      [theme.breakpoints.only("xs")]: {
        width: "300px !important",
        paddingRight: 24,
      },
    },
    option: {
      color: "#838386",
      '&.MuiAutocomplete-option[aria-selected="true"]': {
        background: "#F0F0F0 0% 0% no-repeat padding-box",
      },
      '&.MuiAutocomplete-option[data-focus="true"]': {
        background: "#F0F0F0 0% 0% no-repeat padding-box",
      },
    },
    province: {
      width: 120,
      margin: 8,
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
        marginRight: 36,
      },
      [theme.breakpoints.only("sm")]: {
        marginRight: 60,
      },
      "& .MuiFormControl-marginNormal": {
        marginTop: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.only("xl")]: {
        marginRight: 36,
        width: 120,
      },
      [theme.breakpoints.only("md")]: {
        marginRight: 36,
        width: 120,
      },
      /*'& .MuiInputLabel-shrink': {
                display: "none"
            }*/
      [theme.breakpoints.only("lg")]: {
        width: "-webkit-fill-available",
      },
    },
    leftSearch: {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      //border: `2px solid ${theme.palette.primary.main}`,
      display: "flex",
      //justifyContent: "center",
      alignItems: "center",
    },
    rightActiveSearch: {
      background: `#FFFFFF 0% 0% no-repeat padding-box`,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    rightSearch: {
      background: `#F7F7F7 0% 0% no-repeat padding-box`,
      border: `1px solid #C1C1C3`,
      minHeight: 50,
    },
    rightSearchInput: {
      minHeight: 50,
      [theme.breakpoints.down("md")]: {
        minHeight: 54,
      },
      "& .MuiInputLabel-formControl": {
        transform: "translate(0, 17px) scale(1)",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(0, -13.5px) scale(1)",
        marginBottom: 0,
        height: 26,
        width: "auto",
        display: "none",
      },
    },
    textField: {
      "& .MuiFormLabel-root": {
        fontSize: 16,
        color: theme.palette.primary.main,
        letterSpacing: 0.8,
        paddingLeft: 24,
        [theme.breakpoints.only("xs")]: {
          fontSize: 15,
        },
      },
    },
    paddingX: {
      [theme.breakpoints.up("lg")]: {
        //paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      },
    },
    paddingX2: {
      [theme.breakpoints.down("md")]: {
        //paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      },
    },
    card: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 15px #00000029",
      width: "30.3%",
      minHeight: 253,
      [theme.breakpoints.only("xs")]: {
        width: "86%",
      },
      [theme.breakpoints.only("md")]: {
        width: "45.3%",
      },
    },
    cardButton: {
      minWidth: 140,
      width: 140,
    },
    inputRoot: {
      borderRadius: 0,
      minHeight: 50,
      minWidth: "100%",
      backgroundColor: "transparent",
      marginBottom: 0,
      marginTop: "0px !important",
      paddingLeft: 24,
    },
    inputRoot2: {
      borderRadius: 0,
      minHeight: 40,
      minWidth: "100%",
      backgroundColor: "transparent",
      marginBottom: 0,
      marginTop: "0px !important",
      paddingLeft: 24,
    },
    inputBase: {
      backgroundColor: "transparent",
      color: "#05BEF8",
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    butonContainer: {
      display: "flex",
      position: "relative",
      [theme.breakpoints.only("xs")]: {
        display: "block",
        position: "unset",
        textAlign: "center",
      },
    },
    submit: {
      minWidth: 200,
      borderRadius: 0,
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: "#FFFFFF",
      textTransform: "none",
      lineHeight: 1,
      [theme.breakpoints.only("md")]: {
        minWidth: 132,
      },
    },
    submit2: {
      minWidth: 200,
      borderRadius: 0,
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: "#FFFFFF",
      textTransform: "none",
    },
    delete: {
      minWidth: 200,
      borderRadius: 0,
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: "#F42950",
      textTransform: "none",
      border: "1px solid #F42950",
      [theme.breakpoints.only("md")]: {
        minWidth: 132,
      },
    },
    deleteText: {
      font: "normal normal bold 18px/33px Barlow",
      letterSpacing: 0.7,
      color: "#F42950",
      textTransform: "none",
    },
    titleAlerts: {
      font: "normal normal bold 22px/33px Barlow",
      letterSpacing: 0.86,
      color: "#FFFFFF",
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
        lineHeight: 1,
      },
    },
    titleAlertsPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    titleCard: {
      font: "normal normal 600 20px/33px Barlow",
      letterSpacing: 0.78,
    },
    menu: {},
    menuItem: {
      marginBottom: 0,
      marginTop: 0,
      fontSize: 18,
      color: "#838386",
      [theme.breakpoints.only("xs")]: {
        minHeight: 22,
      },
    },
    body: {
      font: "normal normal normal 16px/19px Barlow",
      letterSpacing: 0.8,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tabPanel: {
      [theme.breakpoints.only("xs")]: {
        "& .MuiBox-root": {
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        },
      },
    },
    textLinkedin: {
      fontSize: 16,
      letterSpacing: 0.8,
      color: theme.palette.text.secondary,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
    },
    body2: {
      fontSize: 18,
      letterSpacing: 0.7,
      color: theme.palette.text.secondary,
      fontWeight: "bold",
    },

    body3: {
      fontSize: 20,
      letterSpacing: 0.78,
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
    },
    processDiv: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.only("xs")]: {
        //width: 220,
      },
    },
    description: {
      fontSize: 16,
      letterSpacing: 0.8,
      color: theme.palette.text.secondary,
      //paddingBottom: "1.5rem",
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    titleManage: {
      fontSize: 21,
      fontWeight: 600,
      letterSpacing: 0.53,
      color: theme.palette.text.secondary,
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    cardTitle: {
      fontSize: 20,
      letterSpacing: 0.78,
      //color: theme.palette.text.secondary,
      fontWeight: "bold",
    },
    cardSubTitle: {
      fontSize: 18,
      letterSpacing: 0.9,
      color: theme.palette.text.secondary,
    },
    situation: {
      border: "1px solid #193C47",
      borderRadius: "5px 5px 0px 0px",
      textAlign: "center",
      width: 200,
      marginTop: 27,
      [theme.breakpoints.down("sm")]: {
        marginTop: 15,
        marginBottom: 72,
      },
    },
    formControl: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F7F7F7",
        width: "100%",
        justifyContent: "center",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "1rem",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiInput-root": {
        marginTop: "0 !important",
        marginBottom: 0,
        [theme.breakpoints.up("lg")]: {
          backgroundColor: "transparent",
        },
      },
      "& .MuiSelect-selectMenu": {
        [theme.breakpoints.up("lg")]: {
          backgroundColor: "transparent",
          color: "#FFFFFF",
        },
      },
      "& .MuiSelect-icon": {
        [theme.breakpoints.up("lg")]: {
          color: "#FFFFFF",
        },
      },
      "& .MuiSelect-select.MuiSelect-select": {
        [theme.breakpoints.up("lg")]: {
          width: 158,
        },
      },
    },
    paddingLeft: {
      paddingTop: "1.5rem",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.5rem",
      },
    },
    paperAlert: {
      boxShadow: "0px 0px 25px #0000004D",
      borderRadius: 10,
    },
    paperAlertDeleted: {
      textAlign: "center",
      boxShadow: "0px 0px 25px #0000004D",
      borderRadius: 10,
      padding: "15px 48px 29px 48px",
      [theme.breakpoints.only("xs")]: {
        padding: "15px 24px 29px 24px",
      },
    },
    rightDisableSearchAlert: {
      background: `#FFFFFF 0% 0% no-repeat padding-box`,
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    rightSearchAlert: {
      background: `#F7F7F7 0% 0% no-repeat padding-box`,
      //border: `1px solid #C1C1C3`,
      minHeight: 50,
    },
    rightSearchAlert2: {
      background: `#F7F7F7 0% 0% no-repeat padding-box`,
      //border: `1px solid #C1C1C3`,
      minHeight: 40,
    },
    rightSearchInputAlert: {
      minHeight: 50,
      [theme.breakpoints.down("md")]: {
        minHeight: 54,
      },
      "& .MuiInputLabel-formControl": {
        transform: "translate(0, 17px) scale(1)",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(0, -13.5px) scale(1)",
        marginBottom: 0,
        height: 26,
        width: "auto",
        display: "none",
      },
      "& .MuiFormControl-fullWidth": {
        [theme.breakpoints.down("xs")]: {
          minWidth: 140,
          maxWidth: 140,
        },
        minWidth: 300,
        maxWidth: 300,
      },
    },
    inputBaseAlert: {
      backgroundColor: "transparent",
      color: theme.palette.text.secondary,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    responsiveWidth: {
      width: "100%",
      "& input": {
        overflow: "hidden",
      },
      "&.MuiInput-root": {
        borderRadius: 0,
        minHeight: 50,
        backgroundColor: "transparent",
        marginBottom: 0,
        marginTop: "0px !important",
        //paddingLeft: 24,
        [theme.breakpoints.down("xs")]: {
          minWidth: 140,
          maxWidth: 140,
        },
        minWidth: 300,
        maxWidth: 300,
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        paddingLeft: 24,
        [theme.breakpoints.down("xs")]: {
          width: 60,
        },
      },
    },
    bodyAlert: {
      fontSize: 16,
      letterSpacing: 0.8,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("lg")]: {
        width: 15,
      },
    },
    paperWidthSm: {
      [theme.breakpoints.up("md")]: {
        width: 542,
      },
    },
    submit3: {
      minWidth: 82,
      borderRadius: 0,
      fontSize: 18,
      fontWeight: "bold",
    },
    iconButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
      padding: 8,
      "&.MuiIconButton-root:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
      },
    },
  };
});

const tab0Styles = makeStyles(() => {
  return {
    root: {
      boxShadow: "0px 0px 36px #00000017",
      backgroundColor: "#FFFFFF",
      margin: 20,
      marginRight: 0,
    },
  };
});

const tab1Styles = makeStyles(() => {
  return {
    root: {
      boxShadow: "0px 0px 36px #00000017",
      backgroundColor: "#FFFFFF",
      margin: 20,
      marginLeft: 0,
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default function ProfessionalManagement(props) {
  const classes = useStyles();
  const theme = useTheme();
  const screenXsOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const screenXlOnly = useMediaQuery(theme.breakpoints.only("xl"));
  const screenMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const screenLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const screenMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const screenSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedTab = props.match.params["tab"]
    ? parseInt(props.match.params["tab"])
    : null;
  let history = useHistory();
  let [states, setStates] = useState([]);
  const dispatch = useDispatch();
  let [applications, setApplications] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [valueTab, setValueTab] = React.useState(
    selectedTab && selectedTab < 2 ? selectedTab : 0
  );
  let [professionalInfo, setProfessionalInfo] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  let professional = professionalInfo ? professionalInfo : {};
  let [saving] = useState(false);

  let [specialties, setSpecialties] = useState(null);
  let [provinces, setProvinces] = useState(null);
  let [enterprises, setEnterprises] = useState(null);
  let [specialty, setSpecialty] = React.useState(null);
  let [province, setProvince] = React.useState(null);
  let [alerts, setAlerts] = useState(null);
  let [alertsMessage, setAlertsMessage] = useState(null);
  let [alertsMessageColor, setAlertsMessageColor] = useState("#00B52A");
  const [specialtyAlert, setSpecialtyAlert] = React.useState(null);
  const [provinceAlert, setProvinceAlert] = React.useState(null);
  const [enterpriseAlert, setEnterpriseAlert] = React.useState(null);
  let [filterValue, setFilterValue] = React.useState(1);
  let [filterValues, setFilterValues] = React.useState([]);
  let [filterValueAlert, setFilterValueAlert] = React.useState(1);
  let [filterValuesAlert, setFilterValuesAlerts] = React.useState([]);
  let [openNewAlert, setOpenNewAlert] = React.useState(false);

  let [employmentRelationships, setEmploymentRelationships] = useState(null);
  let [employmentRelationship, setEmploymentRelationship] = useState(null);
  let [workdays, setWorkdays] = useState(null);
  let [workday, setWorkday] = useState(null);
  let [idAlert, setIdAlert] = useState(null);
  let [updateDateAlert, setUpdateDateAlert] = useState(Date.now());
  let [daily, setDaily] = useState("daily");
  let [order, setOrder] = useState("desc");
  let [orderAlert, setOrderAlert] = useState(
    filterValueAlert === 1 ? "desc" : "asc"
  );
  let [openDialogDelete, setOpenDialogDelete] = useState(false);
  let [errorAlert, setErrorAlert] = useState(false);

  let [filters, setFilters] = useState(null);

  const defaultPropsSpecialty = {
    options: specialties ? specialties : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultProvince = {
    options: provinces ? provinces : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultEnterprise = {
    options: enterprises ? enterprises : [],
    getOptionLabel: (option) => option.name,
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const prevOpen = React.useRef(open);
  useEffect(() => {
    loadFilters();
    loadProfessionalProfile();
    loadStates();
    loadApplications();

    /*loadProvinces();
        loadSpecialties();*/

    loadEnterprises();
    loadEmploymentRelationship();
    loadWorkdays();
    loadAlerts();
    loadFilterValues();
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [specialties, provinces, enterprises, alerts, open, openNewAlert]);

  function loadFilters(reload = false) {
    if ((provinces === null && specialties === null) || reload) {
      api.filters
        .getFilters(province ? province.id : 0, specialty ? specialty.id : 0)
        .then((response) => {
          if (response.status === 200) {
            let { provinces, specialties } = response.data;
            setProvinces(provinces);
            setSpecialties(specialties);
          }
        });
    }
  }

  function immediatelyLoadFilters(provinceId, specialtyId) {
    api.filters.getFilters(provinceId, specialtyId).then((response) => {
      if (response.status === 200) {
        let { provinces, specialties } = response.data;
        setProvinces(provinces);
        setSpecialties(specialties);
      }
    });
  }

  function loadFilterValues() {
    let filter = [
      { name: <Translate id={"Offers.Professional.DateOfPublish"} />, id: 1 },
      { name: <Translate id={"Offers.Professional.Province"} />, id: 2 },
      { name: <Translate id={"Offers.Professional.Name"} />, id: 3 },
    ];

    setFilterValues(filter);
    let filter2 = [
      { name: <Translate id={"Offers.Employer.DateOfPublish"} />, id: 1 },
      { name: <Translate id={"Offers.Employer.Province"} />, id: 2 },
      { name: <Translate id={"Offers.Employer.Specialty"} />, id: 3 },
    ];
    setFilterValuesAlerts(filter2);
  }

  const handleClickOpenAlert = () => {
    setOpenNewAlert(true);
  };

  const handleCloseOpenAlert = () => {
    setOpenNewAlert(false);
    setIdAlert(null);
    setErrorAlert(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangeDaily = (event) => {
    let frequency = event.target.value;
    api.alerts
      .changeSchedule(frequency === "daily" ? 1 : 7)
      .then((response) => {
        setDaily(frequency);
      });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function showError(error) {
    dispatch(types.fetchSetErrorText(error));
    dispatch(types.fetchOpen(true));
  }

  function loadEmploymentRelationship() {
    try {
      if (!employmentRelationships)
        api.employmentRelationships.all().then((response) => {
          if (response.status === 200) {
            setEmploymentRelationships(response.data);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  function loadWorkdays() {
    try {
      if (!workdays)
        api.workdays.all().then((response) => {
          if (response.status === 200) {
            setWorkdays(response.data);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function loadProfessionalProfile() {
    if (professionalInfo === null)
      api.professional.getProfessionalProfile().then((response) => {
        if (response.status === 200) {
          setProfessionalInfo(response.data);
        }
      });
  }

  function loadStates() {
    if (!states.length)
      api.states.all().then((response) => {
        if (response.status === 200) {
          let { data } = response;
          let filterData = data.filter((s) => s.code !== offersSatte.draft);
          setStates(filterData);
          if (filterData.length) {
            handleListItemClick(null, filterData[0].id);
          }
        }
      });
  }

  function loadApplications() {
    if (applications === null)
      api.professional.getProfessionalApplications().then((response) => {
        if (response.status === 200) {
          let data = Object.assign({}, response.data);
          let orderAux = orderBy(
            data,
            [
              function (o) {
                return filterValue === 1
                  ? o.offer.initLifeRangeDate
                  : filterValue === 2
                  ? o.offer.municipality &&
                    o.offer.municipality.province &&
                    o.offer.municipality.province.name
                    ? o.offer.municipality.province.name
                    : ""
                  : o.offer.title;
              },
            ],
            filterValue === 1 ? "desc" : "asc"
          );
          setApplications(orderAux);
        } else {
          setApplications([]);
          showError(response.data.error);
        }
      });
  }

  function loadAlerts() {
    try {
      if (alerts === null && professionalInfo)
        api.alerts.allByProfessional(professionalInfo.id).then((response) => {
          if (response.status === 200) {
            let data = response.data;
            let alertAux = orderBy(
              data,
              [
                function (o) {
                  return filterValueAlert === 1
                    ? new Date(o.updateDate).getTime()
                    : filterValueAlert === 2
                    ? o.province && o.province.name
                      ? o.province.name
                      : ""
                    : o.specialty && o.specialty.name
                    ? o.specialty.name
                    : "";
                },
              ],
              filterValueAlert === 1 ? "desc" : "asc"
            );
            setAlerts(alertAux);

            if (alertAux.length > 0) {
              let frequency = alertAux[0].daily ? "daily" : "weekly";
              setDaily(frequency);
            }
          } else {
            setAlerts([]);
            showError(response.data.error);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  function loadProvinces() {
    try {
      if (provinces === null)
        api.provinces.all().then((response) => {
          if (response.status === 200) {
            setProvinces(response.data);
          } else {
            setProvinces([]);
            showError(response.data.error);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  function loadEnterprises() {
    try {
      if (enterprises === null)
        api.enterprises.all().then((response) => {
          if (response.status === 200) {
            setEnterprises(response.data);
          } else {
            setEnterprises([]);
            showError(response.data.error);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  function loadSpecialties() {
    try {
      if (specialties === null)
        api.specialties.all().then((response) => {
          if (response.status === 200) {
            setSpecialties(response.data);
          } else {
            setSpecialties([]);
            showError(response.data.error);
          }
        });
    } catch (e) {
      showError(e.message);
    }
  }

  const handleChangeSelect = (event) => {
    const name = event.target.name;
    if (name === "employmentRelationship") {
      setEmploymentRelationship(event.target.value);
    } else {
      setWorkday(event.target.value);
    }
    setErrorAlert(false);
  };

  function renderItem(state, name) {
    let total =
      state !== -1
        ? applications
          ? applications.filter((x) => x.offer.stateId === state).length
          : 0
        : applications
        ? applications.length
        : 0;
    return (
      <ListItem
        button
        className={`${classes.itemList} d-flex justify-content-center`}
        style={state !== -1 ? { marginTop: 24 } : {}}
        selected={selectedIndex === state}
        onClick={(event) => handleListItemClick(event, state)}
      >
        <div className="d-flex">
          <Typography
            color="primary"
            className={classes.body2}
            style={
              selectedIndex === state
                ? { color: "#FFFFFF" }
                : { color: "#193C47" }
            }
          >
            {`${name} (${total})`}
          </Typography>
        </div>
      </ListItem>
    );
  }

  const handleChangeFilter = (event) => {
    let value = event.target.value;
    if (valueTab === 0) {
      let data = Object.assign({}, applications);
      let orderAux = orderBy(
        data,
        [
          function (o) {
            return value === 1
              ? o.offer.initLifeRangeDate
              : value === 2
              ? o.offer.municipality.province.name
              : o.offer.title;
          },
        ],
        value === 1 ? "desc" : "asc"
      );
      setApplications(orderAux);
      setFilterValue(value);
    } else {
      let data = Object.assign([], alerts);
      let alertAux = orderBy(
        data,
        [
          function (o) {
            return value === 1
              ? o.updateDate
              : value === 2
              ? o.province && o.province.name
                ? o.province.name
                : ""
              : o.specialty && o.specialty.name
              ? o.specialty.name
              : "";
          },
        ],
        value === 1 ? "desc" : "asc"
      );
      setAlerts(alertAux);
      setFilterValueAlert(value);
    }
  };

  function renderFilter() {
    return (
      <div className={classes.formControl}>
        <Typography
          variant="body2"
          style={
            screenLgUp
              ? { fontWeight: 600, color: "#FFFFFF" }
              : { color: "#141414", fontWeight: 600 }
          }
        >
          <Translate id={"Offers.OrderedFor"} />:
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id={valueTab}
          value={valueTab === 0 ? filterValue : filterValueAlert}
          onChange={handleChangeFilter}
        >
          {valueTab === 0
            ? filterValues.map((value) => {
                return <MenuItem value={value.id}>{value.name}</MenuItem>;
              })
            : filterValuesAlert.map((value) => {
                return <MenuItem value={value.id}>{value.name}</MenuItem>;
              })}
        </Select>
      </div>
    );
  }

  function renderItemTab(applications, state) {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <div
            className={`${classes.tabTop} d-flex flex-wrap justify-content-between`}
          >
            {state !== -1 ? (
              <div className={classes.processDiv}>
                <Typography
                  className={`${classes.body3} pl-4`}
                  style={{ color: "#FFFFFF" }}
                >
                  <Translate id={"Employer.SelectionProcesses"} />
                </Typography>
                <Typography
                  className={`${classes.body3} pl-4 pl-sm-1 text-lowercase`}
                  style={{ color: "#FFFFFF" }}
                >
                  {state ? state.name : ""}
                </Typography>
              </div>
            ) : (
              <Typography
                className={`${classes.body3} font-weight-bold pl-4`}
                style={{ color: "#FFFFFF" }}
              >
                <Translate id={"Professional.AllSelectionProcesses"} />
              </Typography>
            )}

            {renderFilter()}
          </div>
        </Grid>
        <Grid item xs={12} style={screenXsOnly ? { marginTop: 45 } : {}}>
          {applications &&
            applications.map((application) => {
              let { offer } = application;
              return (
                <Paper
                  style={{
                    boxShadow: "0px 0px 15px #00000029",
                    marginTop: screenXsOnly ? 25 : 35,
                  }}
                >
                  <div style={{ padding: "26px 35px" }}>
                    <Grid container style={{ minHeight: 210 }}>
                      <Grid item xs={12} md={9}>
                        <div className="d-flex">
                          <Typography
                            className={`${classes.cardTitle} pr-2`}
                            color="textSecondary"
                          >
                            {offer.title}
                          </Typography>
                          <Typography
                            className={classes.cardTitle}
                            color="primary"
                          >
                            {` (${offer.stateName})`}
                          </Typography>
                        </div>
                        <Divider className={`${classes.divider} my-2`} />
                        <div className="mt-3 mb-3">
                          {application.hiddenEnterpriseInfo ? (
                            ""
                          ) : (
                            <Typography
                              className={`${classes.cardSubTitle} font-weight-bold`}
                            >
                              {` ${
                                application.enterprise &&
                                application.enterprise.name
                              }`}
                            </Typography>
                          )}
                          {offer.municipality ? (
                            <div className="d-flex">
                              <Typography className={`${classes.cardSubTitle}`}>
                                {` ${offer.municipality.province.name}`}
                              </Typography>
                              <Typography
                                className={`${classes.cardSubTitle} px-2`}
                              >
                                -
                              </Typography>
                              <Typography className={`${classes.cardSubTitle}`}>
                                {` ${offer.municipality.name}`}
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <Typography
                          className={`${classes.description} pr-3`}
                          color="textSecondary"
                        >
                          {offer.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className="position-relative text-center"
                      >
                        <Typography
                          variant="body2"
                          color={"textSecondary"}
                          style={
                            offersSatte.open !== offer.stateCode
                              ? { paddingBottom: 86 }
                              : {}
                          }
                          className={`font-weight-bold ${
                            screenSmDown ? "pt-3" : "pt-2"
                          }`}
                        >
                          {application.offer &&
                            application.offer.initLifeRangeDate &&
                            moment(application.offer.initLifeRangeDate).format(
                              "DD/MM/YYYY"
                            )}
                        </Typography>
                        <div className="d-flex justify-content-center">
                          {offersSatte.open === offer.stateCode ? (
                            <div className={classes.situation}>
                              <Typography
                                variant="body2"
                                color={"textSecondary"}
                                className="font-weight-bold py-1"
                              >
                                <Translate id={"Offers.YourSituation"} />
                              </Typography>
                              <Typography
                                variant="body2"
                                color={"textSecondary"}
                                className="py-1"
                                style={{
                                  borderTop: "1px solid #193C47",
                                  backgroundColor: "#008FBA19",
                                }}
                              >
                                {application.state && application.state.name
                                  ? application.state.name
                                  : ""}
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}

                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => {
                              history.push(`/offer/${offer.seoTitle}`);
                            }}
                            className={`${classes.submit} position-absolute`}
                            style={{ bottom: 0 }}
                          >
                            <Typography className={`${classes.submit}`}>
                              <Translate id={"Professional.ToAccess"} />
                            </Typography>
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              );
            })}
        </Grid>
      </Grid>
    );
  }

  const tab0Classes = tab0Styles();
  const tab1Classes = tab1Styles();

  function handleAddAlert() {
    if (specialtyAlert || provinceAlert) {
      let data = Object.assign([], alerts);
      try {
        let jsonAlert = {
          professionalId: professionalInfo ? professionalInfo.id : null,
          specialtyId: specialtyAlert ? specialtyAlert.id : null,
          provinceId: provinceAlert ? provinceAlert.id : null,
          enterpriseId: enterpriseAlert ? enterpriseAlert.id : null,
          employmentRelationshipId: employmentRelationship
            ? employmentRelationship
            : null,
          workdayId: workday ? workday : null,
          daily: daily === "daily",
        };
        if (idAlert) {
          jsonAlert["id"] = idAlert;
        }
        api.alerts.addOrUpdate(jsonAlert).then((response) => {
          handleCloseOpenAlert();
          if (response.status === 200) {
            setAlertsMessage(
              idAlert ? (
                <Translate id={"Offers.ChangesMadeSuccessfully"} />
              ) : (
                <Translate id={"Offers.NewAlertCreatedSuccessfully"} />
              )
            );
            setAlertsMessageColor("#00B52A");
            let alertAux = {
              specialty: response.data.specialty,
              province: response.data.province,
              enterprise: response.data.enterprise,
              employmentRelationship: response.data.employmentRelationship,
              workday: response.data.workday,
              id: response.data.id,
            };
            if (idAlert) {
              let index = data.findIndex((a) => a.id === idAlert);
              if (index !== -1) {
                data[index] = alertAux;
              }
            } else {
              data.push(alertAux);
            }
            let alert = orderBy(
              data,
              [
                function (o) {
                  return filterValueAlert === 1
                    ? o.updateDate
                    : filterValueAlert === 2
                    ? o.province && o.province.name
                      ? o.province.name
                      : ""
                    : o.specialty && o.specialty.name
                    ? o.specialty.name
                    : "";
                },
              ],
              filterValueAlert === 1 ? "desc" : "asc"
            );
            setAlerts(alert);
          } else {
            showError(response.data.error);
          }
          setIdAlert(null);
          setSpecialtyAlert(null);
          setProvinceAlert(null);
          setEnterpriseAlert(null);
          setEmploymentRelationship(null);
          setWorkday(null);
          setUpdateDateAlert(null);
        });
      } catch (e) {
        setIdAlert(null);
        showError(e.message);
      }
    } else {
      setErrorAlert(true);
    }
  }

  function handleDeleteAlert() {
    try {
      setOpenDialogDelete(false);
      let data = Object.assign([], alerts);
      api.alerts.delete(idAlert).then((response) => {
        if (response.status === 200) {
          setAlertsMessage(<Translate id={"Offers.AlertRemovedList"} />);
          setAlertsMessageColor("#00B52A");
          let aux = data.filter((a) => a.id !== idAlert);
          setAlerts(aux);
        } else {
          showError(response.data.error);
        }
        setIdAlert(null);
        setSpecialtyAlert(null);
        setProvinceAlert(null);
        setEnterpriseAlert(null);
        setEmploymentRelationship(null);
        setWorkday(null);
        setUpdateDateAlert(null);
      });
    } catch (e) {
      setIdAlert(null);
      showError(e.message);
    }
  }

  function handleEditAlert(
    specialtyP,
    provinceP,
    enterpriseP,
    employmentRelationshipP,
    workdayP,
    updateDateP,
    idAlertP
  ) {
    try {
      addAuxDataAlert(
        specialtyP,
        provinceP,
        enterpriseP,
        employmentRelationshipP,
        workdayP,
        updateDateP,
        idAlertP
      );
      setOpenNewAlert(true);
    } catch (e) {
      showError(e.message);
    }
  }

  function confirmDeletedAlert(
    specialtyP,
    provinceP,
    enterpriseP,
    employmentRelationshipP,
    workdayP,
    updateDateP,
    idAlertP
  ) {
    try {
      addAuxDataAlert(
        specialtyP,
        provinceP,
        enterpriseP,
        employmentRelationshipP,
        workdayP,
        updateDateP,
        idAlertP
      );
      setOpenDialogDelete(true);
    } catch (e) {
      showError(e.message);
    }
  }

  function addAuxDataAlert(
    specialtyP,
    provinceP,
    enterpriseP,
    employmentRelationshipP,
    workdayP,
    updateDateP,
    idAlertP
  ) {
    setSpecialtyAlert(specialtyP);
    setProvinceAlert(provinceP);
    setEnterpriseAlert(enterpriseP);
    setEmploymentRelationship(
      employmentRelationshipP ? employmentRelationshipP.id : ""
    );
    setWorkday(workdayP ? workdayP.id : "");
    setIdAlert(idAlertP);
    setUpdateDateAlert(updateDateP);
  }

  function renderDialogDelete() {
    return (
      <Dialog
        open={openDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        classes={{ paper: classes.paperAlertDeleted }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className={`${classes.submit3} font-weight-bold`}>
            <Translate id={"Offers.Employer.RemoveFollowingAlert"} />
          </Typography>
        </DialogTitle>
        <DialogContent className="pb-4 px-0">
          <List className={"px-5 py-3"} style={{ border: "1px solid #193C47" }}>
            <ListItem key={0} className={"p-0"}>
              <Typography
                variant="body2"
                color="primary"
                className="font-weight-bold"
              >
                <Translate id={"Offers.Employer.Specialty"} />:{" "}
                {specialtyAlert ? specialtyAlert.name : ""}
              </Typography>
            </ListItem>
            <ListItem key={1} className={"p-0"}>
              <Typography
                variant="body2"
                color="primary"
                className="font-weight-bold"
              >
                <Translate id={"Offers.Employer.Province"} />:{" "}
                {provinceAlert ? provinceAlert.name : ""}
              </Typography>
            </ListItem>
            <Hidden xsUp>
              <ListItem key={2} className={"p-0"}>
                <Typography
                  variant="body2"
                  color="primary"
                  className="font-weight-bold"
                >
                  <Translate id={"Offers.Employer.Company"} />:{" "}
                  {enterpriseAlert ? enterpriseAlert.name : ""}
                </Typography>
              </ListItem>
            </Hidden>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDeleteAlert()}
            className={`${classes.submit3} mx-3`}
          >
            <Typography className={`${classes.submit3} ${classes.colorWhite}`}>
              <Translate id={"Buttons.Yes"} />
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialogDelete(false)}
            className={`${classes.submit3} mx-3`}
          >
            <Typography className={`${classes.submit3} ${classes.colorWhite}`}>
              <Translate id={"Buttons.No"} />
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function renderNewAlert() {
    return (
      <div>
        <Dialog
          open={openNewAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          classes={{
            paper: classes.paperAlert,
            paperWidthSm: classes.paperWidthSm,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <div className="d-flex justify-content-center align-items-center">
              <img alt={"create alert"} src={PersonalUnidad} className="pr-3" />
              <Typography className={classes.titleAlert}>
                {idAlert ? (
                  <Translate id={"Offers.EditAlert"} />
                ) : (
                  <Translate id={"Professional.CreateNewAlert"} />
                )}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="d-flex justify-content-between align-items-center">
              <Typography
                variant="body2"
                color="textSecondary"
                className="font-weight-bold"
              >
                <Translate id={"Offers.Specialty"} />:
              </Typography>
              <div
                className={`${classes.rightSearchAlert} ${classes.rightSearchInputAlert} `}
              >
                <Autocomplete
                  {...defaultPropsSpecialty}
                  id="auto-highlight-speciality-alert"
                  value={specialtyAlert}
                  onChange={(event, newValue) => {
                    setSpecialtyAlert(newValue);
                    setErrorAlert(false);
                  }}
                  autoHighlight
                  closeIcon={<CloseIcon color={"primary"} fontSize="small" />}
                  classes={{
                    popper: classes.popperXs,
                    //option: classes.option,
                    inputRoot: classes.inputRoot2,
                    input: classes.inputBaseAlert,
                  }}
                  renderInput={(params) => (
                    <TextField
                      color={"primary"}
                      className={classes.textField}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{
                        classes,
                        disableUnderline: true,
                      }}
                      {...params}
                      label={<Translate id={"Offers.Indicate"} />}
                      margin="none"
                    />
                  )}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);
                    return (
                      <li>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              color: "#141414",
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </li>
                    );
                  }}
                />
              </div>
            </div>
            <div className="d-flex my-3 justify-content-between align-items-center">
              <Typography
                variant="body2"
                color="textSecondary"
                className="font-weight-bold"
              >
                <Translate id={"Offers.Province"} />:
              </Typography>
              <div
                className={`${classes.rightSearchAlert} ${classes.rightSearchInputAlert}`}
              >
                <Autocomplete
                  {...defaultProvince}
                  id="auto-highlight-province"
                  value={provinceAlert}
                  onChange={(event, newValue) => {
                    setProvinceAlert(newValue);
                    setErrorAlert(false);
                  }}
                  autoHighlight
                  closeIcon={<CloseIcon color={"primary"} fontSize="small" />}
                  classes={{
                    popper: classes.popperXs,
                    //option: classes.option,
                    inputRoot: classes.inputRoot2,
                    input: classes.inputBaseAlert,
                  }}
                  renderInput={(params) => (
                    <TextField
                      color={"primary"}
                      className={classes.textField}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        classes,
                        disableUnderline: true,
                      }}
                      {...params}
                      label={<Translate id={"Offers.Indicate"} />}
                      margin="none"
                    />
                  )}
                  renderOption={(option, { inputValue }) => {
                    const matches = match(option.name, inputValue);
                    const parts = parse(option.name, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              color: "#141414",
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <Hidden xsUp>
              <div className="d-flex my-3 justify-content-between align-items-center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="font-weight-bold"
                >
                  <Translate id={"Offers.Enterprise"} />:
                </Typography>
                <div
                  className={`${classes.rightSearchAlert} ${classes.rightSearchInputAlert}`}
                >
                  <Autocomplete
                    {...defaultEnterprise}
                    id="auto-highlight-enterprice"
                    value={enterpriseAlert}
                    onChange={(event, newValue) => {
                      setEnterpriseAlert(newValue);
                      setErrorAlert(false);
                    }}
                    autoHighlight
                    closeIcon={<CloseIcon color={"primary"} fontSize="small" />}
                    classes={{
                      popper: classes.popperXs,
                      //option: classes.option,
                      inputRoot: classes.inputRoot2,
                      input: classes.inputBaseAlert,
                    }}
                    renderInput={(params) => (
                      <TextField
                        color={"primary"}
                        className={classes.textField}
                        InputLabelProps={{ shrink: false }}
                        InputProps={{
                          classes,
                          disableUnderline: true,
                        }}
                        {...params}
                        label={<Translate id={"Offers.Indicate"} />}
                        margin="none"
                      />
                    )}
                    renderOption={(option, { inputValue }) => {
                      const matches = match(option.name, inputValue);
                      const parts = parse(option.name, matches);
                      return (
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                                color: "#141414",
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="d-flex my-3 justify-content-between align-items-center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="font-weight-bold"
                >
                  <Translate id={"Offers.EmploymentRelationship"} />:
                </Typography>
                <Translate>
                  {({ translate }) => {
                    return (
                      <div
                        className={`${classes.rightSearchAlert} ${classes.rightSearchInputAlert}`}
                      >
                        <Select
                          className={classes.responsiveWidth}
                          classes={{
                            inputRoot: classes.inputRoot2,
                            input: classes.inputBaseAlert,
                          }}
                          label={translate("Offers.Indicate")}
                          onChange={handleChangeSelect}
                          name="employmentRelationship"
                          value={employmentRelationship}
                          displayEmpty
                          renderValue={(value) =>
                            value
                              ? employmentRelationships.find(
                                  (x) => x.id === value
                                ).name
                              : translate("Offers.Indicate")
                          }
                        >
                          <MenuItem value="">
                            <em>{translate("Offers.None")}</em>
                          </MenuItem>
                          {employmentRelationships &&
                            employmentRelationships.map((title) => {
                              return (
                                <MenuItem value={title.id}>
                                  {title.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    );
                  }}
                </Translate>
              </div>
              <div className="d-flex my-3 justify-content-between align-items-center">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="font-weight-bold"
                >
                  <Translate id={"Offers.Workday"} />:
                </Typography>
                <Translate>
                  {({ translate }) => {
                    return (
                      <div
                        className={`${classes.rightSearchAlert} ${classes.rightSearchInputAlert}`}
                      >
                        <Select
                          className={classes.responsiveWidth}
                          classes={{
                            inputRoot: classes.inputRoot2,
                            input: classes.inputBaseAlert,
                          }}
                          label={translate("Offers.Indicate")}
                          onChange={handleChangeSelect}
                          name="workday"
                          value={workday}
                          displayEmpty
                          renderValue={(value) =>
                            value
                              ? workdays.find((x) => x.id === value).name
                              : translate("Offers.Indicate")
                          }
                        >
                          <MenuItem value="">
                            <em>{translate("Offers.None")}</em>
                          </MenuItem>

                          {workdays &&
                            workdays.map((title) => {
                              return (
                                <MenuItem value={title.id}>
                                  {title.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    );
                  }}
                </Translate>
              </div>
            </Hidden>
            <div>
              {errorAlert ? (
                <FormHelperText
                  variant={"body2"}
                  color={"error"}
                  className="pt-3 d-flex justify-content-center"
                  style={{ fontSize: 14, color: "#FB060F" }}
                >
                  <Translate id={"Offers.LeastOneFieldWithInformation"} />
                </FormHelperText>
              ) : (
                ""
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div
              className={`d-flex flex-wrap mx-3 ${
                screenSmDown
                  ? "justify-content-center"
                  : "justify-content-between"
              } ${screenXlOnly ? "mt-3 mb-4" : "my-3"}`}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAlert}
                className={`${classes.submit2} my-2 ${
                  screenXlOnly ? "" : "mx-2"
                }`}
              >
                {saving && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    className="mr-2"
                  />
                )}
                <Typography className={`${classes.submit2}`}>
                  <Translate
                    id={idAlert ? "Offers.CommitChanges" : "Offers.SaveAlert"}
                  />
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseOpenAlert}
                className={`${classes.submit2} my-2 ${
                  screenXlOnly ? "" : "mx-2"
                }`}
              >
                {saving && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    className="mr-2"
                  />
                )}
                <Typography className={`${classes.submit2}`}>
                  <Translate id={"Buttons.Cancel"} />
                </Typography>
              </Button>
            </div>
            {idAlert && (
              <Typography
                variant="body2"
                color="textSecondary"
                className={"pt-1 pb-3"}
              >
                <Translate id={"Offers.DateLastModified"} />:{" "}
                <strong>{moment(updateDateAlert).format("DD/MM/YYYY")}</strong>
              </Typography>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function renderItemCardAlert(title, value) {
    return (
      <Grid container spacing={screenXlOnly ? 2 : 0}>
        <Grid item xs={12} xl={4}>
          <Typography
            variant="body2"
            color="textSecondary"
            className="font-weight-bold"
            style={screenXlOnly ? { paddingTop: 10 } : {}}
          >
            <Translate id={title} />:
          </Typography>
        </Grid>
        <Grid item xs={12} xl={8} className={"pb-3"}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ paddingTop: 10, paddingLeft: 20 }}
            className={classes.rightSearchAlert2}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function renderCardAlert() {
    return (
      alerts &&
      alerts.map((alert) => {
        return (
          <Paper
            elevation={0}
            className={`${classes.card} ${
              screenXsOnly ? "p-4 mb-1" : "p-4 m-3"
            } `}
            style={{ marginTop: screenXsOnly ? 25 : 35 }}
          >
            <Grid container>
              <Grid item xs={12}>
                {renderItemCardAlert(
                  "Offers.Specialty",
                  alert.specialty ? alert.specialty.name : ""
                )}
                {renderItemCardAlert(
                  "Offers.Province",
                  alert.province ? alert.province.name : ""
                )}
              </Grid>
              <Grid item xs={12} className="text-center">
                <div
                  className={`d-flex flex-wrap  ${
                    screenXlOnly
                      ? "justify-content-between mt-5"
                      : "justify-content-center mt-3"
                  }`}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleEditAlert(
                        alert.specialty,
                        alert.province,
                        alert.enterprise,
                        alert.employmentRelationship,
                        alert.workday,
                        alert.updateDate,
                        alert.id
                      )
                    }
                    className={`${classes.submit} my-2 ${
                      screenXlOnly ? "" : "mx-2"
                    }`}
                  >
                    <Typography className={`${classes.submit}`}>
                      <Translate id={"Offers.ManagementEdit"} />
                    </Typography>
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      confirmDeletedAlert(
                        alert.specialty,
                        alert.province,
                        alert.enterprise,
                        alert.employmentRelationship,
                        alert.workday,
                        alert.updateDate,
                        alert.id
                      );
                    }}
                    className={`${classes.delete} my-2  ${
                      screenXlOnly ? "" : "mx-2"
                    }`}
                  >
                    <Typography className={`${classes.deleteText}`}>
                      <Translate id={"Buttons.Delete"} />
                    </Typography>
                  </Button>
                </div>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={screenXlOnly ? "pt-5" : "pt-4"}
                >
                  <Translate id={"Offers.DateLastModified"} />:{" "}
                  <strong>
                    {moment(alert.updateDate).format("DD/MM/YYYY")}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      })
    );
  }

  function renderManagementProcess() {
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} md={3}>
              <Typography className={classes.titleManage}>
                <Translate id={"Offers.ManageSelectionProcesses"} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container className={classes.paddingLeft}>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  xl={4}
                  className={`${classes.leftSearch} py-2`}
                >
                  <Icon
                    fontSize={"large"}
                    className={`d-flex align-items-center justify-content-center ${classes.colorWhite} mx-md-2`}
                  >
                    <SearchIcon />
                  </Icon>
                  <Typography className={classes.searchProfessional}>
                    <Translate id="Titles.SearchSelectionProcesses" />
                  </Typography>
                  <IconButton
                    fontSize={"large"}
                    className={`d-flex align-items-center justify-content-center ${classes.colorWhite} mx-md-3`}
                    onClick={() => {
                      setActiveSearch(!activeSearch);
                    }}
                  >
                    {activeSearch ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={7}
                  xl={8}
                  className={`${
                    screenLgUp
                      ? `${classes.rightSearch} ${classes.rightSearchInput}`
                      : classes.rightSearchInput
                  } d-flex align-items-center`}
                  style={{ minHeight: 50 }}
                >
                  <Grid
                    container
                    className={`${classes.paddingX} justify-content-between align-items-center`}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      className={`${
                        screenMdDown
                          ? `${classes.rightSearch} ${classes.rightSearchInput}`
                          : classes.rightSearchInput
                      }  my-3 my-md-3 my-lg-0 my-xl-0`}
                    >
                      {activeSearch ? (
                        <Autocomplete
                          className={`${classes.paddingX2}`}
                          {...defaultPropsSpecialty}
                          id="auto-highlight-speciality"
                          value={specialty}
                          onChange={(event, newValue) => {
                            setSpecialty(newValue);
                            immediatelyLoadFilters(
                              province ? province.id : 0,
                              newValue ? newValue.id : 0
                            );
                          }}
                          autoHighlight
                          closeIcon={
                            <CloseIcon color={"primary"} fontSize="small" />
                          }
                          classes={{
                            popper: classes.popperXs,
                            option: classes.option,
                            inputRoot: classes.inputRoot,
                            input: classes.inputBase,
                          }}
                          renderInput={(params) => (
                            <TextField
                              color={"primary"}
                              className={classes.textField}
                              InputLabelProps={{ shrink: false }}
                              inputProps={{
                                classes,
                                disableUnderline: true,
                              }}
                              {...params}
                              label={<Translate id={"Titles.Specialty"} />}
                              margin="none"
                            />
                          )}
                          renderOption={(option, { inputValue }) => {
                            const matches = match(option.name, inputValue);
                            const parts = parse(option.name, matches);
                            return (
                              <div>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </div>
                            );
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      className={`${
                        screenMdDown
                          ? `${classes.rightSearch} ${classes.rightSearchInput}`
                          : classes.rightSearchInput
                      } `}
                    >
                      {activeSearch ? (
                        <Autocomplete
                          className={`${classes.paddingX2}`}
                          {...defaultProvince}
                          id="auto-highlight-province"
                          value={province}
                          onChange={(event, newValue) => {
                            setProvince(newValue);
                            immediatelyLoadFilters(
                              newValue ? newValue.id : 0,
                              specialty ? specialty.id : 0
                            );
                          }}
                          autoHighlight
                          closeIcon={
                            <CloseIcon color={"primary"} fontSize="small" />
                          }
                          classes={{
                            popper: classes.popperXs,
                            option: classes.option,
                            inputRoot: classes.inputRoot,
                            input: classes.inputBase,
                          }}
                          renderInput={(params) => (
                            <TextField
                              color={"primary"}
                              className={classes.textField}
                              InputLabelProps={{ shrink: false }}
                              InputProps={{
                                classes,
                                disableUnderline: true,
                              }}
                              {...params}
                              label={<Translate id={"Titles.Province"} />}
                              margin="none"
                            />
                          )}
                          renderOption={(option, { inputValue }) => {
                            const matches = match(option.name, inputValue);
                            const parts = parse(option.name, matches);
                            return (
                              <div>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </div>
                            );
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      {activeSearch && (province !== null || specialty !== null)
                        ? renderSearch()
                        : ""}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Translate>
            {({ translate }) => {
              return (
                <List>
                  {states.map((state) => {
                    return renderItem(state.id, state.name);
                  })}
                  {renderItem(-1, translate("Professional.Everybody"))}
                </List>
              );
            }}
          </Translate>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={9}
          lg={9}
          xl={9}
          className={screenXsOnly ? "pl-0" : "pl-4"}
        >
          {renderItemTab(
            selectedIndex !== -1
              ? applications
                ? applications.filter((x) => x.offer.stateId === selectedIndex)
                : []
              : applications
              ? applications
              : [],
            selectedIndex !== -1
              ? states
                ? states.find((x) => x.id === selectedIndex)
                : -1
              : -1
          )}
        </Grid>
      </Grid>
    );
  }

  function renderTab() {
    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabRoot}
          variant="fullWidth"
          value={valueTab}
          onChange={handleChangeTab}
          aria-label="nav tabs"
        >
          <Tab
            icon={<img alt="" src={screenSmDown ? OrangeOkM : OrangeOk} />}
            className={classes.tabText}
            label={<Translate id={"Employer.ManageSelectionProcesses"} />}
            {...a11yProps(0)}
          />
          <Tab
            icon={
              <img alt="" src={screenSmDown ? OrangeLaptopM : OrangeLaptop} />
            }
            className={classes.tabText}
            label={
              <Translate id={"Professional.ManageSelectionProcessAlerts"} />
            }
            {...a11yProps(1)}
          />
        </Tabs>
        <Paper className={classes.paper} elevation={0}>
          <TabPanel value={valueTab} index={0}>
            {renderManagementProcess()}
          </TabPanel>
          <TabPanel value={valueTab} index={1} className={classes.tabPanel}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={`${
                  classes.tabTop
                } d-flex flex-wrap justify-content-between ${
                  screenXsOnly ? "mx-4" : ""
                }`}
              >
                <Typography
                  className={`${classes.body3} font-weight-bold pl-4`}
                  style={{ color: "#FFFFFF" }}
                >
                  <Translate id={"Professional.MySelectionProcessAlerts"} />
                </Typography>
                {renderFilter()}
              </Grid>
              <Grid
                item
                xs={12}
                className={`d-flex flex-wrap justify-content-center ${
                  screenMdDown ? "pt-5" : "pt-3"
                }`}
              >
                {renderCardAlert()}
              </Grid>
              <Grid item xs={12}>
                {alertsMessage ? (
                  <Snackbar
                    open={alertsMessage !== ""}
                    autoHideDuration={5000}
                    onClose={() => setAlertsMessage("")}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert
                      onClose={() => setAlertsMessage("")}
                      icon={false}
                      severity={
                        alertsMessageColor === "#00B52A" ? "success" : "error"
                      }
                    >
                      <Typography
                        variant="body2"
                        color={alertsMessageColor}
                        className="font-weight-bold"
                      >
                        {alertsMessage}
                      </Typography>
                    </Alert>
                  </Snackbar>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </Paper>
      </div>
    );
  }

  function renderBreadCrumb() {
    return (
      <React.Fragment>
        <BreadcrumbsItem to="/">
          <Translate id={"Titles.Start"} />
        </BreadcrumbsItem>
        <BreadcrumbsItem to="/professional/workspace">
          <Translate id={"Professional.ProfessionalZone"} />
        </BreadcrumbsItem>
      </React.Fragment>
    );
  }

  function renderSearch() {
    return (
      <Tooltip
        classes={{ tooltip: classes.customToolTip }}
        title={<Translate id={"Titles.Search"} />}
        placement="bottom"
      >
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={() => {
            if (province || specialty) {
              history.push(
                `/jobs/${specialty ? specialty.id : 0}/${
                  province ? province.id : 0
                }`
              );
            }
          }}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className="mt-sm-4"
    >
      {renderBreadCrumb()}
      <Grid item xs={10} sm={9} md={7} className="pb-2">
        <Typography className={classes.title} color="textSecondary">
          <Translate id={"Professional.ProfessionalZone"} />
        </Typography>
        <Grid container className="pt-4">
          <Grid item xs={3} sm={2} md={2} lg={2}>
            {!professional.profileImageUrl ? (
              <div>
                {professional.interviewUrl ? (
                  <LogoPreview
                    videoUrl={professional.interviewUrl}
                    management={true}
                  />
                ) : (
                  <div className={classes.logo} />
                )}
              </div>
            ) : (
              <img src={professional.profileImageUrl} className={classes.img} />
            )}
          </Grid>
          <Grid
            item
            xs={9}
            sm={10}
            md={10}
            lg={10}
            className={screenMdDown ? "pl-4" : ""}
            style={screenXlOnly ? { marginLeft: -38 } : {}}
          >
            <Typography
              color="textSecondary"
              className={`${classes.ellipsis} ${classes.textLinkedin}`}
            >
              {professional.firstName
                ? `${professional.firstName} ${professional.lastName}`
                : ""}
            </Typography>
            <Typography
              color="textSecondary"
              className={`${classes.ellipsis} ${classes.textLinkedin}`}
            >
              {professional.professionTitle}
            </Typography>
            <Typography
              color="textSecondary"
              className={`${classes.ellipsis} ${classes.textLinkedin}`}
            >
              {professional.linkedInProfile}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
            {renderTab()}
          </Grid>
        </Grid>
      </Grid>
      {valueTab === 1 ? (
        <Grid
          item
          xs={11}
          className={`justify-content-between ${classes.butonContainer} ${
            screenXsOnly ? "py-4" : "py-5"
          }`}
        >
          <div
            className={`justify-content-between d-flex align-items-center px-4 py-3 flex-wrap ${classes.rightSearchAlert}`}
          >
            <Typography variant="body2" className="font-weight-bold">
              <Translate id={"Offers.HowOftenNotified"} />
            </Typography>
            <RadioGroup
              row
              aria-label="Daily"
              name="daily"
              value={daily}
              onChange={handleChangeDaily}
            >
              <FormControlLabel
                value={"daily"}
                control={<Radio color="primary" />}
                className="mb-0"
                label={<Translate id={"Offers.Daily"} />}
                labelPlacement="start"
              />
              <FormControlLabel
                value={"weekly"}
                control={<Radio color="primary" />}
                className="mb-0"
                label={<Translate id={"Offers.Weekly"} />}
                labelPlacement="start"
              />
            </RadioGroup>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenAlert}
            className={`${classes.submit} ${screenXsOnly ? "mt-4" : ""}`}
          >
            <Typography className={`${classes.submit}`}>
              <Translate id={"Professional.CreateNewAlert"} />
            </Typography>
          </Button>
        </Grid>
      ) : (
        ""
      )}
      {renderNewAlert()}
      {renderDialogDelete()}
    </Grid>
  );
}
