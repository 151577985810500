import React, {useEffect, useState, createRef, useRef} from 'react';
import MaterialTable from 'material-table';
import api from "../../api/index";
import {Translate} from "react-localize-redux";
import {Grid, Hidden, useMediaQuery, Tooltip} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {PrintOutlined} from '@material-ui/icons';
import DefaultImage from "../../images/employer/default-image.png";
import {NavLink} from "react-router-dom";
import PaymentCard from "./paymentCard";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DownloadIcon from "../../images/payment/download-pdf.png";
import RepeatIcon from '@material-ui/icons/Repeat';
import {BASE_PATH} from "../../api/index";
import {useHistory} from 'react-router-dom';
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import moment from "moment";

const useStyles = makeStyles((theme) => {
    theme.overrides.MuiInputLabel = {
        ...theme.overrides.MuiInputLabel,
        root: {
            ...theme.overrides.MuiInputLabel.root,
            fontWeight: "bold"
        }
    }
    return {
        paddingXs: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "1rem",
                paddingLeft: "1rem"
            }
        },
        title: {
            fontSize: 35,
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: 0,
            [theme.breakpoints.only('xs')]: {
                fontSize: 30,
                marginTop: 0
            }
        },
        img: {
            width: 87,
            height: 87,
            objectFit: "contain",
            [theme.breakpoints.only('xs')]: {
                width: 84,
                height: 84,
            }
        },
        textLinkedin: {
            fontSize: 16,
            letterSpacing: 0.8,
            lineHeight: 1.45,
            color: theme.palette.text.secondary,
            whiteSpace: "nowrap",
            [theme.breakpoints.only('xs')]: {
                fontSize: 15,
            },
        },
        ellipsis: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        account: {
            display: "flex",
            alignItems: "flex-end",
            [theme.breakpoints.only('xs')]: {
                marginTop: 20
            },
        },
        sortBox: {
            minHeight: 50,
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
            marginBottom: 25,
            marginTop: 20
        },
        mb4: {
            marginBottom: 37,
            [theme.breakpoints.only('xs')]: {
                marginBottom: 0
            },
        },
        formControl: {
            display: "flex",
            alignItems: "center",
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
            justifyContent: "center",

            '& .MuiSelect-select:focus': {
                backgroundColor: "transparent",
            },
            '& .MuiInput-root': {
                marginTop: "0 !important",
                marginBottom: 0,
                [theme.breakpoints.up('lg')]: {
                    backgroundColor: "transparent",
                },

            },
            '& .MuiSelect-selectMenu': {
                [theme.breakpoints.up('lg')]: {
                    backgroundColor: "transparent",
                    color: "#FFFFFF"
                },
            },
            '& .MuiSelect-icon': {
                [theme.breakpoints.up('lg')]: {
                    color: "#FFFFFF"
                },

            },
            '& .MuiSelect-select.MuiSelect-select': {
                backgroundColor: theme.palette.secondary.main,
            }
        },
        select: {
            minHeight: 22
        },
        selectContainer: {
            minWidth: 170
        },
        customWidth: {
            maxWidth: 180
        }
    }
});

export default function EmployerPayments() {
    let history = useHistory();
    let classes = useStyles();
    const theme = useTheme();
    let [employerInfo, setEmployerInfo] = useState(null);
    let [payments, setPayments] = useState([]);
    const screenXsOnly = useMediaQuery(theme.breakpoints.only('xs'));

    let [selectedSort, setSelectedSort] = useState(1);
    let [orderByDirection, setOrderByDirection] = useState("desc");
    let [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(30);

    useEffect(() => {
        loadEmployerProfile();
    }, [])

    function loadEmployerProfile() {
        if (employerInfo === null)
            api.employers.getEmployerProfile().then(response => {
                if (response.status === 200) {
                    setEmployerInfo(response.data);
                }
            })
    }

    const toggleOrderByDirection = () => {
        if (orderByDirection === "asc") {
            setOrderByDirection("desc");
            return "desc";
        }
        if (orderByDirection === "desc") {
            setOrderByDirection("asc")
            return "asc";
        }
    }


    const handleChangeFilter = (event) => {
        let value = event.target.value;
        setSelectedSort(value);

        let direction = "desc";
        if (selectedSort === value) direction = toggleOrderByDirection();
        else setOrderByDirection("desc");

        loadPayments(value, direction);
    }

    function loadPayments(sortBy, sortDirection) {
        api.payments.getEmployerPayments(page, pageSize, sortBy, sortDirection === "asc")
            .then(response => {
                if (response.status === 200) {
                    setPayments(response.data.payments);
                }
            })
    }

    function handleDownloadInvoice(id) {
        window.open(`${BASE_PATH}/payment/generateinvoice?id=${id}`, '_blank')
    }

    function handleRetryPayment(id) {
        api.payments.getRetryPayment(id).then(response => {
            if (response.status === 200) {
                history.push(response.data);
            }
        })
    }

    function renderSortBy() {
        return <div className={classes.sortBox}>
            <div className={classes.formControl}>
                <Typography variant="body2" style={{color: "#141414", fontWeight: 600}}>
                    <Translate id={"Offers.OrderedFor"}/>:
                </Typography>
                <Select
                    className={classes.selectContainer}
                    labelId="select-label"
                    value={selectedSort}
                    onChange={handleChangeFilter}>

                    <MenuItem value={1} className={classes.select}
                              onClick={() => handleChangeFilter({target: {value: 1}})}>
                        <Translate id={"Payments.DateColumn"}/>
                    </MenuItem>
                    <MenuItem value={2} className={classes.select}
                              onClick={() => handleChangeFilter({target: {value: 2}})}>
                        <Translate id={"Payments.NoColumn"}/>
                    </MenuItem>
                    <MenuItem value={3} className={classes.select}
                              onClick={() => handleChangeFilter({target: {value: 3}})}>
                        <Translate id={"Payments.ConceptColumn"}/></MenuItem>
                    <MenuItem value={4} className={classes.select}
                              onClick={() => handleChangeFilter({target: {value: 4}})}>
                        <Translate id={"Payments.TotalColumn"}/></MenuItem>
                </Select>
            </div>
        </div>
    }

    const getDataInvoice = (id) => new Promise((resolve, reject) => {
        api.payments.getInvoice(id)
            .then(response => {
                resolve(response.data)
            });
    });

    function renderBreadCrumb() {
        return <React.Fragment>
            <BreadcrumbsItem to='/'><Translate id={"Titles.Start"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace'><Translate id={"Employer.EmployerArea"}/></BreadcrumbsItem>
            <BreadcrumbsItem to='/employer/workspace/segment'><Translate id={"Payments.Invoice"}/></BreadcrumbsItem>
        </React.Fragment>
    }

    return (<Translate>
        {({translate}) => {
            return <Grid container spacing={0}>
                {renderBreadCrumb()}
                <Grid container direction="row" justify="center" alignItems="center" className={`mt-4`}>
                    <Grid item xs={11} sm={9} md={7} className={`${classes.paddingXs} ${classes.mb4}`}>
                        <Typography className={classes.title} color="textSecondary">
                            <Translate id={"Payments.Title"}/>
                        </Typography>

                        {employerInfo && <Grid container>
                            <Grid item xs={12} md={8} className="d-flex pt-4">
                                <Grid container>
                                    <Grid item md={2} xs={4}>
                                        {employerInfo.enterprise && employerInfo.enterprise.logoUrl ?
                                            <img src={`${employerInfo.enterprise.logoUrl}?image=${Math.random()}`}
                                                 className={classes.img}/> :
                                            <img src={DefaultImage} className={classes.img}/>
                                        }
                                    </Grid>
                                    <Grid item md={10} xs={8} className="pl-md-3">
                                        <Typography className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                    color="textSecondary">
                                            {employerInfo && employerInfo.enterprise ? employerInfo.enterprise.name : ""}
                                        </Typography>
                                        <Typography className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                    color="textSecondary">
                                            {employerInfo.employerType.name}
                                        </Typography>
                                        <Typography className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                    color="textSecondary">
                                            {employerInfo && employerInfo.enterprise && employerInfo.enterprise.sector ? employerInfo.enterprise.sector.name : ""}
                                        </Typography>

                                        <a href={employerInfo.enterprise.webUrl} role="button" target={"_blank"}>
                                            <Typography className={`${classes.ellipsis} ${classes.textLinkedin}`}
                                                        color="textSecondary" style={{maxWidth: "80%"}}>
                                                {employerInfo && employerInfo.enterprise ? employerInfo.enterprise.webUrl : ""}
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.account}>
                                <div style={{width: "max-content"}}>
                                    <Grid item xs={12}>
                                        <Typography component={"span"}>
                                            <b>{`${translate("Payments.Account")}: `}</b>
                                            {employerInfo.email}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component={"span"}>
                                            <b>{`${translate("Payments.Cif")}: `}</b>
                                            {employerInfo.enterprise ? employerInfo.enterprise.identifier : ""}
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>}
                    </Grid>

                    <Grid item xs={12} style={{display: !screenXsOnly ? "none" : ""}}>
                        {renderSortBy()}
                    </Grid>

                    <Grid item xs={12} className={classes.paddingXs}>
                        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                            <Grid id="payments" item xs={12} sm={11} md={11} lg={11} xl={10} className={"pb-5"}>
                                <MaterialTable
                                    style={{display: screenXsOnly ? "none" : ""}}
                                    title="Remote Data Preview"
                                    columns={[
                                        {
                                            title: translate("Payments.DateColumn"), field: 'shortDate',
                                            render: rowData => rowData.date ? moment(rowData.date).format("DD/MM/yyyy") : "",
                                        },
                                        {
                                            title: translate("Payments.InvoiceColumn"), field: 'invoiceNumber',
                                            headerStyle: {
                                                maxWidth: 50
                                            }
                                        },
                                        {
                                            title: translate("Payments.InitDateColumn"), field: 'paymentDate',
                                            render: rowData => rowData.paymentDate ? moment(rowData.paymentDate).format("DD/MM/yyyy") : "",
                                            headerStyle: {
                                                maxWidth: 50
                                            },
                                            defaultSort: 'desc',
                                            defaultGroupSort: 'desc'
                                        },
                                        {
                                            title: translate("Payments.ConceptColumn"), field: 'concept',
                                            cellStyle: {
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                minWidth: 450
                                            },
                                        },
                                        {
                                            title: translate("Payments.TotalColumn"),
                                            field: 'import',
                                            render: rowData => `${rowData.import.toFixed(2)} ${rowData.currencySymbol ? rowData.currencySymbol : ''}`
                                        },
                                    ]}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            let asc = query.orderDirection ? query.orderDirection === "asc" : null;
                                            let orderBy = query.orderBy ? query.orderBy.field : null;

                                            let indexOrderBy = orderBy === "shortDate" ? 1
                                                : orderBy === "invoiceNumber" ? 2
                                                    : orderBy === "concept" ? 3
                                                        : orderBy === "import" ? 4
                                                            : orderBy === "paymentDate"
                                                                ? 5 : 0;

                                            api.payments.getEmployerPayments(query.page, query.pageSize, indexOrderBy, asc)
                                                .then(response => {
                                                    if (response.status === 200) {
                                                        setPayments(response.data.payments);
                                                        resolve({
                                                            data: response.data.payments,
                                                            page: response.data.page,
                                                            totalCount: response.data.total
                                                        })
                                                    } else {
                                                        resolve({
                                                            data: [],
                                                            page: 0,
                                                            totalCount: 0
                                                        })
                                                    }
                                                })
                                        })
                                    }
                                    actions={[
                                        rowData => ({
                                            icon: () => <img src={DownloadIcon}/>,
                                            onClick: (event, rowData) => handleDownloadInvoice(rowData.id),
                                            hidden: !rowData.success
                                        }),
                                        rowData => ({
                                            icon: () => <Translate>
                                                {({translate}) => {
                                                    return <Tooltip classes={{tooltip: classes.customWidth}}
                                                                    title={translate("Payments.Pending")}
                                                                    placement="top">
                                                        <RepeatIcon color="secondary" style={{fontSize: 40}}/>
                                                    </Tooltip>
                                                }}
                                            </Translate>,
                                            onClick: (event, rowData) => handleRetryPayment(rowData.id),
                                            hidden: rowData.success
                                        })
                                    ]}
                                    components={{
                                        Toolbar: props => (<div></div>)
                                    }}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#FFB170',
                                            color: '#141414',
                                            fontWeight: 700,
                                            padding: 13,
                                            fontSize: 18
                                        },
                                        rowStyle: {
                                            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%)",
                                            borderBottom: "2px solid #0000001A",
                                            fontSize: 18,
                                            fontWeight: 400
                                        },
                                        actionsColumnIndex: -1,
                                        paging: true,
                                        sorting: true,
                                        defaultSort: "desc",
                                        pageSize: 30,
                                        pageSizeOptions: [30],
                                        thirdSortClick: false
                                    }}
                                    localization={{
                                        header: {
                                            actions: translate("Payments.DownloadColumn")
                                        },
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsSelect: "pagos",
                                            nextTooltip: "",
                                            previousTooltip: "",
                                            firstTooltip: "",
                                            lastTooltip: ""
                                        },
                                        body:{
                                            emptyDataSourceMessage: ""
                                        }
                                    }}
                                />

                                <Grid item xs={12} style={{display: !screenXsOnly ? "none" : ""}}>
                                    {payments.map((payment, index) => {
                                        return <PaymentCard payment={payment} index={index}
                                                            handleDownloadInvoice={handleDownloadInvoice}
                                                            handleRetryPayment={handleRetryPayment}/>
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }}
    </Translate>)
}