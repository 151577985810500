import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Tooltip,
  Modal,
  Fade,
  Backdrop,
  Popover,
  Popper,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Typography from "@material-ui/core/Typography";
import { useHistory, withRouter } from "react-router-dom";
import api from "../../api/index";
import {
  applicationStates,
  offersSatte,
  PaymentType,
} from "../../utils/constants";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Info } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => {
  theme.overrides.MuiSelect = {
    ...theme.overrides.MuiSelect,
    root: {
      padding: 9,
    },
    select: {
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  };
  return {
    root: {
      position: "absolute",
      right: 0,
      top: 0,
      [theme.breakpoints.only("xs")]: {
        position: "inherit",
        marginLeft: 8,
      },
    },
    title1: {
      fontSize: 35,
      fontWeight: 600,
      lineHeight: 1.3,
      marginTop: 20,
      [theme.breakpoints.only("xs")]: {
        fontSize: 30,
        marginTop: 0,
        lineHeight: 1,
      },
    },
    subtitle: {
      fontSize: 25,
      lineHeight: 1,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 22,
        lineHeight: 1.2,
      },
    },
    topPaper: {
      marginTop: 35,
      padding: "28px 24px",
      boxShadow: "0px 0px 25px #0000001A",
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
        padding: 15,
      },
    },
    downPaper: {
      marginTop: 35,
      padding: "28px 60px",
      boxShadow: "0px 0px 25px #0000001A",
      paddingBottom: 47,
      [theme.breakpoints.only("xs")]: {
        boxShadow: "none",
        padding: 0,
        marginTop: 0,
      },
    },
    caption: {
      fontSize: 16,
      fontWeight: 700,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    captionValue: {
      fontSize: 16,
      fontWeight: 400,
      marginLeft: 3,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    badge: {
      width: 25,
      height: 25,
      borderRadius: 50,
      background: "#141414  0% 0% no-repeat padding-box",
      marginLeft: 10,
      cursor: "pointer",
    },
    textBadge: {
      fontWeight: 700,
      color: theme.palette.common.white,
      fontSize: 17,
      marginLeft: 4,
    },
    tooltip: {
      lineHeight: 1.2,
      fontSize: 15,
    },
    menuCircle: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 50,
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.only("xs")]: {
        width: 30,
        height: 30,
      },
    },
    nonPadding: {
      padding: 0,
    },
    option: {
      fontSize: 16,
      fontWeight: 400,
      paddingTop: 4,
      paddingBottom: 4,
    },
    stateOption: {
      fontSize: 18,
      fontWeight: 600,
    },
    separator: {
      width: 88,
      border: "1px solid #141414",
      margin: 0,
    },
    separatorLarge: {
      width: 135,
      border: "1px solid #141414",
      margin: 0,
      [theme.breakpoints.only("xs")]: {
        width: 88,
      },
    },
    relative: {
      position: "relative",
    },
    applicantsTittle: {
      fontSize: 22,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
    },
    selected: {
      minHeight: 40,
      height: 40,
      minWidth: 120,
    },
    labelShrink: {
      transform: "translate(0, 1.5px) scale(1)",
      fontWeight: 600,
      fontSize: 16,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    item: {
      fontSize: 16,
      [theme.breakpoints.only("xs")]: {
        fontSize: 15,
      },
    },
    formControl: {
      marginLeft: 35,
    },
    filtersContainer: {
      justifyContent: "flex-end",
      display: "flex",
      [theme.breakpoints.only("xs")]: {
        marginTop: 14,
      },
    },
    cardContainer: {
      marginTop: 30,
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
    },
    stateWidget: {
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
      },
    },
    img: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
      [theme.breakpoints.only("xs")]: {
        maxWidth: 67,
      },
    },
    reactivationTitle: {
      fontSize: 22,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
    },
    infoContainer: {
      textAlign: "left",
      maxWidth: 261,
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    dateCaption: {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.only("xs")]: {
        fontSize: 16,
      },
    },
    priceContainer: {
      padding: "5px 20px",
      backgroundColor: "#F0F0F0",
      marginTop: 10,
      [theme.breakpoints.only("xs")]: {
        justifyContent: "center",
      },
    },
    paddingXs: {
      [theme.breakpoints.only("xs")]: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
    },
    modal: {
      zIndex: 10,
      position: "fixed",
      right: "1rem",
      bottom: "1rem",
      padding: 0,
      backgroundColor: "#fff",
      maxWidth: "360px",
      overFlow: "auto",
      [theme.breakpoints.only("xs")]: {
        width: "90vw",
      },
    },
    modalButton: {
      zIndex: 10,
      position: "fixed",
      right: "1rem",
      bottom: "6rem",
      padding: 0,
      backgroundColor: "#fff",
      overFlow: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1rem solid #FFB170",
      borderRadius: ".2rem",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
    },
    button: {
      minHeight: 40,
      height: 40,
      borderRadius: 0,
      minWidth: 180,
      width: 187,
      marginTop: 25,
      [theme.breakpoints.only("xs")]: {
        marginTop: 20,
      },
    },
    buttonPay: {
      backgroundColor: "#1e7b85",
      color: "#fff",
      minHeight: 40,
      height: 40,
      borderRadius: 0,
      minWidth: 180,
      width: 187,
    },
    buttonLabel: {
      fontSize: 18,
      fontWeight: 700,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, .5)",
      height: "50%",
      width: "50%",
    },
    moadalText: {
      fontSize: 14,
      [theme.breakpoints.only("s")]: {
        fontSize: 12,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 10,
      },
    },
  };
});

const ModalBackdrop = (props) => {
  const classes = useStyles();
  return <Backdrop className={classes.backdrop} {...props}></Backdrop>;
};

const ModalPay = ({ open }) => {
  const [cant, setCant] = useState(1);
  const [price, setPrice] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const [isBuy, setIsBuy] = useState(false);
  const [minimized, setMinimized] = useState(true);

  async function redirectToPayment() {
    let payment = await api.payments.createOrder(
      PaymentType.AddCredit,
      parseInt(cant),
      0
    );
    payment = payment.status === 200 ? payment.data.id : null;
    if (payment) {
      history.push(`/employer/pay/${PaymentType.AddCredit}/${payment}/${cant}`);
    }
  }

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  useEffect(() => {
    api.plans.getPlan("AddCredit", cant).then((res) => {
      if (res.status == 200) {
        setPrice(res.data.price);
        setCurrencyCode(res.data.currencyCode);
      }
    });
  }, [cant]);

  /*
   */
  if (modalOpen) {
    if (minimized) {
      return (
        <Box
          aria-labelledby={"Dev.transition-modal-title"}
          aria-describedby={"Dev.transition-modal-description"}
          keepMounted={true}
          className={classes.modalButton}
          BackdropComponent={ModalBackdrop}
          onClose={() => setModalOpen(true)}
          disableEnforceFocus={true}
          disableAutoFocus={false}
          id="Dev.buy-credit1"
        >
          <Button
            id="Dev.buy-credit2"
            variant="contained"
            color="secondary"
            className={classes.buttonPay}
            onClick={(e) => setMinimized(false)}
          >
            <Typography className={classes.buttonLabel} variant="inherit">
              <Translate id={"Dev.button-modal-title"} />
            </Typography>
          </Button>
        </Box>
      );
    } else {
      return (
        <Box
          aria-labelledby={"Dev.transition-modal-title"}
          aria-describedby={"Dev.transition-modal-description"}
          keepMounted={true}
          className={classes.modal}
          BackdropComponent={ModalBackdrop}
          onClose={() => setModalOpen(true)}
          disableEnforceFocus={true}
          disableAutoFocus={false}
        >
          <Box
            className={classes.paper}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                color="secondary"
                onClick={(e) => {
                  setIsBuy(false);
                  setMinimized(true);
                }}
              >
                <ArrowBackOutlinedIcon
                  fontSize="large"
                  id="iconArrow"
                  className={classes.icon}
                  color="action"
                />
              </IconButton>
              <Typography variant="h6">
                <Translate id={"Dev.transition-modal-title"} />
              </Typography>
            </Box>
            {isBuy ? (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <Typography
                    className={`${classes.moadalText} d-flex align-items-center justify-content-center`}
                    style={{
                      border: "1px solid #FFB170",
                      width: "10.3rem",
                      height: "3.35rem",
                    }}
                  >
                    <Translate id={"Offers.Total"} /> {price}
                    {currencyCode} + IVA
                  </Typography>
                </div>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    setModalOpen(false);
                    redirectToPayment();
                  }}
                  className={classes.button}
                >
                  <Typography className={` font-weight-bold`}>
                    <Translate id={"Offers.Contract"} />
                  </Typography>
                </Button>
                <Grid item xs={12} className="d-flex flex-wrap pt-4">
                  <Typography className={classes.moadalText}>
                    <Translate id={"Offers.Iva15"} />
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Typography className={classes.moadalText}>
                  <Translate id={"Dev.transition-modal-description"} />
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={(e) => setIsBuy(true)}
                >
                  <Typography
                    className={classes.buttonLabel}
                    variant="inherit"
                    color="textSecondary"
                  >
                    <Translate id={"Dev.buy-credit"} />
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        </Box>
      );
    }
  } else {
    return null;
  }
};

export default ModalPay;
